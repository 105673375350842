import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FMSColors } from 'service/common/designLib';
import { useSelector } from 'react-redux';

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
`;

const LeftNavLink = styled(NavLink)`
  a:link,
  :visited,
  :hover,
  :active {
    text-decoration: none;
  }
`;

const LeftMenu = React.memo(({ menu, isSelected, handleSelectMenu }) => (
  <LeftNavLink
    activeStyle={{
      fontWeight: 'bold',
      fontSize: '16px',
      color: FMSColors.grey
    }}
    isActive={() => isSelected}
    style={{
      width: '100%',
      fontSize: '16px',
      fontWeight: '500',
      color: FMSColors.brownGrey
    }}
    to={{ pathname: menu.menuUrl, state: null }}
    onClick={() => handleSelectMenu(menu)}
  >
    <div className='menu' style={{ padding: '10px 0px', margin: '0px 15px', lineHeight: '1.4' }}>
      {menu.depth === 3 && <img alt='menu depth' src='/assets/images/menu/menu-line.png' />}
      <FormattedMessage defaultMessage={menu.name} id={menu.msgKey || 'none'} />
    </div>
  </LeftNavLink>
));

const HomeNavLeftMenu = ({
  menuList,
  selectedMenuIDs,
  handleSelectMenu,
  handleAgreeMember,
  handlePersonalInfo,
  countryCd = ''
}) => {
  // redux 메뉴 클릭 css 활성화
  const menuActive = useSelector(({ menu }) => {
    return menu.menuActive.data;
  });
  const menuLeftActive = useSelector(({ menu }) => {
    return menu.menuLeftActive.data;
  });

  // useEffect(()=>{
  //   console.log('menuLeftActive:', menuLeftActive);
  // },[menuLeftActive])
  // redux 좌측 메뉴 수동 활성화
  if (menuLeftActive) {
    menuList = menuLeftActive;
  }

  return (
    <Container>
      {menuList &&
        menuList.map((menu) => {
          let active = false; // 메뉴 css 활성화 여부
          if (menuActive && menuActive.includes(menu.menuID)) {
            // redux에서 제어한 메뉴 active
            active = true;
          } else if (!menuActive && selectedMenuIDs && selectedMenuIDs.includes(menu.menuID)) {
            // 메뉴클릭시 active
            active = true;
          }
          return (
            <LeftMenu
              key={menu.menuID}
              handleSelectMenu={handleSelectMenu}
              isSelected={active}
              menu={menu}
            />
          );
        })}

      <div
        className='policy-box'
        style={{
          margin: '0px 15px',
          position: 'fixed',
          // top: 'max( 800px, calc(100% - 36px))'
          bottom: '25px',
          lineHeight: '1.4'
        }}
      >
        <span
          role='button'
          style={{ cursor: 'pointer' }}
          tabIndex='0'
          onClick={handleAgreeMember}
          onKeyDown={handleAgreeMember}
        >
          <FormattedMessage id='Footer.Policy1' />
        </span>
        <span> l </span>
        <span
          role='button'
          style={{ cursor: 'pointer' }}
          tabIndex='0'
          onClick={handlePersonalInfo}
          onKeyDown={handlePersonalInfo}
        >
          <FormattedMessage id='Footer.Policy2' />
        </span>
      </div>
    </Container>
  );
};

export default React.memo(HomeNavLeftMenu);
