import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { closeModalPostcode } from 'v2/redux/modules/modals/postcodeModal';
import { AutoComplete } from 'components/hereMap';
import { AutoComplete as RuAutoComplete } from 'components/yandexMap';
import FMSModal from 'components/ui/common/FMSModal';
import { FMSIconBtnClose } from 'components/ui/button/FMSIconBtn';
import { initializeSearchResultNew, getSearchResultNew } from 'v2/redux/modules/locationLocal';

const stateSelector = createSelector(
  (store) => store.locationLocal,
  (store) => store.modals.postcodeModal,
  (locationLocal, postcodeModal) => ({
    onChangeAddress: fp.get('onChangeAddress', postcodeModal),
    closeOnChange: fp.get('closeOnChange', postcodeModal),
    isNewAddressFormat: fp.get('isNewAddressFormat', postcodeModal),
    isModalOpen: fp.getOr(false, 'isOpen', postcodeModal),
    postcodeModal,
    suggestions: fp.getOr([], 'searchResult.data', locationLocal)
  })
);

const PostcodeModalPage = () => {
  const {
    onChangeAddress,
    closeOnChange,
    isModalOpen,
    postcodeModal,
    suggestions,
    isNewAddressFormat
  } = useSelector(stateSelector, fp.equals);
  const dispatch = useDispatch();
  const handlerCloseModal = useCallback(() => dispatch(closeModalPostcode()), [dispatch]);
  const handleCompleteAddress = useCallback(
    (data) => {
      onChangeAddress(data);
      if (closeOnChange) handlerCloseModal();
    },
    [onChangeAddress, handlerCloseModal]
  );
  const onInputKeyDown = useCallback((query) => dispatch(getSearchResultNew(query)), [dispatch]);

  /**
   * 기존검색결과 제거
   */
  useEffect(() => {
    if (fp.isNotEmpty(suggestions)) {
      const composer = fp.pipe(initializeSearchResultNew, dispatch);
      composer();
    }
  }, [isModalOpen]);

  return (
    <FMSModal
      {...{
        ...postcodeModal,
        handleCloseModal: handlerCloseModal,
        titleStyles: {
          marginTop: -35,
          fontSize: 18,
          paddingBottom: 10,
          position: 'relative'
        },
        customStyles: { content: { width: 500, height: 400, paddingTop: 60 } }
      }}
    >
      <AutoComplete
        handleCompleteAddress={handleCompleteAddress}
        r-if={!$utils.isRu}
        suggestions={suggestions}
        onKeyDown={onInputKeyDown}
        isNewAddressFormat={isNewAddressFormat}
      />
      <RuAutoComplete
        r-else
        handleCompleteAddress={handleCompleteAddress}
        suggestions={suggestions}
        onKeyDown={onInputKeyDown}
      />
      <FMSIconBtnClose onClick={handlerCloseModal} />
    </FMSModal>
  );
};

export default memo(PostcodeModalPage);
