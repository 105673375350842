import React from 'react';
import styled from 'styled-components';
import { FMSBtnForm } from 'components/ui/button/FMSBtn';
import axios from 'axios';
// import { FMSLayout } from 'components/ui/common';
import * as Sentry from '@sentry/react';

const ErrorWrap = styled.div`
  text-align: center;
  margin-top: 400px;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error.stack);
    console.error('errorTrack: ', error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <ErrorWrap>
          <div>
            <h1>OOPS!!</h1>
            <FMSBtnForm
              filled
              type='submit'
              onClick={() => {
                window.location.href = '/';
              }}
            >
              HOME
            </FMSBtnForm>
          </div>
        </ErrorWrap>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
