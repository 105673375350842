import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getTerminalListAPI,
  getTerminalDetailAPI,
  createTerminalAPI,
  updateTerminalAPI,
  deleteTerminalAPI
} from 'v2/apis/terminalApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  changeTerminalPage,
  getTerminalList,
  changeTerminalDetailPage,
  getTerminalDetail,
  createTerminal,
  updateTerminal,
  deleteTerminal,
  deleteTerminals,
  entityTerminalList,
  entityTerminalDetail
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/terminal';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorTerminalListPage = (state) => state.terminal.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateTerminal = createEntity({
  key: createTerminal().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    const selectorListPage = yield select(selectorTerminalListPage);
    const { pager } = selectorListPage;
    const { ID } = payload;
    history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    if (ID) yield put(getTerminalDetail(ID));
  }
});

// update for update page
export const entityUpdateTerminal = createEntity({
  key: updateTerminal().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { ID } = requestParam;
    if (ID) yield put(getTerminalDetail(ID));
  }
});

// deleteCompany(DetailPage)
export const entityDeleteTerminal = createEntity({
  key: deleteTerminal().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorTerminalListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForTerminals = createEntity({
  key: deleteTerminals().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeTerminalPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');

    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorTerminalListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getTerminalList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorTerminalListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getTerminalList(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createTerminalAsync = createSagaAction.bind(null, entityCreateTerminal, createTerminalAPI);
const updateTerminalAsync = createSagaAction.bind(null, entityUpdateTerminal, updateTerminalAPI);
const deleteTerminalAsync = createSagaAction.bind(null, entityDeleteTerminal, deleteTerminalAPI);
const deleteForTerminalsAsync = createSagaAction.bind(
  null,
  entityDeleteForTerminals,
  deleteTerminalAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getTerminalAsync = createSagaAction.bind(null, entityTerminalList, getTerminalListAPI);
const getTerminalDetailAsync = createSagaAction.bind(
  null,
  entityTerminalDetail,
  getTerminalDetailAPI
);
// const loadAllTerminalAsync = createSagaAction.bind(null, entityLoadAllTerminal, getClearComapnyAPI);

function* changeTerminalDetailPageSaga() {
  yield put(changeTerminalDetailPage({ mode: 'view' }));
}

/**
 * Watcher
 */
export default function* terminalWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createTerminal().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createTerminalAsync,
    entityCreateTerminal
  );
  yield takeLatest(
    updateTerminal().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateTerminalAsync,
    entityUpdateTerminal
  );
  yield takeLatest(
    deleteTerminal().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteTerminalAsync,
    entityDeleteTerminal
  );
  yield takeLatest(getTerminalList().type, fetchSaga, getTerminalAsync, entityTerminalList);
  yield takeLatest(
    getTerminalDetail().type,
    fetchSaga,
    getTerminalDetailAsync,
    entityTerminalDetail
  );

  // deletes in list page
  yield takeLatest(
    deleteTerminals().type,
    fetchSagas,
    null,
    deleteForTerminalsAsync,
    entityDeleteForTerminals
  );

  // select business
  // yield takeLatest(loadAllTerminal().type, fetchSaga, loadAllTerminalAsync, entityLoadAllTerminal);

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityCreateTerminal.types[1], changeTerminalDetailPageSaga);
  yield takeLatest(entityUpdateTerminal.types[1], changeTerminalDetailPageSaga);
}
