import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { createEntity, createReducer } from 'v2/redux/lib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

export const { initializeVehicleLogList, getVehicleLogList, changeVehicleLogList } = createActions(
  {
    GET_VEHICLE_LOG_LIST: (filters, pageInfo, sortInfo) => ({ filters, pageInfo, sortInfo })
  },
  'INITIALIZE_VEHICLE_LOG_LIST',
  'CHANGE_VEHICLE_LOG_LIST'
);

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      filters: {
        startDate: new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000,
        endDate: new Date().setHours(23, 59, 59, 999)
      },
      pageInfo: {
        page: 1,
        limit: PAGE_LIMIT
      },
      sortInfo: {
        field: 'creatAt',
        order: 'desc'
      }
    }
  }
};

export const entityVehicleLog = createEntity({
  key: getVehicleLogList().type,
  parseData: (action) => parseDataForList(action, 'reservationItems')
});

const vehicleLogReducer = handleActions(
  {
    [initializeVehicleLogList().type]: () => initializeState.list,
    [changeVehicleLogList().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

export const vehicleLog = combineReducers({
  list: createReducer({
    entity: entityVehicleLog,
    reducer: vehicleLogReducer
  })
});
