import React, { useCallback, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { initializeLoadAllCountries, loadAllCountries } from 'v2/redux/modules/country';
import { initializeMyCountry } from 'v2/redux/modules/authentication';

import FMSDataSelect from 'v2/components/pages/form/FMSDataSelect';

const stateSelector = createSelector(
  (store) => store.country,
  (country) => ({
    loading: fp.getOr(false, 'loadAll.loading', country),
    data: fp.getOr([], 'loadAll.data', country)
  })
);
const CountrySelectContainer = ({
  filters,
  defaultSelectedFirst,
  onChangeValue,
  selectedValue,
  ...props
}) => {
  const { loading, data } = useSelector(stateSelector, fp.equals);

  const dispatch = useDispatch();
  const handlerGetCountries = useCallback((filters) => dispatch(loadAllCountries(filters)), [
    dispatch
  ]);

  const [prevFilter, setPrevFilter] = useState({});

  useEffect(() => {
    const cleanUp = () => {
      dispatch(initializeMyCountry());
      dispatch(initializeLoadAllCountries());
    };

    return cleanUp;
  }, []);

  useEffect(() => {
    if (fp.notEquals(filters, prevFilter)) {
      setPrevFilter(filters);
      handlerGetCountries(filters);
    }
  }, [filters]);

  const getSelectedValue = useCallback(
    fp.curry((selectedValue, defaultSelectedFirst, data) => {
      if (fp.isEmpty(selectedValue) && defaultSelectedFirst && fp.isNotEmpty(data)) {
        return fp.head(data);
      }

      if (fp.isNotEmpty(selectedValue)) {
        return selectedValue;
      }
    }),
    []
  );

  useEffect(() => {
    if (defaultSelectedFirst && fp.isFunction(onChangeValue)) {
      onChangeValue({ meta: getSelectedValue(selectedValue, defaultSelectedFirst, data) });
    }
  }, [data]);

  return (
    <FMSDataSelect
      data={data}
      loading={loading}
      selectedValue={getSelectedValue(selectedValue, defaultSelectedFirst, data)}
      onChangeValue={onChangeValue}
      {...props}
    />
  );
};

export default memo(CountrySelectContainer);
