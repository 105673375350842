import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { listToTree } from 'v2/containers/main/lib/homeLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import { put } from 'redux-saga/effects';
import { changeVehiclesListPage, getVehicleBasic, saveMoveBranch } from '../vehicle/basic';
import { getErrorMessage } from '../../../../constants/errors';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  // menu redux all
  initializeLoadAllMenu,
  loadAllMenu,
  // tree page
  initializeMenuState,
  changeMenuState,
  getMenuList,
  setMenuActive,
  setMenuTabActive,
  setMenuLeftActive,
  // detail page
  initializeDetailPage,
  changeMenuDetailPage,
  getMenu,
  // CUD
  createMenu,
  updateMenu,
  deleteMenu,
  // menuProgram redux
  initializeMenuProgramState,
  changeMenuProgramState,
  getMenuProgramList,
  getMenuProgram,
  addForMenuProgramList,
  deleteForMenuProgramList
} = createActions(
  {
    LOAD_ALL_MENU: (filters) => ({ filters }),
    CREATE_MENU: (data) => ({ data }),
    UPDATE_MENU: (ID, data) => ({ ID, data }),
    DELETE_MENU: (ID) => ({ ID }),
    GET_MENU: (ID) => ({ ID }),

    GET_MENU_PROGRAM_LIST: (ID, pageInfo, sortInfo, filters) => ({
      ID,
      pageInfo,
      sortInfo,
      filters
    }),
    GET_MENU_PROGRAM: (ID) => ({ ID }),
    ADD_FOR_MENU_PROGRAM_LIST: (ID, data) => ({ ID, data }),
    DELETE_FOR_MENU_PROGRAM_LIST: (ID, data) => ({ ID, data })
  },
  'GET_MENU_LIST',
  'SET_MENU_ACTIVE',
  'SET_MENU_LEFT_ACTIVE',
  'SET_MENU_TAB_ACTIVE',
  'INITIALIZE_LOAD_ALL_MENU',
  'INITIALIZE_MENU_STATE',
  'CHANGE_MENU_STATE',
  'CHANGE_MENU_DETAIL_PAGE',
  'INITIALIZE_MENU_PROGRAM_STATE',
  'CHANGE_MENU_PROGRAM_STATE',
  'INITIALIZE_DETAIL_PAGE'
);
/** ********************************************** */

function makeItemFn(data) {
  data.id = data.ID;
  if (typeof data.toggled === 'undefined') data.toggled = true;
  if (typeof data.depth === 'undefined') data.depth = 0;
  return data;
}

export function makeListToTree(data, rootNode, idField) {
  return { treeNode: listToTree({ list: data, rootNode, makeItemFn, idField }), data };
}

function parseDataForTree(action) {
  const { data } = parseDataForList(action);
  const rootNode = { name: 'Title.Menu', toggled: true, ID: 0, id: 0, depth: 0 };
  return makeListToTree(data, rootNode);
}

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllMenuList = createEntity({
  key: loadAllMenu().type,
  parseData: parseDataForTree
});

export const entityMenuList = createEntity({
  key: getMenuList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityMenuProgramList = createEntity({
  key: getMenuProgramList().type,
  parseData: parseDataForTree,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});
export const entityMenuDetail = createEntity({
  key: getMenu().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});
export const entityMenuActive = createEntity({
  key: setMenuActive().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});
// 상단 TAB메뉴 수동 제어
export const entityMenuTabActive = createEntity({
  key: setMenuTabActive().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});
// Left메뉴 수동 제어
export const entityMenuLeftActive = createEntity({
  key: setMenuLeftActive().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});
/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    openNodes: [],
    selectedNode: null
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create'
  },
  programList: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  },
  menuActive: {
    data: null
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const menuListPageReducer = handleActions(
  {
    [initializeMenuState().type]: () => initializeState.list,
    [changeMenuState().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const menuListLoadAllReducer = handleActions(
  {
    [initializeLoadAllMenu().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const programListPageReducer = handleActions(
  {
    [initializeMenuProgramState().type]: () => initializeState.programList,
    [changeMenuProgramState().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const menuDetailPageReducer = handleActions(
  {
    [initializeDetailPage().type]: () => initializeState.detail,
    [changeMenuDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const setMenuActiveReducer = handleActions(
  {
    // [initializeVehiclesListPage().type]: () => initializeState.list,
    [setMenuActive().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);
const setMenuTabActiveReducer = handleActions(
  {
    // [initializeVehiclesListPage().type]: () => initializeState.list,
    [setMenuTabActive().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);
const setMenuLeftActiveReducer = handleActions(
  {
    // [initializeVehiclesListPage().type]: () => initializeState.list,
    [setMenuLeftActive().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);

// page action reducer 선언
export const menu = combineReducers({
  list: createReducer({
    entity: entityMenuList,
    reducer: menuListPageReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllMenuList,
    reducer: menuListLoadAllReducer
  }),
  programList: createReducer({
    entity: entityMenuProgramList,
    reducer: programListPageReducer
  }),
  detail: createReducer({
    entity: entityMenuDetail,
    reducer: menuDetailPageReducer
  }),
  menuActive: createReducer({
    entity: entityMenuActive,
    reducer: setMenuActiveReducer
  }),
  menuTabActive: createReducer({
    entity: entityMenuTabActive,
    reducer: setMenuTabActiveReducer
  }),
  menuLeftActive: createReducer({
    entity: entityMenuLeftActive,
    reducer: setMenuLeftActiveReducer
  })
});
/** ********************************************** */
