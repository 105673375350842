import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

export const {
  initializeLoadAllTerminal,
  loadAllTerminal,
  initializeTerminalState,
  changeTerminalPage,
  getTerminalList,
  initializeTerminalDetailPage,
  changeTerminalDetailPage,
  getTerminalDetail,
  createTerminal,
  updateTerminal,
  deleteTerminal,
  deleteTerminals
} = createActions(
  {
    LOAD_ALL_TERMINAL: (filters) => ({ filters }),
    GET_TERMINAL_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_TERMINAL_DETAIL: (ID) => ({ ID }),
    CREATE_TERMINAL: (data) => ({ data }),
    UPDATE_TERMINAL: (ID, data) => ({ ID, data }),
    DELETE_TERMINAL: (ID) => ({ ID })
  },
  'INITIALIZE_LOAD_ALL_TERMINAL',
  'INITIALIZE_TERMINAL_STATE',
  'CHANGE_TERMINAL_PAGE',
  'DELETE_TERMINALS',
  'INITIALIZE_TERMINAL_DETAIL_PAGE',
  'CHANGE_TERMINAL_DETAIL_PAGE'
);

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllTerminal = createEntity({
  key: loadAllTerminal().type,
  parseData: (action) => parseDataForList(action, 'controllerItems')
});

export const entityTerminalList = createEntity({
  key: getTerminalList().type,
  parseData: (action) => parseDataForList(action, 'controllerItems'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityTerminalDetail = createEntity({
  key: getTerminalDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create'
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const terminalPageReducer = handleActions(
  {
    [initializeTerminalState().type]: () => initializeState.list,
    [changeTerminalPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const terminalLoadAllReducer = handleActions(
  {
    [initializeLoadAllTerminal().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const terminalDetailPageReducer = handleActions(
  {
    [initializeTerminalDetailPage().type]: () => initializeState.detail,
    [changeTerminalDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const terminal = combineReducers({
  list: createReducer({
    entity: entityTerminalList,
    reducer: terminalPageReducer
  }),
  detail: createReducer({
    entity: entityTerminalDetail,
    reducer: terminalDetailPageReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllTerminal,
    reducer: terminalLoadAllReducer
  })
});
/** ********************************************** */
