import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';
import { put } from 'redux-saga/effects';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import { getErrorMessage } from '../../../../constants/errors';
import history from '../../../../service/common/history';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializePushListPage,
  initializePushDetailPage,
  changePushPage,
  getPushList,
  getPushDetail,
  getCityList,
  getCustomerList,
  createPush,
  initializeCityList,
  changePushDetailPage,
  deletePush
} = createActions(
  {
    GET_PUSH_LIST: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    }),
    GET_PUSH_DETAIL: (ID) => ({ ID }),
    CREATE_PUSH: (data) => ({ data })
  },
  'INITIALIZE_PUSH_LIST_PAGE',
  'INITIALIZE_PUSH_DETAIL_PAGE',
  'CHANGE_PUSH_PAGE',
  'CHANGE_PUSH_DETAIL_PAGE',
  'GET_CITY_LIST',
  'GET_CUSTOMER_LIST',
  'GET_CITY_DETAIL',
  'INITIALIZE_CITY_LIST',
  'DELETE_PUSH'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityPushList = createEntity({
  key: getPushList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityPushDetail = createEntity({
  key: getPushDetail().type,
  // parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityCityList = createEntity({
  key: getCityList().type,
  parseData: (action) => {
    //city, region, district 객체로 배열이 아니라 .map에서 튕겨내어 별도로 리턴
    const data = action?.response?.data;
    return data;
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityCustomerList = createEntity({
  key: getCustomerList().type,
  parseData: (action) => {
    const data = action?.response?.data;
    return data;
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityCreatePush = createEntity({
  key: createPush().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    history.replace({ pathname: '/push' });
  }
});

export const entityDeletePush = createEntity({
  key: deletePush().type,
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    // const selectorListPage = yield select(selectorMembersPage);
    // const { pager } = selectorListPage;
    history.push({ pathname: '/push', state: pager });
    yield put(getPushList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
    yield put(getPushList(pageInfo, sortInfo, filters));
  }
});

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    error: null,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {
        startDate: new Date().setHours(0, 0, 0, 0) - 60 * 24 * 60 * 60 * 1000,
        endDate: new Date().setHours(23, 59, 59, 999),
        recipientType: null,
        recipientEmail: null,
        subject: null,
        sendStatus: null
      }
    }
  },
  pageInfo: {
    page: 1,
    limit: PAGE_LIMIT
  },
  sortInfo: {
    field: 'creatAt',
    order: 'desc'
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create',
    error: null
  },
  cityList: {
    data: []
  },
  customerList: {
    data: []
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.

const pushListPageReducer = handleActions(
  {
    [initializePushListPage().type]: () => initializeState.list,
    [changePushPage().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);
const pushDetailPageReducer = handleActions(
  {
    [initializePushDetailPage().type]: () => initializeState.detail,
    [changePushDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const cityListReducer = handleActions(
  {
    [initializeCityList().type]: () => initializeState.cityList
  },
  {},
  { produce }
);

const customerListReducer = handleActions(
  {
    [initializeCityList().type]: () => initializeState.customerList
  },
  {},
  { produce }
);

// page action reducer 선언
export const push = combineReducers({
  list: createReducer({
    entity: entityPushList,
    reducer: pushListPageReducer
  }),
  detail: createReducer({
    entity: entityPushDetail,
    reducer: pushDetailPageReducer
  }),
  city: createReducer({
    entity: entityCityList,
    reducer: cityListReducer
  }),
  customer: createReducer({
    entity: entityCustomerList,
    reducer: customerListReducer
  })
});
/** ********************************************** */
