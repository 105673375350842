import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import { getAddedDate } from 'service/utils/FMSDateUtil';
import { select, put } from 'redux-saga/effects';

export const {
  initializeAllTestCarReservationList,
  getAllTestCarReservationList,
  approveTestCarReservations,
  denyTestCarReservations,
  summaryTestCarReservations,
  downloadTestCarReservations
} = createActions(
  {
    GET_ALL_TEST_CAR_RESERVATION_LIST: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    }),
    APPROVE_TEST_CAR_RESERVATIONS: (data) => ({ data }),
    DENY_TEST_CAR_RESERVATIONS: (data, reason) => ({ data, reason }),
    SUMMARY_TEST_CAR_RESERVATIONS: (filters) => ({ filters }),
    DOWNLOAD_TEST_CAR_RESERVATIONS: (filters, pageInfo, sortInfo) => ({
      filters,
      pageInfo,
      sortInfo
    })
  },
  'INITIALIZE_ALL_TEST_CAR_RESERVATION_LIST'
);

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      filters: {
        reservationClass: null,
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: getAddedDate(new Date().setHours(23, 59, 59, 999), 7, 'days').getTime(),
        keywordType: 'licensePlateNumber',
        keyword: null
      },
      pageInfo: {
        page: 1,
        limit: PAGE_LIMIT
      },
      sortInfo: {
        field: 'startTime',
        order: 'asc'
      }
    }
  },
  summary: {
    data: {}
  }
};

const selectorTestCarReservationSearchPage = (state) => state.testCarReservation.list;
const selectorMyBusiness = (state) => state.authentication.myBusiness.data;

export const entitySummaryTestCarReservations = createEntity({
  key: summaryTestCarReservations().type,
  afterFailureFn: () => {}
});

export const entityAllTestCarReservationList = createEntity({
  key: getAllTestCarReservationList().type,
  parseData: (action) => parseDataForList(action, 'reservationItems'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  },
  *afterSuccessFn() {
    const selectorListPage = yield select(selectorTestCarReservationSearchPage);
    const {
      pager: { filters }
    } = selectorListPage;
    yield put(summaryTestCarReservations(filters));
  }
});

export const testCarReservation = combineReducers({
  list: createReducer({
    entity: entityAllTestCarReservationList,
    reducer: handleActions(
      {
        [initializeAllTestCarReservationList().type]: () => initializeState.list
      },
      {},
      { produce }
    )
  }),
  summary: createReducer({
    entity: entitySummaryTestCarReservations
  })
});
