export const SUPPORTED_COUNTRY = $utils.isRu
  ? 'RUS,KOR'
  : 'AUT,BEL,BGR,CYP,CZE,DEU,DNK,EST,ESP,FIN,FRA,GBR,GRC,HRV,HUN,IRL,ISL,ITA,LIE,LTU,LUX,LBY,MLT,NLD,NOR,POL,PRT,ROU,SWE,SVN,SVK,KOR';

/** FMS 지원 언어들 */
export const SUPPORTED_LANGUAGES = $utils.isRu
  ? ['en', 'ru', 'ko']
  : ['en', 'ko', 'it', 'es', 'pl', 'sk', 'fr', 'be', 'cz', 'de', 'hu', 'nl', 'se'];
/** FMS 기본 언어 */
export const DEFAULT_USED_LANGUAGE = process.env.FMS_DEFAULT_LANG || 'ko';
/** FMS 언어 선택창 지원 플래그 */
export const IS_SUPPORTED_LANGUAGE_SELECT = SUPPORTED_LANGUAGES.length > 0;
/** FMS - CCSP Login Type */
export const CCSP_LOGIN_TYPE = [
  'FMS_CCSP_SYSTEM_CLIENT_ID', // MOCEAN.
  'FMS_CCSP_CORP_CLIENT_ID' // FMS User.(Rentcar, Private company employee)
];

export const PAGE_LIMIT = 15;
export const PAGE_LIMIT_TEN = 10;
