import { takeLatest } from 'redux-saga/effects';
import { createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { getMonitoringdataListAPI } from 'v2/apis/monitoringdataApi';
import { getMonitoringdataList, entityMonitoringdataList } from '.';

const getMonitoringDataListAsync = createSagaAction.bind(
  null,
  entityMonitoringdataList,
  getMonitoringdataListAPI
);

export default function* monitoringDataWatcher() {
  yield takeLatest(
    getMonitoringdataList().type,
    fetchSaga,
    getMonitoringDataListAsync,
    entityMonitoringdataList
  );
}
