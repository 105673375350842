import request from './request';

const prefix = '/code';

export const getCodeListAPI = () => {
  return request({
    url: prefix,
    data: {
      All: true,
      ReqGroup: true,
      ReqItem: true
    }
  });
};

export const getCodeListWithGroupKeyAPI = (groupKeyList) => {
  return Promise.all(
    groupKeyList.map((key) => {
      return request({
        url: `${prefix}/usage`,
        data: {
          group: key
        }
      });
    })
  );
};

export const createCodeAPI = (data) => {
  return request({
    url: prefix,
    method: 'post',
    data
  });
};

export const updateCodeAPI = (ID, data) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'patch',
    data
  });
};

export const deleteCodeAPI = (ID) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
};
