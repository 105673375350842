import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeVehicleVariableCostPage,
  getVehicleVariableCostList,
  saveVariableCost,
  changeVariableCostState,
  initializeOpenModal,
  changeOpenModal
} = createActions(
  {
    GET_VEHICLE_VARIABLE_COST_LIST: (filters) => {
      return { filters };
    },
    SAVE_VARIABLE_COST: (data) => {
      return { data };
    }
  },
  'INITIALIZE_VEHICLE_VARIABLE_COST_PAGE',
  'CHANGE_VARIABLE_COST_STATE',
  'INITIALIZE_OPEN_MODAL',
  'CHANGE_OPEN_MODAL'
);
/** ********************************************** */

/** ********************************************** */

function parseVariableCostList(action) {
  const list = parseDataForList(action, 'variableCostItems');
  const vehicleId = action?.response?.data?.vehicleId;
  return { ...list, vehicleId };
}

/** sagas action 정의: async(request, success, failure) */
export const entityVehicleVariableCostList = createEntity({
  key: getVehicleVariableCostList().type,
  parseData: parseVariableCostList,
  afterFailureFn: () => {
    // FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      filters: {}
    },
    vehicleId: ''
  },
  openModal: {
    isCloseModal: false
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const variableCostListPageReducer = handleActions(
  {
    [initializeVehicleVariableCostPage().type]: () => initializeState.list,
    [changeVariableCostState().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const variableCost = combineReducers({
  list: createReducer({
    entity: entityVehicleVariableCostList,
    reducer: variableCostListPageReducer
  }),
  openModal: handleActions(
    {
      [initializeOpenModal().type]: () => initializeState.openModal,
      [changeOpenModal().type]: (state, { payload }) => {
        return {
          ...state,
          ...payload
        };
      }
    },
    initializeState.openModal,
    { produce }
  )
});
/** ********************************************** */
