export const makeCode = (data) => {
  const makeData = {
    name: 'Code.RootName',
    toggled: true,
    depth: 0
  };
  if (data) {
    for (const item of data) {
      if (item.parentID) {
        const node = findNode(makeData.children, item.parentID);
        if (node) {
          if (!node.children) {
            node.children = [];
          }
          node.children.push({
            name: item.name,
            data: item,
            depth: node.depth + 1
          });
        }
      } else {
        if (!makeData.children) {
          makeData.children = [];
        }
        makeData.children.push({
          name: item.name,
          data: item,
          depth: 1
        });
      }
    }
  }
  return makeData;
};

export const findNode = (children, parentID, result) => {
  if (children) {
    for (const child of children) {
      if (child.data.ID === parentID) {
        return child;
      }

      result = findNode(child.children, parentID) || result;
    }
  }
  return result;
};

export const getMultiCodesList = (data) => {
  const resultData = data.reduce((acc, cur) => {
    const current = { ...cur };
    const groupKey = current.config && current.config.params && current.config.params.group;

    if (current.data && current.data.length) {
      const labeledCodeList = current.data.map((item) => {
        const codeData = { ...item };
        codeData.label = item.msgKey;
        codeData.value = item.codeValue;

        return codeData;
      });
      current.data = [...labeledCodeList];
    }

    const codeList = current.data;

    if (groupKey && codeList && codeList.length) {
      acc[groupKey] = codeList;
    }

    return acc;
  }, {});

  return resultData;
};
