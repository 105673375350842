import { put, call, fork, take, race } from 'redux-saga/effects';
import {
  openModalConfirm,
  confirmYes,
  confirmNo,
  closeModalConfirm
} from '../modules/modals/confirmModal';
/**
 * ...pender({ 역할을 하는 실행 함수.
 *
 * @param {*} entity ./lib/actionLib entity type
 * @param {*} apiFn
 * @param {*} param { params } apiParams
 */
export function* createSagaAction(entity, apiFn, params) {
  if (!entity) return;

  try {
    if (entity.changeProgress) yield fork(entity.changeProgress, true);
    yield put(entity.request(params));
    let response = null;
    let error = null;
    try {
      response = yield call(apiFn, params);
      if (response) {
        if (entity.parseError) {
          error = entity.parseError(response);
        }
      } else {
        error = {};
      }
    } catch (e) {
      error = e;
    }
    if (entity.changeProgress) yield fork(entity.changeProgress, false);

    if (error) yield put(entity.failure(params, error));
    else yield put(entity.success(params, response));
  } catch (e) {
    console.error(e);
  }
}

export function* fetchSaga(entityAsyncSaga, entity, action, ...args) {
  try {
    // console.log('ddddddddddddddddddddddddddddddddddddddddddddddd');
    // businessSaga SubRoutin 실행
    yield fork(entityAsyncSaga, { ...action.payload, ...args });

    // entity async request action type(success, failure) 감지.
    const [, listPageSuccessType, listPageFailureType] = entity.types;
    const { success, failure } = yield race({
      success: take(listPageSuccessType),
      failure: take(listPageFailureType)
    });

    // onFailure -> afterFailureFn 실행
    if (failure && entity.afterFailureFn) {
      yield fork(entity.afterFailureFn, failure.error, failure.params);
    }
    // onSuccess -> afterSuccessFn 실행
    if (success && entity.afterSuccessFn) {
      yield fork(entity.afterSuccessFn, success.response?.data, {
        ...action.payload,
        ...args
      });
    }
    return { success, failure };
  } catch (e) {
    console.error(e);
  }
  return { failure: 'error' };
}

export function* fetchSagas(parsePayload, entityAsyncSaga, entity, action, ...args) {
  try {
    // console.log('>>>>>>>>>!!!>>>>>>>>>>>', action);
    const data = parsePayload ? parsePayload(action.payload) : action.payload;
    const successIds = [];
    const failureIds = [];

    const itemAction = {};
    const { afterFailureFn, afterSuccessFn, beforeFn, ...itemEntity } = entity;
    if (beforeFn) {
      const isContinue = yield call(beforeFn, data);
      if (!isContinue) return;
    }
    // console.log('>>>>>>>>>>>>!!!!data>>>>>>>>', data);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of data) {
      itemAction.payload = { ...item };
      const response = yield call(fetchSaga, entityAsyncSaga, itemEntity, itemAction, ...args);
      if (response.success) successIds.push(response.success);
      else failureIds.push(response.failure);
    }

    // onFailure -> afterFailureFn 실행
    if (successIds.length !== data.length) {
      if (afterFailureFn) yield fork(() => afterFailureFn([...failureIds]));
    } else if (afterSuccessFn) yield fork(() => afterSuccessFn([...successIds]));
  } catch (e) {
    console.error(e);
  }
}

export function* confirmFetchSaga(messageLabel, entityAsyncSaga, entity, action, ...args) {
  let isContinue = true;
  try {
    if (messageLabel) {
      yield put(openModalConfirm({ messageLabel }));
      const { yes, no } = yield race({
        yes: take(confirmYes().type),
        no: take(confirmNo().type)
      });
      yield put(closeModalConfirm());

      if (no) isContinue = false; // yield call(fetchSaga, entityAsyncSaga, entity, action, ...args);
      // } else {
      //   isContinue = true;
    }

    if (isContinue) {
      const data = action.payload;
      const { beforeFn } = entity;
      if (beforeFn) {
        const isContinue = yield call(beforeFn, data);
        if (!isContinue) return;
      }
      yield call(fetchSaga, entityAsyncSaga, entity, action, ...args);
    }
  } catch (e) {
    console.error(e);
  }
}

export function* confirmDelFetchSaga(messageLabel, entityAsyncSaga, entity, action, ...args) {
  let isContinue = true;
  try {
    if (messageLabel) {
      console.log('@@@@@@@', action);
      console.log(messageLabel);

      yield put(
        openModalConfirm({
          messageLabel,
          values: action.payload.IDArr.length || 0
        })
      );
      const { yes, no } = yield race({
        yes: take(confirmYes().type),
        no: take(confirmNo().type)
      });
      yield put(closeModalConfirm());

      if (no) isContinue = false; // yield call(fetchSaga, entityAsyncSaga, entity, action, ...args);
      // } else {
      //   isContinue = true;
    }

    if (isContinue) {
      const data = action.payload;
      const { beforeFn } = entity;
      if (beforeFn) {
        const isContinue = yield call(beforeFn, data);
        if (!isContinue) return;
      }
      yield call(fetchSaga, entityAsyncSaga, entity, action, ...args);
    }
  } catch (e) {
    console.error(e);
  }
}

export function* confirmFetchSagas(messageLabel, entityAsyncSaga, entity, action, ...args) {
  try {
    if (messageLabel) {
      yield put(openModalConfirm({ messageLabel }));
      const { yes } = yield race({
        yes: take(confirmYes().type),
        no: take(confirmNo().type)
      });
      yield put(closeModalConfirm());
      if (yes) yield call(fetchSagas, entityAsyncSaga, entity, action, ...args);
    } else {
      yield call(fetchSagas, entityAsyncSaga, entity, action, ...args);
    }
  } catch (e) {
    console.error(e);
  }
}

export function* moveAction(actionFn, args) {
  yield put(actionFn(args));
}
