import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';

import styled from 'styled-components';
import Select, { components } from 'react-select';

import { FMSColors } from 'service/common/designLib';
import FMSCheckbox from './FMSCheckbox';

const allOption = {
  label: <FormattedMessage id='Common.All' />,
  value: '*'
};
const Container = styled.div`
  width: 100%;
  height: 40px;
`;

const FMSSelect = styled(Select)`
  border-radius: 1px;
  font-size: 14px;
  line-height: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ::-ms-expand {
    display: none;
  }
`;

const MenuHeader = styled.div`
  border-bottom: 1px dashed #dddddd;
  margin: 0 10px;
  padding: 5px 0;
  color: #999999;
  font-size: 14px;
`;

const customStyles = {
  container: (base, state) => ({ ...base, zIndex: state.isFocused ? 999 : 0 }),
  control: (provided, { selectProps: { underline }, isDisabled }) => {
    return {
      ...provided,
      overflowY: 'hidden',
      borderRadius: '0px',
      border: underline ? '0px' : '1px solid #dddddd',
      borderBottom: '1px solid #dddddd',
      backgroundColor: '#ffffff',
      fontSize: '14px',
      lineHeight: '22px',
      backgroundImage: isDisabled ? '' : `url('${process.env.PUBLIC_URL}/assets/images/un.png')`,
      backgroundPosition: 'right 4px center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '25px',
      height: '40px',
      cursor: 'pointer',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none'
      }
    };
  },
  option: (provided, { isSelected }) => {
    return {
      ...provided,
      cursor: 'pointer',
      color: null,
      backgroundColor: null,
      ':hover': {
        color: FMSColors.primary,
        backgroundColor: '#fbfbfb'
      },
      ':active': {
        color: FMSColors.primary,
        backgroundColor: '#fbfbfb'
      }
    };
  },
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: '#fbfbfb',
      borderRadius: '18px',
      '&:not(:first-of-type)': {
        marginLeft: '10px'
      }
    };
  },
  multiValueLabel: (provided, { isDisabled }) => {
    return {
      ...provided,
      color: FMSColors.primary,
      padding: isDisabled ? '3px 10px' : '3px',
      paddingLeft: '10px'
    };
  },
  multiValueRemove: (provided, { isDisabled }) => {
    return {
      ...provided,
      display: isDisabled ? 'none' : 'inherit',
      color: '#848ca5',
      ':hover': {
        color: '#000000'
      }
    };
  }
};

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <MenuHeader>
        <FormattedMessage id='Common.Btn.MultiSelected' />
      </MenuHeader>
      <div style={{ height: '200px', overFlowY: 'auto' }}>{props.children}</div>
    </components.MenuList>
  );
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <FMSCheckbox defaultChecked={props.isSelected}>
          <label style={{ marginLeft: 10 }}>{props.label}</label>
        </FMSCheckbox>
      </components.Option>
    </div>
  );
};
const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }
  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
};
const MultiValue = (props) => {
  let labelToBeDisplayed = props.data.label;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = <FormattedMessage id='Common.Btn.AllSelect' />;
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const FMSSelectMulti = ({
  intl,
  options,
  isCodeValue,
  allowSelectAll,
  placeholder,
  value,
  valueOption,
  ...rest
}) => {
  const arOptions = options.map((item) => isCodeValue ? { ...item, label: <FormattedMessage id={item.label} /> } : item);
  const optionsArray = allowSelectAll ? [allOption, ...arOptions] : arOptions;

  let valueArray = null;
  if (!value || !value.length || value.length < 1) valueArray = valueOption || [];
  else if (value.length === arOptions.length) valueArray = optionsArray;
  // BUG FIX options 객체와 value 객체가 같아야 selected 가 설정됨.
  // value만 같아도 설정되도록 수정.
  // else valueArray = options.filter(item => value.includes(item));
  else valueArray = arOptions.filter((item) => value.includes(item.value));

  const customFilter = (option, searchText) => {
    if (!isCodeValue) {
      if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      }
      return false;
    }
    if (
      intl
        .formatMessage({ id: option.label.props.id })
        .toLowerCase()
        .includes(searchText.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <FMSSelect
        {...rest}
        isMulti
        closeMenuOnSelect={false}
        components={{
          MenuList,
          MultiValue,
          Option,
          ValueContainer,
          DropdownIndicator: null,
          IndicatorSeparator: null
        }}
        filterOption={customFilter}
        hideSelectedOptions={false}
        isClearable={false}
        options={optionsArray}
        placeholder={placeholder && <FormattedMessage id={placeholder} />}
        styles={customStyles}
        value={valueArray}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === allOption.value) {
              return rest.onChange(optionsArray, event);
            }
            let result = [];
            if (selected.length === options.length) {
              if (selected.includes(allOption)) {
                result = selected.filter((option) => option.value !== allOption.value);
              } else if (event.action === 'select-option') {
                result = optionsArray;
              }
              return rest.onChange(result, event);
            }
          }
          return rest.onChange(selected, event);
        }}
      />
    </Container>
  );
};

FMSSelectMulti.defaultProps = {
  options: [],
  style: {},
  className: '',
  placeholder: '',
  allOption: {
    label: <FormattedMessage id='Common.All' />,
    value: '*'
  },
  allowSelectAll: false
};

export default injectIntl(FMSSelectMulti);
