import { all } from 'redux-saga/effects';
// import modalsSaga from 'v2/redux/modules/modals/confirmModalSaga';
import authenticationSaga from 'v2/redux/modules/authentication/authenticationSaga';

import businessSaga from 'v2/redux/modules/business/businessSaga';
import accidentSaga from 'v2/redux/modules/operation/accident/accidentSaga';
import companySaga from 'v2/redux/modules/company/companySaga';
import branchSaga from 'v2/redux/modules/branch/branchSaga';
import companyUserSaga from 'v2/redux/modules/companyUser/companyUserSaga';
import chargeSaga from 'v2/redux/modules/charge/chargeSaga';
import locationSaga from 'v2/redux/modules/location/locationSaga';
import terminalSaga from 'v2/redux/modules/terminal/terminalSaga';
// import messageSaga from 'v2/redux/modules/message/messageSaga';
import programSaga from 'v2/redux/modules/program/programSaga';
import vehiclePurchaseSaga from 'v2/redux/modules/vehicle/purchase/vehiclePurchaseSaga';
import vehicleAccidentSaga from 'v2/redux/modules/vehicle/accident/vehicleAccidentSaga';
import vehicleBasicSaga from 'v2/redux/modules/vehicle/basic/vehicleBasicSaga';
import vehicleModelsSaga from 'v2/redux/modules/vehicle/models/vehicleModelsSaga';
import insuranceSaga from 'v2/redux/modules/insurance/insuranceSaga';
import businessMemberSaga from 'v2/redux/modules/businessMember/businessMemberSaga';
import businessCustomerSaga from 'v2/redux/modules/businessCustomer/businessCustomerSaga';
import memberSaga from 'v2/redux/modules/member/memberSaga';
import customerSaga from 'v2/redux/modules/customer/customerSaga';
import reservationSaga from 'v2/redux/modules/reservation/reservationSaga';
import menuSaga from 'v2/redux/modules/menu/menuSaga';
import menuProgramSaga from 'v2/redux/modules/menu/menuProgramSaga';
import newCodeSaga from 'v2/redux/modules/code/newCodeSaga';
import controlHistorySaga from 'v2/redux/modules/controlHistory/controlHistorySaga';
import licenseCheckHistorySaga from 'v2/redux/modules/licenseCheckHistory/licenseCheckHistorySaga';
import roleSaga from 'v2/redux/modules/role/roleSaga';
import paymentCalendarSaga from 'v2/redux/modules/paymentCalendar/paymentCalendarSaga';
import licenseSaga from 'v2/redux/modules/license/licenseSaga';
import locationLocalSaga from 'v2/redux/modules/locationLocal/locationLocalSaga';
import paymentSaga from 'v2/redux/modules/payment/paymentSaga';
import businessLicenseSaga from 'v2/redux/modules/businessLicense/businessLicenseSaga';
import reservationSearchSaga from 'v2/redux/modules/reservationSearch/reservationSearchSaga';
import newHolidaySaga from 'v2/redux/modules/holiday/newHolidaySaga';
import reservationStateSaga from 'v2/redux/modules/reservationState/reservationStateSaga';
import rentalSearchSaga from 'v2/redux/modules/rentalSearch/rentalSearchSaga';
import vehicleInsuranceSaga from 'v2/redux/modules/vehicleInsurance/vehicleInsuranceSaga';
import vehicleLogSaga from 'v2/redux/modules/vehicle/vehicleLog/vehicleLogSaga';
import testCarReservationSaga from 'v2/redux/modules/testCarReservation/testCarReservationSaga';
import testCarConfigSaga from 'v2/redux/modules/testCarConfig/testCarConfigSaga';
import violationSaga from 'v2/redux/modules/violation/violationSaga';
import vehicleRegistrationCardSaga from 'v2/redux/modules/vehicle/vehicleRegistrationCard/vehicleRegistrationCardSaga';
import vehicleMySalesSaga from 'v2/redux/modules/vehicle/mySales/vehicleMySalesSaga';
import vehicleSapSaga from 'v2/redux/modules/vehicle/vehicleSap/vehicleSapSaga';
import termsSaga from 'v2/redux/modules/terms/termsSaga';
import appVersionSaga from 'v2/redux/modules/appVersion/appVersionSaga';
/* 2020.11.17 leejw 추가 */
import monitoringdataSaga from 'v2/redux/modules/monitoringdata/monitoringdataSaga';
import alarmsManagementSaga from 'v2/redux/modules/alarms/alarmsManagementSaga.js';
import notificationSaga from 'v2/redux/modules/notification/notificationSaga';
import taxRateSaga from 'v2/redux/modules/taxRate/taxRateSaga';
import currencySaga from 'v2/redux/modules/currency/currencySaga';
import digitalContractSaga from 'v2/redux/modules/digitalContract/digitalContractSaga';
import pushSaga from 'v2/redux/modules/push/pushSaga';
import apiHistorySaga from 'v2/redux/modules/apiHistory/apiHistorySaga';

/* 2020.12.22 leejw 추가 */
import businessreportSaga from 'v2/redux/modules/businessreport/businessreportSaga';
import surveySaga from 'v2/redux/modules/survey/surveySaga';
/* 2021.01.07 쿠폰관리 추가 */
import couponSaga from 'v2/redux/modules/couponmanage/couponSaga';
/* 2021.04.30 딜러서포트 추가 */
import dealerSupportSaga from 'v2/redux/modules/dealersupport/dealerSupportSaga';
import accessoryInfoSaga from 'v2/redux/modules/accessory/accessoryInfoSaga';
import accessoryModelSaga from 'v2/redux/modules/accessory/accessoryModelSaga';
import accessoryCategorySaga from 'v2/redux/modules/accessory/accessoryCategorySaga';

import costitemSaga from 'v2/redux/modules/costitem/costitemSaga';
// 딜러그룹별 비용항목
import costitemBydealerSaga from 'v2/redux/modules/costitem/costitemByDealer/costitemBydealerSaga';
// 공지사항관리
import noticeSaga from 'v2/redux/modules/notice/noticeSaga';
// 차량 비용
import vehicleCostSaga from 'v2/redux/modules/vehicle/vehicleCost/vehicleCostSaga';
// 변동 비용
import variableCostSaga from 'v2/redux/modules/vehicle/variableCost/variableCostSaga';
/* T국가전개 추가 */
import countrySaga from 'v2/redux/modules/country/countrySaga';
/* 2021.06.11 StripeInvoice 추가 */
import stripeSaga from 'v2/redux/modules/stripeinvoice/stripeinvoiceSaga';
// 서비스 기록 조회
import serviceHistorySaga from 'v2/redux/modules/serviceHistory/serviceHistorySaga';
import vehicleRetiredWatcher from '../modules/vehicle/retired/vehicleRetiredSaga';

import dealerkpiSaga from 'v2/redux/modules/dealerKPI/dealerkpiSaga';

// TODO saga middleware watch
export default function* rootSaga() {
  yield all([
    accessoryInfoSaga(),
    accessoryModelSaga(),
    accessoryCategorySaga(),
    authenticationSaga(),
    businessSaga(),
    accidentSaga(),
    companySaga(),
    branchSaga(),
    companyUserSaga(),
    chargeSaga(),
    locationSaga(),
    terminalSaga(),
    // messageSaga(),
    programSaga(),
    vehiclePurchaseSaga(),
    vehicleBasicSaga(),
    vehicleAccidentSaga(),
    vehicleModelsSaga(),
    insuranceSaga(),
    businessMemberSaga(),
    businessCustomerSaga(),
    memberSaga(),
    customerSaga(),
    reservationSaga(),
    menuSaga(),
    menuProgramSaga(),
    newCodeSaga(),
    roleSaga(),
    controlHistorySaga(),
    licenseCheckHistorySaga(),
    paymentCalendarSaga(),
    licenseSaga(),
    locationLocalSaga(),
    paymentSaga(),
    businessLicenseSaga(),
    reservationSearchSaga(),
    newHolidaySaga(),
    reservationStateSaga(),
    rentalSearchSaga(),
    vehicleAccidentSaga(),
    vehicleInsuranceSaga(),
    vehicleLogSaga(),
    testCarReservationSaga(),
    testCarConfigSaga(),
    violationSaga(),
    vehicleRegistrationCardSaga(),
    vehicleMySalesSaga(),
    vehicleSapSaga(),
    termsSaga(),
    appVersionSaga(),
    monitoringdataSaga(),
    alarmsManagementSaga(),
    apiHistorySaga(),
    notificationSaga(),
    taxRateSaga(),
    currencySaga(),
    vehicleRetiredWatcher(),
    digitalContractSaga(),
    pushSaga(),
    businessreportSaga(),
    surveySaga(),
    couponSaga(),
    dealerSupportSaga(),
    costitemSaga(),
    costitemBydealerSaga(),
    noticeSaga(),
    vehicleCostSaga(),
    variableCostSaga(),
    countrySaga(),
    stripeSaga(),
    serviceHistorySaga(),
    dealerkpiSaga()
  ]);
}
