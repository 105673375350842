import request from 'apis/request';

const prefix = '/customer';

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/**
 * 이용자 리스트 조회
 * ex) /customer
 */
export const getBusinessCustomersAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, startDate, endDate,
    keywordType,
    keyword,
    ...data
  } = filters;

  if (keywordType && keywordType !== '' && keyword) data[keywordType] = keyword;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });

  // return new Promise((resolve, reject)=>{
  //   resolve({
  //     status: '200',
  //     data: {
  //       items: [{
  //         name: 'Temp',
  //         ID:'TEST_ID1',
  //         activationStatus :'PENDING_APPROVE',
  //         business:{
  //           ID: '4d1e7ba2-033c-494c-935f-2f53e43542c3'
  //         }
  //       },{
  //         name: 'Temp2',
  //         ID:'TEST_ID2',
  //         activationStatus :'PENDING_APPROVE',
  //         business:{
  //           ID: '4d1e7ba2-033c-494c-935f-2f53e43542c3'
  //         }
  //       }]
  //     }
  //   })
  // });
};

/**
 * 이용자 상세조회
 * ex) /customer/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const getBusinessCustomerAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });

/**
 * 이용자 승인
 * ex) /customer/ce4b7689-baa5-4cb6-ba34-dbc8468f943d/confirm
 */
export const confirmBusinessCustomerAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}/confirm`,
    method: 'put',
    data: { message: '' }
  });
};

/**
 * 이용자 일괄 승인
 * ex) /customer/b93ddfcc-56fc-4efe-9dbf-94e419b1001b/confirm
 */
export const confirmAllBusinessCustomerAPI = ({ IDArr, message }) => {
  return Promise.all(
    IDArr.map((ID) => {
      return request({
        url: `${prefix}/${ID}/confirm`,
        method: 'put',
        data: { message }
      });
    })
  );
};

/**
 * 이용자 반려
 * ex) /customer/ce4b7689-baa5-4cb6-ba34-dbc8468f943d/confirm
 */
export const denyBusinessCustomerAPI = ({ ID, data }) => request({
    url: `${prefix}/${ID}/deny`,
    method: 'put',
    data
  });

/**
 * 이용자 수정
 * ex) /customer/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const updateBusinessCustomerAPI = ({ ID, data }) => request({
    url: `${prefix}/${ID}`,
    method: 'put',
    data
  });

/**
 * 이용자 삭제
 * ex) /customer/b93ddfcc-56fc-4efe-9dbf-94e419b1001b
 */
export const deleteBusinessCustomerAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });

export const downloadBusinessCustomerAPI = (data) => {
  return request({
    url: '/customer/download',
    data,
    method: 'file'
  });
};
