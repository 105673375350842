import styled from 'styled-components';
import { FMSColors } from 'service/common/designLib';

import { FMSIconBtnClose } from 'components/ui/button/FMSIconBtn';

export const ConfirmMessageContainer = styled.div`
  padding: 20px;
  min-width: 340px;
  background-color: ${FMSColors.whiteSmoke};
  border-top: 1px solid ${FMSColors.greyLight};
  border-bottom: 1px solid ${FMSColors.greyLight};

  font-size: 16px;
  line-height: 1.38em;
  color: ${FMSColors.greyDark};
`;

export const ModalCloseTopIcon = styled(FMSIconBtnClose)`
  align: right;
`;
