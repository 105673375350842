import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getCouponListAPI,
  getCouponDetailAPI,
  createCouponAPI,
  updateCouponAPI,
  deleteCouponAPI,
  getAllCouponListAPI
} from 'v2/apis/couponApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  loadAllCoupon,
  getCouponList,
  deleteForCouponList,
  changeCouponDetailPage,
  getCouponDetail,
  createCoupon,
  updateCoupon,
  deleteCoupon,
  entityLoadAllCouponList,
  entityCouponList,
  entityCouponDetail
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/couponmanage';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorCouponListPage = (state) => state.coupon.list;
export const selectorCouponLoadAll = (state) => state.coupon.loadAll;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateCoupon = createEntity({
  key: createCoupon().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(changeCouponDetailPage({ mode: 'view' }));
    const selectorListPage = yield select(selectorCouponListPage);
    const { pager } = selectorListPage;
    const { ID } = payload;
    history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    if (ID) yield put(getCouponDetail(ID));
  }
});

// update for update page
export const entityUpdateCoupon = createEntity({
  key: updateCoupon().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { ID } = requestParam;
    if (ID) yield put(getCouponDetail(ID));
  }
});
// delete for detail page
export const entityDeleteCoupon = createEntity({
  key: deleteCoupon().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCouponListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForCouponList = createEntity({
  key: deleteForCouponList().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeCouponDetailPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCouponListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCouponList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorCouponListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCouponList(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createCouponAsync = createSagaAction.bind(null, entityCreateCoupon, createCouponAPI);
const updateCouponAsync = createSagaAction.bind(null, entityUpdateCoupon, updateCouponAPI);
const deleteCouponAsync = createSagaAction.bind(null, entityDeleteCoupon, deleteCouponAPI);
const deleteForCouponListAsync = createSagaAction.bind(
  null,
  entityDeleteForCouponList,
  deleteCouponAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getCouponListAsync = createSagaAction.bind(null, entityCouponList, getCouponListAPI);
const getCouponDetailAsync = createSagaAction.bind(null, entityCouponDetail, getCouponDetailAPI);
const loadAllCouponListAsync = createSagaAction.bind(
  null,
  entityLoadAllCouponList,
  getAllCouponListAPI
);

function* changeCouponPageSaga() {
  yield put(changeCouponDetailPage({ mode: 'view' }));
}
function* loadAllSaga() {
  const {
    pager: { filters }
  } = yield select(selectorCouponLoadAll);
  yield put(loadAllCoupon(filters));
}

/**
 * Watcher
 */
export default function* couponWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createCoupon().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createCouponAsync,
    entityCreateCoupon
  );
  yield takeLatest(
    updateCoupon().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateCouponAsync,
    entityUpdateCoupon
  );
  yield takeLatest(
    deleteCoupon().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteCouponAsync,
    entityDeleteCoupon
  );
  yield takeLatest(getCouponList().type, fetchSaga, getCouponListAsync, entityCouponList);
  yield takeLatest(getCouponDetail().type, fetchSaga, getCouponDetailAsync, entityCouponDetail);

  // deletes in list page
  yield takeLatest(
    deleteForCouponList().type,
    fetchSagas,
    null,
    deleteForCouponListAsync,
    entityDeleteForCouponList
  );

  // select business
  yield takeLatest(
    loadAllCoupon().type,
    fetchSaga,
    loadAllCouponListAsync,
    entityLoadAllCouponList
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityUpdateCoupon.types[1], changeCouponPageSaga);
  yield takeLatest(entityCreateCoupon.types[1], loadAllSaga);
  yield takeLatest(entityUpdateCoupon.types[1], loadAllSaga);
  yield takeLatest(entityDeleteCoupon.types[1], loadAllSaga);
}
