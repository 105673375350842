import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
  addMaintenanceVehicleAPI,
  addVehicleInfoAPI,
  addVehicleKpmsInfoAPI,
  checkPlateNumberDuplicateAPI,
  deleteMaintenanceVehicleAPI,
  deleteVehicleInfoAPI,
  editMaintenanceVehicleAPI,
  editVehicleInfoAPI,
  getMaintenanceAvailableVehicleListAPI,
  getMaintenanceVehicleListAPI,
  getRearrangementVehicleListAPI,
  getVehicleBasicDetailAPI,
  getVehicleListAPI,
  retireVehicleInfoAPI,
  saveMoveBranchAPI,
  saveRearrangementHourAPI
} from 'v2/apis/vehicleApi';

import { confirmFetchSaga, confirmFetchSagas, createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import history from 'service/common/history';
import { getResultVehicleControlAPI, requestVehicleControlAPI } from 'v2/apis/vehicleControlApi';
import { getErrorMessage } from 'constants/errors';
import moment from 'moment/moment';
import {
  addMaintenanceVehicle,
  addVehicleInfo,
  addVehicleKpmsInfo,
  changeVehiclesListPage,
  checkPlateNumberDuplicate,
  controlVehicleDoorClose,
  controlVehicleDoorOpen,
  controlVehicleHorn,
  deleteForVehicles,
  deleteMaintenanceVehicle,
  deleteVehicleInfo,
  editMaintenanceVehicle,
  editVehicleInfo,
  entityCheckPlateNumberDuplicate,
  entityControlVehicle,
  entityMaintenanceAvailableVehicleList,
  entityMaintenanceVehicleList,
  entityRearrangementVehicle,
  entityVehicleBasic,
  entityVehiclesList,
  getMaintenanceAvailableVehicleList,
  getMaintenanceVehicleList,
  getRearrangementVehicle,
  getVehicleBasic,
  getVehiclesList,
  initializeVehicleControl,
  retireVehicleInfo,
  saveMoveBranch,
  saveRearrangementHour
} from '.';
import { changeProgress } from '../../reservation';

/** ********************************************** */
/** constant */
export const historyUrl = '/vehicle';
export const mySalesHistoryUrl = '/vehicleSap';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorVehiclesPage = (state) => state.vehicleBasic.list;
export const selectorVehicleControl = (state) => state.vehicleBasic.control;
export const selectorVehicleRearrangementVehicleList = (state) =>
  state.vehicleBasic.rearrangementVehicleList;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
export const entityAddVehicleInfo = createEntity({
  key: addVehicleInfo().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn(payload, params) {
    const { vehicleID, code } = payload;

    if (code === 910103) {
      FMSCommon.toast.fail('VehicleBasic.VIN.Error');
      return;
    }
    FMSCommon.toast.success('Common.Created.Success');

    const { isMySales = false } = params;

    if (isMySales && isMySales.isMySales) {
      history.replace({ pathname: `${historyUrl}/${vehicleID}`, state: isMySales });
    } else {
      history.replace({
        pathname: `${historyUrl}/${vehicleID}`,
        state: { isMySales: params.isMySales || false }
      });
    }
    // const selectorListPage = yield select(selectorBranchListPage);
    // const { pager } = selectorListPage;
    // const { ID } = payload;
    // history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
  }
});

export const entityAddVehicleKpmsInfo = createEntity({
  key: addVehicleKpmsInfo().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn(payload, params) {
    const { vehicleID, code } = payload;

    if (code === 910103) {
      FMSCommon.toast.fail('VehicleBasic.VIN.Error');
      return;
    }
    FMSCommon.toast.success('Common.Created.Success');

    const { isMySales = false } = params;

    if (isMySales && isMySales.isMySales) {
      history.replace({ pathname: `${historyUrl}/${vehicleID}`, state: isMySales });
    } else {
      history.replace({
        pathname: `${historyUrl}/${vehicleID}`,
        state: { isMySales: params.isMySales || false }
      });
    }
  }
});

export const entityEditVehicleInfo = createEntity({
  key: editVehicleInfo().type,
  afterFailureFn: (payload) => {
    console.log('payload vehicle:', payload);
    if (payload && payload?.data?.errorCode)
      FMSCommon.toast.fail(getErrorMessage(payload.data.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  afterSuccessFn(payload, params) {
    FMSCommon.toast.success('Common.Updated.Success');
    // const selectorListPage = yield select(selectorBranchListPage);
    // const { pager } = selectorListPage;
    const { ID } = payload;
    const { isMySales = false } = params;

    if (isMySales && isMySales.isMySales) {
      history.replace({ pathname: `${historyUrl}/${ID}`, state: isMySales });
    } else {
      history.replace({
        pathname: `${historyUrl}/${ID}`,
        state: { isMySales: params.isMySales || false }
      });
    }
  }
});

export const entityDeleteVehicleInfo = createEntity({
  key: deleteVehicleInfo().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) {
      const details = payload.data?.details;
      if (details?.existedReservationKey !== undefined) {
        const startTime = moment(details.existedReservationStartTime).format('YYYY-MM-DD');
        const endTime = moment(details.existedReservationEndTime).format('YYYY-MM-DD');
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode), '', {
          br: `<br/>`,
          id: details.existedReservationKey,
          startTime,
          endTime
        });
      } else {
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
      }
    } else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  afterSuccessFn(payload, params) {
    FMSCommon.toast.success('Common.Deleted.Success');
    const { isMySales = false } = params;

    if (isMySales && isMySales.isMySales) {
      history.replace({ pathname: `${mySalesHistoryUrl}`, state: isMySales });
    } else {
      history.replace({ pathname: `${historyUrl}` });
    }
  }
});

export const entityRetireVehicleInfo = createEntity({
  key: retireVehicleInfo().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) {
      const details = payload.data?.details;
      if (details?.existedReservationKey !== undefined) {
        const startTime = moment(details.existedReservationStartTime).format('YYYY-MM-DD');
        const endTime = moment(details.existedReservationEndTime).format('YYYY-MM-DD');
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode), '', {
          br: `<br/>`,
          id: details.existedReservationKey,
          startTime,
          endTime
        });
      } else {
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
      }
    } else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  afterSuccessFn(payload, params) {
    FMSCommon.toast.success('ERROR_CODE_SUCCESS');
    const { isMySales = false } = params;

    if (isMySales && isMySales.isMySales) {
      history.replace({ pathname: `${mySalesHistoryUrl}`, state: isMySales });
    } else {
      history.replace({ pathname: `${historyUrl}` });
    }
  }
});

// mulitple delete for list page.
export const entityDeleteForVehicles = createEntity({
  key: deleteForVehicles().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (!isContinue) {
      FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    } else {
      yield put(changeVehiclesListPage({ deleteVehiclesState: 'pending' }));
    }
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    yield put(changeVehiclesListPage({ deleteVehiclesState: 'deleted' }));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    yield put(changeVehiclesListPage({ deleteVehiclesState: 'deleteFailed' }));
  }
});

// 차량 재정비 시간 저장
export const entitySaveRearrangementHour = createEntity({
  key: saveRearrangementHour().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
    const selector = yield select(selectorVehicleRearrangementVehicleList);
    const {
      pager: { filters }
    } = selector;
    yield put(getRearrangementVehicle(filters));
  }
});

// 차량 브랜치 이동
export const entitySaveMoveBranch = createEntity({
  key: saveMoveBranch().type,
  *afterFailureFn(payload) {
    yield put(changeVehiclesListPage({ deleteVehiclesState: 'failMoveBranch' }));
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Updated.Success');
    yield put(changeVehiclesListPage({ deleteVehiclesState: 'moveBranch' }));
  }
});

// APP-23 차량정비 화면 start
export const entityAddMaintenanceVehicle = createEntity({
  key: addMaintenanceVehicle().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn(/* payload, params */) {
    FMSCommon.toast.success('Common.Created.Success');
  }
});

export const entityEditMaintenanceVehicle = createEntity({
  key: editMaintenanceVehicle().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn(/* payload, params */) {
    FMSCommon.toast.success('Common.Created.Success');
  }
});

export const entityDeleteMaintenanceVehicle = createEntity({
  key: deleteMaintenanceVehicle().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  afterSuccessFn(/* payload, params */) {
    FMSCommon.toast.success('Common.Deleted.Success');
    history.replace({ pathname: `${historyUrl}` });
  }
});
// APP-23 차량정비 화면 end

/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getVehiclesListAsync = createSagaAction.bind(null, entityVehiclesList, getVehicleListAPI);
const getVehicleBasicAsync = createSagaAction.bind(
  null,
  entityVehicleBasic,
  getVehicleBasicDetailAPI
);
const getRearrangementVehicleAsync = createSagaAction.bind(
  null,
  entityRearrangementVehicle,
  getRearrangementVehicleListAPI
);
const checkPlateNumberDuplicateAsync = createSagaAction.bind(
  null,
  entityCheckPlateNumberDuplicate,
  checkPlateNumberDuplicateAPI
);
const addVehicleInfoAsync = createSagaAction.bind(null, entityAddVehicleInfo, addVehicleInfoAPI);
const addVehicleKpmsInfoAsync = createSagaAction.bind(
  null,
  entityAddVehicleKpmsInfo,
  addVehicleKpmsInfoAPI
);
const editVehicleInfoAsync = createSagaAction.bind(null, entityEditVehicleInfo, editVehicleInfoAPI);
const deleteVehicleInfoAsync = createSagaAction.bind(
  null,
  entityDeleteVehicleInfo,
  deleteVehicleInfoAPI
);
const retireVehicleInfoAsync = createSagaAction.bind(
  null,
  entityRetireVehicleInfo,
  retireVehicleInfoAPI
);
const deleteForVehiclesAsync = createSagaAction.bind(
  null,
  entityDeleteForVehicles,
  deleteVehicleInfoAPI
);
const controlVehicleAsync = createSagaAction.bind(
  null,
  entityControlVehicle,
  requestVehicleControlAPI
);
const saveRearrangementHourAsync = createSagaAction.bind(
  null,
  entitySaveRearrangementHour,
  saveRearrangementHourAPI
);

// 차량 브랜치 이동
const saveMoveBranchAsync = createSagaAction.bind(null, entitySaveMoveBranch, saveMoveBranchAPI);

// APP-23 차량정비 화면 start
const getMaintenanceAvailableVehicleListAsync = createSagaAction.bind(
  null,
  entityMaintenanceAvailableVehicleList,
  getMaintenanceAvailableVehicleListAPI
);
const getMaintenanceVehicleListAsync = createSagaAction.bind(
  null,
  entityMaintenanceVehicleList,
  getMaintenanceVehicleListAPI
);
const addMaintenanceVehicleAsync = createSagaAction.bind(
  null,
  entityAddMaintenanceVehicle,
  addMaintenanceVehicleAPI
);
const editMaintenanceVehicleAsync = createSagaAction.bind(
  null,
  entityEditMaintenanceVehicle,
  editMaintenanceVehicleAPI
);
const deleteMaintenanceVehicleAsync = createSagaAction.bind(
  null,
  entityDeleteMaintenanceVehicle,
  deleteMaintenanceVehicleAPI
);

// APP-23 차량정비 화면 end

function* checkResultVehicleControl() {
  let retryTime = 0;
  let isContinue = true;

  const { data, action } = yield select(selectorVehicleControl) || { data: {} };
  const { tid, result } = data || {};
  const { code } = result || {};

  if (!tid || tid === '') {
    isContinue = false;
  }
  if (code < 2000 || code > 3000) {
    isContinue = false;
  }

  while (isContinue) {
    try {
      const { data } = yield call(getResultVehicleControlAPI, tid) || {
        data: {}
      };
      const { status } = data || {};

      if (status === 3) {
        if (action === 'rc.door.on') FMSCommon.toast.sucess('Vehicle.Control.Opened.Success');
        if (action === 'rc.door.off') FMSCommon.toast.sucess('Vehicle.Control.Closed.Success');
        if (action === 'rc.horn') FMSCommon.toast.sucess('Vehicle.Control.Siren.Success');

        isContinue = false;
      } else if (status === -1) {
        FMSCommon.toast.fail('Vehicle.Control.Fail');
        isContinue = false;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    if (retryTime < 11) {
      retryTime += 1;
      yield delay(3 * 1000);
    } else {
      isContinue = false;
    }
  }
  if (retryTime > 10) {
    FMSCommon.toast.fail('Vehicle.Control.Timeout');
  }

  // 최종 화면 정리.
  yield put(initializeVehicleControl());
}

/**
 * Watcher
 */
export default function* vehicleBasicWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(getVehiclesList().type, fetchSaga, getVehiclesListAsync, entityVehiclesList);
  yield takeLatest(getVehicleBasic().type, fetchSaga, getVehicleBasicAsync, entityVehicleBasic);
  yield takeLatest(
    checkPlateNumberDuplicate().type,
    fetchSaga,
    checkPlateNumberDuplicateAsync,
    entityCheckPlateNumberDuplicate
  );
  yield takeLatest(
    addVehicleInfo().type,
    confirmFetchSaga,
    'Common.Confirm.SAPCreate',
    addVehicleInfoAsync,
    entityAddVehicleInfo
  );
  yield takeLatest(
    addVehicleKpmsInfo().type,
    confirmFetchSaga,
    'Common.Confirm.SAPCreate',
    addVehicleKpmsInfoAsync,
    entityAddVehicleKpmsInfo
  );

  yield takeLatest(
    editVehicleInfo().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    editVehicleInfoAsync,
    entityEditVehicleInfo
  );
  yield takeLatest(
    deleteVehicleInfo().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteVehicleInfoAsync,
    entityDeleteVehicleInfo
  );
  yield takeLatest(
    retireVehicleInfo().type,
    confirmFetchSaga,
    'Common.Confirm.Retire',
    retireVehicleInfoAsync,
    entityRetireVehicleInfo
  );

  yield takeLatest(
    deleteForVehicles().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteForVehiclesAsync,
    entityDeleteForVehicles
  );
  yield takeLatest(
    controlVehicleDoorOpen().type,
    confirmFetchSaga,
    'Vehicle.Confirm.Control.Open',
    controlVehicleAsync,
    entityControlVehicle
  );
  yield takeLatest(
    controlVehicleDoorClose().type,
    confirmFetchSaga,
    'Vehicle.Confirm.Control.Close',
    controlVehicleAsync,
    entityControlVehicle
  );
  yield takeLatest(
    controlVehicleHorn().type,
    confirmFetchSaga,
    'Vehicle.Confirm.Control.Siren',
    controlVehicleAsync,
    entityControlVehicle
  );

  // 재정비시간 차량 조회
  yield takeLatest(
    getRearrangementVehicle().type,
    fetchSaga,
    getRearrangementVehicleAsync,
    entityRearrangementVehicle
  );
  // 차량 재정비 시간 저장
  yield takeLatest(
    saveRearrangementHour().type,
    fetchSaga,
    saveRearrangementHourAsync,
    entitySaveRearrangementHour
  );

  // 차량 브랜치이동
  yield takeLatest(saveMoveBranch().type, fetchSaga, saveMoveBranchAsync, entitySaveMoveBranch);

  // APP-23 차량정비 화면 start
  yield takeLatest(
    getMaintenanceAvailableVehicleList().type,
    fetchSaga,
    getMaintenanceAvailableVehicleListAsync,
    entityMaintenanceAvailableVehicleList
  );
  yield takeLatest(
    getMaintenanceVehicleList().type,
    fetchSaga,
    getMaintenanceVehicleListAsync,
    entityMaintenanceVehicleList
  );
  yield takeLatest(
    addMaintenanceVehicle().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    addMaintenanceVehicleAsync,
    entityAddMaintenanceVehicle
  );
  yield takeLatest(
    editMaintenanceVehicle().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    editMaintenanceVehicleAsync,
    entityEditMaintenanceVehicle
  );
  yield takeLatest(
    deleteMaintenanceVehicle().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteMaintenanceVehicleAsync,
    entityDeleteMaintenanceVehicle
  );
  // APP-23 차량정비 화면 end

  yield takeLatest(entityControlVehicle.types[1], checkResultVehicleControl);
}
