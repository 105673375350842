import React from 'react';
import './style.scss';

const withMapInstance = (WrappedComponent) => {
  const getMapUiLang = (language) => {
    let mapUiLang = 'en-US';
    if (language) {
      switch (language) {
        case 'it':
          mapUiLang = 'it-IT';
          break;
        case 'ru':
          mapUiLang = 'ru-RU';
          break;
        case 'es':
          mapUiLang = 'es-ES';
          break;
        case 'pl':
          mapUiLang = 'pl-PL';
          break;
        case 'fr':
          mapUiLang = 'fr-FR';
          break;
        default:
          mapUiLang = 'en-US';
          break;
      }
    }
    return mapUiLang;
  };

  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        platform: null,
        map: null,
        behavior: null,
        ui: null,
        mapUiLang: null,
        defaultLayers: null
      };
    }

    componentDidMount() {
      const { apiKey, lat, lng, level, language } = this.props;
      const platform = new window.H.service.Platform({
        apikey: apiKey
      });

      const defaultLayers = platform.createDefaultLayers();
      const container = document.getElementById('map');
      const options = {
        center: new window.H.geo.Point(lat, lng),
        zoom: level,
        engineType: window.H.map.render.RenderEngine.EngineType.P2D,
        pixelRatio: window.devicePixelRatio || 1
      };
      const hereMap = new window.H.Map(container, defaultLayers.raster.normal.map, options);
      window.addEventListener('resize', () => hereMap.getViewPort().resize());
      const mapEvents = new window.H.mapevents.MapEvents(hereMap);
      const behavior = new window.H.mapevents.Behavior(mapEvents);
      const mapUiLang = getMapUiLang(language);
      const ui = window.H.ui.UI.createDefault(hereMap, defaultLayers, mapUiLang);
      this.setState({ platform, defaultLayers, map: hereMap, behavior, ui, mapUiLang }); // 지도 생성 및 객체 리턴
    }

    componentDidUpdate() {
      if (!_.isEqual(getMapUiLang(this.props.language), this.state.mapUiLang)) {
        const ui = window.H.ui.UI.createDefault(
          this.state.map,
          this.state.defaultLayers,
          getMapUiLang(this.props.language)
        );

        this.setState((prevState) => {
          return {
            ...prevState,
            ui,
            mapUiLang: getMapUiLang(this.props.language)
          };
        });
      }
    }

    render() {
      const { children, ...restProps } = this.props;
      const { map, behavior, ui, mapUiLang, defaultLayers } = this.state;

      /* const chgMapUiLang = getMapUiLang(restProps.language);
      if (mapUiLang && mapUiLang !== chgMapUiLang) {
        const ui = window.H.ui.UI.createDefault(map, defaultLayers, chgMapUiLang);
        this.setState({ ui, mapUiLang: chgMapUiLang }); // 지도 생성 및 객체 리턴
      } */

      return (
        <div className='map-container'>
          <div className='map' id='map'>
            {map && (
              <WrappedComponent
                {...restProps}
                behavior={behavior}
                language={mapUiLang}
                map={map}
                ui={ui}
              >
                {React.Children.map(children, (child) => {
                  if (child) {
                    return (
                      <child.type
                        {...child.props}
                        behavior={behavior}
                        language={mapUiLang}
                        map={map}
                        ui={ui}
                      />
                    );
                  }
                  return child;
                })}
              </WrappedComponent>
            )}
          </div>
        </div>
      );
    }
  };
};

export default withMapInstance;
