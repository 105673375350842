import request from './request';

const prefix = '/monitoring';

/**
 * 차량 조회
 */
export const getVehicleAPI = (ID) => request({
    url: `${prefix}/vehicle/${ID}`
  });

/**
 * 차량 목록조회
 */
export const getVehicleListAPI = (data) => {
  // console.log(data);
  // if (true) return;

  return request({
    url: `${prefix}/vehicle`,
    data
  });
};

/**
 * 맵 기준 차량 목록조회
 */
export const getVehicleListByMapAPI = (data) => request({
    url: `${prefix}/vehicle/map`,
    data
  });

/**
 * 맵 기준 거점 목록조회
 */
export const getLocationListAPI = (data) => request({
    url: `${prefix}/station/map`,
    data
  });
