import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getVehicleModelsListAPI,
  getVehicleModelListAPI,
  getVehicleModelAPI,
  createVehicleModelAPI,
  updateVehicleModelAPI,
  updateVehicleModelImageAPI,
  deleteVehicleModelAPI
} from 'v2/apis/vehicleModelApi';

import { FMSCommon } from 'service/common/commonLib';
import history from 'service/common/history';
import { createEntity } from 'v2/redux/lib';
import {
  createSagaAction,
  fetchSaga,
  fetchSagas,
  confirmFetchSaga,
  confirmFetchSagas
} from 'v2/redux/sagas';
import { getErrorMessage } from 'constants/errors';
import {
  getVehicleModelsList,
  entityVehicleModelsList,
  getVehicleModelList,
  getVehicleModel,
  createVehicleModel,
  updateVehicleModel,
  updateVehicleModelImage,
  deleteVehicleModel,
  deleteForVehicleModelList,
  changeVehicleModelPage,
  entityVehicleModelList,
  entityVehicleModel,
  loadAllVehicleModel,
  entityLoadAllVehicleModel
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/vehicleModel';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorVehicleModelList = (state) => state.vehicleModels.list;
export const selectorVehicleModelListPage = (state) => state.vehicleModels.listPage;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
export const entityCreateVehicleModel = createEntity({
  key: createVehicleModel().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    // 상세 페이지 이동
    const { ID } = payload;
    // const { image } = requestParams;
    // if (ID && image) {
    // yield put(updateVehicleModelImage(ID, image, 'create'));
    // } else {
    const selectorListPage = yield select(selectorVehicleModelListPage);
    const { pager } = selectorListPage;
    if (ID) history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    if (ID) yield put(getVehicleModel(ID));
    // }
  }
});

// update for update page
export const entityUpdateVehicleModel = createEntity({
  key: updateVehicleModel().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { ID } = requestParams || null;
    // const { ID, image } = requestParams || null;
    // if (ID && image) yield put(updateVehicleModelImage(ID, image, 'update'));
    // else {
    if (ID) yield put(getVehicleModel(ID));
    if (ID) yield put(changeVehicleModelPage({ mode: 'view' }));
    // }
  }
});
// delete for detail page
export const entityDeleteVehicleModel = createEntity({
  key: deleteVehicleModel().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorVehicleModelListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// update for update image
export const entityUpdateVehicleModelImage = createEntity({
  key: updateVehicleModelImage().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    const { ID, mode } = requestParams;
    if (mode === 'create') {
      const selectorListPage = yield select(selectorVehicleModelListPage);
      const { pager } = selectorListPage;
      if (ID) history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    } else {
      if (ID) yield put(getVehicleModel(ID));
      if (ID) yield put(changeVehicleModelPage({ mode: 'view' }));
    }
  }
});

// mulitple delete for list page.
export const entityDeleteForVehicleModeles = createEntity({
  key: deleteForVehicleModelList().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeVehicleModelPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');

    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorVehicleModelListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getVehicleModelList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorVehicleModelListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getVehicleModelList(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getVehicleModelsListAsync = createSagaAction.bind(
  null,
  entityVehicleModelsList,
  getVehicleModelsListAPI
);
const loadAllVehicleModelAsync = createSagaAction.bind(
  null,
  entityLoadAllVehicleModel,
  getVehicleModelsListAPI
);
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createVehicleModelAsync = createSagaAction.bind(
  null,
  entityCreateVehicleModel,
  createVehicleModelAPI
);
const updateVehicleModelAsync = createSagaAction.bind(
  null,
  entityUpdateVehicleModel,
  updateVehicleModelAPI
);
const deleteVehicleModelAsync = createSagaAction.bind(
  null,
  entityDeleteVehicleModel,
  deleteVehicleModelAPI
);
const deleteForVehicleModelAsync = createSagaAction.bind(
  null,
  entityDeleteForVehicleModeles,
  deleteVehicleModelAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getVehicleModelListAsync = createSagaAction.bind(
  null,
  entityVehicleModelList,
  getVehicleModelListAPI
);
const getVehicleModelAsync = createSagaAction.bind(null, entityVehicleModel, getVehicleModelAPI);

const updateVehicleModelImageAsync = createSagaAction.bind(
  null,
  entityUpdateVehicleModelImage,
  updateVehicleModelImageAPI
);

/**
 * Watcher
 */
export default function* vehicleModelsWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    getVehicleModelsList().type,
    fetchSaga,
    getVehicleModelsListAsync,
    entityVehicleModelsList
  );
  yield takeLatest(
    loadAllVehicleModel().type,
    fetchSaga,
    loadAllVehicleModelAsync,
    entityLoadAllVehicleModel
  );
  yield takeLatest(
    createVehicleModel().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createVehicleModelAsync,
    entityCreateVehicleModel
  );
  yield takeLatest(
    updateVehicleModel().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateVehicleModelAsync,
    entityUpdateVehicleModel
  );
  yield takeLatest(
    deleteVehicleModel().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteVehicleModelAsync,
    entityDeleteVehicleModel
  );
  yield takeLatest(
    getVehicleModelList().type,
    fetchSaga,
    getVehicleModelListAsync,
    entityVehicleModelList
  );
  yield takeLatest(getVehicleModel().type, fetchSaga, getVehicleModelAsync, entityVehicleModel);
  yield takeLatest(
    updateVehicleModelImage().type,
    fetchSaga,
    updateVehicleModelImageAsync,
    entityUpdateVehicleModelImage
  );

  // deletes in list page
  yield takeLatest(
    deleteForVehicleModelList().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteForVehicleModelAsync,
    entityDeleteForVehicleModeles
  );
}
