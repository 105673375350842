import React from 'react';
import { FormattedMessage } from 'react-intl';

import FMSModal from 'components/ui/common/FMSModal';
import { CenterButtonContainer, FMSBtnModal } from 'components/ui/button/FMSBtn';
import { FMSIconBtnClose } from 'components/ui/button/FMSIconBtn';
import { ConfirmMessageContainer } from './styles/ModalStyles';

export const FMSConfirmModal = ({ isOpen = true, message, btnList, close }) => (
  <>
    <FMSModal customStyles={{ content: { width: 500, marginTop: 50 } }} isOpen={isOpen}>
      <FMSIconBtnClose style={{ padding: 0 }} onClick={close} />
      <ConfirmMessageContainer style={{ marginBottom: 20, marginTop: 25 }}>
        {message && message.label ? (
          <FormattedMessage id={message.label} values={{ ...message.values, ...{ br: <br /> } }} />
        ) : (
          ''
        )}
      </ConfirmMessageContainer>
      <CenterButtonContainer style={{ marginBottom: 10 }}>
        {btnList
          && btnList.map((btn) => (
            <FMSBtnModal
              key={btn.type}
              filled={btn.type !== 'Cancel'}
              type='button'
              onClick={btn.action}
            >
              <FormattedMessage id={btn.label} />
            </FMSBtnModal>
          ))}
      </CenterButtonContainer>
    </FMSModal>
  </>
);

export default FMSConfirmModal;
