// import request from 'apis/localRequest';
import request from 'apis/request';

const prefix = '/terms';

// const prefix = '/test/noth/termsList'

/** ************
 * 약관 리스트
 ************ */
export const getTermsListAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  return request({
    url: `${prefix}`,
    data
  });
};

/** ************
 * 약관 생성
 ************* */
export const createTermsAPI = ({ data, file }) => {
  data.version = parseInt(data.version, 10);
  data.orderNum = parseInt(data.orderNum, 10);

  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  })

  if (data?.contentType === 'PDF' && file) {
    formData.append('pdfFile', file);
  }

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}`,
    method: 'post',
    data: formData
  });
};

/**
 * 약관 업데이트
 */
export const updateTermsAPI = ({ ID, data, file }) => {
  data.version = parseInt(data.version, 10);
  data.orderNum = parseInt(data.orderNum, 10);

  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  })

  if (data?.contentType === 'PDF' && file) {
    if (_.isUndefined(file.url)) {
      formData.append('pdfFile', file);
    } else {
      //fileUrl이 존재하는 경우는 기존에 업로드 되어져 있는 파일인 경우이므로 file을 보낼 필요 없음
      formData.append('pdfFile', '');
    }
    formData.append('pdfID', file.uid);
  }

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}/${ID}`,
    method: 'patch',
    data: formData
  });
};

/**
 * 약관 삭제
 */
export const deleteTermsAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
};

/**
 * 파일 삭제
 */
export const deleteTermsFileAPI = ({ pdfID }) => {
  return request({
    url: `${prefix}/pdf/${pdfID}`,
    method: 'delete'
  });
};

/**
 * 약관 버전 업데이트
 */
export const updateTermsVersionAPI = ({ ID, data }) => {
  data.version = parseInt(data.termsVersion, 10);
  data.orderNum = parseInt(data.orderNum, 10);

  return request({
    url: `${prefix}/termsVersion/${ID}`,
    method: 'patch'
    // data,
  });
};

/**
 *
 * 약관 내용 조회
 */
export const getTermsAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/groupkey/${ID}`,
    data
  });
};
