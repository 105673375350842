import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** 
 * 액션 타입 정의 & action creator 정의
 * createActions(actionMap, ?identityActions, ?options) 
 * -> object mapping action types to action creators
*/
export const {
  initializeServiceHistoryListPage,
  getServiceHistoryList,
  initializeServiceHistoryDetailPage,
  getServiceHistoryDetail,
} = createActions(
  { // type
    GET_SERVICE_HISTORY_LIST: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    }),
    GET_SERVICE_HISTORY_DETAIL: (ID) => ({ ID })
  },
  'INITIALIZE_SERVICE_HISTORY_LIST_PAGE',
  'INITIALIZE_SERVICE_HISTORY_DETAIL_PAGE'
);
/** ********************************************** */

/**
 * sagas action
 */
export const entityServiceHistoryList = createEntity({
  key: getServiceHistoryList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityServiceHistoryDetail = createEntity({
  key: getServiceHistoryDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
})
/** ********************************************** */

/**
 * reducer
 */
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {
        startDate: new Date().setHours(0, 0, 0, 0) - 60 * 24 * 60 * 60 * 1000,
        endDate: new Date().setHours(23, 59, 59, 999),
      }
    }
  },
  pageInfo: {
    page: 1,
    limit: PAGE_LIMIT
  },
  sortInfo: {
    field: 'creatAt',
    order: 'desc'
  },
  detail: {
    data: null,
    loading: false,
    error: null,
    mode: 'view'
  }
};
/** ********************************************** */

const listPageReducer = handleActions(
  {
    [initializeServiceHistoryListPage().type]: () => initializeState.list
  },
  {},
  { produce }
);

const detailPageReducer = handleActions(
  {
    [initializeServiceHistoryDetailPage().type]: () => initializeState.detail,
  },
  {},
  { produce }
);

// page action reducer 선언
export const serviceHistory = combineReducers({
  list: createReducer({
    entity: entityServiceHistoryList,
    reducer: listPageReducer
  }),
  detail: createReducer({
    entity: entityServiceHistoryDetail,
    reducer: detailPageReducer
  }),
});