import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getAllReservationListAPI,
  getReservationDetailAPI,
  cancelReservationAPI,
  rejectReservationAPI,
  approveReservationAPI,
  updateReservationAPI,
  updateNewReservationAPI,
  // inUseUpdateReservationAPI,
  forceReturnUpdateReservationAPI,
  enterContractCancleAPI,
  requestTerminalReservationAPI,
  requestReturnTerminalReservationAPI,
  sendKaKaoTalkAPI,
  checkPasswordKaKaoTalkAPI,
  getBusinessKaKaoTalkAPI
} from 'v2/apis/reservationSearchApi';
import { updateChargeVehicleMileageKmAPI } from 'v2/apis/chargeApi';
import { changeRentalAPI, confirmRentalAPI, denyRentalAPI } from 'v2/apis/reservationApi';
import { createSagaAction, fetchSaga, confirmFetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import history from 'service/common/history';
import { FMSCommon } from 'service/common/commonLib';
import { changeHomeMenu } from 'v2/redux/modules/homeMenu';
import { getErrorMessage } from 'constants/errors';
import moment from 'moment/moment';
import React from 'react';
import {
  entityAllReservationList,
  getAllReservationList,
  entityReservationDetail,
  getReservationDetail,
  changeReservation,
  confirmReservation,
  denyReservation,
  cancelTestCar,
  cancelReservation,
  rejectReservation,
  approveReservation,
  updateReservation,
  updateNewReservation,
  inuseUpdateReservation,
  forceReturnUpdateReservation,
  enterContractCancle,
  requestTerminalReservation, // 단말기로 예약정보 전송.
  requestReturnTerminalReservation,
  sendKakaoTalk,
  checkPasswordKakaoTalk,
  getBusinessKakaoTalk,
  entityBusinessKakao,
  changeBusinessKakao,
  changeProgress,
  updateChargeVehicleMileageKm,
  setReservationDetail
} from '.';

export const historyUrl = '/reservation';
export const historyUrlStatus = '/rentStatus';
export const testCarUrl = '/testCarReservation';
export const selectorReservationSearchPage = (state) => state.reservationSearch.list;
export const selectorReservationSearchDetailPage = (state) => state.reservationSearch.detail;

export const entityCancelReservation = createEntity({
  key: cancelReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    const selectorListPage = yield select(selectorReservationSearchPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: historyUrl,
      state: { filters, pageInfo, sortInfo }
    });
  }
});

export const entityCancelTestCar = createEntity({
  key: cancelTestCar().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Reservation.Cancel.Complete');
    const selectorListPage = yield select(selectorReservationSearchPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: testCarUrl,
      state: { filters, pageInfo, sortInfo }
    });
  }
});

export const entityRejectReservation = createEntity({
  key: rejectReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
    const selectorListPage = yield select(selectorReservationSearchPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: historyUrl,
      state: { filters, pageInfo, sortInfo }
    });
  }
});

export const entityApproveReservation = createEntity({
  key: approveReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
    const selectorListPage = yield select(selectorReservationSearchPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: historyUrl,
      state: { filters, pageInfo, sortInfo }
    });
  }
});

export const entityChangeReservation = createEntity({
  key: changeReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(/* payload */) {
    FMSCommon.toast.success('Common.Updated.Success');
    const selectorListPage = yield select(selectorReservationSearchPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: historyUrl,
      state: { filters, pageInfo, sortInfo }
    });
  }
});

export const entityConfirmReservation = createEntity({
  key: confirmReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Reservation.Confirm.Complete');
    const selectorListPage = yield select(selectorReservationSearchPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    if (payload.vehicle && payload.vehicle.vehicleType !== 'TESTCAR') {
      history.push({
        pathname: historyUrl,
        state: { filters, pageInfo, sortInfo }
      });
    } else {
      history.push({
        pathname: testCarUrl,
        state: { filters, pageInfo, sortInfo }
      });
    }
  }
});

export const entityDenyReservation = createEntity({
  key: denyReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Reservation.Deny.Complete');
    const selectorListPage = yield select(selectorReservationSearchPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    if (payload.vehicle && payload.vehicle.vehicleType !== 'TESTCAR') {
      history.push({
        pathname: historyUrl,
        state: { filters, pageInfo, sortInfo }
      });
    } else {
      history.push({
        pathname: testCarUrl,
        state: { filters, pageInfo, sortInfo }
      });
    }
  }
});

export const entityUpdateReservation = createEntity({
  key: updateReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) {
      const details = payload.data?.details;
      if (details?.notReturnReservationKey !== undefined) {
        const startTime = moment(details.notReturnReservationStartTime).format('YYYY-MM-DD');
        const endTime = moment(details.notReturnReservationEndTime).format('YYYY-MM-DD');
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode), '', {
          br: <br />,
          id: details.notReturnReservationKey,
          startTime,
          endTime
        });
      } else {
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
      }
    } else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    if (payload.code && payload.code !== 0 && payload.code !== 2 && payload.code !== 3) {
      FMSCommon.toast.success(getErrorMessage(payload.code));
    } else {
      FMSCommon.toast.success('Reservation.Update.Complete');
      const selectorListPage = yield select(selectorReservationSearchPage);
      const { filters, pageInfo, sortInfo } = selectorListPage;
      if (requestParam.data.isTestCar === 'true') {
        history.push({
          pathname: `${testCarUrl}/${payload.ID}`,
          state: { filters, pageInfo, sortInfo }
        });
      } else {
        history.push({
          pathname: `${historyUrl}/${payload.ID}`,
          state: { filters, pageInfo, sortInfo }
        });
      }
    }
  }
});

export const entityUpdateNewReservation = createEntity({
  key: updateNewReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) {
      const details = payload.data?.details;
      if (details?.notReturnReservationKey !== undefined) {
        const startTime = moment(details.notReturnReservationStartTime).format('YYYY-MM-DD');
        const endTime = moment(details.notReturnReservationEndTime).format('YYYY-MM-DD');
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode), '', {
          br: <br />,
          id: details.notReturnReservationKey,
          startTime,
          endTime
        });
      } else {
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
      }
    } else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    if (payload.code && payload.code !== 0 && payload.code !== 2 && payload.code !== 3) {
      FMSCommon.toast.success(getErrorMessage(payload.code));
    } else {
      FMSCommon.toast.success('Reservation.Update.Complete');
      const selectorListPage = yield select(selectorReservationSearchPage);
      const { filters, pageInfo, sortInfo } = selectorListPage;
      if (requestParam.data.isTestCar === 'true') {
        history.push({
          pathname: `${testCarUrl}/${payload.ID}`,
          state: { filters, pageInfo, sortInfo }
        });
      } else {
        history.push({
          pathname: `${historyUrl}/${payload.ID}`,
          state: { filters, pageInfo, sortInfo }
        });
      }
    }
  }
});

export const entityInuseUpdateReservation = createEntity({
  key: inuseUpdateReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(/* payload */) {
    // console.log(`rtn:${JSON.stringify(payload)}`);
    FMSCommon.toast.success('Reservation.InUse.Complete');
    const selectorListPage = yield select(selectorReservationSearchPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: historyUrlStatus,
      state: { filters, pageInfo, sortInfo }
    });
    yield put(
      changeHomeMenu({
        pathname: historyUrlStatus,
        state: { filters, pageInfo, sortInfo }
      })
    );
    // history.push({ pathname: `${historyUrlStatus}/${payload.reservationID}`, state: { filters, pageInfo, sortInfo } });
    // yield put(changeHomeMenu({ pathname: `${historyUrlStatus}/${payload.reservationID}`, state: { filters, pageInfo, sortInfo } }));
  }
});

export const entityForceReturnUpdateReservation = createEntity({
  key: forceReturnUpdateReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    const selectorListPage = yield select(selectorReservationSearchPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: `${historyUrlStatus}/${payload.reservationID}`,
      state: { filters, pageInfo, sortInfo }
    });
    yield put(
      changeHomeMenu({
        pathname: `${historyUrlStatus}/${payload.reservationID}`,
        state: { filters, pageInfo, sortInfo }
      })
    );
  }
});

export const entityContractCancle = createEntity({
  key: enterContractCancle().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.BeContractCancle.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Reservation.Request.BeContractCancle.Success');
    const selectorListPage = yield select(selectorReservationSearchPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: historyUrlStatus,
      state: { filters, pageInfo, sortInfo }
    });
  }
});

export const entityRequestTerminalReservation = createEntity({
  key: enterContractCancle().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Reservation.Request.Terminal.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Reservation.Request.Terminal.Success');
  }
});

export const entityRequestReturnTerminalReservation = createEntity({
  key: enterContractCancle().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Reservation.Request.Terminal.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Reservation.Request.Terminal.Success');
  }
});

// 카카오톡 알림 전송
export const entitySendKakaoTalk = createEntity({
  key: sendKakaoTalk().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Send.Kakaotalk.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Send.Kakaotalk.Success');
  }
});

// 카카오톡 화면에서 비밀번호 확인
export const entityCheckPasswordKakaoTalk = createEntity({
  key: checkPasswordKakaoTalk().type,
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Send.Kakaotalk.Password.Fail');
    yield put(changeBusinessKakao({ mode: true }));
  },
  afterSuccessFn: (payload, requestParam) => {
    const { ID, data } = requestParam;
    window.location.href = `${process.env.FMS_CORE_API_ENDPOINT}/fms/bill/${ID}?birthDate=${data.birthDate}&reportType=${data.reportType}`;
  }
});

export const entityChargeVehicleMileageKm = createEntity({
  key: fp.pipe(updateChargeVehicleMileageKm, fp.get('type'))(),

  afterFailureFn: (payload) => {
    FMSCommon.toast.fail(
      fp.pipe(fp.get('errorCode'), fp.isEmpty)(payload)
        ? 'Common.Update.Fail'
        : fp.pipe(fp.get('errorCode'), getErrorMessage)(payload)
    );
  },

  *afterSuccessFn(payload, requestParams) {
    const composer = fp.pipe(
      fp.getOr(0, 'mileageChargeInuseStartKm'),
      fp.curry((path, val) => setReservationDetail(path, val))('data.mileageCharge.inUseKm'),
      (action) => put(action)
    );

    yield composer(requestParams);

    FMSCommon.toast.success('Common.Updated.Success');
  }
});

const getAllReservationListAsync = createSagaAction.bind(
  null,
  entityAllReservationList,
  getAllReservationListAPI
);
const getReservationDetailAsync = createSagaAction.bind(
  null,
  entityReservationDetail,
  getReservationDetailAPI
);
const cancelReservationAsync = createSagaAction.bind(
  null,
  entityCancelReservation,
  cancelReservationAPI
);
const cancelTestcarAsync = createSagaAction.bind(null, entityCancelTestCar, cancelReservationAPI);
const rejectReservationAsync = createSagaAction.bind(
  null,
  entityRejectReservation,
  rejectReservationAPI
);
const approveReservationAsync = createSagaAction.bind(
  null,
  entityApproveReservation,
  approveReservationAPI
);
const updateReservationAsync = createSagaAction.bind(
  null,
  entityUpdateReservation,
  updateReservationAPI
);
const updateNewReservationAsync = createSagaAction.bind(
  null,
  entityUpdateNewReservation,
  updateNewReservationAPI
);

const changeReservationAsync = createSagaAction.bind(
  null,
  entityChangeReservation,
  changeRentalAPI
);
const confirmReservationAsync = createSagaAction.bind(
  null,
  entityConfirmReservation,
  confirmRentalAPI
);
const denyReservationAsync = createSagaAction.bind(null, entityDenyReservation, denyRentalAPI);

const forceReturnUpdateReservationAsync = createSagaAction.bind(
  null,
  entityForceReturnUpdateReservation,
  forceReturnUpdateReservationAPI
);

const enterContractCancleAsync = createSagaAction.bind(
  null,
  entityContractCancle,
  enterContractCancleAPI
);
const requestTerminalReservationAsync = createSagaAction.bind(
  null,
  entityRequestTerminalReservation,
  requestTerminalReservationAPI
);
const requestReturnTerminalReservationAsync = createSagaAction.bind(
  null,
  entityRequestReturnTerminalReservation,
  requestReturnTerminalReservationAPI
);
const sendKakaotalkAsync = createSagaAction.bind(null, entitySendKakaoTalk, sendKaKaoTalkAPI);
const checkPasswordKakaotalkAsync = createSagaAction.bind(
  null,
  entityCheckPasswordKakaoTalk,
  checkPasswordKaKaoTalkAPI
);
const getBusinessKakaotalkAsync = createSagaAction.bind(
  null,
  entityBusinessKakao,
  getBusinessKaKaoTalkAPI
);

const updateChargeVehicleMileageKmAsync = createSagaAction.bind(
  null,
  entityChargeVehicleMileageKm,
  updateChargeVehicleMileageKmAPI
);

export default function* reservationSearch() {
  yield takeLatest(
    getAllReservationList().type,
    fetchSaga,
    getAllReservationListAsync,
    entityAllReservationList
  );

  yield takeLatest(
    getReservationDetail().type,
    fetchSaga,
    getReservationDetailAsync,
    entityReservationDetail
  );
  yield takeLatest(
    cancelReservation().type,
    confirmFetchSaga,
    'Common.Confirm.Reservation.Cancle',
    cancelReservationAsync,
    entityCancelReservation
  );
  yield takeLatest(
    cancelTestCar().type,
    confirmFetchSaga,
    'Common.Confirm.Reservation.Cancle',
    cancelTestcarAsync,
    entityCancelTestCar
  );
  yield takeLatest(
    rejectReservation().type,
    confirmFetchSaga,
    'Common.Confirm.Reservation.Reject',
    rejectReservationAsync,
    entityRejectReservation
  );
  yield takeLatest(
    approveReservation().type,
    confirmFetchSaga,
    'Common.Confirm.Reservation.Approve',
    approveReservationAsync,
    entityApproveReservation
  );
  yield takeLatest(
    updateReservation().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateReservationAsync,
    entityUpdateReservation
  );
  yield takeLatest(
    updateNewReservation().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateNewReservationAsync,
    entityUpdateNewReservation
  );
  yield takeLatest(
    changeReservation().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    changeReservationAsync,
    entityChangeReservation
  );
  yield takeLatest(
    confirmReservation().type,
    confirmFetchSaga,
    'Common.Confirm.Approve',
    confirmReservationAsync,
    entityConfirmReservation
  );
  yield takeLatest(
    denyReservation().type,
    confirmFetchSaga,
    'Common.Confirm.Deny',
    denyReservationAsync,
    entityDenyReservation
  );
  // yield takeLatest(inuseUpdateReservation().type, confirmFetchSaga, 'Common.Confirm.InUse', inuseUpdateReservationAsync, entityInuseUpdateReservation);
  yield takeLatest(
    forceReturnUpdateReservation().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    forceReturnUpdateReservationAsync,
    entityForceReturnUpdateReservation
  );
  yield takeLatest(
    enterContractCancle().type,
    confirmFetchSaga,
    null,
    enterContractCancleAsync,
    entityContractCancle
  );
  yield takeLatest(
    requestTerminalReservation().type,
    confirmFetchSaga,
    'Reservation.Request.Terminal.Confirm',
    requestTerminalReservationAsync,
    entityRequestTerminalReservation
  );
  yield takeLatest(
    requestReturnTerminalReservation().type,
    confirmFetchSaga,
    'Reservation.Request.ReturnTerminal.Confirm',
    requestReturnTerminalReservationAsync,
    entityRequestReturnTerminalReservation
  );
  yield takeLatest(
    sendKakaoTalk().type,
    confirmFetchSaga,
    null,
    sendKakaotalkAsync,
    entitySendKakaoTalk
  );
  yield takeLatest(
    checkPasswordKakaoTalk().type,
    confirmFetchSaga,
    null,
    checkPasswordKakaotalkAsync,
    entityCheckPasswordKakaoTalk
  );
  yield takeLatest(
    getBusinessKakaoTalk().type,
    fetchSaga,
    getBusinessKakaotalkAsync,
    entityBusinessKakao
  );

  yield takeLatest(
    updateChargeVehicleMileageKm().type,
    fetchSaga,
    updateChargeVehicleMileageKmAsync,
    entityChargeVehicleMileageKm
  );
}
