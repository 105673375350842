import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getHolidayListAPI,
  createHolidayAPI,
  getHolidayDetailAPI,
  updateHolidayAPI,
  deleteHolidayAPI
} from 'v2/apis/holidayApi';

import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import dateFnsFormat from 'date-fns/format';
import { getErrorMessage } from 'constants/errors';

import {
  getNewHolidayList,
  getNewHolidayDetail,
  createNewHoliday,
  updateNewHoliday,
  deleteNewHoliday,
  changeNewHolidayDetailState,
  entityHolidayList,
  entityHolidayDetail
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/newHoliday';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorHolidayListPage = (state) => state.newHoliday.holidayList;
/** ********************************************** */

const changeFormat = (param) => {
  const obj = {
    holidayYear: dateFnsFormat(param.holidayDate, 'YYYY'),
    holidayMonth: dateFnsFormat(param.holidayDate, 'MM'),
    businessID: param.businessID
  };
  return obj;
};

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateHoliday = createEntity({
  key: createNewHoliday().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(changeNewHolidayDetailState({ data: payload, mode: 'edit' }));
    yield put(getNewHolidayList(changeFormat(payload)));
  }
});

// update for update page
export const entityUpdateHoliday = createEntity({
  key: updateNewHoliday().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Updated.Success');
    yield put(changeNewHolidayDetailState({ mode: 'edit' }));
    yield put(getNewHolidayList(changeFormat(payload)));
  }
});
// delete for detail page
export const entityDeleteHoliday = createEntity({
  key: deleteNewHoliday().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Deleted.Success');
    yield put(changeNewHolidayDetailState({ mode: 'create' }));
    const selectorListPage = yield select(selectorHolidayListPage);
    const { pager } = selectorListPage;
    const filters = pager && pager.filters;
    yield put(
      getNewHolidayList({
        holidayYear: filters.holidayYear,
        holidayMonth: filters.holidayMonth,
        businessID: filters.businessID
      })
    );
    yield put(
      getNewHolidayDetail(dateFnsFormat(requestParam.day, 'YYYYMMDD'), {
        businessID: filters.businessID
      })
    );
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createHolidayAsync = createSagaAction.bind(null, entityCreateHoliday, createHolidayAPI);
const updateHolidayAsync = createSagaAction.bind(null, entityUpdateHoliday, updateHolidayAPI);
const deleteHolidayAsync = createSagaAction.bind(null, entityDeleteHoliday, deleteHolidayAPI);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getHolidayListAsync = createSagaAction.bind(null, entityHolidayList, getHolidayListAPI);
const getHolidayDetailAsync = createSagaAction.bind(null, entityHolidayDetail, getHolidayDetailAPI);
// const loadAllHolidayListAsync = createSagaAction.bind(null, entityLoadAllHolidayList, getAllBranchListAPI);

/**
 * Watcher
 */
export default function* newHolidayWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createNewHoliday().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createHolidayAsync,
    entityCreateHoliday
  );
  yield takeLatest(
    updateNewHoliday().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateHolidayAsync,
    entityUpdateHoliday
  );
  yield takeLatest(
    deleteNewHoliday().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteHolidayAsync,
    entityDeleteHoliday
  );
  yield takeLatest(getNewHolidayList().type, fetchSaga, getHolidayListAsync, entityHolidayList);
  yield takeLatest(
    getNewHolidayDetail().type,
    fetchSaga,
    getHolidayDetailAsync,
    entityHolidayDetail
  );
}
