import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { produce } from 'immer';

export const { initializeDealerSupportListPage, getDealerSupportList } = createActions(
  {
    GET_DEALER_SUPPORT_LIST: (filters) => ({ filters })
  },
  'INITIALIZE_DEALER_SUPPORT_LIST_PAGE'
);

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      filters: {
        countryID: null,
        businessID: null,
        branchID: null,
        startDate: null,
        endDate: null,
        role: null,
        isAll: null
      }
    }
  }
};

export const entityDealerSupportList = createEntity({
  key: getDealerSupportList().type,
  parseData: (action, dataField) => {
    const data = action && action.response && action.response.data && action.response.data;
    const fieldName = dataField || 'items';
    let resData = null;
    if (dataField === 'none') {
      resData = data;
    } else if (data && fieldName in data) {
      resData = data[fieldName];
    }
    const reqPager = action && action.params ? action.params : null;
    const resPageInfo = data ? data.pageInfo : null;
    const pageInfo = { ...(reqPager ? reqPager.pageInfo : null), ...(resPageInfo || null) };
    if (resData === null) {
      resData = [];
    }

    // 삭제 예정, label, value는 사용하지 마세요.
    const updatedList =
      resData
      && resData.map
      && resData.map((item) => {
        item.label = item.name || item.managerName;
        item.value = item.ID;
        return item;
      });

    if (updatedList.length > 0) {
      const sumRow = updatedList.reduce((acc, current) => {
        // ur
        const { ur } = current;
        if (acc.ur) {
          acc.ur += ur;
        } else {
          acc.ur = ur;
        }
        current.ur = Intl.NumberFormat('es').format(current.ur);

        // rented days
        const { rentedDays } = current;
        if (acc.rentedDays) {
          acc.rentedDays += rentedDays;
        } else {
          acc.rentedDays = rentedDays;
        }
        current.rentedDays = Intl.NumberFormat('es').format(current.rentedDays);

        // level achieved
        const { levelAchieved } = current;
        if (acc.levelAchieved) {
          acc.levelAchieved += levelAchieved;
        } else {
          acc.levelAchieved = levelAchieved;
        }
        current.levelAchieved =
          current.levelAchieved !== 0 ? Intl.NumberFormat('es').format(current.levelAchieved) : '-';

        // monthly support car
        const { monthlySupportCar } = current;
        if (acc.monthlySupportCar) {
          acc.monthlySupportCar += monthlySupportCar;
        } else {
          acc.monthlySupportCar = monthlySupportCar;
        }
        current.monthlySupportCar = Intl.NumberFormat('es').format(current.monthlySupportCar);

        return acc;
      }, {});

      sumRow.licensePlateNumber = '';
      sumRow.fuelType = '';
      sumRow.ur = Intl.NumberFormat('es').format(
        Math.round((sumRow.ur / updatedList.length) * 10) / 10
      );
      sumRow.rentedDays = Intl.NumberFormat('es').format(sumRow.rentedDays);
      sumRow.levelAchieved = Intl.NumberFormat('es').format(
        Math.round((sumRow.levelAchieved / updatedList.length) * 10) / 10
      );
      sumRow.monthlySupportCar = Intl.NumberFormat('es').format(sumRow.monthlySupportCar);
      updatedList.push(sumRow);
    }

    return { data: updatedList, pager: { ...reqPager, pageInfo } };
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const dealerSupportListPageReducer = handleActions(
  {
    [initializeDealerSupportListPage().type]: () => initializeState.list
  },
  {},
  { produce }
);

export const dealerSupport = combineReducers({
  list: createReducer({
    entity: entityDealerSupportList,
    reducer: dealerSupportListPageReducer
  })
});
