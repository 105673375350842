import request from 'apis/request';

const prefix = '/vehicle/accessory-svc/stock';

export const getAccessoryInfoListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const data = { ...filters };
  // const { field = 'createdAt', order = 'desc' } = sortInfo;

  const { page = 1, limit = 20 } = pageInfo;
  // const { ...data } = filters;

  data.pageNum = page;
  data.rowsPerPage = limit;
  delete data.field;
  delete data.order;
  delete data.limit;
  delete data.page;
  delete data.prevPage;
  delete data.nextPage;
  delete data.totalPage;
  delete data.totalRecord;

  return request({
    url: `${prefix}`,
    data
  });
};

export const getAccessoryInfoAPI = ({ ID, businessId, branchId }) => {
  return request({
    url: `${prefix}/${ID}`,
    data: {
      businessId,
      branchId
    }
  });
};

export const createAccessoryInfoAPI = (inputData) => {
  return request({
    url: `${prefix}`,
    method: 'post',
    data: inputData.data
  });
};

export const updateAccessoryInfoAPI = (inputData) => {
  return request({
    url: `${prefix}/id/${inputData.ID}`,
    method: 'patch',
    data: inputData.data
  });
};

export const deleteAccessoryInfoAPI = ({ id }) => {
  return request({
    url: `${prefix}/id/${id}`,
    method: 'delete'
  });
};
