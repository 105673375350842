import request from 'apis/request';

const prefix = '/vehicle/repair';

export const getVehicleAccidentListAPI = ({
  /* vehicleID, pageInfo = {}, */ filters = {},
  sortInfo = {}
}) => {
  const { status, defaultStatus, keywordType, keyword, startDate, endDate, ...data } = filters;
  const { field } = sortInfo;
  if (field === 'incidentDate' || field === 'startTime') data.sortField = 1;
  // data.page = pageInfo && pageInfo.page ? pageInfo.page : 1;
  // data.rowPerPage = pageInfo && pageInfo.limit ? pageInfo.limit : 20;

  // data.byVehicleID = vehicleID;

  return request({
    url: prefix,
    data
  });
};

/**
 * 사고 상세 내역 조회
 */
export const getVehicleAccidentDetailAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });
