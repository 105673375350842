import { call, put, select, takeLatest, delay, take, race, fork } from 'redux-saga/effects';

import { getVehicleMySalesListAPI, getVehicleListAPI } from 'v2/apis/vehicleApi';

import { createSagaAction, fetchSaga, confirmFetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import history from 'service/common/history';
import { requestVehicleControlAPI, getResultVehicleControlAPI } from 'v2/apis/vehicleControlApi';
import { getErrorMessage } from 'constants/errors';
import {
  getVehicleMySalesList,
  entityVehiclesMySalesList,
  entityRegList,
  getVehicleRegList
} from '.';

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getVehiclesMySalesListAsync = createSagaAction.bind(
  null,
  entityVehiclesMySalesList,
  getVehicleMySalesListAPI
);
const getVehiclesRegListAsync = createSagaAction.bind(null, entityRegList, getVehicleListAPI);

export function* fetchMySalesSaga(entityAsyncSaga, entity, action, ...args) {
  try {
    const params = {
      filters: {
        businessID: action.payload.filters.businessID
      },
      pageInfo: {
        limit: 1000000,
        page: 1
      }
    };

    yield put(getVehicleRegList({ loading: true }));
    const res = yield call(getVehicleListAPI, params);

    // yield put({type:getVehicleRegList().type,payload:res.data.items})

    // businessSaga SubRoutin 실행
    yield fork(entityAsyncSaga, { ...action.payload, ...args, regList: res.data.items });

    // entity async request action type(success, failure) 감지.
    const [, listPageSuccessType, listPageFailureType] = entity.types;
    const { success, failure } = yield race({
      success: take(listPageSuccessType),
      failure: take(listPageFailureType)
    });

    // onFailure -> afterFailureFn 실행
    if (failure && entity.afterFailureFn) {
      yield fork(entity.afterFailureFn, failure.error, failure.params);
    }
    // onSuccess -> afterSuccessFn 실행
    if (success && entity.afterSuccessFn) {
      yield fork(entity.afterSuccessFn, success.response.data.list, { ...action.payload, ...args });
    }
    return { success, failure };
  } catch (e) {
    console.error(e);
  }
  return { failure: 'error' };
}

/**
 * Watcher
 */
export default function* vehicleMySalesWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    getVehicleMySalesList().type,
    fetchMySalesSaga,
    getVehiclesMySalesListAsync,
    entityVehiclesMySalesList
  );
  // yield takeLatest(deleteTerminals().type, fetchSagas, null, deleteForTerminalsAsync, entityDeleteForTerminals);
  yield takeLatest(getVehicleRegList().type, fetchSaga, getVehiclesRegListAsync, entityRegList);
  // yield takeLatest(checkPlateNumberDuplicate().type, fetchSaga, checkPlateNumberDuplicateAsync, entityCheckPlateNumberDuplicate);
  // yield takeLatest(addVehicleInfo().type, confirmFetchSaga, 'Common.Confirm.Create', addVehicleInfoAsync, entityAddVehicleInfo);
  // yield takeLatest(editVehicleInfo().type, confirmFetchSaga, 'Common.Confirm.Update', editVehicleInfoAsync, entityEditVehicleInfo);
  // yield takeLatest(deleteVehicleInfo().type, confirmFetchSaga, 'Common.Confirm.Delete', deleteVehicleInfoAsync, entityDeleteVehicleInfo);

  // yield takeLatest(deleteForVehicles().type, fetchSagas, null, deleteForVehiclesAsync, entityDeleteForVehicles);
  // yield takeLatest(controlVehicleDoorOpen().type, confirmFetchSaga, 'Vehicle.Confirm.Control.Open', controlVehicleAsync, entityControlVehicle);
  // yield takeLatest(controlVehicleDoorClose().type, confirmFetchSaga, 'Vehicle.Confirm.Control.Close', controlVehicleAsync, entityControlVehicle);
  // yield takeLatest(controlVehicleHorn().type, confirmFetchSaga, 'Vehicle.Confirm.Control.Siren', controlVehicleAsync, entityControlVehicle);

  // yield takeLatest(entityControlVehicle.types[1], checkResultVehicleControl);
}
