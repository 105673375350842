import { select, put, takeLatest, getContext } from 'redux-saga/effects';
import {
  getAuthProfileAPI,
  updateProfileAPI,
  deleteAuthAPI,
  changePasswordAPI,
  deleteJoinAPI,
  getUserInfoAPI
} from 'v2/apis/authenticationApi';
import { createEntity } from 'v2/redux/lib';
import { createSagaAction, fetchSaga, confirmFetchSaga } from 'v2/redux/sagas';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';
import {
  initializeProfile,
  getProfile,
  entityProfile,
  logout,
  changeMyBusiness,
  changeMyBranch,
  deleteJoin,
  updatePassword,
  updateProfile,
  changeProfile,
  changeMyCountry,
  getUserInfo,
  entityUserInfo
} from '.';

/** ********************************************** */
/** constant */
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorProfile = (state) => state.authentication.profile;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// delete for detail page
export const entityLogout = createEntity({
  key: logout().type,
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Logout.Success');
    yield put(initializeProfile());
    const history = yield getContext('history');
    history?.push('/');
  }
});
export const entityDeleteJoin = createEntity({
  key: deleteJoin().type,
  afterSuccessFn: () => {
    window.location.href = '/';
  }
});
export const entityChangePassword = createEntity({
  key: updatePassword().type,
  afterSuccessFn: () => {
    FMSCommon.toast.success('MyPage.UpdatePwd.Success');
    window.location.href = '/mypage';
  },
  afterFailureFn: (payload) => {
    if (payload && payload.data && payload.data.errCode === '403') {
      // 403 기존 비밀번호 오류
      FMSCommon.toast.fail('MyPage.oldPwd.error');
    } else {
      FMSCommon.toast.fail('MyPage.UpdatePwd.Fail');
    }
  }
});
export const entityUpdateProfile = createEntity({
  key: updateProfile().type,
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
    yield put(getUserInfo());
    yield put(changeProfile({ mode: 'view' }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.data && payload.data.errorCode)
      FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  }
});

/** ********************************************** */

function* changeMyInfo() {
  const profile = yield select(selectorProfile);
  const {
    data: { business, branch, isTestRide, country }
  } = profile;

  if (country && country.ID) yield put(changeMyCountry({ data: { ...country } }));
  if (business && business.ID) yield put(changeMyBusiness({ data: { ...business, isTestRide } }));
  if (branch && branch.ID) yield put(changeMyBranch({ data: branch }));
}
/**
 * SubRoutin
 */
// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getProfileAsync = createSagaAction.bind(null, entityProfile, getAuthProfileAPI);
const updatePasswordAsync = createSagaAction.bind(null, entityChangePassword, changePasswordAPI);
const updateProfileAsync = createSagaAction.bind(null, entityUpdateProfile, updateProfileAPI);
const deleteJoinAsync = createSagaAction.bind(null, entityDeleteJoin, deleteJoinAPI);
const logoutAsync = createSagaAction.bind(null, entityLogout, deleteAuthAPI);
const getUserInfoAsync = createSagaAction.bind(null, entityUserInfo, getUserInfoAPI);

/**
 * Watcher
 */
export default function* businessWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(getProfile().type, fetchSaga, getProfileAsync, entityProfile);
  yield takeLatest(
    updateProfile().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateProfileAsync,
    entityUpdateProfile
  ); // 개인정보 변경
  yield takeLatest(
    updatePassword().type,
    confirmFetchSaga,
    'Common.Confirm.PwdChange',
    updatePasswordAsync,
    entityChangePassword
  ); // 비밀번호 변경
  yield takeLatest(
    deleteJoin().type,
    confirmFetchSaga,
    'Common.Confirm.UserDelete',
    deleteJoinAsync,
    entityDeleteJoin
  ); // 탈퇴

  yield takeLatest(logout().type, fetchSaga, logoutAsync, entityLogout);

  // profile 성공시 myBusiness, myBranch 설정
  yield takeLatest(entityProfile.types[1], changeMyInfo);
  // yield takeLatest(entityLogout.types[1], logoutRefresh);

  // 마이페이지 로그인 사용자 정보 조회
  yield takeLatest(getUserInfo().type, fetchSaga, getUserInfoAsync, entityUserInfo);
}
