import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getCustomersAPI,
  getCustomerAPI,
  confirmCustomerAPI,
  denyCustomerAPI,
  updateCustomerAPI,
  deleteCustomerAPI,
  getServiceCustomersAPI,
  getServiceCustomerAPI
} from 'v2/apis/customerApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  updateCustomer,
  deleteCustomer,
  changeCustomerPage,
  deleteForCustomers,
  getCustomer,
  getCustomers,
  entityCustomer,
  entityCustomers,
  getServiceCustomer,
  getServiceCustomers,
  entityServiceCustomer,
  entityServiceCustomers,
  denyCustomer,
  confirmCustomer
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/customer';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorCustomersPage = (state) => state.customer.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// update for update page
export const entityUpdateCustomer = createEntity({
  key: updateCustomer().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  afterSuccessFn: () => {
    FMSCommon.toast.success('Common.Updated.Success');
  }
});
// confirm for confirm page
export const entityConfirmCustomer = createEntity({
  key: confirmCustomer().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Customer.Approve.Fail');
  },
  afterSuccessFn: () => {
    FMSCommon.toast.success('Customer.Approved.Success');
  }
});
// confirm for confirm page
export const entityDenyCustomer = createEntity({
  key: denyCustomer().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Customer.Denied.Fail');
  },
  afterSuccessFn: () => {
    FMSCommon.toast.success('Customer.Denied.Success');
  }
});
// delete for detail page
export const entityDeleteCustomer = createEntity({
  key: deleteCustomer().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCustomersPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForCustomers = createEntity({
  key: deleteForCustomers().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeCustomerPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCustomersPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCustomers(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorCustomersPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCustomers(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const updateCustomerAsync = createSagaAction.bind(null, entityUpdateCustomer, updateCustomerAPI);
const confirmCustomerAsync = createSagaAction.bind(null, entityConfirmCustomer, confirmCustomerAPI);
const denyCustomerAsync = createSagaAction.bind(null, entityDenyCustomer, denyCustomerAPI);
const deleteCustomerAsync = createSagaAction.bind(null, entityDeleteCustomer, deleteCustomerAPI);
const deleteForCustomersAsync = createSagaAction.bind(
  null,
  entityDeleteForCustomers,
  deleteCustomerAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getCustomersAsync = createSagaAction.bind(null, entityCustomers, getCustomersAPI);
const getCustomerAsync = createSagaAction.bind(null, entityCustomer, getCustomerAPI);

const getServiceCustomersAsync = createSagaAction.bind(
  null,
  entityServiceCustomers,
  getServiceCustomersAPI
);
const getServiceCustomerAsync = createSagaAction.bind(
  null,
  entityServiceCustomer,
  getServiceCustomerAPI
);

function* changeCustomerPageSaga() {
  yield put(changeCustomerPage({ mode: 'view' }));
}

/**
 * Watcher
 */
export default function* memberWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    confirmCustomer().type,
    confirmFetchSaga,
    'Common.Confirm.Approve',
    confirmCustomerAsync,
    entityConfirmCustomer
  );
  yield takeLatest(
    denyCustomer().type,
    confirmFetchSaga,
    'Common.Confirm.Deny',
    denyCustomerAsync,
    entityDenyCustomer
  );
  yield takeLatest(
    updateCustomer().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateCustomerAsync,
    entityUpdateCustomer
  );
  yield takeLatest(
    deleteCustomer().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteCustomerAsync,
    entityDeleteCustomer
  );

  yield takeLatest(getCustomers().type, fetchSaga, getCustomersAsync, entityCustomers);
  yield takeLatest(getCustomer().type, fetchSaga, getCustomerAsync, entityCustomer);

  yield takeLatest(
    getServiceCustomers().type,
    fetchSaga,
    getServiceCustomersAsync,
    entityServiceCustomers
  );
  yield takeLatest(
    getServiceCustomer().type,
    fetchSaga,
    getServiceCustomerAsync,
    entityServiceCustomer
  );

  // deletes in list page
  yield takeLatest(
    deleteForCustomers().type,
    fetchSagas,
    null,
    deleteForCustomersAsync,
    entityDeleteForCustomers
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityUpdateCustomer.types[1], changeCustomerPageSaga);
}
