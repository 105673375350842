import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

export const FMSModalsContext = React.createContext({});

const Renderer = memo(({ component, ...rest }) => {
  console.log(component(rest));
  return component(rest);
}, []);

export const Root = memo(({ modals, container: Container = React.Fragment }) => {
  const [mountNode, setMountNode] = useState(undefined);

  useEffect(() => setMountNode(document.body));

  return mountNode
    ? ReactDOM.createPortal(
      <Container>
        {Object.keys(modals).map((key) => (
          <Renderer key={key} component={modals[key].modal} {...modals[key].modalProps} />
          ))}
      </Container>,
        document.body
      )
    : null;
});

export const FMSModalsProvider = ({ container, children }) => {
  const [modals, setModals] = useState({});
  const showModal = useCallback((key, modal, modalProps) => {
    return setModals((modals) => ({
      ...modals,
      [key]: { modal, modalProps }
    }));
  }, []);
  const hideModal = useCallback(
    (key) => setModals((modals) => {
        const newModals = { ...modals };
        delete newModals[key];
        return newModals;
      }),
    []
  );
  const contextValue = useMemo(() => ({ showModal, hideModal }), []);

  return (
    <FMSModalsContext.Provider value={contextValue}>
      <>
        {children}
        <Root container={container} modals={modals} />
      </>
    </FMSModalsContext.Provider>
  );
};
