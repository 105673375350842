import { combineReducers } from 'redux';

import { createActions, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

export const {
  initializeAllReservationList,
  getAllReservationList,
  changeReservationList,
  initializeReservationDetail,
  getReservationDetail,
  cancelReservation,
  rejectReservation,
  approveReservation,
  cancelTestCar,
  changeReservation,
  confirmReservation,
  denyReservation,
  changeReservationDetail,
  updateReservation,
  updateNewReservation,
  inuseUpdateReservation,
  forceReturnUpdateReservation,
  enterContractCancle,
  requestTerminalReservation,
  requestReturnTerminalReservation,
  sendKakaoTalk,
  checkPasswordKakaoTalk,
  getBusinessKakaoTalk,
  initializeBusinessKakao,
  changeBusinessKakao,
  initializeProgress,
  changeProgress,
  updateChargeVehicleMileageKm,
  setReservationDetail
} = createActions(
  {
    GET_ALL_RESERVATION_LIST: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    }),
    GET_RESERVATION_DETAIL: (ID) => ({ ID }),
    CANCEL_RESERVATION: (ID) => ({ ID }),
    REJECT_RESERVATION: (ID) => ({ ID }),
    APPROVE_RESERVATION: (ID) => ({ ID }),
    CANCEL_TEST_CAR: (ID) => ({ ID }),
    UPDATE_RESERVATION: (data) => ({ data }),
    UPDATE_NEW_RESERVATION: (data) => ({ data }),
    INUSE_UPDATE_RESERVATION: (data) => ({ data }),
    FORCE_RETURN_UPDATE_RESERVATION: (data) => ({ data }),
    ENTER_CONTRACT_CANCLE: (ID, data) => ({ ID, data }),
    CHANGE_RESERVATION: (ID, data) => ({ ID, data }),
    CONFIRM_RESERVATION: (ID, data) => ({ ID, data }),
    DENY_RESERVATION: (ID, data) => ({ ID, data }),
    REQUEST_TERMINAL_RESERVATION: (ID) => ({ ID }),
    REQUEST_RETURN_TERMINAL_RESERVATION: (ID) => ({ ID }),
    SEND_KAKAO_TALK: (ID, data) => ({ ID, data }),
    CHECK_PASSWORD_KAKAO_TALK: (ID, data) => ({ ID, data }),
    GET_BUSINESS_KAKAO_TALK: (ID) => ({ ID }),
    SET_RESERVATION_DETAIL: (path, value) => ({ path, value })
  },
  'INITIALIZE_ALL_RESERVATION_LIST',
  'CHANGE_RESERVATION_LIST',
  'INITIALIZE_RESERVATION_DETAIL',
  'CHANGE_RESERVATION_DETAIL',
  'INITIALIZE_BUSINESS_KAKAO',
  'CHANGE_BUSINESS_KAKAO',
  'INITIALIZE_PROGRESS',
  'CHANGE_PROGRESS',
  'UPDATE_CHARGE_VEHICLE_MILEAGE_KM'
);

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      filters: {
        reservationClass: null,
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: new Date().setHours(23, 59, 59, 999) + 30 * 24 * 60 * 60 * 1000,
        keywordType: 'licensePlateNumber',
        keyword: null
      },
      pageInfo: {
        page: 1,
        limit: PAGE_LIMIT
      },
      sortInfo: {
        field: 'creatAt',
        order: 'desc'
      }
    }
  },
  detail: {
    data: {},
    loading: false,
    mode: null,
    pageStatus: null
  },
  kakaoBusiness: {
    data: {},
    mode: false
  },
  progress: {
    progressLoading: false
  }
};

export const entityAllReservationList = createEntity({
  key: getAllReservationList().type,
  parseData: (action) => parseDataForList(action, 'reservationItems'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const allReservationListReducer = handleActions(
  {
    [initializeAllReservationList().type]: () => initializeState.list,
    [changeReservationList().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);

export const entityReservationDetail = createEntity({
  key: getReservationDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const getReservationDetailReducer = handleActions(
  {
    [initializeReservationDetail().type]: () => initializeState.detail,
    [changeReservationDetail().type]: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    [setReservationDetail().type]: (state, { payload: { path, value } }) =>
      fp.set(path, value, state)
  },
  {},
  { produce }
);

export const entityBusinessKakao = createEntity({
  key: getBusinessKakaoTalk().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const getBusinessKakaoReducer = handleActions(
  {
    [initializeBusinessKakao().type]: () => initializeState.kakaoBusiness,
    [changeBusinessKakao().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);

export const reservationSearch = combineReducers({
  list: createReducer({
    entity: entityAllReservationList,
    reducer: allReservationListReducer
  }),
  detail: createReducer({
    entity: entityReservationDetail,
    reducer: getReservationDetailReducer
  }),
  kakaoBusiness: createReducer({
    entity: entityBusinessKakao,
    reducer: getBusinessKakaoReducer
  }),
  progress: handleActions(
    {
      [initializeProgress().type]: () => initializeState.progress,
      [changeProgress().type]: (state, { payload }) => ({
        ...state,
        ...payload
      })
    },
    initializeState.progress,
    { produce }
  )
});
