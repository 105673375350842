import request from 'apis/request';
import { addDays, addMonths, startOfMonth } from 'date-fns';

const prefix = '/reservation';

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
  }
}

/**
 * 일별 대여스케쥴 리스트 조회
 * ex) /reservation/vehices/usage
 */
export const getReservationVehiclesUsageAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, startTime, endTime,
    reservationClass = 'DAY',
    keywordType,
    keyword,
    baseTime,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  // data.sortOrder = (order === 'desc') ? 2 : 1;
  // data.sortField = getSortFieldNum(field);
  if (keywordType && keyword) data[keywordType] = keyword;
  const baseDate = baseTime && baseTime > 0 ? new Date(baseTime) : new Date();
  baseDate.setHours(0, 0, 0, 0);
  if (reservationClass === 'DAY') {
    data.startDate = baseDate.getTime();
    data.endDate = addDays(new Date(data.startDate), 1).getTime() - 1;
  } else {
    data.startDate = startOfMonth(baseDate).getTime();
    data.endDate = addMonths(new Date(data.startDate), 1).getTime() - 1;
  }

  return request({
    url: `${prefix}/vehicles/usage`,
    data
  });
};

/**
 * 일별 대여스케쥴 상태별 Count
 * ex) /monitoring/vehicle/count
 */
export const getReservationStateVehiclesCountAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, startTime, endTime,
    reservationClass = 'DAY',
    keywordType,
    keyword,
    baseTime,
    ...data
  } = filters;

  // backend API 에서 미지원
  // if (keywordType && keyword) data[keywordType] = keyword;
  // const baseDate = baseTime && baseTime > 1582775249471 ? new Date(baseTime) : new Date();
  // baseDate.setHours(0, 0, 0, 0);
  // data.startTime = baseDate.getTime();
  // data.endTime = addDays(new Date(data.startTime), 1).getTime() - 1;

  return request({
    url: '/monitoring/vehicle/count',
    data
  });
};

/**
 * 대여스케쥴 대여구분(장기/단기/보험대차) Count
 * ex) /reservation/count
 */
export const getReservationStateCountAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, startTime, endTime,
    reservationClass = 'MONTH',
    keywordType,
    keyword,
    baseTime,
    ...data
  } = filters;

  if (keywordType && keyword) data[keywordType] = keyword;
  const baseDate = baseTime && baseTime > 1582775249471 ? new Date(baseTime) : new Date();
  baseDate.setHours(0, 0, 0, 0);
  if (reservationClass === 'MONTH') {
    data.startTime = startOfMonth(baseDate).getTime();
    data.endTime = addMonths(new Date(data.startTime), 1).getTime() - 1;
  } else {
    data.startTime = baseDate.getTime();
    data.endTime = addDays(new Date(data.startTime), 1).getTime() - 1;
  }

  return request({
    url: '/reservation/count',
    data
  });
};
