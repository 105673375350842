import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT, PAGE_LIMIT_TEN } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  updateRoleAclList,
  initializeRoleUserList,
  getRoleUserList,
  initializeLoadAllRoleList,
  loadAllRoleList,
  initializeRoleListPage,
  getRoleList,
  deleteForRoleList,
  initializeRolePage,
  changeRolePage,
  getRole,
  createRole,
  updateRole,
  deleteRole
} = createActions(
  {
    UPDATE_ROLE_ACL_LIST: (ID, data) => ({ ID, data }),
    LOAD_ALL_ROLE_LIST: (filters) => ({ filters }),
    GET_ROLE_USER_LIST: (pageInfo, sortInfo, filters, roleID) => ({
      pageInfo,
      sortInfo,
      filters,
      roleID
    }),
    GET_ROLE_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_ROLE: (ID) => ({ ID }),
    CREATE_ROLE: (data) => ({ data }),
    UPDATE_ROLE: (ID, data) => ({ ID, data }),
    DELETE_ROLE: (ID) => ({ ID })
  },
  'INITIALIZE_ROLE_USER_LIST',
  'INITIALIZE_LOAD_ALL_ROLE_LIST',
  'INITIALIZE_ROLE_LIST_PAGE',
  'CHANGE_ROLE_LIST_PAGE',
  'DELETE_FOR_ROLE_LIST',
  'INITIALIZE_ROLE_PAGE',
  'CHANGE_ROLE_PAGE'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllRoleList = createEntity({
  key: loadAllRoleList().type,
  parseData: parseDataForList
});

export const entityRoleList = createEntity({
  key: getRoleList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityRole = createEntity({
  key: getRole().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

// 역할별 사용자 목록
export const entityRoleUserList = createEntity({
  key: getRoleUserList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    },
    error: null
  },
  detail: {
    selectedRoleID: null,
    selectedRole: null,
    data: {
      roleInfo: null,
      subMenus: null
    },
    loading: false,
    mode: 'view',
    aclMode: 'view',
    error: null
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    },
    error: null
  },
  userList: {
    data: null,
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT_TEN, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    },
    error: null
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const listPageReducer = handleActions(
  {
    [initializeRoleListPage().type]: () => initializeState.list
  },
  {},
  { produce }
);
const listLoadAllReducer = handleActions(
  {
    [initializeLoadAllRoleList().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const detailPageReducer = handleActions(
  {
    [initializeRolePage().type]: () => initializeState.detail,
    [changeRolePage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
// 역할별 사용자
const userListReducer = handleActions(
  {
    [initializeRoleUserList().type]: () => initializeState.userList
  },
  {},
  { produce }
);

// page action reducer 선언
export const role = combineReducers({
  list: createReducer({
    entity: entityRoleList,
    reducer: listPageReducer
  }),
  detail: createReducer({
    entity: entityRole,
    reducer: detailPageReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllRoleList,
    reducer: listLoadAllReducer
  }),
  userList: createReducer({
    entity: entityRoleUserList,
    reducer: userListReducer
  })
});
/** ********************************************** */
