import request from 'apis/request';

const prefix = '/vehicle/insurance';

export const getVehicleInsuranceMonthAPI = ({ filters, pageInfo }) => {
  const { keyword, keywordType, paymentStatus, ...data } = filters;
  if (keyword) {
    data[keywordType] = keyword;
  }
  if (paymentStatus) {
    data.paymentStatus = paymentStatus;
  }
  const { page, limit } = pageInfo;
  data.page = page;
  data.rowPerPage = limit;
  return request({
    url: `${prefix}/month`,
    data
  });
};

export const deleteVehicleInsuranceMonthAPI = ({ ID }) => request({
    url: `${prefix}/month/${ID}`,
    method: 'delete'
  });

export const getDetailVehicleInsuranceAPI = ({ ID, insureYear }) => {
  return request({
    url: `${prefix}/${ID}`,
    data: {
      insureYear
    }
  });
};

export const getInsurancePaymentAPI = ({ ID, Year }) => {
  return request({
    url: `${prefix}/${ID}/${Year}/month`
  });
};

export const createVehicleInsuranceMonthAPI = (formData) => {
  return request({
    url: prefix,
    method: 'post',
    data: {
      ...formData
    }
  });
};

export const updateVehicleInsuranceAPI = (formData) => {
  return request({
    url: `${prefix}/${formData.vehicleID}/${formData.insureYear}`,
    method: 'patch',
    data: {
      ...formData
    }
  });
};

export const updateInsurancePaymentAPI = (data) => {
  return request({
    method: 'patch',
    url: `${prefix}/month/${data.ID}`,
    data
  });
};

export const getVehicleInsurancePaymentDetailAPI = ({ ID }) => {
  return request({
    url: `${prefix}/month/${ID}`
  });
};

export const updateInsurancePaymentForVehicleAPI = ({ data }) => {
  return request({
    method: 'patch',
    url: `${prefix}/month/${data.ID}`,
    data
  });
};
