import { put, call, select, takeLatest } from 'redux-saga/effects';

import {
  getRoleListAPI,
  getRoleAPI,
  createRoleAPI,
  updateRoleAPI,
  deleteRoleAPI,
  getAdminListAPI,
  updateAccessListAPI
} from 'v2/apis/roleApi';

import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  createRole,
  updateRole,
  deleteRole,
  getRole,
  changeRolePage,
  entityRole,
  getRoleList,
  entityRoleList,
  loadAllRoleList,
  entityLoadAllRoleList,
  getRoleUserList,
  entityRoleUserList,
  updateRoleAclList
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/role';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorRoleListPage = (state) => state.role.list;
export const selectorRoleAllPage = (state) => state.role.loadAll;
export const selectorRoleDetailPage = (state) => state.role.detail;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateRole = createEntity({
  key: createRole().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Role.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    const { ID } = payload;
    const {
      data: { roleInfo: selectedRole }
    } = yield call(getRoleAPI, { ID });
    yield put(changeRolePage({ selectedRole, mode: 'view' }));
  }
});

// update for update page
export const entityUpdateRole = createEntity({
  key: updateRole().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Role.Update.Fail');
  },
  afterSuccessFn: () => {
    FMSCommon.toast.success('Common.Updated.Success');
  }
});
// delete for detail page
export const entityDeleteRole = createEntity({
  key: deleteRole().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
  }
});
// acl update
export const entityUpdateRoleAclList = createEntity({
  key: updateRoleAclList().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, params) {
    FMSCommon.toast.success('Common.Updated.Success');
    yield put(getRole(params.ID));
    yield put(changeRolePage({ aclMode: 'view' }));
  }
});

/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createRoleAsync = createSagaAction.bind(null, entityCreateRole, createRoleAPI);
const updateRoleAsync = createSagaAction.bind(null, entityUpdateRole, updateRoleAPI);
const deleteRoleAsync = createSagaAction.bind(null, entityDeleteRole, deleteRoleAPI);
// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getRoleListAsync = createSagaAction.bind(null, entityRoleList, getRoleListAPI);
const getRoleAsync = createSagaAction.bind(null, entityRole, getRoleAPI);
const loadAllRoleListAsync = createSagaAction.bind(null, entityLoadAllRoleList, getRoleListAPI);

// 역할별 사용자 목록
const getRoleUserListAsync = createSagaAction.bind(null, entityRoleUserList, getAdminListAPI);
// 역할별 접근권한
const updateRoleAclListAsync = createSagaAction.bind(
  null,
  entityUpdateRoleAclList,
  updateAccessListAPI
);

function* changeRolePageSaga() {
  yield put(loadAllRoleList());
}

function* changeRoleDetailSaga() {
  const { data: roleList } = yield select(selectorRoleAllPage);
  const { selectedRole } = yield select(selectorRoleDetailPage);

  if (roleList && roleList.find && selectedRole) {
    const newSelectedRole = roleList.find((item) => item.ID === selectedRole.ID);
    yield put(changeRolePage({ mode: 'view', selectedRole: newSelectedRole }));
  }
}

/**
 * Watcher
 */
export default function* businessWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createRole().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createRoleAsync,
    entityCreateRole
  );
  yield takeLatest(
    updateRole().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateRoleAsync,
    entityUpdateRole
  );
  yield takeLatest(
    deleteRole().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteRoleAsync,
    entityDeleteRole
  );
  yield takeLatest(getRoleList().type, fetchSaga, getRoleListAsync, entityRoleList);
  yield takeLatest(getRole().type, fetchSaga, getRoleAsync, entityRole);

  // 역할별 사용자 목록
  yield takeLatest(getRoleUserList().type, fetchSaga, getRoleUserListAsync, entityRoleUserList);
  // 역할별 접근권한
  yield takeLatest(
    updateRoleAclList().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateRoleAclListAsync,
    entityUpdateRoleAclList
  );

  // role list select container
  yield takeLatest(loadAllRoleList().type, fetchSaga, loadAllRoleListAsync, entityLoadAllRoleList);

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityCreateRole.types[1], changeRolePageSaga);
  yield takeLatest(entityUpdateRole.types[1], changeRolePageSaga);
  yield takeLatest(entityDeleteRole.types[1], changeRolePageSaga);
  yield takeLatest(entityLoadAllRoleList.types[1], changeRoleDetailSaga);
}
