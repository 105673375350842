import { lazy } from 'react';

const ReactLazyPreload = (importStatement) => {
  const Component = lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

// code split 위해 반드시 아래 패턴 유지
export const LoginPage = ReactLazyPreload(() => import('v2/containers/main/login/LoginPage'));
export const RedirectPage = ReactLazyPreload(() => import('v2/containers/main/login/RedirectPage'));
export const ProfileDetailPage = ReactLazyPreload(() =>
  import('v2/pages/mypage/ProfileDetailPage')
);
export const MyBusinessPage = ReactLazyPreload(() => import('v2/pages/mypage/MyBusinessPage'));
export const MyBranchPage = ReactLazyPreload(() => import('v2/pages/mypage/MyBranchPage'));

// 차량 관리
// export const VehicleMonitoringPage = ReactLazyPreload(() => import('./vehicle/monitoring/VehicleMonitoringPage'));
export const VehicleListPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleInfo/VehicleListPage')
);
export const VehicleDetailPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleInfo/VehicleDetailPage')
);

export const VehicleMySalesPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleMySales/VehicleMySalesListPage')
);
export const VehicleMySalesCreatePage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleMySales/VehicleMySalesCreatePage')
);
// export const VehicleCreatePage = ReactLazyPreload(() => import('v2/pages/vehicle/vehicleInfo/createAndedit/VehicleCreatePage'));

export const VehicleSapPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleSap/VehicleSapListPage')
);
export const VehicleSapCreatePage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleSap/VehicleSapCreatePage')
);

export const VehicleRegistrationCardImageCreatePage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleInfo/basic/VehicleRegistrationCardImageCreatePage')
);
export const VehicleRegistrationCardCreatePage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleInfo/basic/VehicleRegistrationCardCreatePage')
);
export const VehicleFormPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleInfo/basic/VehicleFormPage')
);

export const VehicleKpmsFormPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleInfo/basic/VehicleKpmsFormPage')
);
export const VehicleEditPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleInfo/createAndedit/VehicleEditPage')
);
export const VehicleModelListPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleModel/VehicleModelListPage')
);
export const VehicleModelDetailPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleModel/VehicleModelDetailPage')
);
export const VehicleRetiredListPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleRetired/VehicleRetiredListPage')
);
export const VehicleModelCreatePage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleModel/VehicleModelDetailPage')
);
export const AccidentListPage = ReactLazyPreload(() =>
  import('v2/pages/operation/accident/AccidentListPage')
);
export const AccidentCreatePage = ReactLazyPreload(() =>
  import('v2/pages/operation/accident/AccidentCreatePage')
);
export const AccidentEditPage = ReactLazyPreload(() =>
  import('v2/pages/operation/accident/AccidentEditPage')
);

// 예약 관리
export const ReservationListPage = ReactLazyPreload(() =>
  import('v2/pages/rent/reservation/ReservationListPage')
);
export const ReservationDetailPage = ReactLazyPreload(() =>
  import('v2/pages/rent/reservation/ReservationDetailPage')
);
export const RentStatusListPage = ReactLazyPreload(() =>
  import('v2/pages/rent/status/RentStatusListPage')
);
export const RentStatusDetailPage = ReactLazyPreload(() =>
  import('v2/pages/rent/status/RentStatusDetailPage')
);
export const TestCarConfigListPage = ReactLazyPreload(() =>
  import('v2/pages/rent/testCarConfig/TestCarConfigListPage')
);

export const ShortRentalPage = ReactLazyPreload(() =>
  import('v2/pages/rent/short/ShortRentalPage')
);
export const InsuranceRentalPage = ReactLazyPreload(() =>
  import('v2/pages/rent/insuranceRental/InsuranceRentalPage')
);
export const TestRentalCreatePage = ReactLazyPreload(() =>
  import('v2/pages/rent/testRental/TestRentalCreatePage')
);

// 거점 관리
export const LocationListPage = ReactLazyPreload(() =>
  import('v2/pages/location/LocationListPage')
);
export const LocationDetailPage = ReactLazyPreload(() =>
  import('v2/pages/location/LocationDetailPage')
);
export const LocationFormPage = ReactLazyPreload(() =>
  import('v2/pages/location/LocationFormPage')
);
export const LocationQueryPage = ReactLazyPreload(() =>
  import('v2/pages/location/LocationQueryPage')
);

// 관리자 관리
export const MemberListPage = ReactLazyPreload(() => import('v2/pages/user/member/MemberListPage'));
export const MemberDetailPage = ReactLazyPreload(() =>
  import('v2/pages/user/member/MemberDetailPage')
);
// 알람 조회
export const NotificationListPage = ReactLazyPreload(() =>
  import('v2/pages/user/notification/NotificationListPage')
);

// 세율 조회
export const TaxRateListPage = ReactLazyPreload(() =>
  import('v2/pages/user/taxRate/TaxRateListPage')
);

// 환율
export const CurrencyListPage = ReactLazyPreload(() =>
  import('v2/pages/currency/CurrencyListPage')
);

// 전자계약관리
export const DigitalContractListPage = ReactLazyPreload(() =>
  import('v2/pages/user/digitalContract/DigitalContractListPage')
);

// apiHistory
export const ApiHistoryListPage = ReactLazyPreload(() =>
  import('v2/pages/system/apiHistory/ApiHistoryListPage')
);
// 사업자 관리
export const BusinessListPage = ReactLazyPreload(() =>
  import('v2/pages/user/business/BusinessListPage')
);
export const BusinessDetailPage = ReactLazyPreload(() =>
  import('v2/pages/user/business/BusinessDetailPage')
);
// 관리자(사업자 관리자) 관리
export const BusinessMemberListPage = ReactLazyPreload(() =>
  import('v2/pages/user/member/BusinessMemberListPage')
);
export const BusinessMemberDetailPage = ReactLazyPreload(() =>
  import('v2/pages/user/member/BusinessMemberDetailPage')
);
// 고객 관리
export const CustomerListPage = ReactLazyPreload(() =>
  import('v2/pages/user/customer/CustomerListPage')
);
// 서비스 고객 관리
export const CustomerDetailPage = ReactLazyPreload(() =>
  import('v2/pages/user/customer/CustomerDetailPage')
);
export const ServiceCustomerDetailPage = ReactLazyPreload(() =>
  import('v2/pages/user/serviceCustomer/ServiceCustomerDetailPage')
);
export const ServiceCustomerListPage = ReactLazyPreload(() =>
  import('v2/pages/user/serviceCustomer/ServiceCustomerListPage')
);
export const BusinessCustomerListPage = ReactLazyPreload(() =>
  import('v2/pages/user/customer/BusinessCustomerListPage')
);
export const BusinessCustomerDetailPage = ReactLazyPreload(() =>
  import('v2/pages/user/customer/BusinessCustomerDetailPage')
);

// 매출/정산 관리
export const PaymentCalendarPage = ReactLazyPreload(() =>
  import('v2/pages/payment/paymentCalendar/PaymentCalendarPage')
);
export const PaymentListPage = ReactLazyPreload(() =>
  import('v2/pages/payment/payment/PaymentListPage')
);
export const PaymentDetailPage = ReactLazyPreload(() =>
  import('v2/pages/payment/payment/PaymentDetailPage')
);
export const ViolationListPage = ReactLazyPreload(() =>
  import('v2/pages/payment/violation/ViolationListPage')
);
export const ViolationDetailPage = ReactLazyPreload(() =>
  import('v2/pages/payment/violation/ViolationDetailPage')
);

// 보험료 관리
export const InsuranceListPage = ReactLazyPreload(() =>
  import('v2/pages/rent/insurance/InsuranceListPage')
);
export const InsuranceDetailPage = ReactLazyPreload(() =>
  import('v2/pages/rent/insurance/InsuranceDetailPage')
);
export const InsuranceVehicleListPage = ReactLazyPreload(() =>
  import('v2/pages/rent/insurance/InsuranceVehicleListPage')
);

// 단말 관리
export const TerminalListPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/terminal/TerminalListPage')
);
export const TerminalDetailPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/terminal/TerminalDetailPage')
);

export const ChargeListPage = ReactLazyPreload(() => import('v2/pages/rent/charge/ChargeListPage'));
export const ChargeDetailPage = ReactLazyPreload(() =>
  import('v2/pages/rent/charge/ChargeDetailPage')
);
export const ChargeVehicleListPage = ReactLazyPreload(() =>
  import('v2/pages/rent/charge/ChargeVehicleListPage')
);

export const UseStateListPage = ReactLazyPreload(() =>
  import('v2/pages/rent/usestate/UseStatePage')
);

// 휴일 관리
export const NewHolidayListPage = ReactLazyPreload(() =>
  import('v2/pages/rent/holiday/newHolidayListPage')
);
export const HolidayListPage = ReactLazyPreload(() => import('v2/pages/holidays/HolidayListPage'));

export const CodePage = ReactLazyPreload(() => import('v2/pages/system/code/CodePage'));
export const MenuPage = ReactLazyPreload(() => import('v2/pages/system/menu/MenuPage'));
export const RolePage = ReactLazyPreload(() => import('v2/pages/system/role/RolePage'));
export const ProgramListPage = ReactLazyPreload(() =>
  import('v2/pages/system/program/ProgramListPage')
);
export const TermsPage = ReactLazyPreload(() => import('v2/pages/system/terms/TermsPage'));

export const LicenseListPage = ReactLazyPreload(() => import('v2/pages/license/LicenseListPage'));
export const LicenseDetailPage = ReactLazyPreload(() =>
  import('v2/pages/license/LicenseDetailPage')
);

// 차량 제어이력
export const ControlHistoryListPage = ReactLazyPreload(() =>
  import('v2/pages/history/control/ControlHistoryListPage')
);
export const ControlHistoryDetailPage = ReactLazyPreload(() =>
  import('v2/pages/history/control/ControlHistoryDetailPage')
);
// 운전면허증 검증 이력
export const LicenseCheckHistoryListPage = ReactLazyPreload(() =>
  import('v2/pages/history/licenseCheck/LicenseCheckHistoryListPage')
);
export const LicenseCheckHistoryDetailPage = ReactLazyPreload(() =>
  import('v2/pages/history/licenseCheck/LicenseCheckHistoryDetailPage')
);

export const Error = ReactLazyPreload(() => import('./error/Error'));

// 거래처 관리자
export const CompanyUserListPage = ReactLazyPreload(() =>
  import('v2/pages/companyUser/CompanyUserListPage')
);
export const CompanyUserDetailPage = ReactLazyPreload(() =>
  import('v2/pages/companyUser//CompanyUserDetailPage')
);

export const CompanyListPage = ReactLazyPreload(() => import('v2/pages/company/CompanyListPage'));
export const CompanyDetailPage = ReactLazyPreload(() =>
  import('v2/pages/company/CompanyDetailPage')
);

// 영업점
export const BranchListPage = ReactLazyPreload(() => import('v2/pages/branch/BranchListPage'));

export const BranchDetailPage = ReactLazyPreload(() => import('v2/pages/branch/BranchDetailPage'));

// 차량 보험정보, 보험금 관리
export const VehicleInsuranceListPage = ReactLazyPreload(() =>
  import('v2/pages/vehicle/vehicleInsurance/VehicleInsuranceListPage')
);
// export const VehicleInsuranceDetailPage = ReactLazyPreload(() => import('v2/pages/vehicle/vehicleInsurance/VehicleInsuranceDetailPage'));
// 월간대여
export const MonthRentalPage = ReactLazyPreload(() =>
  import('v2/pages/rent/month/MonthRentalPage')
);

// 대여가능차량조회
export const RentalSearchListPage = ReactLazyPreload(() =>
  import('v2/pages/rent/rentalSearch/RentalSearchListPage')
);
export const RentalSearchDetailPage = ReactLazyPreload(() =>
  import('v2/pages/rent/rentalSearch/RentalSearchDetailPage')
);

export const TestCarReservationListPage = ReactLazyPreload(() =>
  import('v2/pages/rent/testCarReservation/TestCarReservationListPage')
);
export const TestCarReservationDetailPage = ReactLazyPreload(() =>
  import('v2/pages/rent/testCarReservation/TestCarReservationDetailPage')
);

/* 2020.11.17 leejw 추가 */
export const MonitoringdataListPage = ReactLazyPreload(() =>
  import('v2/pages/monitoringdata/MonitoringdataListPage')
);

export const SurveyListPage = ReactLazyPreload(() => import('v2/pages/survey/SurveyListPage'));

/* 2020.12.22 leejw 추가 */
export const BusinessReportListPage = ReactLazyPreload(() =>
  import('v2/pages/businessreport/BusinessReportListPage')
);

export const VoucherKpiMainPage = ReactLazyPreload(() =>
  import('v2/pages/voucherKpi/VoucherKpiMainPage')
);
/** dash */
export const DashBoardPage = ReactLazyPreload(() => import('v2/pages/dashboard/DashBoardMainPage'));
export const DashBoardKpi = ReactLazyPreload(() => import('v2/pages/dashboard/DashboardKpiPage'));
export const MonthlyRentalPage = ReactLazyPreload(() =>
  import('v2/pages/dashboard/MonthlyRentalPage')
);
export const DailyReservationPage = ReactLazyPreload(() =>
  import('v2/pages/dashboard/DailyReservationPage')
);
export const MonthOfCarPage = ReactLazyPreload(() => import('v2/pages/dashboard/MonthOfCarPage'));
export const CostItemCodePage = ReactLazyPreload(() =>
  import('v2/pages/costitem/CostItemCodePage')
);
export const CostItemCodeByDealerPage = ReactLazyPreload(() =>
  import('v2/pages/costitem/CostItemCodeByDealerPage')
);

/* 2021.01.07 쿠폰관리 추가 */
export const CouponmanageListPage = ReactLazyPreload(() =>
  import('v2/pages/couponmanage/CouponManageListPage')
);
export const CouponmanageDetailPage = ReactLazyPreload(() =>
  import('v2/pages/couponmanage/CouponManageDetailPage')
);

/* 2021.04.30 딜러서포트 추가 */
export const DealerSupportListPage = ReactLazyPreload(() =>
  import('v2/pages/dealersupport/DealerSupportListPage')
);

export const NoticeListPage = ReactLazyPreload(() => import('v2/pages/notice/NoticeListPage'));
export const NoticeCreatePage = ReactLazyPreload(() => import('v2/pages/notice/NoticeDetailPage'));
export const NoticeDetailPage = ReactLazyPreload(() => import('v2/pages/notice/NoticeDetailPage'));

export const PushListPage = ReactLazyPreload(() => import('v2/pages/push/PushListPage'));
export const PushCreatePage = ReactLazyPreload(() => import('v2/pages/push/PushDetailPage'));
export const PushDetailPage = ReactLazyPreload(() => import('v2/pages/push/PushDetailPage'));

/* 2021.06.11 결제송장관리(stripe payment invoice) 추가 */
export const StripeInvoiceListPage = ReactLazyPreload(() =>
  import('v2/pages/stripeinvoice/StripeInvoiceListPage')
);

// 액세서리 관리
export const AccessoryInfoListPage = ReactLazyPreload(() =>
  import('v2/pages/accessory/accessoryInfo/AccessoryInfoListPage')
);
export const AccessoryInfoDetailPage = ReactLazyPreload(() =>
  import('v2/pages/accessory/accessoryInfo/AccessoryInfoDetailPage')
);
export const AccessoryInfoCreatePage = ReactLazyPreload(() =>
  import('v2/pages/accessory/accessoryInfo/AccessoryInfoDetailPage')
);
export const AccessoryModelListPage = ReactLazyPreload(() =>
  import('v2/pages/accessory/accessoryModel/AccessoryModelListPage')
);
export const AccessoryModelDetailPage = ReactLazyPreload(() =>
  import('v2/pages/accessory/accessoryModel/AccessoryModelDetailPage')
);
export const AccessoryModelCreatePage = ReactLazyPreload(() =>
  import('v2/pages/accessory/accessoryModel/AccessoryModelDetailPage')
);

export const AccessoryServiceListPage = ReactLazyPreload(() =>
  import('v2/pages/accessory/accessoryService/AccessoryServiceListPage')
);
export const AccessoryServiceDetailPage = ReactLazyPreload(() =>
  import('v2/pages/accessory/accessoryService/AccessoryServiceDetailPage')
);
export const AccessoryServiceCreatePage = ReactLazyPreload(() =>
  import('v2/pages/accessory/accessoryService/AccessoryServiceDetailPage')
);

export const AccessoryCategoryListPage = ReactLazyPreload(() =>
  import('v2/pages/accessory/category/CategoryListPage')
);
export const AccessoryCategoryDetailPage = ReactLazyPreload(() =>
  import('v2/pages/accessory/category/CategoryDetailPage')
);
export const AccessoryCategoryCreatePage = ReactLazyPreload(() =>
  import('v2/pages/accessory/category/CategoryDetailPage')
);

export const AppVersionListPage = ReactLazyPreload(() =>
  import('v2/pages/system/appVersion/AppVersionListPage')
);
export const AppVersionDetailPage = ReactLazyPreload(() =>
  import('v2/pages/system/appVersion/AppVersionDetailPage')
);

// 서비스 로그
export const ServiceHistoryListPage = ReactLazyPreload(() =>
  import('v2/pages/system/serviceHistory/ServiceHistoryListPage')
);
export const ServiceHistoryDetailPage = ReactLazyPreload(() =>
  import('v2/pages/system/serviceHistory/ServiceHistoryDetailPage')
);

// dashboard 고도화
export const DealerKPIListPage = ReactLazyPreload(() =>
  import('v2/pages/dealerKPI/DealerKPIListPage')
);
