import { put, select, takeLatest } from 'redux-saga/effects';

import { getVehicleAccidentListAPI, getVehicleAccidentDetailAPI } from 'v2/apis/vehicleAccidentApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';

import history from 'service/common/history';
import {
  getVehicleAccidentList,
  entityVehicleAccidentList,
  getVehicleAccidentDetail,
  entityVehicleAccidentDetail
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/branch';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorVehicleAccidentListPage = (state) => state.vehicleAccident.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */

/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getVehicleAccidentListAsync = createSagaAction.bind(
  null,
  entityVehicleAccidentList,
  getVehicleAccidentListAPI
);
const getVehicleAccidentDetailAsync = createSagaAction.bind(
  null,
  entityVehicleAccidentDetail,
  getVehicleAccidentDetailAPI
);

/**
 * Watcher
 */
export default function* vehicleAccidentWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    getVehicleAccidentList().type,
    fetchSaga,
    getVehicleAccidentListAsync,
    entityVehicleAccidentList
  );
  yield takeLatest(
    getVehicleAccidentDetail().type,
    fetchSaga,
    getVehicleAccidentDetailAsync,
    entityVehicleAccidentDetail
  );
}
