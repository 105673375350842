import { getGeocodeAPI, getAddressAPI, getSearchResultAPI } from 'apis/localApi';
import { Map } from 'immutable';
import { createAction, createActions, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import { FMSCommon } from 'service/common/commonLib';

// Sync action creator
export const { initializeLocalState, getGeocode, getAddress, getSearchResult } = createActions(
  {
    INITIALIZE_LOCAL_STATE: (stateName) => ({ stateName })
  },
  'GET_GEOCODE',
  'GET_ADDRESS',
  'GET_SEARCH_RESULT'
);
// Async action creator
export const getGeocodeAsync = createAction(getGeocode().type, getGeocodeAPI);
export const getAddressAsync = createAction(getAddress().type, getAddressAPI);
export const getSearchResultAsync = createAction(getSearchResult().type, getSearchResultAPI);
export const defaultState = Map({
  address: {
    status: null,
    data: { addressName: null, buildingName: null },
    error: null
  },
  geocode: {
    status: null,
    data: {
      latitude: 37.45492610406875,
      longitude: 127.06486316115738
    },
    error: null
  },
  searchResult: {
    status: null,
    data: [],
    error: null
  }
});

const local = handleActions(
  {
    [initializeLocalState().type]: (state, { payload: { stateName } }) => {
      if (stateName) return state.set(stateName, defaultState.get(stateName));
    },
    ...pender({
      type: [getAddress().type],
      onPending: (state) => state.setIn(['address', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { documents }
          }
        }
      ) => {
        if (documents && documents[0]) {
          return state
            .setIn(
              ['address', 'data', 'addressName'],
              (documents[0].road_address && documents[0].road_address.address_name)
                || (documents[0].address && documents[0].address.address_name)
                || ''
            )
            .setIn(
              ['address', 'data', 'buildingName'],
              (documents[0].road_address && documents[0].road_address.building_name) || ''
            )
            .setIn(['address', 'status'], 'DONE');
        }
        return state
          .setIn(['address', 'data', 'addressName'], '')
          .setIn(['address', 'status'], 'DONE');
      },
      onFailure: (state, { payload }) => {
        FMSCommon.toast.fail('Local.Address.Get.Fail');
        return state.setIn(['address', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [getGeocode().type],
      onPending: (state) => state.setIn(['geocode', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { documents }
          }
        }
      ) => {
        if (documents && documents[0]) {
          return state
            .setIn(['geocode', 'data'], {
              latitude: parseFloat(documents[0].y) || 0,
              longitude: parseFloat(documents[0].x) || 0
            })
            .setIn(['geocode', 'status'], 'DONE');
        }
        FMSCommon.toast.fail('Local.Geocode.Get.Fail');
        return state.setIn(['geocode', 'status'], 'DONE');
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Local.Geocode.Get.Fail');
        return state.setIn(['geocode', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [getSearchResult().type],
      onPending: (state) => state.setIn(['searchResult', 'status'], 'PENDING'),
      onSuccess: (state, { payload: { data } }) => state.mergeIn(['searchResult', 'data'], data).setIn(['searchResult', 'status'], 'DONE'),
      onFailure: (state) => {
        FMSCommon.toast.fail('Local.Search.Fail');
        return state.setIn(['searchResult', 'status'], 'DONE');
      }
    })
  },
  defaultState
);

export default local;
