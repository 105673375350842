import { takeLatest } from 'redux-saga/effects';

import { getGeocodeAPI, getAddressAPI, getSearchResultAPI } from 'v2/apis/localApi'; // here map
import * as yandexApi from 'v2/apis/yandexApi'; // yandex map

import { createSagaAction, fetchSaga } from 'v2/redux/sagas';

import {
  getGeocodeNew,
  getAddressNew,
  getSearchResultNew,
  entityAdrress,
  entityGeoCode,
  entitySearchResult
} from '.';

/** ********************************************** */
/** constant */
// export const historyUrl = '/local';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
// export const selectorLocalPage = state => state.local.list;
/** ********************************************** */

/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getGeocodeAsync = createSagaAction.bind(
  null,
  entityGeoCode,
  $utils.isRu ? yandexApi.getGeocodeAPI : getGeocodeAPI
);
const getAdrressAsync = createSagaAction.bind(
  null,
  entityAdrress,
  $utils.isRu ? yandexApi.getAddressAPI : getAddressAPI
);
const getSearchResultAsync = createSagaAction.bind(
  null,
  entitySearchResult,
  $utils.isRu ? yandexApi.getSearchResultAPI : getSearchResultAPI
);
/**
 * Watcher
 */
export default function* locationLocalWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(getGeocodeNew().type, fetchSaga, getGeocodeAsync, entityGeoCode);
  yield takeLatest(getAddressNew().type, fetchSaga, getAdrressAsync, entityAdrress);
  yield takeLatest(getSearchResultNew().type, fetchSaga, getSearchResultAsync, entitySearchResult);
}
