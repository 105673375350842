import React from 'react';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const { openModalConfirm, closeModalConfirm, confirmYes, confirmNo } = createActions(
  'OPEN_MODAL_CONFIRM',
  'CLOSE_MODAL_CONFIRM',
  'CONFIRM_YES',
  'CONFIRM_NO'
);
/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  confirmModal: {
    isOpen: false,
    message: { label: 'Common.Btn.Confirm', values: {} }
  }
};

function parseConfirmConfig(oldConfig, { message, messageLabel, values }) {
  const nextMessage = { ...message, label: messageLabel, values: { count: values, br: <br /> } };
  return { ...oldConfig, message: nextMessage };
}

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
export const confirmModalReducer = handleActions(
  {
    [openModalConfirm().type]: (state, { payload }) => ({
      ...state,
      ...parseConfirmConfig(state, payload),
      isOpen: true
    }),
    [closeModalConfirm().type]: (state) => ({ ...state, isOpen: false })
  },
  {},
  { produce }
);

/** ********************************************** */
