import request from 'apis/request';

const prefix = '/member';

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/**
 * 법인 관리자 리스트 조회
 * ex) /member
 */
export const getBusinessMembersAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, startDate, endDate,
    keywordType,
    keyword,
    ...data
  } = filters;

  if (keywordType && keywordType !== '' && keyword) data[keywordType] = keyword;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}/business`,
    data
  });
};

/**
 * 법인 관리자 상세조회
 * ex) /member/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const getBusinessMemberAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });

/**
 * 법인 관리자 승인
 * ex) /member/ce4b7689-baa5-4cb6-ba34-dbc8468f943d/confirm
 */
export const confirmBusinessMemberAPI = ({ ID, data }) => {
  if (!data || !data.roleDetail) return;
  data.roleDetail = data.roleDetail.value;
  data.role = 'OPERATOR';

  return request({
    url: `${prefix}/${ID}/confirm`,
    method: 'put',
    data
  });
};

/**
 * 법인 관리자 반려
 * ex) /member/ce4b7689-baa5-4cb6-ba34-dbc8468f943d/confirm
 */
export const denyBusinessMemberAPI = ({ ID, data }) => request({
    url: `${prefix}/${ID}/deny`,
    method: 'put',
    data
  });

/**
 * 법인 관리자 수정
 * ex) /member/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const updateBusinessMemberAPI = ({ ID, data }) => {
  if (!data) return;
  data.role = 'OPERATOR';
  data.roleDetail =
    data.roleDetail && data.roleDetail.value ? data.roleDetail.value : data.roleDetail;
  data.aclMenu =
    data
    && data.aclMenu
    && data.aclMenu
      .filter((acl) => acl && acl.menuID)
      .map((acl) => ({
        menuID: acl.menuID,
        inquiryYN: acl.inquiryYN || false,
        saveYN: acl.saveYN || false,
        delYN: acl.delYN || false,
        downYN: acl.downYN || false
      }));

  return request({
    url: `${prefix}/${ID}`,
    method: 'put',
    data
  });
};

/**
 * 법인 관리자 삭제
 * ex) /member/b93ddfcc-56fc-4efe-9dbf-94e419b1001b
 */
export const deleteBusinessMemberAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
