import { takeLatest } from 'redux-saga/effects';
import { createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { getApiHistoryListAPI } from 'v2/apis/apiHistoryAPI';

import { entityApiHistoryList, getApiHistoryList } from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/ApiHistory';

/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// 리스트조회
const getApiHistoryListAsync = createSagaAction.bind(
  null,
  entityApiHistoryList,
  getApiHistoryListAPI
);

export default function* apiHistoryWatcher() {
  yield takeLatest(
    getApiHistoryList().type,
    fetchSaga,
    getApiHistoryListAsync,
    entityApiHistoryList
  );
}
