import React, { memo, useState, useLayoutEffect, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { YMaps as YMapsProvider } from 'react-yandex-maps';

import history from 'service/common/history';

import FMSToast from 'components/ui/common/FMSToast';
import { FMSModalsProvider } from 'components/ui/modals/FMSModalsContext';
import RootModalPage from 'v2/containers/modals/RootModalPage';
import ErrorPage from 'containers/ErrorPage';
import { DEFAULT_USED_LANGUAGE } from 'v2/constants';
import * as Sentry from '@sentry/react';
import App from './main';
import enLocal from '../../locale/json/en.json';
import czLocal from '../../locale/json/cz.json';
import deLocal from '../../locale/json/de.json';
import esLocal from '../../locale/json/es.json';
import frLocal from '../../locale/json/fr.json';
import beLocal from '../../locale/json/be.json';
import huLocal from '../../locale/json/hu.json';
import itLocal from '../../locale/json/it.json';
import koLocal from '../../locale/json/ko.json';
import nlLocal from '../../locale/json/nl.json';
import plLocal from '../../locale/json/pl.json';
import ruLocal from '../../locale/json/ru.json';
import seLocal from '../../locale/json/se.json';
import skLocal from '../../locale/json/sk.json';

const stateSelector = createSelector(
  (store) => store.authentication,
  (authentication) => ({
    language: fp.getOr(DEFAULT_USED_LANGUAGE, 'language', authentication)
  })
);

if (process.env.FMS_NAME !== '로컬') {
  Sentry.init({
    dsn: process.env.FMS_SENTRY_WEB_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
const IntlApp = () => {
  /** 언어 리소스 전체 */
  const [en, setEn] = useState(enLocal);
  const [ko, setKo] = useState(koLocal);
  const [ru, setRu] = useState(ruLocal);
  const [it, setIt] = useState(itLocal);
  const [pl, setPl] = useState(plLocal);
  const [sk, setSk] = useState(skLocal);
  const [fr, setFr] = useState(frLocal);
  const [be, setBe] = useState(beLocal);
  const [es, setEs] = useState(esLocal);
  const [cz, setCz] = useState(czLocal);
  const [de, setDe] = useState(deLocal);
  const [hu, setHu] = useState(huLocal);
  const [nl, setNl] = useState(nlLocal);
  const [se, setSe] = useState(seLocal);

  const [lang, setLang] = useState({ ko, en, ru, it, pl, sk, fr, es, cz, de, hu, nl, se });
  // kyw local
  const setLangFunc = (item, data) => {
    (item === 'ko' && setKo(data)) ||
      (item === 'en' && setEn(data)) ||
      (item === 'ru' && setRu(data)) ||
      (item === 'it' && setIt(data)) ||
      (item === 'pl' && setPl(data)) ||
      (item === 'sk' && setSk(data)) ||
      (item === 'fr' && setFr(data)) ||
      (item === 'be' && setBe(data)) ||
      (item === 'es' && setEs(data)) ||
      (item === 'cz' && setCz(data)) ||
      (item === 'de' && setDe(data)) ||
      (item === 'hu' && setHu(data)) ||
      (item === 'nl' && setNl(data)) ||
      (item === 'se' && setSe(data));
  };
  useEffect(() => {
    const langGroup = ['ko', 'en', 'ru', 'it', 'pl', 'sk', 'fr', 'be', 'es', 'cz', 'de', 'hu'];
    // for (const item of langGroup) {
    //   getJson(item).then((data) => {
    //     setLangFunc(item, data);
    //   });
    // }
  }, []);

  function getJson(item) {
    return fetch(`${process.env.FMS_LANG_PACK}/${item}.json`)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    setLang({ ...lang, ko, en, ru, it, pl, sk, fr, be, es, cz, de, hu });
  }, [ko, en, ru, it, pl, sk, fr, be, es, cz, de, hu]);

  const { language } = useSelector(stateSelector, fp.equals);

  return (
    <IntlProvider
      locale={language}
      messages={{
        ...lang[DEFAULT_USED_LANGUAGE],
        ...lang[language]
      }}
      textComponent={React.Fragment}
    >
      {/* 맵사용 요청이 있을때만, 로딩되므로 굳이 분기 필요없음 */}
      {/* <YMapsProvider
        key={`isRu-${fp.pipe(getYandexLang, fp.toString)('lang')}`}
        preload={fp.equals('RU', process.env.FMS_SERVICE_COUNTRY_CODE)}
        query={{
          apiKey: process.env.FMS_YANDEX_API_KEY,
          lang: getYandexLang('lang')
        }}
      > */}
      <FMSModalsProvider>
        <Router basename={process.env.FMS_BASENAME} history={history}>
          <ErrorPage>
            <App history={history} />
          </ErrorPage>
        </Router>
        <FMSToast />
        <RootModalPage />
      </FMSModalsProvider>
      {/* </YMapsProvider> */}
    </IntlProvider>
  );
};

export default memo(IntlApp);
