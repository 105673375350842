import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import InputSelectBase from './InputSelectBase';

const InputSelect = ({
  style,
  onChangeValue,
  // onChange, // 전파하지 않음, InputSelect에서 다시 생성.
  // options, // 전파하지 않음, InputSelect에서 다시 생성.
  // isCodeValue, // 전파하지 않음, options 생성시 사용.
  // placeholder, // 전파하지 않음, 다시 생성해서 전파(message 처리)
  // defaultValue,// 전파하지 않음, 사용하지 않음.
  // value,// 전파하지 않음, 사용하지 않음.
  hidden = false, // 전파하지 않음, hidden 처리는 InputSelect
  // mode,// 전파하지 않음, InputSelect에서 사용
  ...props
}) => {
  const formContext = useFormContext();
  const { control, setValue, clearError, setError, triggerValidation, unregister, register } =
    (formContext && formContext) || {};

  const { mode, name, required } = props; // 필수 입력.
  return hidden ? null : (
    <div style={{ width: '100%', ...style }}>
      <Controller
        as={
          <InputSelectBase
            {...{
              initError: (isError, values) => {
                if (mode === 'view') {
                  setValue(name, values, true);
                } else if (mode === 'create') {
                  // unregister && unregister(name);
                  // required && register && register({ name, type: 'custom' }, { required });
                  //! required && register && register({ name });
                  register(name, { required });
                  setValue(name, values);
                  // clearError(name);
                } else if (!fp.get('loading', props)) {
                  setValue(name, values, true);
                  triggerValidation(name);
                } else if (mode === 'edit') {
                  setValue(name, values, true);
                }
              },
              // onChangeError: (isError, values) => {
              //   console.log('test2', values);
              //   setValue(name, values, required);
              // },
              ...props
            }}
          />
        }
        // defaultValue={selectedValue}
        control={control}
        rules={mode !== 'view' ? { required } : undefined}
        onChange={([selected]) => {
          setValue(name, selected, required);
          if (required) {
            if (selected && (selected.value || selected.length > 0)) {
              clearError(name);
            } else setError(name);
          }
          if (onChangeValue) onChangeValue(selected);
          return { value: selected };
        }}
        {...{
          ...props
        }}
      />
    </div>
  );
};
export default React.memo(InputSelect);
