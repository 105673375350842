import request from 'apis/request';

export const getDealerKPIListAPI = ({ filters = {} }) => {
  const { keywordType, keyword, ...data } = filters;
  console.log('getDealerKPIListAPI');
  if (keyword && keyword !== '') data[keywordType] = keyword;
  return request({
    // url: '/businessreport',
    url: '/dealerKPI',
    data
  });
};

export const getDealerKPIExcelAPI = ({ filters }) => {
  const { ...data } = filters;
  return request({
    url: '/dealerKPI/download',
    method: 'file',
    data
  });
};
