import request from 'apis/request';

const prefix = '/control';

export const requestVehicleControlAPI = ({ data, operator, action }) => {
  if (!data) return;
  if (!action || action === '') return;
  return request({
    url: `${prefix}/vehicle`,
    method: 'put',
    data: {
      vehicleID: data,
      action
    }
  });
};

/**
 * 차량 제어 결과 조회
 */
export const getResultVehicleControlAPI = (tid) => {
  if (!tid || tid === '') return;

  return request({
    url: `${prefix}/result/${tid}`
  });
};
