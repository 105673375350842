import { takeLatest } from 'redux-saga/effects';
import { createSagaAction, fetchSaga } from 'v2/redux/sagas';
import {
  getDealerKPIListAPI
} from 'v2/apis/dealerKPIApi';
import {
  getDealerkpiList,
  entityDealerKPIList
} from '.';

const getDalerKPIListAsync = createSagaAction.bind(
  null,
    entityDealerKPIList,
  getDealerKPIListAPI
);

export default function* monitoringDataWatcher() {
  yield takeLatest(
      getDealerkpiList().type,
    fetchSaga,
      getDalerKPIListAsync,
      entityDealerKPIList
  );
}
