import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { produce } from 'immer';

export const {
  initializeDealerkpiListPage,
  getDealerkpiList
} = createActions(
  {
    GET_DEALERKPI_LIST: (filters) => ({ filters })
  },
  'INITIALIZE_DEALERKPI_LIST_PAGE'
);

export const entityDealerKPIList = createEntity({
  key: getDealerkpiList().type,
  parseData: (action, dataField) => {
    const data = action && action.response && action.response.data && action.response.data;
    const fieldName = dataField || 'items';
    let resData = null;
    if (dataField === 'none') {
      resData = data;
    } else if (data && fieldName in data) {
      resData = data[fieldName];
    }
    const reqPager = action && action.params ? action.params : null;
    const resPageInfo = data ? data.pageInfo : null;
    const pageInfo = { ...(reqPager ? reqPager.pageInfo : null), ...(resPageInfo || null) };
    if (resData === null) {
      resData = [];
    }
    // 삭제 예정, label, value는 사용하지 마세요.
    const updatedList =
      resData &&
      resData.map &&
      resData.map((item) => {
        return item;
      });

    if (updatedList.length > 0) {
      const sumRow = updatedList.reduce((acc, current, i) => {
        console.log('current',current);
        const { avgNewRentalsPerVehicle } = current;
        if (acc.avgNewRentalsPerVehicle) {
          acc.avgNewRentalsPerVehicle += avgNewRentalsPerVehicle;
        } else {
          acc.avgNewRentalsPerVehicle = avgNewRentalsPerVehicle;
        }
        current.avgNewRentalsPerVehicle = avgNewRentalsPerVehicle;

        const { avgRentalDuration } = current;
        if (acc.avgRentalDuration) {
          acc.avgRentalDuration += avgRentalDuration;
        } else {
          acc.avgRentalDuration = avgRentalDuration;
        }
        current.avgRentalDuration = avgRentalDuration;

        const { avgVehicleRate } = current;
        if (acc.avgVehicleRate) {
          acc.avgVehicleRate += avgVehicleRate;
        } else {
          acc.avgVehicleRate = avgVehicleRate;
        }
        current.avgVehicleRate = avgVehicleRate;

        const { utilizationRate } = current;
        if (acc.utilizationRate) {
          acc.utilizationRate += utilizationRate;
        } else {
          acc.utilizationRate = utilizationRate;
        }
        current.utilizationRate = utilizationRate;

        const { avgRevenuePerVehicle } = current;
        if (acc.avgRevenuePerVehicle) {
          acc.avgRevenuePerVehicle += avgRevenuePerVehicle;
        } else {
          acc.avgRevenuePerVehicle = avgRevenuePerVehicle;
        }
        current.avgRevenuePerVehicle = avgRevenuePerVehicle;

        const { avgRevenuePerRentedDay } = current;
        if (acc.avgRevenuePerRentedDay) {
          acc.avgRevenuePerRentedDay += avgRevenuePerRentedDay;
        } else {
          acc.avgRevenuePerRentedDay = avgRevenuePerRentedDay;
        }
        current.avgRevenuePerRentedDay = avgRevenuePerRentedDay;

        return acc;
      }, {});
      sumRow.branchName = '';

      // avgNewRentalsPerVehicle 의 평균
      sumRow.avgNewRentalsPerVehicle = Intl.NumberFormat('es').format(sumRow.avgNewRentalsPerVehicle / updatedList.length);
      // avgRentalDuration 의 평균
      sumRow.avgRentalDuration = Intl.NumberFormat('es').format(sumRow.avgRentalDuration / updatedList.length);
      // avgVehicleRate 의 평균
      sumRow.avgVehicleRate = Intl.NumberFormat('es').format(sumRow.avgVehicleRate / updatedList.length);
      // utilizationRate 의 평균
      sumRow.utilizationRate = Intl.NumberFormat('es').format(sumRow.utilizationRate / updatedList.length);
      // avgRevenuePerVehicle 의 평균
      sumRow.avgRevenuePerVehicle = Intl.NumberFormat('es').format(sumRow.avgRevenuePerVehicle / updatedList.length);
      // avgRevenuePerRentedDay 의 평균
      sumRow.avgRevenuePerRentedDay = Intl.NumberFormat('es').format(sumRow.avgRevenuePerRentedDay / updatedList.length);

      updatedList.push(sumRow);
    }
    updatedList.currency = data?.currency;
    return { data: { list: updatedList }, pager: { ...reqPager, pageInfo } };
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const initializeState = {
  list: {
    data: {
      list: []
    },
    loading: false,
    pager: {
      filters: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
          0,
          0,
          0,
          0
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(
          23,
          59,
          59,
          999
        ),
        countryID: '_All',
        businessID: '_All',
        branchID: '_All',
        vehicleID: '_All'
      }
    }
  }
};

const listPageReducer = handleActions(
  {
    [initializeDealerkpiListPage().type]: () => initializeState.list
  },
  {},
  { produce }
);

export const dealerkpi = combineReducers({
  list: createReducer({
    entity: entityDealerKPIList,
    reducer: listPageReducer
  })
});
