import request from 'apis/request';

const prefix = '/apiHistory';
export const getApiHistoryListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page, limit } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;

  return request({
    url: `${prefix}`,
    data
  });
};
