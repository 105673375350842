import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { produce } from 'immer';

export const {
  initializeBusinessReportListPage,
  getBusinessReportList,
  initializeGetBranchInfo,
  getBranchInfo,
  initializeGetVehicleModelInfo,
  getVehicleModelInfo
} = createActions(
  {
    GET_BUSINESS_REPORT_LIST: (filters) => ({ filters }),
    GET_BRANCH_INFO: (filters) => ({ filters }),
    GET_VEHICLE_MODEL_INFO: (filters) => ({ filters })
  },
  'INITIALIZE_BUSINESS_REPORT_LIST_PAGE',
  'INITIALIZE_GET_BRANCH_INFO',
  'INITIALIZE_GET_VEHICLE_MODEL_INFO'
);

export const entityBusinessReportList = createEntity({
  key: getBusinessReportList().type,
  parseData: (action, dataField) => {
    const data = action && action.response && action.response.data && action.response.data;
    const fieldName = dataField || 'items';
    let resData = null;
    if (dataField === 'none') {
      resData = data;
    } else if (data && fieldName in data) {
      resData = data[fieldName];
    }
    const reqPager = action && action.params ? action.params : null;
    const resPageInfo = data ? data.pageInfo : null;
    const pageInfo = { ...(reqPager ? reqPager.pageInfo : null), ...(resPageInfo || null) };
    if (resData === null) {
      resData = [];
    }
    // 삭제 예정, label, value는 사용하지 마세요.
    const updatedList =
      resData &&
      resData.map &&
      resData.map((item) => {
        item.label = item.name || item.managerName;
        item.value = item.ID;
        return item;
      });

    // const usedVehicleModelCnt = 0;
    // const avgRentalPeriod = 0;
    if (updatedList.length > 0) {
      const sumRow = updatedList.reduce((acc, current, i) => {
        const { rentedDays } = current;
        if (acc.rentedDays) {
          acc.rentedDays += rentedDays;
        } else {
          acc.rentedDays = rentedDays;
        }

        current.rentedDays = Intl.NumberFormat('es').format(current.rentedDays);

        current.utilizationRate = Intl.NumberFormat('es').format(current.utilizationRate);

        const { availableDays } = current;
        if (acc.availableDays) {
          acc.availableDays += availableDays;
        } else {
          acc.availableDays = availableDays;
        }
        current.availableDays = Intl.NumberFormat('es').format(current.availableDays);

        const { noOfRunningRentals } = current;
        if (acc.noOfRunningRentals) {
          acc.noOfRunningRentals += noOfRunningRentals;
        } else {
          acc.noOfRunningRentals = noOfRunningRentals;
        }
        current.noOfRunningRentals = Intl.NumberFormat('es').format(current.noOfRunningRentals);

        const { revenue } = current;
        if (acc.revenue) {
          acc.revenue += revenue;
        } else {
          acc.revenue = revenue;
        }
        current.revenue = Intl.NumberFormat('es').format(current.revenue);

        const { revenueVat } = current;
        if (acc.revenueVat) {
          acc.revenueVat += revenueVat;
        } else {
          acc.revenueVat = revenueVat;
        }
        current.revenueVat = Intl.NumberFormat('es').format(current.revenueVat);

        const { noOfNewRentals } = current;
        if (acc.noOfNewRentals) {
          acc.noOfNewRentals += noOfNewRentals;
        } else {
          acc.noOfNewRentals = noOfNewRentals;
        }
        current.noOfNewRentals = Intl.NumberFormat('es').format(current.noOfNewRentals);

        const { noOfCanceledRentals } = current;
        if (acc.noOfCanceledRentals) {
          acc.noOfCanceledRentals += noOfCanceledRentals;
        } else {
          acc.noOfCanceledRentals = noOfCanceledRentals;
        }
        current.noOfCanceledRentals = Intl.NumberFormat('es').format(current.noOfCanceledRentals);

        const { totalRentalPeriod } = current;
        if (acc.totalRentalPeriod) {
          acc.totalRentalPeriod += totalRentalPeriod;
        } else {
          acc.totalRentalPeriod = totalRentalPeriod;
        }
        current.totalRentalPeriod = Intl.NumberFormat('es').format(current.totalRentalPeriod);

        current.avgRentalPeriod = Intl.NumberFormat('es').format(current.avgRentalPeriod);

        const { oneToThreeDays } = current;
        if (acc.oneToThreeDays) {
          acc.oneToThreeDays += oneToThreeDays;
        } else {
          acc.oneToThreeDays = oneToThreeDays;
        }
        current.oneToThreeDays = Intl.NumberFormat('es').format(current.oneToThreeDays);

        const { fourToSixDays } = current;
        if (acc.fourToSixDays) {
          acc.fourToSixDays += fourToSixDays;
        } else {
          acc.fourToSixDays = fourToSixDays;
        }
        current.fourToSixDays = Intl.NumberFormat('es').format(current.fourToSixDays);

        const { oneWeekOver } = current;
        if (acc.oneWeekOver) {
          acc.oneWeekOver += oneWeekOver;
        } else {
          acc.oneWeekOver = oneWeekOver;
        }
        current.oneWeekOver = Intl.NumberFormat('es').format(current.oneWeekOver);

        const { twoWeeksOver } = current;
        if (acc.twoWeeksOver) {
          acc.twoWeeksOver += twoWeeksOver;
        } else {
          acc.twoWeeksOver = twoWeeksOver;
        }
        current.twoWeeksOver = Intl.NumberFormat('es').format(current.twoWeeksOver);

        const { oneMonth } = current;
        if (acc.oneMonth) {
          acc.oneMonth += oneMonth;
        } else {
          acc.oneMonth = oneMonth;
        }
        current.oneMonth = Intl.NumberFormat('es').format(current.oneMonth);

        const { oneYear } = current;
        if (acc.oneYear) {
          acc.oneYear += oneYear;
        } else {
          acc.oneYear = oneYear;
        }
        current.oneYear = Intl.NumberFormat('es').format(current.oneYear);

        const { unknown } = current;
        if (acc.unknown) {
          acc.unknown += unknown;
        } else {
          acc.unknown = unknown;
        }
        current.unknown = Intl.NumberFormat('es').format(current.unknown);

        const privateCnt = current.private;
        if (acc.private) {
          acc.private += privateCnt;
        } else {
          acc.private = privateCnt;
        }
        current.private = Intl.NumberFormat('es').format(current.private);

        const { b2b } = current;
        if (acc.b2b) {
          acc.b2b += b2b;
        } else {
          acc.b2b = b2b;
        }
        current.b2b = Intl.NumberFormat('es').format(current.b2b);

        const { courtesyCar } = current;
        if (acc.courtesyCar) {
          acc.courtesyCar += courtesyCar;
        } else {
          acc.courtesyCar = courtesyCar;
        }
        current.courtesyCar = Intl.NumberFormat('es').format(current.courtesyCar);

        const { testDrive } = current;
        if (acc.testDrive) {
          acc.testDrive += testDrive;
        } else {
          acc.testDrive = testDrive;
        }
        current.testDrive = Intl.NumberFormat('es').format(current.testDrive);

        const { others } = current;
        if (acc.others) {
          acc.others += others;
        } else {
          acc.others = others;
        }
        current.others = Intl.NumberFormat('es').format(current.others);

        const { onLine } = current;
        if (acc.onLine) {
          acc.onLine += onLine;
        } else {
          acc.onLine = onLine;
        }
        current.onLine = Intl.NumberFormat('es').format(current.onLine);

        const { offLine } = current;
        if (acc.offLine) {
          acc.offLine += offLine;
        } else {
          acc.offLine = offLine;
        }
        current.offLine = Intl.NumberFormat('es').format(current.offLine);

        const { noOfReservation } = current;
        if (acc.noOfReservation) {
          acc.noOfReservation += noOfReservation;
        } else {
          acc.noOfReservation = noOfReservation;
        }
        current.noOfReservation = Intl.NumberFormat('es').format(current.noOfReservation);

        return acc;
      }, {});
      sumRow.operator = '';
      sumRow.branch = '';
      sumRow.standardModelName = '';
      sumRow.modelName = '';
      sumRow.plateNumber = '';
      sumRow.vehicleStatus = '';
      sumRow.inuseKm = ' '; //마일리지 total 빈값표시
      sumRow.returnKm = ' ';

      // utilizationRate 계산하면 소수점 3째자리부터 이상한 값이 화면에 나타남
      // 그래서 소수점 3째자리 에서 반올림
      const utilizationRate =
        (Math.round((sumRow.rentedDays / sumRow.availableDays) * 100) / 100) * 100;
      sumRow.utilizationRate =
        sumRow.availableDays === 0
          ? 0
          : Intl.NumberFormat('es').format(utilizationRate > 100 ? 100 : utilizationRate);
      // availableDays 대한 합을 구하면 소수점 2째자리부터 이상한 값이 화면에 나타남
      // 그래서 소수점 2째자리 에서 반올림
      sumRow.availableDays = Intl.NumberFormat('es').format(
        Math.round(sumRow.availableDays * 10) / 10
      );
      // revenue에 대한 합을 구하면 소수점 3째자리부터 이상한 값이 화면에 나타남
      // 그래서 소수점 3째자리 에서 반올림
      sumRow.revenue = Intl.NumberFormat('es').format(Math.round(sumRow.revenue * 100) / 100);
      // revenueVat에 대한 합을 구하면 소수점 3째자리부터 이상한 값이 화면에 나타남
      // 그래서 소수점 3째자리 에서 반올림
      sumRow.revenueVat = Intl.NumberFormat('es').format(Math.round(sumRow.revenueVat * 100) / 100);
      // avgRentalPeriod 계산하면 소수점 3째자리부터 이상한 값이 화면에 나타남
      // 그래서 소수점 3째자리 에서 반올림
      sumRow.avgRentalPeriod =
        sumRow.noOfNewRentals === 0
          ? 0
          : Intl.NumberFormat('es').format(
              Math.round((sumRow.totalRentalPeriod / sumRow.noOfNewRentals) * 100) / 100
            );
      // rentedDays에 대한 합을 구하면 소수점 2째자리부터 이상한 값이 화면에 나타남
      // 그래서 소수점 2째자리 에서 반올림
      sumRow.rentedDays = Intl.NumberFormat('es').format(Math.round(sumRow.rentedDays * 10) / 10);
      // totalRentalPeriod에 대한 합을 구하면 소수점 2째자리부터 이상한 값이 화면에 나타남
      // 그래서 소수점 2째자리 에서 반올림
      sumRow.totalRentalPeriod = Intl.NumberFormat('es').format(
        Math.round(sumRow.totalRentalPeriod * 10) / 10
      );
      updatedList.push(sumRow);
    }
    updatedList.currency = data?.currency;
    return { data: { list: updatedList }, pager: { ...reqPager, pageInfo } };
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityBranchInfo = createEntity({
  key: getBranchInfo().type,
  parseData: parseDataForList
});

export const entityVehicleModelInfo = createEntity({
  key: getVehicleModelInfo().type,
  parseData: parseDataForList
});

export const initializeState = {
  list: {
    data: {
      list: []
    },
    loading: false,
    pager: {
      filters: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
          0,
          0,
          0,
          0
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(
          23,
          59,
          59,
          999
        ),
        countryID: '_All',
        businessID: '_All',
        branchID: '_All',
        vehicleID: '_All'
      }
    }
  },
  branchInfo: {
    data: null,
    loading: false,
    pager: {
      filters: {
        businessID: '_All'
      }
    }
  },
  vehicleModelInfo: {
    data: null,
    loading: false,
    pager: {
      filters: {
        businessID: '_All',
        branchID: '_All'
      }
    }
  }
};

const listPageReducer = handleActions(
  {
    [initializeBusinessReportListPage().type]: () => initializeState.list
  },
  {},
  { produce }
);

const getBranchReducer = handleActions(
  {
    [initializeGetBranchInfo().type]: () => initializeState.branchInfo
  },
  {},
  { produce }
);

const getVehicleModelReducer = handleActions(
  {
    [initializeGetVehicleModelInfo().type]: () => initializeState.vehicleModelInfo
  },
  {},
  { produce }
);

export const businessreport = combineReducers({
  list: createReducer({
    entity: entityBusinessReportList,
    reducer: listPageReducer
  }),
  branchInfo: createReducer({
    entity: entityBranchInfo,
    reducer: getBranchReducer
  }),
  vehicleModelInfo: createReducer({
    entity: entityVehicleModelInfo,
    reducer: getVehicleModelReducer
  })
});
