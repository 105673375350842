import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getBranchListAPI,
  getBranchDetailAPI,
  createBranchAPI,
  updateBranchAPI,
  deleteBranchAPI,
  getAllBranchListAPI,
  suspendBranchAPI,
  restoreBranchAPI
} from 'v2/apis/branchApi';

import {
  createSagaAction,
  confirmFetchSaga,
  confirmFetchSagas,
  fetchSaga,
  fetchSagas
} from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  loadAllBranch,
  getBranchList,
  deleteForBranchList,
  changeBranchDetailPage,
  getBranchDetail,
  createBranch,
  updateBranch,
  deleteBranch,
  suspendBranch,
  deleteOneBranchInList,
  entityLoadAllBranchList,
  entityBranchList,
  entityBranchDetail,
  restoreBranch
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/branch';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorBranchListPage = (state) => state.branch.list;
export const selectorBranchLoadAll = (state) => state.branch.loadAll;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateBranch = createEntity({
  key: createBranch().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(changeBranchDetailPage({ mode: 'view' }));
    const selectorListPage = yield select(selectorBranchListPage);
    const { pager } = selectorListPage;
    const { ID } = payload;
    history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    if (ID) yield put(getBranchDetail(ID));
  }
});

// update for update page
export const entityUpdateBranch = createEntity({
  key: updateBranch().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { ID } = requestParam;
    if (ID) yield put(getBranchDetail(ID));
  }
});
// delete for detail page
export const entityDeleteBranch = createEntity({
  key: deleteBranch().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorBranchListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

export const entitySuspendBranch = createEntity({
  key: suspendBranch().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Suspend.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Suspend.Success');
    const selectorListPage = yield select(selectorBranchListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

export const entityRestoreBranch = createEntity({
  key: restoreBranch().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Suspend.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Suspend.Success');
    const selectorListPage = yield select(selectorBranchListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// delete one  for List page
export const entityDeleteOneBranchInList = createEntity({
  key: deleteOneBranchInList().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorBranchListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getBranchList(pageInfo, sortInfo, filters));
  }
});

// mulitple delete for list page.
export const entityDeleteForBranchList = createEntity({
  key: deleteForBranchList().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeBranchDetailPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorBranchListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getBranchList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorBranchListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getBranchList(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createBranchAsync = createSagaAction.bind(null, entityCreateBranch, createBranchAPI);
const updateBranchAsync = createSagaAction.bind(null, entityUpdateBranch, updateBranchAPI);
const deleteBranchAsync = createSagaAction.bind(null, entityDeleteBranch, deleteBranchAPI);
const suspendBranchAsync = createSagaAction.bind(null, entitySuspendBranch, suspendBranchAPI);
const restoreBranchAsync = createSagaAction.bind(null, entityRestoreBranch, restoreBranchAPI);
const deleteOneBranchInListAsync = createSagaAction.bind(
  null,
  entityDeleteOneBranchInList,
  deleteBranchAPI
);
const deleteForBranchListAsync = createSagaAction.bind(
  null,
  entityDeleteForBranchList,
  deleteBranchAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getBranchListAsync = createSagaAction.bind(null, entityBranchList, getBranchListAPI);
const getBranchDetailAsync = createSagaAction.bind(null, entityBranchDetail, getBranchDetailAPI);
const loadAllBranchListAsync = createSagaAction.bind(
  null,
  entityLoadAllBranchList,
  getAllBranchListAPI
);

function* changeBranchPageSaga() {
  yield put(changeBranchDetailPage({ mode: 'view' }));
}
// 상단 branch Tab 재조회
function* loadAllSaga() {
  const {
    pager: { filters }
  } = yield select(selectorBranchLoadAll);
  yield put(loadAllBranch(filters));
}

/**
 * Watcher
 */
export default function* branchWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createBranch().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createBranchAsync,
    entityCreateBranch
  );
  yield takeLatest(
    updateBranch().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateBranchAsync,
    entityUpdateBranch
  );
  yield takeLatest(
    deleteBranch().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteBranchAsync,
    entityDeleteBranch
  );

  yield takeLatest(
    suspendBranch().type,
    confirmFetchSaga,
    'Common.Confirm.Suspend',
    suspendBranchAsync,
    entitySuspendBranch
  );

  yield takeLatest(
    restoreBranch().type,
    confirmFetchSaga,
    'Common.Confirm.Restore',
    restoreBranchAsync,
    entityRestoreBranch
  );

  // delete one in list page
  yield takeLatest(
    deleteOneBranchInList().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteOneBranchInListAsync,
    entityDeleteOneBranchInList
  );
  yield takeLatest(getBranchList().type, fetchSaga, getBranchListAsync, entityBranchList);
  yield takeLatest(getBranchDetail().type, fetchSaga, getBranchDetailAsync, entityBranchDetail);

  // deletes in list page
  yield takeLatest(
    deleteForBranchList().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteForBranchListAsync,
    entityDeleteForBranchList
  );

  // select business
  yield takeLatest(
    loadAllBranch().type,
    fetchSaga,
    loadAllBranchListAsync,
    entityLoadAllBranchList
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityUpdateBranch.types[1], changeBranchPageSaga);
  yield takeLatest(entityCreateBranch.types[1], loadAllSaga);
  yield takeLatest(entityUpdateBranch.types[1], loadAllSaga);
  yield takeLatest(entityDeleteBranch.types[1], loadAllSaga);
  yield takeLatest(entitySuspendBranch.types[1], loadAllSaga);
  yield takeLatest(entityRestoreBranch.types[1], loadAllSaga);
}
