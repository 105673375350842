import request from 'apis/request';

const prefix = '/company';

function getComapnySortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/**
 * 거래처 관리자 목록
 */
export const getCompanyUsersAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { keywordType, keyword, ...data } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getComapnySortFieldNum(field);
  // data.businessID = ID;
  data.page = pageInfo && pageInfo.page ? pageInfo.page : 1;

  return request({
    url: `${prefix}/all/user`,
    data
  });
};

export const getAllCompanyUsersAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  return request({
    url: `${prefix}/all/user`,
    data
  });
};

/**
 * 거래처 관리자 등록
 */
export const createCompanyUserAPI = ({ data }) => request({
    url: `${prefix}/${data.companyID}/user`,
    method: 'post',
    data
  });
/**
 * 거래처 관리자 조회
 */
export const getCompanyUserAPI = ({ companyID, ID }) => request({
    url: `${prefix}/${companyID}/user/${ID}`
  });
/**
 * 거래처 관리자 수정
 */
export const updateCompanyUserAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${data.companyID}/user/${ID}`,
    method: 'patch',
    data
  });
};
/**
 * 거래처 관리자 삭제
 */
export const deleteCompanyUserAPI = ({ ID, companyID }) => {
  return request({
    url: `${prefix}/${companyID}/user/${ID}`,
    method: 'delete'
  });
};
/**
 * 거래처명 조회 Select box에서 사용
 */
export const getCompanyListAllAPI = () => request({
    url: '/company/all'
  });
/**
 * 거래처명 상세 조회, 거래처 관리자 상세조회시 거래처명 조회용
 */
export const getCompanyDetailAPI = (ID) => request({
    url: `/company/${ID}`
  });

export const getCompanyUserListAPI = ({ ID }) => request({
    url: `${prefix}/${ID}/user`
  });
