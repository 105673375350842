import { select, put, takeLatest } from 'redux-saga/effects';
import {
  getTermsListAPI,
  createTermsAPI,
  updateTermsAPI,
  deleteTermsAPI,
  deleteTermsFileAPI,
  updateTermsVersionAPI
} from 'v2/apis/termsApi';

import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import {
  getTermsList,
  createTerms,
  updateTerms,
  deleteTerms,
  deleteTermsFile,
  updateTermsVersion,
  entityTermsList,
  changeTermsState,
  initializeDetailPage
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/terms';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
// export const selectorMenuPage = state => state.menu.list;

// function* changeMenuPageSaga() {
//   yield put(changeMenuState({ mode: 'none' }));
// }

// function* changeMenuPageUpdateSaga() {
//   yield put(changeMenuState({ mode: 'view' }));
// }

/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateTerms = createEntity({
  key: createTerms().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
  }
});

// update for update page
export const entityUpdateTerms = createEntity({
  key: updateTerms().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
  }
});

// update for update page
export const entityUpdateVersionTerms = createEntity({
  key: updateTermsVersion().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
  }
});

// // delete for detail page
export const entityDeleteTerms = createEntity({
  key: deleteTerms().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  afterSuccessFn(/* payload */) {
    FMSCommon.toast.success('Common.Deleted.Success');
    // if (payload.code && payload.code !== 0 && payload.code !== 2 && payload.code !== 3) {
    //   FMSCommon.toast.success(getErrorMessage(payload.code));
    // } else {
    //   FMSCommon.toast.success('Common.Deleted.Success');
    //   yield put(changeMenuState({ selectedNode: null, mode: 'none' }));
    // }
  }
});

// // delete file for detail page
export const entityDeleteTermsFile = createEntity({
  key: deleteTermsFile().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Deleted.Success');
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createTermsAsync = createSagaAction.bind(null, entityCreateTerms, createTermsAPI);
const updateTermsAsync = createSagaAction.bind(null, entityUpdateTerms, updateTermsAPI);
const deleteTermsAsync = createSagaAction.bind(null, entityDeleteTerms, deleteTermsAPI);
const deleteTermsFileAsync = createSagaAction.bind(null, entityDeleteTermsFile, deleteTermsFileAPI);
const updateTermsVersionAsync = createSagaAction.bind(
  null,
  entityUpdateVersionTerms,
  updateTermsVersionAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getTermsListAsync = createSagaAction.bind(null, entityTermsList, getTermsListAPI);
// const getMenuAsync = createSagaAction.bind(null, entityMenuDetail, getMenuAPI);
// const loadAllMenuListAsync = createSagaAction.bind(null, entityLoadAllMenuList, getAllMenuListAPI);

export const selectorCountry = (state) => state.authentication.myCountry;

function* changeTermsSaga() {
  const country = yield select(selectorCountry);
  const {
    data: { ID }
  } = country;

  yield put(changeTermsState({ data: null, loading: true }));
  yield put(initializeDetailPage());
  yield put(getTermsList({ countryID: ID || null }));
}

/**
 * Watchersa
 */
export default function* termsWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }

  yield takeLatest(getTermsList().type, fetchSaga, getTermsListAsync, entityTermsList);
  yield takeLatest(
    createTerms().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createTermsAsync,
    entityCreateTerms
  );
  yield takeLatest(
    updateTerms().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateTermsAsync,
    entityUpdateTerms
  );
  yield takeLatest(
    deleteTerms().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteTermsAsync,
    entityDeleteTerms
  );
  yield takeLatest(deleteTermsFile().type,
    fetchSaga,
    deleteTermsFileAsync,
    entityDeleteTermsFile
  );
  yield takeLatest(
    updateTermsVersion().type,
    confirmFetchSaga,
    'Common.Confirm.UpdateTermsVersion',
    updateTermsVersionAsync,
    entityUpdateVersionTerms
  );
  //   yield takeLatest(getMenu().type, fetchSaga, getMenuAsync, entityMenuDetail);

  //   // select business
  //   yield takeLatest(loadAllMenu().type, fetchSaga, loadAllMenuListAsync, entityLoadAllMenuList);

  //   // type, worker
  //   // success update for detail page -> change mode for detail page
  yield takeLatest(entityCreateTerms.types[1], changeTermsSaga);
  yield takeLatest(entityUpdateTerms.types[1], changeTermsSaga);
  yield takeLatest(entityDeleteTerms.types[1], changeTermsSaga);
  yield takeLatest(entityUpdateVersionTerms.types[1], changeTermsSaga);
}
