import { takeLatest } from 'redux-saga/effects';
import {
  getReservationListAPI,
  getPaymentDetailAPI,
  getTimeListAPI,
  paymentProcessingAPI
} from 'v2/apis/paymentApi';
import { createSagaAction, fetchSaga, confirmFetchSaga } from 'v2/redux/sagas';
import {
  entityPaymentList,
  getPaymentList,
  entityPaymentDetail,
  getPaymentDetail,
  entityTimeList,
  getTimeList,
  entityProcessPayment,
  processPayment
} from '.';

const getPaymentListAsync = createSagaAction.bind(null, entityPaymentList, getReservationListAPI);
const getPaymentDetailAsync = createSagaAction.bind(null, entityPaymentDetail, getPaymentDetailAPI);
const getTimeListAsync = createSagaAction.bind(null, entityTimeList, getTimeListAPI);
const getProcessPaymentAsync = createSagaAction.bind(
  null,
  entityProcessPayment,
  paymentProcessingAPI
);

export default function* paymentWatcher() {
  yield takeLatest(getPaymentList().type, fetchSaga, getPaymentListAsync, entityPaymentList);
  yield takeLatest(getPaymentDetail().type, fetchSaga, getPaymentDetailAsync, entityPaymentDetail);
  yield takeLatest(getTimeList().type, fetchSaga, getTimeListAsync, entityTimeList);
  yield takeLatest(
    processPayment().type,
    confirmFetchSaga,
    'Common.Confirm.Pay',
    getProcessPaymentAsync,
    entityProcessPayment
  );
}
