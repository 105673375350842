/* eslint-disable import/prefer-default-export */
import { parseData } from 'v2/redux/lib/actionLib';

export function parseDataForList(action, dataField) {
  const data = action?.response?.data;
  const fieldName = dataField || 'items';
  let resData = null;
  if (dataField === 'none') {
    resData = data;
  } else if (data && fieldName in data) {
    resData = data[fieldName];
  }
  const reqPager = action && action.params ? action.params : null;
  const resPageInfo = data ? data.pageInfo : null;
  const pageInfo = { ...(reqPager ? reqPager.pageInfo : null), ...(resPageInfo || null) };
  if (resData === null) {
    resData = [];
  }

  // 삭제 예정, label, value는 사용하지 마세요.
  const updatedList =
    resData &&
    resData.map &&
    resData.map((item) => {
      item.label = item.name || item.managerName;
      item.value = item.ID;
      return item;
    });
  // console.log('resdata', resData);
  // console.log('updatedList:', updatedList);
  return { data: updatedList, pager: { ...reqPager, pageInfo } };
}

export function parseDataAndField(action, dataField) {
  const data1 = parseData(action);
  let resData = data1;
  const { data } = resData;

  if (dataField) {
    resData = data[dataField];
  }
  return { data: resData };
}
