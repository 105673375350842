import request from 'apis/request';

const prefix = '/license';

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

export const getLicenseListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, startDate, endDate,
    keywordType,
    keyword,
    ...data
  } = filters;

  if (keywordType && keywordType !== '' && keyword) data[keywordType] = keyword;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

export const getLicenseAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });

export const createLicenseAPI = ({ data }) => {
  data.useYN = data.useYN === 'true';
  return request({
    url: `${prefix}`,
    method: 'post',
    data
  });
};

export const updateLicenseAPI = ({ ID, data }) => {
  data.useYN = data.useYN === 'true';
  return request({
    url: `${prefix}/${ID}`,
    method: 'patch',
    data
  });
};

// export const getLicenseMenuAPI = (ID) => request({
//     url: 'menu',
//   });

function findTreeCheckYN(tree, list) {
  if (!tree) return;
  tree.forEach
    && tree.forEach((item) => {
      if (item.checked) list.push(item.menuID);
      findTreeCheckYN(item.subMenuList, list);
    });
}

export const updateLicenseMenuAPI = ({ ID, data }) => {
  const licenseMenus = [];
  findTreeCheckYN(data, licenseMenus);

  return request({
    url: `${prefix}/${ID}/menu`,
    method: 'post',
    data: { licenseMenus }
  });
};

export const deleteLicenseAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });

export const getLicenseBusinessAPI = (ID) => request({
    url: `${prefix}/${ID}/business`
  });
