import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';
import { createEntity, createReducer, action } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import { parseDataForList } from '../../lib/actionLib';

export const {
  getVehicleMySalesList,
  getVehicleMySalesPagingList,
  setVehicleMySalesDetail,
  initializeVehiclesMySalesListPage,
  getVehicleRegList
} = createActions(
  {
    GET_VEHICLE_MY_SALES_LIST: (pageInfo, sortInfo, filters, intl) => {
      return { pageInfo, sortInfo, filters, intl };
    },
    GET_VEHICLE_MY_SALES_Paging_LIST: (pageInfo, sortInfo, filters, intl) => {
      return { pageInfo, sortInfo, filters, intl };
    },
    SET_VEHICLE_MY_SALES_DETAIL: (data) => {
      return { data };
      // return ({data:{...data,model:{ID:'2d63ff30-45e0-47c5-9698-a9eeb08feca6'}}})
    }
  },
  'INITIALIZE_VEHICLES_MY_SALES_LIST_PAGE',
  'GET_VEHICLE_REG_LIST'
);

export const initializeState = {
  list: {
    regList: [],
    dataAll: [],
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    },
    deleteVehiclesState: 'pending'
  },
  detail: {
    data: {},
    loading: false,
    progressLoading: false,
    mode: 'create',
    tid: null
  }
};

const vehiclesMySalesListPageReducer = handleActions(
  {
    [initializeVehiclesMySalesListPage().type]: () => initializeState.list,
    [getVehicleMySalesPagingList().type]: (state, { payload }) => {
      const {
        pageInfo: { page, limit }
      } = payload;
      const startIdx = (page - 1) * PAGE_LIMIT;
      const endIdx = startIdx + PAGE_LIMIT;
      const { dataAll } = state;
      const { filters } = payload;
      const { vin, vehicleNumber, modelName, codeType } = filters;

      const has_filter =
        (vin !== undefined && vin !== '')
        || (vehicleNumber !== undefined && vehicleNumber !== '')
        || (modelName !== undefined && modelName !== '')
        || (codeType !== undefined && codeType !== 'ALL');
      let filtedData = [];
      let { length } = state.dataAll;

      const { regList = [] } = state;

      if (regList.length > 0) {
        const fn_includeCheck = (obj, list) => {
          const ch_val = list.some((d) => {
            if (d.VIN === obj.vin) {
              obj.ID = d.ID;
              obj.detail = d;
              return true;
            }
            return false;
          });
          return ch_val;
        };
      }

      if (has_filter) {
        const vinFilterValue = vin || '';
        const vehicleNmFilterValue = vehicleNumber || '';
        const modelNmFilterValue = modelName || '';
        const typeFilterValue = codeType || 'ALL';

        const filterCheckList = [
          vinFilterValue !== '',
          vehicleNmFilterValue !== '',
          modelNmFilterValue !== '',
          typeFilterValue !== 'ALL'
        ];

        filtedData = dataAll.filter((d) => {
          const isFilter = false;
          const { vin, plateNo, modelName, regYn } = d;

          const fnFilterVin = () => {
            if (filterCheckList[0]) {
              if (vin.toLowerCase().indexOf(vinFilterValue.toLowerCase()) !== -1) return true;
              return false;
            }
            return true;
          };

          const fnFilterVehNum = () => {
            if (filterCheckList[1]) {
              if (plateNo.toLowerCase().indexOf(vehicleNmFilterValue.toLowerCase()) !== -1)
                return true;
              return false;
            }
            return true;
          };

          const fnFilterModelNm = () => {
            if (filterCheckList[2]) {
              if (modelName.toLowerCase().indexOf(modelNmFilterValue.toLowerCase()) !== -1)
                return true;
              return false;
            }
            return true;
          };

          const fnRegYn = () => {
            if (filterCheckList[3]) {
              if (typeFilterValue === 'REGISTERED') {
                if (regYn === 'Y') return true;
                return false;
              }
              if (regYn === 'N') return true;
              return false;
            }
            return true;
          };

          return fnFilterVin() && fnFilterVehNum() && fnFilterModelNm() && fnRegYn();
        });
        length = filtedData.length;
      }

      const realDatas = has_filter
        ? filtedData.slice(startIdx, endIdx)
        : dataAll.slice(startIdx, endIdx);

      return { ...state, pager: { ...payload }, data: realDatas };
    },

    [getVehicleRegList().type]: (state, { payload }) => ({ ...state, ...payload })
    // [setVehicleMySalesDetail().type]: (state,{payload}) => {

    //   return ({ ...state, ...payload })
    // },
  },
  {},
  { produce }
);

const setVehicleMySalesDetailReducer = handleActions(
  {
    [setVehicleMySalesDetail().type]: (state, { payload }) => {
      return { ...state, ...payload };
    }
    // [changeVehiclesListPage().type]: (state, { payload }) => ({ ...state, ...payload }),
  },
  {},
  { produce }
);

export const entityVehiclesMySalesList = createEntity({
  key: getVehicleMySalesList().type,
  parseData: (action, dataFiled) => {
    const { intl } = action.params;

    const lists = action.response.data.data.list || [];

    const dataAll = [...lists];
    const {
      pageInfo: { page, limit }
    } = action.params;
    const { filters } = action.params;
    const { vin, vehicleNumber, modelName, codeType } = filters;

    const has_filter =
      (vin !== undefined && vin !== '')
      || (vehicleNumber !== undefined && vehicleNumber !== '')
      || (modelName !== undefined && modelName !== '')
      || (codeType !== undefined && codeType !== 'ALL');
    let filtedData = [];
    let { length } = dataAll;

    const { regList = [] } = action.params;

    if (regList !== null && regList.length > 0) {
      const fn_includeCheck = (obj, list) => {
        const ch_val = list.some((d) => {
          if (d.VIN === obj.vin) {
            obj.ID = d.ID;
            obj.detail = d;
            return true;
          }
          return false;
        });
        return ch_val;
      };

      dataAll.forEach((d) => {
        const { productionDate } = d;

        d.productionDate = productionDate.substr(0, 4);
        // d.plateNo = plateNo || '없음';

        if (fn_includeCheck(d, regList)) {
          d.regYn = 'Y';
          if (intl) d.regNm = intl.formatMessage({ id: 'IFSearch.Registered' }); // '등록';
        } else {
          d.regYn = 'N';
          if (intl) d.regNm = intl.formatMessage({ id: 'IFSearch.Unregistered' }); // '미등록';
        }
      });
    }

    if (has_filter) {
      const vinFilterValue = vin || '';
      const vehicleNmFilterValue = vehicleNumber || '';
      const modelNmFilterValue = modelName || '';
      const typeFilterValue = codeType || 'ALL';

      const filterCheckList = [
        vinFilterValue !== '',
        vehicleNmFilterValue !== '',
        modelNmFilterValue !== '',
        typeFilterValue !== 'ALL'
      ];

      filtedData = dataAll.filter((d) => {
        const isFilter = false;
        const { vin, plateNo, modelName, regYn } = d;

        const fnFilterVin = () => {
          if (filterCheckList[0]) {
            if (vin.toLowerCase().indexOf(vinFilterValue.toLowerCase()) !== -1) return true;
            return false;
          }
          return true;
        };

        const fnFilterVehNum = () => {
          if (filterCheckList[1]) {
            if (plateNo.toLowerCase().indexOf(vehicleNmFilterValue.toLowerCase()) !== -1)
              return true;
            return false;
          }
          return true;
        };

        const fnFilterModelNm = () => {
          if (filterCheckList[2]) {
            if (modelName.toLowerCase().indexOf(modelNmFilterValue.toLowerCase()) !== -1)
              return true;
            return false;
          }
          return true;
        };

        const fnRegYn = () => {
          if (filterCheckList[3]) {
            if (typeFilterValue === 'REGISTERED') {
              if (regYn === 'Y') return true;
              return false;
            }
            if (regYn === 'N') return true;
            return false;
          }
          return true;
        };

        return fnFilterVin() && fnFilterVehNum() && fnFilterModelNm() && fnRegYn();
      });
      length = filtedData.length;
    }
    // var setInfo = { ...action.params.pageInfo, totalRecord: length };

    const startIdx = (page - 1) * PAGE_LIMIT;
    const endIdx = startIdx + PAGE_LIMIT;

    const realDatas = has_filter
      ? filtedData.slice(startIdx, endIdx)
      : dataAll.slice(startIdx, endIdx);
    const reqPager = action && action.params ? action.params : null;
    const pageInfo = { ...(reqPager ? reqPager.pageInfo : null), totalRecord: length };

    return { dataAll, regList, data: realDatas, pager: { ...reqPager, pageInfo } };
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  },
  afterSuccessFn: () => {}
});

export const entityRegList = createEntity({
  key: getVehicleRegList().type,
  afterSuccessFn: (res) => {
    // console.log(res)
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entitySetVehicleMySalesDetail = createEntity({
  key: setVehicleMySalesDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

// page action reducer 선언
export const vehicleMySales = combineReducers({
  list: createReducer({
    entity: entityVehiclesMySalesList,
    reducer: vehiclesMySalesListPageReducer
  }),
  detail: createReducer({
    entity: entitySetVehicleMySalesDetail,
    reducer: setVehicleMySalesDetailReducer
  })
  // duplicate: createReducer({
  //   entity: entityCheckPlateNumberDuplicate,
  //   reducer: vehicleDuplicateReducer,
  // }),
  // control: createReducer({
  //   entity: entityControlVehicle,
  //   reducer: handleActions({
  //     [initializeVehicleControl().type]: () => initializeState.control,
  //     [changeVehicleControl().type]: (state, { payload }) => ({ ...state, ...payload }),
  //   }, {}, { produce }),
  // }),
});
