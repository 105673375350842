import { useSelector } from 'react-redux';

export const useMyLaunguage = () => {
  const { language } = useSelector(({ authentication }) => authentication);
  return { language };
};

export const useMyBusiness = () => {
  const {
    myBusiness: { data }
  } = useSelector(({ authentication }) => authentication);
  return { myBusiness: data };
};

export const useMyBranch = () => {
  const {
    myBranch: { data }
  } = useSelector(({ authentication }) => authentication);
  return { myBranch: data };
};

export const useMyCountry = () => {
  const {
    myCountry: { data }
  } = useSelector(({ authentication }) => authentication);
  return { myCountry: data };
};

const useAuthentication = () => useSelector(({ authentication }) => authentication);

export default useAuthentication;
