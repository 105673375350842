import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FMSConfirmModal from 'components/ui/modals/FMSConfirmModal';
import { closeModalConfirm, confirmYes, confirmNo } from 'v2/redux/modules/modals/confirmModal';

const ConfirmModalPage = () => {
  const { confirmModal } = useSelector(({ modals }) => modals);
  const dispatch = useDispatch();
  // 모달창에서 'X' 버튼을 클릭하면 버그발생 => 단순히 모달창을 닫는 기능만 수행해서 아래와 같은 버그가 발생하는거 같음
  // 1. 대여관리 선택 > 대여보험료관리 선택 > 대여보험료관리 화면으로 이동
  // 2. 대여보험료 리스트에서 임의 항목 선택
  // 3. 수정 버튼 선택 > 수정화면으로 이동
  // 4. 삭제 버튼 선택
  // 5. 삭제 팝업에서 X버튼 선택 > 팝업 닫음
  // 6. 수정 완료 버튼 선택
  // 7. 수정 완료, 삭제 완료 토스트 팝업이 2개가 동시에 뜸
  // const handlerCloseModalConfirm = useCallback(() => dispatch(closeModalConfirm()), [dispatch]);
  const handlerCloseModalConfirm = useCallback(() => dispatch(confirmNo()), [dispatch]);
  const handlerConfirmYes = useCallback(() => dispatch(confirmYes()), [dispatch]);
  const handlerConfirmNo = useCallback(() => dispatch(confirmNo()), [dispatch]);

  return (
    <>
      <FMSConfirmModal
        {...{
          ...confirmModal,
          close: handlerCloseModalConfirm,
          btnList: [
            {
              type: 'Confirm',
              action: handlerConfirmYes,
              label: 'Common.Btn.Confirm'
            },
            {
              type: 'Cancel',
              action: handlerConfirmNo,
              label: 'Common.Btn.Cancel'
            }
          ]
        }}
      />
    </>
  );
};

export default React.memo(ConfirmModalPage);
