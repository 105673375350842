import request from 'apis/request';

const prefix = '/terminal';

export const convertFormToData = (data) => {
  if (data) {
    if (data.nadID) data.nadID = data.nadID.replace(/-/g, '');
    if (data.orderDate) data.orderDate = data.orderDate.replace(/\./g, '');
    if (data.deliveryDate) data.deliveryDate = data.deliveryDate.replace(/\./g, '');
    if (data.activationDate) data.activationDate = data.activationDate.replace(/\./g, '');
    if (data.state && data.state.value) data.state = data.state.value;
    if (data.status && data.status.value) data.status = data.status.value;
  }

  delete data.createdAt;
  return data;
};

function getComapnySortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/**
 * 단말기 목록 조회
 */
// export const getTerminalListAPI = () => (
//   request({
//     url: prefix,
//   })
// );

export const getTerminalListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getComapnySortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 단말기 상세 조회
 */
export const getTerminalDetailAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });

/**
 * 단말기 등록
 */
export const createTerminalAPI = ({ data: inputData }) => {
  const data = convertFormToData(inputData);

  return request({
    url: prefix,
    method: 'post',
    data
  });
};

/**
 * 단말기 수정
 */
export const updateTerminalAPI = ({ ID, data: inputData }) => {
  const data = convertFormToData(inputData);
  return request({
    url: `${prefix}/${ID}`,
    method: 'patch',
    data
  });
};

/**
 * 단말기 삭제
 */
export const deleteTerminalAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
