import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getLicenseListAPI,
  getLicenseAPI,
  createLicenseAPI,
  updateLicenseAPI,
  deleteLicenseAPI,
  updateLicenseMenuAPI
} from 'v2/apis/licenseApi';

import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  getLicenseList,
  entityLicenseList,
  changeLicenseState,
  getLicense,
  createLicense,
  updateLicense,
  deleteLicense,
  entityLicense,
  updateLicenseAcl,
  changeLicenseAclPage,
  initializeLoadAllLicense,
  loadAllLicense,
  entityLoadAllLicense
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/license';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorLicenseListPage = (state) => state.license.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateLicense = createEntity({
  key: createLicense().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    const selectorListPage = yield select(selectorLicenseListPage);
    const { pager } = selectorListPage;
    const { ID } = payload;
    history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    if (ID) yield put(getLicense(ID));
  }
});

// update for update page
export const entityUpdateLicense = createEntity({
  key: updateLicense().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { ID } = requestParam;
    if (ID) yield put(getLicense(ID));
  }
});

// deleteCompany(Page)
export const entityDeleteLicense = createEntity({
  key: deleteLicense().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn(payload) {
    if (payload.code && payload.code !== 0 && payload.code !== 2 && payload.code !== 3) {
      FMSCommon.toast.success(getErrorMessage(payload.code));
    } else {
      FMSCommon.toast.success('Common.Deleted.Success');
      const selectorListPage = yield select(selectorLicenseListPage);
      const { pager } = selectorListPage;
      history.push({ pathname: historyUrl, state: pager });
    }
  }
});

// update for update page
export const entityUpdateLicenseAcl = createEntity({
  key: updateLicenseAcl().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Updated.Success');

    const fromPayload = fp.get('ID', payload);
    const fromStore = yield select((store) => fp.get('license.detail.data.licenseInfo.ID', store));
    const licenseID = fromPayload || fromStore;

    if (licenseID) yield put(getLicense(licenseID));
    yield put(changeLicenseAclPage({ aclMode: 'view' }));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createLicenseAsync = createSagaAction.bind(null, entityCreateLicense, createLicenseAPI);
const updateLicenseAsync = createSagaAction.bind(null, entityUpdateLicense, updateLicenseAPI);
const deleteLicenseAsync = createSagaAction.bind(null, entityDeleteLicense, deleteLicenseAPI);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getLicenseListAsync = createSagaAction.bind(null, entityLicenseList, getLicenseListAPI);
const loadAllLicenseAsync = createSagaAction.bind(null, entityLoadAllLicense, getLicenseListAPI);
const getLicenseAsync = createSagaAction.bind(null, entityLicense, getLicenseAPI);
// License ACL
const updateLicenseAclAsync = createSagaAction.bind(
  null,
  entityUpdateLicenseAcl,
  updateLicenseMenuAPI
);

function* changeLicensePageSaga() {
  yield put(changeLicenseState({ mode: 'view' }));
}

/**
 * Watcher
 */
export default function* licenseWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createLicense().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createLicenseAsync,
    entityCreateLicense
  );
  yield takeLatest(
    updateLicense().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateLicenseAsync,
    entityUpdateLicense
  );
  yield takeLatest(
    deleteLicense().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteLicenseAsync,
    entityDeleteLicense
  );
  yield takeLatest(getLicenseList().type, fetchSaga, getLicenseListAsync, entityLicenseList);
  yield takeLatest(loadAllLicense().type, fetchSaga, loadAllLicenseAsync, entityLoadAllLicense);
  yield takeLatest(getLicense().type, fetchSaga, getLicenseAsync, entityLicense);

  yield takeLatest(
    updateLicenseAcl().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateLicenseAclAsync,
    entityUpdateLicenseAcl
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityCreateLicense.types[1], changeLicensePageSaga);
  yield takeLatest(entityUpdateLicense.types[1], changeLicensePageSaga);
}
