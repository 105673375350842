import request from 'apis/request';

export const getBranchInfoAPI = ({ filters }) => {
  return request({
    url: '/branch',
    data: filters
  });
};

export const getVehicleModelInfoAPI = ({ filters }) => {
  if (filters.branchID === '_All') {
    filters.branchID = 1000;
  }
  return request({
    url: '/businessreport/vehiclemodel',
    data: filters
  });
};

export const getBusinessReportListAPI = ({ filters = {} }) => {
  const { keywordType, keyword, ...data } = filters;
  if (keyword && keyword !== '') data[keywordType] = keyword;
  return request({
    url: '/businessreport',
    data
  });
};
