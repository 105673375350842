import request from 'apis/request';

const prefix = '/monitoringdata';

export const getMonitoringdataListAPI = ({ pageInfo = {}, sortInfo = {}, filters = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { keywordType, keyword, ...data } = filters;

  if (keyword && keyword !== '') data[keywordType] = keyword;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order;
  data.sortField = field;

  return request({
    url: `${prefix}`,
    data
  });
};
