import { put, select, takeLatest } from 'redux-saga/effects';
import {
  getInsuranceListAPI,
  createInsuranceAPI,
  getInsuranceDetailAPI,
  updateInsuranceAPI,
  deleteInsuranceAPI,
  getVehicleListAPI,
  getInsuranceDetailVehicleListAPI,
  getVehicleInsuranceListAPI,
  deleteInsuranceVehicleAPI,
  getMileageListByVehicleIdAPI,
  checkInsuranceGroupNameDuplicateAPI,
  getUploadFileAPI,
  createFileAPI,
  deleteFileAPI
} from 'v2/apis/insuranceApi';
import { createSagaAction, confirmFetchSaga, fetchSaga, confirmFetchSagas } from 'v2/redux/sagas';
import { createEntity, parseErrorWithErrorField } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import history from 'service/common/history';
import { getErrorMessage } from 'constants/errors';
import {
  getInsuranceList,
  entityInsuranceList,
  getInsuranceDetail,
  entityInsuranceDetail,
  getVehicleInsurance,
  entityVehicleInsurance,
  getVehicleAll,
  entityVehicle,
  createInsurance,
  updateInsurance,
  deleteInsurance,
  deleteForInsuranceList,
  changeInsuranceDetailPage,
  getInsuranceForVehicle,
  entityInsuranceForVehicle,
  deleteInsuranceVehicle,
  changeInsuranceVehicleList,
  changeInsuranceListPage,
  getMileageListByVehicleId,
  entityMileageListByVehicleId,
  entityCheckInsuranceGroupNameDuplicate,
  checkInsuranceGroupNameDuplicate,
  entityUploadFile,
  getUploadFile,
  createFile,
  changeResultStatus,
  deleteFile
} from '.';

export const historyUrl = '/insurance';
export const selectorInsurancePage = (state) => state.insurance.list;
export const selectorMyBusiness = (state) => state.authentication.myBusiness.data;
export const selectorInsuranceVehicleListPage = (state) => state.insurance.insuranceForVehicleList;

export const entityCreateFile = createEntity({
  key: createFile().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    const { insuranceGroupID, uploading } = payload;
    FMSCommon.toast.success('Common.Created.Success');
    yield put(changeResultStatus({ resultStatus: 'SUCCESS' }));
    yield put(getUploadFile(insuranceGroupID));
  }
});

export const entityDeleteFile = createEntity({
  key: deleteFile().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn(payload) {
    const { insuranceGroupID, uploading } = payload;
    FMSCommon.toast.success('Common.Deleted.Success');
    yield put(getUploadFile(insuranceGroupID));
  }
});

export const entityCreateInsurance = createEntity({
  key: createInsurance().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    const selectorListPage = yield select(selectorInsurancePage);
    const { pager, status } = selectorListPage;
    const { ID } = payload;
    history.replace({ pathname: `${historyUrl}/${ID}`, state: { pager, path: status } });
  }
});

export const entityDeleteInsurance = createEntity({
  key: deleteInsurance().type,
  parseError: parseErrorWithErrorField,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorInsurancePage);
    const { pager, status } = selectorListPage;
    if (status === '/insuranceVehicle') {
      history.push({ pathname: `${status}`, state: { pager, path: status } });
    } else {
      history.push({ pathname: historyUrl, state: { pager, path: status } });
    }
  }
});

export const entityUpdateInsurance = createEntity({
  key: updateInsurance().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    //  const { ID: businessID } = yield select(selectorMyBusiness);
    const { groupID } = requestParam;
    const selectorListPage = yield select(selectorInsurancePage);
    const { pager, status } = selectorListPage;

    // refreshPage
    history.push({ pathname: historyUrl, state: { pager, path: status } });
    history.push({ pathname: `${historyUrl}/${groupID}`, state: { pager, path: status } });
  }
});

export const entityDeleteForInsuranceList = createEntity({
  key: deleteForInsuranceList().type,
  parseError: parseErrorWithErrorField,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeInsuranceListPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorInsurancePage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getInsuranceList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Insurance.Toast.Delete.Fail');
    const selectorListPage = yield select(selectorInsurancePage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getInsuranceList(pageInfo, sortInfo, filters));
  }
});

export const entityDeleteInsuranceVehicle = createEntity({
  key: deleteInsuranceVehicle().type,
  parseError: parseErrorWithErrorField,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeInsuranceVehicleList({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterFailureFn(payload) {
    // if (payload && payload.errorCode) {
    if (payload && _.isArray(payload) && _.size(payload) > 0) {
      if (payload[0].error) {
        FMSCommon.toast.fail(getErrorMessage(payload[0].error.errorCode));
      }
    } else FMSCommon.toast.fail('Insurance.Toast.Delete.Fail');
    const selectorListPage = yield select(selectorInsuranceVehicleListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getInsuranceForVehicle(pageInfo, sortInfo, filters));
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorInsuranceVehicleListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getInsuranceForVehicle(pageInfo, sortInfo, filters));
  }
});

function* changeInsurancePageSaga() {
  yield put(changeInsuranceDetailPage({ mode: 'view' }));
}

const getInsuranceListAsync = createSagaAction.bind(null, entityInsuranceList, getInsuranceListAPI);
const createFileAsync = createSagaAction.bind(null, entityCreateFile, createFileAPI);
const deleteFileAsync = createSagaAction.bind(null, entityDeleteFile, deleteFileAPI);
const getInsuranceDetailAsync = createSagaAction.bind(
  null,
  entityInsuranceDetail,
  getInsuranceDetailAPI
);
const getVehicleInsuranceAsync = createSagaAction.bind(
  null,
  entityVehicleInsurance,
  getInsuranceDetailVehicleListAPI
);
const getVehicleAsync = createSagaAction.bind(null, entityVehicle, getVehicleListAPI);
const createInsuranceAsync = createSagaAction.bind(null, entityCreateInsurance, createInsuranceAPI);
const deleteInsuranceAsync = createSagaAction.bind(null, entityDeleteInsurance, deleteInsuranceAPI);
const updateInsuranceAsync = createSagaAction.bind(null, entityUpdateInsurance, updateInsuranceAPI);
const deleteForInsuranceListAsync = createSagaAction.bind(
  null,
  entityDeleteForInsuranceList,
  deleteInsuranceAPI
);
const getInsuranceForVehicleAsync = createSagaAction.bind(
  null,
  entityInsuranceForVehicle,
  getVehicleInsuranceListAPI
);
const deleteInsuranceVehicleAsync = createSagaAction.bind(
  null,
  entityDeleteInsuranceVehicle,
  deleteInsuranceVehicleAPI
);
const getMileageListByVehicleIdAsync = createSagaAction.bind(
  null,
  entityMileageListByVehicleId,
  getMileageListByVehicleIdAPI
);
const checkInsuranceGroupNameDuplicateAsync = createSagaAction.bind(
  null,
  entityCheckInsuranceGroupNameDuplicate,
  checkInsuranceGroupNameDuplicateAPI
);
const getUploadFileAsync = createSagaAction.bind(null, entityUploadFile, getUploadFileAPI);

export default function* InsuranceWatcher() {
  yield takeLatest(createFile().type, fetchSaga, createFileAsync, entityCreateFile);
  yield takeLatest(deleteFile().type, fetchSaga, deleteFileAsync, entityDeleteFile);
  yield takeLatest(getUploadFile().type, fetchSaga, getUploadFileAsync, entityUploadFile);
  yield takeLatest(getInsuranceList().type, fetchSaga, getInsuranceListAsync, entityInsuranceList);
  yield takeLatest(
    getInsuranceDetail().type,
    fetchSaga,
    getInsuranceDetailAsync,
    entityInsuranceDetail
  );
  yield takeLatest(
    getVehicleInsurance().type,
    fetchSaga,
    getVehicleInsuranceAsync,
    entityVehicleInsurance
  );
  yield takeLatest(getVehicleAll().type, fetchSaga, getVehicleAsync, entityVehicle);
  yield takeLatest(
    createInsurance().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createInsuranceAsync,
    entityCreateInsurance
  );
  yield takeLatest(
    deleteInsurance().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteInsuranceAsync,
    entityDeleteInsurance
  );
  yield takeLatest(
    updateInsurance().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateInsuranceAsync,
    entityUpdateInsurance
  );
  yield takeLatest(
    deleteForInsuranceList().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteForInsuranceListAsync,
    entityDeleteForInsuranceList
  );

  yield takeLatest(
    getInsuranceForVehicle().type,
    fetchSaga,
    getInsuranceForVehicleAsync,
    entityInsuranceForVehicle
  );
  yield takeLatest(
    deleteInsuranceVehicle().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteInsuranceVehicleAsync,
    entityDeleteInsuranceVehicle
  );

  yield takeLatest(entityCreateInsurance.types[1], changeInsurancePageSaga);
  yield takeLatest(entityUpdateInsurance.types[1], changeInsurancePageSaga);

  yield takeLatest(
    getMileageListByVehicleId().type,
    fetchSaga,
    getMileageListByVehicleIdAsync,
    entityMileageListByVehicleId
  );

  yield takeLatest(
    checkInsuranceGroupNameDuplicate().type,
    fetchSaga,
    checkInsuranceGroupNameDuplicateAsync,
    entityCheckInsuranceGroupNameDuplicate
  );
}
