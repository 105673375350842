import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { createEntity, createReducer } from 'v2/redux/lib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

export const {
  initializeLoadAllVehicleInsurance,
  loadAllVehicleInsurance,
  initializeVehicleInsuranceMonthList,
  getVehicleInsuranceMonthList,
  changeVehicleInsuranceMonthList,
  deleteVehicleInsuranceMonth,
  initializeVehicleInsuranceDetail,
  changeVehicleInsuranceDetail,
  getVehicleInsuranceDetail,
  updateVehicleInsurance,
  initializeInsurancePaymentList,
  getInsurancePaymentList,
  changeInsurancePaymentList,
  createVehicleInsuranceMonth,
  updateInsurancePayment,
  initializeVehicleInsurancePaymentDetail,
  getVehicleInsurancePaymentDetail,
  updateInsurancePaymentForVehicle,
  initializeVehicleInsuranceDetailChange,
  changeVehicleInsuranceDetailChange,
  getVehicleInsuranceDetailChange
} = createActions(
  {
    LOAD_ALL_VEHICLE_INSURANCE: (filters) => ({ filters }),
    GET_VEHICLE_INSURANCE_MONTH_LIST: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    }),
    DELETE_VEHICLE_INSURANCE_MONTH: (ID, vehicleID, insureYear) => ({ ID, vehicleID, insureYear }),
    GET_VEHICLE_INSURANCE_DETAIL: (ID, insureYear) => ({ ID, insureYear }),
    UPDATE_VEHICLE_INSURANCE: (data) => data,
    GET_INSURANCE_PAYMENT_LIST: (ID, Year) => ({ ID, Year }),
    CREATE_VEHICLE_INSURANCE_MONTH: (data) => data,
    UPDATE_INSURANCE_PAYMENT: (data) => data,
    GET_VEHICLE_INSURANCE_PAYMENT_DETAIL: (ID) => ({ ID }),
    UPDATE_INSURANCE_PAYMENT_FOR_VEHICLE: (data, vehicleID) => ({ data, vehicleID }),
    GET_VEHICLE_INSURANCE_DETAIL_CHANGE: (ID, insureYear) => ({ ID, insureYear })
  },
  'INITIALIZE_LOAD_ALL_VEHICLE_INSURANCE',
  'INITIALIZE_VEHICLE_INSURANCE_MONTH_LIST',
  'CHANGE_VEHICLE_INSURANCE_MONTH_LIST',
  'INITIALIZE_VEHICLE_INSURANCE_DETAIL',
  'CHANGE_VEHICLE_INSURANCE_DETAIL',
  'INITIALIZE_INSURANCE_PAYMENT_LIST',
  'CHANGE_INSURANCE_PAYMENT_LIST',
  'INITIALIZE_VEHICLE_INSURANCE_PAYMENT_DETAIL',
  'INITIALIZE_VEHICLE_INSURANCE_DETAIL_CHANGE',
  'CHANGE_VEHICLE_INSURANCE_DETAIL_CHANGE'
);

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: {
        limit: PAGE_LIMIT,
        page: 1
      },
      sortInfo: {},
      filters: {
        dateType: 'paymentDueDate',
        startDate: new Date().setHours(0, 0, 0, 0) - 28 * 24 * 60 * 60 * 1000,
        endDate: new Date().setHours(23, 59, 59, 999) + 7 * 24 * 60 * 60 * 1000,
        paymentStatus: 'false'
      }
    }
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  },
  detail: {
    data: null,
    mode: 'create',
    loading: true
  },
  insuranceList: {
    data: [],
    loading: false
  },
  paymentDetail: {
    data: null
  },
  detailChange: {
    data: null,
    mode: 'create',
    loading: true
  }
};

export const entityLoadAllVehicleInsurance = createEntity({
  key: loadAllVehicleInsurance().type,
  parseData: parseDataForList
});

const vehicleInsuranceLoadAllReducer = handleActions(
  {
    [initializeLoadAllVehicleInsurance().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);

export const entityVehicleInsuranceMonth = createEntity({
  key: getVehicleInsuranceMonthList().type,
  parseData: parseDataForList
});

const vehicleInsuranceMonthReducer = handleActions(
  {
    [initializeVehicleInsuranceMonthList().type]: () => initializeState.list,
    [changeVehicleInsuranceMonthList().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

export const entityVehicleInsuranceDetail = createEntity({
  key: getVehicleInsuranceDetail().type
});

const vehicleInsuranceDetailReducer = handleActions(
  {
    [initializeVehicleInsuranceDetail().type]: () => initializeState.detail,
    [changeVehicleInsuranceDetail().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

export const entityInsurancePaymentList = createEntity({
  key: getInsurancePaymentList().type,
  parseDate: (action) => parseDataForList(action, 'none')
});

const InsurancePaymentListReducer = handleActions(
  {
    [initializeInsurancePaymentList().type]: () => initializeState.insuranceList,
    [changeInsurancePaymentList().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

export const entityVehicleInsurancePaymentDetail = createEntity({
  key: getVehicleInsurancePaymentDetail().type
});

const VehicleInsurancePaymentDetailReducer = handleActions(
  {
    [initializeVehicleInsurancePaymentDetail().tpye]: () => initializeState.paymentDetail
  },
  {},
  { produce }
);

export const entityVehicleInsuranceDetailChange = createEntity({
  key: getVehicleInsuranceDetailChange().type
});

const VehicleInsuranceDetailChangeReducer = handleActions(
  {
    [initializeVehicleInsuranceDetailChange().type]: () => initializeState.detailChange,
    [changeVehicleInsuranceDetailChange().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

export const vehicleInsurance = combineReducers({
  list: createReducer({
    entity: entityVehicleInsuranceMonth,
    reducer: vehicleInsuranceMonthReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllVehicleInsurance,
    reducer: vehicleInsuranceLoadAllReducer
  }),
  detail: createReducer({
    entity: entityVehicleInsuranceDetail,
    reducer: vehicleInsuranceDetailReducer
  }),
  insuranceList: createReducer({
    entity: entityInsurancePaymentList,
    reducer: InsurancePaymentListReducer
  }),
  paymentDetail: createReducer({
    entity: entityVehicleInsurancePaymentDetail,
    reducer: VehicleInsurancePaymentDetailReducer
  }),
  detailChange: createReducer({
    entity: entityVehicleInsuranceDetailChange,
    reducer: VehicleInsuranceDetailChangeReducer
  })
});
