import request from 'apis/request';

const prefix = '/vehiclemodel';

/**
 * 차량 모델 목록 조회(전체)
 */
export const getVehicleModelsListAPI = ({ filters = {} }) => {
  const { ...data } = filters;
  return request({
    url: prefix,
    data
  });
};

function getSortFieldNum(field) {
  switch (field) {
    default:
      return 0;
    case 'name':
      return 1;
    case 'fuelType':
      return 2;
    case 'grade':
      return 3;
    case 'seatingCapacity':
      return 4;
    case 'displacement':
      return 5;
    case 'fuelTankCapacity':
      return 6;

    // case 'createdAt':
    //   return 1;
    // case 'name':
    //   return 2;
  }
}

/**
 * 차량 모델 목록 조회(페이징)
 */
export const getVehicleModelListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 차량 모델 상세 조회
 */
export const getVehicleModelAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });

/**
 * 차량 모델 등록
 */
export const createVehicleModelAPI = ({ data, image }) => {
  // data.standard = `${data.standard === 'on'}`;
  data.warmUpTime = '30';
  const dt = new FormData();

  if (data) {
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item) => {
          dt.append(key, item);
        });
      } else dt.append(key, data[key]);
    });
  }

  if (image) {
    dt.append('image', image);
  }

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: prefix,
    method: 'post',
    data: dt
  });
};
/**
 * 차량 모델 수정
 */
export const updateVehicleModelAPI = ({ ID, data, image }) => {
  data.warmUpTime = '30';
  // image를 포함한 multipart로 보냄
  const dt = new FormData();

  if (data) {
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item) => {
          dt.append(key, item);
        });
      } else {
        dt.append(key, data[key]);
      }
    });
  }

  if (image) {
    dt.append('image', image);
  }

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}/${ID}`,
    method: 'patch',
    data: dt
  });
};
/**
 * 차량 모델 이미지 수정
 */
export const updateVehicleModelImageAPI = ({ ID, image }) => {
  const data = new FormData();
  data.append('image', image);
  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}/${ID}/image`,
    method: 'post',
    data
  });
};
/**
 * 차량 모델 삭제
 */
export const deleteVehicleModelAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
};
