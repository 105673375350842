import {
  getCodeListAPI,
  getCodeListWithGroupKeyAPI,
  createCodeAPI,
  updateCodeAPI,
  deleteCodeAPI
} from 'apis/codeApi';
import { Map, fromJS } from 'immutable';
import { createAction, createActions, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import { FMSCommon } from 'service/common/commonLib';
import { makeCode, getMultiCodesList } from 'service/CodeService';

// Sync action creator
export const {
  initializeCodeState,
  getCodeList,
  getCodeListWithGroupKey,
  createCode,
  updateCode,
  deleteCode
} = createActions(
  {
    INITIALIZE_CODE_STATE: (sliceStoreName) => sliceStoreName
  },
  'GET_CODE_LIST',
  'GET_CODE_LIST_WITH_GROUP_KEY',
  'CREATE_CODE',
  'UPDATE_CODE',
  'DELETE_CODE'
);

export const getCodeListAsync = createAction(getCodeList().type, getCodeListAPI);
export const getCodeListWithGroupKeyAsync = createAction(
  getCodeListWithGroupKey().type,
  getCodeListWithGroupKeyAPI
);
export const createCodeAsync = createAction(createCode().type, createCodeAPI);
export const updateCodeAsync = createAction(updateCode().type, updateCodeAPI);
export const deleteCodeAsync = createAction(deleteCode().type, deleteCodeAPI);
export const defaultState = {
  list: {
    status: null,
    data: [],
    filters: {},
    error: null
  },
  multiList: {
    status: null,
    data: {},
    error: null
  }
};

const code = handleActions(
  {
    [initializeCodeState().type]: (state, { payload: { stateName } }) => {
      const arState = stateName ? stateName.split('.') : null;
      if (!arState) return fromJS(defaultState);
      return state.setIn(arState, fromJS(defaultState).getIn(arState));
    },
    ...pender({
      type: [getCodeList().type],
      onPending: (state) => state.setIn(['list', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { items }
          }
        }
      ) => state.setIn(['list', 'data'], fromJS(makeCode(items))).setIn(['list', 'status'], 'DONE'),
      onFailure: (state, { payload }) => {
        FMSCommon.toast.fail('Code.View.Fail');
        return state.setIn(['list', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [getCodeListWithGroupKey().type],
      onPending: (state) => state.setIn(['list', 'status'], 'PENDING'),
      onSuccess: (state, { payload }) => {
        const codeListObj = getMultiCodesList(payload);

        return state
          .setIn(['multiList', 'data'], fromJS(codeListObj))
          .setIn(['multiList', 'status'], 'DONE');
      },
      onFailure: (state, { payload }) => {
        FMSCommon.toast.fail('Code.View.Fail');
        return state.setIn(['list', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [createCode().type],
      onPending: (state) => state.setIn(['list', 'status'], 'CREATING'),
      onSuccess: (state) => {
        FMSCommon.toast.success('Code.Create.Success');
        return state.setIn(['list', 'status'], 'CREATED');
      },
      onFailure: (state, { payload }) => {
        FMSCommon.toast.fail('Code.Create.Fail');
        return state.setIn(['list', 'status'], 'FAIL');
      }
    }),
    ...pender({
      type: [updateCode().type],
      onPending: (state) => state.setIn(['list', 'status'], 'PENDING'),
      onSuccess: (state, { payload: { data } }) => {
        FMSCommon.toast.success('Code.Update.Success');
        return state
          .setIn(['detail', 'status'], 'UPDATED')
          .mergeIn(['detail', 'data'], fromJS(data));
      },
      onFailure: (state, { payload }) => {
        FMSCommon.toast.fail('Code.Update.Fail');
        return state.setIn(['list', 'status'], 'FAIL');
      }
    }),
    ...pender({
      type: [deleteCode().type],
      onPending: (state) => state.setIn(['list', 'status'], 'PENDING'),
      onSuccess: (state, { payload: { data } }) => {
        FMSCommon.toast.success('Code.Delete.Success');
        return state.setIn(['list', 'status'], 'UPDATED').mergeIn(['list', 'data'], fromJS(data));
      },
      onFailure: (state, { payload }) => {
        FMSCommon.toast.fail('Code.Update.Fail');
        return state.setIn(['detail', 'status'], 'FAIL');
      }
    })
  },
  fromJS(defaultState)
);

export default code;
