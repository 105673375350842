import React, { useEffect, useState, useCallback } from 'react';
import InputSelect from 'v2/components/ui/input/InputSelect';

const defaultFieldes = { label: 'label', value: 'value' };

const FMSDataSelect = ({
  loading = false,
  data,
  fieldes,
  firstSelect = false,
  initSelect,
  ...props
}) => {
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (loading && fp.isNotEmpty(selectOptions)) {
      setSelectOptions([]);
    } else if (fp.isNotEmpty(data)) {
      const dataFieldes = fp.assign(defaultFieldes, fieldes);
      const options = fp.map(
        (item) => fp.pipe(
            fp.set('label', fp.get(dataFieldes.label, item)),
            fp.set('value', fp.get(dataFieldes.value, item)),
            fp.set('meta', item)
          )({}),
        data
      );
      setSelectOptions(options);
    }
  }, [loading, data, firstSelect]);

  const getSelectedValue = useCallback((options, selectedVal, firstSelect) => {
    const result = firstSelect ? fp.getOr(null, '[0].value', options) : selectedVal;

    return result;
  }, []);

  return (
    <InputSelect
      {...props}
      initSelect={initSelect}
      loading={loading}
      options={selectOptions}
      selectedValue={getSelectedValue(selectOptions, fp.get('selectedValue', props), firstSelect)}
    />
  );
};

export default React.memo(FMSDataSelect);
