import React from 'react';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FMSColors } from 'service/common/designLib';

const FMSToastDesign = styled.div`
  .Toastify__toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: -ms-max-content;
    width: max-content;
    bottom: 50%;
  }

  .Toastify__toast {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin: 0 auto;
    bottom: 0;
    min-height: 30px;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }
  .Toastify__toast--error {
    border-radius: 3px;
    background-color: #fee3e3;
    border: 1px solid #f75454;
    background-image: url(${process.env.PUBLIC_URL}/assets/images/warning-line.png);
    background-position: left 10px center;
    background-repeat: no-repeat;
    background-size: 35px;
  }
  .Toastify__toast--success {
    border-radius: 3px;
    background-color: ${FMSColors.secondary};
    border: 1px solid ${FMSColors.primary};
    background-image: url(${process.env.PUBLIC_URL}/assets/images/done.svg);
    background-position: left 10px center;
    background-repeat: no-repeat;
    background-size: 35px;
  }
  .Toastify__toast--info {
    border-radius: 3px;
    background-color: #fbfbfb;
    border: 1px solid #dddddd;
  }
  .Toastify__toast--info .Toastify__toast-body {
    color: #666666;
  }
  .Toastify__toast--error .Toastify__toast-body {
    color: #f75454;
    padding-left: 35px;
  }
  .Toastify__toast--success .Toastify__toast-body {
    color: ${FMSColors.primary};
    padding-left: 35px;
  }
  .Toastify__toast-body {
    padding: 5px;
    font-size: 16px;
    text-align: left;
  }

  .Toastify__toast--warning .Toastify__toast-body {
    color: ${FMSColors.white};
  }

  .Toastify__progress-bar {
    background-color: transparent;
  }

  .Toastify__close-button--warning {
    background-color: ${FMSColors.main};
    color: ${FMSColors.white};
  }

  .Toastify__close-button--success {
    background-color: ${FMSColors.main};
    color: ${FMSColors.white};
  }

  @keyframes zoomIn {
    from {
      transform: scale3d(0.9, 0.9, 0.9);
    }
    to {
      transform: scale3d(1, 1, 1);
    }
  }

  .zoomIn {
    animation-name: zoomIn;
  }

  @keyframes zoomOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .zoomOut {
    animation-name: zoomOut;
  }
`;

const FMSToastAdmin = ({ className }) => (
  <FMSToastDesign className={className}>
    <ToastContainer />
  </FMSToastDesign>
);

export default React.memo(FMSToastAdmin);
