import React, { Fragment, useCallback, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useLocation } from 'react-router-dom';

import { initializeLoadAllBranch, loadAllBranch } from 'v2/redux/modules/branch';
import { initializeMyBranch, changeMyBranch } from 'v2/redux/modules/authentication';

import FMSDataSelect from 'v2/components/pages/form/FMSDataSelect';

const stateSelector = createSelector(
  (store) => store.branch,
  (store) => store.country,
  (store) => store.business,
  (store) => store.location,
  (store) => store.authentication,
  (branch, country, business, location, authentication) => ({
    data: fp.getOr([], 'loadAll.data', branch),
    filters: fp.getOr({}, 'loadAll.pager.filters', branch),

    allCountryLoading: fp.getOr(false, 'loadAll.loading', country),
    allBusinessLoading: fp.getOr(false, 'loadAll.loading', business),
    allBranchLoading: fp.getOr(false, 'loadAll.loading', branch),
    allLocationLoading: fp.getOr(false, 'loadAll.loading', location),

    profileData: fp.getOr({}, 'profile.data', authentication),
    role: fp.getOr('', 'profile.data.role', authentication),
    roleDetail: fp.getOr('', 'profile.data.roleDetail', authentication),
    myCountry: fp.getOr({}, 'myCountry.data', authentication),
    myBusiness: fp.getOr({}, 'myBusiness.data', authentication),
    myBranch: fp.getOr({}, 'myBranch.data', authentication),
    userBranchID: fp.getOr(0, 'profile.data.branch.userBranchID', authentication)
  })
);

/**
 * Branch 자동 선택 로직을 포함한 component로 HomeMain외에는 BranchSelect component 사용
 */
const BranchSelectContainer = ({ forceShow, filters, ...props }) => {
  const state = useSelector(stateSelector, fp.equals);
  const dispatch = useDispatch();
  const location = useLocation();
  const [branchFilters, setBranchFilters] = useState();
  const [needFirstSelectRoutes] = useState(['/useState']);

  const handlerGetBranch = useCallback((filters) => dispatch(loadAllBranch(filters)), [dispatch]);

  useEffect(() => {
    const cleanUp = () => {
      dispatch(initializeLoadAllBranch());
      dispatch(initializeMyBranch());
    };

    return cleanUp;
  }, []);

  useEffect(() => {
    setBranchFilters((prev) => (fp.equals(filters, prev) ? prev : filters));
  }, [filters]);

  useEffect(() => {
    const isValidBranchID = fp.pipe(fp.get('ID'), fp.toNumber, fp.gt, fp.ap(0));
    const getBranchList = fp.pipe(
      fp.getOr([], 'data'),
      fp.filter((branch) => fp.isNotEmpty(branch) && isValidBranchID(branch))
    );

    const getMyBranch = (state) => fp.pipe(fp.get('myBranch.ID'), fp.toNumber, fp.gt, fp.ap(0))(state)
        ? fp.get('myBranch', state)
        : {};
    const getBranchFromList = (myBranch, branchList) => {
      const composer = fp.find(fp.pipe(fp.get('ID'), fp.equals(fp.get('ID', myBranch))));
      const result = composer(branchList);

      return result;
    };

    const branchList = getBranchList(state); // branchID가 유효한 branch 목록 확인
    const normalizeStr = fp.pipe(fp.trim, fp.toLower); // 문자열 normalize
    const routePath = fp.getOr('', 'pathname', location); // 현재 경로
    // 조회한 branch 목록으로 부터 myBranch 조회 (store의 myBranch 정보가 유효하지 않은 경우가 있다.)
    const myBranch = getBranchFromList(getMyBranch(state), branchList);
    // business 변경 여부 조회
    const isBusinessChanged = fp.curry((myBranch, branchList, state) => {
      const composer = fp.pipe(
        fp.get('myBusiness.ID'),
        fp.curry((brachFromList, businessID) => {
          const currentBizID = fp.get('business.ID', brachFromList);
          return fp.equals(currentBizID, businessID);
        })(myBranch)
      );
      const result = composer(state);
      return result;
    });

    // branch 목록이 존재하고,
    // myBranch가 비어있고,
    // 현재 경로가 needFirstSelectRoutes에 포함된 경로면
    // branch 목록중 첫번째 브랜치 선택 적용
    const autoSelectedBranch = fp.head(branchList);
    if (
      fp.isNotEmpty(branchList) // branch 목록 완료 후 실행
      && fp.includes(normalizeStr(routePath), fp.map(normalizeStr, needFirstSelectRoutes)) // route path 확인
      && !isValidBranchID(myBranch) // 초기 로딩 시, branch가 invalid
      && fp.notEquals(fp.get('ID', myBranch), fp.get('ID', autoSelectedBranch)) // branch변경 확인
      && isBusinessChanged(myBranch, branchList, state) // biz변경 여부 확인
    ) {
      dispatch(changeMyBranch({ data: autoSelectedBranch }));
    }
  }, [location, dispatch, state.myBusiness, state.myBranch, state.data, state.allBranchLoading]);

  useEffect(() => {
    if (fp.pipe(fp.get('businessID'), fp.isNotEmpty)(filters)) {
      handlerGetBranch(branchFilters);
    }
  }, [handlerGetBranch, branchFilters]);

  const isRenderSelector = useCallback((forceShow, roleDetail, role) => {
    const userBranchID = fp.get('userBranchID', state);
    return (
      forceShow
      || fp.equals('1', roleDetail)
      || (fp.equals('3', roleDetail) && fp.includes(userBranchID, [0, null]))
      || fp.startsWith('SYSTEM', role)
      || fp.startsWith('MOCEAN', role)
    );
  }, []);

  return (
    <>
      <FMSDataSelect
        data={fp.get('data', state)}
        loading={
          fp.get('allBranchLoading', state)
          || fp.get('allBusinessLoading', state)
          || fp.get('allCountryLoading', state)
        }
        {...props}
        r-if={isRenderSelector(forceShow, fp.get('roleDetail', state), fp.get('role', state))}
      />
      <Fragment r-else>
        {fp.get('myBranch.data.name', state) || fp.getOr('', 'profileData.branch.name', state)}
      </Fragment>
    </>
  );
};

export default memo(BranchSelectContainer);
