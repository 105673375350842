import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getStripeListAPI,
  getStripeDetailAPI,
  createStripeAPI,
  updateStripeAPI,
  deleteStripeAPI,
  getAllStripeListAPI
} from 'v2/apis/stripeApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  loadAllStripe,
  getStripeList,
  deleteForStripeList,
  changeStripeDetailPage,
  getStripeDetail,
  createStripe,
  updateStripe,
  deleteStripe,
  entityLoadAllStripeList,
  entityStripeList,
  entityStripeDetail
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/stripeinvoice';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorStripeListPage = (state) => state.stripe.list;
export const selectorStripeLoadAll = (state) => state.stripe.loadAll;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateStripe = createEntity({
  key: createStripe().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(changeStripeDetailPage({ mode: 'view' }));
    const selectorListPage = yield select(selectorStripeListPage);
    const { pager } = selectorListPage;
    const { ID } = payload;
    history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    if (ID) yield put(getStripeDetail(ID));
  }
});

// update for update page
export const entityUpdateStripe = createEntity({
  key: updateStripe().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { ID } = requestParam;
    if (ID) yield put(getStripeDetail(ID));
  }
});
// delete for detail page
export const entityDeleteStripe = createEntity({
  key: deleteStripe().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorStripeListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForStripeList = createEntity({
  key: deleteForStripeList().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeStripeDetailPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorStripeListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getStripeList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorStripeListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCouponList(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createStripeAsync = createSagaAction.bind(null, entityCreateStripe, createStripeAPI);
const updateStripeAsync = createSagaAction.bind(null, entityUpdateStripe, updateStripeAPI);
const deleteStripeAsync = createSagaAction.bind(null, entityDeleteStripe, deleteStripeAPI);
const deleteForStripeListAsync = createSagaAction.bind(
  null,
  entityDeleteForStripeList,
  deleteStripeAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getStripeListAsync = createSagaAction.bind(null, entityStripeList, getStripeListAPI);
const getStripeDetailAsync = createSagaAction.bind(null, entityStripeDetail, getStripeDetailAPI);
const loadAllStripeListAsync = createSagaAction.bind(
  null,
  entityLoadAllStripeList,
  getAllStripeListAPI
);

function* changeStripePageSaga() {
  yield put(changeStripeDetailPage({ mode: 'view' }));
}
function* loadAllSaga() {
  const {
    pager: { filters }
  } = yield select(selectorStripeLoadAll);
  yield put(loadAllStripe(filters));
}

/**
 * Watcher
 */
export default function* stripeWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createStripe().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createStripeAsync,
    entityCreateStripe
  );
  yield takeLatest(
    updateStripe().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateStripeAsync,
    entityUpdateStripe
  );
  yield takeLatest(
    deleteStripe().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteStripeAsync,
    entityDeleteStripe
  );
  yield takeLatest(getStripeList().type, fetchSaga, getStripeListAsync, entityStripeList);
  yield takeLatest(getStripeDetail().type, fetchSaga, getStripeDetailAsync, entityStripeDetail);

  // deletes in list page
  yield takeLatest(
    deleteForStripeList().type,
    fetchSagas,
    null,
    deleteForStripeListAsync,
    entityDeleteForStripeList
  );

  // select business
  yield takeLatest(
    loadAllStripe().type,
    fetchSaga,
    loadAllStripeListAsync,
    entityLoadAllStripeList
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityUpdateStripe.types[1], changeStripePageSaga);
  yield takeLatest(entityCreateStripe.types[1], loadAllSaga);
  yield takeLatest(entityUpdateStripe.types[1], loadAllSaga);
  yield takeLatest(entityDeleteStripe.types[1], loadAllSaga);
}
