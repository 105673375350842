import { takeLatest } from 'redux-saga/effects';

import {
  getReservationVehiclesUsageAPI,
  getReservationStateVehiclesCountAPI,
  getReservationStateCountAPI
} from 'v2/apis/reservationStateApi';

import { createSagaAction, fetchSaga } from 'v2/redux/sagas';

import {
  getReservationVehiclesUsage,
  changeUseStatePage,
  changeReservationVehiclesUsage,
  entityReservationVehiclesUsage,
  getReservationStateVehiclesCount,
  entityReservationVehiclesCount,
  getReservationStateCount,
  entityReservationCount
} from '.';

/** ********************************************** */
/** constant */
/** ********************************************** */

/** ********************************************** */
/** saga selector */
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getReservationVehiclesUsageAsync = createSagaAction.bind(
  null,
  entityReservationVehiclesUsage,
  getReservationVehiclesUsageAPI
);
const getReservationStateVehiclesCountAsync = createSagaAction.bind(
  null,
  entityReservationVehiclesCount,
  getReservationStateVehiclesCountAPI
);
const getReservationStateCountAsync = createSagaAction.bind(
  null,
  entityReservationCount,
  getReservationStateCountAPI
);

/**
 * Watcher
 */
export default function* reservationStateSaga() {
  // type, workder, // worker arguments. { actionHandler, entity }
  // select business
  yield takeLatest(
    getReservationVehiclesUsage().type,
    fetchSaga,
    getReservationVehiclesUsageAsync,
    entityReservationVehiclesUsage
  );
  yield takeLatest(
    getReservationStateVehiclesCount().type,
    fetchSaga,
    getReservationStateVehiclesCountAsync,
    entityReservationVehiclesCount
  );
  yield takeLatest(
    getReservationStateCount().type,
    fetchSaga,
    getReservationStateCountAsync,
    entityReservationCount
  );
}
