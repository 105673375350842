import request from 'apis/request';

const prefix = '/alarms';

function getAlarmSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
  }
}
export const getAlramsListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page, limit } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getAlarmSortFieldNum(field);

  return request({
    url: '/notification',
    data
  });
};

// 상세보기
export const getAlarmsDetailAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};
// 알람등록기능
export const createAlramsAPI = ({ data }) => {
  return request({
    url: prefix,
    method: 'post',
    data
  });
};

export const createAPI = ({ data }) => {
  return request({
    url: `${prefix}`,
    method: 'post',
    data
  });
};
