import React, { useEffect } from 'react';
import withMapInstance from './withMapInstance';

const HereMap = ({ map, behavior, ui, children, lat, lng, onClick, onDrag, ...props }) => {
  useEffect(() => {
    if (map) {
      map.setCenter(new window.H.geo.Point(lat, lng));
    }
  }, [lat, lng]);
  return <>{children}</>;
};

export default withMapInstance(HereMap);
