import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { defaultGeoData } from 'v2/lib/utils';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';
// eslint-disable-next-line import/no-cycle
import { vehicleParseDataForList } from './locationActionLib';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeLoadAllLocations,
  initializeLoadUnusedLocations,
  loadAllLocations,
  loadUnusedLocations,
  initializeLocationState,
  changeLocationListState,
  initializeLocationDetailState,
  changeLocationDetailState,
  getLocationList,
  getLocationDetail,
  getLocationVehicle,
  createLocation,
  updateLocation,
  deleteLocation,
  deleteLocations,
  initializeVehicleDetailState,
  getLocationVehicleList,
  initializeLocationVehicleList
} = createActions(
  {
    LOAD_ALL_LOCATIONS: (filters) => {
      return { filters };
    },
    LOAD_UNUSED_LOCATIONS: (filters) => {
      return { filters };
    },
    GET_LOCATION_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_LOCATION_DETAIL: (ID) => ({ ID }),
    GET_LOCATION_VEHICLE: (ID) => ({ ID }),
    CREATE_LOCATION: (data) => ({ data }),
    UPDATE_LOCATION: (ID, data) => ({ ID, data }),
    DELETE_LOCATION: (ID) => ({ ID }),
    GET_LOCATION_VEHICLE_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters })
  },
  'INITIALIZE_LOAD_ALL_LOCATIONS',
  'INITIALIZE_LOAD_UNUSED_LOCATIONS',
  'INITIALIZE_LOCATION_STATE',
  'CHANGE_LOCATION_LIST_STATE',
  'INITIALIZE_LOCATION_DETAIL_STATE',
  'CHANGE_LOCATION_DETAIL_STATE',
  'DELETE_LOCATIONS',
  'INITIALIZE_VEHICLE_DETAIL_STATE',
  'INITIALIZE_LOCATION_VEHICLE_LIST'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllLocations = createEntity({
  key: loadAllLocations().type,
  parseData: parseDataForList
});

export const entityLoadUnusedLocations = createEntity({
  key: loadUnusedLocations().type,
  parseData: parseDataForList
});

export const entityLocationList = createEntity({
  key: getLocationList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityLocationDetail = createEntity({
  key: getLocationDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityLocationVehicle = createEntity({
  key: getLocationVehicle().type,
  parseData: vehicleParseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityLocationVehicleList = createEntity({
  key: getLocationVehicleList().type,
  parseData: parseDataForList,
  afterFailureFn: (error) => {
    const {
      data: { errorCode, message }
    } = error;
    if (errorCode === 400) {
      FMSCommon.toast.fail('Location.error.branch.not.exist', null, { location: message });
    }
  }
});
/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  },
  loadUnused: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  },
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  detail: {
    status: null,
    data: {
      latitude: defaultGeoData.latitude, // utils.js
      longitude: defaultGeoData.longitude
      // latitude: 37.45492610406875,
      // longitude: 127.06486316115738
    },
    error: null,
    mode: 'create',
    loading: false
  },
  vehicleList: {
    status: null,
    vehicleData: [],
    count: {
      IN_USE: 0,
      WAIT: 0,
      DISABLE: 0,
      INCIDENT: 0,
      LOW_VOLTAGE: 0,
      NOT_RETURNED: 0,
      UNKNOWN: 0
    },
    filters: {},
    error: null
  },
  vehicleListPage: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const loadAllLocationsReducer = handleActions(
  {
    [initializeLoadAllLocations().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const loadUnusedLocationsReducer = handleActions(
  {
    [initializeLoadUnusedLocations().type]: () => initializeState.loadUnused
  },
  {},
  { produce }
);
const locationListPageReducer = handleActions(
  {
    [initializeLocationState().type]: () => initializeState.list,
    [changeLocationListState().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const locationDetailPageReducer = handleActions(
  {
    [initializeLocationDetailState().type]: () => initializeState.detail,
    [changeLocationDetailState().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const vehicleDetailPageReducer = handleActions(
  {
    [initializeVehicleDetailState().type]: () => initializeState.vehicleList
  },
  {},
  { produce }
);
const locationVehicleListPageReducer = handleActions(
  {
    [initializeLocationVehicleList().type]: () => initializeState.vehicleListPage
  },
  {},
  { produce }
);

// page action reducer 선언
export const location = combineReducers({
  loadAll: createReducer({
    entity: entityLoadAllLocations,
    reducer: loadAllLocationsReducer
  }),
  loadUnused: createReducer({
    entity: entityLoadUnusedLocations,
    reducer: loadUnusedLocationsReducer
  }),
  list: createReducer({
    entity: entityLocationList,
    reducer: locationListPageReducer
  }),
  detail: createReducer({
    entity: entityLocationDetail,
    reducer: locationDetailPageReducer
  }),
  vehicleList: createReducer({
    entity: entityLocationVehicle,
    reducer: vehicleDetailPageReducer
  }),
  vehicleListPage: createReducer({
    entity: entityLocationVehicleList,
    reducer: locationVehicleListPageReducer
  })
});
/** ********************************************** */
