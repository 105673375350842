import axios from 'axios';

/**
 * 주소 -> 위경도 변환
 */
export const getGeocodeAPI = (query) => axios.get('https://dapi.kakao.com/v2/local/search/address.json', {
    headers: {
      Authorization: `KakaoAK ${process.env.FMS_KAKAO_REST_APP_KEY}`
    },
    params: {
      query
    }
  });

/**
 * 위경도 -> 주소 변환
 */
export const getAddressAPI = ({ latitude, longitude }) => axios.get('https://dapi.kakao.com/v2/local/geo/coord2address.json', {
    headers: {
      Authorization: `KakaoAK ${process.env.FMS_KAKAO_REST_APP_KEY}`
    },
    params: {
      y: latitude,
      x: longitude
    }
  });

/**
 * 키워드검색
 */
export const getSearchResultAPI = (query) => axios.get('https://dapi.kakao.com/v2/local/search/keyword.json', {
    headers: {
      Authorization: `KakaoAK ${process.env.FMS_KAKAO_REST_APP_KEY}`
    },
    params: {
      query
    }
  });
