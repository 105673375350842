import { takeLatest } from 'redux-saga/effects';

import { getControlHistoryListAPI, getControlHistoryAPI } from 'v2/apis/controlHistoryApi';

import { createSagaAction, fetchSaga } from 'v2/redux/sagas';

import {
  getControlHistoryList,
  entityControlHistoryList,
  getControlHistory,
  entityControlHistory
} from '.';

/** ********************************************** */
/** constant */
/** ********************************************** */

/** ********************************************** */
/** saga selector */
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getListAsync = createSagaAction.bind(
  null,
  entityControlHistoryList,
  getControlHistoryListAPI
);
const getDetailAsync = createSagaAction.bind(null, entityControlHistory, getControlHistoryAPI);

/**
 * Watcher
 */
export default function* businessWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(getControlHistoryList().type, fetchSaga, getListAsync, entityControlHistoryList);
  yield takeLatest(getControlHistory().type, fetchSaga, getDetailAsync, entityControlHistory);
}
