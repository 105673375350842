import React from 'react';
import { cssTransition, toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import moment from 'moment';

export const FMSCommon = {
  // 토스트 팝업
  toast: {
    autoClose: 2500,
    zoom: cssTransition({
      enter: 'zoomIn',
      exit: 'zoomOut',
      duration: [100, 1000]
    }),
    warn(message, callback, values) {
      toast.info(<FormattedMessage id={message} {...{ values: { ...values, br: <br /> } }} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        transition: this.zoom,
        autoClose: this.autoClose,
        closeButton: false,
        onClose: callback
      });
    },
    success(message, callback, values) {
      toast.success(<FormattedMessage id={message} {...{ values: { ...values, br: <br /> } }} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        transition: this.zoom,
        autoClose: this.autoClose,
        closeButton: false,
        onClose: callback
      });
    },
    fail(message, callback, values) {
      toast.error(<FormattedMessage id={message} {...{ values: { ...values, br: <br /> } }} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        transition: this.zoom,
        autoClose: this.autoClose,
        closeButton: false,
        onClose: callback
      });
    }
  },
  phoneFormat(phoneNumber, isNumber) {
    if (!phoneNumber) {
      return phoneNumber;
    }

    if (phoneNumber.startsWith('+')) {
      return this.mobileNumberFormat(phoneNumber, isNumber);
    }
    if (phoneNumber.startsWith('01')) {
      return this.mobileNumberFormat(phoneNumber, isNumber);
    }
    return this.phoneNumberFormat(phoneNumber, isNumber);
  },
  phoneNumberFormat: (phoneNumber, isNumber) => {
    if (!phoneNumber) {
      return phoneNumber;
    }

    // 02_서울특별시
    // 031_경기도
    // 032_인천광역시
    // 033_강원도
    // 041_충청남도
    // 042_대전광역시
    // 043_충청북도
    // 044_세종특별자치시
    // 051_부산광역시
    // 052(2)_울산광역시, 052, 0522
    // 053_대구광역시
    // 054_경상북도
    // 055_경상남도
    // 061_전라남도
    // 062_광주광역시
    // 063_전라북도
    // 064_제주특별자치도(700번대만 사용)
    // 070, 080
    // 15YY, 16YY, 17YY, 18YY, 19YY

    const numValue = isNumber ? phoneNumber.replace(/[^0-9]/g, '') : phoneNumber.replace(/-/g, '');
    const firstNum = phoneNumber.substring(0, 2);

    if (numValue.length < 3) return numValue;
    if (['15', '16', '17', '18', '19'].includes(firstNum)) {
      if (numValue.length < 5) return numValue;
      return numValue.replace(/(.{1,4})(.{1,})/, '$1-$2');
    }
    if (['02'].includes(firstNum)) {
      if (numValue.length < 6) return numValue.replace(/(.{1,2})(.{1,})/, '$1-$2');
      if (numValue.length < 10) return numValue.replace(/(.{1,2})(.{1,3})(.{1,})/, '$1-$2-$3');
      return numValue.replace(/(.{1,2})(.{1,4})(.{1,})/, '$1-$2-$3');
    }
    if (numValue.startsWith('052') || numValue.startsWith('0522')) {
      if (numValue.length < 5) return numValue;
      if (numValue.length < 8) return numValue.replace(/(.{1,4})(.{1,})/, '$1-$2');
      if (numValue.length < 12) return numValue.replace(/(.{1,4})(.{1,3})(.{1,})/, '$1-$2-$3');
      return numValue.replace(/(.{1,4})(.{1,4})(.{1,})/, '$1-$2-$3');
    }
    if (numValue.length < 4) return numValue;
    if (numValue.length < 7) return numValue.replace(/(.{1,3})(.{1,})/, '$1-$2');
    if (numValue.length < 11) return numValue.replace(/(.{1,3})(.{1,3})(.{1,})/, '$1-$2-$3');
    return numValue.replace(/(.{1,3})(.{1,4})(.{1,})/, '$1-$2-$3');
  },
  mobileNumberFormat: (phoneNumber, isNumber, onlyNumber) => {
    if (!phoneNumber) {
      return phoneNumber;
    }

    const numValue = onlyNumber // 숫자만 입력 허용시 사용
      ? phoneNumber.replace(/[^0-9]/g, '')
      : isNumber
      ? phoneNumber.replace(/[^0-9|+]/g, '')
      : phoneNumber.replace(/-/g, '');

    if (phoneNumber.startsWith('+')) {
      // +82-1X 기준
      if (numValue.length < 4) {
        return numValue;
      }
      if (numValue.length < 6) {
        return numValue.replace(/(.{1,3})(.{1,})/, '$1-$2');
      }
      if (numValue.length < 9) {
        return numValue.replace(/(.{1,3})(.{1,2})(.{1,})/, '$1-$2-$3');
      }
      if (numValue.length < 12) {
        return numValue.replace(/(.{1,3})(.{1,2})(.{1,3})(.{1,})/, '$1-$2-$3-$4');
      }
      return numValue.replace(/(.{1,3})(.{1,2})(.{1,4})(.{1,})/, '$1-$2-$3-$4');
    }
    // 01X-XXX(X)-XXXX 기준
    if (numValue.length < 4) {
      return numValue;
    }
    if (numValue.length < 8) {
      return numValue.replace(/(.{1,3})(.{1,})/, '$1-$2');
    }
    if (numValue.length < 11) {
      return numValue.replace(/(.{1,3})(.{1,3})(.{1,})/, '$1-$2-$3');
    }
    return numValue.replace(/(.{1,3})(.{1,4})(.{1,})/, '$1-$2-$3');
  },
  // 사업자 등록번호 포맷
  businessRegNumFormat: (businessRegNum) => {
    if (!businessRegNum) {
      return businessRegNum;
    }

    businessRegNum = businessRegNum.replace(/[^0-9]/g, '');

    return businessRegNum.replace(/(\d{3})(\d{2})(\d)/, '$1-$2-$3');
  },
  // 운전면허번호 포맷
  licenseFormat: (license) => {
    if (!license) {
      return license;
    }
    // license = license.replace(/[^0-9]/g, '');
    // let tmp = '';

    // if (license.length < 3) {
    //  return license;
    // } if (license.length < 9) {
    //  tmp += license.substr(0, 2);
    //  tmp += '-';
    //  tmp += license.substr(2);
    //  return tmp;
    // }
    // tmp += license.substr(0, 2);
    // tmp += '-';
    // tmp += license.substr(2, 6);
    // tmp += '-';
    // tmp += license.substr(8);
    // return tmp;

    return license;
  },
  // 양의 정수값
  positiveInteger: {
    // 3자리마다 comma
    toLocale(value, defaultValue = 0) {
      if (!value) return defaultValue;

      let numValue = defaultValue;
      if (value)
        numValue = typeof value === 'number' ? value : Number(`${value}`.replace(/[^0-9]/g, ''));

      if (Number.isNaN(numValue)) return defaultValue;
      return numValue.toLocaleString();
    },
    // 숫자만
    toNumber(value, defaultValue = 0) {
      if (!value) return defaultValue;

      let numValue = defaultValue;
      if (value)
        numValue = typeof value === 'number' ? value : Number(`${value}`.replace(/[^0-9]/g, ''));

      if (Number.isNaN(numValue)) return defaultValue;
      return numValue;
    }
  },
  realNumber: {
    // 키보드 입력값 유지 하면서, realNumber 유지하기
    format(value, placeValueCount = 2, defaultValue = '') {
      if (!value) return defaultValue;

      let numValue = defaultValue;
      let arStrValue = null;
      const isMinus = String(value).startsWith('-');
      if (typeof value === 'number') {
        numValue = value;
      } else {
        const strValue = value.replace(/,/g, '');
        arStrValue = strValue.split('.');
        if (arStrValue) {
          if (arStrValue[0]) arStrValue[0] = arStrValue[0].replace(/[^0-9]/g, '');
          if (arStrValue.length > 1) {
            arStrValue[1] = `.${arStrValue[1]
              .replace(/[^0-9]/g, '')
              .substring(0, placeValueCount)}`;
          }
        } else {
          return defaultValue;
        }
        numValue = Number(
          `${arStrValue && arStrValue[0] ? arStrValue[0] : ''}${
            arStrValue && arStrValue[1] ? arStrValue[1] : ''
          }`
        );
      }

      if (Number.isNaN(numValue)) return defaultValue;
      return `${isMinus ? '-' : ''}${
        arStrValue && arStrValue[0] ? arStrValue[0].replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : ''
      }${arStrValue && arStrValue[1] ? arStrValue[1] : ''}`;
    },
    // 3자리마다 comma, 소수점 2자리.
    toLocale(value, defaultValue = 0) {
      if (!value) return defaultValue;

      let numValue = defaultValue;
      if (value) {
        if (typeof value === 'number') {
          numValue = value;
        } else {
          const pattern = /^\d+\.?\d{0,2}?$/g;
          value = value.replace(/,/gi, '');
          if (pattern.test(value)) {
            numValue = value;
          } else {
            if (value.indexOf('.') === 0) {
              return defaultValue;
            }
            numValue = value.substring(0, value.length - 1);
          }
        }
      }
      if (Number.isNaN(numValue)) return defaultValue;
      numValue = numValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return numValue;
    },
    // 숫자만
    toNumber(value, defaultValue = 0) {
      if (!value) return defaultValue;

      let numValue = defaultValue;
      if (value) numValue = typeof value === 'number' ? value : value.replace(/,/g, '');

      if (Number.isNaN(numValue)) return defaultValue;
      return numValue;
    },
    // 3자리마다 comma, 소수점 2자리 및 정수 9자리 입력체크(※ 123456789.12)
    // 대여요금관리상세화면 주중요금, 주말요금 입력 시 사용
    charge(value, defaultValue = 0) {
      if (!value) return defaultValue;

      let numValue = defaultValue;
      if (value) {
        if (typeof value === 'number') {
          numValue = value;
        } else {
          const pattern = /^\d+,?\d{0,2}?$/g;
          value = value.replace(/[.]/gi, '');
          if (pattern.test(value)) {
            numValue = value;
            const idx = value.indexOf(',');
            if (idx > -1) {
              if (value.substring(0, idx).length > 9) {
                numValue = `${value.substring(0, 9)}.${value.split(',')[1]}`;
              }
            } else if (value.length > 9) {
              numValue = value.substring(0, 9);
            }
          } else {
            if (value.indexOf(',') === 0) {
              return defaultValue;
            }
            numValue = value.substring(0, value.length - 1);
          }
        }
      }

      if (Number.isNaN(numValue)) return defaultValue;
      numValue = numValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return numValue;
    }
  },
  Integer: {
    feeFormat(fee) {
      fee = Number(fee).toFixed(2);
      const uncommaFee = this.intUncomma(fee);
      fee = String(uncommaFee);
      return fee.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    },
    italyFeeFormat(fee) {
      const uncommaFee = this.italyIntUncomma(fee);
      fee = String(uncommaFee);
      return fee.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1.');
    },
    intUncomma(str) {
      str = String(str);
      const rtn = parseFloat(str.replace(/,/gi, ''));
      if (Number.isNaN(Number(rtn))) {
        return 0;
      }

      return rtn;
    },
    italyIntUncomma(str) {
      str = String(str);
      const rtn = parseFloat(str.replace(/\./gi, ''));
      if (Number.isNaN(Number(rtn))) {
        return 0;
      }

      return rtn;
    }
  },
  // 숫자 3자리마다 콤마
  feeFormat(fee) {
    const uncommaFee = String(fee).replace(/,/gi, '');
    fee = String(uncommaFee);
    return fee.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  },
  uncomma(str) {
    str = String(str);
    return str.replace(/[^\d]+/g, '');
  },
  /* DATA넘어온 값을 그대로 확용하기 위한 함수 추가_김하림_20210201 */
  returnOriginFormat(fee) {
    return fee;
  },
  uncommaIncludeMinus(str) {
    str = String(str);
    return str.replace(/[^\d|-]+/g, '');
  },
  openSuccessToast(message, callback) {
    this.toast.success(message);
    callback();
  },
  getCodeLabelWithValue(codeList, codeValue) {
    if (!codeList || !codeList.length) {
      return '';
    }
    const filtered = codeList.filter((item) => {
      return item.value === codeValue;
    });

    if (!filtered || !filtered.length) {
      return '';
    }
    return filtered[0].label;
  },
  // millisecods -> dataFormat
  DateFormat(milliseconds, dateFormat) {
    if (!Number(milliseconds)) {
      return milliseconds;
    }
    return format(new Date(Number(milliseconds)), dateFormat);
  },
  changeDateFormat(date, dateFormat) {
    return format(new Date(date), dateFormat);
  },
  expiryDateFormat: (expiryDate, isNumber) => {
    if (!expiryDate) {
      return expiryDate;
    }
    const numValue = isNumber ? expiryDate.replace(/[^0-9|+]/g, '') : expiryDate.replace(/-/g, '');
    // if (numValue.length < 5) {
    //   return numValue.replace(/(.{1,4})(.{1,})/, '$1.$2');
    // }
    // if (numValue.length < 8) {
    //   return numValue.replace(/(.{1,4})(.{1,3})(.{1,})/, '$1.$2.$3');
    // } else {
    //   return numValue.replace(/(.{1,4})(.{1,2})(.{1,})/, '$1.$2.$3');
    // }

    if (numValue.length < 3) {
      return numValue.replace(/(.{1,2})(.{1,})/, '$1.$2');
    }
    if (numValue.length < 8) {
      return numValue.replace(/(.{1,2})(.{1,2})(.{1,})/, '$1.$2.$3');
    }

    return numValue.replace(/(.{1,2})(.{1,2})(.{1,})/, '$1.$2.$3');
  },

  stringToDate(stringDate) {
    const year = stringDate.substring(0, 4);
    const month = stringDate.substring(4, 6);
    const day = stringDate.substring(6.8);
    return new Date(year, month - 1, day).getTime();
  },

  stringToDateYYYYMM(stringDate) {
    if (!stringDate) return false;
    const year = stringDate.substring(0, 4);
    const month = stringDate.substring(4, 6);
    return new Date(year, month - 1);
  },
  newlicenseFormat: (license) => {
    if (!license) {
      return license;
    }
    license = license.replace(/[^0-9]/g, '');
    let tmp = '';

    if (license.length < 3) {
      return license;
    }
    tmp += license.substring(0, 2);
    tmp += '-';
    tmp += license.substring(2, 4);
    tmp += '-';
    tmp += license.substring(4, 10);
    tmp += '-';
    tmp += license.substring(10, 12);
    return tmp;
  },

  insurancePay(payment) {
    if (typeof payment !== 'string') {
      return payment;
    }
    const integerData = payment.split('.');
    return integerData[0].replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,').concat('.', integerData[1]);
  },

  licenseFormatItaly: (license) => {
    if (!license) {
      return license;
    }

    // 특수문자 제거
    // license = license.replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi, '');
    license = license.replace(/[\\{\\}\\[\]\\/?.,;:|\\)*~`!^\-_+<>@\\#$%&\\\\=\\(\\'\\"\s]/g, '');
    // 혹시 몰라 한글 제거
    license = license.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '');
    // 공백 제거
    license = license.replace(/ /gi, '');
    license = license.toUpperCase();

    return license;
  },
  timeFormat(timevalue) {
    const uncommaTime = String(timevalue).replace(/:/gi, '');
    timevalue = String(uncommaTime);
    return timevalue.replace(/(\d)(?=(?:\d{2})+(?!\d))/g, '$1:');
  },

  getPaymentCharge(reservationShort, mileageCharge, intl) {
    const basicCharge = reservationShort?.basicCharge || 0;
    const additionalCostDetail = reservationShort?.additionalCostDetail || [];
    const couponAmount = reservationShort?.couponAmount || 0;
    const discountCharge = reservationShort?.discountCharge || 0;
    const refundAmount = reservationShort?.refundAmount || 0;
    const mileageExceededChargeCost = mileageCharge?.mileageExceededChargeCost || 0;

    let additionalCostList = '';
    let additionalCost = 0; // 리턴되는 additionalCost는 보험료만 포함되어 직접 계산
    if (additionalCostDetail?.length) {
      const currency = intl.formatMessage(
        { id: 'Common.Unit.Money.0' },
        { symbol: FMSCommon.getCurrencySymbol() }
      );

      const list = [];
      additionalCostDetail.forEach((item) => {
        let title = '';
        if (item.name === 'insurance') {
          title = intl.formatMessage({ id: 'ShortRental.Charge.Insurance' });
        } else if (item.name === 'accessory') {
          title = intl.formatMessage({ id: 'Title.Accessory.And.Service' });
        } else if (item.name === 'mileage') {
          title = intl.formatMessage({ id: 'Charge.Mileage.Title' });
        }

        const cost = item.cost || 0;
        additionalCost += cost;
        list.push(`${title}: ${FMSCommon.feeFormat(cost)} ${currency}`);
      });

      additionalCostList = list.join(', ');
    }

    return {
      basicCharge,
      additionalCostList,
      additionalCost,
      couponAmount,
      discountCharge,
      refundAmount,
      mileageExceededChargeCost
    };
  },
  getPaymentChargeExceptInsurance(reservationShort, mileageCharge, intl) {
    const basicCharge = reservationShort?.basicCharge || 0;
    const additionalCostDetail = reservationShort?.additionalCostDetail || [];
    const couponAmount = reservationShort?.couponAmount || 0;
    const discountCharge = reservationShort?.discountCharge || 0;
    const refundAmount = reservationShort?.refundAmount || 0;
    const mileageExceededChargeCost = mileageCharge?.mileageExceededChargeCost || 0;

    let additionalCostList = '';
    let additionalCost = 0; // 리턴되는 additionalCost는 보험료만 포함되어 직접 계산
    if (additionalCostDetail?.length) {
      const currency = intl.formatMessage(
        { id: 'Common.Unit.Money.0' },
        { symbol: FMSCommon.getCurrencySymbol() }
      );

      const list = [];
      additionalCostDetail.forEach((item) => {
        let title = '';

        if (item.name === 'insurance') {
          return;
        }

        if (item.name === 'accessory') {
          title = intl.formatMessage({ id: 'Title.Accessory.And.Service' });
        } else if (item.name === 'mileage') {
          title = intl.formatMessage({ id: 'Charge.Mileage.Title' });
        }
        const cost = item.cost || 0;
        additionalCost += cost;
        list.push(`${title}: ${FMSCommon.displayNumberFormat(cost)} ${currency}`);
      });

      additionalCostList = list.join(', ');
    }

    return {
      basicCharge,
      additionalCostList,
      additionalCost,
      couponAmount,
      discountCharge,
      refundAmount,
      mileageExceededChargeCost
    };
  },
  getCurrencySymbol() {
    let symbol = '€';
    switch (window.currentCountryCd) {
      case 'PL':
        symbol = 'zł';
        break;
      case 'RU':
        symbol = '₽';
        break;
      case 'EURO':
        symbol = '€';
        break;
      case 'CZ':
        symbol = 'Kč';
        break;
      default:
        symbol = '€';
        break;
    }
    return symbol;
  },
  //화폐단위 선택
  getCurrencySymbolParam(type) {
    let symbol = '€';
    switch (type) {
      case 'PL':
        symbol = 'zł';
        break;
      case 'RU':
        symbol = '₽';
        break;
      case 'EURO':
        symbol = '€';
        break;
      default:
        symbol = '€';
        break;
    }
    return symbol;
  },

  //환율 정보로 화폐단위 결정
  getCurrencyExchangeRate(currency) {
    let symbol = '€';
    //1 : 환율 적용 국가지만 해당 기간에 환율 값이 없음
    //undefined: 환율 적용 국가가 아님
    if (currency && currency !== 1) {
      symbol = FMSCommon.getCurrencySymbolParam('EURO');
    } else {
      symbol = FMSCommon.getCurrencySymbol();
    }
    return symbol;
  },

  // 디스플레이 되는 숫자 유로표기로 변경 요청으로 인한 추가
  // 차후 국가별 표기 변경
  displayNumberFormat(nVal) {
    let retVal = Intl.NumberFormat('de').format(nVal);
    if (window.currentCountryCd === 'RU') {
      retVal = Intl.NumberFormat('ru').format(nVal);
    }
    // const retVal = Intl.NumberFormat('de').format(nVal);
    if (retVal === 'NaN') return 0;
    return retVal;
  },
  unParseDisplayNumberFormat(nVal) {
    return (nVal || 0)
      .toString()
      .replace(/\./gi, ',')
      .replace(/,/gi, '.');
  },
  // 2021.12.01 이미지 파일 확장자 체크f
  checkUploadImageFileExtension(fileName) {
    const spFileName = fileName.split('.');
    const fileExt = spFileName[spFileName.length - 1].toLowerCase(); // 파일 확장자
    const imgExtArr = process.env.FMS_IMAGE_EXTENSION.replace(/\./g, '').split(','); // 등록가능 확장자
    if (imgExtArr.indexOf(fileExt) < 0) {
      return false;
    }
    return true;
  },
  // 2021.12.01 파일명 체크
  checkUploadFileName(fileName) {
    const IMPOSSIBLE_CHAR = /[\\/:*?"<>|\s ]/gi; // 입력 불가 문자
    if (IMPOSSIBLE_CHAR.test(fileName) === true) {
      return false;
    }
    return true;
  },
  //브랜치 멀티 선택시 filter 값 세팅 공통 함수
  setFilterBranchID(branchID) {
    if (!branchID) {
      //1상단 브랜치 All일 경우 0 or 2. 검색옵션 selectbox선택안하면 null
      return '_All';
    } else if (!Array.isArray(branchID)) {
      //상단 브랜치 선택시 : findValueBySelector 함수 문자열 return으로 형변환
      return branchID.toString();
    } else if (branchID && branchID.length) {
      //select box 브랜치 선택
      if (branchID[0].value === '_All') return '_All';
      return branchID
        .filter((item) => item.value !== '_All')
        .map((item) => item.value)
        .join();
    } else {
      return '_All';
    }
  },
  setThisMonthStartDate() {
    return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  },
  setThisMonthEndDate() {
    return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  },
  setThisYearStartDate() {
    return moment()
      .startOf('year')
      .toDate()
      .getTime();
  },
  setThisYearEndDate() {
    return moment()
      .endOf('year')
      .toDate()
      .getTime();
  },
  getYearFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.getFullYear();
  }
};
