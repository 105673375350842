import styled from 'styled-components';

const FMSIconBtnContainer = styled.div`
  background: url('${process.env.PUBLIC_URL}/assets/images/calendar.png') no-repeat;
  background-size: 24px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 25px;
  background-position: center;
  cursor: pointer;
`;

const FMSIconBtn = styled.img`
  cursor: pointer;
  vertical-align: middle;
`;

const FMSIconBtnArrowBack = styled(FMSIconBtn)`
  content: url('${process.env.PUBLIC_URL}/assets/images/arrow-back.png');
`;

const FMSIconBtnArrow = styled(FMSIconBtn)`
  content: url('${process.env.PUBLIC_URL}/assets/images/arrow.png');
`;

const FMSIconBtnCalendar = styled(FMSIconBtn)`
  content: url('${process.env.PUBLIC_URL}/assets/images/calendar.png');
  border-radius: 25px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  width: 24px;
  height: 24px;
  padding: 12px;
`;

const FMSIconBtnClose = styled(FMSIconBtn)`
  background: url('${process.env.PUBLIC_URL}/assets/images/x.png') no-repeat;
  background-size: 24px;
  content: url('${process.env.PUBLIC_URL}/assets/images/x.png');
  width: 24px;
  height: 24px;
  // padding: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

export {
  FMSIconBtnContainer,
  FMSIconBtn,
  FMSIconBtnArrow,
  FMSIconBtnArrowBack,
  FMSIconBtnCalendar,
  FMSIconBtnClose
};
