import axios from 'axios';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
  retryAdapterEnhancer
} from 'axios-extensions';
import { FMSCommon } from 'service/common/commonLib';
import history from 'service/common/history';
import store from 'v2/redux/store';
import { initializeProfile } from 'v2/redux/modules/authentication';
import fp from 'lodash/fp';

export const getAxiosAdapter = () => {
  const isLocal = fp.equals('로컬', process.env.FMS_NAME);

  return axios.defaults.adapter;
  // local Mode 백엔드 호출 cache 삭제
  // return isLocal
  //   ? retryAdapterEnhancer(throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter)), {
  //       times: 1
  //     })
  //   : axios.defaults.adapter;
};
const httpConfig = {
  baseURL: process.env.FMS_API_ENDPOINT,
  timeout: 30000,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8'
  },
  withCredentials: true,
  adapter: getAxiosAdapter()
};

let cancelSource = axios.CancelToken.source();

export function createRequest() {
  return axios.create(httpConfig);
}

/**
 * Returns a Axios Request Promise
 * method : get, post, put, patch, delete
 */
export default fp.memoize(({ url, method = 'get', data, config, axiosInstance }) => {
  axiosInstance = axiosInstance || createRequest();
  axiosInstance.interceptors.response.use(undefined, (err) => {
    const error = err && err.response;
    if (!error) return Promise.reject(error);
    const authID =
      store.getState().authentication &&
      store.getState().authentication.profile &&
      store.getState().authentication.profile.data
        ? store.getState().authentication.profile.data.ID
        : null;
    // 세션 만료 알림 안내
    const sessionExpired = !!authID;
    // 원본 request Fail message 처리 방지.
    let ignoreReject = false;
    if (error.status === 401 && !['/redirect'].includes(window.location.pathname)) {
      if (sessionExpired) {
        store.dispatch(initializeProfile());
        FMSCommon.toast.fail('Auth.Alert.Expired');
      }
      if (
        !(
          error &&
          error.config &&
          error.config &&
          error.config.url &&
          error.config.url.endsWith('/profile')
        )
      )
        ignoreReject = true;
    } else if (error.status === 403) {
      history.goBack();
      FMSCommon.toast.fail('Auth.Alert.Forbidden');
    }
    return ignoreReject ? Promise.resolve(error) : Promise.reject(error);
  });

  switch (method) {
    case 'post':
      return axiosInstance.post(url, data, {
        ...config,
        // ...postConfig,
        cancelToken: cancelSource.token
      });
    case 'patch':
      return axiosInstance.patch(url, data, {
        ...config,
        cancelToken: cancelSource.token
      });
    case 'put':
      return axiosInstance.put(url, data, {
        ...config,
        cancelToken: cancelSource.token
      });
    case 'delete':
      if (data) {
        if (!config) {
          config = {};
        }
        config.data = data;
      }
      return axiosInstance.delete(url, {
        ...config,
        cancelToken: cancelSource.token
      });
    case 'file':
      return axiosInstance.get(url, {
        ...config,
        responseType: 'blob',
        params: data,
        cancelToken: cancelSource.token
      });
    case 'cancel':
      cancelSource.cancel();
      cancelSource = axios.CancelToken.source();
      break;
    default:
      return axiosInstance.get(url, {
        ...config,
        params: data,
        cancelToken: cancelSource.token
      });
  }
});
