import { takeLatest } from 'redux-saga/effects';
import { confirmFetchSaga, confirmFetchSagas, createSagaAction, fetchSaga } from 'v2/redux/sagas';
import {
  createCurrencyAPI,
  deleteCurrencyAPI,
  getCurrencyListAPI,
  updateCurrencyAPI
} from 'v2/apis/currencyAPI';

import {
  createCurrency,
  deleteCurrency,
  entityCreateCurrency,
  entityCurrencyList,
  entityDeleteCurrency,
  entityUpdateCurrency,
  getCurrencyList,
  updateCurrency
} from '.';
import { createPush, entityCreatePush } from '../push';
import { deleteAppVersionList } from '../appVersion';
import { entityDeleteAppVersionList } from '../appVersion/appVersionSaga';

/** ********************************************** */
/** constant */
export const historyUrl = '/currency';

/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// 리스트조회
const getCurrencyListAsync = createSagaAction.bind(null, entityCurrencyList, getCurrencyListAPI);
const updateCurrencyAsync = createSagaAction.bind(null, entityUpdateCurrency, updateCurrencyAPI);
const createCurrencyAsync = createSagaAction.bind(null, entityCreateCurrency, createCurrencyAPI);
const deleteCurrencyAsync = createSagaAction.bind(null, entityDeleteCurrency, deleteCurrencyAPI);

export default function* currencyWatcher() {
  yield takeLatest(getCurrencyList().type, fetchSaga, getCurrencyListAsync, entityCurrencyList);
  yield takeLatest(
    createCurrency().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createCurrencyAsync,
    entityCreateCurrency
  );
  yield takeLatest(
    updateCurrency().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateCurrencyAsync,
    entityUpdateCurrency
  );
  yield takeLatest(
    deleteCurrency().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteCurrencyAsync,
    entityDeleteCurrency
  );
}
