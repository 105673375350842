import { put, select, takeLatest } from 'redux-saga/effects';

import { getLocationListAPI } from 'v2/apis/locationApi';

import { changeHomeMenu } from 'v2/redux/modules/homeMenu';
import {
  getVehicleAvailableListAPI,
  getInsuranceChargeListAPI,
  getInsuranceCostAPI,
  searchAgentAPI,
  searchUserAPI,
  searchCurrentCompanyAPI,
  checkLicenseAPI,
  postReservationAPI,
  getEstimateAPI,
  getReservationListAPI,
  createEstimateAPI,
  deleteEstimatesAPI,
  downloadCSVAPI,
  returnAPI,
  forceReturnAPI,
  extendAPI,
  getReservationUseImgListAPI,
  deletesRentalAPI
} from 'v2/apis/reservationApi';
import { createSagaAction, confirmFetchSaga, fetchSaga, confirmDelFetchSaga } from 'v2/redux/sagas';
import { fetchSagaNew } from 'v2/redux/lib/reducerLibNew';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import history from 'service/common/history';

import { getErrorMessage } from 'constants/errors';
import {
  getReservationLocationList,
  entityGetLocationList,
  getVehicleAvailableList,
  entityGetVehicleAvailableList,
  getInsuranceChargeList,
  entityGetInsurancechargeList,
  getInsuranceCost,
  entityGetInsuranceCost,
  getAgentList,
  entityGetAgentList,
  getUserList,
  entityGetUserList,
  getCompanyUserList,
  entityGetCompanyList,
  checkLicense,
  entityCheckLicense,
  postReservation,
  entityPostReservation,
  getEstimate,
  entityGetEstimaterList,
  getRentList,
  entityGetReservationList,
  deleteEstimate,
  createEstimate,
  changeEstimate,
  downloadCsv,
  entityDownloadCSV,
  extendRental,
  returnVehicle,
  forceReturnVehicle,
  postRentalReservation,
  getReservationUseImgList,
  entityReservationUseImg,
  changeProgress,
  deletesRentalStatus,
  updateAddressInfo,
  entityUpdateAddressInfo,
  updateInvoicingInfo,
  entityUpdateInvoicingInfo,
  deleteInvoicingInfo,
  entityDeleteInvoicingInfo,
  getDamageSpecificationList,
  entityGetDamageSpecificationList,
  registerDamageSpecificationImageGroup,
  entityRegisterDamageSpecificationImageGroup,
  createDamageSpecification,
  entityCreateDamageSpecification,
  deleteDamageSpecification,
  entityDeleteDamageSpecification,
  updateReservationNoConfirm,
  entityUpdateReservationNoConfirm,
  inuseUpdateReservation,
  entityInuseUpdateReservation,
  entitySendInvoiceEmail,
  sendInvoiceEmail,
  updateCompanyAddressInfo,
  entityUpdateCompanyAddressInfo,
  entityUpdateRentalCompanyInfo,
  updateRentalCompanyInfo
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/rentStatus';
export const selectorRentStatusPage = (state) => {
  return state.reservationSearch.list;
};
export const selectorReservationPage = (state) => state.reservation.reservationList;
export const selectorRentItsPage = (state) => {
  return state.reservation.list;
};

/** ********************************************** */

/** ********************************************** */
/** saga selector */
// export const selectorEstimateListPage = state => state.estimate.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
export const entityCreateEstimate = createEntity({
  key: createEstimate().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },

  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Common.Created.Success');
    const reservationParam = {
      reservationID: requestParams.data.reservationID
    };
    yield put(getEstimate(reservationParam));
  }
});

export const entityDeleteEstimate = createEntity({
  key: deleteEstimate().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeEstimate({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');

    return isContinue;
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Common.Deleted.Success');
    const reservationParam = {
      reservationID: requestParams.IDArr[0].reservationID
    };
    yield put(getEstimate(reservationParam));
  },
  *afterFailureFn(payload, requestParams) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const reservationParam = {
      reservationID: requestParams[0].reservationID
    };
    yield put(getEstimate(reservationParam));
  }
});

export const entityExtend = createEntity({
  key: extendRental().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else if (payload.status === 510) FMSCommon.toast.fail('Rent.modal.Extend.Duplicate');
    else FMSCommon.toast.fail('Rent.modal.Extend.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Reservation.Extend.Complete');
    const selectorListPage = yield select(selectorRentStatusPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: historyUrl,
      state: { filters, pageInfo, sortInfo }
    });
  }
});

export const entityReturnVehicle = createEntity({
  key: returnVehicle().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Rent.modal.Return.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Reservation.Return.Complete');
    const selectorListPage = yield select(selectorRentStatusPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: historyUrl,
      state: { filters, pageInfo, sortInfo }
    });
  }
});

export const entityForceReturnVehicle = createEntity({
  key: forceReturnVehicle().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Rent.modal.Force.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Reservation.ForceReturn.Complete');
    const selectorListPage = yield select(selectorRentStatusPage);
    const { filters, pageInfo, sortInfo } = selectorListPage;
    history.push({
      pathname: historyUrl,
      state: { filters, pageInfo, sortInfo }
    });
  }
});

export const entityRentalPostReservation = createEntity({
  key: postRentalReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    if (payload.code && payload.code !== 0 && payload.code !== 2 && payload.code !== 3) {
      FMSCommon.toast.success(getErrorMessage(payload.code));
    } else {
      FMSCommon.toast.success('Common.Created.Success');
      const selectorListPage = yield select(selectorRentStatusPage);
      const { filters, pageInfo, sortInfo } = selectorListPage;
      history.push({
        pathname: `${historyUrl}/${payload.ID}`,
        state: { filters, pageInfo, sortInfo }
      });
      yield put(
        changeHomeMenu({
          pathname: `${historyUrl}/${payload.ID}`,
          state: { filters, pageInfo, sortInfo }
        })
      );
    }
  }
});
// 삭제
// export const entityDeleteRent = createEntity({
//   key: deleteRentalStatus().type,
//   parseError: parseErrorWithErrorField,
//   afterFailureFn: payload => {
//     if (payload && payload.errorCode)
//       FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
//     else FMSCommon.toast.fail('Common.Delete.Fail');
//   },
//   *afterSuccessFn() {
//     FMSCommon.toast.success('Common.Deleted.Success');
//     const selectorListPage = yield select(selectorReservationPage);
//     const { pager, status } = selectorListPage;
//     history.push({ pathname: historyUrl, state: { pager, path: status } });
//   },
// });
// 일괄삭제
// mulitple delete for list page.
export const entityDeleteForStatus = createEntity({
  key: deletesRentalStatus().type,
  beforeFn(payload) {
    const isContinue = payload.IDArr && payload.IDArr.length;
    if (!isContinue) {
      FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    }
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorReservationPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;

    yield put(getRentList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    // console.log('실패', payload);
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorReservationPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getRentList(pageInfo, sortInfo, filters));
  }
});

/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getLocationListAsync = createSagaAction.bind(null, entityGetLocationList, getLocationListAPI);
const getVehicleAvailableListAsync = createSagaAction.bind(
  null,
  entityGetVehicleAvailableList,
  getVehicleAvailableListAPI
);
const getInsurancechargeListAsync = createSagaAction.bind(
  null,
  entityGetInsurancechargeList,
  getInsuranceChargeListAPI
);
const getInsurancecCostAsync = createSagaAction.bind(
  null,
  entityGetInsuranceCost,
  getInsuranceCostAPI
);
const getAgentListAsync = createSagaAction.bind(null, entityGetAgentList, searchAgentAPI);
const getUserListAsync = createSagaAction.bind(null, entityGetUserList, searchUserAPI);
const getCompanyListAsync = createSagaAction.bind(
  null,
  entityGetCompanyList,
  searchCurrentCompanyAPI
);
const checkLicenseAsync = createSagaAction.bind(null, entityCheckLicense, checkLicenseAPI);
const postReservationAsync = createSagaAction.bind(null, entityPostReservation, postReservationAPI);
const getEstimateAsync = createSagaAction.bind(null, entityGetEstimaterList, getEstimateAPI);
const getReservationListAsync = createSagaAction.bind(
  null,
  entityGetReservationList,
  getReservationListAPI
);
const createEstimateAsync = createSagaAction.bind(null, entityCreateEstimate, createEstimateAPI);
const deleteEstimateAsync = createSagaAction.bind(null, entityDeleteEstimate, deleteEstimatesAPI);
const downloadCSVAsync = createSagaAction.bind(null, entityDownloadCSV, downloadCSVAPI);
const extendAsync = createSagaAction.bind(null, entityExtend, extendAPI);
const returnVehicleAsync = createSagaAction.bind(null, entityReturnVehicle, returnAPI);
const forceReturnVehicleAsync = createSagaAction.bind(
  null,
  entityForceReturnVehicle,
  forceReturnAPI
);
const postRentalReservationAsync = createSagaAction.bind(
  null,
  entityRentalPostReservation,
  postReservationAPI
);
const getReservationUseImgListAsync = createSagaAction.bind(
  null,
  entityReservationUseImg,
  getReservationUseImgListAPI
);

// 일괄삭제
const deleteForRentalAsync = createSagaAction.bind(null, entityDeleteForStatus, deletesRentalAPI);

/**
 * Watcher
 */
export default function* reservationWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    getReservationLocationList().type,
    fetchSaga,
    getLocationListAsync,
    entityGetLocationList
  );
  yield takeLatest(
    getVehicleAvailableList().type,
    fetchSaga,
    getVehicleAvailableListAsync,
    entityGetVehicleAvailableList
  );
  yield takeLatest(
    getInsuranceChargeList().type,
    fetchSaga,
    getInsurancechargeListAsync,
    entityGetInsurancechargeList
  );
  yield takeLatest(
    getInsuranceCost().type,
    fetchSaga,
    getInsurancecCostAsync,
    entityGetInsuranceCost
  );
  yield takeLatest(getAgentList().type, fetchSaga, getAgentListAsync, entityGetAgentList);
  yield takeLatest(getUserList().type, fetchSaga, getUserListAsync, entityGetUserList);
  yield takeLatest(getCompanyUserList().type, fetchSaga, getCompanyListAsync, entityGetCompanyList);
  yield takeLatest(checkLicense().type, fetchSaga, checkLicenseAsync, entityCheckLicense);
  yield takeLatest(postReservation().type, fetchSaga, postReservationAsync, entityPostReservation);
  yield takeLatest(getEstimate().type, fetchSaga, getEstimateAsync, entityGetEstimaterList);
  yield takeLatest(
    getRentList().type,
    fetchSaga,
    getReservationListAsync,
    entityGetReservationList
  );
  yield takeLatest(
    deleteEstimate().type,
    confirmFetchSaga,
    null,
    deleteEstimateAsync,
    entityDeleteEstimate
  );
  yield takeLatest(
    createEstimate().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createEstimateAsync,
    entityCreateEstimate
  );
  yield takeLatest(downloadCsv().type, fetchSaga, downloadCSVAsync, entityDownloadCSV);
  yield takeLatest(extendRental().type, fetchSaga, extendAsync, entityExtend);
  yield takeLatest(returnVehicle().type, fetchSaga, returnVehicleAsync, entityReturnVehicle);
  yield takeLatest(
    forceReturnVehicle().type,
    fetchSaga,
    forceReturnVehicleAsync,
    entityForceReturnVehicle
  );
  yield takeLatest(
    postRentalReservation().type,
    fetchSaga,
    postRentalReservationAsync,
    entityRentalPostReservation
  );
  yield takeLatest(
    getReservationUseImgList().type,
    fetchSaga,
    getReservationUseImgListAsync,
    entityReservationUseImg
  );
  // 일괄삭제
  yield takeLatest(
    deletesRentalStatus().type,
    confirmDelFetchSaga,
    'Common.Confirm.Deletes',
    deleteForRentalAsync,
    entityDeleteForStatus
  );
  yield takeLatest(updateAddressInfo().type, fetchSagaNew, entityUpdateAddressInfo);
  yield takeLatest(updateCompanyAddressInfo().type, fetchSagaNew, entityUpdateCompanyAddressInfo);
  yield takeLatest(updateRentalCompanyInfo().type, fetchSagaNew, entityUpdateRentalCompanyInfo);
  yield takeLatest(updateInvoicingInfo().type, fetchSagaNew, entityUpdateInvoicingInfo);
  yield takeLatest(deleteInvoicingInfo().type, fetchSagaNew, entityDeleteInvoicingInfo);
  yield takeLatest(sendInvoiceEmail().type, fetchSagaNew, entitySendInvoiceEmail);
  yield takeLatest(
    getDamageSpecificationList().type,
    fetchSagaNew,
    entityGetDamageSpecificationList
  );
  yield takeLatest(
    registerDamageSpecificationImageGroup().type,
    fetchSagaNew,
    entityRegisterDamageSpecificationImageGroup
  );
  yield takeLatest(createDamageSpecification().type, fetchSagaNew, entityCreateDamageSpecification);
  yield takeLatest(deleteDamageSpecification().type, fetchSagaNew, entityDeleteDamageSpecification);
  yield takeLatest(
    updateReservationNoConfirm().type,
    fetchSagaNew,
    entityUpdateReservationNoConfirm
  );
  yield takeLatest(inuseUpdateReservation().type, fetchSagaNew, entityInuseUpdateReservation);
}
