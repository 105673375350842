import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeLoadAllHoliday,
  loadAllHoliday,

  getNewHolidayList,
  getNewHolidayDetail,
  createNewHoliday,
  updateNewHoliday,
  deleteNewHoliday,
  // getHolidayBusinessList,
  initializeNewHolidayState,
  changeNewHolidayState,
  initializeNewHolidayDetailState,
  changeNewHolidayDetailState
} = createActions(
  {
    LOAD_ALL_HOLIDAY: (filters) => ({ filters }),
    GET_NEW_HOLIDAY_LIST: (filters) => ({ filters }),
    GET_NEW_HOLIDAY_DETAIL: (ID, data) => ({ ID, data }),
    CREATE_NEW_HOLIDAY: (data) => ({ data }),
    UPDATE_NEW_HOLIDAY: (ID, data) => ({ ID, data }),
    DELETE_NEW_HOLIDAY: (ID, day) => ({ ID, day })
  },
  'INITIALIZE_LOAD_ALL_HOLIDAY',
  'INITIALIZE_NEW_HOLIDAY_STATE',
  'CHANGE_NEW_HOLIDAY_STATE',
  'INITIALIZE_NEW_HOLIDAY_DETAIL_STATE',
  'CHANGE_NEW_HOLIDAY_DETAIL_STATE'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllHolidayList = createEntity({
  key: loadAllHoliday().type,
  parseData: parseDataForList
});

export const entityHolidayList = createEntity({
  key: getNewHolidayList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityHolidayDetail = createEntity({
  key: getNewHolidayDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  holidayList: {
    status: null,
    data: [],
    loading: false,
    pager: {
      // pageInfo: { limit: 20, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  holidayDetail: {
    status: null,
    data: null,
    loading: false,
    mode: 'create',
    detailID: null,
    selectedMonthValue: null,
    selectedDay: null
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const holidayListPageReducer = handleActions(
  {
    [initializeNewHolidayState().type]: () => initializeState.holidayList,
    [changeNewHolidayState().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const holidayListLoadAllReducer = handleActions(
  {
    [initializeLoadAllHoliday().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const holidayDetailPageReducer = handleActions(
  {
    [initializeNewHolidayDetailState().type]: () => initializeState.holidayDetail,
    [changeNewHolidayDetailState().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const newHoliday = combineReducers({
  holidayList: createReducer({
    entity: entityHolidayList,
    reducer: holidayListPageReducer
  }),
  holidayDetail: createReducer({
    entity: entityHolidayDetail,
    reducer: holidayDetailPageReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllHolidayList,
    reducer: holidayListLoadAllReducer
  })
});
/** ********************************************** */
