import request from 'apis/request';

const prefix = '/dealersupport';

export const getDealerSupportListAPI = ({ filters = {} }) => {
  const { keywordType, keyword, ...data } = filters;

  if (keyword && keyword !== '') data[keywordType] = keyword;

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 백그라둔드 정보 조회
 */
export const getBackgroundInfoAPI = ({ filters }) => {
  return request({
    url: `${prefix}/backgroundinfo`,
    data: filters
  });
};

/**
 * 백그라둔드 정보 insert
 */
export const insertBackgroundInfoAPI = ({ data }) => {
  return request({
    url: `${prefix}/backgroundinfo`,
    method: 'post',
    data
  });
};
