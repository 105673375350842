import React, { useCallback, memo } from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import CountrySelectContainer from 'v2/pages/country/CountrySelectContainer';
import BusinessSelectContainer from 'v2/pages/user/business/BusinessSelectContainer';
import BranchSelectContainer from 'v2/pages/branch/BranchSelectContainer';
import LanguageSelectContainer from 'v2/pages/authentication/LanguageSelectContainer';
import FMSForm from 'v2/components/ui/input/FMSForm';

import { initializeLoadAllBranch } from 'v2/redux/modules/branch';
import { initializeLoadAllBusinesses } from 'v2/redux/modules/business';
import { initializeMyBusiness, initializeMyBranch } from 'v2/redux/modules/authentication';

import { isRu } from 'v2/lib/utils';
import HomeNavLeftMenu from './HomeNavLeftMenu';
import HomeNavTopMenu from './HomeNavTopMenu';
import { Body, Container, Header, HeaderLogo, HeaderGnb, LNB, Content } from '../styles/HomeStyles';
import { findTreeNode } from '../lib/homeLib';

const stateSelector = createSelector(
  (store) => store.authentication,
  (authentication) => ({
    myCountry: fp.getOr({}, 'myCountry.data', authentication),
    myBusiness: fp.getOr({}, 'myBusiness.data', authentication),
    myBranch: fp.getOr({}, 'myBranch.data', authentication),
    profile: fp.getOr({}, 'profile.data', authentication)
  })
);

const handleMypage = () => {
  window.open(
    `${process.env.FMS_CCSP_HANDLE_PERSONAL_INFO}`,
    'account_change',
    'top=100,left=100,width=1280,height=800'
  );
};
const HomeMain = ({
  loading = false,
  profile,
  handleLogout,
  myCountry,
  handleChangeMyCountry,
  myBusiness,
  handleChangeMyBusiness,
  myBranch,
  handleChangeMyBranch,
  menuRoot,
  leftMenuList,
  selectedMenuIDs,
  handleSelectMenu,
  RouteComponent,
  handleAgreeMember,
  handlePersonalInfo
}) => {
  // test
  // countryCd = "RU"
  const state = useSelector(stateSelector, fp.equals);

  const dispatch = useDispatch();
  const findFirstMenu = findTreeNode(menuRoot, (item) => item.menuUrl && item.menuUrl !== '');
  const checkRole = useCallback(
    fp.curry((prefix, profile) => {
      const composer = fp.pipe(fp.getOr('', 'role'), fp.toUpper, fp.startsWith(prefix));
      const result = composer(profile);

      return result;
    }),
    []
  );
  const {
    role,
    roleDetail,
    branch: { userBranchID }
  } = profile;
  // const { userBranchID } = branch;
  const isSystemRole = useCallback(checkRole('SYSTEM'), [checkRole]);
  // const isMoceanRole = useCallback(checkRole('MOCEAN'), [checkRole]);

  const getMeta = useCallback((data) => {
    return fp.get('[0].meta', data) || fp.get('meta', data) || { ID: '' };
  }, []);

  const handleCountrySelectorChange = useCallback(
    fp.curry((currentCountry, data) => {
      const nextCountry = getMeta(data);

      if (
        fp.notEquals(currentCountry, nextCountry) ||
        fp.isEmpty(currentCountry) ||
        fp.isEmpty(fp.get('ID', currentCountry))
      ) {
        window.currentCountryCd = nextCountry.countryCd;
        handleChangeMyCountry(nextCountry);
        dispatch(initializeLoadAllBusinesses());
        dispatch(initializeLoadAllBranch());
        dispatch(initializeMyBusiness());
      }
    }),
    [getMeta]
  );

  const handleBusinessSelectorChange = useCallback(
    fp.curry((currentBusiness, data) => {
      const nextBusiness = getMeta(data);
      if (fp.notEquals(currentBusiness, nextBusiness)) {
        handleChangeMyBusiness(nextBusiness);
        dispatch(initializeLoadAllBranch());
        // dispatch(initializeMyBranch());

        if (
          _.isEqual(roleDetail, '1') ||
          (_.isEqual(roleDetail, '3') && (userBranchID === 0 || userBranchID === null)) || // 3이면서 branchID없는경우
          _.includes(role, 'SYSTEM') ||
          _.includes(role, 'MOCEAN')
        ) {
          dispatch(initializeMyBranch());
        }
      }
    }),
    [getMeta]
  );

  const handleBranchSelectorChange = useCallback(
    fp.curry((currentBranch, data) => {
      const nextBranch = getMeta(data);
      if (fp.notEquals(currentBranch, nextBranch)) {
        handleChangeMyBranch(nextBranch);
      }
    }),
    []
  );

  return (
    <Body alt='Body' r-if={!loading}>
      <Container alt='Container'>
        <Header alt='Header'>
          <HeaderLogo
            to={findFirstMenu && findFirstMenu.menuUrl ? findFirstMenu.menuUrl : '/'}
            onClick={() => handleSelectMenu(null, null, true)}
          >
            <img
              alt='KIA Flex'
              r-if={$utils.isRu}
              src='/assets/images/img_header_flex_logo.png'
              style={{ height: 'auto' }}
            />
            <img
              r-else
              alt='Kia DRIVE'
              src='/assets/images/logo/Kia_Drive_Logo_V1.png'
              style={{ height: 'auto' }}
            />
          </HeaderLogo>
          <div>
            <FMSForm
              style={{
                width: isSystemRole(fp.get('profile', state)) ? '600px' : '300px',
                display: 'inline-flex'
              }}
            >
              <CountrySelectContainer
                defaultSelectedFirst={fp.isEmpty(fp.get('ID', myCountry))}
                hidden={!isSystemRole(fp.get('profile', state))}
                name='myCountry'
                selectedValue={fp.getOr(null, 'ID', myCountry)}
                style={{ marginLeft: '20px' }}
                onChangeValue={handleCountrySelectorChange(fp.get('myCountry.ID', state))}
              />
              <BusinessSelectContainer
                filters={{
                  countryID: fp.getOr(null, 'myCountry.ID', state),
                  countryCd: fp.getOr(null, 'myCountry.countryCd', state)
                }}
                hidden={fp.isNotEmpty(fp.get('profile.business.ID', state))}
                name='myBusiness'
                selectedValue={fp.getOr(null, 'myBusiness.ID', state)}
                style={{ marginLeft: '20px' }}
                onChangeValue={handleBusinessSelectorChange(fp.get('myBusiness.ID', state))}
              />
              <BranchSelectContainer
                isClearable
                filters={{ businessID: fp.getOr(null, 'myBusiness.ID', state) }}
                name='myBranch'
                placeholder='Common.All'
                selectedValue={fp.getOr(null, 'myBranch.ID', state)}
                style={{ marginLeft: '20px' }}
                onChangeValue={handleBranchSelectorChange(fp.get('myBranch.ID', state))}
              />
            </FMSForm>
          </div>
          <HeaderGnb>
            <ul>
              <li>
                <Link to='/mypage' onClick={() => handleSelectMenu(null, '/mypage')}>
                  <FormattedMessage id='Common.Logout.Hello' values={{ user: profile.name }} />
                </Link>
                <button className='logout' type='button' onClick={handleLogout}>
                  <label>
                    <FormattedMessage id='Common.Logout' />
                  </label>
                </button>
              </li>
              <li className='split' />
              {!isRu ? (
                <li>
                  <button className='logout' type='button' onClick={handleMypage}>
                    <label>
                      <FormattedMessage id='MyPage.UserInfoChange' />
                    </label>
                  </button>
                </li>
              ) : (
                <li>
                  <Link to='/password' onClick={() => handleSelectMenu(null, '/password')}>
                    <FormattedMessage id='Menu.Password' />
                  </Link>
                </li>
              )}
              <li
                className='split'
                {...(!profile || !profile.roleGrade || profile.roleGrade !== '1'
                  ? { style: { display: 'none' } }
                  : null)}
              >
                {' '}
              </li>
              <li
                {...(!profile || !profile.roleGrade || profile.roleGrade !== '1'
                  ? { style: { display: 'none' } }
                  : null)}
              >
                <Link to='/mybusiness' onClick={() => handleSelectMenu(null, '/mybusiness')}>
                  <FormattedMessage id='Menu.MyBusiness' />
                </Link>
              </li>
              <li
                className='split'
                {...(!profile || !profile.roleGrade || profile.roleGrade !== '1'
                  ? { style: { display: 'none' } }
                  : null)}
              >
                {' '}
              </li>
              <li
                {...(!profile || !profile.roleGrade || profile.roleGrade !== '2'
                  ? { style: { display: 'none' } }
                  : null)}
              >
                <Link to='/mybranch' onClick={() => handleSelectMenu(null, '/mybranch')}>
                  <FormattedMessage id='Menu.MyBranch' />
                </Link>
              </li>
              <li
                className='split'
                {...(!profile || !profile.roleGrade || profile.roleGrade !== '1'
                  ? { style: { display: 'none' } }
                  : null)}
              >
                {' '}
              </li>
              <li>
                <LanguageSelectContainer />
              </li>
            </ul>
          </HeaderGnb>
        </Header>
        <HomeNavTopMenu
          handleSelectMenu={handleSelectMenu}
          menuList={menuRoot && menuRoot.children ? menuRoot.children : null}
          selectedMenuIDs={selectedMenuIDs}
          style={{
            width: '100%',
            minWidth: '1680px'
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            minHeight: 'calc(100% - 126px)'
          }}
        >
          <LNB>
            <HomeNavLeftMenu
              countryCd={fp.getOr('', 'myCountry.countryCd', state)}
              handleAgreeMember={handleAgreeMember}
              handlePersonalInfo={handlePersonalInfo}
              handleSelectMenu={handleSelectMenu}
              menuList={leftMenuList}
              selectedMenuIDs={selectedMenuIDs}
            />
          </LNB>
          <Content alt='Content'>{RouteComponent}</Content>
        </div>
      </Container>
    </Body>
  );
};
export default memo(HomeMain);
