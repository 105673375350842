import React, { useCallback, useEffect, useState } from 'react';
import { useGA4React } from 'ga-4-react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMyBusiness, useMyCountry } from 'v2/redux/hooks/useAuthentication';

/** Google Analytics 시작전 필요한 사전 작업
 * 1. TRACKING_ID  만들어야 함
 *     - https://analytics.google.com/
 *     - Google Analytics Version4 사용함 ( 유의! Version3 아님 )
 * 2. .env.me / .env.dev / .env.stg / .env.prd  파일에 FMS_TRACKING_ID 등록 필요
 *  ex) FMS_TRACKING_ID="G-H6HH2M1N2Z"
 */
const GoogleAnalyticsHOC = ({ children, selectedMenuIDs }) => {
  // 국가명 추출
  const { myCountry } = useMyCountry();
  let { countryName, name } = myCountry;
  if (_.isUndefined(countryName)) countryName = name;

  /*
    첫 로딩시 GA page_view 이벤트 초기 설정 값
    - 설정됨과 동시에 해당 value로 page전환시에 page_view 이벤트 자동 발생 시작...
  */
  const ga = useGA4React(process.env.FMS_TRACKING_ID, {
    page_location:
      process.env.FMS_NAME === '운영'
        ? `${window.location.href} - ${countryName}`
        : `${window.location.href} - ${process.env.FMS_NAME} - ${countryName}`,
    page_title:
      process.env.FMS_NAME === '운영'
        ? `${window.document.title} - ${countryName}`
        : `${window.document.title} - ${process.env.FMS_NAME} - ${countryName}`
    // page_title: '차량관제 - first', // process.env.FMS_NAME === '운영'? '차량관제' : `차량관제 - ${process.env.FMS_NAME}`,
    // send_page_view: false // <-- false처리시 첫 로그인시 page_event 이벨트 발생 안함
    /* custom_map: {
      dimension1: 'business_name',
    }, */
  });

  const { profile } = useSelector(({ authentication }) => {
    const {
      profile: { data }
    } = authentication;
    return {
      profile: data
    };
  });

  const intl = useIntl();
  /**
   * 운영, 개발, 스테이징 별 구분을 위해
   * TOP-MENU명에 FMS_NAME정보 포함하여 설정
   * */

  const [title, setTitle] = useState(
    process.env.FMS_NAME === '운영' ? '차량관제' : `차량관제 - ${process.env.FMS_NAME}`
  );

  const { myBusiness } = useMyBusiness();
  const getTitle = useCallback(() => title, [title]);

  /**
   *
   * GNB메뉴는 profile 정보에 포함되어 있지 않아
   * 별도로 url정보 체크후  menu name 추출
   */
  const isGnBMenu = (url) => {
    return url === '/mypage' || url === '/password' || url === '/mybusiness' || url === '/mybranch';
  };
  const getGnBMenuName = (url) => {
    let gnbMenuName = '';

    if (url === '/mypage') {
      gnbMenuName = intl.formatMessage({ id: 'Menu.MyPage' });
    } else if (url === '/password') {
      gnbMenuName = intl.formatMessage({ id: 'Menu.Password' });
    } else if (url === '/mybusiness') {
      gnbMenuName = intl.formatMessage({ id: 'Menu.MyBusiness' });
    } else if (url === '/mybranch') {
      gnbMenuName = intl.formatMessage({ id: 'Menu.MyBranch' });
    }
    return gnbMenuName;
  };

  const findTitleByUrl = useCallback(
    (url) => {
      let title = '';

      if (isGnBMenu(url)) {
        // GnB 메뉴는 profile에 정보 없음
        title = getGnBMenuName(url);
      } else {
        // GnB메뉴외에는 prifile 정보에 포함되어 있음
        const menu = profile && profile.aclMenu.filter((menu) => menu.menuUrl === url);
        title = menu && menu.length > 0 ? menu[0].name : window.document.title;
      }
      return title;
    },
    [profile]
  );

  // 일반메뉴인 경우 ( GnB메뉴, 일반 메뉴, TFMS클릭으로 First Menu이동인경우 )
  useEffect(() => {
    let title = '';
    const path =
      process.env.FMS_NAME === '운영'
        ? `${window.location.pathname} - ${countryName}`
        : `${window.location.pathname} - ${process.env.FMS_NAME} - ${countryName}`; // page_path
    const location =
      process.env.FMS_NAME === '운영'
        ? `${window.location.href} - ${countryName}`
        : `${window.location.href} - ${process.env.FMS_NAME} - ${countryName}`; // page_location
    const leftMenuPath = _.split(window.location.pathname, '/');

    title = findTitleByUrl(`/${leftMenuPath[1]}`);

    if (ga) {
      setTitle(
        process.env.FMS_NAME === '운영'
          ? `${title} - ${countryName}`
          : `${title} - ${process.env.FMS_NAME} - ${countryName}`
      );
      /* 
        page가 바뀔때마다 page_view event의 속성 정보 변경 ...
        -> config 속성 정보를 변경하지 않으면 초기 설정값으로 page_view event가 발생함 
      */
      ga.gtag('config', process.env.FMS_TRACKING_ID, {
        page_location: location,
        page_path: path,
        page_title:
          process.env.FMS_NAME === '운영'
            ? `${title} - ${countryName}`
            : `${title} - ${process.env.FMS_NAME} - ${countryName}`,
        send_page_view: false // 반드시 설정해야함 !! config  속성 변경시 send_page_view : false로 하지 않으면 별도 page_view_event다 또 발생 !!!!
      });
    }
  }, [window.location.pathname, selectedMenuIDs, ga]);

  /*
    UrlType 이 JS인경우  ( 외부 URL연결..)
    - AQS메뉴는 window.location.href변경이 없으므로 default page view event 발생 안함 
      =>  별도  page view 발생 시켜야함 함.
  */

  useEffect(() => {
    if (selectedMenuIDs) {
      const menu = profile && profile.aclMenu.filter((menu) => menu.menuID === selectedMenuIDs[0]);

      if (menu.length > 0 && menu[0].urlType === 'JS') {
        const title =
          process.env.FMS_NAME === '운영'
            ? `${menu[0].name} - ${countryName}`
            : `${menu[0].name} - ${process.env.FMS_NAME} - ${countryName}`;
        ga.gtag('event', 'page_view', {
          page_location:
            process.env.FMS_NAME === '운영'
              ? `${window.location.href} - ${countryName}`
              : `${window.location.href} - ${process.env.FMS_NAME} - ${countryName}`,
          page_path:
            process.env.FMS_NAME === '운영'
              ? `${window.location.pathname} - ${countryName}`
              : `${window.location.pathname} - ${process.env.FMS_NAME} - ${countryName}`,
          page_title: title
        });
        setTitle(title);
      }
    }
  }, [selectedMenuIDs]);

  /* 
    business별 집계위해  event 발생 
    1. 시스템 관리자 : 사업자 변경시 해당 사업자로 event발생 -> 사업자 변경시 매번 발생 
    2. 사업자 직원: 로그인시 해당 사업자로 event발생 -> 로그인시 1번 발생(profile변경시) 
  */
  useEffect(() => {
    // action - 이벤트 카테고리에 대한 설명 - 중분류, 글자수 제한 16자리
    if (ga && myBusiness) {
      ga.gtag('event', myBusiness.name, {
        page_title: getTitle(),
        event_category:
          process.env.FMS_NAME === '운영'
            ? 'business'
            : `business - ${process.env.FMS_NAME} - ${countryName}`, // 이벤트의 유형을 타나내는 기본단위로서 이벤트를 그룹화하기 위해 지정하는 이름 - 대분류
        event_label:
          process.env.FMS_NAME === '운영'
            ? profile.role
            : `${profile.role} - ${process.env.FMS_NAME} - ${countryName}`, // 추적할 이벤트에 대한 추가 정보 수집 - 소분류
        page_location:
          process.env.FMS_NAME === '운영'
            ? window.location.href
            : `${window.location.href} - ${process.env.FMS_NAME} - ${countryName}`,
        business_name:
          process.env.FMS_NAME === '운영'
            ? myBusiness.name
            : `${myBusiness.name} - ${process.env.FMS_NAME} - ${countryName}`
      });
    }
  }, [myBusiness, ga]);

  return <>{children}</>;
};

export default GoogleAnalyticsHOC;
