import request from 'apis/request';
import isDate from 'date-fns/is_date';
import dateFnsFormat from 'date-fns/format';

const prefix = '/payment';

/**
 * 매출/정산 관리 월별 조회
 */
export const getPaymentMonthListAPI = ({ selectedMonthTime, ID }) => {
  const month = new Date(selectedMonthTime);
  if (!selectedMonthTime || !isDate(month)) {
    return Promise.reject('Params is invalid(not date type).');
  }
  const data = {
    year: month.getFullYear(),
    month: dateFnsFormat(month, 'MM'),
    businessID: ID
  };
  return request({
    url: `${prefix}/calendar`,
    data
  });
};

/**
 * 매출/정산 관리 일별 조회
 */
export const getPaymentDayListAPI = ({ selectedDayTime, ID }) => {
  const day = new Date(selectedDayTime);
  if (!selectedDayTime || !isDate(day)) {
    return Promise.reject('Params is invalid(not date type).');
  }
  const data = {
    date: dateFnsFormat(day, 'YYYYMMDD'),
    businessID: ID
  };
  return request({
    url: `${prefix}/calendar/detail`,
    data
  });
};
