import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { isRu } from 'v2/lib/utils';
import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FMSSelect } from '../ui/input';

const AutoComplete = ({ handleCompleteAddress, onKeyDown, suggestions, isNewAddressFormat }) => {
  const supportCountry = [
    { label: 'ALL', value: 'ALL' },
    { label: 'AUT', value: 'AUT' },
    { label: 'BEL', value: 'BEL' },
    { label: 'BGR', value: 'BGR' },
    { label: 'CYP', value: 'CYP' },
    { label: 'CZE', value: 'CZE' },
    { label: 'DEU', value: 'DEU' },
    { label: 'DNK', value: 'DNK' },
    { label: 'EST', value: 'EST' },
    { label: 'ESP', value: 'ESP' },
    { label: 'FIN', value: 'FIN' },
    { label: 'FRA', value: 'FRA' },
    { label: 'GBR', value: 'GBR' },
    { label: 'GRC', value: 'GRC' },
    { label: 'HRV', value: 'HRV' },
    { label: 'HUN', value: 'HUN' },
    { label: 'IRL', value: 'IRL' },
    { label: 'ISL', value: 'ISL' },
    { label: 'ITA', value: 'ITA' },
    { label: 'LIE', value: 'LIE' },
    { label: 'LTU', value: 'LTU' },
    { label: 'LUX', value: 'LUX' },
    { label: 'LBY', value: 'LBY' },
    { label: 'MLT', value: 'MLT' },
    { label: 'NLD', value: 'NLD' },
    { label: 'NOR', value: 'NOR' },
    { label: 'POL', value: 'POL' },
    { label: 'PRT', value: 'PRT' },
    { label: 'ROU', value: 'ROU' },
    { label: 'SWE', value: 'SWE' },
    { label: 'SVN', value: 'SVN' },
    { label: 'SVK', value: 'SVK' },
    { label: 'KOR', value: 'KOR' }
  ];
  const [userInput, setUserInput] = useState('');
  const [suggestionsListComponent, setSuggestionsListComponent] = useState([]);
  const [postCountry, setPostCountry] = useState('ALL');

  const getEuLabel = (zipCode, street, city, country) => {
    let euAddressLabel = '';

    if (!fp.isEmpty(street)) {
      euAddressLabel += street + ', ';
    }
    if (!fp.isEmpty(city)) {
      euAddressLabel += city + ', ';
    }
    if (!fp.isEmpty(country)) {
      euAddressLabel += country + ', ';
    }
    if (!fp.isEmpty(zipCode)) {
      euAddressLabel += zipCode;
    }

    return euAddressLabel;
  };

  const getRuLabel = (zipCode, label) => {
    let ruAddressLabel = '';

    if (!fp.isEmpty(zipCode)) {
      ruAddressLabel += zipCode + ' ';
    }
    if (!fp.isEmpty(label)) {
      ruAddressLabel += label;
    }

    return ruAddressLabel;
  };

  const handleAddress = (data) => {
    console.log('handleAddress data:', data);
    const zoneCode = data.address.postalCode;
    if (handleCompleteAddress) {
      if (!isRu && isNewAddressFormat) {
        const street = data.address.street;
        const city = data.address.city;
        const country = data.address.countryName;
        handleCompleteAddress({
          zoneCode,
          street,
          city,
          country
        });
      } else {
        const fullAddress = data.label;
        handleCompleteAddress({
          fullAddress,
          zoneCode
        });
      }
    }
  };
  useEffect(() => {
    suggestions = [];
  }, []);

  useEffect(() => {
    if (suggestions.length) {
      const liComp = suggestions.map((suggestion) => (
        <li
          key={suggestion.locationId}
          role='presentation'
          onClick={() => handleAddress(suggestion)}
        >
          {!$utils.isRu
            ? getEuLabel(
                suggestion.address.postalCode,
                suggestion.address.street,
                suggestion.address.city,
                suggestion.address.country
              )
            : getRuLabel(suggestion.address.postalCode, suggestion.label)}
        </li>
      ));
      const ulComp = <ul className='suggestions'>{liComp}</ul>;
      setSuggestionsListComponent(ulComp);
    } else {
      setSuggestionsListComponent(
        <div className='no-suggestions'>
          <em>
            <FormattedMessage id='Common.Msg.NoSearchData' />
          </em>
        </div>
      );
    }
  }, [suggestions]);

  const onChange = (e) => {
    setUserInput(e.currentTarget.value);
  };

  const onKeyInput = (e) => {
    if (e.keyCode === 13) {
      // onKeyDown(e.currentTarget.value);
      const searchValue = document.getElementsByClassName('search');
      const filter = { searchValue: searchValue[0].value, postCountry };
      onKeyDown(filter);
    }
  };
  const addressButtonClick = () => {
    const searchValue = document.getElementsByClassName('search');
    const filter = { searchValue: searchValue[0].value, postCountry };
    onKeyDown(filter);
    // onKeyDown(searchValue[0].value);
  };
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '25%' }}>
          <FMSSelect
            required
            name='postCountry'
            options={supportCountry}
            value={postCountry}
            onChange={(e) => {
              console.log('value:', e);
              setPostCountry(e.value);
            }}
          />
        </div>
        <FormattedMessage id='Reservation.Address.Placeholder'>
          {(placeholderText) => (
            <input
              className='search'
              placeholder={placeholderText}
              style={{
                width: 'calc(100% - 32px)',
                border: '1px solid #dbdee1'
              }}
              type='text'
              value={userInput}
              onChange={onChange}
              onKeyDown={onKeyInput}
            />
          )}
        </FormattedMessage>
        <Button
          icon={<SearchOutlined />}
          onClick={addressButtonClick}
          style={{ verticalAlign: '-4px' }}
        />
      </div>
      {suggestionsListComponent}
    </>
  );
};

export default AutoComplete;
