import {
  getAccidentDataAPI,
  getAccidentListAPI,
  getVehicleListAPI,
  getBusinessListAPI,
  getBranchListAllAPI,
  getCompanyListByBusinessAPI,
  getReservationInfoAPI,
  createAccidentAPI,
  editAccidentAPI,
  deleteAccidentAPI
} from 'apis/accidentApi';
import { produce } from 'immer';
import { createAction, createActions, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import { FMSCommon } from 'service/common/commonLib';
import { combineReducers } from 'redux';
import { createReducer, createEntity } from 'v2/redux/lib';
import { PAGE_LIMIT } from 'v2/constants/index';
import { parseDataForList } from '../../lib/actionLib';

// Sync action creator
export const {
  initializeAccidentsPage,
  changeAccidentsPage,
  getAccidents,
  deleteForAccidents
} = createActions(
  {
    GET_ACCIDENTS: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters })
  },
  'INITIALIZE_ACCIDENTS_PAGE',
  'CHANGE_ACCIDENTS_PAGE',
  'DELETE_FOR_ACCIDENTS'
);

export const entityAccidents = createEntity({
  key: getAccidents().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const initializeState = {
  list: {
    status: null,
    data: [],
    error: null,
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'incidentDate', order: 'desc' },
      filters: {}
    },
    deleteAccidentsState: 'pending'
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const accidentPageReducer = handleActions(
  {
    [initializeAccidentsPage().type]: () => initializeState.list,
    [changeAccidentsPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const accident = combineReducers({
  list: createReducer({
    entity: entityAccidents,
    reducer: accidentPageReducer
  })
});
