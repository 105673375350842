import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { createEntity, createReducer } from 'v2/redux/lib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import { FMSCommon } from 'service/common/commonLib';

export const {
  initializeViolationList,
  changeViolationList,
  getViolationList,
  initializeViolationDetail,
  changeViolationDetail,
  getViolationDetail,
  createViolation,
  initializeLinkReservation,
  changeLinkReservation,
  getLinkReservation,
  deleteViolation,
  deleteViolationList,
  updateViolation,
  downloadPdf,
  initializeVehicleListForViolation,
  getVehicleListForViolation
} = createActions(
  {
    GET_VIOLATION_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_VIOLATION_DETAIL: (ID) => ({ ID }),
    CREATE_VIOLATION: (data) => ({ data }),
    GET_VEHICLE_LIST: (data) => ({ data }),
    GET_LINK_RESERVATION: (data) => ({ data }),
    DELETE_VIOLATION: (docID) => ({ docID }),
    UPDATE_VIOLATION: (data) => ({ data }),
    DOWNLOAD_PDF: (ID) => ({ ID }),
    GET_VEHICLE_LIST_FOR_VIOLATION: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    })
  },
  'INITIALIZE_VIOLATION_LIST',
  'CHANGE_VIOLATION_LIST',
  'INITIALIZE_VIOLATION_DETAIL',
  'CHANGE_VIOLATION_DETAIL',
  'INITIALIZE_LINK_RESERVATION',
  'CHANGE_LINK_RESERVATION',
  'DELETE_VIOLATION_LIST',
  'INITIALIZE_VEHICLE_LIST_FOR_VIOLATION'
);

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      filters: {
        startDate: new Date().setHours(0, 0, 0, 0) - 28 * 24 * 60 * 60 * 1000,
        endDate: new Date().setHours(23, 59, 59, 59) + 7 * 24 * 60 * 60 * 1000,
        keywordType: 'licensePlateNumber',
        keyword: null
      },
      pageInfo: {
        limit: PAGE_LIMIT,
        page: 1
      },
      sortInfo: {}
    }
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create',
    error: null
  },
  link: {
    data: null,
    mode: 'create'
  },
  vehicle: {
    data: [],
    loading: false,
    pager: {
      filters: {
        keywordType: 'licensePlateNumber',
        keyword: null
      },
      pageInfo: {
        limit: 8,
        page: 1
      },
      sortInfo: {}
    }
  }
};

export const entityViolationList = createEntity({
  key: getViolationList().type,
  parseData: (action) => parseDataForList(action, 'violationItems')
});

export const entityViolationDetail = createEntity({
  key: getViolationDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityLinkReservation = createEntity({
  key: getLinkReservation().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('PenaltyAndTollPage.Fail.ConnectRentInformation');
  },
  afterSuccessFn: (payload) => {
    if (payload === null) {
      FMSCommon.toast.fail('PenaltyAndTollPage.Fail.ConnectRentInformation');
    }
  }
});

export const entityVehicleListForViolation = createEntity({
  key: getVehicleListForViolation().type,
  parseData: parseDataForList
});

const violationListReducer = handleActions(
  {
    [initializeViolationList().type]: () => initializeState.list,
    [changeViolationList().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

const violationDetailReducer = handleActions(
  {
    [initializeViolationDetail().type]: () => initializeState.detail,
    [changeViolationDetail().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

const linkResevationReducer = handleActions(
  {
    [initializeLinkReservation().type]: () => initializeState.link,
    [changeLinkReservation().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

const vehicleListForViolationReducer = handleActions(
  {
    [initializeVehicleListForViolation().type]: () => initializeState.vehicle
  },
  {},
  { produce }
);

export const violation = combineReducers({
  list: createReducer({
    entity: entityViolationList,
    reducer: violationListReducer
  }),
  detail: createReducer({
    entity: entityViolationDetail,
    reducer: violationDetailReducer
  }),
  link: createReducer({
    entity: entityLinkReservation,
    reducer: linkResevationReducer
  }),
  vehicle: createReducer({
    entity: entityVehicleListForViolation,
    reducer: vehicleListForViolationReducer
  })
});
