import { put, takeLatest } from 'redux-saga/effects';
import { uploadVehicleRegistrationCardImageAPI } from 'v2/apis/vehicleRegistrationCardApi';
import { createVehicleByRegistrationCardImageAPI } from 'v2/apis/vehicleApi';

import { createSagaAction, fetchSaga, confirmFetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { getErrorMessage } from 'constants/errors';

import { FMSCommon } from 'service/common/commonLib';

import history from 'service/common/history';

import {
  uploadVehicleRegistrationCard,
  entityVehicleRegistrationCard, // 차량등록증 업로드
  createVehicleRegistrationCard, // 차량등록증을 통한 차량등록
  changeVehicleRegistrationCardFormPage // 차량등록증을 통한 차량등록 Form Page 변경.
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/vehicle';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorFormPage = (state) => state.vehicleRegistrationCard.form;
export const selectorListPage = (state) => state.vehicle.list;
/** ********************************************** */

/** 자동차 등록증 차량 등록 */
export const entityCreateVehicleRegistrationCard = createEntity({
  key: createVehicleRegistrationCard().type,
  *changeProgress(progressLoading) {
    yield put(changeVehicleRegistrationCardFormPage({ progressLoading }));
  },
  afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn(payload) {
    const { pager } = selectorListPage;
    const { vehicleID } = payload;
    if (vehicleID) history.replace({ pathname: `${historyUrl}/${vehicleID}`, state: pager });
  }
});

const uploadVehicleRegistrationCardImageAsync = createSagaAction.bind(
  null,
  entityVehicleRegistrationCard,
  uploadVehicleRegistrationCardImageAPI
);
const createVehicleByRegistrationCardImageAsync = createSagaAction.bind(
  null,
  entityCreateVehicleRegistrationCard,
  createVehicleByRegistrationCardImageAPI
);

export default function* vehicleRegistrationCardSaga() {
  yield takeLatest(
    uploadVehicleRegistrationCard().type,
    fetchSaga,
    uploadVehicleRegistrationCardImageAsync,
    entityVehicleRegistrationCard
  );
  yield takeLatest(
    createVehicleRegistrationCard().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createVehicleByRegistrationCardImageAsync,
    entityCreateVehicleRegistrationCard
  );
}
