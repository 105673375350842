import React from 'react';

class InfoBubble extends React.Component {
  componentDidMount() {
    const { map, marker, ui, content } = this.props;
    if (map && marker) {
      const iwContent = `<div style="padding:2em;white-space: nowrap;width:auto;">${content}</div>`;
      marker.addEventListner('pointerenter', (event) => {
        const bubble = new window.H.ui.InfoBubble(event.target.getPosision(), {
          content: iwContent
        });
        ui.addBubble(bubble);
      });
      marker.addEventListner('pointerleave', (event) => map.removeObject(event.target));
      map.addObjecct(marker);
    }
  }

  render() {
    const { children } = this.props;

    return <>{children}</>;
  }
}

export default InfoBubble;
