import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getProgramListAPI,
  createProgramAPI,
  deleteProgramAPI,
  getAllProgramListAPI
} from 'v2/apis/programApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, confirmFetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import {
  loadAllProgram,
  changeProgramListPage,
  getProgramList,
  createProgram,
  entityLoadAllProgramList,
  entityProgramList,
  deleteForProgramList
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/program';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorProgramListPage = (state) => state.program.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateProgram = createEntity({
  key: createProgram().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
    const selectorListPage = yield select(selectorProgramListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getProgramList(pageInfo, sortInfo, filters));
  }
});

// mulitple delete for list page.
export const entityDeleteForProgramList = createEntity({
  key: deleteForProgramList().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeProgramListPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');

    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorProgramListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getProgramList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorProgramListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getProgramList(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createProgramAsync = createSagaAction.bind(null, entityCreateProgram, createProgramAPI);
const deleteForProgramListAsync = createSagaAction.bind(
  null,
  entityDeleteForProgramList,
  deleteProgramAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getProgramListAsync = createSagaAction.bind(null, entityProgramList, getProgramListAPI);
const loadAllProgramListAsync = createSagaAction.bind(
  null,
  entityLoadAllProgramList,
  getAllProgramListAPI
);

/**
 * Watcher
 */
export default function* programWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createProgram().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createProgramAsync,
    entityCreateProgram
  );
  yield takeLatest(getProgramList().type, fetchSaga, getProgramListAsync, entityProgramList);

  // deletes in list page
  yield takeLatest(
    deleteForProgramList().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteForProgramListAsync,
    entityDeleteForProgramList
  );

  // select business
  yield takeLatest(
    loadAllProgram().type,
    fetchSaga,
    loadAllProgramListAsync,
    entityLoadAllProgramList
  );

  // type, worker
}
