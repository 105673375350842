/* eslint-disable import/prefer-default-export */
import request from 'apis/request';
// import { format } from 'date-fns';

const prefix = '/reservation';
const estimatePrefix = '/estimate';

export const getVehicleAvailableListAPI = (data) => {
  const vo = {};
  vo.latitude = data.latitude;
  vo.longitude = data.longitude;
  vo.startTime = data.startTime;
  vo.endTime = data.endTime;
  vo.businessID = data.businessID;
  vo.branchID = data.branchID;
  vo.isPrivateCompany = data.isPrivateCompany;
  vo.insuranceID = data.insuranceID;

  if (data.vehicleType) vo.vehicleType = data.vehicleType;
  if (data.distance && data.distance > 0) vo.distance = data.distance;
  if (data.useVehicleID && data.useVehicleID !== '') {
    vo.useVehicleID = data.useVehicleID;
  }
  if (data.reservationID && data.reservationID !== '') {
    vo.reservationID = data.reservationID;
  }

  vo.page = data.page;
  vo.rowPerPage = data.rowPerPage;

  return request({
    url: '/vehicle/available',
    data: vo
  });
};

export const getInsuranceChargeListAPI = ({ filters = {} }) => {
  return request({
    url: '/insurancechargeList',
    data: filters
  });
};

export const getInsuranceCostAPI = ({ filters }) => {
  return request({
    url: '/payment/insurance',
    data: filters
  });
};

export const getReservationExtendAPI = ({ filters }) => {
  return request({
    url: '/payment/extend',
    data: filters
  });
};

/**
 * upDate ReservationType 예약 타입
 */
export const upDateReservationTypeAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'patch',
    data
  });
};

export const searchAgentAPI = (filters) => {
  filters.masking = 1;
  return request({
    url: '/member/business',
    data: filters
  });
};
export const searchUserAPI = (filters) => {
  // filters['role'] = 'NON_MEMBER';
  return request({
    url: '/customer/search',
    data: filters
  });
};

export const searchCurrentCompanyAPI = (filters) => {
  return request({
    url: '/company',
    data: filters
  });
};

export const checkLicenseAPI = (data) => {
  return request({
    url: '/reservation/license/check',
    method: 'post',
    data
  });
};

export const postReservationAPI = (data) => {
  return request({
    url: '/reservation',
    method: 'post',
    data
  });
};

// 견적차량 정보 조회
export const getEstimateAPI = ({ data }) => {
  return request({
    url: `${estimatePrefix}`,
    data
  });
};

// 견적차량 정보 등록
export const createEstimateAPI = ({ data }) => {
  return request({
    url: `${estimatePrefix}`,
    method: 'post',
    data
  });
};

// 견적차량 정보 삭제
export const deleteEstimateAPI = ({ ID }) => {
  return request({
    url: `${estimatePrefix}/${ID}`,
    method: 'delete'
  });
};

/**
 * 견적차량 복수 삭제 기능
 */
export const deleteEstimatesAPI = ({ IDArr }) =>
  Promise.all(
    IDArr.map((data) => {
      return request({
        method: 'delete',
        url: `${estimatePrefix}/${data.ID}`
      });
    })
  );

function getReservationSortNum(filed) {
  switch (filed) {
    // case 'START_TIME':
    //   return 1;
    // case 'END_TIME':
    //   return 2;
    // case 'CREATED_AT':
    //   return 3;
    case 'reservationKey':
      return 1;
    case 'vehicle.licensePlateNumber':
      return 3;
    case 'endTime':
      return 3;
    case 'reservationDays':
      return 4;
    case 'user.name':
      return 9;
    case 'user.mobileNumber':
      return 11;
    case 'startTime':
      return 5;
    default:
      return 0;
  }
}

export const getReservationListAPI = ({ pageInfo, sortInfo, filters }) => {
  /* filters */
  const { status, keywordType, keyword, reservationClass, paymentStatus, ...data } = filters;
  // data.reservationTag = 'NORMAL';
  if (keywordType) {
    data[keywordType] = keyword;
  }
  if (reservationClass) {
    data.reservationClasses = reservationClass;
  }
  if (paymentStatus) {
    data.paymentStatus = paymentStatus;
  }
  data.status = status || '3,7,12,13,14';
  /* pageInfo */
  const { page, limit } = pageInfo;
  data.page = page;
  data.rowPerPage = limit;
  /* sortInfo */
  const { field, order } = sortInfo;
  data.sortField = getReservationSortNum(field);
  data.sortOrder = order === 'desc' ? 1 : 0;

  /* businessID */
  // data.businessID = businessID;
  // data.branchID = branchID;
  return request({
    url: prefix,
    data
  });
};
export const downloadCSVAPI = (data) => {
  return request({
    url: `${prefix}/disapproval/download`,
    method: 'file',
    data
  });
};

export const returnAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}/return`,
    method: 'patch',
    data
  });
};

export const forceReturnAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}/forcereturn`,
    method: 'patch',
    data
  });
};

export const extendAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}/extend`,
    method: 'patch',
    data
  });
};

export const changeRentalAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}/change`,
    method: 'patch',
    data
  });
};

export const confirmRentalAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}/confirm`,
    method: 'patch',
    data
  });
};

export const denyRentalAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}/deny`,
    method: 'patch',
    data
  });
};

/**
 * 이용 전/후 차량 사진 조회
 */
export const getReservationUseImgListAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}/images`
  });
};

/**
 * 대여내역 삭제 기능(일괄삭제)
 */
export const deletesRentalAPI = ({ IDArr }) => {
  return Promise.all(
    IDArr.map((ID) => {
      // console.log('ID================>', ID);
      return request({
        method: 'delete',
        url: `${prefix}/${ID}`
      });
    })
  );
  // request({
  //   //url: `${prefix}/${'47c82534-3378-11eb-a2b3-02d1b6b3a672'}`,
  //   url: `${prefix}/${ID}`,
  //   method: 'delete',
  // });
};

/**
 * upDate ReservationType 예약 타입, 상태를 이용으로 변경
 */
export const updateReservationTypeInUseAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}/inuse`,
    method: 'patch',
    data
  });
};

export const updateAddressInfoAPI = (data) => {
  return request({
    url: '/customer/address',
    method: 'post',
    data: {
      userID: data.userID,
      address: data.address
    }
  });
};

export const updateCompanyAddressInfoAPI = (data) => {
  const ID = data.companyID;
  return request({
    url: `/company/${ID}`,
    method: 'patch',
    data: {
      address: data.address
    }
  });
};
export const updateCompanyRentalInfoAPI = (data) => {
  console.log('updateCompanyRentalInfoAPI:', data);
  return request({
    url: '/reservation/company',
    method: 'post',
    data: data.data
  });
};

export const updateInvoicingInfoAPI = (data) => {
  return request({
    url: `${prefix}/invoice/id/${data.reservationId}`,
    method: 'patch',
    data: {
      invoicingInfo: data.invoicingInfo,
      invoicingInfoEng: data.invoicingInfoEng,
      businessId: data.businessId,
      branchId: data.branchId,
      isInvoiceClearYn: data.isInvoiceClearYn
    }
  });
};

export const sendInvoiceEmailAPI = ({ ID }) => {
  return request({
    url: `/invoice/${ID}/resend`,
    method: 'post',
    data: {
      reservationId: ID
    }
  });
};

export const deleteInvoicingInfoAPI = (data) => {
  return request({
    url: `${prefix}/invoice/id/${data.reservationId}`,
    method: 'delete',
    data: {
      businessId: data.businessId,
      branchId: data.branchId
    }
  });
};

export const getDamageSpecificationListAPI = (data) => {
  return request({
    url: `${prefix}/${data.reservationId}/vehicle/image`,
    method: 'get'
  });
};

export const registerDamageSpecificationImageGroupAPI = (data) => {
  const formData = new FormData();
  formData.append('imageGroup', data.imageGroup);

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}/${data.reservationId}/vehicle/image`,
    method: 'post',
    data: formData
  });
};

export const createDamageSpecificationAPI = (data) => {
  const formData = new FormData();
  formData.append('imageGroup', data.imageGroup);
  formData.append('left', data.file);

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}/${data.reservationId}/vehicle/image`,
    method: 'patch',
    data: formData
  });
};

export const deleteDamageSpecificationAPI = (data) => {
  const formData = new FormData();
  formData.append('imageGroup', data.imageGroup);

  formData.append('LeftIDstr', data.fileIds.join(','));

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}/${data.reservationId}/vehicle/image`,
    method: 'patch',
    data: formData
  });
};
