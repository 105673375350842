import request from 'apis/request';

const prefix = '/notice';

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'noticeTitle':
      return 1;
    case 'createdAt':
      return 2;
  }
}

/**
 * 공지사항관리 리스트 조회
 */
export const getNoticeListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page, limit } = pageInfo;
  const { field, order } = sortInfo;
  const { ...data } = filters;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 공지사항관리 상세 조회
 */
export const getNoticeDetailAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};

/**
 * 공지사항관리 등록 기능
 */
export const createNoticeAPI = (inputData) => {
  return request({
    url: `${prefix}`,
    method: 'post',
    data: inputData.data
  });
};

/**
 * 공지사항관리 삭제 기능
 */
export const deleteNoticeAPI = ({ ID }) => {
  return request({
    method: 'delete',
    url: `${prefix}/${ID}`
  });
};

/**
 * 공지사항관리 수정 기능
 */
export const updateNoticeAPI = (inputData) => {
  return request({
    url: `${prefix}/${inputData.ID}`,
    method: 'patch',
    data: inputData.data
  });
};
