import request from 'apis/request';

const prefix = '/business';

function getBusinessSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/**
 * 법인 리스트 조회
 * ex) /business
 */
export const getBusinessesAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getBusinessSortFieldNum(field);

  return request({
    url: `${prefix}List`,
    data
  });
};

export const getAllBusinessesAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  return request({
    url: `${prefix}List`,
    data
  });
};

/**
 * 법인 상세조회
 * ex) /business/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const getBusinessAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });

/**
 * 법인 등록
 * ex) /business
 */
export const createBusinessAPI = ({ data }) => request({
    url: `${prefix}`,
    method: 'post',
    data
  });

/**
 * 법인 수정
 * ex) /business/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const updateBusinessAPI = ({ ID, data }) => request({
    url: `${prefix}/${ID}`,
    method: 'put',
    data
  });

/**
 * 법인 삭제
 * ex) /business/b93ddfcc-56fc-4efe-9dbf-94e419b1001b
 */
export const deleteBusinessAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
