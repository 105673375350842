import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { getValueAlternative } from 'service/utils/FMSValueUtil';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
  }

  span {
    font-size: 1.125rem;
    font-weight: 700;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  /* 아이콘의 크기는 폰트 사이즈로 조정 가능 */
  font-size: 2rem;
  // margin-right: 20px;
  color: #adb5bd;
`;

export default class FMSCheckbox extends PureComponent {
  render() {
    const {
      defaultChecked,
      checked: inputChecked,
      readOnly,
      disabled,
      children,
      onChange = () => null,
      ...restProps
    } = this.props;
    const checked = getValueAlternative(inputChecked, defaultChecked, false);

    return (
      <CheckboxWrapper>
        <input
          type='checkbox'
          {...{ ...restProps, checked, readOnly, disabled }}
          onChange={onChange}
        />
        <Icon className='icon' {...{ ...restProps, checked, readOnly, disabled }}>
          {checked && !disabled ? (
            <img
              alt='checkbox-on'
              src={`${process.env.PUBLIC_URL}/assets/images/checkbox-on.svg`}
              width='24px'
            />
          ) : disabled ? (
            <img
              alt='checkbox'
              src={`${process.env.PUBLIC_URL}/assets/images/checkbox-disabled.svg`}
              width='24px'
            />
          ) : (
            <img
              alt='checkbox'
              src={`${process.env.PUBLIC_URL}/assets/images/checkbox.svg`}
              width='24px'
            />
          )}
        </Icon>
        {children}
      </CheckboxWrapper>
    );
  }
}
