// import React from 'react';
import firebase from 'firebase/app'; // firebase모듈을 import해줘야 합니다.
import 'firebase/messaging';
import fp from 'lodash/fp';

export const messaging = (() => {
  if (fp.isEmpty(firebase.apps)) {
    // init firebase
    const config = {
      appId: process.env.FMS_FCM_APP_ID,
      apiKey: process.env.FMS_FCM_API_KEY,
      projectId: process.env.FMS_FCM_PROJECT_ID,
      messagingSenderId: process.env.FMS_FCM_MESSAGING_SENDER_ID
    };
    firebase.initializeApp(config);
  }

  const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

  return messaging;
})();
