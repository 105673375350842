import React, { memo } from 'react';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';

const defaultCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: {
    zIndex: 11,
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  }
};

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('div');
else Modal.setAppElement('body');

const FMSModal = ({
  children,
  isOpen,
  handleCloseModal,
  className,
  customStyles,
  titleStyles,
  title,
  titleLabel
}) => (
  <Modal
    className={className}
    isOpen={isOpen}
    style={{
      content: { ...defaultCustomStyles.content, ...(customStyles && customStyles.content) },
      overlay: { ...defaultCustomStyles.overlay, ...(customStyles && customStyles.overlay) }
    }}
    onRequestClose={handleCloseModal}
  >
    <>
      <h1 style={titleStyles}>
        {title || null}
        {titleLabel && <FormattedMessage id={titleLabel} />}
      </h1>
      {children}
    </>
  </Modal>
);

export default memo(FMSModal);
