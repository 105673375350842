import accidents from 'redux/modules/accidents';
import local from 'redux/modules/local';
import monitoring from 'redux/modules/monitoring';
// import testCarConfig from 'redux/modules/testCarConfig';

import code from 'redux/modules/code';

const reducers = {
  accidents,
  local,
  monitoring,
  // testCarConfig,
  code
};

export default reducers;
