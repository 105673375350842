import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { parseData, createEntity, createReducer } from 'v2/redux/lib';
import { SUPPORTED_LANGUAGES, DEFAULT_USED_LANGUAGE } from 'v2/constants';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeProfile,
  getProfile,
  logout,
  initializeLanguage,
  changeLanguage,
  changeMyBusiness,
  changeMyBranch,
  updateProfile,
  deleteProfile, // 로그아웃
  changeProfile,
  updatePassword,
  deleteJoin, // 탈퇴
  changeMyCountry,
  initializeUserInfo,
  getUserInfo,
  initializeMyCountry,
  initializeMyBusiness,
  initializeMyBranch
} = createActions(
  {
    UPDATE_PROFILE: (data) => ({ data }),
    CHANGE_LANGUAGE: (language) => language,
    UPDATE_PASSWORD: (passwordCurrent, passwordChange) => ({ passwordCurrent, passwordChange })
  },
  'INITIALIZE_PROFILE',
  'GET_PROFILE',
  'LOGOUT',
  'INITIALIZE_LANGUAGE',
  'LOAD_LANGUAGE',
  'CHANGE_MY_BUSINESS',
  'CHANGE_MY_BRANCH',
  'DELETE_PROFILE',
  'CHANGE_PROFILE',
  'DELETE_JOIN',
  'CHANGE_MY_COUNTRY',
  'INITIALIZE_USER_INFO',
  'GET_USER_INFO',
  'INITIALIZE_MY_COUNTRY',
  'INITIALIZE_MY_BUSINESS',
  'INITIALIZE_MY_BRANCH'
);
/** ********************************************** */

function parseProfile(action) {
  const { data } = parseData(action);
  if (data && data.activationStatus && data.activationStatus === 'NORMAL') {
    if (data && data.role && (data.role.startsWith('SYSTEM') || data.role.startsWith('MOCEAN'))) {
      data.roleID = data.roleDetail || '0';
    } else {
      data.roleGrade = data.roleDetail;
    }
  }
  return { data };
}

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityProfile = createEntity({ key: getProfile().type, parseData: parseProfile });
/** ********************************************** */

/* 마이페이지 화면 로그인 사용자 정보 조회 */
export const entityUserInfo = createEntity({ key: getUserInfo().type });

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  profile: {
    data: null,
    loading: null,
    error: null,
    mode: 'view'
  },
  language: SUPPORTED_LANGUAGES.includes(localStorage.getItem('lang'))
    ? localStorage.getItem('lang')
    : DEFAULT_USED_LANGUAGE,
  myBusiness: {
    data: {
      ID: ''
    },
    loading: null,
    error: null
  },
  myBranch: {
    data: {
      ID: 0
    },
    loading: null,
    error: null
  },
  myCountry: {
    data: {
      ID: '',
      countryCd: '',
      lang: ''
      // kyw todo 국가 기본설정
      // ID: '7fe1b8b3-s12k-4794-8b9b-6e4c43a29308',
      // countryCd: 'SK',
      // lang: 'sk'
    },
    loading: null,
    error: null
  },
  userInfo: {
    data: null,
    loading: null,
    error: null
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const profileReducer = handleActions(
  {
    [initializeProfile().type]: () => initializeState.profile,
    [changeProfile().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

const userInfoReducer = handleActions(
  {
    [initializeUserInfo().type]: () => initializeState.userInfo
  },
  {},
  { produce }
);

// page action reducer 선언
const authentication = combineReducers({
  profile: createReducer({
    entity: entityProfile,
    reducer: profileReducer
  }),
  language: handleActions(
    {
      [changeLanguage().type]: (state, { payload }) => {
        localStorage.setItem('lang', payload);
        localStorage.setItem('localLanguage', payload);
        return payload;
      }
    },
    initializeState.language,
    { produce }
  ),
  myBusiness: handleActions(
    {
      [initializeMyBusiness().type]: () => initializeState.myBusiness,
      [changeMyBusiness().type]: (state, { payload }) => ({ ...state, ...payload })
    },
    initializeState.myBusiness,
    { produce }
  ),
  myBranch: handleActions(
    {
      [initializeMyBranch().type]: () => initializeState.myBranch,
      [changeMyBranch().type]: (state, { payload }) => ({ ...state, ...payload })
    },
    initializeState.myBranch,
    { produce }
  ),
  myCountry: handleActions(
    {
      [initializeMyCountry().type]: () => initializeState.myCountry,
      [changeMyCountry().type]: (state, { payload }) => ({ ...state, ...payload })
    },
    initializeState.myCountry,
    { produce }
  ),
  userInfo: createReducer({
    entity: entityUserInfo,
    reducer: userInfoReducer
  })
});

export default authentication;
/** ********************************************** */
