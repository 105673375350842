import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { listToTree } from 'v2/containers/main/lib/homeLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { multiCodeParseDataForList, parseDataForList as parseCodeForList } from './codeActionLib';
// TODO

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  // code redux all
  initializeLoadAllCodeNew,
  loadAllCodeNew,
  // tree page
  initializeCodeStateNew,
  changeCodePageNew,
  getCodeListNew,
  getCodeListWithGroupKeyNew,
  // detail page
  initializeDetailPage,
  changeCodeDetailPage,
  getCodeNew,
  // CUD
  createCodeNew,
  updateCodeNew,
  deleteCodeNew,
  // common code
  initializeMultiCodeStateNew
} = createActions(
  {
    LOAD_ALL_CODE_NEW: (filters) => ({ filters }),
    GET_CODE_LIST_NEW: (filters) => ({ filters }),
    GET_CODE_NEW: (ID) => ({ ID }),
    CREATE_CODE_NEW: (data) => ({ data }),
    UPDATE_CODE_NEW: (ID, data) => ({ ID, data }),
    DELETE_CODE_NEW: (ID) => ({ ID }),
    GET_CODE_LIST_WITH_GROUP_KEY_NEW: (data) => ({ data })
  },
  'INITIALIZE_LOAD_ALL_CODE_NEW',
  'INITIALIZE_CODE_STATE_NEW',
  'INITIALIZE_DETAIL_PAGE',
  'CHANGE_CODE_DETAIL_PAGE',
  'CHANGE_CODE_PAGE_NEW',
  'INITIALIZE_MULTI_CODE_STATE_NEW'
);
/** ********************************************** */

function makeItemFn(data) {
  data.id = data.ID;
  if (typeof data.msgKey === 'undefined') {
    data.label = data.name;
    data.isMsgKey = false;
  } else {
    data.label = data.msgKey;
    data.isMsgKey = true;
  }
  data.value = data.codeValue;
  return data;
}

export function makeListToTree(data, rootNode) {
  return { treeNode: listToTree({ list: data, rootNode, makeItemFn }), data };
}

function parseDataForTree(action) {
  const { data } = parseDataForList(action);
  const rootNode = { name: 'Title.Code', toggled: true, ID: 0, id: 0, depth: 0 };
  return makeListToTree(data, rootNode);
}

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllCode = createEntity({
  key: loadAllCodeNew().type,
  parseData: parseDataForTree,
  afterSuccessFn: (payload) => {
    window.FMSCodes = payload && payload.items ? payload.items : [];
  }
});

export const entityCodeList = createEntity({
  key: getCodeListNew().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityCodeMultiList = createEntity({
  key: getCodeListWithGroupKeyNew().type,
  parseData: multiCodeParseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});
export const entityCodeDetail = createEntity({
  key: getCodeNew().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      // pageInfo: { limit: 20, page: 1 },
      // sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {
        usage: 0,
        All: true,
        ReqGroup: true,
        ReqItem: true
      }
    },
    openNodes: [],
    selectedNode: null
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create'
  },
  multiList: {
    status: null,
    data: {},
    error: null
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {
        usage: 1,
        All: true,
        ReqGroup: true,
        ReqItem: true
      }
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const codeListLoadAllReducer = handleActions(
  {
    [initializeLoadAllCodeNew().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const codeListPageReducer = handleActions(
  {
    [initializeCodeStateNew().type]: () => initializeState.list,
    [changeCodePageNew().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const multiCodeReducer = handleActions(
  {
    [initializeMultiCodeStateNew().type]: () => initializeState.multiList
  },
  {},
  { produce }
);
const codeDetailPageReducer = handleActions(
  {
    [initializeDetailPage().type]: () => initializeState.detail,
    [changeCodeDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const newCode = combineReducers({
  list: createReducer({
    entity: entityCodeList,
    reducer: codeListPageReducer
  }),
  multiList: createReducer({
    entity: entityCodeMultiList,
    reducer: multiCodeReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllCode,
    reducer: codeListLoadAllReducer
  }),
  detail: createReducer({
    entity: entityCodeDetail,
    reducer: codeDetailPageReducer
  })
});
/** ********************************************** */
