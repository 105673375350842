import request from 'apis/request';

const prefix = '/vehicle/accessory-svc/dealer';
const prefixVehicle = '/vehicle/accessory-svc/mount-available';

/**
 * 액세서리 모델 목록 조회(페이징)
 */
export const getAccessoryModelListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const composer = fp.pipe(fp.set('pageNum', page), fp.set('rowsPerPage', limit));
  const data = composer(filters);

  /* if (data.categoryType === 'S') {
    data.accessoryName = data.accessoryNameOfService;
    delete data.accessoryNameOfService;
  } */

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 액세서리 모델 목록 조회(페이징X)
 */
export const getAccessoryModelListForItemAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  // const { page = 1, limit = 20 } = pageInfo;
  // const composer = fp.pipe(fp.set('pageNum', page), fp.set('rowsPerPage', limit));
  const data = { ...filters, categoryType: 'A' };

  /* if (data.categoryType === 'S') {
    data.accessoryName = data.accessoryNameOfService;
    delete data.accessoryNameOfService;
  } */

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 액세서리 모델 상세 조회
 */
export const getAccessoryModelAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};

/**
 * 액세서리 모델 등록
 */
export const createAccessoryModelAPI = ({ data, image }) => {
  // image를 포함한 multipart로 보냄
  const dt = new FormData();

  if (data) {
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        dt.append(key, _.join(data[key], ','));
      } else {
        dt.append(key, data[key]);
      }
    });
  }

  if (image) dt.append('imageData', image);

  dt.append('priceModel', 'F');

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: prefix,
    method: 'post',
    data: dt
  });
};

/**
 * 액세서리 모델 수정
 */
export const updateAccessoryModelAPI = ({ ID, data, image }) => {
  // image를 포함한 multipart로 보냄
  const dt = new FormData();

  if (data) {
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item) => {
          dt.append(key, item);
        });
      } else dt.append(key, data[key]);
    });
  }

  if (image) dt.append('imageData', image);

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}/${ID}`,
    method: 'patch',
    data: dt
  });
};

/**
 * 장착가능차량 선택건 업데이트
 */
export const updateAccessoryModelVehiclesAPI = ({ data }) => {
  return request({
    url: prefixVehicle,
    method: 'post',
    data
  });
};

/**
 * 장착가능차량 전체 리스트
 */
export const getAccessoryVehicleListAPI = ({ businessId, branchId }) => {
  return request({
    url: `${prefixVehicle}/vehicle-models?businessId=${businessId}`,
    method: 'get'
  });
};

/**
 * 액세서리 모델 삭제
 */
export const deleteAccessoryModelAPI = ({ id }) => {
  return request({
    url: `${prefix}/${id}`,
    method: 'delete',
    data: { remarks: '' }
  });
};

export const getAccessoryDealerModelListAPI = ({
  categoryCd,
  businessId,
  branchId,
  vehicleId,
  startTimeMill,
  endTimeMill
}) => {
  /* let startDateTime = '';
  if (startTimeMill && startTimeMill !== '0') {
    startDateTime = yyyymmddhh(startTimeMill);
  }
  let endDateTime = '';
  if (startTimeMill && startTimeMill !== '0') {
    endDateTime = yyyymmddhh(endTimeMill);
  } */
  return request({
    url: `/vehicle/accessory-svc/dealer/category/${categoryCd}/items`,
    data: {
      businessId,
      branchId,
      vehicleId,
      startDateTime: startTimeMill,
      endDateTime: endTimeMill
    }
  });
};

function yyyymmddhh(timestamp) {
  const d = new Date(timestamp),
    yyyy = d.getFullYear(),
    mm = `0${d.getMonth() + 1}`.slice(-2),
    dd = `0${d.getDate()}`.slice(-2),
    hh = `0${d.getHours()}`.slice(-2);
  return yyyy + mm + dd + hh;
}
