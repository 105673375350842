import { takeLatest } from 'redux-saga/effects';
import { getPaymentMonthListAPI, getPaymentDayListAPI } from 'v2/apis/paymentCalendarApi';
import { createSagaAction, fetchSaga } from 'v2/redux/sagas';
import {
  entityPaymentMonthList,
  getPaymentMonthList,
  entityPaymentDayList,
  getPaymentDayList
} from '.';

const getPaymentMonthListAsync = createSagaAction.bind(
  null,
  entityPaymentMonthList,
  getPaymentMonthListAPI
);
const getPaymentDayListAsync = createSagaAction.bind(
  null,
  entityPaymentDayList,
  getPaymentDayListAPI
);

export default function* paymentCalendarWatcher() {
  yield takeLatest(
    getPaymentMonthList().type,
    fetchSaga,
    getPaymentMonthListAsync,
    entityPaymentMonthList
  );
  yield takeLatest(
    getPaymentDayList().type,
    fetchSaga,
    getPaymentDayListAsync,
    entityPaymentDayList
  );
}
