import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { PAGE_LIMIT } from 'v2/constants/index';

export const {
  getVehicleSapList,
  getVehicleSapPagingList,
  setVehicleSapDetail,
  initializeVehiclesSapListPage,
  setVehicleSapList
} = createActions(
  {
    GET_VEHICLE_SAP_LIST: (pageInfo, sortInfo, filters, intl) => {
      return { pageInfo, sortInfo, filters, intl };
    },
    GET_VEHICLE_SAP_Paging_LIST: (pageInfo, sortInfo, filters, intl) => {
      return { pageInfo, sortInfo, filters, intl };
    },
    SET_VEHICLE_SAP_DETAIL: (data) => {
      return { data };
    }
  },
  'INITIALIZE_VEHICLES_SAP_LIST_PAGE',
  'SET_VEHICLE_SAP_LIST'
);

export const initializeState = {
  list: {
    regList: [],
    dataAll: [],
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    },
    deleteVehiclesState: 'pending'
  },
  detail: {
    data: {},
    loading: false,
    progressLoading: false,
    mode: 'create',
    tid: null
  }
};

const vehiclesSapListPageReducer = handleActions(
  {
    [initializeVehiclesSapListPage().type]: () => initializeState.list,
    [getVehicleSapPagingList().type]: (state, { payload }) => {
      const {
        pageInfo: { page }
      } = payload;
      const startIdx = (page - 1) * PAGE_LIMIT;
      const endIdx = startIdx + PAGE_LIMIT;
      const { dataAll } = state;
      const { filters } = payload;
      const { vin, vehicleNumber, modelName, codeType } = filters;

      const hasFilter =
        (vin !== undefined && vin !== '')
        || (vehicleNumber !== undefined && vehicleNumber !== '')
        || (modelName !== undefined && modelName !== '')
        || (codeType !== undefined && codeType !== 'ALL');
      let filtedData = [];
      if (hasFilter) {
        const vinFilterValue = vin || '';
        const vehicleNmFilterValue = vehicleNumber || '';
        const modelNmFilterValue = modelName || '';
        const typeFilterValue = codeType || 'ALL';

        const filterCheckList = [
          vinFilterValue !== '',
          vehicleNmFilterValue !== '',
          modelNmFilterValue !== '',
          typeFilterValue !== 'ALL'
        ];

        filtedData = dataAll.filter((d) => {
          const { vin, plateNo, modelName, regYn } = d;

          const fnFilterVin = () => {
            if (filterCheckList[0]) {
              if (vin.toLowerCase().indexOf(vinFilterValue.toLowerCase()) !== -1) return true;

              return false;
            }
            return true;
          };

          const fnFilterVehNum = () => {
            if (filterCheckList[1]) {
              if (plateNo.toLowerCase().indexOf(vehicleNmFilterValue.toLowerCase()) !== -1)
                return true;

              return false;
            }
            return true;
          };

          const fnFilterModelNm = () => {
            if (filterCheckList[2]) {
              if (modelName.toLowerCase().indexOf(modelNmFilterValue.toLowerCase()) !== -1)
                return true;

              return false;
            }
            return true;
          };

          const fnRegYn = () => {
            if (filterCheckList[3]) {
              if (typeFilterValue === 'REGISTERED') {
                if (regYn === 'Y') return true;

                return false;
              }

              if (regYn === 'N') return true;

              return false;
            }

            return true;
          };

          return fnFilterVin() && fnFilterVehNum() && fnFilterModelNm() && fnRegYn();
        });
      }

      const realDatas = hasFilter
        ? filtedData.slice(startIdx, endIdx)
        : dataAll.slice(startIdx, endIdx);

      return { ...state, pager: { ...payload }, data: realDatas };
    },

    [setVehicleSapList().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);

const setVehicleSapDetailReducer = handleActions(
  {
    [setVehicleSapDetail().type]: (state, { payload }) => {
      return { ...state, ...payload };
    }
  },
  {},
  { produce }
);

export const entityVehiclesSapList = createEntity({
  key: getVehicleSapList().type,
  parseData: (action) => {
    const { intl } = action.params;

    const lists = action.response.data.data.list || [];

    const dataAll = [...lists];
    const {
      pageInfo: { page }
    } = action.params;
    const { filters } = action.params;
    const { vin, vehicleNumber, modelName, codeType } = filters;

    const hasFilter =
      (vin !== undefined && vin !== '')
      || (vehicleNumber !== undefined && vehicleNumber !== '')
      || (modelName !== undefined && modelName !== '')
      || (codeType !== undefined && codeType !== 'ALL');
    let filtedData = [];
    let { length } = dataAll;

    const { regList = [] } = action.params;

    if (regList !== null && regList.length > 0) {
      const fnIncludeCheck = (obj, list) => {
        const chVal = list.some((d) => {
          if (d.VIN === obj.vin) {
            obj.ID = d.ID;
            obj.detail = d;
            return true;
          }
          return false;
        });
        return chVal;
      };

      dataAll.forEach((d) => {
        const { productionDate } = d;

        d.productionDate = productionDate.substr(0, 4);
        // d.plateNo = plateNo || '없음';

        if (fnIncludeCheck(d, regList)) {
          d.regYn = 'Y';
          if (intl) d.regNm = intl.formatMessage({ id: 'IFSearch.Registered' }); // '등록';
        } else {
          d.regYn = 'N';
          if (intl) d.regNm = intl.formatMessage({ id: 'IFSearch.Unregistered' }); // '미등록';
        }
      });
    }

    if (hasFilter) {
      const vinFilterValue = vin || '';
      const vehicleNmFilterValue = vehicleNumber || '';
      const modelNmFilterValue = modelName || '';
      const typeFilterValue = codeType || 'ALL';

      const filterCheckList = [
        vinFilterValue !== '',
        vehicleNmFilterValue !== '',
        modelNmFilterValue !== '',
        typeFilterValue !== 'ALL'
      ];

      filtedData = dataAll.filter((d) => {
        const { vin, plateNo, modelName, regYn } = d;

        const fnFilterVin = () => {
          if (filterCheckList[0]) {
            if (vin.toLowerCase().indexOf(vinFilterValue.toLowerCase()) !== -1) return true;

            return false;
          }

          return true;
        };

        const fnFilterVehNum = () => {
          if (filterCheckList[1]) {
            if (plateNo.toLowerCase().indexOf(vehicleNmFilterValue.toLowerCase()) !== -1)
              return true;

            return false;
          }
          return true;
        };

        const fnFilterModelNm = () => {
          if (filterCheckList[2]) {
            if (modelName.toLowerCase().indexOf(modelNmFilterValue.toLowerCase()) !== -1)
              return true;

            return false;
          }
          return true;
        };

        const fnRegYn = () => {
          if (filterCheckList[3]) {
            if (typeFilterValue === 'REGISTERED') {
              if (regYn === 'Y') return true;
              return false;
            }

            if (regYn === 'N') return true;
            return false;
          }
          return true;
        };

        return fnFilterVin() && fnFilterVehNum() && fnFilterModelNm() && fnRegYn();
      });
      length = filtedData.length;
    }
    // var setInfo = { ...action.params.pageInfo, totalRecord: length };

    const startIdx = (page - 1) * PAGE_LIMIT;
    const endIdx = startIdx + PAGE_LIMIT;

    const realDatas = hasFilter
      ? filtedData.slice(startIdx, endIdx)
      : dataAll.slice(startIdx, endIdx);
    const reqPager = action && action.params ? action.params : null;
    const pageInfo = {
      ...(reqPager ? reqPager.pageInfo : null),
      totalRecord: length
    };

    return {
      dataAll,
      regList,
      data: realDatas,
      pager: { ...reqPager, pageInfo }
    };
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  },
  afterSuccessFn: () => {}
});

export const entitySetVehicleSapDetail = createEntity({
  key: setVehicleSapDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

// page action reducer 선언
export const vehicleSap = combineReducers({
  list: createReducer({
    entity: entityVehiclesSapList,
    reducer: vehiclesSapListPageReducer
  }),
  detail: createReducer({
    entity: entitySetVehicleSapDetail,
    reducer: setVehicleSapDetailReducer
  })
});
