import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getClearComapnyAPI,
  getCompanyListAPI,
  // getCompanyListAllAPI,
  getInsuranceCompanyListAPI,
  getCompanyDetailAPI,
  createCompanyAPI,
  updateCompanyAPI,
  deleteCompanyAPI
  // deleteCompanysAPI, // 거래처 복수 삭제 기능
} from 'v2/apis/companyApi';

import {
  getSurveyListAPI,
  // getBranchDetailAPI,
  // createBranchAPI,
  // updateBranchAPI,
  // deleteBranchAPI,
  getAllBranchListAPI
} from 'v2/apis/surveyApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  loadAllSurvey,
  getSurveyList,
  entitySurveyList
  // deleteForBranchList,
  // changeBranchDetailPage,
  // getBranchDetail,
  // createBranch,
  // updateBranch,
  // deleteBranch,
  // entityLoadAllBranchList,
  // entityBranchList,
  // entityBranchDetail,
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/survey';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorSurveyListPage = (state) => state.survey.list;
export const selectorsurveyLoadAll = (state) => state.survey.loadAll;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page

/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getSurveyListAsync = createSagaAction.bind(null, entitySurveyList, getSurveyListAPI);
// const getBranchDetailAsync = createSagaAction.bind(
//   null,
//   entityBranchDetail,
//   getBranchDetailAPI,
// );
// const loadAllBranchListAsync = createSagaAction.bind(
//   null,
//   entityLoadAllBranchList,
//   getAllBranchListAPI,
// );

// function* changeBranchPageSaga() {
//   yield put(changeBranchDetailPage({ mode: 'view' }));
// }
function* loadAllSaga() {
  const {
    pager: { filters }
  } = yield select(selectorsurveyLoadAll);
  yield put(loadAllSurvey(filters));
}

/**
 * Watcher
 */
export default function* surveyWatcher() {
  yield takeLatest(getSurveyList().type, fetchSaga, getSurveyListAsync, entitySurveyList);
  // yield takeLatest(
  //   getBranchDetail().type,
  //   fetchSaga,
  //   getBranchDetailAsync,
  //   entityBranchDetail,
  // );
  // // select business
  // yield takeLatest(
  //   loadAllSurvey().type,
  //   fetchSaga,
  //   loadAllBranchListAsync,
  //   entityLoadAllBranchList,
  // );
  // type, worker
  // success update for detail page -> change mode for detail page
  // yield takeLatest(entityUpdateBranch.types[1], changeBranchPageSaga);
  // yield takeLatest(entityCreateBranch.types[1], loadAllSaga);
  // yield takeLatest(entityUpdateBranch.types[1], loadAllSaga);
  // yield takeLatest(entityDeleteBranch.types[1], loadAllSaga);
}
