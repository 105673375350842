import { takeLatest } from 'redux-saga/effects';
import { getVehicleLogAPI } from 'v2/apis/vehicleApi';
import { createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { getVehicleLogList, entityVehicleLog } from '.';

const getVehicleLogAsync = createSagaAction.bind(null, entityVehicleLog, getVehicleLogAPI);

export default function* vehicleLog() {
  yield takeLatest(getVehicleLogList().type, fetchSaga, getVehicleLogAsync, entityVehicleLog);
}
