import request from 'apis/request';

const prefix = '/taxRates';

export const getTaxRateListAPI = (countryID) => {
  return request({
    url: `${prefix}`,
    data: countryID
  });
};

export const updateTaxRateAPI = (data) => {
  return request({
    url: `${prefix}`,
    method: 'patch',
    data: data
  });
};
