import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import sagaMonitor from '@redux-saga/simple-saga-monitor';
import penderMiddleware, { penderReducer } from 'redux-pender';
import reducers from 'redux/modules';
import { default as sagaReducers, rootInitState } from 'v2/redux/modules';

import rootSaga from './watchers';

const rootReducers = combineReducers({ ...sagaReducers, pender: penderReducer, ...reducers });

// TODO https://redux.js.org/recipes/code-splitting/
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const configureStore = () => {
  let store = null;
  console.log('store process.env.NODE_ENV:', process.env.NODE_ENV);
  if (process.env.NODE_ENV === 'development' || process.env.FMS_NAME !== '운영') {
    const devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
      rootReducers,
      rootInitState,
      devTools(compose(applyMiddleware(penderMiddleware(), sagaMiddleware)))
    );
  } else {
    store = createStore(
      rootReducers,
      rootInitState,
      applyMiddleware(penderMiddleware(), sagaMiddleware)
    );
  }
  store.runSaga = sagaMiddleware.run;
  sagaMiddleware.run(rootSaga);
  // TODO store.close = () => store.dispatch(END);

  return store;
};
export default configureStore();
