import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import addMinutes from 'date-fns/add_minutes';
import isDate from 'date-fns/is_date';
import addMonths from 'date-fns/add_months';
import isSameDay from 'date-fns/is_same_day';

export { isDate, addMonths, isSameDay };
// 1970.1.2 < 이하이면 선택 안한 것과 동일.
export const DATETIME_MIN_VALUE = 1000 * 60 * 60 * 24;

export function convertInputDayToDate(selectedDay, defaultDay) {
  if (isSelectedDay(selectedDay)) return new Date(selectedDay);
  if (defaultDay && isSelectedDay(defaultDay)) return new Date(defaultDay);
  return new Date();
}
export function isSelectedDay(selectedDay) {
  if (
    selectedDay
    && isDate(new Date(selectedDay))
    && new Date(selectedDay).getTime() > DATETIME_MIN_VALUE
  )
    return true;
  return false;
}
export function convertInputTimeToDate(inTime, defaultTime) {
  if (isSelectedTime(inTime)) return new Date(inTime);
  if (defaultTime && isSelectedTime(defaultTime)) return new Date(defaultTime);
  return new Date();
}
export function isSelectedTime(inTime) {
  if (inTime && inTime > DATETIME_MIN_VALUE) return true;
  return false;
}
export function convertInputTimeToDateNull(inTime) {
  if (isSelectedTime(inTime)) return new Date(inTime);
  return null;
}

const _optionsHours = [];
export function optionsHours(length = 24) {
  if (!_optionsHours || _optionsHours.length < 1) {
    Array.from({ length }).forEach((x, i) => {
      const v = `${i}`.padStart(2, '0');

      _optionsHours.push({ label: v, value: `${i}` });
    });
  }
  return _optionsHours;
}

export function optionsMinutes(step = 1) {
  const length = parseInt(60 / step);

  const _optionsMinutes = [];
  Array.from({ length }).forEach((x, i) => {
    const v = `${i * step}`.padStart(2, '0');

    _optionsMinutes.push({ label: v, value: `${i * step}` });
  });
  return _optionsMinutes;
}

export function getNextStepMinute(date, step) {
  if (!date) {
    return date;
  }
  if (!isDate(date)) {
    return null;
  }

  date.setSeconds(0);
  date.setMilliseconds(0);

  let calcDate = new Date(date);

  if (step > 1) {
    const minusValue = parseInt(calcDate.getMinutes() % step);
    if (minusValue >= 0) {
      // 김석환 팀장님 요구사항. 2019-11-07
      calcDate = addMinutes(calcDate, step);
      calcDate = addMinutes(calcDate, minusValue * -1);
    }
  }
  return calcDate;
}

const TIME_LABEL = {
  ko: ['시', '분']
};
export const WEEKDAYS_SHORT = {
  ko: ['일', '월', '화', '수', '목', '금', '토'],
  en: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
};
const WEEKDAYS_LONG = {
  ko: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
};
const MONTHS = {
  ko: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']
};
const FIRST_DAY_OF_WEEK = {
  //  ko: 0
};

export const FORMAT_DAY = {
  ko: 'YYYY.MM.DD'
};
export const FORMAT_DAYTIME = {
  ko: 'YYYY.MM.DD HH:mm'
};
const FORMAT_TITLE = {
  ko: 'YYYY.MM'
};

export function defaultTimeLabel(unit = 0, locale = defaultLocale) {
  return TIME_LABEL[locale] ? TIME_LABEL[locale][unit] : TIME_LABEL[0][unit];
}

export function defaultFormat(locale = defaultLocale, daytime = false) {
  if (daytime) {
    return FORMAT_DAYTIME[locale] ? FORMAT_DAYTIME[locale] : FORMAT_DAYTIME[0];
  }
  return FORMAT_DAY[locale] ? FORMAT_DAY[locale] : FORMAT_DAY[0];
}

export function defaultParseDate(str, format, locale = defaultLocale) {
  const parsed = dateFnsParse(str, format, { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

export function defaultFormatDate(date, format, locale = defaultLocale) {
  return date && date.getTime() > DATETIME_MIN_VALUE ? dateFnsFormat(date, format, { locale }) : '';
}

export function formatDay(d, locale = defaultLocale) {
  let value;
  if (FORMAT_DAY[locale]) {
    value = defaultFormatDate(d, FORMAT_DAY[locale], locale);
  } else {
    switch (locale) {
      case 'en':
      default:
        value = `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${
          MONTHS[locale][d.getMonth()]
        } ${d.getFullYear()}`;
        break;
    }
  }
  return value;
}

function formatMonthTitle(d, locale = defaultLocale) {
  let value;
  if (FORMAT_TITLE[locale]) {
    value = defaultFormatDate(d, FORMAT_TITLE[locale], locale);
  } else {
    switch (locale) {
      case 'en':
      default:
        value = `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()} `;
        break;
    }
  }

  return value;
}

function formatWeekdayShort(i, locale = defaultLocale) {
  return WEEKDAYS_SHORT[locale][i];
}

function formatWeekdayLong(i, locale = defaultLocale) {
  return WEEKDAYS_LONG[locale][i];
}

function getFirstDayOfWeek(locale = defaultLocale) {
  return FIRST_DAY_OF_WEEK[locale] ? FIRST_DAY_OF_WEEK[locale] : 0;
}

export const defaultLocaleUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek
};

export const defaultModifiers = {
  saturday: { daysOfWeek: [6] },
  sunday: { daysOfWeek: [0] }
};

export const defaultLocale = 'ko';
