import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { FMSColors } from 'service/common/designLib';
import FMSCheckbox from 'v2/components/ui/input/FMSCheckbox';
import Select, { components } from 'react-select';

const ALL_VALUE = '_All';

const SelectStyledComponent = styled(Select)`
  width: ${(props) => (props.width ? props.width : '100%')};
  min-height: 32px;
  height: 100%;
  font-size: 13px;
  line-height: 22px;
`;

const customStyles = {
  container: (base, state) => ({ ...base, zIndex: state.isFocused ? 999 : 0 }),
  option: (provided, { isSelected }) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '2em',
    color: '#999',
    backgroundColor: isSelected ? '#fbfbfb' : null,
    cursor: 'pointer',
    ':hover': {
      color: FMSColors.primary,
      backgroundColor: '#fbfbfb'
    },
    ':active': {
      color: FMSColors.primary,
      backgroundColor: '#fbfbfb'
    },
    transition: 'all 0.2s ease-in-out',
    zIndex: 999
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      maxWidth: 'calc(100% - 30px)',
      paddingRight: '24px'
    };
  },
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: '#fbfbfb',
      borderRadius: '18px',
      '&:not(:first-child)': {
        marginLeft: '10px'
      }
    };
  },
  multiValueLabel: (provided, { isDisabled }) => {
    return {
      ...provided,
      color: FMSColors.primary,
      padding: isDisabled ? '3px 10px' : '3px',
      paddingLeft: '10px'
    };
  },
  multiValueRemove: (provided, { isDisabled }) => {
    return {
      ...provided,
      display: isDisabled ? 'none' : 'inherit',
      color: '#848ca5',
      ':hover': {
        color: '#000000'
      }
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: '#FFF',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      lineHeight: '22px',
      minHeight: '32px',
      height: '100%',
      minWidth: '150px',
      backgroundImage: state.isDisabled
        ? 'none'
        : `url('${process.env.PUBLIC_URL}/assets/images/un.png')`,
      backgroundPosition: 'right 4px center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '25px',
      cursor: 'pointer',
      boxShadow: 'none',
      '&:hover': state.isFocused
        ? {
            borderColor: FMSColors.primary,
            backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/un2-focus.png')`
          }
        : null,
      transition: 'border-color 0.2s ease-in-out',
      borderRadius: '1px',
      '& > div': { display: 'inline-flex' },
      ...(state.selectProps && state.selectProps.error
        ? {
            borderColor: FMSColors.danger,
            backgroundPosition: 'right 8px center',
            backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/warning-line.svg')`
          }
        : {
            borderColor: '#dbdee1'
          })
    };
  },
  placeholder: () => ({
    color: '#cbcbcb',
    fontSize: '13px',
    lineHeight: '32px'
  }),
  valueContainer: () => ({
    marginLeft: '3px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '7px 7px 7px 7px',
    position: 'relative',
    right: '23px'
  })
};
const Option = React.memo((props) => {
  const { isSelected, label } = props;
  return (
    <div>
      <components.Option {...props}>
        <FMSCheckbox
          triggerValidationOff
          defaultChecked={isSelected}
          label={label}
          labelIsCode={false}
          name={`InputSelectBase-checkbox-${label}`}
        />
      </components.Option>
    </div>
  );
});
const ValueContainer = React.memo(({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val) => val.value === ALL_VALUE)) {
    toBeRendered = [[children[0][0]], children[1]];
  }
  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
});

const SelectComponent = React.memo(
  ({
    selectOptions,
    selectedOption,
    onSelectedItem,
    disabled,
    name,
    value, // ignore 시켜야 함.
    ...props
  }) => {
    const { isMulti = false } = props;
    return (
      <div className='SelectComponent' style={{ height: '100%' }}>
        <SelectStyledComponent
          {...{
            key: `SelectComponent__${selectedOption ? selectedOption.value : ''}`,
            components: {
              DropdownIndicator: null,
              IndicatorSeparator: null,
              ...(isMulti ? { Option, ValueContainer } : null)
            },
            styles: customStyles,
            isDisabled: disabled,
            options: selectOptions,
            onChange: onSelectedItem,
            /** isMulti */
            ...(isMulti
              ? {
                  hideSelectedOptions: false,
                  closeMenuOnSelect: false,
                  isClearable: false
                }
              : null),
            value: selectedOption,
            // defaultValue: selectedOption,
            menuPlacement: 'auto',
            ...props
          }}
        />
      </div>
    );
  }
);

const InputSelectBaseComponet = React.memo(({ mode, selectedOption, ...props }) => {
  if (mode !== 'view') {
    return (
      <div className='InputSelectBaseComponet' style={{ width: '100%', height: '100%' }}>
        <SelectComponent
          {...{
            selectedOption,
            ...props
          }}
        />
      </div>
    );
  }

  if (selectedOption) {
    if (selectedOption.length) {
      return (
        <>
          {selectedOption.map(
            (item, index) => `${item.label}${index < selectedOption.length - 1 ? ',' : ''}`
          )}
        </>
      );
    }
    return <>{selectedOption ? selectedOption.label : ''}</>;
  }
});

const InputSelectBase = ({
  style, // 전파하지 않음
  onChange, // 전파하지 않음, InputSelect에서 다시 생성.
  options, // 전파하지 않음, InputSelect에서 다시 생성.
  isCodeValue, // 전파하지 않음, options 생성시 사용.
  placeholder, // 전파하지 않음, 다시 생성해서 전파(message 처리)
  defaultValue, // 전파하지 않음, 사용하지 않음.
  value, // 전파하지 않음, 사용하지 않음.
  hidden = false, // 전파하지 않음, hidden 처리는 InputSelect
  mode, // 전파하지 않음, InputSelect에서 사용
  includeAll = false,
  initError,
  onChangeError,
  loading,
  ...props
}) => {
  const { /* name, */ selectedValue, isMulti } = props; // 필수 입력.
  const { initSelect } = props; // clear select

  const intl = useIntl();
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (loading) {
      setSelectOptions([]);
      setSelectedOption(null);
    }
  }, [loading]);

  // onChange
  const onSelectedItem = useCallback(
    (e) => {
      let selectedItems = e;
      let selectedAll = false;
      let clearSelectedAll = false;
      if (includeAll) {
        const oldOnSelectedAll =
          selectedOption
          && selectedOption.some
          && selectedOption.some((item) => item.value === ALL_VALUE);
        const newOnSelectedAll =
          selectedItems
          && selectedItems.some
          && selectedItems.some((item) => item.value === ALL_VALUE);
        // old selectAll checked(true) && !new selectAll unchecked(false) -> all select 해제.
        const offSelectedAll = oldOnSelectedAll && !newOnSelectedAll;
        let onSelectedAll = !oldOnSelectedAll && newOnSelectedAll;
        if (!offSelectedAll && !newOnSelectedAll) {
          onSelectedAll =
            selectedItems && selectedItems.length && selectOptions && selectOptions.length
              ? selectedItems.length === selectOptions.length - 1
              : 0;
        }

        if (onSelectedAll) selectedAll = true;
        else if (offSelectedAll) clearSelectedAll = true;
      }

      if (selectedAll) selectedItems = selectOptions;
      else if (clearSelectedAll) selectedItems = null;
      else
        selectedItems =
          selectedItems && selectedItems.filter
            ? selectedItems.filter((item) => item.value !== ALL_VALUE)
            : selectedItems;

      if (onChange) {
        onChange(selectedItems);
        if (onChangeError) onChangeError(!!selectedItems, selectedItems);
      }
      setSelectedOption(selectedItems || null);
      if (initError)
        initError(
          selectedItems ? !(selectedItems.value || selectedItems.length > 0) : true,
          selectedItems || null
        );
    },
    [selectedOption]
  );

  // init selectedValue
  useEffect(() => {
    if (!selectedOption || initSelect) {
      // initSelect props를 true 설정한 경우 selectedOption 제거
      if (selectOptions && selectOptions.length && selectOptions.length > 0) {
        if (isMulti) {
          const selectedDelimeter = ',';
          const arValues =
            selectedValue && selectedValue.split ? selectedValue.split(selectedDelimeter) : [];
          const findItem = selectOptions.filter(
            (item) => arValues
              && (arValues.includes(item.value) || arValues.includes(item.value.toString()))
          );
          if (includeAll && findItem && findItem.length === selectOptions.length - 1)
            setSelectedOption(selectOptions);
          else setSelectedOption(findItem);
          if (initError)
            initError(findItem ? !(findItem.value || findItem.length > 0) : true, findItem);
        } else {
          const findItem = selectOptions.find((item) => {
            if (typeof item.value === typeof selectedValue) return item.value === selectedValue;
            if (item.value && selectedValue)
              return item.value.toString() === selectedValue.toString();
            return false;
          });
          setSelectedOption(findItem);
          if (initError)
            initError(findItem ? !(findItem.value || findItem.length > 0) : true, findItem);
        }
      } else {
        // myBusiness -> myBrach 변화시 초기값
        setSelectedOption(null);
        if (initError) initError(false, null);
      }
    }
  }, [selectOptions, selectedValue]);

  // useEffect(() =>{
  //   if (initError) initError(selectedOption ? !(selectedOption.value || selectedOption.length > 0) : true);
  // }, [selectedOption]);

  useEffect(() => {
    const newOptions =
      options
      && options.map((option) => {
        const newOption = { ...option };
        if (isCodeValue && typeof newOption.label === 'string' && intl?.messages) {
          newOption.label =
            (intl.messages[newOption.label] && intl.formatMessage({ id: newOption.label }))
            || newOption.label;
        }
        return newOption;
      });
    if (includeAll) {
      const newOption = {
        label: intl.formatMessage({ id: 'Common.Btn.AllSelect' }),
        value: ALL_VALUE
      };
      if (newOptions && newOptions.length) setSelectOptions([newOption, ...newOptions] || []);
    } else {
      setSelectOptions(newOptions || []);
    }
  }, [includeAll, options, isCodeValue]);

  return hidden ? null : (
    <InputSelectBaseComponet
      {...{
        selectOptions,
        selectedOption,
        onSelectedItem,
        placeholder: placeholder ? intl.formatMessage({ id: placeholder }) : null,
        mode,
        hidden,
        style,
        ...props
      }}
    />
  );
};
export default React.memo(InputSelectBase);
