import { takeLatest } from 'redux-saga/effects';
import { confirmFetchSaga, createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { getDigitalContractListAPI, updateDigitalContractAPI } from 'v2/apis/digitalContractAPI';

import {
  entityDigitalContractList,
  entityUpdateDigitalContract,
  getDigitalContractList,
  updateDigitalContract
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/digitalContract';

/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// 리스트조회
const getDigitalContractListAsync = createSagaAction.bind(
  null,
  entityDigitalContractList,
  getDigitalContractListAPI
);
const updateDigitalContractAsync = createSagaAction.bind(
  null,
  entityUpdateDigitalContract,
  updateDigitalContractAPI
);

export default function* digitalContractWatcher() {
  yield takeLatest(
    getDigitalContractList().type,
    fetchSaga,
    getDigitalContractListAsync,
    entityDigitalContractList
  );
  yield takeLatest(
    updateDigitalContract().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateDigitalContractAsync,
    entityUpdateDigitalContract
  );
}
