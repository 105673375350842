import { takeLatest } from 'redux-saga/effects';
import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import {
  getAlramsListAPI,
  getAlarmsDetailAPI,
  createAlramsAPI,
  createAPI
} from 'v2/apis/alarmsAPI';

import {
  getAlramsList,
  entityAlarmList,
  initializeAlarmsDetailPage,
  changeAlarmsPage,
  changeAlarmsDetailPage,
  getAlarmsDetailNew,
  entityAlarmsDetail,
  createAlarmsNew,
  createAlarms,
  changeState
} from '.';
import { getMembersAPI } from '../../../apis/memberApi';

/** ********************************************** */
/** constant */
export const historyUrl = '/alarms';
/** ********************************************** */
/** ********************************************** */
/** saga selector */
export const selectorAlarmsListPage = (state) => state.alram.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreate = createEntity({
  key: createAlarms().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(changeAlarmsDetailPage({ mode: 'view' }));
    // const selectorListPage = yield select(selectorAlarmsListPage);
    // const { pager } = selectorListPage;
    // const { ID } = payload;
    // history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    // if (ID) yield put(getAlarmsDetailNew(ID));
  }
});
export const entityCreateAlarms = createEntity({
  key: createAlarmsNew().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) {
      console.log('==========', payload);
      FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    } else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    console.log('성공후=======================', payload);
    FMSCommon.toast.success('Common.Created.Success');

    yield put(changeAlarmsDetailPage({ mode: 'view' }));
    const selectorListPage = yield select(selectorAlarmsListPage);
    const { pager } = selectorListPage;
    const { ID } = payload;
    history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    yield put(getAlarmsDetailNew(ID));
  }
});

function* changeAlarmsDetailPageSaga() {
  yield put(changeState({ mode: 'view' }));
}

const createAsync = createSagaAction.bind(null, entityCreate, createAPI);

// 리스트조회
const getAlramListAsync = createSagaAction.bind(null, entityAlarmList, getAlramsListAPI);
// 상세보기
const getAlarmsDetailAsync = createSagaAction.bind(null, entityAlarmsDetail, getAlarmsDetailAPI);
// 생성
const createAlamsync = createSagaAction.bind(null, entityCreateAlarms, createAlramsAPI);

export default function* AlarmWatcher() {
  yield takeLatest(getAlramsList().type, fetchSaga, getAlramListAsync, entityAlarmList);

  yield takeLatest(getAlarmsDetailNew().type, fetchSaga, getAlarmsDetailAsync, entityAlarmsDetail);
  yield takeLatest(
    createAlarmsNew().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createAlamsync,
    entityCreateAlarms
  );
  yield takeLatest(
    createAlarms().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createAsync,
    entityCreate
  );
}
