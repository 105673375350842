import { put, select, takeLatest } from 'redux-saga/effects';

import {
  saveVehiclePurchaseAPI,
  updateVehiclePurchaseAPI,
  getVehiclePurchaseAPI
} from 'v2/apis/vehiclePurchaseApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  saveVehiclePurchase,
  updateVehiclePurchase,
  getVehiclePurchaseDetail,
  entityVehiclePurchaseDetail
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/vehicle';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorBranchListPage = (state) => state.branch.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entitySaveVehiclePurchase = createEntity({
  key: saveVehiclePurchase().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn(payload, requestParams) {
    const { vehicleID } = requestParams || null;
    // history.push({ pathname: `${historyUrl}/${vehicleID}/purchase` });
    FMSCommon.toast.success('Common.Created.Success');
    // const selectorListPage = yield select(selectorBranchListPage);
    // const { pager } = selectorListPage;
    // const { ID } = payload;
    // history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
  }
});

// // update for update page
export const entityUpdateVehiclePurchase = createEntity({
  key: updateVehiclePurchase().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    const { vehicleID } = requestParams || null;
    // history.push({ pathname: `${historyUrl}/${vehicleID}/purchase` });
    FMSCommon.toast.success('Common.Updated.Success');
    yield put(getVehiclePurchaseDetail(vehicleID));
  }
});
// // delete for detail page
// export const entityDeleteBranch = createEntity({ key: deleteBranch().type
//   , afterFailureFn: () => {
//     FMSCommon.toast.fail('Common.Delete.Fail');
//   }
//   , *afterSuccessFn() {
//     FMSCommon.toast.success('Common.Deleted.Success');
//     const selectorListPage = yield select(selectorBranchListPage);
//     const { pager } = selectorListPage;
//     history.push({ pathname: historyUrl, state: pager });
//   },
// });

// // mulitple delete for list page.
// export const entityDeleteForBranchList = createEntity({ key: deleteForBranchList().type
//   , *beforeFn(payload) {
//     const isContinue = payload && payload.length;
//     if(isContinue) yield put(changeBranchDetailPage({ loading: true }));
// else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');

//     return isContinue;
//   }
//   , *afterSuccessFn() {
//     FMSCommon.toast.success('Common.Deleted.Success');
//     const selectorListPage = yield select(selectorBranchListPage);
//     const { pager: { pageInfo, sortInfo, filters } } = selectorListPage;
//     yield put(getBranchList(pageInfo, sortInfo, filters));
//   }
//   , *afterFailureFn() {
//     FMSCommon.toast.fail('Common.Deletes.Fail');
//     const selectorListPage = yield select(selectorBranchListPage);
//     const { pager: { pageInfo, sortInfo, filters } } = selectorListPage;
//     yield put(getBranchList(pageInfo, sortInfo, filters));
//   } });
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const saveVehiclePurchaseAsync = createSagaAction.bind(
  null,
  entitySaveVehiclePurchase,
  saveVehiclePurchaseAPI
);
const updateVehiclePurchaseAsync = createSagaAction.bind(
  null,
  entityUpdateVehiclePurchase,
  updateVehiclePurchaseAPI
);
const getVehiclePurchaseDetailAsync = createSagaAction.bind(
  null,
  entityVehiclePurchaseDetail,
  getVehiclePurchaseAPI
);

/**
 * Watcher
 */
export default function* branchWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    saveVehiclePurchase().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    saveVehiclePurchaseAsync,
    entitySaveVehiclePurchase
  );
  yield takeLatest(
    updateVehiclePurchase().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateVehiclePurchaseAsync,
    entityUpdateVehiclePurchase
  );
  yield takeLatest(
    getVehiclePurchaseDetail().type,
    fetchSaga,
    getVehiclePurchaseDetailAsync,
    entityVehiclePurchaseDetail
  );
}
