import request from 'apis/request';

const prefix = '/monitoring/vehicle';
const prefixVehicle = '/vehicle';
const prefixVehicleMySales = '/vehicleMySales';
const prefixVehicleSap = '/vehicle/vehicleAddedInfo';
const prefixVehicleMaintenance = '/vehicle/maintenance';

function getVehicleSortFieldNum(field) {
  switch (field) {
    default:
      return 0;
    case 'model.name':
      return 1;
  }
}

export const getVehicleListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getVehicleSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

const ALL_VALUE = '_All';
const parseFormData = (data) => {
  const formData = _.pickBy(data);

  // {"insurance":{"age":21,"BI1":"1","BI2":"2","PD":"3","UVD":"4","DB":"5","IBI":"6","IPD":"7","IDB":"8","ISD":"99"},
  // "insureSelfVehicle":true,
  // "used":true,
  // "licensePlateNumber":"99가1123",
  // "vin":"1234567890poiuytr",
  // "controllerID":"11123",
  // "modelYear":1111,
  // "color":"WHITE",
  // "transmission":"AUTO",
  // "extendCnt":0,
  // "locationID":"03d44985-1f12-4c01-b750-b3ea6f0391c1",
  // "modelID":"1eba04dd-3671-4f94-b66b-bf64c7b3e0ad",
  // "companyID":2127,
  // "regularInspectionDate":"2020-03-02",
  // "registrationDate":"2020-03-23",
  // "expiryDate":"2020-03-23",
  // "options":["NOSMOKING","DASHCAM","REARVIEW","NAVIGATION"],
  // "branchID":8,
  // "groupID":"414b84a2-e2a6-4b9a-ae5b-765c93f81c28"}

  formData.regularInspectionDate = formData.regularInspectionDate
    ? formData.regularInspectionDate.replace(/\./g, '-')
    : undefined;
  formData.registrationDate = formData.registrationDate
    ? formData.registrationDate.replace(/\./g, '-')
    : undefined;
  formData.expiryDate = formData.expiryDate ? formData.expiryDate.replace(/\./g, '-') : undefined;
  formData.options =
    formData.options &&
    formData.options.filter &&
    formData.options.filter((item) => item.value !== ALL_VALUE).map((item) => item.value);
  formData.insureSelfVehicle = formData.insureSelfVehicle === 'true';
  formData.used = formData.used === 'true';
  formData.extendCnt =
    formData.extendCnt && formData.extendCnt.value ? Number(formData.extendCnt.value) : undefined;
  formData.modelYear = formData.modelYear ? Number(formData.modelYear) : undefined;
  // formData.color =formData.color && formData.color.value ? formData.color.value : undefined;
  formData.transmission =
    formData.transmission && formData.transmission.value ? formData.transmission.value : undefined;

  if (formData.business && !formData.business.isPrivateCompany) {
    formData.insurance = {};
    formData.insurance.age =
      formData.insuranceAge && formData.insuranceAge.value
        ? Number(formData.insuranceAge.value)
        : undefined;
    formData.insurance.BI1 = formData.insuranceBI1 ? formData.insuranceBI1 : undefined;
    formData.insurance.BI2 = formData.insuranceBI2 ? formData.insuranceBI2 : undefined;
    formData.insurance.PD = formData.insurancePD ? formData.insurancePD : undefined;
    formData.insurance.UVD = formData.insuranceUVD ? formData.insuranceUVD : undefined;
    formData.insurance.DB = formData.insuranceDB ? formData.insuranceDB : undefined;
    formData.insurance.IBI = formData.insuranceIBI ? formData.insuranceIBI : undefined;
    formData.insurance.IPD = formData.insuranceIPD ? formData.insuranceIPD : undefined;
    formData.insurance.IDB = formData.insuranceIDB ? formData.insuranceIDB : undefined;
    formData.insurance.ISD = formData.insuranceISD ? formData.insuranceISD : undefined;
    formData.insurance.IPH = formData.insuranceIPH ? formData.insuranceIPH : undefined; /** 추가 */
    formData.insurance.BVO = formData.insuranceBVO ? formData.insuranceBVO : undefined;
    formData.insurance.IVO = formData.insuranceIVO ? formData.insuranceIVO : undefined;
    formData.insurance.VPN = formData.insuranceVPN ? formData.insuranceVPN : undefined;
    formData.insurance.BLN = formData.insuranceBLN ? formData.insuranceBLN : undefined;
    formData.insurance.ILN = formData.insuranceILN ? formData.insuranceILN : undefined;
    formData.insurance.companyId =
      formData.company && formData.company.value ? formData.company.value : undefined;
    formData.insurance.companyId = Number.isInteger(formData.insurance.companyId)
      ? `${formData.insurance.companyId}`
      : formData.insurance.companyId;
    formData.insurance.company =
      formData.company && formData.company.label ? formData.company.label : undefined;
    formData.insurance.insureSelfVehicle = formData.insureSelfVehicle === true;

    delete formData.company;
    delete formData.insuranceAge;
    delete formData.insuranceBI1;
    delete formData.insuranceBI2;
    delete formData.insurancePD;
    delete formData.insuranceUVD;
    delete formData.insuranceDB;
    delete formData.insuranceIBI;
    delete formData.insuranceIPD;
    delete formData.insuranceIDB;
    delete formData.insuranceISD;
    delete formData.insuranceIPH;
    delete formData.insuranceBVO;
    delete formData.insuranceIVO;
    delete formData.insuranceVPN;
    delete formData.insuranceBLN;
    delete formData.insuranceILN;
    delete formData.company;
  }

  formData.countryID =
    formData.country && formData.country.ID ? formData.country.ID : formData.countryID;
  delete formData.country;

  formData.groupID =
    formData.business && formData.business.ID ? formData.business.ID : formData.groupID;
  delete formData.business;

  formData.branchID = formData.branchID ? formData.branchID : undefined;
  delete formData.branch;

  formData.locationID =
    formData.location && formData.location.value ? formData.location.value : undefined;
  delete formData.location;

  formData.modelID = formData.model && formData.model.value ? formData.model.value : undefined;
  delete formData.model;

  // 추가데이터 숫자형으로 변환
  formData.seatCapacity = formData.seatCapacity ? Number(formData.seatCapacity) : undefined;
  formData.length = formData.length
    ? Number(
        typeof formData.length === 'string' ? formData.length.replace(/,/g, '') : formData.length
      )
    : undefined;
  formData.width = formData.width
    ? Number(typeof formData.width === 'string' ? formData.width.replace(/,/g, '') : formData.width)
    : undefined;
  formData.height = formData.height
    ? Number(
        typeof formData.height === 'string' ? formData.height.replace(/,/g, '') : formData.height
      )
    : undefined;
  formData.trunkCapacity = formData.trunkCapacity
    ? Number(
        typeof formData.trunkCapacity === 'string'
          ? formData.trunkCapacity.replace(/,/g, '')
          : formData.trunkCapacity
      )
    : undefined;

  return formData;
};

/**
 * 차량 상세 조회
 */
export const getVehicleBasicDetailAPI = ({ ID }) => {
  // console.log('detail api in');
  return request({
    url: `${prefix}/${ID}`
  });
};

export const addVehicleInfoAPI = (formData) => {
  const data = parseFormData(formData);
  return request({
    url: prefixVehicle,
    method: 'post',
    data
  });
};

export const addVehicleKpmsInfoAPI = (data) => {
  console.log('formData:', data);
  // return false;
  return request({
    url: '/kpms/vehicle',
    method: 'post',
    data
  });
};

/**
 * OCR을 통한 차량등록증으로부터의 차량 등록
 *
 * @param {*} param0
 */
export const createVehicleByRegistrationCardImageAPI = ({ data: formData }) => {
  const data = parseFormData(formData);

  return request({
    url: `${prefixVehicle}/ocr`,
    method: 'post',
    data
  });
};

export const editVehicleInfoAPI = (formData) => {
  const data = parseFormData(formData);

  return request({
    url: `${prefixVehicle}/${data.ID}`,
    method: 'patch',
    data
  });
};

export const deleteVehicleInfoAPI = ({ ID }) => {
  return request({
    url: `${prefixVehicle}/${ID}`,
    method: 'delete'
  });
};
export const retireVehicleInfoAPI = ({ ID }) => {
  return request({
    url: `${prefixVehicle}/${ID}/retire`,
    method: 'patch'
  });
};

/**
 * 차량 번호 중복 체크
 */
export const checkPlateNumberDuplicateAPI = ({ licensePlateNumber, countryID }) => {
  return request({
    url: `${prefixVehicle}/check`,
    data: {
      licensePlateNumber,
      countryID
    }
  });
};

export const getVehicleLogAPI = ({ filters, pageInfo }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { ...data } = filters;

  data.page = page;
  data.rowPerPage = limit;

  return request({
    url: '/reservation',
    data
  });
};

export const getVehicleMySalesListAPI = ({ filters = {} }) => {
  const data = {};
  data.dealerCode = filters.dealerCode;

  return request({
    url: `${prefixVehicleMySales}`,
    data
  });
};

/**
 * APP-07 SAP 연동 차량등록
 * @param {} param0
 * @returns
 */
export const getVehicleSapListAPI = ({ filters = {} }) => {
  const data = {};
  data.dealerCode = filters.dealerCode;
  data.countryID = filters.countryID;

  return request({
    url: `${prefixVehicleSap}`,
    data
  });
};

/**
 * 재정비 차량 목록 조회
 */
export const getRearrangementVehicleListAPI = ({ filters }) =>
  request({
    url: '/vehicle/rearrangement/available',
    data: {
      countryID: filters.countryID,
      businessID: filters.businessID,
      branchID: filters.branchID
    }
  });

// 차량 재정비 시간 저장
export const saveRearrangementHourAPI = (data) => {
  return request({
    url: '/vehicle/rearrangement',
    method: 'patch',
    data
  });
};

// 차량 브랜치이동
export const saveMoveBranchAPI = (data) => {
  return request({
    url: '/vehicle/move',
    method: 'post',
    data
  });
};

/**
 * APP-23 차량정비 가능 차량 목록 조회
 */
export const getMaintenanceAvailableVehicleListAPI = ({ filters = {} }) => {
  const data = {};
  data.branchID = filters.branchID;
  data.businessID = filters.businessID;
  data.startTime = filters.startTime;
  data.endTime = filters.endTime;

  return request({
    url: `${prefixVehicleMaintenance}/available`,
    data
  });
};

/**
 * APP-23 차량 정비일자 목록 조회
 */
export const getMaintenanceVehicleListAPI = ({
  filters = {},
  pageInfo = {}
  // sortInfo = {},
}) => {
  const { page = 1, limit = 20 } = pageInfo;

  const data = {};
  data.vehicleID = filters.vehicleId;
  data.page = page;
  data.rowPerPage = limit;

  // console.log(data);

  return request({
    url: `${prefixVehicleMaintenance}/history`,
    data
  });
};

/**
 * APP-23 차량 정비일자 등록
 */
export const addMaintenanceVehicleAPI = (data) => {
  return request({
    url: `${prefixVehicleMaintenance}`,
    method: 'post',
    data
  });
};

/**
 * APP-23 차량 정비일자 수정
 */
export const editMaintenanceVehicleAPI = (data) => {
  return request({
    url: `${prefixVehicleMaintenance}/history`,
    method: 'patch',
    data
  });
};

/**
 * APP-23 차량 정비일자 삭제
 */
export const deleteMaintenanceVehicleAPI = ({ ID }) => {
  return request({
    url: `${prefixVehicleMaintenance}/${ID}/history`,
    method: 'delete'
  });
};
