import React, { useEffect } from 'react';

let circle;
const Circle = ({ map, children, radius, lat, lng, ...props }) => {
  useEffect(() => {
    circle = new window.H.map.Circle(new window.H.geo.Point(lat, lng), radius, {
      style: {
        strokeColor: 'rgba(244, 13, 13, 0.5)',
        fillColor: 'rgba(244, 13, 13, 0.5)',
        lineWidth: 1
      }
    });
    // map.removeObject(circle);
  }, []);

  useEffect(() => {
    if (map) map.addObject(circle);
    else map.removeObject(circle);
  }, [map]);

  useEffect(() => {
    circle && circle.setRadius(radius);
  }, [radius]);

  useEffect(() => {
    circle && circle.setCenter(new window.H.geo.Point(lat, lng));
  }, [lat, lng]);

  return <>{children}</>;
};

export default Circle;
