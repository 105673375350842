import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeLoadAllBranch,
  loadAllBranch,
  initializeBranchListPage,
  changeBranchListPage,
  getBranchList,
  deleteForBranchList,
  initializeBranchDetailPage,
  changeBranchDetailPage,
  getBranchDetail,
  createBranch,
  updateBranch,
  deleteBranch,
  suspendBranch,
  restoreBranch,
  deleteOneBranchInList
} = createActions(
  {
    LOAD_ALL_BRANCH: (filters) => ({ filters }),
    GET_BRANCH_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_BRANCH_DETAIL: (ID) => ({ ID }),
    CREATE_BRANCH: (data) => ({ data }),
    UPDATE_BRANCH: (ID, data) => ({ ID, data }),
    DELETE_BRANCH: (ID) => ({ ID }),
    SUSPEND_BRANCH: (ID) => ({ ID }),
    RESTORE_BRANCH: (ID) => ({ ID }),
    DELETE_ONE_BRANCH_IN_LIST: (ID) => ({ ID })
  },
  'INITIALIZE_LOAD_ALL_BRANCH',
  'INITIALIZE_BRANCH_LIST_PAGE',
  'CHANGE_BRANCH_LIST_PAGE',
  'DELETE_FOR_BRANCH_LIST',
  'INITIALIZE_BRANCH_DETAIL_PAGE',
  'CHANGE_BRANCH_DETAIL_PAGE'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllBranchList = createEntity({
  key: loadAllBranch().type,
  parseData: parseDataForList
});

export const entityBranchList = createEntity({
  key: getBranchList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityBranchDetail = createEntity({
  key: getBranchDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create'
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const branchListPageReducer = handleActions(
  {
    [initializeBranchListPage().type]: () => initializeState.list,
    [changeBranchListPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const branchListLoadAllReducer = handleActions(
  {
    [initializeLoadAllBranch().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const branchDetailPageReducer = handleActions(
  {
    [initializeBranchDetailPage().type]: () => initializeState.detail,
    [changeBranchDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const branch = combineReducers({
  list: createReducer({
    entity: entityBranchList,
    reducer: branchListPageReducer
  }),
  detail: createReducer({
    entity: entityBranchDetail,
    reducer: branchDetailPageReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllBranchList,
    reducer: branchListLoadAllReducer
  })
});
/** ********************************************** */
