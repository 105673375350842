import { takeLatest } from 'redux-saga/effects';
import { createSagaAction, fetchSaga } from 'v2/redux/sagas';
import {
  getBusinessReportListAPI,
  getBranchInfoAPI,
  getVehicleModelInfoAPI
} from 'v2/apis/businessreportApi';
import {
  getBusinessReportList,
  entityBusinessReportList,
  getBranchInfo,
  entityBranchInfo,
  getVehicleModelInfo,
  entityVehicleModelInfo
} from '.';

const getBusinessReportListAsync = createSagaAction.bind(
  null,
  entityBusinessReportList,
  getBusinessReportListAPI
);
const getBranchInfoAsync = createSagaAction.bind(null, entityBranchInfo, getBranchInfoAPI);
const getVehicleModelInfoAsync = createSagaAction.bind(
  null,
  entityVehicleModelInfo,
  getVehicleModelInfoAPI
);

export default function* monitoringDataWatcher() {
  yield takeLatest(
    getBusinessReportList().type,
    fetchSaga,
    getBusinessReportListAsync,
    entityBusinessReportList
  );
  yield takeLatest(getBranchInfo().type, fetchSaga, getBranchInfoAsync, entityBranchInfo);
  yield takeLatest(
    getVehicleModelInfo().type,
    fetchSaga,
    getVehicleModelInfoAsync,
    entityVehicleModelInfo
  );
}
