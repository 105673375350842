import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeBusinessMembersPage,
  changeBusinessMembersPage,
  getBusinessMembers,
  deleteForBusinessMembers,
  initializeBusinessMemberPage,
  changeBusinessMemberPage,
  getBusinessMember,
  updateBusinessMember,
  deleteBusinessMember,
  confirmBusinessMember,
  denyBusinessMember
} = createActions(
  {
    GET_BUSINESS_MEMBERS: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_BUSINESS_MEMBER: (ID) => ({ ID }),
    UPDATE_BUSINESS_MEMBER: (ID, data) => ({ ID, data }),
    CONFIRM_BUSINESS_MEMBER: (ID, data) => ({ ID, data }),
    DELETE_BUSINESS_MEMBER: (ID) => ({ ID }),
    DENY_BUSINESS_MEMBER: (ID, data) => ({ ID, data })
  },
  'INITIALIZE_BUSINESS_MEMBERS_PAGE',
  'CHANGE_BUSINESS_MEMBERS_PAGE',
  'DELETE_FOR_BUSINESS_MEMBERS',
  'INITIALIZE_BUSINESS_MEMBER_PAGE',
  'CHANGE_BUSINESS_MEMBER_PAGE'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityBusinessMembers = createEntity({
  key: getBusinessMembers().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityBusinessMember = createEntity({
  key: getBusinessMember().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  detail: {
    data: null,
    loading: true,
    mode: 'view',
    aclMode: 'view'
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const listPageReducer = handleActions(
  {
    [initializeBusinessMembersPage().type]: () => initializeState.list,
    [changeBusinessMembersPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const detailPageReducer = handleActions(
  {
    [initializeBusinessMemberPage().type]: () => initializeState.detail,
    [changeBusinessMemberPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const businessMember = combineReducers({
  list: createReducer({
    entity: entityBusinessMembers,
    reducer: listPageReducer
  }),
  detail: createReducer({
    entity: entityBusinessMember,
    reducer: detailPageReducer
  })
});
/** ********************************************** */
