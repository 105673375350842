import { takeLatest } from 'redux-saga/effects';

import {
  getLicenseCheckHistoryListAPI,
  getLicenseCheckHistoryAPI
} from 'v2/apis/licenseCheckHistoryApi';

import { createSagaAction, fetchSaga } from 'v2/redux/sagas';

import {
  getLicenseCheckHistoryList,
  entityLicenseCheckHistoryList,
  getLicenseCheckHistory,
  entityLicenseCheckHistory
} from '.';

/** ********************************************** */
/** constant */
/** ********************************************** */

/** ********************************************** */
/** saga selector */
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getListAsync = createSagaAction.bind(
  null,
  entityLicenseCheckHistoryList,
  getLicenseCheckHistoryListAPI
);
const getDetailAsync = createSagaAction.bind(
  null,
  entityLicenseCheckHistory,
  getLicenseCheckHistoryAPI
);

/**
 * Watcher
 */
export default function* businessWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    getLicenseCheckHistoryList().type,
    fetchSaga,
    getListAsync,
    entityLicenseCheckHistoryList
  );
  yield takeLatest(
    getLicenseCheckHistory().type,
    fetchSaga,
    getDetailAsync,
    entityLicenseCheckHistory
  );
}
