import request from 'apis/request';

const prefix = '/holiday';

/**
 * 휴일 조회
 */
export const getHolidayListAPI = ({ filters }) => {
  return request({
    url: prefix,
    data: filters
  });
};

/**
 * 휴일 등록
 */
export const createHolidayAPI = ({ data }) => {
  return request({
    url: prefix,
    method: 'post',
    data
  });
};

/**
 * 휴일 상세 조회
 */
export const getHolidayDetailAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}`,
    data
  });
};

/**
 * 휴일 수정
 */
export const updateHolidayAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'put',
    data
  });
};

/**
 * 휴일 삭제
 */
export const deleteHolidayAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
};

/**
 * 사업자 목록 조회
 */
export const getHolidayBusinessListAPI = () => request({
    url: '/businessList'
  });

/**
 * 휴일체크
 */
export const checkLocationHolidayAPI = ({ data }) => {
  return request({
    url: '/checkholiday',
    data
  });
};

/**
 * 휴일체크
 */
export const checkExtendHolidayAPI = ({ data }) => {
  return request({
    url: '/checkExtendholiday',
    data
  });
};
