import request from 'apis/request';
import { isRu } from '../lib/utils';

const prefix = '/charge';

/**
 * 사업자 목록 조회
 */
export const getBusinessesAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = field === 'createdAt' ? 1 : 2;

  return request({
    url: '/businessList',
    data
  });
};

function getChargeSortNum(filed) {
  switch (filed) {
    case 'name':
      return 1;
    case 'reserv_least_hour':
      return 2;
    case 'charge_hour':
      return 3;
    case 'week_day_charge':
      return 4;
    case 'week_end_charge':
      return 5;
    case 'peak_season_week_day_charge':
      return 6;
    case 'peak_season_week_end_charge':
      return 7;
    default:
      return 0;
  }
}
/**
 * 요금제 목록 조회
 */
export const getChargeListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field, order } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getChargeSortNum(field);

  return request({
    url: prefix,
    data
  });
};

/**
 * 요금제 등록
 */
export const createChargeAPI = ({ data }) =>
  request({
    url: prefix,
    method: 'post',
    data
  });

/**
 * 요금제 수정
 */
export const updateChargeAPI = ({ ID, data }) =>
  request({
    url: `${prefix}/${ID}`,
    method: 'patch',
    data
  });

/**
 * 요금제 삭제
 */
export const deleteChargeAPI = ({ ID }) =>
  request({
    method: 'delete',
    url: `${prefix}/${ID}`
  });

/**
 * 요금제 상세 조회
 */
export const getChargeDetailAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}`
  });

/**
 * 요금제 상세 차량 목록 조회
 */
export const getChargeDetailVehicleListAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}/vehicle`
  });

/**
 * 요금제 차량목록 조회
 */
export const getVehicleListAPI = ({ ID, branchID }) => {
  const url = isRu ? '/charge/vehicle2/lists' : '/charge/vehicle/lists';
  return request({
    url: `${url}`,
    data: fp.omitBy(fp.isNil, {
      businessID: ID,
      branchID
    })
  });
};

/**
 * 차량별 요금제 조회
 */
export const getVehicleChargeListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = field === 'createdAt' ? 1 : 2;
  // data.businessID = ID;

  const url = isRu ? '/charge/vehicle2/list' : '/charge/vehicle/list';
  return request({
    url: `${url}`,
    data
  });
};

/**
 * 시작 마일리지 거리 수정
 */
export const updateChargeVehicleMileageKmAPI = ({
  mileageChargeId,
  businessId,
  vehicleId,
  reservationId,
  mileageChargeInuseStartKm
}) => {
  return request({
    url: `${prefix}/vehicle/mileage/km/${mileageChargeId}`,
    method: 'patch',
    data: {
      businessId,
      vehicleId,
      reservationId,
      mileageChargeInuseStartKm
    }
  });
};

/**
 * 차량별 요금제 삭제
 */
export const deleteChargeVehicleAPI = ({ vehicleId }) => {
  return request({
    method: 'delete',
    url: `${prefix}/vehicle/${vehicleId}`
  });
};

/**
 * 요금제명 중복체크
 */
export const checkChargeNameDuplicateAPI = ({ businessID, name }) => {
  return request({
    url: `${prefix}/check`,
    data: {
      businessID,
      chargeName: name
    }
  });
};
