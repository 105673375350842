import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getVehicleFixedCostListAPI,
  createFixedCostListAPI,
  updateFixedCostAPI,
  deleteFixedCostListAPI,
  getVehicleHistoryListAPI
} from 'v2/apis/vehicleCostApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, confirmFetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';
import history from 'service/common/history';
import {
  getVehicleFixedCostList,
  getVehicleHistoryList,
  entityVehicleFixedCostList,
  updateFixedCost,
  createFixedCostList,
  deleteFixedCostList,
  toggleVehicleFixedCostModal,
  changeFixedCostState,
  entityVehicleHistoryList
} from '.';

/** ********************************************** */
/** constant */
// export const historyUrl = '/costitembydealer';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorVehicleCostPage = (state) => state.vehicleCost.list;
/** ********************************************** */

// 수정
export const entityUpdateFixedCost = createEntity({
  key: updateFixedCost().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
    yield put(toggleVehicleFixedCostModal({ openModal: true }));
  }
});

// 등록
export const entityCreateFixedCostList = createEntity({
  key: createFixedCostList().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) {
      FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    } else {
      FMSCommon.toast.fail('Common.Create.Fail');
    }
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(toggleVehicleFixedCostModal({ openModal: true }));
  }
});

// mulitple delete for list page.
export const entityDeleteFixedCostList = createEntity({
  key: deleteFixedCostList().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeFixedCostState({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorVehicleCostPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getVehicleFixedCostList(pageInfo, sortInfo, filters));
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorVehicleCostPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getVehicleFixedCostList(pageInfo, sortInfo, filters));
  }
});

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getFixedCostListAsync = createSagaAction.bind(
  null,
  entityVehicleFixedCostList,
  getVehicleFixedCostListAPI
);

const getHistoryListAsync = createSagaAction.bind(
  null,
  entityVehicleHistoryList,
  getVehicleHistoryListAPI
);

const updateFixedCostAsync = createSagaAction.bind(null, entityUpdateFixedCost, updateFixedCostAPI);
const createCreateFixedCostListAsync = createSagaAction.bind(
  null,
  entityCreateFixedCostList,
  createFixedCostListAPI
);
const deleteFixedCostListAsync = createSagaAction.bind(
  null,
  entityDeleteFixedCostList,
  deleteFixedCostListAPI
);
/**
 * Watcher
 */
export default function* vehicleCostWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    getVehicleFixedCostList().type,
    fetchSaga,
    getFixedCostListAsync,
    entityVehicleFixedCostList
  );
  yield takeLatest(
    getVehicleHistoryList().type,
    fetchSaga,
    getHistoryListAsync,
    entityVehicleHistoryList
  );

  yield takeLatest(
    updateFixedCost().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateFixedCostAsync,
    entityUpdateFixedCost
  );
  yield takeLatest(
    createFixedCostList().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createCreateFixedCostListAsync,
    entityCreateFixedCostList
  );

  // deletes in list page
  yield takeLatest(
    deleteFixedCostList().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteFixedCostListAsync,
    entityDeleteFixedCostList
  );
}
