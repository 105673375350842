import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { PAGE_LIMIT } from 'v2/constants';
import { produce } from 'immer';

export const { initializeMonitoringdataListPage, getMonitoringdataList } = createActions(
  {
    GET_MONITORINGDATA_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters })
  },
  'INITIALIZE_MONITORINGDATA_LIST_PAGE'
);

export const entityMonitoringdataList = createEntity({
  key: getMonitoringdataList().type,
  parseData: (action, dataField) => {
    const data = action && action.response && action.response.data && action.response.data;
    const fieldName = dataField || 'items';

    let resData = null;
    if (dataField === 'none') {
      resData = data;
    } else if (data && fieldName in data) {
      resData = data[fieldName];
    }

    const reqPager = action && action.params ? action.params : null;
    const resPageInfo = data ? data.pageInfo : null;
    // const items = data ? data.items : null;
    const pageInfo = { ...(reqPager ? reqPager.pageInfo : null), ...(resPageInfo || null) };
    if (resData === null) {
      resData = [];
    }

    // 삭제 예정, label, value는 사용하지 마세요.
    const updatedList =
      resData &&
      resData.list &&
      resData.list.map &&
      resData.list.map((item) => {
        item.label = item.name || item.managerName;
        item.value = item.ID;
        item.realContractType = item.contractType;
        return item;
      });

    const updatedExcelList =
      resData &&
      resData.excelList &&
      resData.excelList.map &&
      resData.excelList.map((item) => {
        item.label = item.name || item.managerName;
        item.value = item.ID;
        item.realContractType = item.contractType;
        return item;
      });

    return {
      data: { list: updatedList, excelList: updatedExcelList },
      pager: { ...reqPager, pageInfo }
    };
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const initializeState = {
  list: {
    data: {
      list: [],
      excelList: []
    },
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'for_orderby.created_at', order: 'desc' },
      filters: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
          0,
          0,
          0,
          0
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(
          23,
          59,
          59,
          999
        ),
        excelDownloadYn: 'Y',
        countryID: '_All',
        businessID: '_All',
        branchID: '_All'
      }
    }
  }
};

const listPageReducer = handleActions(
  {
    [initializeMonitoringdataListPage().type]: () => {
      // console.log(initializeState.list);
      return initializeState.list;
    }
  },
  {},
  { produce }
);

export const monitoringdata = combineReducers({
  list: createReducer({
    entity: entityMonitoringdataList,
    reducer: listPageReducer
  })
});
