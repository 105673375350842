import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { getNextStepMinute } from 'components/ui/dayPicker/DayPickerUtils';
import { getAddedDate } from 'service/utils/FMSDateUtil';
import { PAGE_LIMIT } from 'v2/constants/index';

const getInitialState = () => ({
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {
        startTime: getNextStepMinute(getAddedDate(new Date(), 15, 'minutes'), 10),
        endTime: getNextStepMinute(getAddedDate(new Date(), 195, 'minutes'), 10),
        keywordType: 'licensePlateNumber',
        keyword: null
      }
    }
  },
  detail: {
    data: {},
    loading: false,
    mode: 'edit'
  }
});

export const {
  initializeRentalSearchState,
  initializeRentalSearchDetailState,
  initializeRentalSearchVehicle,
  changeRentalSearchVehicle,
  changeRentalSearchPage,
  getRentalSearchList,
  getRentalSearchDetail,
  resetRentalSearchList
} = createActions(
  {
    GET_RENTAL_SEARCH_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_RENTAL_SEARCH_DETAIL: (ID) => ({ ID }),
    RESET_RENTAL_SEARCH_LIST: (nextState = getInitialState().list) => ({ nextState })
  },
  'INITIALIZE_RENTAL_SEARCH_STATE',
  'INITIALIZE_RENTAL_SEARCH_DETAIL_STATE',
  'CHANGE_RENTAL_SEARCH_PAGE',
  'INITIALIZE_RENTAL_SEARCH_VEHICLE',
  'CHANGE_RENTAL_SEARCH_VEHICLE'
);

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityRentalSearchList = createEntity({
  key: getRentalSearchList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityRentalSearchDetail = createEntity({
  key: getRentalSearchDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.

export const initializeState = getInitialState();

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.

// page action reducer 선언
export const rentalSearch = combineReducers({
  list: createReducer({
    entity: entityRentalSearchList,
    reducer: handleActions(
      {
        [initializeRentalSearchState().type]: () => initializeState.list,
        [resetRentalSearchList().type]: (state, { payload }) => ({ ...state, ...payload.nextState })
      },
      {},
      { produce }
    )
  }),
  detail: createReducer({
    entity: entityRentalSearchDetail,
    reducer: handleActions(
      {
        [initializeRentalSearchDetailState().type]: () => initializeState.detail,
        [changeRentalSearchPage().type]: (state, { payload }) => ({ ...state, ...payload })
      },
      {},
      { produce }
    )
  }),
  vehicleModel: handleActions(
    {
      [initializeRentalSearchVehicle().type]: () => initializeState.vehicleModel,
      [changeRentalSearchVehicle().type]: (state, { payload }) => ({ ...state, ...payload })
    },
    {},
    { produce }
  )
});
/** ********************************************** */
