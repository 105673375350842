import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FMSColors /* FMSFontSize */ } from 'service/common/designLib';
import NumberFormat from 'react-number-format';
// import * as _ from 'lodash';

const Container = styled.div`
  width: 100%;
  ${(props) => props.labelProps && 'display: flex; align-items: top;'}
`;

/*
아메바 : 
  display: flex; 추가 
  justify-content: flex-start; 추가
  align-items:center; 추가
  padding-right: 추가
  line-height: 24px -> 1.3 수정
*/
const Label = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => props.labelWidth}px;
  padding-right: ${(props) => (props.labelPadding ? props.labelPadding : '15px')};
  line-height: 1.3;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '13px')};
  color: ${(props) => (props.color ? props.color : '#787878')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ suffixContent }) => {
    if (suffixContent) {
      return css`
        position: relative;
      `;
    }
  }}

  // suffix
  :after {
    content: '${(props) => props.suffixContent || ''}';
    position: absolute;
    right: ${(props) => (props.icon ? '40px' : '10px')} ;
    top: 0px;
    line-height: 40px;
  }
`;

const iconStyle = (icon) =>
  icon && icon.type === 'onoff'
    ? `background-image: url(${process.env.PUBLIC_URL}/assets/images/${icon.name}-off.png);
      :focus {
        background-image: url(${process.env.PUBLIC_URL}/assets/images/${icon.name}-on.png);
      }`
    : icon
    ? `background-image : url(${icon});`
    : '';

const Input = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 1px;
  ${(props) =>
    props.underline
      ? `border: 0; border-bottom: 1px solid ${FMSColors.line};`
      : 'border: 1px solid #dbdee1;'}
  ${(props) =>
    props.underlineColor &&
    `border: 0; border-bottom: 1px solid ${props.underlineColor};`}
  background-color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  ${(props) =>
    props.iconProps &&
    `
    ${iconStyle(props.iconProps)}
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: 25px;
  `}
  ${(props) =>
    props.cursor &&
    `
    cursor: ${props.cursor};
  `}
  padding-left: 10px;
  padding-right: ${(props) => props.paddingRight || 10}px;
  transition: all 0.2s ease-in-out;
  color: ${(props) => (props.color ? props.color : FMSColors.grey)};
  :focus {
    border-color: ${FMSColors.primary};
    color: ${FMSColors.primary};
    outline: none;
  }
  :disabled {
    background-image: none;
    color: ${(props) => (props.color ? props.color : FMSColors.grey)};
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  width: 100%;
  height: 32px;
  border-radius: 1px;
  border: 1px solid #dbdee1;
  background-color: #ffffff;
  font-size: 13px;
  line-height: 22px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  color: #666666;
`;

const InputStyleDiv = styled.div`
  width: 100%;
  height: 32px;
  border-radius: 1px;
  ${(props) =>
    props.underline
      ? `border: 0; border-bottom: 1px solid ${FMSColors.line};`
      : 'border: 1px solid #dddddd;'}
  background-color: #ffffff;
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-left: 10px;
  padding-right: ${(props) => props.paddingRight || 10}px;
  transition: all 0.2s ease-in-out;
  color: ${(props) => (props.color ? props.color : FMSColors.grey)};
`;

const CheckLabel = styled.label`
  display: block;
  width: fit-content;
  position: relative;
  padding-left: 27px;
  & input {
    display: none;
  }
  & i.checkmark {
    position: absolute;
    left: 0;
    width: 19px;
    height: 19px;
    background: url('${process.env.PUBLIC_URL}/assets/images/checkbox.svg') no-repeat;
    background-size: contain;
  }
  & input:checked + i.checkmark {
    position: absolute;
    left: 0;
    width: 19px;
    height: 19px;
    background: url('${process.env.PUBLIC_URL}/assets/images/checkbox-on.svg') no-repeat;
    background-size: contain;

  }
`;

const FMSInput = forwardRef((props, ref) => {
  const {
    // intl,
    type = 'text',
    mode,
    label,
    style,
    className,
    classNameForInput = '',
    classNameForLabel = '',
    icon,
    options,
    required,
    error = [],
    validateInput,
    inputStyle,
    suffixContent,
    onClick,
    defaultChecked,
    placeholder,
    children,
    underlineColor,
    detailView = false,
    hidden = false,
    value,
    format,
    ...rest
  } = props;
  const intl = useIntl();
  const hasError = error.length > 0;
  const renderErrorMsg = () =>
    mode !== 'view' &&
    hasError && (
      <p className='error msg' style={{ marginTop: 0 }}>
        <FormattedMessage id={error[0].msg} values={error[0]} />
      </p>
    );
  const renderInput = (type, placeholderText) => {
    const advancedClass = `${classNameForInput}${mode !== 'view' && hasError ? ' error' : ''}`;
    switch (type) {
      case 'search': {
        return (
          <Input
            ref={ref}
            className={advancedClass}
            iconProps={`${process.env.PUBLIC_URL}/assets/images/search.png`}
            // onKeyUp={validateInput}
            paddingRight={37}
            placeholder={placeholderText}
            style={mode !== 'view' && hasError ? { ...inputStyle, marginBottom: 0 } : inputStyle}
            type='text'
            underlineColor={underlineColor}
            value={value}
            onClick={onClick}
            {...rest}
          />
        );
      }
      case 'calendar': {
        return (
          <Input
            ref={ref}
            readOnly
            // onKeyUp={validateInput}
            underline
            className={advancedClass}
            cursor='pointer'
            iconProps={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
            placeholder={placeholderText}
            style={mode !== 'view' && hasError ? { ...inputStyle, marginBottom: 0 } : inputStyle}
            type='text'
            underlineColor={underlineColor}
            value={value}
            onClick={onClick}
            {...rest}
          />
        );
      }
      case 'text': {
        const formatValue = format && value ? format(value) : value;
        return (
          <Input
            ref={ref}
            className={advancedClass}
            iconProps={icon}
            placeholder={!detailView ? placeholderText : ''}
            style={mode !== 'view' && hasError ? { ...inputStyle, marginBottom: 0 } : inputStyle}
            type='text'
            underlineColor={underlineColor}
            value={formatValue || ''}
            onClick={onClick}
            onKeyUp={validateInput}
            {...rest}
          />
        );
      }
      case 'textZero': {
        const formatValue = format && value ? format(value) : value;
        return (
          <Input
            ref={ref}
            className={advancedClass}
            iconProps={icon}
            placeholder={!detailView ? placeholderText : ''}
            style={mode !== 'view' && hasError ? { ...inputStyle, marginBottom: 0 } : inputStyle}
            type='text'
            underlineColor={underlineColor}
            value={formatValue}
            onClick={onClick}
            onKeyUp={validateInput}
            {...rest}
          />
        );
      }
      case 'password': {
        return (
          <Input
            ref={ref}
            className={advancedClass}
            iconProps={icon}
            placeholder={!detailView ? placeholderText : ''}
            style={mode !== 'view' && hasError ? { ...inputStyle, marginBottom: 0 } : inputStyle}
            type='password'
            value={value}
            onClick={onClick}
            onKeyUp={validateInput}
            {...rest}
          />
        );
      }
      case 'number': {
        return (
          <Input
            ref={ref}
            className={advancedClass}
            iconProps={icon}
            placeholder={placeholderText}
            style={mode !== 'view' && hasError ? { ...inputStyle, marginBottom: 0 } : inputStyle}
            type='number'
            underlineColor={underlineColor}
            value={value}
            onClick={onClick}
            onKeyUp={validateInput}
            {...rest}
          />
        );
      }
      case 'email': {
        return (
          <Input
            ref={ref}
            className={advancedClass}
            placeholder={placeholderText}
            style={mode !== 'view' && hasError ? { ...inputStyle, marginBottom: 0 } : inputStyle}
            type='email'
            underlineColor={underlineColor}
            value={value}
            onKeyUp={validateInput}
            {...rest}
          />
        );
      }
      case 'checkbox': {
        return (
          <CheckLabel>
            <FormattedMessage id={label.text} />
            <input
              ref={ref}
              defaultChecked
              id={rest.id}
              name={rest.name}
              type='checkbox'
              onClick={onClick}
            />
            <i className='checkmark' />
          </CheckLabel>
        );
      }
      case 'custom-disabled': {
        return <InputStyleDiv underline>{children}</InputStyleDiv>;
      }
      case 'numberFormat': {
        const language = window.currentCountryCd;
        // const thousandSeparator = language === 'KO' || language === 'EN' ? ',' : '.';
        const decimalSeparator = ',';
        let thousandSeparator = '.';
        if (language === 'RU') {
          thousandSeparator = ' ';
        }
        return (
          <NumberFormat
            // ref={ref}
            getInputRef={ref}
            allowedDecimalSeparators={['.', ',']}
            className={advancedClass}
            customInput={Input}
            decimalSeparator={decimalSeparator}
            iconProps={icon}
            placeholder={placeholderText}
            style={mode !== 'view' && hasError ? { ...inputStyle, marginBottom: 0 } : inputStyle}
            thousandSeparator={thousandSeparator}
            underlineColor={underlineColor}
            value={value}
            onClick={onClick}
            onKeyUp={validateInput}
            {...rest}
            // onValueChange={(val) => {
            //   rest.onChange({ target: { name: rest.name, value: val.value } });
            // }}
            // inputProps={...rest}
          />
        );
      }
      default: {
        return (
          <Input
            ref={ref}
            className={advancedClass}
            iconProps={icon}
            placeholder={placeholderText}
            style={mode !== 'view' && hasError ? { ...inputStyle, marginBottom: 0 } : inputStyle}
            type='text'
            underlineColor={underlineColor}
            value={value}
            onKeyUp={validateInput}
            {...rest}
          />
        );
      }
    }
  };

  return hidden ? null : (
    <Container
      className={className}
      labelProps={label}
      style={
        mode !== 'view' && hasError
          ? { ...style, ...(type === 'hidden' && { display: 'none' }), marginBottom: '10px' }
          : { ...style, ...(type === 'hidden' && { display: 'none' }) }
      }
    >
      {label && type !== 'checkbox' && (
        <Label
          className={classNameForLabel}
          color={label.color}
          fontSize={label.fontSize}
          labelWidth={label.width}
        >
          {label.text && <FormattedMessage id={label.text} values={label.values} />}
          {required && '*'}
        </Label>
      )}
      <InputContainer
        icon={!rest.disabled && !rest.readOnly && ['text', 'number'].includes(type)}
        style={{
          width: `calc(100% - ${label && type !== 'checkbox' && label.width ? label.width : 0}px)`
        }}
        suffixContent={suffixContent ? intl.formatMessage({ id: suffixContent }) : null}
      >
        {placeholder ? (
          <FormattedMessage id={placeholder}>
            {(placeholderText) => renderInput(type, placeholderText)}
          </FormattedMessage>
        ) : (
          renderInput(type, '')
        )}
        {renderErrorMsg()}
      </InputContainer>
    </Container>
  );
});

FMSInput.defaultProps = {
  styles: {},
  options: []
};

export default React.memo(
  FMSInput,
  // FMSInput 리렌더링 방지
  (prevProps, nextProps) => {
    return (
      typeof nextProps.name !== 'undefined' &&
      customReplacer(prevProps) === customReplacer(nextProps)
      // _.isEqual(prevProps.mode, nextProps.mode) &&
      // _.isEqual(prevProps.value, nextProps.value) &&
      // _.isEqual(prevProps.error, nextProps.error) &&
      // _.isEqual(prevProps.required, nextProps.required)
    );
  }
);

const customReplacer = (obj) => {
  let cache = [];
  const result = JSON.stringify(obj, (key, value) => {
    // 중복 참조(object reference)가 있을 때, 순환 참조 방지용.
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        return;
      }
      cache.push(value);
    }
    return value;
  });
  cache = null;
  return result;
};
