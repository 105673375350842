import { put, takeLatest } from 'redux-saga/effects';

import {
  getAllMenuListAPI,
  getMenuListAPI, // 전체조회
  updateMenuAPI,
  deleteMenuAPI,
  createMenuAPI,
  getMenuAPI
} from 'v2/apis/menuApi';

import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import {
  loadAllMenu,
  changeMenuState,
  getMenuList,
  createMenu,
  updateMenu,
  deleteMenu,
  entityLoadAllMenuList,
  entityMenuList,
  getMenu,
  entityMenuDetail
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/menu';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorMenuPage = (state) => state.menu.list;

// function* changeMenuPageSaga() {
//   yield put(changeMenuState({ mode: 'none' }));
// }

// function* changeMenuPageUpdateSaga() {
//   yield put(changeMenuState({ mode: 'view' }));
// }

/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateMenu = createEntity({
  key: createMenu().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
  }
});

// update for update page
export const entityUpdateMenu = createEntity({
  key: updateMenu().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
  }
});

// // delete for detail page
export const entityDeleteMenu = createEntity({
  key: deleteMenu().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn(payload) {
    if (payload.code && payload.code !== 0 && payload.code !== 2 && payload.code !== 3) {
      FMSCommon.toast.success(getErrorMessage(payload.code));
    } else {
      FMSCommon.toast.success('Common.Deleted.Success');
      yield put(changeMenuState({ selectedNode: null, mode: 'none' }));
    }
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createMenuAsync = createSagaAction.bind(null, entityCreateMenu, createMenuAPI);
const updateMenuAsync = createSagaAction.bind(null, entityUpdateMenu, updateMenuAPI);
const deleteMenuAsync = createSagaAction.bind(null, entityDeleteMenu, deleteMenuAPI);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getMenuListAsync = createSagaAction.bind(null, entityMenuList, getMenuListAPI);
const getMenuAsync = createSagaAction.bind(null, entityMenuDetail, getMenuAPI);
const loadAllMenuListAsync = createSagaAction.bind(null, entityLoadAllMenuList, getAllMenuListAPI);

function* changeMenuSaga() {
  yield put(changeMenuState({ data: null, loading: true }));
  yield put(getMenuList());
}

/**
 * Watchersa
 */
export default function* menuWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createMenu().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createMenuAsync,
    entityCreateMenu
  );
  yield takeLatest(
    updateMenu().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateMenuAsync,
    entityUpdateMenu
  );
  yield takeLatest(
    deleteMenu().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteMenuAsync,
    entityDeleteMenu
  );
  yield takeLatest(getMenuList().type, fetchSaga, getMenuListAsync, entityMenuList);
  yield takeLatest(getMenu().type, fetchSaga, getMenuAsync, entityMenuDetail);

  // select business
  yield takeLatest(loadAllMenu().type, fetchSaga, loadAllMenuListAsync, entityLoadAllMenuList);

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityCreateMenu.types[1], changeMenuSaga);
  yield takeLatest(entityUpdateMenu.types[1], changeMenuSaga);
  yield takeLatest(entityDeleteMenu.types[1], changeMenuSaga);
}
