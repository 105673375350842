import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getBusinessesAPI,
  getAllBusinessesAPI,
  getBusinessAPI,
  createBusinessAPI,
  updateBusinessAPI,
  deleteBusinessAPI
} from 'v2/apis/businessApi';

import {
  createSagaAction,
  confirmFetchSaga,
  fetchSaga,
  fetchSagas,
  confirmFetchSagas
} from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';

import history from 'service/common/history';
import { getErrorMessage } from 'constants/errors';
import { createBusinessLicense } from '../businessLicense';

import {
  createBusiness,
  updateBusiness,
  deleteBusiness,
  getBusiness,
  changeBusinessPage,
  entityBusiness,
  deleteForBusinesses,
  getBusinesses,
  entityBusinesses,
  loadAllBusinesses,
  entityLoadAllBusinesses
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/business';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorBusinessesPage = (state) => state.business.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateBusiness = createEntity({
  key: createBusiness().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Common.Created.Success');
    // license 등록
    const { ID } = payload;
    const licenseID =
      requestParams && requestParams.data && requestParams.data.licenseID
        ? requestParams.data.licenseID
        : null;
    if (ID && licenseID) yield put(createBusinessLicense({ businessID: ID, licenseID }));

    // 사업자 상세 페이지 이동 및 상단에 위치한 사업자 셀렉트박스 정보 재조회
    const selectorListPage = yield select(selectorBusinessesPage);
    const { pager } = selectorListPage;
    yield put(loadAllBusinesses({ countryID: pager.filters.countryID }));

    if (ID) history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    if (ID) yield put(getBusiness(ID));
  }
});

// update for update page
export const entityUpdateBusiness = createEntity({
  key: updateBusiness().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Common.Updated.Success');
    // license 등록
    const { ID } = requestParams || null;
    const licenseID =
      requestParams && requestParams.data && requestParams.data.licenseID
        ? requestParams.data.licenseID
        : null;
    if (ID && licenseID) yield put(createBusinessLicense({ businessID: ID, licenseID }));

    if (ID) yield put(getBusiness(ID));
    if (ID) yield put(changeBusinessPage({ mode: 'view' }));

    const selectorListPage = yield select(selectorBusinessesPage);
    const {
      pager: { filters }
    } = selectorListPage;
    yield put(loadAllBusinesses(fp.set('countryID', fp.get('data.countryID', requestParams), {})));
  }
});
// delete for detail page
export const entityDeleteBusiness = createEntity({
  key: deleteBusiness().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorBusinessesPage);
    const { pager } = selectorListPage;
    yield put(loadAllBusinesses({ countryID: pager.filters.countryID }));
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForBusinesses = createEntity({
  key: deleteForBusinesses().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeBusinessPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorBusinessesPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(loadAllBusinesses({ countryID: filters.countryID }));
    yield put(getBusinesses(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorBusinessesPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getBusinesses(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createBusinessAsync = createSagaAction.bind(null, entityCreateBusiness, createBusinessAPI);
const updateBusinessAsync = createSagaAction.bind(null, entityUpdateBusiness, updateBusinessAPI);
const deleteBusinessAsync = createSagaAction.bind(null, entityDeleteBusiness, deleteBusinessAPI);
const deleteForBusinessesAsync = createSagaAction.bind(
  null,
  entityDeleteForBusinesses,
  deleteBusinessAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getBusinessesAsync = createSagaAction.bind(null, entityBusinesses, getBusinessesAPI);
const getBusinessAsync = createSagaAction.bind(null, entityBusiness, getBusinessAPI);
const loadAllBusinessesAsync = createSagaAction.bind(
  null,
  entityLoadAllBusinesses,
  getAllBusinessesAPI
);

function* changeBusinessPageSaga() {
  yield put(changeBusinessPage({ mode: 'view' }));
}
function* loadAllSaga() {
  yield put(loadAllBusinesses());
}

/**
 * Watcher
 */
export default function* businessWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createBusiness().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createBusinessAsync,
    entityCreateBusiness
  );
  yield takeLatest(
    updateBusiness().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateBusinessAsync,
    entityUpdateBusiness
  );
  yield takeLatest(
    deleteBusiness().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteBusinessAsync,
    entityDeleteBusiness
  );
  yield takeLatest(getBusinesses().type, fetchSaga, getBusinessesAsync, entityBusinesses);
  yield takeLatest(getBusiness().type, fetchSaga, getBusinessAsync, entityBusiness);

  // deletes in list page
  yield takeLatest(
    deleteForBusinesses().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteForBusinessesAsync,
    entityDeleteForBusinesses
  );

  // select business
  yield takeLatest(
    loadAllBusinesses().type,
    fetchSaga,
    loadAllBusinessesAsync,
    entityLoadAllBusinesses
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  // yield takeLatest(entityUpdateBusiness.types[1], changeBusinessPageSaga);
  // yield takeLatest(entityUpdateBusiness.types[1], loadAllSaga);
  // yield takeLatest(entityCreateBusiness.types[1], loadAllSaga);
  // yield takeLatest(entityDeleteBusiness.types[1], loadAllSaga);
}
