import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import FMSLoader from 'components/ui/common/FMSLoader';
import anonymousRoutes from 'v2/constants/anonymousRoutes';

const renderType = () => {
  const renderResult = (
    <Switch>
      {anonymousRoutes.map((menu) => (
        <Route
          key={menu.path}
          aclAuth={menu.aclAuth}
          component={menu.component}
          exact={menu.exact}
          path={menu.path}
        />
      ))}
    </Switch>
  );
  return <Suspense fallback={<FMSLoader loading />}>{renderResult}</Suspense>;
};

const HomeAnonymousPage = () => renderType();
export default React.memo(HomeAnonymousPage);
