import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getBusinessMembersAPI,
  getBusinessMemberAPI,
  confirmBusinessMemberAPI,
  denyBusinessMemberAPI,
  updateBusinessMemberAPI,
  deleteBusinessMemberAPI
} from 'v2/apis/businessMemberApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  updateBusinessMember,
  deleteBusinessMember,
  getBusinessMember,
  changeBusinessMemberPage,
  entityBusinessMember,
  deleteForBusinessMembers,
  getBusinessMembers,
  entityBusinessMembers,
  denyBusinessMember,
  confirmBusinessMember
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/business/member';

const historySelfUrl = '/businessMember';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorBusinessMembersPage = (state) => state.businessMember.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// update for update page
export const entityUpdateBusinessMember = createEntity({
  key: updateBusinessMember().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  afterSuccessFn(payload) {
    // const { ID } = payload;
    // 사업자 직원관리 권한 업데이트 후 재조회시 오류 수정
    // yield put(getBusinessMember(ID));

    FMSCommon.toast.success('Common.Updated.Success');
    history.replace({ pathname: `${historySelfUrl}` });
  }
});
// confirm for confirm page
export const entityConfirmBusinessMember = createEntity({
  key: confirmBusinessMember().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Member.Approve.Fail');
  },
  *afterSuccessFn(payload) {
    const { ID } = payload;
    yield put(getBusinessMember(ID));
    FMSCommon.toast.success('Member.Approved.Success');
  }
});
// confirm for confirm page
export const entityDenyBusinessMember = createEntity({
  key: denyBusinessMember().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Member.Denied.Fail');
  },
  *afterSuccessFn(payload) {
    const { ID } = payload;
    yield put(getBusinessMember(ID));
    FMSCommon.toast.success('Member.Denied.Success');
  }
});
// delete for detail page
export const entityDeleteBusinessMember = createEntity({
  key: deleteBusinessMember().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorBusinessMembersPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historySelfUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForBusinessMembers = createEntity({
  key: deleteForBusinessMembers().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeBusinessMemberPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorBusinessMembersPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getBusinessMembers(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorBusinessMembersPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getBusinessMembers(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const updateBusinessMemberAsync = createSagaAction.bind(
  null,
  entityUpdateBusinessMember,
  updateBusinessMemberAPI
);
const confirmBusinessMemberAsync = createSagaAction.bind(
  null,
  entityConfirmBusinessMember,
  confirmBusinessMemberAPI
);
const denyBusinessMemberAsync = createSagaAction.bind(
  null,
  entityDenyBusinessMember,
  denyBusinessMemberAPI
);
const deleteBusinessMemberAsync = createSagaAction.bind(
  null,
  entityDeleteBusinessMember,
  deleteBusinessMemberAPI
);
const deleteForBusinessMembersAsync = createSagaAction.bind(
  null,
  entityDeleteForBusinessMembers,
  deleteBusinessMemberAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getBusinessMembersAsync = createSagaAction.bind(
  null,
  entityBusinessMembers,
  getBusinessMembersAPI
);
const getBusinessMemberAsync = createSagaAction.bind(
  null,
  entityBusinessMember,
  getBusinessMemberAPI
);

function* changeBusinessMemberPageSaga() {
  yield put(changeBusinessMemberPage({ mode: 'view', aclMode: 'view' }));
}

/**
 * Watcher
 */
export default function* memberWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    confirmBusinessMember().type,
    confirmFetchSaga,
    'Common.Confirm.Approve',
    confirmBusinessMemberAsync,
    entityConfirmBusinessMember
  );
  yield takeLatest(
    denyBusinessMember().type,
    confirmFetchSaga,
    'Common.Confirm.Deny',
    denyBusinessMemberAsync,
    entityDenyBusinessMember
  );
  yield takeLatest(
    updateBusinessMember().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateBusinessMemberAsync,
    entityUpdateBusinessMember
  );
  yield takeLatest(
    deleteBusinessMember().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteBusinessMemberAsync,
    entityDeleteBusinessMember
  );
  yield takeLatest(
    getBusinessMembers().type,
    fetchSaga,
    getBusinessMembersAsync,
    entityBusinessMembers
  );
  yield takeLatest(
    getBusinessMember().type,
    fetchSaga,
    getBusinessMemberAsync,
    entityBusinessMember
  );

  // deletes in list page
  yield takeLatest(
    deleteForBusinessMembers().type,
    fetchSagas,
    null,
    deleteForBusinessMembersAsync,
    entityDeleteForBusinessMembers
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityUpdateBusinessMember.types[1], changeBusinessMemberPageSaga);
}
