import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getAppVersionListAPI,
  deleteAppVersionAPI,
  createAppVersionAPI
} from 'v2/apis/appVersionApi';

import { confirmFetchSaga, confirmFetchSagas, createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import { deleteAppVersionList, getAppVersionList, entityAppVersionList, createAppVersion } from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/appVersion';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorAppVersionListPage = (state) => state.appVersion.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateAppVersion = createEntity({
  key: createAppVersion().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else if (payload && payload.data && payload.data.errorCode)
      FMSCommon.toast.fail(getErrorMessage(payload.data.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    history.replace({ pathname: `${historyUrl}` });
  }
});

// mulitple delete for list page.
export const entityDeleteAppVersionList = createEntity({
  key: deleteAppVersionList().type,
  beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (!isContinue) FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorAppVersionListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getAppVersionList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorAppVersionListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getAppVersionList(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createAppVersionAsync = createSagaAction.bind(
  null,
  entityCreateAppVersion,
  createAppVersionAPI
);
const getAppVersionListAsync = createSagaAction.bind(
  null,
  entityAppVersionList,
  getAppVersionListAPI
);
const deleteAppVersionListAsync = createSagaAction.bind(
  null,
  entityDeleteAppVersionList,
  deleteAppVersionAPI
);

/**
 * Watcher
 */
export default function* appVersionWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createAppVersion().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createAppVersionAsync,
    entityCreateAppVersion
  );
  yield takeLatest(
    getAppVersionList().type,
    fetchSaga,
    getAppVersionListAsync,
    entityAppVersionList
  );

  yield takeLatest(
    deleteAppVersionList().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteAppVersionListAsync,
    entityDeleteAppVersionList
  );
}
