import { takeLatest } from 'redux-saga/effects';
import { createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { getDealerSupportListAPI } from 'v2/apis/dealerSupportApi';
import { getDealerSupportList, entityDealerSupportList } from '.';

const getDealerSupportListAsync = createSagaAction.bind(
  null,
  entityDealerSupportList,
  getDealerSupportListAPI
);

export default function* dealerSupportWatcher() {
  yield takeLatest(
    getDealerSupportList().type,
    fetchSaga,
    getDealerSupportListAsync,
    entityDealerSupportList
  );
}
