export const BaseColors = {
  black: '#000000',
  brownGrey: '#999999',
  grey: '#666666',
  greyLight: '#eaeaea',
  greyDark: '#333333',
  greyBrown: '#999999',
  red: '#EA0029',
  blue: '#558fff',
  turquoise: '#00bcba',
  white: '#ffffff',
  whiteSmoke: '#f7f7f7',
  yellow: '#ffaf00',
  purple: '#7e5fd4',
  redFms: '#EA0029',
  pantoneFms: '#05141F'
};

export const FMSColors = {
  black: BaseColors.black,
  brownGrey: BaseColors.brownGrey,
  greyLight: BaseColors.greyLight,
  greyDark: BaseColors.greyDark,
  grey: BaseColors.grey,
  red: BaseColors.red,
  blue: BaseColors.blue,
  turquoise: BaseColors.turquoise,
  white: BaseColors.white,
  whiteSmoke: BaseColors.whiteSmoke,
  yellow: BaseColors.yellow,
  purple: BaseColors.purple,
  primary: BaseColors.pantoneFms,
  primaryHover: '#05141F',
  secondary: '#fbfbfb',
  secondaryHover: '#eee',
  succFMSs: '#28a745',
  info: '#17a2b8',
  warning: '#ffc107',
  danger: '#f75454',
  light: '#f8f9fa',
  dark: '#343a40',
  bgColor: '#f8f9fa',
  main: '#17a2b8',
  text: BaseColors.greyDark,
  border: BaseColors.greyLight,
  line: '#dddddd',
  bluegrey: '#757b8c'
};

export const FMSSize = {
  loader: '6px',
  loaderSize: '71px',
  navigation: '48px'
};

export const FMSFontSize = {
  body: '16.5px',
  dFMScription: '13px',
  label: '14.5px',
  nav: '17px',
  button: '18px',
  checkButton: '13px',
  radioButton: '13px',
  pageButton: '16px',
  price: '40px',
  FMSmessage: '16px',
  popupFMSmesage: '18px',
  copyright: '14px',
  paging: '14px',
  comment: '15px',
  title: '20px',
  inputLabel: '16px'
};

export const FMSIconSize = {
  navIcon: '22px',
  buttonIcon: '24px',
  logo: '45vh',
  logoSmall: '30vh',
  logoLogin: '20vh'
};

export const FMSDeviceWidth = {
  small: '1040px',
  medium: '1680px' // 아메바 : 수정
};

export const FMSTrimming = {
  lineHeight: '1.5'
};
