import { takeLatest } from 'redux-saga/effects';

import { getVehicleRetiredListAPI, recoverVehiclesAPI } from 'v2/apis/vehicleRetiredApi';
import { confirmFetchSagas, createSagaAction, fetchSaga } from 'v2/redux/sagas';
import {
  entityRecoverVehicles,
  entityVehicleRetiredList,
  getVehicleRetiredList,
  recoverVehicles
} from '.';

// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getVehicleRetiredListAsync = createSagaAction.bind(
  null,
  entityVehicleRetiredList,
  getVehicleRetiredListAPI
);

const recoverVehiclesAsync = createSagaAction.bind(null, entityRecoverVehicles, recoverVehiclesAPI);

export default function* vehicleRetiredWatcher() {
  yield takeLatest(
    getVehicleRetiredList().type,
    fetchSaga,
    getVehicleRetiredListAsync,
    entityVehicleRetiredList
  );
  yield takeLatest(
    recoverVehicles().type,
    confirmFetchSagas,
    'VehicleRetired.Recover.Check',
    null,
    recoverVehiclesAsync,
    entityRecoverVehicles
  );
}
