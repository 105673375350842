import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeNoticeListPage,
  getNoticeList,
  deleteNoticeList,
  initializeNoticeDetailPage,
  getNoticeDetail,
  createNotice,
  updateNotice,
  deleteNotice,
  changeNoticeDetailPage
} = createActions(
  {
    GET_NOTICE_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_NOTICE_DETAIL: (ID) => ({ ID }),
    CREATE_NOTICE: (data) => ({ data }),
    UPDATE_NOTICE: (ID, data) => ({ ID, data }),
    DELETE_NOTICE: (ID) => ({ ID })
  },
  'INITIALIZE_NOTICE_LIST_PAGE',
  'INITIALIZE_NOTICE_DETAIL_PAGE',
  'DELETE_NOTICE_LIST',
  'CHANGE_NOTICE_DETAIL_PAGE'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityNoticeList = createEntity({
  key: getNoticeList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});
export const entityNoticeDetail = createEntity({
  key: getNoticeDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});
/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {
        startDate: new Date().setHours(0, 0, 0, 0) - 30 * 24 * 60 * 60 * 1000, //현재날짜 - 30일 전
        endDate: new Date().setHours(23, 59, 59, 999), //현재날짜
      }
    }
  },
  detail: {
    data: {},
    loading: false,
    mode: 'create'
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const noticeListPageReducer = handleActions(
  {
    [initializeNoticeListPage().type]: () => initializeState.list
  },
  {},
  { produce }
);
const noticeDetailPageReducer = handleActions(
  {
    [initializeNoticeDetailPage().type]: () => initializeState.detail,
    [changeNoticeDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const notice = combineReducers({
  list: createReducer({
    entity: entityNoticeList,
    reducer: noticeListPageReducer
  }),
  detail: createReducer({
    entity: entityNoticeDetail,
    reducer: noticeDetailPageReducer
  })
});
/** ********************************************** */
