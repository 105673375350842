import request from 'apis/request';
import axios from 'axios';

const prefix = '/reservation';

function getReservationSortNum(filed) {
  switch (filed) {
    case 'reservationKey':
      return 1;
    case 'vehicle.licensePlateNumber':
      return 3;
    case 'endTime':
      return 3;
    case 'reservationDays':
      return 4;
    case 'user.name':
      return 9;
    case 'user.mobileNumber':
      return 11;
    case 'startTime':
      return 5;
    default:
      return 0;
  }
}
export const getAllReservationListAPI = ({ filters, sortInfo, pageInfo }) => {
  /* filters */
  const { status, keywordType, keyword, reservationClass, ...data } = filters;
  if (keywordType) {
    data[keywordType] = keyword;
  }
  if (reservationClass) {
    data.reservationClasses = reservationClass;
  }
  if (status) {
    data.status = status;
  } else {
    data.status = '1,2,4,8';
  }
  // 1. 승인대기(제휴법인) 2. 예약확정&승인완료 4. 예약취소 8.예약거부(승인거절)
  // data.reservationTag = 'NORMAL';

  const { page, limit } = pageInfo;
  data.page = page;
  data.rowPerPage = limit;

  /* sortInfo */
  const { field, order } = sortInfo;
  data.sortField = getReservationSortNum(field);
  data.sortOrder = order === 'desc' ? 1 : 0;

  return request({
    url: prefix,
    data,
    config: {
      timeout: 60 * 1000
    }
  });
};

export const getReservationDetailAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}`
  });

export const cancelReservationAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}/cancel`,
    method: 'patch'
  });

export const rejectReservationAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}/cancel`,
    method: 'patch',
    data: {
      action: 'REJECT'
    }
  });

export const approveReservationAPI = ({ ID }) =>
  request({
    url: `/payment/${ID}/pg/dealerpermit`,
    method: 'post'
  });

export const rentalRegisterAPI = ({ data }) =>
  request({
    url: `${prefix}`,
    method: 'post',
    data
  });

export const updateReservationAPI = ({ data }) => {
  console.log('updateReservationAPI data:', data);
  return request({
    url: `${prefix}/${data.reservationID}`,
    method: 'patch',
    data
  });
};

export const updateNewReservationAPI = ({ data }) => {
  console.log('updateNewReservationAPI data:', data);
  return request({
    url: `${prefix}/${data.reservationID}/new`,
    method: 'patch',
    data
  });
};

// 예약값을 가지고 대여등록 할때
export const inuseUpdateReservationAPI = ({ data }) => {
  return request({
    url: `${prefix}/${data.reservationID}/inuse`,
    method: 'patch',
    data
  });
};

// 강제 반납 Api추가
export const forceReturnUpdateReservationAPI = ({ data }) => {
  return request({
    url: `${prefix}/${data.reservationID}/forcereturn`,
    method: 'patch',
    data
  });
};

// 장기대여 중도 해지
export const enterContractCancleAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}/becancel`,
    method: 'patch',
    data
  });
};

export const requestTerminalReservationAPI = ({ ID }) => {
  return request({
    method: 'post',
    url: `/control${prefix}/${ID}`,
    config: {
      timeout: 35000
    }
  });
};
export const requestReturnTerminalReservationAPI = ({ ID }) => {
  return request({
    method: 'post',
    url: `/control${prefix}/return/${ID}`,
    config: {
      timeout: 35000
    }
  });
};

// 카카오톡으로 알림 전송
export const sendKaKaoTalkAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}/bill`,
    data
  });
};

// 비밀번호 화면에서 business 조회
export const getBusinessKaKaoTalkAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}/billData`
  });
};

// 카카오톡 화면에서 비밀번호 확인하기
export const checkPasswordKaKaoTalkAPI = (ID, inputParam) => {
  const { birthDate, reportType } = inputParam;
  return axios
    .post(
      `${process.env.FMS_CORE_API_ENDPOINT}/fms/bill/${ID}`,
      {
        birthDate,
        reportType
      },
      { responseType: 'blob' }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
};
