// eslint-disable-next-line import/no-cycle
import { initializeState as locationInitState } from '.';

const parseParameter = (param) => {
  const data = param.vehicles;
  const vehicleCountList = [];

  if (data)
    data.forEach((item) => {
      if (!vehicleCountList[item.VehicleStatus.vehicleStatus])
        vehicleCountList[item.VehicleStatus.vehicleStatus] = [];
      vehicleCountList[item.VehicleStatus.vehicleStatus].push(item);
    });

  const keys = Object.keys(vehicleCountList);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    vehicleCountList[key] = vehicleCountList[key].length;
  }
  // Object.keys(vehicleCountList).map(
  //   key => (vehicleCountList[key] = vehicleCountList[key].length),
  // );

  return vehicleCountList;
};

export function vehicleParseDataForList(action) {
  const data = action && action.response && action.response.data ? action.response.data : [];
  const count = parseParameter(data);
  return { vehicleData: data, count: { ...locationInitState.vehicleList.count, ...count } };
}
