import { combineReducers } from 'redux';
import { select, put } from 'redux-saga/effects';
import { createActions, handleActions } from 'redux-actions';
import { createEntityNew, createReducerNew } from 'v2/redux/lib/reducerLibNew';
import {
  updateAddressInfoAPI,
  updateInvoicingInfoAPI,
  deleteInvoicingInfoAPI,
  getDamageSpecificationListAPI,
  registerDamageSpecificationImageGroupAPI,
  createDamageSpecificationAPI,
  deleteDamageSpecificationAPI,
  sendInvoiceEmailAPI,
  updateCompanyAddressInfoAPI,
  updateCompanyRentalInfoAPI
} from 'v2/apis/reservationApi';
import { updateReservationAPI, inuseUpdateReservationAPI } from 'v2/apis/reservationSearchApi';
import { produce } from 'immer';
import FileSaver from 'file-saver';
import dateFnsFormat from 'date-fns/format';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import history from 'service/common/history';

import { getErrorMessage } from 'constants/errors';
import { changeHomeMenu } from 'v2/redux/modules/homeMenu';
import { PAGE_LIMIT } from 'v2/constants/index';
import { setMenuActive } from '../menu';
import moment from 'moment/moment';
import React from 'react';
import { getReservationDetail } from '../reservationSearch';
import { selectorReservationSearchDetailPage } from '../reservationSearch/reservationSearchSaga';

export const historyUrl = '/reservation';
export const testCarUrl = '/testCarReservation';
export const selectorReservationPage = (state) => state.reservation.reservationList;
const getInitialReservationList = () => ({
  data: [],
  loading: false,
  pager: {
    filters: {
      reservationClass: null,
      startDate: new Date().setHours(0, 0, 0, 0),
      endDate: new Date().setHours(23, 59, 59, 999) + 30 * 24 * 60 * 60 * 1000,
      status: null,
      keywordType: 'licensePlateNumber',
      keyword: null
    },
    pageInfo: {
      page: 1,
      limit: PAGE_LIMIT
    },
    sortInfo: {
      field: 'creatAt',
      order: 'desc'
    }
  }
});
export const {
  getReservationLocationList,
  getVehicleAvailableList,
  getInsuranceChargeList,
  getInsuranceCost,
  getAgentList,
  getUserList,
  getCompanyUserList,
  checkLicense,
  postReservation,
  getEstimate,
  deleteEstimate,
  createEstimate,
  changeEstimate,
  getRentList,
  downloadCsv,
  extendRental,
  returnVehicle,
  forceReturnVehicle,
  postRentalReservation,
  changeInsuranceInfomation,
  initializeInsuranceChargeList,
  initializeReservationUseImgList,
  getReservationUseImgList,
  initializeRentList,
  initializeProgress,
  changeProgress,
  deletesRentalStatus,
  updateAddressInfo,
  updateCompanyAddressInfo,
  updateRentalCompanyInfo,
  updateInvoicingInfo,
  deleteInvoicingInfo,
  sendInvoiceEmail,
  getDamageSpecificationList,
  registerDamageSpecificationImageGroup,
  createDamageSpecification,
  deleteDamageSpecification,
  updateReservationNoConfirm,
  inuseUpdateReservation,
  resetReservationList
} = createActions(
  {
    GET_INSURANCE_CHARGE_LIST: (filters) => ({ filters }),
    GET_INSURANCE_COST: (filters) => ({ filters }),
    GET_RENT_LIST: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    }),
    GET_ESTIMATE: (data) => ({ data }),
    CREATE_ESTIMATE: (data) => ({ data }),
    EXTEND_RENTAL: (ID, data) => ({ ID, data }),
    RETURN_VEHICLE: (ID, data) => ({ ID, data }),
    FORCE_RETURN_VEHICLE: (ID, data) => ({ ID, data }),
    DELETE_ESTIMATE: (IDArr) => ({ IDArr }),
    GET_RESERVATION_USE_IMG_LIST: (ID) => ({ ID }),
    DELETES_RENTAL_STATUS: (IDArr) => {
      return { IDArr };
    },
    UPDATE_ADDRESS_INFO: (userID, address) => ({ userID, address }),
    UPDATE_COMPANY_ADDRESS_INFO: (companyID, address) => ({ companyID, address }),
    UPDATE_RENTAL_COMPANY_INFO: (data) => ({ data }),
    UPDATE_INVOICING_INFO: (
      invoicingInfo,
      invoicingInfoEng,
      businessId,
      branchId,
      reservationId,
      isInvoiceClearYn
    ) => ({
      invoicingInfo,
      invoicingInfoEng,
      businessId,
      branchId,
      reservationId,
      isInvoiceClearYn
    }),
    DELETE_INVOICING_INFO: (businessId, branchId, reservationId) => ({
      businessId,
      branchId,
      reservationId
    }),
    SEND_INVOICE_EMAIL: (ID) => ({ ID }),
    GET_DAMAGE_SPECIFICATION_LIST: (reservationId) => ({ reservationId }),
    REGISTER_DAMAGE_SPECIFICATION_IMAGE_GROUP: (reservationId, imageGroup) => ({
      reservationId,
      imageGroup
    }),
    CREATE_DAMAGE_SPECIFICATION: (reservationId, imageGroup, file) => ({
      reservationId,
      imageGroup,
      file
    }),
    DELETE_DAMAGE_SPECIFICATION: (reservationId, imageGroup, fileIds) => ({
      reservationId,
      imageGroup,
      fileIds
    }),
    UPDATE_RESERVATION_NO_CONFIRM: (data) => ({ data }),
    INUSE_UPDATE_RESERVATION: (data) => ({ data }),
    RESET_RESERVATION_LIST: (nextState = getInitialReservationList()) => ({ nextState })
  },
  'GET_RESERVATION_LOCATION_LIST',
  'GET_VEHICLE_AVAILABLE_LIST',
  'GET_AGENT_LIST',
  'GET_USER_LIST',
  'GET_COMPANY_USER_LIST',
  'CHECK_LICENSE',
  'POST_RESERVATION',
  'DOWNLOAD_CSV',
  'INITIALIZE_INSURANCE_CHARGE_LIST',
  'CHANGE_ESTIMATE',
  'POST_RENTAL_RESERVATION',
  'CHANGE_INSURANCE_INFOMATION',
  'INITIALIZE_RESERVATION_USE_IMG_LIST',
  'INITIALIZE_RENT_LIST',
  'INITIALIZE_PROGRESS',
  'CHANGE_PROGRESS'
);

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false
  },
  vehicleList: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: 15, page: 1 }
    }
  },
  insuranceList: {
    data: []
  },
  agentList: {
    data: [],
    loading: false
  },
  userList: {
    data: []
  },
  companyList: {
    data: []
  },
  license: {
    data: {}
  },
  post: {
    data: {}
  },
  estimate: {
    loading: false,
    data: [],
    mode: 'create'
  },
  insuranceInfomation: {
    data: {}
  },
  reservationList: getInitialReservationList(),
  cost: {
    data: {}
  },
  useImgList: {
    data: [],
    loading: false
  },
  progress: {
    progressLoading: false
  },
  updateAddressInfo: { apiState: '' },
  updateCompanyAddressInfo: { apiState: '' },
  updateRentalCompanyInfo: { apiState: '' },
  updateInvoicingInfo: { apiState: '' },
  deleteInvoicingInfo: { apiState: '' },
  sendInvoiceEmail: { apiState: '' },
  getDamageSpecificationList: {
    data: {
      imageGroups: []
    },
    apiState: ''
  },
  registerDamageSpecificationImageGroup: { apiState: '' },
  createDamageSpecification: { apiState: '' },
  deleteDamageSpecification: { apiState: '' },
  updateReservationNoConfirm: { apiState: '' },
  inuseUpdateReservation: { apiState: '' }
};

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityGetLocationList = createEntity({
  key: getReservationLocationList().type,
  parseData: parseDataForList
});

export const entityGetVehicleAvailableList = createEntity({
  key: getVehicleAvailableList().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  parseData: parseDataForList
});

export const entityGetInsurancechargeList = createEntity({
  key: getInsuranceChargeList().type,
  parseData: parseDataForList
});

export const entityGetInsuranceCost = createEntity({
  key: getInsuranceCost().type
});

export const entityGetAgentList = createEntity({
  key: getAgentList().type,
  parseData: parseDataForList
});

export const entityGetUserList = createEntity({
  key: getUserList().type,
  parseData: parseDataForList,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) {
      FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    } else {
      FMSCommon.toast.fail('Common.Get.Fail');
    }
  }
});

export const entityGetCompanyList = createEntity({
  key: getCompanyUserList().type,
  parseData: parseDataForList,
  afterSuccessFn: (payload) => {
    if (!payload || !payload.items || payload.items.length === 0) {
      FMSCommon.toast.fail('Common.Msg.NoData');
    }
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Get.Fail');
  }
});

export const entityCheckLicense = createEntity({
  key: checkLicense().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterSuccessFn: () => {
    FMSCommon.toast.success('Reservation.License.ConfirmFinish');
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Reservation.LicenseCheck.Fail');
  }
});

export const entityPostReservation = createEntity({
  key: postReservation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterFailureFn: (payload) => {
    //오류메세지2가지: 1. 현재시간 기준 24시간 이후 예약 건일 경우 2. 반납안될 차량일 경우
    if (payload && payload.errorCode) {
      const details = payload.data?.details;
      if (details?.notReturnReservationKey !== undefined) {
        const startTime = moment(details.notReturnReservationStartTime).format('YYYY-MM-DD');
        const endTime = moment(details.notReturnReservationEndTime).format('YYYY-MM-DD');
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode), '', {
          br: <br />,
          id: details.notReturnReservationKey,
          startTime,
          endTime
        });
      } else {
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
      }
    } else FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
  },
  *afterSuccessFn(payload, requestParams) {
    if (payload.code && payload.code !== 0 && payload.code !== 2 && payload.code !== 3) {
      FMSCommon.toast.success(getErrorMessage(payload.code));
    } else {
      FMSCommon.toast.success('Common.Created.Success');
      if (requestParams.isTestCar === 'true') {
        const selectorListPage = yield select(selectorReservationPage);
        const { filters, pageInfo, sortInfo } = selectorListPage;
        yield put(
          changeHomeMenu({
            pathname: `${testCarUrl}`,
            state: { filters, pageInfo, sortInfo }
          })
        );
        history.push({
          pathname: `${testCarUrl}`,
          state: { filters, pageInfo, sortInfo }
        });
      } else {
        const selectorListPage = yield select(selectorReservationPage);
        const { filters, pageInfo, sortInfo } = selectorListPage;
        yield put(
          changeHomeMenu({
            pathname: `${historyUrl}/${payload.ID}`,
            state: { filters, pageInfo, sortInfo }
          })
        );
        history.push({
          pathname: `${historyUrl}/${payload.ID}`,
          state: { filters, pageInfo, sortInfo }
        });
      }
    }
  }
});

export const entityGetEstimaterList = createEntity({
  key: getEstimate().type,
  parseData: (action) => parseDataForList(action, 'none'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Get.Fail');
  }
});

export const entityGetReservationList = createEntity({
  key: getRentList().type,
  parseData: (action) => parseDataForList(action, 'reservationItems')
});

export const entityDownloadCSV = createEntity({
  key: downloadCsv().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  afterSuccessFn: (payload) => {
    if (payload) {
      FileSaver.saveAs(payload, `reservation-${dateFnsFormat(new Date(), 'YYYYMMDDHHmmss')}.csv`);
    }
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('PenaltyAndTollPage.Fail.Download');
  }
});

export const entityChangeInsuranceInfo = createEntity({
  key: changeInsuranceInfomation().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  }
});

export const entityReservationUseImg = createEntity({
  key: getReservationUseImgList().type,
  parseData: (action) => parseDataForList(action, 'none'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityUpdateAddressInfo = createEntityNew({
  key: updateAddressInfo().type,
  apiFn: updateAddressInfoAPI,
  defaultState: initializeState.updateAddressInfo,
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
    const selectorListPage = yield select(selectorReservationSearchDetailPage);
    const { data } = selectorListPage;
    const reservationID = data?.reservationID;
    yield put(getReservationDetail(reservationID));
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Update.Fail');
  }
});

export const entityUpdateCompanyAddressInfo = createEntityNew({
  key: updateCompanyAddressInfo().type,
  apiFn: updateCompanyAddressInfoAPI,
  defaultState: initializeState.updateCompanyAddressInfo,
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
    const selectorListPage = yield select(selectorReservationSearchDetailPage);
    const { data } = selectorListPage;
    const reservationID = data?.reservationID;
    yield put(getReservationDetail(reservationID));
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Update.Fail');
  }
});

export const entityUpdateRentalCompanyInfo = createEntityNew({
  key: updateRentalCompanyInfo().type,
  apiFn: updateCompanyRentalInfoAPI,
  defaultState: initializeState.updateRentalCompanyInfo,
  *afterSuccessFn(requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    console.log('requestParam:', requestParam);
    const selectorListPage = yield select(selectorReservationSearchDetailPage);
    const { data } = selectorListPage;
    const reservationID = data?.reservationID;
    yield put(getReservationDetail(reservationID));
  },
  afterFailureFn: (payload) => {
    console.log('payload:', payload);
    FMSCommon.toast.fail('Common.Update.Fail');
  }
});

export const entityUpdateInvoicingInfo = createEntityNew({
  key: updateInvoicingInfo().type,
  apiFn: updateInvoicingInfoAPI,
  defaultState: initializeState.updateInvoicingInfo,
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Update.Fail');
  }
});

export const entityDeleteInvoicingInfo = createEntityNew({
  key: deleteInvoicingInfo().type,
  apiFn: deleteInvoicingInfoAPI,
  defaultState: initializeState.deleteInvoicingInfo,
  confirmMessage: 'Common.Confirm.Delete',
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Delete.Fail');
  }
});

export const entitySendInvoiceEmail = createEntityNew({
  key: sendInvoiceEmail().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  apiFn: sendInvoiceEmailAPI,
  defaultState: initializeState.sendInvoiceEmail,
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Rental.Invoice.Email');
    const { ID } = requestParam;
    if (ID) yield put(getReservationDetail(ID));
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  }
});

export const entityGetDamageSpecificationList = createEntityNew({
  key: getDamageSpecificationList().type,
  apiFn: getDamageSpecificationListAPI,
  defaultState: initializeState.getDamageSpecificationList
});

export const entityRegisterDamageSpecificationImageGroup = createEntityNew({
  key: registerDamageSpecificationImageGroup().type,
  apiFn: registerDamageSpecificationImageGroupAPI,
  defaultState: initializeState.registerDamageSpecificationImageGroup
});

export const entityCreateDamageSpecification = createEntityNew({
  key: createDamageSpecification().type,
  apiFn: createDamageSpecificationAPI,
  defaultState: initializeState.createDamageSpecification,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Update.Fail');
  }
});

export const entityDeleteDamageSpecification = createEntityNew({
  key: deleteDamageSpecification().type,
  apiFn: deleteDamageSpecificationAPI,
  defaultState: initializeState.deleteDamageSpecification,
  confirmMessage: 'Common.Confirm.Delete',
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Update.Fail');
  }
});

export const entityUpdateReservationNoConfirm = createEntityNew({
  key: updateReservationNoConfirm().type,
  apiFn: updateReservationAPI,
  defaultState: initializeState.updateReservationNoConfirm,
  *afterSuccessFn() {
    yield put(setMenuActive({ data: [5, 1] })); // 이용 성공 후 대여내역관리 메뉴 활성화
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  }
});

export const entityInuseUpdateReservation = createEntityNew({
  key: inuseUpdateReservation().type,
  apiFn: inuseUpdateReservationAPI,
  defaultState: initializeState.inuseUpdateReservation,
  afterFailureFn: (payload) => {
    console.log('payload Inuse:', payload);
    if (payload && payload.errorCode && payload.data?.details) {
      const details = payload.data?.details;
      if (details.duplicatedReservationKey !== undefined) {
        const startTime = moment(details.duplicatedReservationStartTime).format('YYYY-MM-DD');
        const endTime = moment(details.duplicatedReservationEndTime).format('YYYY-MM-DD');
        FMSCommon.toast.fail(getErrorMessage(payload.errorCode), '', {
          br: <br />,
          id: details.duplicatedReservationKey,
          startTime,
          endTime
        });
        return;
      }
    } else if (payload && payload.errorCode) {
      FMSCommon.toast.fail(getErrorMessage(payload.data.errorCode));
    } else FMSCommon.toast.fail('Common.Update.Fail');
  }
});

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const getLocationListReducer = handleActions(
  {
    // [getLocationList().type]: () => initializeState.list,
  },
  {},
  { produce }
);

const getVehicleAvailableListReducer = handleActions(
  {
    [getVehicleAvailableList().type]: () => initializeState.vehicleList
  },
  {},
  { produce }
);

const getInsuranceChargeListReducer = handleActions(
  {
    [initializeInsuranceChargeList().type]: () => initializeState.insuranceList
  },
  {},
  { produce }
);

const getAgentListReducer = handleActions(
  {
    [getAgentList().type]: () => initializeState.agentList
  },
  {},
  { produce }
);

const getUserListReducer = handleActions(
  {
    [getUserList().type]: () => initializeState.userList
  },
  {},
  { produce }
);

const getCompanyListReducer = handleActions(
  {
    [getCompanyUserList().type]: () => initializeState.companyList
  },
  {},
  { produce }
);

const checkLicenseReducer = handleActions(
  {
    [checkLicense().type]: () => initializeState.license
  },
  {},
  { produce }
);

const postReservationReducer = handleActions(
  {
    [postReservation().type]: () => initializeState.post
  },
  {},
  { produce }
);

const getEstimateReducer = handleActions(
  {
    [getEstimate().type]: () => initializeState.estimate,
    [changeEstimate().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

const getReservationListReducer = handleActions(
  {
    [initializeRentList().type]: () => initializeState.reservationList,
    [resetReservationList().type]: (state, { payload }) => {
      return { ...state, ...payload.nextState };
    }
  },
  {},
  { produce }
);

const getInsuranceCostReducer = handleActions(
  {
    [getInsuranceCost().type]: () => initializeState.cost
  },
  {},
  { produce }
);

const changeInsuranceInfoReducer = handleActions(
  {
    [changeInsuranceInfomation().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);

const getReservationUseImgListReducer = handleActions(
  {
    [initializeReservationUseImgList().type]: () => initializeState.useImgList
  },
  {},
  { produce }
);

// page action reducer 선언
export const reservation = combineReducers({
  list: createReducer({
    entity: entityGetLocationList,
    reducer: getLocationListReducer
  }),
  vehicleList: createReducer({
    entity: entityGetVehicleAvailableList,
    reducer: getVehicleAvailableListReducer
  }),
  insuranceList: createReducer({
    entity: entityGetInsurancechargeList,
    reducer: getInsuranceChargeListReducer
  }),
  agentList: createReducer({
    entity: entityGetAgentList,
    reducer: getAgentListReducer
  }),
  userList: createReducer({
    entity: entityGetUserList,
    reducer: getUserListReducer
  }),
  companyList: createReducer({
    entity: entityGetCompanyList,
    reducer: getCompanyListReducer
  }),
  license: createReducer({
    entity: entityCheckLicense,
    reducer: checkLicenseReducer
  }),
  post: createReducer({
    entity: entityPostReservation,
    reducer: postReservationReducer
  }),
  estimate: createReducer({
    entity: entityGetEstimaterList,
    reducer: getEstimateReducer
  }),
  insuranceInfomation: createReducer({
    entity: entityChangeInsuranceInfo,
    reducer: changeInsuranceInfoReducer
  }),
  reservationList: createReducer({
    entity: entityGetReservationList,
    reducer: getReservationListReducer
  }),
  cost: createReducer({
    entity: entityGetInsuranceCost,
    reducer: getInsuranceCostReducer
  }),
  useImgList: createReducer({
    entity: entityReservationUseImg,
    reducer: getReservationUseImgListReducer
  }),
  progress: handleActions(
    {
      [initializeProgress().type]: () => initializeState.progress,
      [changeProgress().type]: (state, { payload }) => ({
        ...state,
        ...payload
      })
    },
    initializeState.progress,
    { produce }
  ),
  updateAddressInfo: createReducerNew(entityUpdateAddressInfo),
  updateCompanyAddressInfo: createReducerNew(entityUpdateCompanyAddressInfo),
  updateRentalCompanyInfo: createReducerNew(entityUpdateRentalCompanyInfo),
  updateInvoicingInfo: createReducerNew(entityUpdateInvoicingInfo),
  sendInvoiceEmail: createReducerNew(entitySendInvoiceEmail),
  deleteInvoicingInfo: createReducerNew(entityDeleteInvoicingInfo),
  getDamageSpecificationList: createReducerNew(entityGetDamageSpecificationList),
  registerDamageSpecificationImageGroup: createReducerNew(
    entityRegisterDamageSpecificationImageGroup
  ),
  createDamageSpecification: createReducerNew(entityCreateDamageSpecification),
  deleteDamageSpecification: createReducerNew(entityDeleteDamageSpecification),
  updateReservationNoConfirm: createReducerNew(entityUpdateReservationNoConfirm),
  inuseUpdateReservation: createReducerNew(entityInuseUpdateReservation)
});
/** ********************************************** */
