import request from 'apis/request';

const prefix = '/testcarconfig';

/**
 * 초기 조회 조회
 */
export const getTestCarConfigAPI = ({ data }) => {
  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 상세조회
 */
export const getTestCarConfigDetailAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};

/**
 * 제한설정 등록 기능
 */
export const createTestCarConfigAPI = ({ data }) => {
  return request({
    url: `${prefix}`,
    method: 'post',
    data
  });
};

/**
 * 제한설정 수정 기능
 */
export const updateTestCarConfigAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'put',
    data
  });
};

/**
 * 제한설정 삭제 기능
 */
export const deleteTestCarConfigAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
};
