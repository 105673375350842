import request from 'apis/request';
import { PAGE_LIMIT } from 'v2/constants/index';

const prefix = '/vehicle/accessory-svc/category';

export const getAccessoryCategoryListAPI = ({ filters = {}, pageInfo = {} }) => {
  const { page = 1, limit = PAGE_LIMIT } = pageInfo;
  const composer = fp.pipe(fp.set('pageNum', page), fp.set('rowsPerPage', limit));
  const data = composer(filters);

  return request({
    url: `${prefix}`,
    data
  });
};

export const getAccessoryCategoryAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};

export const createAccessoryCategoryAPI = (inputData) => {
  return request({
    url: `${prefix}`,
    method: 'post',
    data: inputData.data
  });
};

export const updateAccessoryCategoryAPI = (inputData) => {
  return request({
    url: `${prefix}/${inputData.ID}`,
    method: 'patch',
    data: inputData.data
  });
};

export const deleteAccessoryCategoryAPI = ({ categoryCd }) => {
  return request({
    url: `${prefix}/${categoryCd}`,
    method: 'delete',
    data: {
      remarks: ''
    }
  });
};

export const getAccessoryDealerCategoryListAPI = ({
  countryId,
  businessId,
  branchId,
  vehicleId,
  startDate,
  endDate
}) => {
  return request({
    url: '/vehicle/accessory-svc/dealer/category/list',
    data: {
      countryId,
      businessId,
      branchId,
      vehicleId,
      startDate,
      endDate
    }
  });
};

export const getAccessoryCategoryListForItemsAPI = ({
  countryId,
  businessId,
  branchId,
  categoryType
}) => {
  return request({
    url: '/vehicle/accessory-svc/category/dealer/exists',
    data: {
      countryId,
      businessId,
      branchId,
      categoryType
    }
  });
};

// 2021.11.17 카테고리 목록 전체 조회(카테고리 SELECTBOX )
export const getAccessoryCategoryListAllAPI = ({ filters = {} }) => {
  return request({
    url: `${prefix}`,
    data: filters
  });
};
