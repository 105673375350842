import request from 'apis/request';

const prefix = '/role';

export const getRoleListAPI = () => request({
    url: `${prefix}`
  });

export const getRoleAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });

export const createRoleAPI = ({ data }) => request({
    url: prefix,
    method: 'post',
    data
  });

export const updateRoleAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'put',
    data
  });
};

export const deleteRoleAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });

// export const getAccessListAPI = ({ ID }) => request({
//     url: `${prefix}/${ID}/acl`,
//   });

export const updateAccessListAPI = ({ ID, data }) => {
  const roleAcls =
    data
    && data
      .filter((acl) => acl && acl.menuID)
      .map((acl) => ({
        menuID: acl.menuID,
        inquiryYN: acl.inquiryYN || false,
        saveYN: acl.saveYN || false,
        delYN: acl.delYN || false,
        downYN: acl.downYN || false
      }));
  return request({
    url: `${prefix}/${ID}/acl`,
    method: 'post',
    data: { roleAcls }
  });
};

export const getAdminListAPI = ({ pageInfo, /* sortInfo, */ filters, roleID }) => {
  const { page = 1, limit = 10 } = pageInfo;
  // const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, startDate, endDate,
    keywordType,
    keyword,
    ...data
  } = filters;

  if (keywordType && keywordType !== '' && keyword) data[keywordType] = keyword;
  data.page = page;
  data.rowPerPage = limit;
  // data.sortOrder = (order === 'desc') ? 2 : 1;
  // data.sortField = getUserSortFieldNum(field);

  return request({
    url: `${prefix}/${roleID}/user`,
    data
  });
};
