import React from 'react';
import InputSelect from 'v2/components/ui/input/InputSelect';
import FMSForm from 'v2/components/ui/input/FMSForm';
import { SUPPORTED_LANGUAGES, DEFAULT_USED_LANGUAGE } from 'v2/constants';

const languageList = [
  { value: 'it', label: 'italiano' },
  { value: 'ko', label: '한국어' },
  { value: 'zh', label: '汉语' },
  { value: 'ja', label: '日本語' },
  { value: 'en', label: 'English' },
  { value: 'es', label: 'español' },
  { value: 'ru', label: 'Русский' },
  { value: 'fr', label: 'français_FR' },
  { value: 'be', label: 'français_BE' },
  { value: 'pl', label: 'Polski' },
  { value: 'sk', label: 'Slovák' },
  { value: 'cz', label: 'čeština' },
  { value: 'de', label: 'Deutsch' },
  { value: 'hu', label: 'Magyar' },
  { value: 'nl', label: 'Dutch' },
  { value: 'se', label: 'svenska' }
];

const list = _.intersectionWith(languageList, SUPPORTED_LANGUAGES, (obj1, obj2) => {
  return obj1.value === obj2 ? obj1 : null;
});

const LanguageSelect = ({ language, ...props }) => {
  return (
    <>
      <FMSForm style={{ width: '100%' }}>
        <InputSelect
          {...props}
          name='language'
          options={list}
          selectedValue={language || DEFAULT_USED_LANGUAGE}
        />
      </FMSForm>
    </>
  );
};

export default React.memo(LanguageSelect);
