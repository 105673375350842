import React, { useCallback, memo, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FMSColors } from 'service/common/designLib';
import { isRu } from 'v2/lib/utils';
import { useSelector } from 'react-redux';

const TopNavLink = styled(NavLink)`
  a:link,
  :visited,
  :hover,
  :active {
    text-decoration: none;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-width: 1680px;
  height: 56px;
  box-sizing: border-box;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  background-color: ${FMSColors.primary};
`;

const TopMenu = memo(({ menu, isSelected, handleSelectMenu }) => {
  const getMenuUrl = useCallback((menu) => {
    const menuUrl = fp.get('children[0].menuUrl', menu);
    return fp.isEmpty(menuUrl) ? '/' : menuUrl;
  }, []);

  // STI2021-1280 러시아인 경우 TopMeun 관리자업무관리 영문 노출 변경 적용
  const getMsgKey = useCallback((menu) => {
    const { msgKey } = menu;
    return isRu && msgKey && msgKey === 'Menu.Work' ? 'Menu.Work.Ru' : msgKey;
  }, []);

  return (
    <TopNavLink
      activeStyle={{
        fontWeight: 'bold',
        fontSize: '15px',
        color: FMSColors.white,
        letterSpacing: -0.3
      }}
      isActive={() => isSelected}
      style={{
        width: '12.5%',
        fontWeight: 'normal',
        fontSize: '14px',
        color: FMSColors.white,
        whiteSpace: 'normal'
      }}
      to={{ pathname: getMenuUrl(menu), state: null }}
      onClick={() => handleSelectMenu(menu)}
    >
      <div
        className='menu'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 10px',
          height: '56px',
          lineHeight: '1.2',
          margin: '0',
          background: isSelected ? '#39444C' : null
        }}
      >
        <FormattedMessage defaultMessage={menu.name} id={getMsgKey(menu) || 'none'} />
      </div>
    </TopNavLink>
  );
});

const HomeNavTopMenu = ({ menuList, selectedMenuIDs, handleSelectMenu }) => {
  // 상단 TAB메뉴 수동 제어
  const menuTabActive = useSelector(({ menu }) => {
    return menu.menuTabActive.data;
  });
  const isMenuSelected = useCallback(
    (selectedMenuIDs, menu) => {
      selectedMenuIDs = menuTabActive || selectedMenuIDs; // 메뉴상단TAB 활성화
      const composer = fp.pipe(fp.get('menuID'), (id) => fp.includes(id, selectedMenuIDs));
      const result = composer(menu);
      return result;
    },
    [menuTabActive]
  );

  const sortedMenuList = useMemo(() => fp.orderBy(['orderNum'], ['asc'], menuList || []), [
    menuList
  ]);

  return (
    <Container>
      <TopMenu
        key={fp.get('menuID', menu)}
        handleSelectMenu={handleSelectMenu}
        isSelected={isMenuSelected(selectedMenuIDs, menu)}
        menu={menu}
        r-for={menu in (sortedMenuList || [])}
        r-if={!fp.isEmpty(sortedMenuList) && !fp.pipe(fp.get('children'), fp.isNil)(menu)}
      />
    </Container>
  );
};

export default memo(HomeNavTopMenu);
