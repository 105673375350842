import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getMembersAPI,
  getMemberAPI,
  confirmMemberAPI,
  denyMemberAPI,
  updateMemberAPI,
  deleteMemberAPI
} from 'v2/apis/memberApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  updateMember,
  deleteMember,
  getMember,
  changeMemberPage,
  entityMember,
  deleteForMembers,
  getMembers,
  entityMembers,
  denyMember,
  confirmMember
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/member';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorMembersPage = (state) => state.member.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// update for update page
export const entityUpdateMember = createEntity({
  key: updateMember().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { ID } = payload;
    if (ID) yield put(getMember(ID));
  }
});
// confirm for confirm page
export const entityConfirmMember = createEntity({
  key: confirmMember().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Member.Approve.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Member.Approved.Success');
    const { ID } = payload;
    if (ID) yield put(getMember(ID));
  }
});
// confirm for confirm page
export const entityDenyMember = createEntity({
  key: denyMember().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Member.Denied.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Member.Denied.Success');
    const { ID } = payload;
    if (ID) yield put(getMember(ID));
  }
});
// delete for detail page
export const entityDeleteMember = createEntity({
  key: deleteMember().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorMembersPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForMembers = createEntity({
  key: deleteForMembers().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeMemberPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorMembersPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getMembers(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorMembersPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getMembers(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const updateMemberAsync = createSagaAction.bind(null, entityUpdateMember, updateMemberAPI);
const confirmMemberAsync = createSagaAction.bind(null, entityConfirmMember, confirmMemberAPI);
const denyMemberAsync = createSagaAction.bind(null, entityDenyMember, denyMemberAPI);
const deleteMemberAsync = createSagaAction.bind(null, entityDeleteMember, deleteMemberAPI);
const deleteForMembersAsync = createSagaAction.bind(null, entityDeleteForMembers, deleteMemberAPI);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getMembersAsync = createSagaAction.bind(null, entityMembers, getMembersAPI);
const getMemberAsync = createSagaAction.bind(null, entityMember, getMemberAPI);

function* changeMemberPageSaga() {
  yield put(changeMemberPage({ mode: 'view' }));
}

/**
 * Watcher
 */
export default function* memberWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    confirmMember().type,
    confirmFetchSaga,
    'Common.Confirm.Approve',
    confirmMemberAsync,
    entityConfirmMember
  );
  yield takeLatest(denyMember().type, fetchSaga, denyMemberAsync, entityDenyMember);
  yield takeLatest(
    updateMember().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateMemberAsync,
    entityUpdateMember
  );
  yield takeLatest(
    deleteMember().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteMemberAsync,
    entityDeleteMember
  );
  yield takeLatest(getMembers().type, fetchSaga, getMembersAsync, entityMembers);
  yield takeLatest(getMember().type, fetchSaga, getMemberAsync, entityMember);

  // deletes in list page
  yield takeLatest(
    deleteForMembers().type,
    fetchSagas,
    null,
    deleteForMembersAsync,
    entityDeleteForMembers
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityUpdateMember.types[1], changeMemberPageSaga);
}
