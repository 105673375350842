import request from 'apis/request';

export const getDigitalContractListAPI = ({ businessId }) => {
  return request({
    url: `/business/${businessId}/contract/visible`
  });
};

export const updateDigitalContractAPI = (data) => {
  const businessId = data.businessId;
  return request({
    url: `/business/${businessId}/contract/visible`,
    method: 'patch',
    data
  });
};
