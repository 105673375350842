import {
  getVehicleAPI,
  getVehicleListAPI,
  getVehicleListByMapAPI,
  getLocationListAPI
} from 'apis/monitoringApi';
import ReservationApi from 'apis/reservationApi';
import { fromJS } from 'immutable';
import { createAction, createActions, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import { FMSCommon } from 'service/common/commonLib';
import * as _ from 'lodash';

// Sync action creator
export const {
  initializeMonitoringState,
  setMonitoringState,
  replaceMonitoringState,
  getMonitoringVehicleDetail,
  getMonitoringVehicleList,
  getMonitoringVehicleListForSystemAdmin,
  getMonitoringVehicleListByMap,
  getMonitoringLocationList,
  getMonitoringReservationList,
  searchMonitoringVehicleList,
  searchMonitoringVehicleListByBusiness,
  searchMonitoringVehicleListByLocation
} = createActions(
  {
    INITIALIZE_MONITORING_STATE: (stateName) => ({ stateName }),
    SET_MONITORING_STATE: (data) => data,
    REPLACE_MONITORING_STATE: (data) => data
  },
  'GET_MONITORING_VEHICLE_DETAIL',
  'GET_MONITORING_VEHICLE_LIST',
  'GET_MONITORING_VEHICLE_LIST_FOR_SYSTEM_ADMIN',
  'GET_MONITORING_VEHICLE_LIST_BY_MAP',
  'GET_MONITORING_LOCATION_LIST',
  'GET_MONITORING_RESERVATION_LIST',
  'SEARCH_MONITORING_VEHICLE_LIST',
  'SEARCH_MONITORING_VEHICLE_LIST_BY_BUSINESS',
  'SEARCH_MONITORING_VEHICLE_LIST_BY_LOCATION'
);
// Async action creator
export const getVehicleAsync = createAction(getMonitoringVehicleDetail().type, getVehicleAPI);
export const getVehicleListAsync = createAction(getMonitoringVehicleList().type, getVehicleListAPI);
export const getVehicleListForSystemAdminAsync = createAction(
  getMonitoringVehicleListForSystemAdmin().type,
  getVehicleListAPI
);
export const getVehicleListByMapAsync = createAction(
  getMonitoringVehicleListByMap().type,
  getVehicleListByMapAPI
);
export const getLocationListAsync = createAction(
  getMonitoringLocationList().type,
  getLocationListAPI
);
export const getReservationListAsync = createAction(
  getMonitoringReservationList().type,
  ReservationApi.getReservationByVehicleTime
);
export const searchVehicleListAsync = createAction(
  searchMonitoringVehicleList().type,
  getVehicleListAPI
);
export const searchVehicleListByBusinessAsync = createAction(
  searchMonitoringVehicleListByBusiness().type,
  getVehicleListAPI
);
export const searchVehicleListByLocationAsync = createAction(
  searchMonitoringVehicleListByLocation().type,
  getVehicleListAPI
);

export const defaultState = {
  markerList: {
    status: null,
    data: {
      IN_USE: [],
      WAIT: [],
      DISABLE: [],
      INCIDENT: [],
      LOW_VOLTAGE: [],
      NOT_RETURNED: [],
      UNKNOWN: []
    }
  },
  vehicleList: {
    status: null,
    cnt: {
      total: 0,
      inUse: 0,
      wait: 0,
      available: 0,
      incident: 0,
      lowVoltage: 0,
      notReturned: 0,
      unknown: 0
    },
    data: [],
    pageInfo: {
      TotalRecord: 0,
      Page: 1, // to server(page)
      Limit: 20, // to server(rowPerPage)
      TotalPage: 1
    },
    sortInfo: { field: 'createdAt', order: 'desc' },
    filters: {
      keyword: '',
      status: []
    }
  },
  searchedVehicleList: {
    status: null,
    data: []
  },
  unavailableVehicleList: {
    status: null,
    totalCnt: 0,
    data: []
  },
  vehicleDetail: {
    status: null,
    data: {}
  },
  reservationList: {
    status: null,
    data: []
  }
};

const monitoring = handleActions(
  {
    [initializeMonitoringState().type]: (state, { payload: { stateName } }) => {
      if (stateName) return state.set(stateName, fromJS(defaultState[stateName]));
    },
    [setMonitoringState().type]: (state, { payload: { storeFields, value } }) => {
      const names = storeFields ? storeFields.split('.') : null;
      if (names) return state.setIn(names, fromJS(value));
    },
    [replaceMonitoringState().type]: (state, { payload: { storeFields, value } }) => {
      const names = storeFields ? storeFields.split('.') : null;

      if (names) return state.setIn(names, fromJS({ ...state.getIn(names).toJS(), ...value }));
    },
    ...pender({
      type: [getMonitoringVehicleDetail().type],
      onPending: (state) => state.setIn(['vehicleDetail', 'status'], 'PENDING'),
      onSuccess: (state, { payload: { data } }) => state
          .setIn(['vehicleDetail', 'data'], fromJS(data))
          .setIn(['vehicleDetail', 'status'], 'DONE'),
      onFailure: (state) => {
        FMSCommon.toast.fail('Vehicle.View.Fail');
        return state.setIn(['vehicleDetail', 'status'], 'DELETED'); // for go list
      }
    }),
    ...pender({
      type: [getMonitoringVehicleList().type],
      onPending: (state) => state
          .setIn(['vehicleList', 'status'], 'PENDING')
          .setIn(['unavailableVehicleList', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { items, statusCnt }
          }
        }
      ) => {
        const unavailableVehicleList = _.cloneDeep(defaultState.unavailableVehicleList.data);
        items
          && items.forEach((item) => {
            if (item.Status >= 3) {
              unavailableVehicleList.push(item);
            }
          });
        return state
          .setIn(['vehicleList', 'data'], fromJS(items) || [])
          .setIn(['vehicleList', 'cnt'], fromJS(statusCnt))
          .setIn(['vehicleList', 'status'], 'DONE')
          .setIn(['unavailableVehicleList', 'data'], fromJS(unavailableVehicleList))
          .setIn(['unavailableVehicleList', 'status'], 'DONE');
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Vehicle.View.Fail');
        return state
          .setIn(['vehicleList', 'status'], 'FAIL')
          .setIn(['unavailableVehicleList', 'status'], 'FAIL');
      }
    }),
    ...pender({
      type: [getMonitoringVehicleListForSystemAdmin().type],
      onPending: (state) => state.setIn(['unavailableVehicleList', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: {
              items,
              statusCnt: { total }
            }
          }
        }
      ) => {
        const systemVehicleList = {};
        items
          && items.forEach((item) => {
            if (item.business && item.business.ID) {
              if (!systemVehicleList[item.business.ID]) {
                systemVehicleList[item.business.ID] = {
                  name: item.business.name,
                  items: [item]
                };
              } else {
                systemVehicleList[item.business.ID].items = [
                  ...systemVehicleList[item.business.ID].items,
                  item
                ];
              }
            }
          });
        return state
          .setIn(['unavailableVehicleList', 'data'], fromJS(systemVehicleList))
          .setIn(['unavailableVehicleList', 'totalCnt'], total)
          .setIn(['unavailableVehicleList', 'status'], 'DONE');
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Vehicle.View.Fail');
        return state.setIn(['unavailableVehicleList', 'status'], 'FAIL');
      }
    }),
    ...pender({
      type: [getMonitoringVehicleListByMap().type],
      onPending: (state) => state.setIn(['markerList', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { items }
          }
        }
      ) => {
        const markerList = _.cloneDeep(defaultState.markerList.data);
        if (items) {
          items.forEach((item) => {
            markerList[item.vehicleStatus].push(item);
          });
        }
        return state
          .setIn(['markerList', 'data'], fromJS(markerList))
          .setIn(['markerList', 'status'], 'DONE');
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Vehicle.View.Fail');
        return state.setIn(['markerList', 'status'], 'FAIL');
      }
    }),
    ...pender({
      type: [getMonitoringLocationList().type],
      onPending: (state) => state.setIn(['locationList', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { items }
          }
        }
      ) => state
          .setIn(['locationList', 'data'], fromJS(items))
          .setIn(['locationList', 'status'], 'DONE'),
      onFailure: (state) => {
        FMSCommon.toast.fail('Location.View.Fail');
        return state.setIn(['locationList', 'status'], 'FAIL');
      }
    }),
    ...pender({
      type: [getMonitoringReservationList().type],
      onPending: (state) => state.setIn(['reservationList', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { reservationItems }
          }
        }
      ) => state
          .setIn(
            ['reservationList', 'data'],
            fromJS(
              reservationItems
                .filter((item) => item.status !== 'CANCELED' && item.status !== 'DENIED')
                .sort((a, b) => a.startTime - b.startTime)
            )
          )
          .setIn(['reservationList', 'status'], 'DONE'),
      onFailure: (state) => {
        FMSCommon.toast.fail('Reservation.View.Fail');
        return state.setIn(['reservationList', 'status'], 'FAIL');
      }
    }),
    ...pender({
      type: [searchMonitoringVehicleList().type],
      onPending: (state) => state.setIn(['searchedVehicleList', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { items, statusCnt }
          }
        }
      ) => {
        return state
          .setIn(['searchedVehicleList', 'data'], fromJS(items))
          .setIn(['searchedVehicleList', 'cnt'], fromJS(statusCnt))
          .setIn(['searchedVehicleList', 'status'], 'DONE');
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Common.Search.Fail');
        return state.setIn(['searchedVehicleList', 'status'], 'FAIL');
      }
    }),
    ...pender({
      type: [searchMonitoringVehicleListByBusiness().type],
      onPending: (state) => state.setIn(['searchedVehicleList', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { items, statusCnt }
          }
        }
      ) => {
        const searchedList = { type: 'business' };
        items
          && items.forEach((item) => {
            if (item.business && item.business.ID) {
              if (!searchedList[item.business.ID]) {
                searchedList[item.business.ID] = {
                  name: item.business.name,
                  items: [item]
                };
              } else {
                searchedList[item.business.ID].items = [
                  ...searchedList[item.business.ID].items,
                  item
                ];
              }
            }
          });
        return state
          .setIn(['searchedVehicleList', 'data'], fromJS(searchedList))
          .setIn(['searchedVehicleList', 'cnt'], fromJS(statusCnt))
          .setIn(['searchedVehicleList', 'status'], 'DONE');
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Common.Search.Fail');
        return state.setIn(['searchedVehicleList', 'status'], 'FAIL');
      }
    }),
    ...pender({
      type: [searchMonitoringVehicleListByLocation().type],
      onPending: (state) => state.setIn(['searchedVehicleList', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { items, statusCnt }
          }
        }
      ) => {
        const searchedList = { type: 'location' };
        items
          && items.forEach((item) => {
            if (item.location && item.location.ID) {
              if (!searchedList[item.location.ID]) {
                searchedList[item.location.ID] = {
                  name: item.location.name,
                  items: [item]
                };
              } else {
                searchedList[item.location.ID].items = [
                  ...searchedList[item.location.ID].items,
                  item
                ];
              }
            }
          });
        return state
          .setIn(['searchedVehicleList', 'data'], fromJS(searchedList))
          .setIn(['searchedVehicleList', 'cnt'], fromJS(statusCnt))
          .setIn(['searchedVehicleList', 'status'], 'DONE');
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Common.Search.Fail');
        return state.setIn(['searchedVehicleList', 'status'], 'FAIL');
      }
    })
  },
  fromJS(defaultState)
);

export default monitoring;
