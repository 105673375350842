import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getTestCarConfigDetailAPI,
  createTestCarConfigAPI,
  updateTestCarConfigAPI,
  deleteTestCarConfigAPI,
  getTestCarConfigAPI
} from 'v2/apis/testCarConfigApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  changeTestCarConfigPage,
  getTestCarConfigDetail,
  createTestCarConfig,
  entityTestCarConfigDetail,
  updateTestCarConfig,
  deleteTestCarConfig,
  getTestCarConfig,
  entityTestCarConfig,
  changeTestCarConfigDetailPage
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/testcarconfig';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
// export const selectorCompanyListPage = state => state.company.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateTestCarConfig = createEntity({
  key: createTestCarConfig().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(changeTestCarConfigDetailPage({ mode: 'view' }));
    yield put(getTestCarConfigDetail(payload.ID));
  }
});

// // update for update page
export const entityUpdateTestCarConfig = createEntity({
  key: updateTestCarConfig().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Updated.Success');
    yield put(changeTestCarConfigDetailPage({ mode: 'view' }));
    yield put(getTestCarConfigDetail(payload.ID));
  }
});

// deleteCompany(DetailPage)
export const entityDeleteTestCarConfig = createEntity({
  key: deleteTestCarConfig().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  afterSuccessFn: () => {
    FMSCommon.toast.success('Common.Deleted.Success');
    // const selectorListPage = yield select(selectorCompanyListPage);
    // const { pager } = selectorListPage;
    // history.push({ pathname: historyUrl, state: pager });
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createTestCarConfigAsync = createSagaAction.bind(
  null,
  entityCreateTestCarConfig,
  createTestCarConfigAPI
);
const getTestCarConfigAsync = createSagaAction.bind(null, entityTestCarConfig, getTestCarConfigAPI);
const updateTestCarConfigAsync = createSagaAction.bind(
  null,
  entityUpdateTestCarConfig,
  updateTestCarConfigAPI
);
const deleteTestCarConfigAsync = createSagaAction.bind(
  null,
  entityDeleteTestCarConfig,
  deleteTestCarConfigAPI
);
const getTestCarConfigDetailAsync = createSagaAction.bind(
  null,
  entityTestCarConfigDetail,
  getTestCarConfigDetailAPI
);

/**
 * Watcher
 */
export default function* testCarConfigWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createTestCarConfig().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createTestCarConfigAsync,
    entityCreateTestCarConfig
  );
  yield takeLatest(
    getTestCarConfigDetail().type,
    fetchSaga,
    getTestCarConfigDetailAsync,
    entityTestCarConfigDetail
  );
  yield takeLatest(
    updateTestCarConfig().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateTestCarConfigAsync,
    entityUpdateTestCarConfig
  );
  yield takeLatest(
    deleteTestCarConfig().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteTestCarConfigAsync,
    entityDeleteTestCarConfig
  );
  yield takeLatest(getTestCarConfig().type, fetchSaga, getTestCarConfigAsync, entityTestCarConfig);
}
