import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList, parseDataAndField } from 'v2/redux/modules/lib/actionLib';

export const {
  initializePaymentMonthList,
  getPaymentMonthList,
  changeMonthList,
  initializePaymentDayList,
  getPaymentDayList,
  changeDayList
} = createActions(
  {
    GET_PAYMENT_MONTH_LIST: (selectedMonthTime, ID) => ({ selectedMonthTime, ID }),
    GET_PAYMENT_DAY_LIST: (selectedDayTime, ID) => ({ selectedDayTime, ID })
  },
  'INITIALIZE_PAYMENT_MONTH_LIST',
  'INITIALIZE_PAYMENT_DAY_LIST',
  'CHANGE_MONTH_LIST',
  'CHANGE_DAY_LIST'
);

export const initializeState = {
  monthList: {
    data: [],
    selectedMonthTime: new Date().setHours(0, 0, 0, 0)
  },
  dayList: {
    data: {},
    selectedDayTime: new Date().setHours(0, 0, 0, 0)
  }
};

export const entityPaymentMonthList = createEntity({
  key: getPaymentMonthList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityPaymentDayList = createEntity({
  key: getPaymentDayList().type,
  parseData: (action) => parseDataAndField(action, 'item'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const paymentMonthListReducer = handleActions(
  {
    [initializePaymentMonthList().type]: () => initializeState.monthList,
    [changeMonthList().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const paymentDayListReducer = handleActions(
  {
    [initializePaymentDayList().type]: () => initializeState.dayList,
    [changeDayList().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

export const paymentCalendar = combineReducers({
  monthList: createReducer({
    entity: entityPaymentMonthList,
    reducer: paymentMonthListReducer
  }),
  dayList: createReducer({
    entity: entityPaymentDayList,
    reducer: paymentDayListReducer
  })
});
