import request from 'apis/request';

const prefix = '/control/history';

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
  }
}

/**
 * 차량제어 이력 목록 조회
 * ex) /history/control
 */
export const getControlHistoryListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { keywordType, keyword, ...data } = filters;

  if (keyword && keyword !== '') data[keywordType] = keyword;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 차량제어 이력 상세조회
 * ex) /history/control/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const getControlHistoryAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });
