import request from 'apis/request';

const prefix = '/vehicle/purchase';

/**
 * 구매내역 등록
 */
export const saveVehiclePurchaseAPI = (formData) => {
  return request({
    url: `${prefix}`,
    method: 'post',
    data: {
      ...formData
    }
  });
};

/**
 * 구매내역 수정
 */
export const updateVehiclePurchaseAPI = (formData) => {
  return request({
    url: `${prefix}/${formData.vehicleID}`,
    method: 'patch',
    data: {
      ...formData
    }
  });
};

/**
 * 구매내역 상세 조회
 */
export const getVehiclePurchaseAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};
