import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeLanguage } from 'v2/redux/modules/authentication';
import { SUPPORTED_LANGUAGES, IS_SUPPORTED_LANGUAGE_SELECT } from 'v2/constants';

import LanguageSelect from './components/LanguageSelect';

const LanguageSelectContainer = ({ ...props }) => {
  const { language } = useSelector(({ authentication }) => authentication);

  const dispatch = useDispatch();
  const handlerChange = useCallback((value) => dispatch(changeLanguage(value)), [dispatch]);

  return (
    <>
      <LanguageSelect
        {...{
          name: 'language',
          language,
          style: { display: IS_SUPPORTED_LANGUAGE_SELECT ? 'block' : 'none', width: '200px' },
          onChange: (data) => {
            let value = null;
            if (data && data.length && data[0]) value = data[0].value;
            else if (data) value = data.value;
            if (value) handlerChange(value);
          },
          ...props
        }}
      />
    </>
  );
};

export default React.memo(LanguageSelectContainer);
