import request from 'apis/request';

const prefix = '/customer';

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/**
 * 이용자 리스트 조회
 * ex) /customer
 */
export const getCustomersAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, startDate, endDate,
    keywordType,
    keyword,
    ...data
  } = filters;

  if (keywordType && keywordType !== '' && keyword) data[keywordType] = keyword;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 이용자 상세조회
 * ex) /customer/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const getCustomerAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}`
  });

/**
 * 이용자 리스트 조회
 * ex) /customer
 */
export const getServiceCustomersAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, startDate, endDate,
    keywordType,
    keyword,
    ...data
  } = filters;

  if (keywordType && keywordType !== '' && keyword) data[keywordType] = keyword;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `/service${prefix}`,
    data
  });
};

/**
 * 이용자 상세조회
 * ex) /customer/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const getServiceCustomerAPI = ({ ID }) =>
  request({
    url: `/service${prefix}/${ID}`
  });

/**
 * 이용자 승인
 * ex) /customer/ce4b7689-baa5-4cb6-ba34-dbc8468f943d/confirm
 */
export const confirmCustomerAPI = ({ ID, data }) =>
  request({
    url: `${prefix}/${ID}/confirm`,
    method: 'put',
    data
  });

/**
 * 이용자 반려
 * ex) /customer/ce4b7689-baa5-4cb6-ba34-dbc8468f943d/confirm
 */
export const denyCustomerAPI = ({ ID, data }) =>
  request({
    url: `${prefix}/${ID}/deny`,
    method: 'put',
    data
  });

/**
 * 이용자 수정
 * ex) /customer/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const updateCustomerAPI = ({ ID, data }) =>
  request({
    url: `${prefix}/${ID}`,
    method: 'put',
    data
  });

/**
 * 이용자 삭제
 * ex) /customer/b93ddfcc-56fc-4efe-9dbf-94e419b1001b
 */
export const deleteCustomerAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });

/**
 * 거점별 고객정보 다운로드
 */
export const getServiceCustomersExcelAPI = ({ filters }) => {
  const { ...data } = filters;
  return request({
    url: '/service/customer/download',
    method: 'file',
    data
  });
};
