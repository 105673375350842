import request from 'apis/request';

const prefix = '/couponmanage';
const prefixCheck = '/checkcoupon';

function getComapnySortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/*
  필요한 api
  1. pageList 데이터 조회
  2. 등록하는 부분
  3. 검색해서 pageList 데이터 조회
  4. 등록페이지에서 저장
  5. 취소
*/

export const getAllCouponListAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 쿠폰관리 전체 리스트 조회
 */
export const getCouponListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  // const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;
  data.page = page;
  data.rowPerPage = limit;
  // data.sortOrder = (order === 'desc') ? 2 : 1;
  // data.sortField = getComapnySortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 쿠폰관리 상세 조회
 */
export const getCouponDetailAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}`
  });

/**
 * 쿠폰관리 등록 기능
 */
export const createCouponAPI = (inputData) => {
  // const data = convertFormToData(inputData.data);
  return request({
    url: `${prefix}`,
    method: 'post',
    data: inputData.data
  });
};

/**
 * 쿠폰관리 수정 기능
 */
export const updateCouponAPI = (inputData) => {
  return request({
    url: `${prefix}/${inputData.ID}`,
    method: 'patch',
    data: inputData.data
  });
};

/**
 * 쿠폰관리 삭제 기능
 */
export const deleteCouponAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
};

/**
 * 쿠폰관리 복수 삭제 기능
 */
export const deleteCouponsAPI = (IDArr) =>
  Promise.all(
    IDArr.map((ID) =>
      request({
        method: 'delete',
        url: `${prefix}/${ID}`
      })
    )
  );

/**
 * 쿠폰 유효성 체크 조회
 */
export const getCheckCouponAPI = ({ data }) => {
  return request({
    url: `${prefixCheck}`,
    data
  });
};
