import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getLocationListAPI,
  getAllLocationListAPI, // 전체 조회
  getLocationDetailAPI,
  getLocationVehicleAPI,
  createLocationAPI,
  updateLocationAPI,
  deleteLocationAPI,
  getLocationVehicleListAPI,
  getUnusedLocationListAPI
} from 'v2/apis/locationApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, confirmFetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  loadAllLocations,
  loadUnusedLocations,
  entityLoadAllLocations,
  entityLoadUnusedLocations,
  changeLocationDetailState,
  getLocationList,
  getLocationDetail,
  createLocation,
  updateLocation,
  deleteLocation,
  deleteLocations,
  entityLocationList,
  entityLocationDetail,
  getLocationVehicle,
  entityLocationVehicle,
  getLocationVehicleList,
  entityLocationVehicleList
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/location';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorLocationListPage = (state) => state.location.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
/** ********************************************** */
// create for create page
export const entityCreateLocation = createEntity({
  key: createLocation().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
    // yield put(changeLocationDetailState({ mode: 'view' }));
    const selectorListPage = yield select(selectorLocationListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// update for update page
export const entityUpdateLocation = createEntity({
  key: updateLocation().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
    const selectorListPage = yield select(selectorLocationListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});
// delete for detail page
export const entityDeleteLocation = createEntity({
  key: deleteLocation().type,
  afterFailureFn: (payload) => {
    const errorCode = payload?.data?.errorCode;
    const message = payload?.data?.message;

    switch (errorCode) {
      case 409: // 해당 거점에 자동차가 등록 되어 있을때 삭제 금지
        FMSCommon.toast.fail('Location.error.vehicle.delete.disable', null, { location: message });
        break;
      case 423: // 해당 거점에 영업점이 등록 되어 있을때 삭제 금지
        FMSCommon.toast.fail('Location.error.branch.delete.disable', null, { location: message });
        break;
      default:
        FMSCommon.toast.fail('Common.Delete.Fail');
    }
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorLocationListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForLocationList = createEntity({
  key: deleteLocations().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeLocationDetailState({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorLocationListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getLocationList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(args = []) {
    args.forEach((element) => {
      const errorCode = element?.error?.data?.errorCode;
      const message = element?.error?.data?.message;
      switch (errorCode) {
        case 409: // 해당 거점에 자동차가 등록 되어 있을때 삭제 금지
          FMSCommon.toast.fail('Location.error.vehicle.delete.disable', null, {
            location: message
          });
          break;
        case 423: // 해당 거점에 영업점이 등록 되어 있을때 삭제 금지
          FMSCommon.toast.fail('Location.error.branch.delete.disable', null, { location: message });
          break;
        default:
          FMSCommon.toast.fail('Common.Delete.Fail');
      }
    });

    const selectorListPage = yield select(selectorLocationListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getLocationList(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const loadAllLocationsAsync = createSagaAction.bind(
  null,
  entityLoadAllLocations,
  getLocationListAPI
);
const loadUnusedLocationsAsync = createSagaAction.bind(
  null,
  entityLoadUnusedLocations,
  getUnusedLocationListAPI
);

const createLocationAsync = createSagaAction.bind(null, entityCreateLocation, createLocationAPI);
const updateLocationAsync = createSagaAction.bind(null, entityUpdateLocation, updateLocationAPI);
const deleteLocationAsync = createSagaAction.bind(null, entityDeleteLocation, deleteLocationAPI);
const deleteForLocationListAsync = createSagaAction.bind(
  null,
  entityDeleteForLocationList,
  deleteLocationAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getLocationListAsync = createSagaAction.bind(null, entityLocationList, getAllLocationListAPI);
const getLocationDetailAsync = createSagaAction.bind(
  null,
  entityLocationDetail,
  getLocationDetailAPI
);
const getLocationVehicleAsync = createSagaAction.bind(
  null,
  entityLocationVehicle,
  getLocationVehicleAPI
);
const getLocationVehicleListAsync = createSagaAction.bind(
  null,
  entityLocationVehicleList,
  getLocationVehicleListAPI
);

function* changeLocationPageSaga() {
  yield put(changeLocationDetailState({ mode: 'view' }));
}
/**
 * Watcher
 */
export default function* locationWatcher() {
  // select business
  yield takeLatest(
    loadAllLocations().type,
    fetchSaga,
    loadAllLocationsAsync,
    entityLoadAllLocations
  );
  yield takeLatest(
    loadUnusedLocations().type,
    fetchSaga,
    loadUnusedLocationsAsync,
    entityLoadUnusedLocations
  );
  yield takeLatest(
    createLocation().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createLocationAsync,
    entityCreateLocation
  );
  yield takeLatest(
    updateLocation().type,
    confirmFetchSaga,
    'Holiday.warning',
    updateLocationAsync,
    entityUpdateLocation
  );
  yield takeLatest(
    deleteLocation().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteLocationAsync,
    entityDeleteLocation
  );
  yield takeLatest(getLocationList().type, fetchSaga, getLocationListAsync, entityLocationList);
  yield takeLatest(
    getLocationVehicle().type,
    fetchSaga,
    getLocationVehicleAsync,
    entityLocationVehicle
  );
  yield takeLatest(
    getLocationDetail().type,
    fetchSaga,
    getLocationDetailAsync,
    entityLocationDetail
  );
  yield takeLatest(
    getLocationVehicleList().type,
    fetchSaga,
    getLocationVehicleListAsync,
    entityLocationVehicleList
  );

  // deletes in list page
  yield takeLatest(
    deleteLocations().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteForLocationListAsync,
    entityDeleteForLocationList
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityUpdateLocation.types[1], changeLocationPageSaga);
}
