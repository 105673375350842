import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getClearComapnyAPI,
  getCompanyListAPI,
  // getCompanyListAllAPI,
  getInsuranceCompanyListAPI,
  getCompanyDetailAPI,
  createCompanyAPI,
  updateCompanyAPI,
  deleteCompanyAPI
  // deleteCompanysAPI, // 거래처 복수 삭제 기능
} from 'v2/apis/companyApi';

import {
  createSagaAction,
  confirmFetchSaga,
  fetchSaga,
  fetchSagas,
  confirmDelFetchSaga
} from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  getCompanyListNew,
  getInsuranceCompanyList,
  getCompanyDetailNew,
  changeCompanyListPage,
  entityCompanyDetail,
  entityCompanyList,
  entityInsuranceCompanyList,
  loadAllCompany,
  entityLoadAllCompany,
  createCompanyNew,
  deleteCompanyNew,
  deleteCompanyListPageNew,
  updateCompanyNew,
  changeCompanyDetailPage
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/company';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorCompanyListPage = (state) => state.company.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateCompany = createEntity({
  key: createCompanyNew().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(changeCompanyDetailPage({ mode: 'view' }));
    const selectorListPage = yield select(selectorCompanyListPage);
    const { pager } = selectorListPage;
    const { ID } = payload;
    history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    yield put(getCompanyDetailNew(ID));
  }
});

// update for update page
export const entityUpdateCompany = createEntity({
  key: updateCompanyNew().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { ID } = requestParam;
    yield put(getCompanyDetailNew(ID));
  }
});

// deleteCompany(DetailPage)
export const entityDeleteCompany = createEntity({
  key: deleteCompanyNew().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCompanyListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForCompany = createEntity({
  key: deleteCompanyListPageNew().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.IDArr && payload.IDArr.length;
    if (isContinue) yield put(changeCompanyListPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCompanyListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCompanyListNew(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorCompanyListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCompanyListNew(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createCompanyAsync = createSagaAction.bind(null, entityCreateCompany, createCompanyAPI);
const updateCompanyAsync = createSagaAction.bind(null, entityUpdateCompany, updateCompanyAPI);
const deleteCompanyAsync = createSagaAction.bind(null, entityDeleteCompany, deleteCompanyAPI);
const deleteForCompanyAsync = createSagaAction.bind(null, entityDeleteForCompany, deleteCompanyAPI);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getCompanyListAsync = createSagaAction.bind(null, entityCompanyList, getCompanyListAPI);
const getInsuranceCompanyListAsync = createSagaAction.bind(
  null,
  entityInsuranceCompanyList,
  getInsuranceCompanyListAPI
);
const getCompanyDetailAsync = createSagaAction.bind(null, entityCompanyDetail, getCompanyDetailAPI);
const loadAllCompanyAsync = createSagaAction.bind(null, entityLoadAllCompany, getClearComapnyAPI);

function* changeCompanyDetailPageSaga() {
  yield put(changeCompanyDetailPage({ mode: 'view' }));
}

/**
 * Watcher
 */
export default function* companyWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createCompanyNew().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createCompanyAsync,
    entityCreateCompany
  );
  yield takeLatest(
    updateCompanyNew().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateCompanyAsync,
    entityUpdateCompany
  );
  yield takeLatest(
    deleteCompanyNew().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteCompanyAsync,
    entityDeleteCompany
  );
  yield takeLatest(getCompanyListNew().type, fetchSaga, getCompanyListAsync, entityCompanyList);
  yield takeLatest(
    getInsuranceCompanyList().type,
    fetchSaga,
    getInsuranceCompanyListAsync,
    entityInsuranceCompanyList
  );
  yield takeLatest(
    getCompanyDetailNew().type,
    fetchSaga,
    getCompanyDetailAsync,
    entityCompanyDetail
  );

  // deletes in list page
  yield takeLatest(
    deleteCompanyListPageNew().type,
    confirmDelFetchSaga,
    'Common.Confirm.Delete',
    deleteForCompanyAsync,
    entityDeleteForCompany
  );

  // select business
  yield takeLatest(loadAllCompany().type, fetchSaga, loadAllCompanyAsync, entityLoadAllCompany);

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityUpdateCompany.types[1], changeCompanyDetailPageSaga);
}
