import request from 'apis/request';

const prefix = '/violation';

/* 과태료 목록 조회 */
export const getViolationListAPI = ({ filters, pageInfo }) => {
  const { keywordType, keyword, ...data } = filters;
  if (keywordType) {
    data[keywordType] = keyword;
  }

  const { page, limit } = pageInfo;
  data.page = page;
  data.rowPerPage = limit;

  return request({
    url: prefix,
    data
  });
};

/* 과태료 상세 조회 */
export const getViolationDetailAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};

/* 과태료 등록 */
export const createViolationAPI = ({ data }) => {
  return request({
    url: prefix,
    method: 'post',
    data
  });
};

/* 대여 연동 */
export const linkReseravtionAPI = ({ data }) => {
  return request({
    url: '/reservation/violation',
    data
  });
};

/* 과태료 수정 */
export const updateViolationAPI = ({ data }) => {
  return request({
    url: `${prefix}/all`,
    method: 'patch',
    data
  });
};

/* 과태료 삭제 */
export const deleteViolationAPI = ({ docID }) => {
  return request({
    url: `${prefix}/${docID}`,
    method: 'delete'
  });
};

/* 이의 신청서 PDF 다운로드 */
export const downloadDocAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}/download`,
    method: 'file'
  });
};

export const getVehicleListForViolationAPI = ({ pageInfo, sortInfo, filters }) => {
  const { page, limit } = pageInfo;
  const { keyword, keywordType, ...data } = filters;

  data.page = page;
  data.rowPerPage = limit;
  if (keyword) {
    data[keywordType] = keyword;
  }
  return request({
    url: '/monitoring/vehicle',
    data
  });
};
