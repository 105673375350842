import request from 'apis/request';

const prefix = '/reservation';

export const getReservationListAPI = ({ pageInfo = {}, sortInfo = {}, filters = {} }) => {
  const {
    startDate,
    endDate,
    paymentStatus,
    keywordType,
    keyword,
    reservationType,
    ...data
  } = filters;
  data.businessType = 'PUBLIC';
  data.status = '1,2,3,5,7';
  // 20200819 default starttime = 000000, default endtime = 235959
  const date = new Date(startDate);
  date.setHours(0, 0, 0, 0);
  data.startDate = date.getTime();
  const date2 = new Date(endDate);
  // date2.setHours(0, 0, 0, 0);
  date2.setHours(23, 59, 59, 999);
  data.endDate = date2.getTime();
  /* 예약유형 추가 */
  data.reservationType = reservationType;

  if (paymentStatus) {
    data.paymentStatus = paymentStatus;
  }
  if (keyword) {
    data[keywordType] = keyword;
  }

  /* pageInfo */
  const { page, limit } = pageInfo;

  data.page = page;
  data.rowPerPage = limit;
  /* sortInfo */
  const { field, order } = sortInfo;
  data.sortField = field === 'startTime' ? 5 : 0;
  data.sortOrder = order === 'desc' ? 1 : 0;
  /* businessID */

  return request({
    url: '/reservation',
    data
  });
};

export const getPaymentDetailAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });

export const getTimeListAPI = ({ vehicleID, baseDay }) => {
  const data = {};
  data.vehicleID = vehicleID;
  const baseDt = new Date(parseInt(baseDay, 10));
  data.startDate = baseDt.setHours(0, 0, 0, 0);
  data.endDate = baseDt.setHours(23, 59, 59, 999);

  return request({
    url: prefix,
    data
  });
};

export const paymentProcessingAPI = ({ ID, data }) => {
  return request({
    // url: '/reservation/payment',
    url: `/reservation/${ID}/returnbillupdate`,
    method: 'patch',
    data
  });
};
