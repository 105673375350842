import { combineReducers } from 'redux';
import { createActions } from 'redux-actions';
import { createEntityNew, createReducerNew, fetchSagaNew } from 'v2/redux/lib/reducerLibNew';
import { takeLatest } from 'redux-saga/effects';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import {
  getAccessoryInfoListAPI,
  getAccessoryInfoAPI,
  createAccessoryInfoAPI,
  updateAccessoryInfoAPI,
  deleteAccessoryInfoAPI
} from 'v2/apis/accessoryInfoApi';
import { getErrorMessage } from 'constants/errors';

export const {
  getAccessoryInfoList,
  getAccessoryInfoDetail,
  createAccessoryInfo,
  updateAccessoryInfo,
  deleteAccessoryInfo
} = createActions({
  GET_ACCESSORY_INFO_LIST: (pageInfo, sortInfo, filters) => {
    return { pageInfo, sortInfo, filters };
  },
  GET_ACCESSORY_INFO_DETAIL: (ID, businessId, branchId) => {
    return { ID, businessId, branchId };
  },
  CREATE_ACCESSORY_INFO: (data) => {
    return { data };
  },
  UPDATE_ACCESSORY_INFO: (ID, data) => ({ ID, data }),
  DELETE_ACCESSORY_INFO: (data) => {
    return data;
  }
});

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  detail: {
    data: {},
    mode: 'view' // view, edit, create
  },
  create: { apiState: '' },
  update: { apiState: '' },
  delete: { apiState: '' }
};

const entityCategoryList = createEntityNew({
  key: getAccessoryInfoList().type,
  apiFn: getAccessoryInfoListAPI,
  defaultState: initializeState.list,
  parseData: (action) => parseDataForList(action, 'items'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const entityCategoryDetail = createEntityNew({
  key: getAccessoryInfoDetail().type,
  apiFn: getAccessoryInfoAPI,
  defaultState: initializeState.detail,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const entityCreateCategory = createEntityNew({
  key: createAccessoryInfo().type,
  apiFn: createAccessoryInfoAPI,
  defaultState: initializeState.create,
  confirmMessage: 'Common.Confirm.Create',
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
  }
});

const entityUpdateCategory = createEntityNew({
  key: updateAccessoryInfo().type,
  apiFn: updateAccessoryInfoAPI,
  defaultState: initializeState.update,
  confirmMessage: 'Common.Confirm.Update',
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
  }
});

const entityDeleteCategory = createEntityNew({
  key: deleteAccessoryInfo().type,
  apiFn: deleteAccessoryInfoAPI,
  defaultState: initializeState.delete,
  confirmMessage: 'Common.Confirm.Delete',
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
  }
});

export const accessoryInfo = combineReducers({
  list: createReducerNew(entityCategoryList),
  detail: createReducerNew(entityCategoryDetail),
  create: createReducerNew(entityCreateCategory),
  update: createReducerNew(entityUpdateCategory),
  delete: createReducerNew(entityDeleteCategory)
});

export default function* watcher() {
  yield takeLatest(getAccessoryInfoList().type, fetchSagaNew, entityCategoryList);
  yield takeLatest(getAccessoryInfoDetail().type, fetchSagaNew, entityCategoryDetail);
  yield takeLatest(createAccessoryInfo().type, fetchSagaNew, entityCreateCategory);
  yield takeLatest(updateAccessoryInfo().type, fetchSagaNew, entityUpdateCategory);
  yield takeLatest(deleteAccessoryInfo().type, fetchSagaNew, entityDeleteCategory);
}
