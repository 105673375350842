/* eslint-disable import/prefer-default-export */

export const addressParseDataForList = (action) => {
  if ($utils.isRu) {
    const actionDataRu = action.response.data.response.GeoObjectCollection.featureMember;
    const addressNameRu = actionDataRu[0].GeoObject.metaDataProperty.GeocoderMetaData.text;
    const result = { data: { addressName: addressNameRu, buildingName: '' } };

    return result;
  }

  const actionData =
    action && action.response && action.response.data && action.response.data.Response
      ? action.response.data.Response
      : {};
  let addressName = '';
  const buildingName = '';
  if (actionData && actionData.View.length > 0) {
    addressName = actionData.View[0].Result[0].Location.Address.Label;
  }
  return { data: { addressName, buildingName } };
};

export const geocodeParseDataForList = (action) => {
  if ($utils.isRu) {
    const actionData = fp.getOr(
      [],
      'response.data.response.GeoObjectCollection.featureMember',
      action
    );
    const location = fp.pipe(fp.getOr('', '[0].GeoObject.Point.pos'), fp.split(' '))(actionData);
    const latitude = parseFloat(fp.getOr(0, '[1]', location));
    const longitude = parseFloat(fp.getOr(0, '[0]', location));
    const result = { data: { latitude, longitude } };

    return result;
  }

  const actionData = action && action?.response?.data?.items ? action.response.data.items : {};
  const location = actionData[0]?.position;
  const latitude = parseFloat(location.lat) || 0;
  const longitude = parseFloat(location.lng) || 0;

  return { data: { latitude, longitude } };
};

export const autoCompleteParseData = (action) => {
  let data = [];
  if (!$utils.isRu) {
    const temp = fp.getOr([], 'response.data.items', action);
    data = temp?.filter((item) => {
      return !fp.isEmpty(item?.address?.postalCode) && !fp.isEmpty(item?.address?.countryName);
    });
    console.log('data:', data);
  } else {
    data = fp.getOr([], 'response.data.features', action);
  }

  const result = {
    data: data
  };

  return result;
};
