import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT, PAGE_LIMIT_TEN } from 'v2/constants/index';

export const {
  getTermsList,
  setTermsDetail,
  changeTermsDetailPage,
  // CRUD
  createTerms,
  updateTerms,
  deleteTerms,
  updateTermsVersion,
  initializeTermsListPage,
  initializeDetailPage,
  changeTermsState,
  deleteTermsFile
} = createActions(
  {
    GET_TERMS_LIST: (filters) => {
      return { filters };
    },
    SET_TERMS_DETAIL: (data) => {
      return { data };
    },
    CHANGE_TERMS_DETAIL_PAGE: (mode) => {
      return { mode };
    },
    CREATE_TERMS: (data, file) => {
      return { data, file };
    },
    UPDATE_TERMS: (ID, data, file) => {
      return { ID, data, file };
    },
    DELETE_TERMS: (ID) => ({ ID }),
    UPDATE_TERMS_VERSION: (ID, data) => {
      return { ID, data };
    },
    DELETE_TERMS_FILE: (pdfID) => ({ pdfID })
  },
  'INITIALIZE_TERMS_LIST_PAGE',
  'INITIALIZE_DETAIL_PAGE',
  'CHANGE_TERMS_STATE'
);

export const initializeState = {
  list: {
    data: [],
    loading: false,
    openNodes: [],
    selectedNode: null
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create'
  }
};

export const entityTermsList = createEntity({
  key: getTermsList().type,
  parseData: (action, dataField) => {
    const data = action && action.response && action.response.data;
    const fieldName = dataField || 'items';
    let resData = null;
    if (dataField === 'none') {
      resData = data;
    } else if (data && fieldName in data) {
      resData = data[fieldName];
    }
    const reqPager = action && action.params ? action.params : null;
    const resPageInfo = data ? data.pageInfo : null;
    const pageInfo = { ...(reqPager ? reqPager.pageInfo : null), ...(resPageInfo || null) };
    if (resData === null) {
      resData = [];
    }

    resData.map((d) => {
      d.required = d.required === 'Y';
      d.useyn = d.useyn === 'Y';
      return d;
    });

    return { data: resData };
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  },
  afterSuccessFn: () => {}
});

export const entityTermsDetail = createEntity({
  key: setTermsDetail().type
});

const TermsListPageReducer = handleActions(
  {
    [initializeTermsListPage().type]: () => initializeState.list,
    [changeTermsState().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

const TermsDetailPageReducer = handleActions(
  {
    [initializeDetailPage().type]: () => initializeState.detail,
    [setTermsDetail().type]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [changeTermsDetailPage().type]: (state, { payload }) => {
      return { ...state, ...payload };
    }
  },
  {},
  { produce }
);

// page action reducer 선언
export const terms = combineReducers({
  list: createReducer({
    entity: entityTermsList,
    reducer: TermsListPageReducer
  }),
  detail: createReducer({
    entity: entityTermsDetail,
    reducer: TermsDetailPageReducer
  })
});
