import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeCostitemCodeByDealerPage,
  getCostitemByDealerCodes,
  updateCostitemByDealerCode,
  createCostitemByDealerCode,
  deleteCostitemByDealerCodes,
  toggleCostItemByDealerModal,
  changeCostItemByDealerState,
  loadAllCostItemByDealer,
  loadAllListCostItemByDealer, // 전체 고정비용 SelectBox 리스트 (2021.10.18)
  initializeLoadAllCostitemCodeByDealer,
  initializeLoadAllListCostitemCodeByDealer // 전체 고정비용 SelectBox 리스트 (2021.10.18)
} = createActions(
  {
    GET_COSTITEM_BY_DEALER_CODES: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    }),
    UPDATE_COSTITEM_BY_DEALER_CODE: (data, businessId) => {
      return { data, businessId };
    },
    CREATE_COSTITEM_BY_DEALER_CODE: (data) => ({ data }),
    LOAD_ALL_COST_ITEM_BY_DEALER: (filters) => ({ filters }),
    LOAD_ALL_LIST_COST_ITEM_BY_DEALER: (filters) => ({ filters }) // 전체 고정비용 SelectBox 리스트 (2021.10.18)
  },
  'INITIALIZE_COSTITEM_CODE_BY_DEALER_PAGE',
  'TOGGLE_COST_ITEM_BY_DEALER_MODAL',
  'CHANGE_COST_ITEM_BY_DEALER_STATE',
  'DELETE_COSTITEM_BY_DEALER_CODES',
  'INITIALIZE_LOAD_ALL_COSTITEM_CODE_BY_DEALER',
  'INITIALIZE_LOAD_ALL_LIST_COSTITEM_CODE_BY_DEALER' // 전체 고정비용 SelectBox 리스트 (2021.10.18)
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityCostItemByDealerCodes = createEntity({
  key: getCostitemByDealerCodes().type,
  parseData: (action) => parseDataForList(action, 'costItems'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityLoadAllCostItemByDealer = createEntity({
  key: loadAllCostItemByDealer().type,
  parseData: (action) => parseDataForList(action, 'costItems')
});

// 전체 고정비용 SelectBox 리스트 (2021.10.18)
export const entityLoadAllListCostItemByDealer = createEntity({
  key: loadAllListCostItemByDealer().type,
  parseData: (action) => parseDataForList(action, 'costItems')
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    },
    openModal: false
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  },
  loadAllList: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const listPageReducer = handleActions(
  {
    [initializeCostitemCodeByDealerPage().type]: () => initializeState.list,
    [toggleCostItemByDealerModal().type]: (state, { payload }) => ({ ...state, ...payload }),
    [changeCostItemByDealerState().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

const costItemByDealerLoadAllReducer = handleActions(
  {
    [initializeLoadAllCostitemCodeByDealer().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);

// 전체 고정비용 SelectBox 리스트 (2021.10.18)
const costItemByDealerLoadAllListReducer = handleActions(
  {
    [initializeLoadAllListCostitemCodeByDealer().type]: () => initializeState.loadAllList
  },
  {},
  { produce }
);

// page action reducer 선언
export const costitemByDealer = combineReducers({
  list: createReducer({
    entity: entityCostItemByDealerCodes,
    reducer: listPageReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllCostItemByDealer,
    reducer: costItemByDealerLoadAllReducer
  }),
  loadAllList: createReducer({
    // 전체 고정비용 SelectBox 리스트 (2021.10.18)
    entity: entityLoadAllListCostItemByDealer,
    reducer: costItemByDealerLoadAllListReducer
  })
});
/** ********************************************** */
