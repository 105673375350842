import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getCostItemsAPI,
  updateCostItemAPI,
  createCostItemAPI,
  deleteCostItemAPI,
  getLoadAllCostItemsAPI
} from 'v2/apis/costItemApi';

import {
  createSagaAction,
  confirmFetchSaga,
  fetchSaga,
  fetchSagas,
  confirmFetchSagas
} from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';

import {
  getCostitemCodes,
  entityCostItemCodes,
  updateCostitemCode,
  createCostitemCode,
  deleteCostitemCodes,
  toggleCostItemModal,
  changeCostItemState,
  loadAllCostItem,
  entityLoadAllCostItem
} from '.';

/** ********************************************** */
/** constant */
// export const historyUrl = '/costitemcode';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorCostitemPage = (state) => state.costitem.list;
export const selectorCostitemLoadAll = (state) => state.costitem.loadAll;
/** ********************************************** */

// 수정
export const entityUpdateCostitemCode = createEntity({
  key: updateCostitemCode().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
    yield put(toggleCostItemModal({ openModal: true }));
  }
});

// 등록
export const entityCreateCostitemCode = createEntity({
  key: createCostitemCode().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(toggleCostItemModal({ openModal: true }));
  }
});

// mulitple delete for list page.
export const entityDeleteCostitemCodeList = createEntity({
  key: deleteCostitemCodes().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeCostItemState({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorCostitemPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCostitemCodes(pageInfo, sortInfo, filters));
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCostitemPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCostitemCodes(pageInfo, sortInfo, filters));
  }
});

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getCostItemCodeAsync = createSagaAction.bind(null, entityCostItemCodes, getCostItemsAPI);
const updateCostitemCodeAsync = createSagaAction.bind(
  null,
  entityUpdateCostitemCode,
  updateCostItemAPI
);
const createCostitemCodeAsync = createSagaAction.bind(
  null,
  entityCreateCostitemCode,
  createCostItemAPI
);
const deleteCostitemCodeListAsync = createSagaAction.bind(
  null,
  entityDeleteCostitemCodeList,
  deleteCostItemAPI
);
const loadAllCostItemAsync = createSagaAction.bind(
  null,
  entityLoadAllCostItem,
  getLoadAllCostItemsAPI
);

/**
 * Watcher
 */
export default function* costItemWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(getCostitemCodes().type, fetchSaga, getCostItemCodeAsync, entityCostItemCodes);
  yield takeLatest(
    updateCostitemCode().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateCostitemCodeAsync,
    entityUpdateCostitemCode
  );
  yield takeLatest(
    createCostitemCode().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createCostitemCodeAsync,
    entityCreateCostitemCode
  );
  // deletes in list page
  yield takeLatest(
    deleteCostitemCodes().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteCostitemCodeListAsync,
    entityDeleteCostitemCodeList
  );

  // select box costitem
  yield takeLatest(loadAllCostItem().type, fetchSaga, loadAllCostItemAsync, entityLoadAllCostItem);
}
