import { takeLatest } from 'redux-saga/effects';
import { createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { getServiceHistoryDetailAPI, getServiceHistoryListAPI } from 'v2/apis/serviceHistoryApi';
import { entityServiceHistoryList, getServiceHistoryList, entityServiceHistoryDetail, getServiceHistoryDetail } from '.';

/**
 * createSagaAction(entity, apiFn, params)
 */
const getServiceHistoryListAsync = createSagaAction.bind(
  null,
  entityServiceHistoryList,
  getServiceHistoryListAPI
);

const getServiceHistoryDetailAsync = createSagaAction.bind(
  null,
  entityServiceHistoryDetail,
  getServiceHistoryDetailAPI
);

export default function* serviceHistoryWatcher() {
  yield takeLatest(
    getServiceHistoryList().type,
    fetchSaga,
    getServiceHistoryListAsync,
    entityServiceHistoryList
  );

  yield takeLatest(
    getServiceHistoryDetail().type,
    fetchSaga,
    getServiceHistoryDetailAsync,
    entityServiceHistoryDetail
  );
}
