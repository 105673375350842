import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getBusinessCustomersAPI,
  getBusinessCustomerAPI,
  confirmBusinessCustomerAPI,
  denyBusinessCustomerAPI,
  updateBusinessCustomerAPI,
  deleteBusinessCustomerAPI,
  confirmAllBusinessCustomerAPI,
  // deleteAllBusinessCustomerAPI,
  downloadBusinessCustomerAPI
} from 'v2/apis/businessCustomerApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import FileSaver from 'file-saver';
import dateFnsFormat from 'date-fns/format';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  updateBusinessCustomer,
  deleteBusinessCustomer,
  getBusinessCustomer,
  changeBusinessCustomerPage,
  entityBusinessCustomer,
  deleteForBusinessCustomers,
  getBusinessCustomers,
  entityBusinessCustomers,
  denyBusinessCustomer,
  confirmBusinessCustomer,
  confirmAllBusinessCustomer,
  downloadBusinessCustomer
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/businessCustomer';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorCustomersPage = (state) => state.customer.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// update for update page
export const entityUpdateBusinessCustomer = createEntity({
  key: updateBusinessCustomer().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Common.Updated.Success');

    const { ID } = requestParams || null;
    if (ID) yield put(getBusinessCustomer(ID));
  }
});
// confirm for confirm page
export const entityConfirmBusinessCustomer = createEntity({
  key: confirmBusinessCustomer().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Customer.Approve.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Customer.Approved.Success');
    const { ID } = requestParams || null;
    if (ID) yield put(getBusinessCustomer(ID));
  }
});
// confirm All for confirm page
export const entityConfirmAllBusinessCustomer = createEntity({
  key: confirmAllBusinessCustomer().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Customer.Approve.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Customer.Approved.Success');
    const selectorListPage = yield select(selectorCustomersPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});
// confirm for confirm page
export const entityDenyBusinessCustomer = createEntity({
  key: denyBusinessCustomer().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Customer.Denied.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Customer.Denied.Success');
    const { ID } = requestParams || null;
    if (ID) yield put(getBusinessCustomer(ID));
  }
});
// delete for detail page
export const entityDeleteBusinessCustomer = createEntity({
  key: deleteBusinessCustomer().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCustomersPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForBusinessCustomers = createEntity({
  key: deleteForBusinessCustomers().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeBusinessCustomerPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCustomersPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getBusinessCustomers(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorCustomersPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getBusinessCustomers(pageInfo, sortInfo, filters));
  }
});
export const entityDownloadBusinessCustomer = createEntity({
  key: downloadBusinessCustomer().type,
  afterSuccessFn: (payload) => {
    if (payload) {
      FileSaver.saveAs(
        payload,
        `businessCustomer-${dateFnsFormat(new Date(), 'YYYYMMDDHHmmss')}.csv`
      );
    }
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const updateCustomerAsync = createSagaAction.bind(
  null,
  entityUpdateBusinessCustomer,
  updateBusinessCustomerAPI
);
const confirmCustomerAsync = createSagaAction.bind(
  null,
  entityConfirmBusinessCustomer,
  confirmBusinessCustomerAPI
);
const denyCustomerAsync = createSagaAction.bind(
  null,
  entityDenyBusinessCustomer,
  denyBusinessCustomerAPI
);
const deleteCustomerAsync = createSagaAction.bind(
  null,
  entityDeleteBusinessCustomer,
  deleteBusinessCustomerAPI
);
const deleteForCustomersAsync = createSagaAction.bind(
  null,
  entityDeleteForBusinessCustomers,
  deleteBusinessCustomerAPI
);
const confirmAllCustomerAsync = createSagaAction.bind(
  null,
  entityConfirmAllBusinessCustomer,
  confirmAllBusinessCustomerAPI
);
const downloadCustomerAsync = createSagaAction.bind(
  null,
  entityDownloadBusinessCustomer,
  downloadBusinessCustomerAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getCustomersAsync = createSagaAction.bind(
  null,
  entityBusinessCustomers,
  getBusinessCustomersAPI
);
const getCustomerAsync = createSagaAction.bind(
  null,
  entityBusinessCustomer,
  getBusinessCustomerAPI
);

function* changeCustomerPageSaga() {
  yield put(changeBusinessCustomerPage({ mode: 'view' }));
}

/**
 * Watcher
 */
export default function* memberWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    confirmBusinessCustomer().type,
    confirmFetchSaga,
    'Common.Confirm.Approve',
    confirmCustomerAsync,
    entityConfirmBusinessCustomer
  );
  yield takeLatest(
    denyBusinessCustomer().type,
    confirmFetchSaga,
    'Common.Confirm.Deny',
    denyCustomerAsync,
    entityDenyBusinessCustomer
  );
  yield takeLatest(
    updateBusinessCustomer().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateCustomerAsync,
    entityUpdateBusinessCustomer
  );
  yield takeLatest(
    deleteBusinessCustomer().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteCustomerAsync,
    entityDeleteBusinessCustomer
  );
  yield takeLatest(
    getBusinessCustomers().type,
    fetchSaga,
    getCustomersAsync,
    entityBusinessCustomers
  );
  yield takeLatest(getBusinessCustomer().type, fetchSaga, getCustomerAsync, entityBusinessCustomer);

  // deletes in list page
  yield takeLatest(
    deleteForBusinessCustomers().type,
    fetchSagas,
    null,
    deleteForCustomersAsync,
    entityDeleteForBusinessCustomers
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityUpdateBusinessCustomer.types[1], changeCustomerPageSaga);
  yield takeLatest(entityConfirmBusinessCustomer.types[1], changeCustomerPageSaga);
  yield takeLatest(entityDenyBusinessCustomer.types[1], changeCustomerPageSaga);
  yield takeLatest(
    confirmAllBusinessCustomer().type,
    confirmFetchSaga,
    'Common.Confirm.Approve',
    confirmAllCustomerAsync,
    entityConfirmAllBusinessCustomer
  );
  yield takeLatest(
    downloadBusinessCustomer().type,
    confirmFetchSaga,
    null,
    downloadCustomerAsync,
    entityDownloadBusinessCustomer
  );
}
