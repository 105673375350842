import request from 'apis/request';

// to do /costitem
const prefix = '/vehicle/cost/items';

// function getSortFieldNum(field) {
//   switch (field) {
//     default:
//     case 'createdAt':
//       return 1;
//     case 'name':
//       return 2;
//   }
// }

/**
 * 비용항목 리스트 조회
 * ex) /vehicle/cost/items
 */
export const getCostItemsAPI = ({ filters = {}, pageInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  // const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;
  data.pageNum = page;
  data.rowsPerPage = limit;
  // data.sortOrder = (order === 'desc') ? 2 : 1;
  // data.sortField = getSortFieldNum(field);
  // data.countryID = 'IT'; // to do : 국가 코드

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 비용항목 리스트 조회(선택박스용)
 * ex) /vehicle/cost/items
 */
export const getLoadAllCostItemsAPI = ({ filters }) => {
  const {
    // status, defaultStatus, startDate, endDate,
    ...data
  } = filters;

  // data.countryID = 'IT'; // to do : 국가 코드

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 비용항목 수정
 * ex) /vehicle/cost/items/code/:costCode
 */
export const updateCostItemAPI = ({ data }) => request({
    url: `${prefix}/code/${data.code}`,
    method: 'patch',
    data
  });

/**
 * 비용항목 등록
 * ex) /vehicle/cost/items
 */
export const createCostItemAPI = ({ data }) => {
  // data.countryID = 'IT'; // to do : 국가 코드
  return request({
    url: prefix,
    method: 'post',
    data // to do : 국가 코드
  });
};

/**
 * 비용항목 삭제
 * ex) /vehicle/cost/items/code/:code
 */
export const deleteCostItemAPI = (data) => {
  return request({
    url: `${prefix}/code/${data.code}`,
    method: 'delete',
    data: {}
  });
};

/**
 * 딜러그룹별 비용항목 리스트 조회
 * ex) /vehicle/cost/items/dealer-group
 */
export const getCostItemsByDealerAPI = ({ filters = {}, pageInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  // const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;

  data.pageNum = page;
  data.rowsPerPage = limit;
  // data.sortOrder = (order === 'desc') ? 2 : 1;
  // data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}/dealer-group`,
    data
  });
};

/**
 * 딜러그룹별 비용항목 리스트 조회 (선택박스)
 * ex) /vehicle/cost/items/dealer-group
 */
export const getLoadAllCostItemsByDealerAPI = ({ filters = {} }) => {
  // const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;

  // data.sortOrder = (order === 'desc') ? 2 : 1;
  // data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}/dealer-group`,
    data
  });
};

/**
 * 딜러그룹별 비용항목 수정
 * ex) /vehicle/cost/items/dealer-group/business/:businessId/code/:costCode
 */
export const updateCostItemByDealerAPI = ({ data, businessId }) => {
  return request({
    url: `${prefix}/dealer-group/business/${businessId}/code/${data.costCode}`,
    method: 'patch',
    data
  });
  // request({
  //   url: `${prefix}/dealer-group/businessId/${data.businessId}/code/${data.costCode}`,
  //   method: 'patch',
  //   data,
  // })
};

/**
 * 딜러그룹별 비용항목 등록
 * ex) /vehicle/cost/items/dealer-group
 */
export const createCostItemByDealerAPI = ({ data }) => {
  return request({
    url: `${prefix}/dealer-group`,
    method: 'post',
    data
  });
};

/**
 * 비용항목 삭제
 * ex) /vehicle/cost/items/dealer-group/business/:businessId/code/:costCode/type/:vehicleType
 */
export const deleteCostItemByDealerAPI = (data) => {
  return request({
    url: `${prefix}/dealer-group/business/${data.businessId}/code/${data.code}/type/${data.vehicleType}`,
    method: 'delete',
    data: {}
  });
};
