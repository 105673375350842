import * as pages from 'containers/pages';

const mainRoutes = [
  {
    path: '/vehicle',
    exact: true,
    component: pages.VehicleListPage, // 차량조회/등록
    subRoutes: [
      {
        path: '/vehicle/registrationCard/imageCreatePage',
        component: pages.VehicleRegistrationCardImageCreatePage
      },
      {
        path: '/vehicle/registrationCard/:imageID',
        component: pages.VehicleRegistrationCardCreatePage
      },
      {
        path: '/vehicle/create',
        component: pages.VehicleFormPage
      },
      {
        path: '/vehicle/edit/:ID',
        component: pages.VehicleFormPage
      },
      {
        path: ['/vehicle/form/:ID', '/vehicle/:ID', '/vehicle/:ID/fixedcost'],
        component: pages.VehicleDetailPage
      }
    ]
  },
  {
    path: '/vehicleMySales',
    exact: true,
    component: pages.VehicleMySalesPage, // 차량조회/등록
    subRoutes: [
      {
        path: '/vehicleMySales/create',
        component: pages.VehicleMySalesCreatePage
      }
    ]
  },
  {
    path: '/vehicleSap',
    exact: true,
    component: pages.VehicleSapPage,
    subRoutes: [
      {
        path: '/vehicleSap/create',
        component: pages.VehicleSapCreatePage
      }
    ]
  },
  {
    path: '/vehicleKpms',
    exact: true,
    component: pages.VehicleKpmsFormPage
  },
  {
    path: '/testCarReservation/newHoliday',
    exact: true,
    component: pages.NewHolidayListPage // 휴일 관리
  },
  {
    path: '/testCarReservation/config',
    exact: true,
    component: pages.TestCarConfigListPage // 시승차 제한설정
  },
  {
    path: '/vehicleModel',
    exact: true,
    component: pages.VehicleModelListPage, // 차량모델 관리
    subRoutes: [
      {
        path: '/vehicleModel/create',
        component: pages.VehicleModelCreatePage
      },
      {
        path: '/vehicleModel/:ID',
        component: pages.VehicleModelDetailPage
      }
    ]
  },
  {
    path: '/vehicleRetired',
    exact: true,
    component: pages.VehicleRetiredListPage // 차량모델 관리
  },
  {
    path: '/terminal',
    exact: true,
    component: pages.TerminalListPage, // 단말기 관리
    subRoutes: [
      {
        path: ['/terminal/create', '/terminal/:ID'],
        component: pages.TerminalDetailPage
      }
    ]
  },
  {
    path: '/testCarReservation',
    exact: true,
    component: pages.TestCarReservationListPage, // 시승차대여관리
    subRoutes: [
      {
        path: '/testCarReservation/:ID',
        component: pages.TestCarReservationDetailPage // 예약내역 조회
      }
    ]
  },
  {
    path: '/accident',
    exact: true,
    component: pages.AccidentListPage, // 사고/수리 정보
    subRoutes: [
      {
        path: '/accident/:ID',
        component: pages.AccidentEditPage
      },
      {
        path: '/accidentCreate',
        component: pages.AccidentCreatePage
      }
    ]
  },
  {
    path: '/reservation',
    exact: true,
    component: pages.ReservationListPage, // 예약내역 조회
    subRoutes: [
      // {
      //   path: '/reservation/edit/:ID',
      //   component: pages.ReservationEditPage, // 예약내역 수정
      // },
      {
        path: '/reservation/:ID',
        component: pages.ReservationDetailPage // 예약내역 조회
      },
      {
        path: '/shortRentalReservation',
        component: pages.ShortRentalPage // 단기대여예약
      },
      {
        path: '/insuranceRentalReservation',
        component: pages.InsuranceRentalPage // 보험대차예약
      },
      {
        path: '/monthRentalReservation',
        component: pages.MonthRentalPage // 월간대여예약
      },
      {
        path: '/testCarReservationCreate',
        component: pages.TestRentalCreatePage // 시승차 신규예약
      }
    ]
  },
  {
    path: '/rentStatus',
    exact: true,
    component: pages.RentStatusListPage, // 대여내역 조회
    subRoutes: [
      {
        path: '/rentStatus/:ID',
        component: pages.RentStatusDetailPage // 대여내역 상세
      },
      {
        path: '/shortRental',
        component: pages.ShortRentalPage // 단기대여예약
      },
      {
        path: '/insuranceRental',
        component: pages.InsuranceRentalPage // 보험대차예약
      },
      {
        path: '/monthRental',
        component: pages.MonthRentalPage // 월간대여예약
      }
    ]
  },
  {
    path: '/useState',
    exact: true,
    component: pages.UseStateListPage
    // component: pages.CurrencyListPage
    // component: pages.MonitoringdataListPage
    // component: pages.DashBoardPage
    // component: pages.VoucherKpiMainPage
    // component: pages.VehicleListPage
    // component: pages.ShortRentalPage // 단기대여예약
    // component: pages.ReservationDetailPage //예약 상세
    // component: pages.ChargeListPage
    // component: pages.UseStateListPage // 이용 현황
  },
  {
    exact: true,
    path: '/paymentCalendar',
    component: pages.PaymentCalendarPage // 매출/정산
  },
  {
    path: '/payment',
    exact: true,
    component: pages.PaymentListPage, // 결제내역
    subRoutes: [
      {
        path: '/payment/:ID',
        exact: true,
        component: pages.PaymentDetailPage
      }
    ]
  },
  {
    path: '/charge',
    exact: true,
    component: pages.ChargeListPage, // 요금제 관리
    subRoutes: [
      {
        path: ['/charge/create/', '/charge/:ID'],
        component: pages.ChargeDetailPage
      }
    ]
  },
  {
    path: '/chargeVehicle',
    exact: true,
    component: pages.ChargeVehicleListPage // 차량별 요금제 관리
  },
  {
    path: '/insurance',
    exact: true,
    component: pages.InsuranceListPage, // 보험료 관리
    subRoutes: [
      {
        path: ['/insurance/create/', '/insurance/:ID'],
        component: pages.InsuranceDetailPage
      }
    ]
  },
  {
    path: '/insuranceVehicle',
    exact: true,
    component: pages.InsuranceVehicleListPage // 차량별 보험료 관리
  },
  {
    path: '/rentalSearch',
    exact: true,
    component: pages.RentalSearchListPage, // 대여가능차량조회 2303기준 사용안함
    subRoutes: [
      {
        path: ['/rentalSearch/create/', '/rentalSearch/:ID'],
        component: pages.RentalSearchDetailPage
      }
    ]
  },
  {
    path: '/member',
    exact: true,
    component: pages.MemberListPage, // 모션 관리자 관리
    subRoutes: [
      {
        path: ['/member/:ID'],
        component: pages.MemberDetailPage
      }
    ]
  },
  {
    path: '/notification',
    exact: true,
    component: pages.NotificationListPage // 알람 조회
  },
  {
    path: '/business',
    exact: true,
    component: pages.BusinessListPage, // 사업자 관리
    subRoutes: [
      {
        path: ['/business/create', '/business/:ID'],
        component: pages.BusinessDetailPage
      }
    ]
  },
  {
    path: '/businessMember',
    exact: true,
    component: pages.BusinessMemberListPage, // 사업자 관리자
    subRoutes: [
      {
        path: ['/businessMember/:ID'],
        component: pages.BusinessMemberDetailPage
      }
    ]
  },
  {
    path: '/businessCustomer',
    exact: true,
    component: pages.BusinessCustomerListPage, // 법인 회원 관리
    subRoutes: [
      {
        path: ['/businessCustomer/:ID'],
        component: pages.BusinessCustomerDetailPage
      }
    ]
  },
  {
    path: '/customer',
    exact: true,
    component: pages.CustomerListPage, // 회원 관리
    subRoutes: [
      {
        path: ['/customer/:ID'],
        component: pages.CustomerDetailPage
      }
    ]
  },
  {
    path: '/service/customer',
    exact: true,
    component: pages.ServiceCustomerListPage, // 회원 관리
    subRoutes: [
      {
        path: ['/service/customer/:ID'],
        component: pages.ServiceCustomerDetailPage
      }
    ]
  },
  {
    path: '/location',
    exact: true,
    component: pages.LocationListPage, // 거점 관리
    subRoutes: [
      {
        path: '/location/create',
        component: pages.LocationFormPage
      },
      {
        path: '/location/form/:ID',
        component: pages.LocationFormPage
      },
      {
        path: '/location/:ID',
        component: pages.LocationDetailPage
      }
    ]
  },
  {
    path: '/locationQuery',
    exact: true,
    component: pages.LocationQueryPage // 거점 조회
  },
  {
    path: '/license',
    exact: true,
    component: pages.LicenseListPage, // Mocean 업무 관리
    subRoutes: [
      {
        path: ['/license/create', '/license/:ID'],
        component: pages.LicenseDetailPage
      }
    ]
  },

  {
    path: '/holiday',
    exact: true,
    component: pages.HolidayListPage // 휴일 관리
  },
  {
    path: '/code',
    exact: true,
    component: pages.CodePage // 코드 관리
  },
  {
    path: '/dashboardKPI',
    exact: true,
    component: pages.DashBoardPage // 구 영엽지표현황 대시보드
  },
  {
    path: '/newDashboardKPI',
    exact: true,
    component: pages.DashBoardKpi // 영엽지표현황 대시보드
  },
  {
    path: '/voucherKPI',
    exact: true,
    component: pages.VoucherKpiMainPage // 전자계약관리 조회
  },
  {
    path: '/dashboardMCU',
    exact: true,
    component: pages.MonthlyRentalPage // Monthly new rental & utilization
  },
  {
    path: '/dashboardDEC',
    exact: true,
    component: pages.DailyReservationPage // Daily reservation
  },
  {
    path: '/monthCar',
    exact: true,
    component: pages.MonthOfCarPage // Business results by model
  },
  {
    path: '/menu',
    exact: true,
    component: pages.MenuPage // 메뉴 관리
  },
  {
    path: '/role',
    exact: true,
    component: pages.RolePage // 역할 관리
  },
  {
    path: '/program',
    exact: true,
    component: pages.ProgramListPage // 프로그램 관리
  },
  {
    path: '/terms',
    exact: true,
    component: pages.TermsPage // 프로그램 관리
  },
  {
    path: '/appVersion',
    exact: true,
    component: pages.AppVersionListPage, // 앱버전 관리
    subRoutes: [
      {
        path: ['/appVersion/create'],
        component: pages.AppVersionDetailPage
      }
    ]
  },
  // {
  //   path: '/message',
  //   exact: true,
  //   component: pages.MessageListPage, // 메시지 관리
  //   subRoutes: [
  //     {
  //       path: '/message/:ID',
  //       component: pages.MessageDetailPage,
  //     },
  //   ],
  // },
  {
    path: '/company',
    exact: true,
    component: pages.CompanyListPage, // 거래처 관리
    subRoutes: [
      {
        path: ['/company/create', '/company/:ID'],
        component: pages.CompanyDetailPage
      }
    ]
  },
  {
    path: '/companyUser',
    exact: true,
    component: pages.CompanyUserListPage, // 거래처 담당자 관리
    subRoutes: [
      {
        path: ['/companyUser/create', '/companyUser/:companyID/:ID'],
        component: pages.CompanyUserDetailPage
      }
    ]
  },
  {
    path: '/branch',
    exact: true,
    component: pages.BranchListPage, // 영업점 관리
    subRoutes: [
      {
        path: ['/branch/create', '/branch/:ID'],
        component: pages.BranchDetailPage
      }
    ]
  },
  {
    path: '/vehicleInsurance',
    exact: true,
    component: pages.VehicleInsuranceListPage // 보험금 관리
    // subRoutes: [
    //   {
    //     path: ['/vehicleInsurance/:ID', '/vehicleInsurance/create'],
    //     component: pages.VehicleInsuranceDetailPage,
    //   },
    // ],
  },
  {
    path: '/history/control',
    exact: true,
    component: pages.ControlHistoryListPage, // 차량제어 이력
    subRoutes: [
      {
        path: ['/history/control/:ID'],
        component: pages.ControlHistoryDetailPage
      }
    ]
  },
  {
    path: '/history/licenseCheck',
    exact: true,
    component: pages.LicenseCheckHistoryListPage, // 운전면허 검증이력
    subRoutes: [
      {
        path: ['/history/licenseCheck/:ID'],
        component: pages.LicenseCheckHistoryDetailPage
      }
    ]
  },
  {
    path: '/mypage',
    component: pages.ProfileDetailPage // 마이페이지
  },
  {
    path: '/mybusiness',
    component: pages.MyBusinessPage // 나의사업장 정보수정
  },
  {
    path: '/mybranch',
    component: pages.MyBranchPage // 나의영업점 정보수정
  },
  {
    path: '/redirect',
    exact: true,
    component: pages.RedirectPage // 로그인 (전체)
  },
  {
    path: '/violation',
    exact: true,
    component: pages.ViolationListPage, // 과태료/통행료
    subRoutes: [
      {
        path: ['/violation/create', '/violation/:ID'],
        component: pages.ViolationDetailPage
      }
    ]
  },

  // 2020.11.16 leejw 추가
  {
    path: '/monitoringdata',
    exact: true,
    component: pages.MonitoringdataListPage
    /** subRoutes: [
     {
        path: ['/history/licenseCheck/:ID'],
        component: pages.LicenseCheckHistoryDetailPage,
      },
     ], */
  },
  // 설문조사 페이지
  {
    path: '/survey',
    exact: true,
    component: pages.SurveyListPage
    // subRoutes: [
    //   {
    //     path: ['/company/create', '/company/:ID'],
    //     component: pages.CompanyDetailPage,
    //   },
    // ],
  },

  // 2020.12.22 leejw 추가
  {
    path: '/businessreport',
    exact: true,
    component: pages.BusinessReportListPage // 운전면허 검증이력
    /** subRoutes: [
     {
        path: ['/history/licenseCheck/:ID'],
        component: pages.LicenseCheckHistoryDetailPage,
      },
     ], */
  },

  // 2021.05.21 추가
  {
    path: '/costitemcode',
    exact: true,
    component: pages.CostItemCodePage
    /** subRoutes: [
     {
        path: ['/history/licenseCheck/:ID'],
        component: pages.LicenseCheckHistoryDetailPage,
      },
     ], */
  },

  // 2021.05.21 추가
  {
    path: '/costitembydealer',
    exact: true,
    component: pages.CostItemCodeByDealerPage
  },

  // 공지사항관리
  {
    path: '/notice',
    exact: true,
    component: pages.NoticeListPage,
    subRoutes: [
      {
        path: '/notice/create',
        component: pages.NoticeCreatePage
      },
      {
        path: '/notice/:ID',
        component: pages.NoticeDetailPage
      }
    ]
  },
  // push전송관리
  {
    path: '/push',
    exact: true,
    component: pages.PushListPage,
    subRoutes: [
      {
        path: '/push/create',
        component: pages.PushCreatePage
      },
      {
        path: '/push/:ID',
        component: pages.PushDetailPage
      }
    ]
  },
  // 액세서리 관리
  {
    path: '/accessory',
    exact: true,
    component: pages.AccessoryInfoListPage, // 액세서리 관리
    subRoutes: [
      {
        path: '/accessory/create',
        component: pages.AccessoryInfoCreatePage
      },
      {
        path: '/accessory/:ID',
        component: pages.AccessoryInfoDetailPage
      }
    ]
  },
  {
    path: '/accessoryModel',
    exact: true,
    component: pages.AccessoryModelListPage, // 액세서리 모델 관리
    subRoutes: [
      {
        path: '/accessoryModel/create',
        component: pages.AccessoryModelCreatePage
      },
      {
        path: '/accessoryModel/:ID',
        component: pages.AccessoryModelDetailPage
      }
    ]
  },
  {
    path: '/accessoryService',
    exact: true,
    component: pages.AccessoryServiceListPage, // 액세서리 서비스 관리
    subRoutes: [
      {
        path: '/accessoryService/create',
        component: pages.AccessoryServiceCreatePage
      },
      {
        path: '/accessoryService/:ID',
        component: pages.AccessoryServiceDetailPage
      }
    ]
  },
  {
    path: '/accessoryCategory',
    exact: true,
    component: pages.AccessoryCategoryListPage, // 액세서리 카테고리 관리
    subRoutes: [
      {
        path: '/accessoryCategory/create',
        component: pages.AccessoryCategoryCreatePage
      },
      {
        path: '/accessoryCategory/:ID',
        component: pages.AccessoryCategoryDetailPage
      }
    ]
  },

  // 2021.01.07 쿠폰관리 추가
  {
    path: '/couponmanage',
    exact: true,
    component: pages.CouponmanageListPage, // 쿠폰 관리
    subRoutes: [
      {
        path: ['/couponmanage/create', '/couponmanage/:ID'],
        component: pages.CouponmanageDetailPage
      }
    ]
  },

  // 2021.04.30 딜러서포트 추가
  {
    path: '/dealersupport',
    exact: true,
    component: pages.DealerSupportListPage // 딜러서포트
  },

  // 2021.06.11 결제송장관리(stripe payment invoice) 추가
  {
    path: '/stripeinvoice',
    exact: true,
    component: pages.StripeInvoiceListPage // 결제송장관리
  },
  {
    path: '/apiHistory',
    exact: true,
    component: pages.ApiHistoryListPage // 알람 조회
  },
  {
    path: '/taxRate',
    exact: true,
    component: pages.TaxRateListPage // 알람 조회
  },
  {
    path: '/currency',
    exact: true,
    component: pages.CurrencyListPage // 환율 조회
  },
  {
    path: '/digitalContract',
    exact: true,
    component: pages.DigitalContractListPage // 전자계약관리 조회
  },
  {
    path: '/serviceHistory',
    exact: true,
    component: pages.ServiceHistoryListPage, // 서비스 로그 조회
    subRoutes: [
      {
        path: '/serviceHistory/:ID',
        component: pages.ServiceHistoryDetailPage
      }
    ]
  },
  // 2024.06.18 대시보드 고도화
  {
    path: '/dealerKPI',
    exact: true,
    component: pages.DealerKPIListPage // 딜러KPI 대시보드
  }
];

export default mainRoutes;
