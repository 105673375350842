import request from 'apis/request';

const prefix = '/push';

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'pushTitle':
      return 1;
    case 'createdAt':
      return 2;
  }
}

/**
 * 푸쉬 리스트 조회
 */
export const getPushListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page, limit } = pageInfo;
  const { field, order } = sortInfo;
  const { ...data } = filters;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 푸쉬 상세 조회
 */
export const getPushDetailAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};

/**
 * 푸쉬 City 데이터
 */
export const getCityListAPI = () => {
  return request({
    url: `/city/distinct`
  });
};

/**
 * 푸쉬 City 별 customer 리스트
 */
export const getCustomerListAPI = ( inputData) => {
  const { ...data } = inputData;
  console.log('getCustomerListAPI, data: ', data);
  return request({
    url: `/city/customer`,
    data
  });
}

/**
 * 푸쉬 등록 기능
 */
export const createPushAPI = (inputData) => {
  return request({
    url: '/push',
    method: 'post',
    data: inputData.data
  });
};

/**
 * 푸쉬 삭제 기능
 */
export const deletePushAPI = ({ ID }) => {
  return request({
    method: 'delete',
    url: `${prefix}/${ID}`
  });
};

/**
 * 푸쉬 수정 기능
 */
export const updatePushAPI = (inputData) => {
  return request({
    url: `${prefix}/${inputData.ID}`,
    method: 'patch',
    data: inputData.data
  });
};
