export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

/**
 * Entity Action Async 객체 생성
 *
 * @param {*} base
 */
function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

/**
// type: [ deleteBusinesses().type ],
// onPending, onSuccess, onFailure handler 정의 함수.(내부 공통)
 * Entity Action Async
 * 
 * @param {*} type 
 * @param {*} payload 
 */
export function action(type, payload = {}) {
  return { type, ...payload };
}

export function parseData(action) {
  const data = action && action.response && action.response.data ? action.response.data : {};
  return { data };
}

function parseError(action) {
  const error =
    action
    && action.response
    && action.response.status
    && action.response.status >= 300
    && action.response.status < 200
      ? action.response
      : null;
  if (error && error.status !== 401 && error.status !== 403) return { error };
  return null;
}

const defaultErrorFiedls = ['validationError', 'errorCode'];
export function parseErrorWithErrorField(action, errorField = defaultErrorFiedls[0]) {
  let error = parseError(action);
  const { data } = action;
  if (!error && data) {
    if (data[defaultErrorFiedls[0]]) error = { errorCode: 1000, data };
    else if (data[defaultErrorFiedls[1]]) error = { errorCode: data[defaultErrorFiedls[1]], data };
    else if (data[errorField]) error = { errorCode: data[errorField], data };
  }
  return error || null;
}

// type: [ deleteBusinesses().type ],
// onPending, onSuccess, onFailure 정의 함수.
export function createEntity({
  key,
  parseData: _parseData,
  parseError: _parseError,
  beforeFn,
  afterSuccessFn,
  afterFailureFn,
  changeProgress
}) {
  // Entity Action Async 객체 생성
  // VEHICLE_CREATE_REQUEST, VEHICLE_CREATE_SUCESS, VEHICLE_CREATE_FAILURE
  const entityActionAsync = createRequestTypes(key);

  // Entity Action Async
  return {
    request: (params) => action(entityActionAsync[REQUEST], params),
    success: (params, response) => action(entityActionAsync[SUCCESS], { params, response }),
    failure: (params, error) => action(entityActionAsync[FAILURE], { params, error }),
    parseError: _parseError || parseErrorWithErrorField,
    parseData: _parseData || parseData,
    beforeFn,
    afterSuccessFn,
    afterFailureFn,
    changeProgress,
    types: [entityActionAsync[REQUEST], entityActionAsync[SUCCESS], entityActionAsync[FAILURE]]
  };
}
