import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getCostItemsByDealerAPI,
  updateCostItemByDealerAPI,
  createCostItemByDealerAPI,
  deleteCostItemByDealerAPI,
  getLoadAllCostItemsByDealerAPI
} from 'v2/apis/costItemApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, confirmFetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';
import history from 'service/common/history';
import {
  getCostitemByDealerCodes,
  entityCostItemByDealerCodes,
  updateCostitemByDealerCode,
  createCostitemByDealerCode,
  deleteCostitemByDealerCodes,
  toggleCostItemByDealerModal,
  changeCostItemByDealerState,
  entityLoadAllCostItemByDealer,
  loadAllCostItemByDealer,
  entityLoadAllListCostItemByDealer, // 전체 고정비용 SelectBox 리스트 (2021.10.18)
  loadAllListCostItemByDealer // 전체 고정비용 SelectBox 리스트 (2021.10.18)
} from '.';

/** ********************************************** */
/** constant */
// export const historyUrl = '/costitembydealer';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorCostitemPage = (state) => state.costitemByDealer.list;
/** ********************************************** */

// 수정
export const entityUpdateCostitemByDealerCode = createEntity({
  key: updateCostitemByDealerCode().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
    yield put(toggleCostItemByDealerModal({ openModal: true }));
  }
});

// 등록
export const entityCreateCostitemByDealerCode = createEntity({
  key: createCostitemByDealerCode().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(toggleCostItemByDealerModal({ openModal: true }));
  }
});

// mulitple delete for list page.
export const entityDeleteCostitemCodeByDealerList = createEntity({
  key: deleteCostitemByDealerCodes().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeCostItemByDealerState({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorCostitemPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCostitemByDealerCodes(pageInfo, sortInfo, filters));
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCostitemPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCostitemByDealerCodes(pageInfo, sortInfo, filters));
  }
});

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getCostItemByDealerCodeAsync = createSagaAction.bind(
  null,
  entityCostItemByDealerCodes,
  getCostItemsByDealerAPI
);
const updateCostitemByDealerCodeAsync = createSagaAction.bind(
  null,
  entityUpdateCostitemByDealerCode,
  updateCostItemByDealerAPI
);
const createCostitemByDealerCodeAsync = createSagaAction.bind(
  null,
  entityCreateCostitemByDealerCode,
  createCostItemByDealerAPI
);
const deleteCostitemByDealerCodeListAsync = createSagaAction.bind(
  null,
  entityDeleteCostitemCodeByDealerList,
  deleteCostItemByDealerAPI
);
const loadAllCostItemByDealerAsync = createSagaAction.bind(
  null,
  entityLoadAllCostItemByDealer,
  getLoadAllCostItemsByDealerAPI
);

// 전체 고정비용 SelectBox 리스트 (2021.10.18)
const loadAllListCostItemByDealerAsync = createSagaAction.bind(
  null,
  entityLoadAllListCostItemByDealer,
  getLoadAllCostItemsByDealerAPI
);

/**
 * Watcher
 */
export default function* costItemByDealerWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    getCostitemByDealerCodes().type,
    fetchSaga,
    getCostItemByDealerCodeAsync,
    entityCostItemByDealerCodes
  );
  yield takeLatest(
    updateCostitemByDealerCode().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateCostitemByDealerCodeAsync,
    entityUpdateCostitemByDealerCode
  );
  yield takeLatest(
    createCostitemByDealerCode().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createCostitemByDealerCodeAsync,
    entityCreateCostitemByDealerCode
  );

  // deletes in list page
  yield takeLatest(
    deleteCostitemByDealerCodes().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteCostitemByDealerCodeListAsync,
    entityDeleteCostitemCodeByDealerList
  );

  // select box
  yield takeLatest(
    loadAllCostItemByDealer().type,
    fetchSaga,
    loadAllCostItemByDealerAsync,
    entityLoadAllCostItemByDealer
  );

  // 전체 고정비용 SelectBox 리스트 (2021.10.18)
  yield takeLatest(
    loadAllListCostItemByDealer().type,
    fetchSaga,
    loadAllListCostItemByDealerAsync,
    entityLoadAllListCostItemByDealer
  );
}
