import request from './request';

const prefix = '/vehicle/repair';

/**
 * 사고/수리 목록 조회
 */
export const getAccidentListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { status, defaultStatus, keywordType, keyword, startDate, endDate, ...data } = filters;
  const { field, order } = sortInfo;
  if (field === 'incidentDate' || field === 'startTime') data.sortField = 1;
  else if (field === 'endTime') data.sortField = 2;
  // else data.sortField = 3; // created_at
  data.sortOrder = order === 'desc' ? 2 : 1;
  if (keywordType && keywordType !== '' && keyword && keyword !== '') data[keywordType] = keyword;

  data.page = pageInfo && pageInfo.page ? pageInfo.page : 1;
  data.rowPerPage = pageInfo && pageInfo.limit ? pageInfo.limit : 20;
  // console.log(status, defaultStatus);
  data.byStatus =
    status && status.length
      ? status.join()
      : defaultStatus && defaultStatus.length && defaultStatus.join();

  const startDt = startDate && startDate > 0 ? new Date(startDate) : null;
  if (startDt) startDt.setHours(0, 0, 0, 0);
  data.startDate = startDt ? startDt.getTime() : undefined;
  const endDt = endDate && endDate > 0 ? new Date(endDate) : null;
  if (endDt) endDt.setHours(23, 59, 59, 999);
  data.endDate = endDt ? endDt.getTime() : undefined;

  return request({
    url: prefix,
    data
  });
};

/**
 * 사고 상세 내역 조회
 */
export const getAccidentDataAPI = (ID) => request({
    url: `${prefix}/${ID}`
  });

/**
 * 사고 등록
 */
export const createAccidentAPI = (data) => request({
    url: prefix,
    method: 'post',
    data
  });

/**
 * 사고 수정
 */
export const editAccidentAPI = (ID, data) => request({
    url: `${prefix}/${ID}`,
    method: 'patch',
    data
  });

/**
 * 사고 삭제
 */
export const deleteAccidentAPI = (ID) => request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });

/**
 * 차량 목록 조회
 */
export const getVehicleListAPI = (businessID, branchID) => request({
    url: 'monitoring/vehicle',
    method: 'get',
    data: {
      businessID,
      branchID
    }
  });

/**
 * 거래처 리스트 조회
 */
export const getCompanyListByBusinessAPI = (businessID, branchID) => {
  return request({
    url: '/company',
    data: {
      byBusinessID: businessID,
      byBranchID: branchID
    }
  });
};

/**
 * 거래처 직원 리스트 조회
 */
export const getCompanyUserListAPI = (ID) => {
  return request({
    url: `/company/${ID}/user`
  });
};

/**
 * 영업점 리스트 조회
 */
export const getBranchListAllAPI = (businessID) => request({
    url: '/branch',
    data: {
      businessID
    }
  });

/**
 * 대여 정보 연동
 */
export const getReservationInfoAPI = (data) => request({
    url: '/reservation/violation',
    data
  });
