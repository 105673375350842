import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { FMSDeviceWidth, FMSColors } from 'service/common/designLib';

export const Body = styled.div`
  display: flex;
  box-sizing: border-box;
  min-width: ${FMSDeviceWidth.medium};
  width: 100%;
  min-height: 100%;
`;

export const LNB = styled.div.attrs({ className: 'amb-lnb' })`
  position: relative;
  flex: 1;
  border-right: 1px solid #ebebeb;
  background: ${FMSColors.white};

  min-width: 240px;
  width: 240px;
  //min-height: calc(100% - 136px);
  //height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  z-index: 9;
`;

export const Container = styled.div.attrs({ className: 'amb-wrapping-container' })`
  box-sizing: border-box;
  float: left;
  width: 100%;
  background-color: #fff;
`;

export const Header = styled.div.attrs({ className: 'amb-header-container' })`
  box-sizing: border-box;
  width: 100%;
  min-width: ${FMSDeviceWidth.medium};
  background-color: ${FMSColors.white};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  height: 80px;
  margin-right: 30px;
`;

export const HeaderLogo = styled(Link).attrs({ className: 'amb-header-logo' })`
  padding: 0 30px;
  img {
    width: 155px;
    height: 31px;
  }
`;

export const HeaderGnb = styled.div`
  margin-left: auto;
  margin-right: 28px;

  div {
    flex: none;
    /* flex: 0 0 auto */
  }
  div.gnb {
    margin-left: auto;
    margin-right: 28px;
  }

  ul {
    margin: 0px;
    padding: 0px;
    position: relative;
    font-size: 18px;
  }

  ul li.split {
    padding: 0px;
    width: 1px;
    height: 12px;
    opacity: 0.4;
    border: solid 1px ${FMSColors.brownGrey};
  }

  ul li {
    display: inline-block;
    padding: 0 10px;
  }

  ul li:first-of-type {
    border-left: none;
  }

  ul li:last-child {
    padding-right: 0;
  }

  ul li a {
    font-size: 13px;
    color: ${FMSColors.brownGrey};
    text-decoration: none;
  }

  button.logout {
    margin-left: 8px;
    padding: 4px 9px;
    cursor: pointer;
    height: 24px;
    line-height: 13px;
    border-radius: 12px;
    border: solid 1px #626a70;
    background-color: #626a70;
  }
  button.logout label {
    cursor: pointer;
    height: 15px;
    opacity: 0.8;
    font-size: 12px;
    color: ${FMSColors.white};
  }
`;
export const Content = styled.div.attrs({ className: 'amb-content' })`
  width: 100%;
  max-width: calc(100% - 140px);
`;
