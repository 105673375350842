import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getCountriesAPI,
  getAllCountriesAPI,
  getAllCountryNumbersAPI,
  getCountryAPI,
  createCountryAPI,
  updateCountryAPI,
  deleteCountryAPI
} from 'v2/apis/countryApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';

import history from 'service/common/history';
import { getErrorMessage } from 'constants/errors';

import {
  createCountry,
  updateCountry,
  deleteCountry,
  getCountry,
  changeCountryPage,
  entityCountry,
  deleteForCountries,
  getCountries,
  entityCountries,
  loadAllCountries,
  entityLoadAllCountries,
  loadAllCountryNumbers,
  entityLoadAllCountryNumbers
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/country';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorCountriesPage = (state) => state.country.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateCountry = createEntity({
  key: createCountry().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Common.Created.Success');
    // license 등록
    const { ID } = payload;

    // 사업자 상세 페이지 이동
    const selectorListPage = yield select(selectorCountriesPage);
    const { pager } = selectorListPage;
    if (ID) history.replace({ pathname: `${historyUrl}/${ID}`, state: pager });
    if (ID) yield put(getCountry(ID));
  }
});

// update for update page
export const entityUpdateCountry = createEntity({
  key: updateCountry().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Common.Updated.Success');
    // license 등록
    const { ID } = requestParams || null;

    if (ID) yield put(getCountry(ID));
    if (ID) yield put(changeCountryPage({ mode: 'view' }));
  }
});
// delete for detail page
export const entityDeleteCountry = createEntity({
  key: deleteCountry().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCountriesPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForCountries = createEntity({
  key: deleteForCountries().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeCountryPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCountriesPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCountries(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorCountriesPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCountries(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createCountryAsync = createSagaAction.bind(null, entityCreateCountry, createCountryAPI);
const updateCountryAsync = createSagaAction.bind(null, entityUpdateCountry, updateCountryAPI);
const deleteCountryAsync = createSagaAction.bind(null, entityDeleteCountry, deleteCountryAPI);
const deleteForCountriesAsync = createSagaAction.bind(
  null,
  entityDeleteForCountries,
  deleteCountryAPI
);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getCountriesAsync = createSagaAction.bind(null, entityCountries, getCountriesAPI);
const getCountryAsync = createSagaAction.bind(null, entityCountry, getCountryAPI);
const loadAllCountriesAsync = createSagaAction.bind(
  null,
  entityLoadAllCountries,
  getAllCountriesAPI
);
const loadAllCountryNumbersAsync = createSagaAction.bind(
  null,
  entityLoadAllCountryNumbers,
  getAllCountryNumbersAPI
);

function* changeCountryPageSaga() {
  yield put(changeCountryPage({ mode: 'view' }));
}
function* loadAllSaga() {
  yield put(loadAllCountries());
}

/**
 * Watcher
 */
export default function* countryWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createCountry().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createCountryAsync,
    entityCreateCountry
  );
  yield takeLatest(
    updateCountry().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateCountryAsync,
    entityUpdateCountry
  );
  yield takeLatest(
    deleteCountry().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteCountryAsync,
    entityDeleteCountry
  );
  yield takeLatest(getCountries().type, fetchSaga, getCountriesAsync, entityCountries);
  yield takeLatest(getCountry().type, fetchSaga, getCountryAsync, entityCountry);

  // deletes in list page
  yield takeLatest(
    deleteForCountries().type,
    fetchSagas,
    null,
    deleteForCountriesAsync,
    entityDeleteForCountries
  );

  // select business
  yield takeLatest(
    loadAllCountries().type,
    fetchSaga,
    loadAllCountriesAsync,
    entityLoadAllCountries
  );
  yield takeLatest(
    loadAllCountryNumbers().type,
    fetchSaga,
    loadAllCountryNumbersAsync,
    entityLoadAllCountryNumbers
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityUpdateCountry.types[1], changeCountryPageSaga);
  yield takeLatest(entityUpdateCountry.types[1], loadAllSaga);
  yield takeLatest(entityCreateCountry.types[1], loadAllSaga);
  yield takeLatest(entityDeleteCountry.types[1], loadAllSaga);
}
