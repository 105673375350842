import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';

export const {
  initializeTestCarConfigPage,
  changeTestCarConfigPage,
  getTestCarConfigDetail,
  createTestCarConfig,
  updateTestCarConfig,
  deleteTestCarConfig,
  getTestCarConfig,
  initializeTestCarConfigDetailPage,
  changeTestCarConfigDetailPage
} = createActions(
  {
    GET_TEST_CAR_CONFIG_DETAIL: (ID) => ({ ID }),
    CREATE_TEST_CAR_CONFIG: (data) => ({ data }),
    UPDATE_TEST_CAR_CONFIG: (ID, data) => ({ ID, data }),
    DELETE_TEST_CAR_CONFIG: (ID) => ({ ID }),
    GET_TEST_CAR_CONFIG: (data) => ({ data })
  },
  'INITIALIZE_TEST_CAR_CONFIG_PAGE',
  'CHANGE_TEST_CAR_CONFIG_PAGE',
  'INITIALIZE_TEST_CAR_CONFIG_DETAIL_PAGE',
  'CHANGE_TEST_CAR_CONFIG_DETAIL_PAGE'
);

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityTestCarConfig = createEntity({
  key: getTestCarConfig().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityTestCarConfigDetail = createEntity({
  key: getTestCarConfigDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: null,
    loading: true,
    listMode: 'edit',
    error: null
  },

  detail: {
    data: null,
    loading: true,
    mode: 'edit',
    error: null
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const testCarConfigPageReducer = handleActions(
  {
    [initializeTestCarConfigPage().type]: () => initializeState.list,
    [changeTestCarConfigPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

const testCarConfigPageDetailReducer = handleActions(
  {
    [initializeTestCarConfigDetailPage().type]: () => initializeState.detail,
    [changeTestCarConfigDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const testCarConfig = combineReducers({
  list: createReducer({
    entity: entityTestCarConfig,
    reducer: testCarConfigPageReducer
  }),
  detail: createReducer({
    entity: entityTestCarConfigDetail,
    reducer: testCarConfigPageDetailReducer
  })
});
/** ********************************************** */
