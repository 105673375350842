import { put, select, takeLatest } from 'redux-saga/effects';
import {
  getViolationListAPI,
  getViolationDetailAPI,
  createViolationAPI,
  linkReseravtionAPI,
  deleteViolationAPI,
  updateViolationAPI,
  downloadDocAPI,
  getVehicleListForViolationAPI
} from 'v2/apis/violationApi';
import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';
import FileSaver from 'file-saver';
import history from 'service/common/history';
import {
  getViolationList,
  entityViolationList,
  changeViolationList,
  getViolationDetail,
  entityViolationDetail,
  createViolation,
  changeViolationDetail,
  getLinkReservation,
  entityLinkReservation,
  deleteViolation,
  deleteViolationList,
  updateViolation,
  downloadPdf,
  getVehicleListForViolation,
  entityVehicleListForViolation
} from '.';

export const historyUrl = '/violation';
export const selectorViolationListPage = (state) => state.violation.list;

export const entityCreateViolation = createEntity({
  key: createViolation().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    yield put(changeViolationDetail({ mode: 'view' }));
    const selectorListPage = yield select(selectorViolationListPage);
    const { pager } = selectorListPage;
    const { docID } = payload;
    history.replace({ pathname: `${historyUrl}/${docID}`, state: pager });
    yield put(getViolationDetail(docID));
  }
});

export const entityDeleteViolation = createEntity({
  key: deleteViolation().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorViolationListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

export const entityDeleteViolationList = createEntity({
  key: deleteViolationList().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeViolationList({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorViolationListPage);
    const {
      pager: { filters, pageInfo, sortInfo }
    } = selectorListPage;
    yield put(getViolationList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorViolationListPage);
    const {
      pager: { filters, pageInfo, sortInfo }
    } = selectorListPage;
    yield put(getViolationList(pageInfo, sortInfo, filters));
  }
});

export const entityUpdateViolation = createEntity({
  key: updateViolation().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { data } = requestParam;
    yield put(getViolationDetail(data.docID));
  }
});

export const entityDownloadPdf = createEntity({
  key: downloadPdf().type,
  afterSuccessFn(payload) {
    FileSaver.saveAs(payload, '이의신청서.pdf');
  }
});

const getViolationListAsync = createSagaAction.bind(null, entityViolationList, getViolationListAPI);
const getViolationDetailAsync = createSagaAction.bind(
  null,
  entityViolationDetail,
  getViolationDetailAPI
);
const createViolationAsync = createSagaAction.bind(null, entityCreateViolation, createViolationAPI);
const getLinkReservationAsync = createSagaAction.bind(
  null,
  entityLinkReservation,
  linkReseravtionAPI
);
const deleteViolationAsync = createSagaAction.bind(null, entityDeleteViolation, deleteViolationAPI);
const deleteViolationListAsync = createSagaAction.bind(
  null,
  entityDeleteViolationList,
  deleteViolationAPI
);
const updateViolationAsync = createSagaAction.bind(null, entityUpdateViolation, updateViolationAPI);
const downloadPdfAsync = createSagaAction.bind(null, entityDownloadPdf, downloadDocAPI);
const getVehicleListForViolationAsync = createSagaAction.bind(
  null,
  entityVehicleListForViolation,
  getVehicleListForViolationAPI
);

function* changeViolationDetailAfterUpdate() {
  yield put(changeViolationDetail({ mode: 'view' }));
}

export default function* violation() {
  yield takeLatest(getViolationList().type, fetchSaga, getViolationListAsync, entityViolationList);
  yield takeLatest(
    getViolationDetail().type,
    fetchSaga,
    getViolationDetailAsync,
    entityViolationDetail
  );
  yield takeLatest(
    createViolation().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createViolationAsync,
    entityCreateViolation
  );
  yield takeLatest(
    getLinkReservation().type,
    fetchSaga,
    getLinkReservationAsync,
    entityLinkReservation
  );
  yield takeLatest(
    deleteViolation().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteViolationAsync,
    entityDeleteViolation
  );
  yield takeLatest(
    deleteViolationList().type,
    fetchSagas,
    null,
    deleteViolationListAsync,
    entityDeleteViolationList
  );
  yield takeLatest(
    updateViolation().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateViolationAsync,
    entityUpdateViolation
  );
  yield takeLatest(downloadPdf().type, fetchSaga, downloadPdfAsync, entityDownloadPdf);
  yield takeLatest(
    getVehicleListForViolation().type,
    fetchSaga,
    getVehicleListForViolationAsync,
    entityVehicleListForViolation
  );

  yield takeLatest(entityUpdateViolation.types[1], changeViolationDetailAfterUpdate);
}
