import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import { getErrorMessage } from 'constants/errors';
import { select, put } from 'redux-saga/effects';

export const {
  initializePaymentList,
  getPaymentList,
  changePaymentList,
  initializePaymentDetail,
  getPaymentDetail,
  initializeTimeList,
  getTimeList,
  processPayment,
  initializePaymentListInfo
} = createActions(
  {
    GET_PAYMENT_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_PAYMENT_DETAIL: (ID) => ({ ID }),
    GET_TIME_LIST: (vehicleID, baseDay) => ({ vehicleID, baseDay }),
    PROCESS_PAYMENT: (data, referer, ID) => ({ data, referer, ID })
  },
  'INITIALIZE_PAYMENT_LIST',
  'CHANGE_PAYMENT_LIST',
  'INITIALIZE_PAYMENT_DETAIL',
  'INITIALIZE_TIME_LIST',
  'INITIALIZE_PAYMENT_LIST_INFO'
);

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      filters: {
        startDate: new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
        endDate: new Date().getTime(),
        keywordType: 'licensePlateNumber',
        keyword: null,
        reservationType: null
      },
      pageInfo: {
        page: 1,
        limit: PAGE_LIMIT
      },
      sortInfo: {
        field: 'creatAt',
        order: 'desc'
      }
    }
  },
  detail: {
    data: {},
    loading: false
  },
  timeList: {
    data: [],
    loading: false
  }
};

const selectorPaymentPage = (state) => state.payment.list;
const selectorPaymentDetailPage = (state) => state.payment.detail;

export const entityProcessPayment = createEntity({
  key: processPayment().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Payment.Pay.Fail');
  },
  *afterSuccessFn({ paymentStatus }, payload) {
    if (_.isEmpty(paymentStatus)) {
      FMSCommon.toast.fail('Payment.Pay.Fail');
    } else {
      FMSCommon.toast.success('Reservation.Pay.Success', () => {}, {
        total: paymentStatus.length,
        success: paymentStatus.map((item) => {
          return item === 'success';
        }).length
      });
      if (payload.referer === 'list') {
        const selectorListPage = yield select(selectorPaymentPage);
        const {
          pager: { pageInfo, sortInfo, filters }
        } = selectorListPage;
        yield put(getPaymentList(filters, pageInfo, sortInfo));
      } else if (payload.referer === 'detail') {
        yield put(getPaymentDetail(payload.ID));
      }
    }
  }
});

export const entityPaymentList = createEntity({
  key: getPaymentList().type,
  parseData: (action) => parseDataForList(action, 'reservationItems'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityPaymentDetail = createEntity({
  key: getPaymentDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityTimeList = createEntity({
  key: getTimeList().type,
  parseData: (action) => parseDataForList(action, 'reservationItems'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const paymentListReducer = handleActions(
  {
    [initializePaymentList().type]: () => initializeState.list,
    [initializePaymentListInfo().type]: (state) => {
      return { ...state, data: [] };
    },
    [changePaymentList().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const paymentDetailReducer = handleActions(
  {
    [initializePaymentDetail().type]: () => initializeState.detail
  },
  {},
  { produce }
);
const timeListReducer = handleActions(
  {
    [initializeTimeList().type]: () => initializeState.timeList
  },
  {},
  { produce }
);

export const payment = combineReducers({
  list: createReducer({
    entity: entityPaymentList,
    reducer: paymentListReducer
  }),
  detail: createReducer({
    entity: entityPaymentDetail,
    reducer: paymentDetailReducer
  }),
  timeList: createReducer({
    entity: entityTimeList,
    reducer: timeListReducer
  })
});
