import request from 'apis/request';

const prefix = '/notification';

function getNotificationortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
  }
}
export const getNotificationListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page, limit } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getNotificationortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};
