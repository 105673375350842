import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import FMSLoader from 'components/ui/common/FMSLoader';
import HomeUnapprovePage from '../HomeUnapprovePage';
import HomeAnonymousPage from '../HomeAnonymousPage';
import HomeMainPage from '../HomeMainPage';

const HomeIndex = ({ profileStatus, loading, ...props }) => {
  const renderComponet = useCallback(() => {
    let MainComponent = null;
    switch (profileStatus) {
      case 'PENDING_APPROVE':
      case 'DENY': {
        MainComponent = <HomeUnapprovePage {...props} />;
        break;
      }
      case 'NORMAL': {
        MainComponent = loading ? null : <HomeMainPage {...props} />;
        break;
      }
      default: {
        MainComponent = loading ? <FMSLoader loading /> : <HomeAnonymousPage />;
        break;
      }
    }

    return MainComponent;
  }, [profileStatus, loading]);

  // return <Route exact="false" component={renderComponet()} history={history} />
  return renderComponet();
};

export default withRouter(React.memo(HomeIndex));
