import request from 'apis/request';

const prefix = '/company';

function getCompnaySortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/*
  필요한 api
  1. pageList 데이터 조회
  2. 등록하는 부분
  3. 검색해서 pageList 데이터 조회
  4. 등록페이지에서 저장
  5. 취소
*/

// checkbox를 위한 초기화??
export const getClearComapnyAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 거래처 전체 리스트 조회
 */
// redux saga
// export const getCompanyListAllAPI = ({ filters = {}, pageInfo = {}, sortInfo = {}, ID })  => {
//   const { page = 1, limit = 20 } = pageInfo;
//   const { field = 'createdAt', order = 'desc' } = sortInfo;
//   const {
//     // status, defaultStatus, keywordType, keyword, startDate, endDate,
//     ...data
//   } = filters;

//   data.page = page;
//   data.rowPerPage = limit;
//   data.sortOrder = (order === 'desc') ? 2 : 1;
//   data.sortField = getCompnaySortFieldNum(field);
//   data.businessID = ID;

//   return request({
//     url: `${prefix}`,
//     data,
//   });
// };

/**
 * 거래처 리스트 조회(20개 한정)
 */
export const getCompanyListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page, limit } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    byCompanyType,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getCompnaySortFieldNum(field);

  if (byCompanyType) {
    data.byCompanyType = byCompanyType;
  } else {
    data.byCompanyType = '1,2';
  }

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 보험사 리스트 조회
 */
export const getInsuranceCompanyListAPI = ({ businessID, branchID }) => {
  const data = {};
  if (businessID) {
    data.byBusinessID = businessID;
  }
  if (branchID) {
    data.byBranchID = branchID;
  }
  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 거래처 상세 조회
 */

// redux saga
export const getCompanyDetailAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};

// LOADING을 위한...??? (loadAllBusinessesAsync)
// export const getAllCompanyAPI = ({ filters = {} })  => {
//   const {
//     // status, defaultStatus, keywordType,
//      keyword, startDate, endDate,
//     ...data
//   } = filters;

//   return request({
//     url: `${prefix}List`,
//     data,
//   });
// };

/**
 * 거래처 등록 기능
 */
export const createCompanyAPI = (inputData) => {
  return request({
    url: prefix,
    method: 'post',
    data: inputData.data
  });
};
/**
 * 거래처 수정 기능
 */
export const updateCompanyAPI = (inputData) => {
  return request({
    url: `${prefix}/${inputData.ID}`,
    method: 'patch',
    data: inputData.data
  });
};

/**
 * 거래처 삭제 기능
 */
export const deleteCompanyAPI = ({ IDArr }) => {
  // console.log('IDArr : ', IDArr);
  return Promise.all(
    IDArr.map((ID) => {
      return request({
        method: 'delete',
        url: `${prefix}/${ID}`
      });
    })
  );
};
