import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { put } from 'redux-saga/effects';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import { initializeProgress } from '../reservation';

export const {
  initializeInsuranceGroupNameDuplicate,
  initializeInsuranceListPage,
  getInsuranceList,
  deleteForInsuranceList,
  initializeInsuranceDetailPage,
  getInsuranceDetail,
  changeInsuranceDetailPage,
  initializeVehicleInsurancePage,
  getVehicleInsurance,
  initializeVehiclePage,
  getVehicleAll,
  createInsurance,
  updateInsurance,
  deleteInsurance,
  changeInsuranceListPage,
  initializeInsuranceForVehiclePage,
  getInsuranceForVehicle,
  changeInsuranceVehicleList,
  deleteInsuranceVehicle,
  getMileageListByVehicleId,
  checkInsuranceGroupNameDuplicate,
  initializeUploadFile,
  getUploadFile,
  createFile,
  changeResultStatus,
  deleteFile,
  changeProgress
} = createActions(
  {
    GET_INSURANCE_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_INSURANCE_DETAIL: (ID) => ({ ID }),
    GET_VEHICLE_INSURANCE: (ID) => ({ ID }),
    GET_VEHICLE_All: (ID, branchID) => ({ ID, branchID }),
    CREATE_INSURANCE: (data, file) => ({ data, file }),
    UPDATE_INSURANCE: (ID, data, file) => ({ ID, data, file }),
    DELETE_INSURANCE: (groupID) => ({ groupID }),
    GET_INSURANCE_FOR_VEHICLE: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_MILEAGE_LIST_BY_VEHICLE_ID: (businessId, vehicleId, startTime, endTime) => ({
      businessId,
      vehicleId,
      startTime,
      endTime
    }),
    CHECK_INSURANCE_GROUP_NAME_DUPLICATE: (businessID, groupID, name) => ({
      businessID,
      groupID,
      name
    }),
    GET_UPLOAD_FILE: (insuranceGroupID) => ({ insuranceGroupID }),
    CREATE_FILE: (insuranceGroupID, file) => ({ insuranceGroupID, file }),
    DELETE_FILE: (insuranceGroupID, pdfID) => ({ insuranceGroupID, pdfID })
  },
  'INITIALIZE_INSURANCE_GROUP_NAME_DUPLICATE',
  'INITIALIZE_INSURANCE_LIST_PAGE',
  'DELETE_FOR_INSURANCE_LIST',
  'INITIALIZE_INSURANCE_DETAIL_PAGE',
  'CHANGE_INSURANCE_DETAIL_PAGE',
  'INITIALIZE_VEHICLE_INSURANCE_PAGE',
  'INITIALIZE_VEHICLE_PAGE',
  'CHANGE_INSURANCE_LIST_PAGE',
  'INITIALIZE_INSURANCE_FOR_VEHICLE_PAGE',
  'CHANGE_INSURANCE_VEHICLE_LIST',
  'DELETE_INSURANCE_VEHICLE',
  'INITIALIZE_UPLOAD_FILE',
  'CHANGE_RESULT_STATUS',
  'CHANGE_PROGRESS'
);

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    },
    businessID: null,
    status: '/insurance'
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create'
  },
  vehicleInsuranceList: {
    data: []
  },
  vehicleList: {
    data: []
  },
  insuranceForVehicleList: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  progress: {
    progressLoading: false
  },
  mileageListByVehicleId: {
    data: {}
  },
  duplicate: {
    data: false,
    loading: false
  },
  uploadFile: {
    data: {},
    resultStatus: 'READY'
  }
};

export const entityUploadFile = createEntity({
  key: getUploadFile().type
});

export const entityCheckInsuranceGroupNameDuplicate = createEntity({
  key: checkInsuranceGroupNameDuplicate().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Alert');
  },
  afterSuccessFn(payload) {
    const { isDuplicate } = payload;
    if (isDuplicate) {
      FMSCommon.toast.fail('InsuranceGroupName.Duplicate.Fail');
    } else {
      FMSCommon.toast.success('InsuranceGroupName.Duplicate.Success');
    }
  }
});

export const entityInsuranceList = createEntity({
  key: getInsuranceList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityInsuranceDetail = createEntity({
  key: getInsuranceDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityVehicleInsurance = createEntity({
  key: getVehicleInsurance().type,
  *changeProgress(progressLoading) {
    yield put(changeProgress({ progressLoading }));
  },
  parseData: (action) => parseDataForList(action, 'items'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityVehicle = createEntity({
  key: getVehicleAll().type,
  parseData: (action) => parseDataForList(action, 'vehicles'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityInsuranceForVehicle = createEntity({
  key: getInsuranceForVehicle().type,
  parseData: (action) => parseDataForList(action, 'charge_vehicle_items'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityMileageListByVehicleId = createEntity({
  key: getMileageListByVehicleId().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const insuranceListPageReducer = handleActions(
  {
    [initializeInsuranceListPage().type]: () => initializeState.list,
    [changeInsuranceListPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const insuranceDetailReducer = handleActions(
  {
    [initializeInsuranceDetailPage().type]: () => initializeState.detail,
    [changeInsuranceDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const vehicleInsurancePageReducer = handleActions(
  {
    [initializeVehicleInsurancePage().type]: () => initializeState.vehicleInsuranceList,
    [changeInsuranceVehicleList().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const vehiclePageReducer = handleActions(
  {
    [initializeVehiclePage().type]: () => initializeState.vehicleList
  },
  {},
  { produce }
);
const insuranceForVehiclePageReducer = handleActions(
  {
    [initializeInsuranceForVehiclePage().type]: () => initializeState.insuranceForVehicleList
  },
  {},
  { produce }
);

const insuranceGroupNameDuplicateReducer = handleActions(
  {
    [initializeInsuranceGroupNameDuplicate().type]: () => initializeState.duplicate
  },
  {},
  { produce }
);

const uploadFileReducer = handleActions(
  {
    [initializeUploadFile().type]: () => initializeState.uploadFile,
    [changeResultStatus().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

export const insurance = combineReducers({
  list: createReducer({
    entity: entityInsuranceList,
    reducer: insuranceListPageReducer
  }),
  detail: createReducer({
    entity: entityInsuranceDetail,
    reducer: insuranceDetailReducer
  }),
  vehicleInsuranceList: createReducer({
    entity: entityVehicleInsurance,
    reducer: vehicleInsurancePageReducer
  }),
  vehicleList: createReducer({
    entity: entityVehicle,
    reducer: vehiclePageReducer
  }),
  insuranceForVehicleList: createReducer({
    entity: entityInsuranceForVehicle,
    reducer: insuranceForVehiclePageReducer
  }),
  progress: handleActions(
    {
      [initializeProgress().type]: () => initializeState.progress,
      [changeProgress().type]: (state, { payload }) => ({
        ...state,
        ...payload
      })
    },
    initializeState.progress,
    { produce }
  ),
  mileageListByVehicleId: createReducer({
    entity: entityMileageListByVehicleId
  }),
  duplicate: createReducer({
    entity: entityCheckInsuranceGroupNameDuplicate,
    reducer: insuranceGroupNameDuplicateReducer
  }),
  uploadFile: createReducer({
    entity: entityUploadFile,
    reducer: uploadFileReducer
  })
});
