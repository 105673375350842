import fp from 'v2/lib/lodashFp';

/**
 * 딜러쉽 직원 여부
 * 참고) 딜러쉽 직원은 branch를 항상 가지고 있어서, branch 체크 시 제외 되어야 된다.
 * @param {*} profile redux store/authentication.profile.data
 * @returns 딜러쉽 직원 여부
 */
export const isOperator = (profile) => {
  const composer = fp.pipe(
    fp.get('role'),
    (role) => fp.startsWith('PUBLIC_', role) || fp.startsWith('BRANCH_', role)
  );
  const result = composer(profile);

  return result;
};

/**
 * 개발용 tap log
 */
export const tap = fp.tap(console.log);

/**
 * 러시아 분기처리 변수
 */
export const isRu = fp.equals('RU', process.env.FMS_SERVICE_COUNTRY_CODE);

/**
 * 지도 기본 위도 ,경도
 * @returns {latitude, longitude}
 */
const getDefaultGeoData = () => {
  // 밀라노 좌표
  let latitude = 45.4642;
  let longitude = 9.19;

  if (isRu) {
    // 러시아 Russia, Moscow, Nikoloyamskaya
    latitude = 55.747495;
    longitude = 37.652634;
  }

  return { latitude, longitude };
};

/**
 * 기본 지도 위도 ,경도 변수
 */
export const defaultGeoData = getDefaultGeoData();

/**
 * 이미지 없을때 대체 이미지 base64 코드
 */
export const substituteImage =
  'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTAgMGg2NHY2NEgweiIvPgogICAgICAgIDxnIHN0cm9rZT0iIzk2OTVBQSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQ2Ljc3OCA0NS44ODlIMTcuMjIyYTMuMTEyIDMuMTEyIDAgMCAxLTMuMTEtMy4xMTFWMjIuNTU2YTMuMTEyIDMuMTEyIDAgMCAxIDMuMTEtMy4xMTJoMjkuNTU2YTMuMTEyIDMuMTEyIDAgMCAxIDMuMTEgMy4xMTJ2MjAuMjIyYTMuMTEyIDMuMTEyIDAgMCAxLTMuMTEgMy4xMXoiLz4KICAgICAgICAgICAgPHBhdGggZD0iTTQ2LjY4NyAyNS4yNzhhMy4xMTIgMy4xMTIgMCAwIDEtNi4yMjMgMCAzLjExMiAzLjExMiAwIDAgMSA2LjIyMyAwek0xNC4xMTEgMzYuMjk2bDguNDE4LTExLjAxOE00MC41NTUgNDUuODg5TDIyLjUzIDI1LjI3OE0zMC45NDcgMzQuMDkzbDQuMjEtNC40MDhNNDcuNzg1IDQ1LjExTDM1LjE1NyAyOS42ODYiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=';
