import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeBusinessCustomersPage,
  changeBusinessCustomersPage,
  getBusinessCustomers,
  deleteForBusinessCustomers,
  initializeBusinessCustomerPage,
  changeBusinessCustomerPage,
  getBusinessCustomer,
  updateBusinessCustomer,
  deleteBusinessCustomer,
  confirmBusinessCustomer,
  denyBusinessCustomer,
  confirmAllBusinessCustomer,
  downloadBusinessCustomer
} = createActions(
  {
    GET_BUSINESS_CUSTOMERS: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_BUSINESS_CUSTOMER: (ID) => ({ ID }),
    UPDATE_BUSINESS_CUSTOMER: (ID, data) => ({ ID, data }),
    CONFIRM_BUSINESS_CUSTOMER: (ID, data) => ({ ID, data }),
    DELETE_BUSINESS_CUSTOMER: (ID) => ({ ID }),
    DENY_BUSINESS_CUSTOMER: (ID, data) => ({ ID, data }),
    CONFIRM_ALL_BUSINESS_CUSTOMER: (IDArr, message) => ({ IDArr, message })
  },
  'INITIALIZE_BUSINESS_CUSTOMERS_PAGE',
  'CHANGE_BUSINESS_CUSTOMERS_PAGE',
  'DELETE_FOR_BUSINESS_CUSTOMERS',
  'INITIALIZE_BUSINESS_CUSTOMER_PAGE',
  'CHANGE_BUSINESS_CUSTOMER_PAGE',
  'DOWNLOAD_BUSINESS_CUSTOMER'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityBusinessCustomers = createEntity({
  key: getBusinessCustomers().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityBusinessCustomer = createEntity({
  key: getBusinessCustomer().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  detail: {
    data: null,
    loading: false,
    mode: 'view'
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const listPageReducer = handleActions(
  {
    [initializeBusinessCustomersPage().type]: () => initializeState.list,
    [changeBusinessCustomersPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const detailPageReducer = handleActions(
  {
    [initializeBusinessCustomerPage().type]: () => initializeState.detail,
    [changeBusinessCustomerPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const businessCustomer = combineReducers({
  list: createReducer({
    entity: entityBusinessCustomers,
    reducer: listPageReducer
  }),
  detail: createReducer({
    entity: entityBusinessCustomer,
    reducer: detailPageReducer
  })
});
/** ********************************************** */
