import { combineReducers } from 'redux';
import { createActions } from 'redux-actions';
import { createEntityNew, createReducerNew, fetchSagaNew } from 'v2/redux/lib/reducerLibNew';
import { takeLatest } from 'redux-saga/effects';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import {
  getAccessoryCategoryListAPI,
  getAccessoryCategoryAPI,
  createAccessoryCategoryAPI,
  updateAccessoryCategoryAPI,
  deleteAccessoryCategoryAPI,
  getAccessoryDealerCategoryListAPI,
  getAccessoryCategoryListForItemsAPI,
  getAccessoryCategoryListAllAPI
} from 'v2/apis/accessoryCategoryApi';
import { getErrorMessage } from 'constants/errors';

export const {
  getAccessoryCategoryList,
  getAccessoryCategoryListForItems,
  getAccessoryCategoryDetail,
  createAccessoryCategory,
  updateAccessoryCategory,
  deleteAccessoryCategory,
  getAccessoryDealerCategoryList,
  getAccessoryCategoryListAll
} = createActions({
  GET_ACCESSORY_CATEGORY_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
  GET_ACCESSORY_CATEGORY_LIST_FOR_ITEMS: (countryId, businessId, branchId, categoryType) => ({
    countryId,
    businessId,
    branchId,
    categoryType
  }),
  GET_ACCESSORY_CATEGORY_DETAIL: (ID) => ({ ID }),
  CREATE_ACCESSORY_CATEGORY: (data) => ({ data }),
  UPDATE_ACCESSORY_CATEGORY: (ID, data) => ({ ID, data }),
  DELETE_ACCESSORY_CATEGORY: (data) => {
    return data;
  },
  GET_ACCESSORY_DEALER_CATEGORY_LIST: (
    countryId,
    businessId,
    branchId,
    vehicleId,
    startDate,
    endDate
  ) => ({
    countryId,
    businessId,
    branchId,
    vehicleId,
    startDate,
    endDate
  }),
  GET_ACCESSORY_CATEGORY_LIST_ALL: (filters) => ({ filters })
});

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  detail: {
    data: {},
    mode: 'view' // view, edit, create
  },
  create: { apiState: '' },
  update: { apiState: '' },
  delete: { apiState: '' },
  dealerList: {
    data: []
  },
  itemsList: {
    data: []
  },
  loadAll: {
    data: [],
    loading: false,
    pager: {
      filters: {}
    }
  }
};

const entityCategoryList = createEntityNew({
  key: getAccessoryCategoryList().type,
  apiFn: getAccessoryCategoryListAPI,
  defaultState: initializeState.list,
  parseData: (action) => parseDataForList(action, 'categories'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const entityCategoryDetail = createEntityNew({
  key: getAccessoryCategoryDetail().type,
  apiFn: getAccessoryCategoryAPI,
  defaultState: initializeState.detail,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const entityCreateCategory = createEntityNew({
  key: createAccessoryCategory().type,
  apiFn: createAccessoryCategoryAPI,
  defaultState: initializeState.create,
  confirmMessage: 'Common.Confirm.Create',
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
  }
});

const entityUpdateCategory = createEntityNew({
  key: updateAccessoryCategory().type,
  apiFn: updateAccessoryCategoryAPI,
  defaultState: initializeState.update,
  confirmMessage: 'Common.Confirm.Update',
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
  }
});

const entityDeleteCategory = createEntityNew({
  key: deleteAccessoryCategory().type,
  apiFn: deleteAccessoryCategoryAPI,
  defaultState: initializeState.delete,
  confirmMessage: 'Common.Confirm.Delete',
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
  }
});

const entityDealerCategoryList = createEntityNew({
  key: getAccessoryDealerCategoryList().type,
  apiFn: getAccessoryDealerCategoryListAPI,
  defaultState: initializeState.list,
  parseData: (action) => parseDataForList(action, 'categories'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('ERROR_CODE_INTERNAL_SERVER_ERROR');
  }
});

const entityCategoryListForItems = createEntityNew({
  key: getAccessoryCategoryListForItems().type,
  apiFn: getAccessoryCategoryListForItemsAPI,
  defaultState: initializeState.list,
  parseData: (action) => parseDataForList(action, 'categories'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('ERROR_CODE_INTERNAL_SERVER_ERROR');
  }
});

// 2021.11.17 카테고리 목록 전체 조회(카테고리 SELECTBOX )
const entityCategoryListAll = createEntityNew({
  key: getAccessoryCategoryList().type,
  apiFn: getAccessoryCategoryListAllAPI,
  defaultState: initializeState.loadAll,
  parseData: (action) => parseDataForList(action, 'categories')
  // afterFailureFn: () => {
  //   FMSCommon.toast.fail('Common.Msg.NoData');
  // }
});

export const accessoryCategory = combineReducers({
  list: createReducerNew(entityCategoryList),
  detail: createReducerNew(entityCategoryDetail),
  create: createReducerNew(entityCreateCategory),
  update: createReducerNew(entityUpdateCategory),
  delete: createReducerNew(entityDeleteCategory),
  dealerList: createReducerNew(entityDealerCategoryList),
  itemsList: createReducerNew(entityCategoryListForItems),
  loadAll: createReducerNew(entityCategoryListAll)
});

export default function* watcher() {
  yield takeLatest(getAccessoryCategoryList().type, fetchSagaNew, entityCategoryList);
  yield takeLatest(getAccessoryCategoryDetail().type, fetchSagaNew, entityCategoryDetail);
  yield takeLatest(createAccessoryCategory().type, fetchSagaNew, entityCreateCategory);
  yield takeLatest(updateAccessoryCategory().type, fetchSagaNew, entityUpdateCategory);
  yield takeLatest(deleteAccessoryCategory().type, fetchSagaNew, entityDeleteCategory);
  yield takeLatest(getAccessoryDealerCategoryList().type, fetchSagaNew, entityDealerCategoryList);
  yield takeLatest(
    getAccessoryCategoryListForItems().type,
    fetchSagaNew,
    entityCategoryListForItems
  );
  yield takeLatest(getAccessoryCategoryListAll().type, fetchSagaNew, entityCategoryListAll);
}
