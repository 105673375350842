import * as pages from 'containers/pages';

const unapprovedRoutes = [
  {
    path: '/mypage',
    component: pages.ProfileDetailPage,
    title: 'Menu.MyPage' // 마이페이지 (전체)
  },
  {
    path: '/',
    component: pages.ProfileDetailPage,
    title: 'Common.Login' // 마이페이지 (전체)
  }
];

export default unapprovedRoutes;
