import React, { useCallback, useEffect, useState, memo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { initializeLoadAllBusinesses, loadAllBusinesses } from 'v2/redux/modules/business';
import { initializeMyBusiness } from 'v2/redux/modules/authentication';

import FMSDataSelect from 'v2/components/pages/form/FMSDataSelect';

const stateSelector = createSelector(
  (store) => store.country,
  (store) => store.business,
  (country, business) => ({
    countryLoading: fp.getOr(false, 'loadAll.loading', country),
    loading: fp.getOr(false, 'loadAll.loading', business),
    data: fp.getOr([], 'loadAll.data', business)
  })
);
const BusinessSelectContainer = ({ filters, selectedValue, onChangeValue, ...props }) => {
  const { loading, data, countryLoading } = useSelector(stateSelector, fp.equals);
  const dispatch = useDispatch();
  const [prevFilter, setPrevFilter] = useState({});

  const getAllBusinesses = useCallback((filters) => dispatch(loadAllBusinesses(filters)), [
    dispatch
  ]);

  useEffect(() => {
    const cleanUp = () => {
      dispatch(initializeLoadAllBusinesses());
      dispatch(initializeMyBusiness());
    };

    return cleanUp;
  }, []);

  useEffect(() => {
    if (fp.notEquals(prevFilter, filters)) {
      setPrevFilter(filters);

      const nextCountryID = fp.get('countryID', filters);
      if (
        fp.isNotEmpty(nextCountryID)
        && fp.notEquals(fp.get('countryID', prevFilter), nextCountryID) // countryID 변경여부 확인
      ) {
        getAllBusinesses(filters);
      }
    }
  }, [getAllBusinesses, filters.countryID]);

  const getSelectedValue = useCallback(
    fp.curry((selectedValue, data) => {
      const allBusinessIDs = fp.map(fp.get('ID'), data);

      if (
        // 선택된 비즈니스가 없거나, 비즈목록이 갱신된 경우
        (fp.isEmpty(selectedValue) || fp.notIncludes(selectedValue, allBusinessIDs))
        && fp.isNotEmpty(data)
      ) {
        return fp.head(data);
      }

      if (fp.isNotEmpty(selectedValue) && fp.includes(selectedValue, allBusinessIDs)) {
        // return _.filter(data, (ele) => ele.ID === selectedValue)[0]; // 나의 사업자 수정 후  business 선택값 사라지는 오류 추 후  수정할부분
        return selectedValue;
      }
    }),
    []
  );

  useEffect(() => {
    if (fp.isFunction(onChangeValue)) {
      onChangeValue({ meta: getSelectedValue(selectedValue, data) });
    }
  }, [data]);

  return (
    <>
      <FMSDataSelect
        data={data}
        loading={loading || countryLoading}
        // selectedValue={getSelectedValue(selectedValue, data)?.ID} // 나의 사업자 수정 후  business 선택값 사라지는 오류 추 후  수정할부분
        selectedValue={getSelectedValue(selectedValue, data)}
        onChangeValue={onChangeValue}
        {...props}
      />
    </>
  );
};

export default memo(BusinessSelectContainer);
