import styled, { css } from 'styled-components';

import { FMSColors } from 'service/common/designLib';

export const FMSImage = styled.div`
  vertical-align: middle;
  background: url(${(props) => props.imageURL}) no-repeat center;
  background-size: contain;
`;

/** 차량 상태를 표시하는 오른쪽만 둥글린 아이콘 */
export const FMSIconHalfRound = styled.div`
  display: inline-block;
  font-size: 12px;
  color: ${FMSColors.white};
  min-width: 60px;
  padding: 0 4px
  height: 24px;
  line-height: 24px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  text-align: center;

  ${({ colorType, vehicleStatus }) => {
    if (colorType) {
      if (colorType === 'blue') {
        return css`
          background-color: ${FMSColors.blue};
        `;
      }
      if (colorType === 'orange') {
        return css`
          background-color: #ffaf00;
        `;
      }
      if (colorType === 'grey') {
        return css`
          background-color: #999999;
        `;
      }
      if (colorType === 'red') {
        return css`
          background-color: #f75454;
        `;
      }
    }
    if (vehicleStatus) {
      if (vehicleStatus === 'IN_USE') {
        return css`
          background-color: ${FMSColors.blue};
        `;
      }
      if (vehicleStatus === 'WAIT') {
        return css`
          background-color: ${FMSColors.purple};
        `;
      }
      if (vehicleStatus === 'LOW_VOLTAGE') {
        return css`
          background-color: ${FMSColors.yellow};
        `;
      }
      if (vehicleStatus === 'NOT_RETURNED') {
        return css`
          background-color: ${FMSColors.danger};
        `;
      }
      return css`
        background-color: ${FMSColors.brownGrey};
      `;
    }
  }};
`;

/** 왼쪽만 둥글린 아이콘 */
export const FMSIconLeftRound = styled.div`
  display: inline-block;
  font-size: 12px !important;
  color: ${FMSColors.primary};
  background-color: #fbfbfb;
  min-width: 40px;
  padding: 0 4px;
  height: 24px;
  line-height: 24px;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  text-align: center;
`;

/** 둥근 모양의 태그 ex : 대표모델 */
export const FMSIconRound = styled.div`
  display: inline-block;
  color: ${FMSColors.primary};
  background-color: #fbfbfb;
  ${(props) => (props.width ? `width: ${props.width};` : 'min-width: 60px;')}
  padding: 0 4px;
  ${(props) => props.height
      ? `height: ${props.height}; line-height: ${props.height} !important;`
      : 'height: 24px; line-height: 24px;'}
  border-radius: 11px;
  text-align: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px !important')};

  ${({ colorType }) => {
    if (colorType === 'blue') {
      return css`
        color: ${FMSColors.white};
        background-color: ${FMSColors.blue};
      `;
    }
    if (colorType === 'orange') {
      return css`
        color: ${FMSColors.white};
        background-color: #ffaf00;
      `;
    }
    if (colorType === 'grey') {
      return css`
        color: ${FMSColors.white};
        background-color: #999999;
      `;
    }
    if (colorType === 'red') {
      return css`
        color: ${FMSColors.white};
        background-color: #f75454;
      `;
    }
  }};
`;

export const FMSStatusCircle = styled.div`
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.vehicleStatus === 'IN_USE'
      ? FMSColors.blue
      : props.vehicleStatus === 'WAIT'
      ? '#7e5fd4'
      : props.vehicleStatus === 'LOW_VOLTAGE'
      ? FMSColors.yellow
      : props.vehicleStatus === 'NOT_RETURNED'
      ? FMSColors.danger
      : FMSColors.brownGrey};
  margin-right: 5px;
`;
