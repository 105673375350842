import { matchPath } from 'react-router';

export const mypageMenu = [
  {
    menuID: 999999991,
    parentID: 999999990,
    name: '나의 정보',
    msgKey: 'Menu.MyPage',
    orderNum: 1,
    useyn: true,
    menuDepth: 1,
    menuUrl: '/mypage',
    aclyn: false
  },
  {
    menuID: 999999992,
    parentID: 999999990,
    name: '비밀번호 변경',
    msgKey: 'Menu.Password',
    orderNum: 2,
    useyn: true,
    menuDepth: 1,
    menuUrl: '/password',
    aclyn: false
  },
  {
    menuID: 999999993,
    parentID: 999999990,
    name: '나의 사업자',
    msgKey: 'Menu.MyBusiness',
    orderNum: 3,
    useyn: true,
    menuDepth: 1,
    menuUrl: '/mybusiness',
    aclyn: false
  },
  {
    menuID: 999999994,
    parentID: 999999990,
    name: '나의 영업점',
    msgKey: 'Menu.MyBranch',
    orderNum: 4,
    useyn: true,
    menuDepth: 1,
    menuUrl: '/mybranch',
    aclyn: false
  }
];

export const findMyPageMenus = (roleGrade, mypageMenu) => {
  let changeMyMenus = mypageMenu && mypageMenu.map((data) => data);
  if (process.env.FMS_LOGIN_TYPE !== 'CCSP')
    changeMyMenus = changeMyMenus.filter((data) => data.menuID !== 999999992);
  if (roleGrade === '1' || roleGrade === 1) {
    return changeMyMenus.filter((data) => data.menuID < 999999994);
  }
  if (roleGrade === '2' || roleGrade === 2) {
    return changeMyMenus.filter((data) => data.menuID !== 999999993);
  }
  return changeMyMenus.filter((data) => data.menuID < 999999993);
};

export const searchMenuTree = (tree, url) => {
  if (!url || !tree) return null;

  if (url && tree && tree.menuUrl && tree.menuUrl === url) {
    const list = [];
    list.push(tree);
    return list;
  }
  if (!tree.children) return null;

  for (const child of tree.children) {
    const list = searchMenuTree(child, url);
    if (list && list.length > 0) {
      if (tree && tree.menuID) list.push(tree);
      return list;
    }
  }

  return null;
};

export const compactTreeChildren = (tree) => {
  if (!tree || !tree.children) return;
  tree.children = tree.children.filter(() => true);
  tree.children.sort((a, b) => a.orderNum - b.orderNum);
  for (const child of tree.children) {
    compactTreeChildren(child);
  }
};

/** children routes 전체를 비교해서 메뉴를 대표하는 route 정보를 가져온다. */
const findRoutesByUrl = (routes, url) => {
  if (!routes || !url) return null;
  return routes.find((item) => {
    const isMatch = matchPath(url, item);
    if (isMatch) return isMatch.isExact;
    if (item.subRoutes) return findRoutesByUrl(item.subRoutes, url);
    return false;
  });
};

/** url 로부터 메뉴를 대표하는 route 정보를 가져온다. */
export const getMenuUrlFromLocation = (routes, url) => {
  const rootRoute = findRoutesByUrl(routes, url);
  return rootRoute ? rootRoute.path : '/';
};

export const getParentMenu = (menuList, menu) => {
  if (!menuList || !menu || !menu.parentID || menu.parentID < 1) return null;
  return menuList.find((item) => item.menuID === menu.parentID);
};

export function listToTree({
  list,
  rootNode: inputRootNode,
  makeItemFn,
  /* orderField = 'orderNum', */ parentField = 'parentID',
  idField = 'ID'
}) {
  if (!list || !list.length || list.length < 1) return null;
  const rootNode = inputRootNode || {};
  rootNode.children = [];

  list.forEach((item) => {
    if (item.children) {
      item.children = [];
    }

    item = makeItemFn ? makeItemFn(item) : item;
    // const orderNum = item[orderField];
    const parentID = item[parentField];
    if (!item.children && parentID && parentID > 0) {
      const parentNode = list.find((parent) => parent[idField] === parentID);

      if (parentNode) {
        item.depth = parentNode.depth + 1;
        if (!parentNode.children) parentNode.children = [];

        parentNode.children.push(item);
      }
    } else {
      item.depth = 1;
      rootNode.children.push(item);
    }
  });

  compactTreeChildren(rootNode);
  return rootNode;
}

export function treeToList(tree, list, idField) {
  list.push(tree);
  if (tree && tree.children && tree.children.forEach)
    tree.children.forEach((child) => treeToList(child, list, idField));
}

export const findTreeNode = (root, findFn) => {
  if (!root || !findFn) return null;

  if (findFn(root)) {
    return root;
  }

  if (!root.children) return null;

  for (const child of root.children) {
    const item = findTreeNode(child, findFn);
    if (item) return item;
  }

  return null;
};
