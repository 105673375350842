import { format } from 'date-fns';
import request from './request';

const prefix = '/reservation';

export const getReservationListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const {
    status,
    vehicleType,
    paymentStatus,
    defaultStatus,
    defaultVehicleType,
    keywordType,
    keyword,
    startDate,
    endDate,
    ...data
  } = filters;
  const { field, order } = sortInfo;
  if (field === 'startTime') data.sortField = 1;
  else if (field === 'endTime') data.sortField = 2;
  // else data.sortField = 3; // created_at
  data.sortOrder = order === 'desc' ? 2 : 1;

  if (keywordType && keywordType !== '' && keyword && keyword !== '') data[keywordType] = keyword;

  data.page = pageInfo && pageInfo.Page ? pageInfo.Page : 1;
  data.rowPerPage = pageInfo && pageInfo.Limit ? pageInfo.Limit : 20;
  data.status = status && status.length && status.length > 0 ? status.join() : defaultStatus.join();
  data.vehicleType =
    vehicleType
    && (vehicleType.length && vehicleType.length > 0 ? vehicleType.join() : defaultVehicleType.join());
  data.paymentStatus =
    paymentStatus && paymentStatus.length && paymentStatus.length > 0
      ? paymentStatus.join()
      : undefined;

  const startDt = startDate && startDate > 0 ? new Date(startDate) : null;
  if (startDt) startDt.setHours(0, 0, 0, 0);
  data.startDate = startDt ? startDt.getTime() : undefined;
  const endDt = endDate && endDate > 0 ? new Date(endDate) : null;
  if (endDt) endDt.setHours(23, 59, 59, 999);
  data.endDate = endDt ? endDt.getTime() : undefined;

  return request({
    url: prefix,
    data
  });
};

export const confirmReservationsAPI = (IDArr) => {
  return Promise.all(
    IDArr.map((ID) => {
      return request({
        method: 'patch',
        url: `${prefix}/${ID}/confirm`
      });
    })
  );
};

export const denyReservationsAPI = (IDArr, reason = '') => {
  return Promise.all(
    IDArr.map((ID) => {
      return request({
        method: 'patch',
        url: `${prefix}/${ID}/deny`,
        data: {
          reason
        }
      });
    })
  );
};
export const downloadCSVAPI = (data) => {
  return request({
    url: `${prefix}/disapproval/download`,
    method: 'file',
    data
  });
};
export const convertFormToData = (data) => {
  if (data) {
    // IE에서 적용안되어 수정함.(2020.09.28)
    // if (data.startTime) {data.startTime = new Date(format(data.startTime)).getTime();
    // if (data.endTime) data.endTime = new Date(format(data.endTime)).getTime();
    if (data.startTime) {
      const startTimeArr = data.startTime.split('.');
      data.startTime = new Date(startTimeArr[0], startTimeArr[1], startTimeArr[2]).getTime();
    }
    if (data.endTime) {
      const endTimeArr = data.endTime.split('.');
      data.endTime = new Date(endTimeArr[0], endTimeArr[1], endTimeArr[2]).getTime();
    }
    if (data.user && data.user.driverLicense && data.user.driverLicense.expiryDate) {
      data.user.driverLicense.expiryDate = new Date(data.user.driverLicense.expiryDate).getTime();
    }
    if (data.user && data.user.mobileNumber) {
      data.user.mobileNumber = data.user.mobileNumber.replace(/-/g, '');
    }
    if (data.payment && data.payment.totalPay !== undefined) {
      data.payment.totalPay = Number(data.payment.totalPay);
    }
    if (data.payment && data.payment.totalPay === undefined) {
      delete data.payment;
    }
  }

  delete data['user.name'];
  delete data['user.address.address'];
  delete data['user.address.detailedAddress'];
  delete data['user.address.zipCode'];
  delete data['user.birthDate'];
  delete data['user.driverLicense.licenseNumber'];
  delete data['user.mobileNumber'];
  delete data['user.team'];
  delete data['user.birthYear'];
  delete data['user.birthMonth'];
  delete data['additionalDriver.birthDate'];
  delete data['additionalDriver.birthMonth'];
  delete data['additionalDriver.birthYear'];
  delete data['additionalDriver.driverLicense.licenseNumber'];
  delete data['additionalDriver.mobileNumber'];
  delete data['additionalDriver.name'];
  delete data['user.emailAddress'];
  delete data['user.employeeRegNumber'];
  delete data['user.driverLicense.expiryDate'];
  delete data['additionalDriver.driverLicense.expiryDate'];
  delete data.selectedVehicle;
  delete data['payment.totalPay'];
  delete data['user.license.location'];
  delete data['user.license.type'];
  delete data['add.license.location'];
  delete data['additionalDriver.driverLicense.licenseType'];
  delete data['additionalDriver.driverLicense.location'];

  return data;
};

const ReservationApi = {
  /**
   * 예약 내역 조회 / 대여 종료 내역 조회
   */
  getReservationList: (fromTime, toTime) => {
    const data = {
      startDate: fromTime,
      endDate: toTime
    };

    return request({
      url: prefix,
      data
    });
  },
  /**
   * 예약 상세 조회 / 이용 상세 조회
   */
  getReservationDetail: (ID) => {
    return request({
      url: `${prefix}/${ID}`
    });
  },

  /**
   * upDate 예약 타입
   */
  upDateReservationType: (ID, data) => {
    return request({
      url: `${prefix}/${ID}/reservationType`,
      method: 'patch',
      data
    });
  },

  /**
   * 예약 일정 조회(차량 지정, 기준일 지정)
   */
  getReservationByVehicleTime: (searchCondition, vehicleID, baseDay, endTime) => {
    const data = {};
    data.searchCondition = searchCondition || 4;
    data.vehicleTime = {};
    data.vehicleTime.vehicleID = vehicleID;
    const baseDt = new Date(parseInt(baseDay, 10));
    baseDt.setHours(0, 0, 0, 0);
    data.vehicleTime.start = baseDt.getTime();

    baseDt.setHours(23, 59, 59, 999);
    data.vehicleTime.end = endTime || baseDt.getTime();

    return request({
      url: `${prefix}?vehicleID=${vehicleID}&startDate=${data.vehicleTime.start}&endDate=${data.vehicleTime.end}`
    });
  },
  /**
   * 예약 일정 조회(차량 지정, 시작일 종료일 지정)
   */
  getReservationByStartEnd: (vehicleID, startTime, endTime) => {
    const data = {};
    data.vehicleTime = {};
    data.vehicleTime.vehicleID = vehicleID;
    data.vehicleTime.start = startTime.getTime();

    data.vehicleTime.end = endTime.getTime();

    return request({
      url: `${prefix}?vehicleID=${vehicleID}&startDate=${data.vehicleTime.start}&endDate=${data.vehicleTime.end}`
    });
  },
  /**
   * 예약 승인
   */
  confirmReservation(ID) {
    return request({
      method: 'patch',
      url: `${prefix}/${ID}/confirm`
    });
  },
  /**
   * 예약 반려
   */
  denyReservation(ID, data) {
    return request({
      method: 'patch',
      url: `${prefix}/${ID}/deny`,
      data: {
        reason: data
      }
    });
  },
  /**
   * 예약 취소
   */
  cancelReservation({ reservationID, isPrivateCompany }) {
    if (isPrivateCompany) {
      return request({
        method: 'patch',
        url: `${prefix}/${reservationID}/cancel`
      });
    }

    return request({
      method: 'patch',
      url: `${prefix}/${reservationID}/cancel`,
      data: {
        isPaymentChanged: true
      }
    });
  },
  /**
   * 사용 중 상태로 변경
   */
  inUseReservation(ID) {
    return request({
      method: 'patch',
      url: `${prefix}/${ID}/inuse`
    });
  },
  /**
   * 차량 반납
   */
  returnVehicle(ID) {
    return request({
      method: 'patch',
      url: `${prefix}/${ID}/return`,
      config: {
        timeout: 35000
      }
    });
  },
  /**
   * 강제 반납
   */
  forceReturnVehicle(ID) {
    return request({
      method: 'patch',
      url: `${prefix}/${ID}/forcereturn`
    });
  },
  /**
   * 예약 삭제
   */
  deleteReservation(ID) {
    return request({
      method: 'delete',
      url: `${prefix}/${ID}`
    });
  },
  /**
   * 반납 연장
   */
  extendReservation(ID, bodyParams) {
    if (!bodyParams.isPrivateCompany) {
      bodyParams.payment.totalPay =
        bodyParams.payment && bodyParams.payment.totalPay ? Number(bodyParams.payment.totalPay) : 0;
    }
    if (
      bodyParams.isPrivateCompany
      || (!bodyParams.isPrivateCompany && bodyParams.payment.totalPay)
    ) {
      return request({
        method: 'patch',
        url: `${prefix}/${ID}/extend`,
        data: bodyParams
      });
    }
  },
  /**
   * 예약 수정
   */
  updateReservation(params) {
    const data = convertFormToData(params);

    if (!Object.keys(data).length) return;
    const { reservationID, ...bodyParams } = data;

    return request({
      url: `${prefix}/${reservationID}`,
      method: 'patch',
      data: bodyParams
    });
  },
  /**
   * 예약 강제 수정
   */
  forceUpdateReservation(params) {
    const data = convertFormToData(params);
    if (!Object.keys(data).length) return;

    return request({
      url: `${prefix}/${data.reservationID}`,
      method: 'patch',
      data
    });
  },
  /**
   * 비즈니스 목록 목록 조회
   */
  getBusinessList(businessType) {
    return request({
      url: `/businessList?businessType=${businessType}`,
      method: 'get'
    });
  },
  /** ************************************************************************************** */
  /**
   * 차량 예약가능 목록조회
   *
   * data 조건:
   * {
   *  startTime:1554199584778// 예약시작일, 필수
   *  ,endTime:1554199590000// 예약종료일, 필수
   *  ,latitude:37.570857112735894// 위도, 필수
   *  ,longitude:126.99581172313859// 경도, 필수
   *  ,distance:50000//, 사각 범위, 선택
   *  ,reservationID:
   * }
   */
  getVehicleAvailableList(data) {
    const vo = {};
    vo.latitude = data.latitude;
    vo.longitude = data.longitude;
    vo.startTime = data.startTime;
    vo.endTime = data.endTime;
    vo.businessID = data.businessID;
    vo.isPrivateCompany = data.isPrivateCompany;
    vo.insuranceID = data.insuranceID;
    if (data.vehicleType) vo.vehicleType = data.vehicleType;
    if (data.distance && data.distance > 0) vo.distance = data.distance;
    if (data.useVehicleID && data.useVehicleID !== '') {
      vo.useVehicleID = data.useVehicleID;
    }

    return request({
      url: '/vehicle/available',
      data: vo
    });
  },

  /**
   * 거점 리스트 조회
   */
  getLocationList(data) {
    return request({
      url: '/location',
      data
    });
  },

  /**
   * 예약 등록
    {
      user: {
        name: '강민철',
        mobileNumber: '01099173790',
        driverLicense: {
          driverName: 'GjjghjKd',
          licenseType: '3',
          licenseNumber: '121122222233',
          issueDate: '20190809',
          expiryDate: '20190909',
          verifyDate: '',
        },
        address: {
          zipCode: '05335',
          address: '서울 강동구 천호대로161길 15',
          detailedAddress: '오방집',
        },
        birthDate: '19741002',
        gender: '',
        team: '',
        companyNumber: '',
        emailAddress: '',
      },
      startTime: 1565661600000,
      endTime: 1565748000000,
      vehicleID: '102a31c1-b0b1-4b3b-9687-92563f893888',
      pickupLocationID: '8400add1-0fe4-4c04-925a-c4b02df2ba69',
      returnLocationID: '8400add1-0fe4-4c04-925a-c4b02df2ba69',
      additionalDriver: {
        name: '',
        mobileNumber: '',
        birthDate: '',
        driverLicense: {
          driverName: '',
          licenseType: '',
          licenseNumber: '',
          issueDate: '',
          expiryDate: '',
          verifyDate: '',
        },
      },
    }
   */
  addReservation(params) {
    const data = convertFormToData(params);
    if (!data) return;

    return request({
      method: 'post',
      url: prefix,
      data
    });
  },

  /**
   * 면허 인증
   */
  checkLicense(data) {
    return request({
      url: `${prefix}/license/check`,
      method: 'post',
      data: {
        licenseNo: data.licenseNo,
        residentName: data.residentName,
        residentDate: data.residentDate,
        licnConCode: data.licnConCode,
        fromDate: data.fromDate,
        toDate: data.toDate,
        expiryDate: data.expiryDate
      }
    });
  },

  /**
   * 요금 정보 GET
   */
  getPaymentDetail(params) {
    return request({
      url: '/payment',
      data: params
    });
  },

  /**
   * 보험 목록 GET
   */
  getInsuranceList() {
    return request({
      url: '/insurance'
    });
  },

  /**
   * 차량에 따른 보험 목록 GET
   */
  getInsuranceListByVehicle(params) {
    return request({
      url: '/insurancechargeList',
      data: params
    });
  },
  /**
   * 이용 전/후 차량 사진 조회
   */
  getReservationUseImgList(ID) {
    return request({
      url: `${prefix}/${ID}/images`
    });
  }
  /**
   *
POST /user/license
{
    "driverName" = "홍길동",
    "birthDate" = "19720928",
    "driverLicenseType" = "LV1_LARGE",
    "licenseNumber" = "AB356D",
    "passwordNum" = "AB356D",
    "startTime": 1565661600000,
    "endTime": 1565748000000
}

response
{
  "vaildCode": "00"
}
   */
};
export default ReservationApi;
