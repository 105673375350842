import {
  getAccidentDataAPI,
  getAccidentListAPI,
  getVehicleListAPI,
  getBranchListAllAPI,
  getCompanyListByBusinessAPI,
  getReservationInfoAPI,
  createAccidentAPI,
  editAccidentAPI,
  deleteAccidentAPI,
  getCompanyUserListAPI
} from 'apis/accidentApi';
import { Map, fromJS } from 'immutable';
import { createAction, createActions, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import { FMSCommon } from 'service/common/commonLib';

// Sync action creator
export const {
  initializeAccidentState,
  changeAccidentState,
  getAccidentData,
  getAccidentList,
  getVehicleList,
  getBusinessList,
  getCompanyListByBusiness,
  getAccidentCompanyUserList,
  getBranchListAll,
  getReservationInfo,
  createAccident,
  editAccident,
  deleteAccident
} = createActions(
  {
    INITIALIZE_ACCIDENT_STATE: (sliceStoreName) => sliceStoreName,
    CHANGE_ACCIDENT_STATE: (changeState) => changeState
  },
  'GET_ACCIDENT_DATA',
  'GET_ACCIDENT_LIST',
  'GET_VEHICLE_LIST',
  'GET_BUSINESS_LIST',
  'GET_COMPANY_LIST_BY_BUSINESS',
  'GET_ACCIDENT_COMPANY_USER_LIST',
  'GET_BRANCH_LIST_ALL',
  'GET_RESERVATION_INFO',
  'CREATE_ACCIDENT',
  'EDIT_ACCIDENT',
  'DELETE_ACCIDENT'
);

// Async action creator
export const getAccidentDataAsync = createAction(getAccidentData().type, getAccidentDataAPI);
export const getAccidentListAsync = createAction(getAccidentList().type, getAccidentListAPI);
export const getVehicleListAsync = createAction(getVehicleList().type, getVehicleListAPI);
export const getCompanyListByBusinessAsync = createAction(
  getCompanyListByBusiness().type,
  getCompanyListByBusinessAPI
);
export const getCompanyUserListAsync = createAction(
  getAccidentCompanyUserList().type,
  getCompanyUserListAPI
);
export const getBranchListAllAsync = createAction(getBranchListAll().type, getBranchListAllAPI);

export const getReservationCheckAsync = createAction(
  getReservationInfo().type,
  getReservationInfoAPI
);
export const createAccidentAsync = createAction(createAccident().type, createAccidentAPI);
export const editAccidentAsync = createAction(editAccident().type, editAccidentAPI);
export const deleteAccidentAsync = createAction(deleteAccident().type, deleteAccidentAPI);
export const defaultState = Map({
  list: {
    status: null,
    data: [],
    error: null,
    pageInfo: {
      TotalRecord: 0,
      Page: 1, // to server(page)
      Limit: 20, // to server(rowPerPage)
      TotalPage: 1
    }
  },
  detail: {
    status: null,
    data: {},
    ID: ''
  },
  vehicleList: {
    status: '',
    data: []
  },
  businessList: {
    status: '',
    data: []
  },
  companyList: {
    status: '',
    data: []
  },
  companyUserList: {
    status: 'aaa',
    data: []
  },
  branchList: {
    status: '',
    data: []
  },
  reservation: {
    status: '',
    data: null
  }
});

const accidents = handleActions(
  {
    [initializeAccidentState().type]: (state, { payload: { stateName } }) => {
      const arState = stateName ? stateName.split('.') : null;
      if (!arState) return;
      return state.setIn(arState, defaultState.getIn(arState));
    },
    [changeAccidentState().type]: (state, { payload: { names, value } }) => {
      const arState = names ? names.split('.') : null;
      if (!arState) return;
      let stateValue = state.getIn(arState);
      if (stateValue && stateValue.toJS) stateValue = stateValue.toJS();
      const newData = {};
      _.merge(newData, stateValue, value);
      if (arState) return state.setIn(arState, newData);
    },
    ...pender({
      type: [getAccidentData().type],
      onPending: (state) => state.setIn(['detail', 'status'], 'FETCHING'),
      onSuccess: (state, { payload: { data } }) => {
        const copied = { ...data };
        copied.business.label = data.business.name;
        copied.business.value = data.business.ID;
        return state.setIn(['detail', 'data'], fromJS(copied)).setIn(['detail', 'status'], 'DONE');
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Accident.Get.Fail');
        return state.setIn(['detail', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [getAccidentList().type],
      onPending: (state) => state.setIn(['list', 'status'], 'FETCHING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { pageInfo, items }
          }
        }
      ) => state
          .setIn(['list', 'data'], items ? fromJS(items) : [])
          .setIn(['list', 'pageInfo'], pageInfo)
          .setIn(['list', 'status'], 'DONE'),
      onFailure: (state) => {
        FMSCommon.toast.fail('Accident.Get.Fail');
        return state.setIn(['list', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [getVehicleList().type],
      onPending: (state) => state.setIn(['vehicleList', 'status'], 'FETCHING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { pageInfo, items }
          }
        }
      ) => state
          .setIn(['vehicleList', 'data'], fromJS(items))
          .setIn(['vehicleList', 'pageInfo'], pageInfo)
          .setIn(['vehicleList', 'status'], 'DONE'),
      onFailure: (state) => {
        FMSCommon.toast.fail('Accident.GetVehicle.Fail');
        return state.setIn(['vehicleList', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [getAccidentCompanyUserList().type],
      onPending: (state) => state.setIn(['companyUserList', 'status'], 'FETCHING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { items }
          }
        }
      ) => {
        const companyModels =
          items
          && items.map((item) => {
            const copied = { ...item };
            copied.label = item.managerName;
            copied.value = item.ID;

            return copied;
          });
        return state
          .setIn(['companyUserList', 'status'], 'DONE')
          .setIn(['companyUserList', 'data'], companyModels);
      },
      onFailure: (state, action) => {
        const error = action.payload;
        console.error(error);
        FMSCommon.toast.fail('Accident.GetCompany.Fail');
        return state.setIn(['companyUserList', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [getCompanyListByBusiness().type],
      onPending: (state) => state.setIn(['companyList', 'status'], 'FETCHING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { items }
          }
        }
      ) => {
        const companyModels =
          items
          && items.map((item) => {
            const copied = { ...item };
            copied.label = item.name;
            copied.value = item.ID;

            return copied;
          });

        return state
          .setIn(['companyList', 'status'], 'DONE')
          .setIn(['companyList', 'data'], companyModels);
      },
      onFailure: (state, action) => {
        const error = action.payload;
        console.error(error);
        FMSCommon.toast.fail('Accident.GetCompany.Fail');
        return state.setIn(['businessList', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [getBranchListAll().type],
      onPending: (state) => state.setIn(['branchList', 'status'], 'FETCHING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { items }
          }
        }
      ) => {
        const branchModels =
          items
          && items.map((item) => {
            const copied = { ...item };
            copied.label = item.name;
            copied.value = item.id;

            return copied;
          });
        return state
          .setIn(['branchList', 'status'], 'DONE')
          .setIn(['branchList', 'data'], branchModels);
      },
      onFailure: (state, action) => {
        const error = action.payload;
        console.error(error);
        FMSCommon.toast.fail('Accident.GetBranch.Fail');
        return state.setIn(['branchList', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [getReservationInfo().type],
      onPending: (state) => state.setIn(['reservation', 'status'], 'FETCHING'),
      onSuccess: (state, { payload: { data } }) => {
        if (!data) {
          FMSCommon.toast.fail('Accident.Fail.ConnectRentInformation');
        }
        return state.setIn(['reservation', 'status'], 'DONE').setIn(['reservation', 'data'], data);
      },
      onFailure: (state, action) => {
        const error = action.payload;
        console.error(error);
        FMSCommon.toast.fail('Accident.GetReservationCheck.Fail');
        return state.setIn(['reservation', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [createAccident().type],
      onPending: (state) => state.setIn(['detail', 'status'], 'PENDING'),
      onSuccess: (
        state,
        {
          payload: {
            data: { ID }
          }
        }
      ) => {
        FMSCommon.toast.success('Accident.Create.Success');
        return state.setIn(['detail', 'status'], 'CREATED').setIn(['detail', 'data', 'ID'], ID);
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Accident.Create.Fail');
        return state.setIn(['detail', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [editAccident().type],
      onPending: (state) => state.setIn(['detail', 'status'], 'PENDING'),
      onSuccess: (state, { payload: { data } }) => {
        const copied = { ...data };
        copied.business.label = data.business.name;
        copied.business.value = data.business.ID;
        FMSCommon.toast.success('Accident.Edit.Success');

        return state
          .setIn(['detail', 'data'], fromJS(copied))
          .setIn(['detail', 'status'], 'EDITED');
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Accident.Edit.Fail');
        return state.setIn(['detail', 'status'], 'DONE');
      }
    }),
    ...pender({
      type: [deleteAccident().type],
      onPending: (state) => state.setIn(['detail', 'status'], 'PENDING'),
      onSuccess: (state) => {
        FMSCommon.toast.success('Accident.Delete.Success');
        return state.setIn(['detail', 'status'], 'DELETED');
      },
      onFailure: (state) => {
        FMSCommon.toast.fail('Accident.Delete.Fail');
        return state.setIn(['detail', 'status'], 'DONE');
      }
    })
  },
  defaultState
);

export default accidents;
