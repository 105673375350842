import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getNoticeListAPI,
  getNoticeDetailAPI,
  createNoticeAPI,
  deleteNoticeAPI,
  updateNoticeAPI
} from 'v2/apis/noticeApi';

import { confirmFetchSaga, confirmFetchSagas, createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import {
  getNoticeList,
  deleteNoticeList,
  entityNoticeList,
  getNoticeDetail,
  createNotice,
  deleteNotice,
  updateNotice,
  changeNoticeDetailPage,
  entityNoticeDetail
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/notice';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorNoticeListPage = (state) => state.notice.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// mulitple delete for list page.
export const entityDeleteNoticeList = createEntity({
  key: deleteNoticeList().type,
  beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (!isContinue) FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorNoticeListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getNoticeList(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorNoticeListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getNoticeList(pageInfo, sortInfo, filters));
  }
});
// create for create page
export const entityCreateNotice = createEntity({
  key: createNotice().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Created.Success');
    history.replace({ pathname: `${historyUrl}` });
  }
});
// delete for detail page
export const entityDeleteNotice = createEntity({
  key: deleteNotice().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorNoticeListPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});
// update for update page
export const entityUpdateNotice = createEntity({
  key: updateNotice().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    yield put(changeNoticeDetailPage({ mode: 'view' }));
    const { ID } = requestParam;
    if (ID) yield put(getNoticeDetail(ID));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getNoticeListAsync = createSagaAction.bind(null, entityNoticeList, getNoticeListAPI);
const deleteNoticeListAsync = createSagaAction.bind(null, entityDeleteNoticeList, deleteNoticeAPI);
const getNoticeDetailAsync = createSagaAction.bind(null, entityNoticeDetail, getNoticeDetailAPI);
const createNoticeAsync = createSagaAction.bind(null, entityCreateNotice, createNoticeAPI);
const deleteNoticeAsync = createSagaAction.bind(null, entityDeleteNotice, deleteNoticeAPI);
const updateNoticeAsync = createSagaAction.bind(null, entityUpdateNotice, updateNoticeAPI);

/**
 * Watcher
 */
export default function* noticeWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(getNoticeList().type, fetchSaga, getNoticeListAsync, entityNoticeList);
  yield takeLatest(
    deleteNoticeList().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteNoticeListAsync,
    entityDeleteNoticeList
  );

  yield takeLatest(getNoticeDetail().type, fetchSaga, getNoticeDetailAsync, entityNoticeDetail);
  yield takeLatest(
    createNotice().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createNoticeAsync,
    entityCreateNotice
  );
  yield takeLatest(
    deleteNotice().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteNoticeAsync,
    entityDeleteNotice
  );
  yield takeLatest(
    updateNotice().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateNoticeAsync,
    entityUpdateNotice
  );
}
