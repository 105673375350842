import { put, select, takeLatest } from 'redux-saga/effects';

import { getMenuProgramAPI, addMenuProgramAPI, deleteMenuProgramAPI } from 'v2/apis/menuApi';

import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import {
  entityMenuProgramList,
  changeMenuProgramState,
  getMenuProgramList,
  addForMenuProgramList,
  deleteForMenuProgramList
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/menu';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorMenuPage = (state) => state.menu.list;

/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// mulitple add for list page.
export const entityAddForMenuProgramList = createEntity({
  key: addForMenuProgramList().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.data.programIDs.length;
    if (isContinue) yield put(changeMenuProgramState({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
    const { selectedNode } = yield select(selectorMenuPage);
    if (selectedNode && selectedNode.ID) yield put(getMenuProgramList(selectedNode.ID));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
    const { selectedNode } = yield select(selectorMenuPage);
    if (selectedNode && selectedNode.ID) yield put(getMenuProgramList(selectedNode.ID));
  }
});

// mulitple delete for list page.
export const entityDeleteForMenuProgramList = createEntity({
  key: deleteForMenuProgramList().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.data.programIDs.length;
    if (isContinue) yield put(changeMenuProgramState({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const { selectedNode } = yield select(selectorMenuPage);
    if (selectedNode && selectedNode.ID) yield put(getMenuProgramList(selectedNode.ID));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    const { selectedNode } = yield select(selectorMenuPage);
    if (selectedNode && selectedNode.ID) yield put(getMenuProgramList(selectedNode.ID));
  }
});
/** ********************************************** */

function* changeMenuProgramSaga() {
  const { selectedNode } = yield select(selectorMenuPage);
  if (selectedNode && selectedNode.ID) yield put(getMenuProgramList(selectedNode.ID));
}
/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getMenuProgramAsync = createSagaAction.bind(null, entityMenuProgramList, getMenuProgramAPI);
const addMenuProgramAsync = createSagaAction.bind(
  null,
  entityAddForMenuProgramList,
  addMenuProgramAPI
);
const deleteMenuProgramAsync = createSagaAction.bind(
  null,
  entityDeleteForMenuProgramList,
  deleteMenuProgramAPI
);
// const deleteMenuAsync = createSagaAction.bind(null, entityDeleteMenu, deleteMenuAPI);
/**
 * Watchersa
 */
export default function* menuProgramSaga() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    getMenuProgramList().type,
    fetchSaga,
    getMenuProgramAsync,
    entityMenuProgramList
  );
  yield takeLatest(
    addForMenuProgramList().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    addMenuProgramAsync,
    entityAddForMenuProgramList
  );
  yield takeLatest(
    deleteForMenuProgramList().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteMenuProgramAsync,
    entityDeleteForMenuProgramList
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityAddForMenuProgramList.types[1], changeMenuProgramSaga);
  yield takeLatest(entityDeleteForMenuProgramList.types[1], changeMenuProgramSaga);
}
