import request from 'apis/request';

const prefix = '/serviceHistory';

function getServiceHistorySortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
  }
}

// 서비스 로그 리스트 조회
export const getServiceHistoryListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page, limit } = pageInfo;
  const { field, order } = sortInfo;
  const { ...data } = filters;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getServiceHistorySortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

// 상세조회
export const getServiceHistoryDetailAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};
