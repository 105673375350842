import request, { getAxiosAdapter } from 'apis/request';
import axios from 'axios';

const { FMS_YANDEX_API_KEY, FMS_YANDEX_PLACES_API_KEY } = process.env;

const getLang = (lang) => {
  return fp.equals('ru', lang) ? 'ru_RU' : 'en_US';
};

/**
 * 주소 > 위경도 변환
 *
 * @param {*} param0
 * @returns
 */
export const getGeocodeAPI = ({ query }) => axios.get(
    'https://geocode-maps.yandex.ru/1.x',
    {
      params: {
        apikey: FMS_YANDEX_API_KEY,
        geocode: query,
        format: 'json',
        sco: 'latlong',
        lang: fp.pipe((key) => localStorage.getItem(key), getLang)('lang')
      },
      adapter: getAxiosAdapter()
    },
    { withCredentials: true }
  );

/**
 * 위경도 > 주소변환
 */
/**
 *
 * @param {Object} param0 위도, 경도
 * @returns
 */
export const getAddressAPI = ({ latitude, longitude }) => axios.get(
    'https://geocode-maps.yandex.ru/1.x',
    {
      params: {
        apikey: FMS_YANDEX_API_KEY,
        geocode: `${latitude},${longitude}`,
        format: 'json',
        sco: 'latlong',
        lang: fp.pipe((key) => localStorage.getItem(key), getLang)('lang')
      },
      adapter: getAxiosAdapter()
    },
    { withCredentials: true }
  );

/**
 *
 * 키워드검색
 *
 * @param {String} type biz | geo
 */
export const getSearchResultAPI = ({ query, type = 'geo' }) => axios.get(
    'https://search-maps.yandex.ru/v1/',
    {
      params: {
        apikey: FMS_YANDEX_PLACES_API_KEY,
        text: query,
        lang: fp.pipe((key) => localStorage.getItem(key), getLang)('lang'),
        type
      },
      adapter: getAxiosAdapter()
    },
    { withCredentials: true }
  );
