import request from 'apis/request';
import { isRu } from 'v2/lib/utils';

const prefix = '/insurancecharge';

function getInsuranceSortNum(filed) {
  switch (filed) {
    case 'groupName':
      return 1;
    case 'Insurance.Premium':
      return 2;
    default:
      return 0;
  }
}
/** 배열을 하나씩 formData로 분리 */
// const makeFormData = (data) => {
//   const formData = new FormData();
//   Object.keys(data).forEach((key) => {
//     if (key === 'insurances' && !_.isEmpty(data[key])) {
//       _.map(data[key], (ele, index) => {
//         Object.keys(ele).forEach((innerKey) => {
//           formData.append(`${key}[${index}].${innerKey}`, ele[innerKey]);
//         });
//       });
//     } else if (key === 'vehicles' && !_.isEmpty(data[key])) {
//       _.map(data[key], (ele, index) => {
//         Object.keys(ele).forEach((innerKey) => {
//           formData.append(`${key}[${index}].${innerKey}`, ele[innerKey]);
//         });
//       });
//     } else {
//       formData.append(key, data[key]);
//     }
//   });
//   return formData;
// };
const makeFormData = (data) => {
  const name = [];
  const premium = [];
  const selfCost = [];
  const costHour = [];
  const costMin = [];
  const insuranceRemark = [];
  const insuranceRemarkEN = [];
  const vehicleIDs = [];
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === 'insurances') {
      if (!_.isEmpty(data.insurances)) {
        _.map(data[key], (ele) => {
          name.push(ele.name);
          premium.push(ele.premium);
          selfCost.push(ele.selfCost);
          costHour.push(ele.costHour);
          costMin.push(ele.costMin);
          insuranceRemark.push(ele.insuranceRemark);
          insuranceRemarkEN.push(ele.insuranceRemarkEN);
        });
        formData.append('insuranceName', name.join('||'));
        formData.append('insurancePremium', premium.join('||'));
        formData.append('insuranceSelfCost', selfCost.join('||'));
        formData.append('insuranceCostHour', costHour.join('||'));
        formData.append('insuranceCostMin', costMin.join('||'));
        formData.append('insuranceRemark', insuranceRemark.join('||'));
        formData.append('insuranceRemarkEN', insuranceRemarkEN.join('||'));
      }
    } else if (key === 'vehicles') {
      if (!_.isEmpty(data.vehicles)) {
        _.map(data[key], (ele, index) => {
          vehicleIDs.push(ele.id);
        });
        formData.append('vehicleIDs', vehicleIDs.join(','));
      }
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

/**
 * 보험료 목록 조회
 */
export const getInsuranceListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getInsuranceSortNum(field);

  return request({
    url: prefix,
    data
  });
};

/**
 * 보험료 등록
 */

export const createInsuranceAPI = ({ data, file }) => {
  const formData = makeFormData(data);
  if (file) {
    formData.append('pdfFile', file);
  } else {
    formData.append('pdfFile', '');
  }

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}`,
    method: 'post',
    data: formData
  });
};

/**
 * 보험료 상세조회
 */
export const getInsuranceDetailAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}`
  });

/**
 * 보험료 수정
 */
export const updateInsuranceAPI = ({ ID, data, file }) => {
  const formData = makeFormData(data);

  if (file) {
    if (_.isUndefined(file.url)) {
      /**
       * fileUrl이 존재하는 경우는 기존에 업로드 되어져 있는 파일인 경우이므로
       * file을 보낼 필요 없음
       * -> isUndefined true 일때만 보내고 있음
       *  */
      formData.append('pdfFile', file);
    } else {
      formData.append('pdfFile', '');
    }
    formData.append('pdfID', file.uid);
  }

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}/${ID}`,
    method: 'patch',
    data: formData
  });
};

/**
 * 보험료 삭제
 */
export const deleteInsuranceAPI = ({ groupID }) =>
  request({
    url: `${prefix}/${groupID}`,
    method: 'delete'
  });

/**
 * 차량목록 조회
 */
export const getVehicleListAPI = ({ ID, branchID }) => {
  const url = isRu ? '/charge/vehicle2/lists' : '/charge/vehicle/lists';
  return request({
    url: `${url}`,
    data: fp.omitBy(fp.isNil, {
      businessID: ID,
      branchID
    })
  });
};

/**
 * 보험료 상세 차량 목록 조회
 */
export const getInsuranceDetailVehicleListAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}/vehicle`
  });

/**
 * 차량별 보험료 조회
 */
export const getVehicleInsuranceListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = field === 'createdAt' ? 1 : 2;

  const url = isRu ? '/charge/vehicle2/list' : '/charge/vehicle/list';
  return request({
    url: `${url}`,
    data
  });
};

/**
 * 차량별 보험료 삭제
 */
export const deleteInsuranceVehicleAPI = ({ vehicle_id }) => {
  return request({
    method: 'delete',
    url: `${prefix}/vehicle/${vehicle_id}`
  });
};

/**
 * 대여 화면에서 선택할 마일리지 조회
 */
export const getMileageListByVehicleIdAPI = ({ businessId, vehicleId, startTime, endTime }) => {
  return request({
    url: '/charge/vehicle/mileage',
    data: {
      businessId,
      vehicleId,
      startTime,
      endTime
    }
  });
};

/**
 * 보험그룹명 중복체크
 */
export const checkInsuranceGroupNameDuplicateAPI = ({ businessID, groupID, name }) => {
  return request({
    url: `${prefix}/check`,
    data: {
      businessID,
      ID: groupID,
      name
    }
  });
};

/**
 * 파일 업로드 리스트
 */
export const getUploadFileAPI = ({ insuranceGroupID }) => {
  return request({
    url: `${prefix}/${insuranceGroupID}/condition`,
    data: {
      insuranceGroupID
    }
  });
};
/**
 * 파일 업로드
 */
export const createFileAPI = ({ insuranceGroupID, file }) => {
  const data = new FormData();
  data.append('insuranceGroupID', insuranceGroupID);
  data.append('pdfFile', file);

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}/${insuranceGroupID}/condition`,
    method: 'post',
    data
  });
};

/**
 * 파일 삭제
 */
export const deleteFileAPI = ({ insuranceGroupID, pdfID }) => {
  return request({
    url: `${prefix}/${insuranceGroupID}/condition/${pdfID}`,
    method: 'delete'
  });
};
