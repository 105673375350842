import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

export const {
  initializeLoadAllCompany,
  loadAllCompany,
  initializeCompanyListPage,
  changeCompanyListPage,
  getCompanyListNew,
  getInsuranceCompanyList,
  deleteCompanyListPageNew,
  initializeCompanyDetailPage,
  changeCompanyDetailPage,
  getCompanyDetailNew,
  createCompanyNew,
  updateCompanyNew,
  deleteCompanyNew
} = createActions(
  {
    LOAD_ALL_COMPANY: (filters) => ({ filters }),
    GET_COMPANY_LIST_NEW: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_INSURANCE_COMPANY_LIST: (businessID, branchID) => ({ businessID, branchID }),
    GET_COMPANY_DETAIL_NEW: (ID) => ({ ID }),
    CREATE_COMPANY_NEW: (data) => ({ data }),
    UPDATE_COMPANY_NEW: (ID, data) => ({ ID, data }),
    DELETE_COMPANY_NEW: (IDArr) => ({ IDArr }),
    DELETE_COMPANY_LIST_PAGE_NEW: (IDArr) => ({ IDArr })
  },
  'INITIALIZE_LOAD_ALL_COMPANY',
  'INITIALIZE_COMPANY_LIST_PAGE',
  'CHANGE_COMPANY_LIST_PAGE',
  'INITIALIZE_COMPANY_DETAIL_PAGE',
  'CHANGE_COMPANY_DETAIL_PAGE'
);

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllCompany = createEntity({
  key: loadAllCompany().type,
  parseData: parseDataForList
});

export const entityCompanyList = createEntity({
  key: getCompanyListNew().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityInsuranceCompanyList = createEntity({
  key: getInsuranceCompanyList().type,
  parseData: (action) => {
    const parsedData = parseDataForList(action);
    const filteredData =
      parsedData.data
      && parsedData.data.filter((item) => {
        return item.companyType === 1;
      });
    parsedData.data = filteredData;
    return parsedData;
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityCompanyDetail = createEntity({
  key: getCompanyDetailNew().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    error: null,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  insuranceCompanyList: {
    data: [],
    loading: false,
    error: null,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create',
    error: null
  },
  loadAll: {
    data: null,
    loading: true,
    error: null,
    pager: {
      filters: {}
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const companyListPageReducer = handleActions(
  {
    [initializeCompanyListPage().type]: () => initializeState.list,
    [changeCompanyListPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const insuranceCompanyListPageReducer = handleActions(
  {
    [initializeCompanyListPage().type]: () => initializeState.insuranceCompanyList,
    [changeCompanyListPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const companyLoadAllReducer = handleActions(
  {
    [initializeLoadAllCompany().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const companyDetailPageReducer = handleActions(
  {
    [initializeCompanyDetailPage().type]: () => initializeState.detail,
    [changeCompanyDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const company = combineReducers({
  list: createReducer({
    entity: entityCompanyList,
    reducer: companyListPageReducer
  }),
  insuranceCompanyList: createReducer({
    entity: entityInsuranceCompanyList,
    reducer: insuranceCompanyListPageReducer
  }),
  detail: createReducer({
    entity: entityCompanyDetail,
    reducer: companyDetailPageReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllCompany,
    reducer: companyLoadAllReducer
  })
});
/** ********************************************** */
