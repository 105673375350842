import { business, initializeState as businessInitState } from './business';
import { accident, initializeState as accidentInitState } from './operation/accident';
import { company, initializeState as companyInitState } from './company';
import { branch, initializeState as branchInitState } from './branch';
import modals, { initializeState as modalsInitState } from './modals';
import authentication, { initializeState as authenticationInitState } from './authentication';
import { companyUser, initializeState as companyUserInitState } from './companyUser';
import { charge, initializeState as chargeInitState } from './charge';
import { terminal, initializeState as terminalInitState } from './terminal';
import { program, initializeState as programInitState } from './program';
import { vehiclePurchase, initializeState as vehiclePurchaseInitState } from './vehicle/purchase';
import { vehicleAccident, initializeState as vehicleAccidentInitState } from './vehicle/accident';
import { vehicleBasic, initializeState as vehicleBasicInitState } from './vehicle/basic';
import { vehicleMySales, initializeState as vehicleMySalesInitState } from './vehicle/mySales';
import { vehicleSap, initializeState as vehicleSapInitState } from './vehicle/vehicleSap';

import { vehicleModels, initializeState as vehicleModelsInitState } from './vehicle/models';
import { insurance, initializeState as insuranceInitState } from './insurance';
import { reservation, initializeState as reservationInitState } from './reservation';
import { member, initializeState as memberInitState } from './member';
import { memberModal, initializeState as memberModalInitState } from './member/memberModal';
import { customer, initializeState as customerInitState } from './customer';
import { businessMember, initializeState as businessMemberInitState } from './businessMember';
import { businessCustomer, initializeState as businessCustomerInitState } from './businessCustomer';
import { vehicleRetired, initializeState as vehicleRetiredInitState } from './vehicle/retired';
import { menu, initializeState as menuInitState } from './menu';
import { terms, initializeState as termsInitState } from './terms';
import { appVersion, initializeState as appVersionInitState } from './appVersion';
import { newCode, initializeState as newCodeInitState } from './code';
import { role, initializeState as roleInitState } from './role';
import {
  licenseCheckHistory,
  initializeState as licenseCheckHistoryInitState
} from './licenseCheckHistory';
import { controlHistory, initializeState as controlHistoryInitState } from './controlHistory';
import { license, initializeState as licenseInitState } from './license';
import { location, initializeState as locationInitState } from './location';
import { locationModal, initializeState as locationModalInitState } from './location/locationModal';
import { locationLocal, initializeState as locationLocalInitState } from './locationLocal';
import { businessLicense, initializeState as businessLicenseInitState } from './businessLicense';
import {
  reservationSearch,
  initializeState as reservationSearchInitState
} from './reservationSearch';
import { newHoliday, initializeState as newHolidayInitState } from './holiday';
import { reservationState, initializeState as reservationStateInitState } from './reservationState';
import { rentalSearch, initializeState as rentalSearchInitState } from './rentalSearch';
import { vehicleInsurance, initializeState as vehicleInsuranceInitState } from './vehicleInsurance';
import { vehicleLog, initializeState as vehicleLogInitState } from './vehicle/vehicleLog';
import { homeMenu, initializeState as homeMenuInitState } from './homeMenu';
import {
  testCarReservation,
  initializeState as testCarReservationInitState
} from './testCarReservation';
import { testCarConfig, initializeState as testCarConfigInitState } from './testCarConfig';
import { payment, initializeState as paymentInitState } from './payment';
import { violation, initializeState as violationInitState } from './violation';
import { paymentCalendar, initializeState as paymentCalendarInitState } from './paymentCalendar';
import {
  vehicleRegistrationCard,
  initializeState as vehicleRegistrationCardInitState
} from './vehicle/vehicleRegistrationCard';
// 2020.11.16 leejw 추가
import { monitoringdata, initializeState as monitoringdataInitState } from './monitoringdata';
import { alarmHistory, initializeState as alramInitState } from './alarms';
import { notification, initializeState as notificationInitState } from './notification';
import { taxRate, initializeState as taxRateInitState } from './taxRate';
import { currency, initializeState as currencyInitState } from './currency';
import { digitalContract, initializeState as digitalContractInitState } from './digitalContract';
import { push, initializeState as pushInitState } from './push';
import { apiHistory, initializeState as apiHistoryInitState } from './apiHistory';

// 2020.12.22 leejw 추가
import { businessreport, initializeState as businessreportInitState } from './businessreport';
import { survey, initializeState as surveyInitState } from './survey';
// 2020.12.22 leejw 추가
import { coupon, initializeState as couponInitState } from './couponmanage';
// 2021.04.30 딜러서포트 추가
import { dealerSupport, initializeState as dealerSupportInitState } from './dealersupport';
// 2021.06.11 결제송장관리(stripe payment invoice) 추가
import { stripe, initializeState as stripeInitState } from './stripeinvoice';

// 비용항목
import { costitem, initializeState as costitemInitState } from './costitem';

// 딜러그룹별 비용항목
import {
  costitemByDealer,
  initializeState as costitemByDealerInitState
} from './costitem/costitemByDealer';

// 공지사항관리
import { notice, initializeState as noticeInitState } from './notice';

// 차량 비용
import { vehicleCost, initializeState as vehicleCostInitState } from './vehicle/vehicleCost';

// 변동 비용
import { variableCost, initializeState as variableCostInitState } from './vehicle/variableCost';
import {
  accessoryInfo,
  initializeState as accessoryInfoInitState
} from './accessory/accessoryInfoSaga';
import {
  accessoryModel,
  initializeState as accessoryModelInitState
} from './accessory/accessoryModelSaga';
import {
  accessoryCategory,
  initializeState as accessoryCategoryInitState
} from './accessory/accessoryCategorySaga';
import { country, initializeState as countryInitState } from './country';

// 서비스 기록 조회
import { serviceHistory, initializeState as serviceHistoryInitState } from './serviceHistory';
// dealerKPI
import { dealerkpi, initializeState as dealerKPIInitState } from "./dealerKPI";

const reducers = {
  accessoryInfo,
  accessoryModel,
  accessoryCategory,
  alarmHistory,
  apiHistory,
  notification,
  digitalContract,
  push,
  business,
  accident,
  company,
  branch,
  modals,
  authentication,
  currency,
  companyUser,
  charge,
  terminal,
  program,
  vehiclePurchase,
  vehicleAccident,
  vehicleBasic,
  vehicleMySales,
  vehicleSap,
  vehicleModels,
  insurance,
  reservation,
  member,
  memberModal,
  customer,
  businessMember,
  businessCustomer,
  vehicleRetired,
  menu,
  terms,
  appVersion,
  newCode,
  role,
  licenseCheckHistory,
  controlHistory,
  license,
  location,
  locationModal,
  locationLocal,
  businessLicense,
  reservationSearch,
  newHoliday,
  reservationState,
  rentalSearch,
  vehicleInsurance,
  vehicleLog,
  homeMenu,
  testCarReservation,
  testCarConfig,
  payment,
  violation,
  paymentCalendar,
  vehicleRegistrationCard,
  monitoringdata,
  businessreport,
  survey,
  coupon,
  dealerSupport,
  stripe,
  costitem,
  costitemByDealer,
  notice,
  vehicleCost,
  variableCost,
  country,
  serviceHistory,
  taxRate,
  dealerkpi
};

export default reducers;

export const rootInitState = {
  accessoryInfo: accessoryInfoInitState,
  accessoryModel: accessoryModelInitState,
  accessoryCategory: accessoryCategoryInitState,
  alarmHistory: alramInitState,
  apiHistory: apiHistoryInitState,
  notification: notificationInitState,
  digitalContract: digitalContractInitState,
  push: pushInitState,
  business: businessInitState,
  accident: accidentInitState,
  company: companyInitState,
  branch: branchInitState,
  modals: modalsInitState,
  authentication: authenticationInitState,
  companyUser: companyUserInitState,
  charge: chargeInitState,
  terminal: terminalInitState,
  program: programInitState,
  vehiclePurchase: vehiclePurchaseInitState,
  vehicleAccident: vehicleAccidentInitState,
  vehicleBasic: vehicleBasicInitState,
  vehicleMySales: vehicleMySalesInitState,
  vehicleSap: vehicleSapInitState,
  vehicleModels: vehicleModelsInitState,
  insurance: insuranceInitState,
  reservation: reservationInitState,
  member: memberInitState,
  memberModal: memberModalInitState,
  customer: customerInitState,
  businessMember: businessMemberInitState,
  businessCustomer: businessCustomerInitState,
  vehicleRetired: vehicleRetiredInitState,
  menu: menuInitState,
  terms: termsInitState,
  appVersion: appVersionInitState,
  newCode: newCodeInitState,
  role: roleInitState,
  licenseCheckHistory: licenseCheckHistoryInitState,
  controlHistory: controlHistoryInitState,
  license: licenseInitState,
  location: locationInitState,
  locationModal: locationModalInitState,
  locationLocal: locationLocalInitState,
  businessLicense: businessLicenseInitState,
  reservationSearch: reservationSearchInitState,
  newHoliday: newHolidayInitState,
  reservationState: reservationStateInitState,
  rentalSearch: rentalSearchInitState,
  vehicleInsurance: vehicleInsuranceInitState,
  vehicleLog: vehicleLogInitState,
  homeMenu: homeMenuInitState,
  testCarReservation: testCarReservationInitState,
  testCarConfig: testCarConfigInitState,
  payment: paymentInitState,
  violation: violationInitState,
  paymentCalendar: paymentCalendarInitState,
  vehicleRegistrationCard: vehicleRegistrationCardInitState,
  monitoringdata: monitoringdataInitState,
  businessreport: businessreportInitState,
  survey: surveyInitState,
  coupon: couponInitState,
  dealerSupport: dealerSupportInitState,
  stripe: stripeInitState,
  costitem: costitemInitState,
  costitemByDealer: costitemByDealerInitState,
  notice: noticeInitState,
  vehicleCost: vehicleCostInitState,
  variableCost: variableCostInitState,
  country: countryInitState,
  serviceHistory: serviceHistoryInitState,
  taxRate: taxRateInitState,
  currency: currencyInitState,
  dealerkpi: dealerKPIInitState
};
