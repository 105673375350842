import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { put } from 'redux-saga/effects';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import { parseDataForList } from '../../lib/actionLib';
import { initializeProgress } from '../../reservation';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  getVehiclesList,
  getVehicleBasic,
  getRearrangementVehicle,
  deleteForVehicles,
  goVehicleList,
  checkPlateNumberDuplicate,
  initializeVehiclesListPage,
  initializeVehicleBasicPage,
  initializeVehicleControl,
  initializeVehicleDuplicate,
  initializeRearrangementPopup,
  changeProgress,
  addVehicleInfo,
  addVehicleKpmsInfo,
  editVehicleInfo,
  deleteVehicleInfo,
  retireVehicleInfo,
  changeVehiclesListPage,
  changeVehiclesBasicPage,
  controlVehicleDoorOpen,
  controlVehicleDoorClose,
  controlVehicleHorn,
  changeVehicleControl,
  saveRearrangementHour,
  saveMoveBranch,
  getMaintenanceAvailableVehicleList,
  getMaintenanceVehicleList,
  changeVehicleMaintenace,
  initializeMaintenanceAvailablePopup,
  initializeMaintenanceVehicle,
  addMaintenanceVehicle,
  editMaintenanceVehicle,
  deleteMaintenanceVehicle
} = createActions(
  {
    GET_VEHICLES_LIST: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    }),
    GET_VEHICLE_BASIC: (ID) => ({ ID }),
    GET_REARRANGEMENT_VEHICLE: (filters) => ({ filters }),
    CHECK_PLATE_NUMBER_DUPLICATE: (licensePlateNumber, countryID) => ({
      licensePlateNumber,
      countryID
    }),
    ADD_VEHICLE_INFO: (data) => data,
    ADD_VEHICLE_KPMS_INFO: (data) => data,
    EDIT_VEHICLE_INFO: (data) => data,
    DELETE_VEHICLE_INFO: (ID, isMySales = false) => ({ ID, isMySales }),
    RETIRE_VEHICLE_INFO: (ID, isMySales = false) => ({ ID, isMySales }),
    CONTROL_VEHICLE_DOOR_OPEN: (data, operator, action) => ({
      data,
      operator,
      action
    }),
    CONTROL_VEHICLE_DOOR_CLOSE: (data, operator, action) => ({
      data,
      operator,
      action
    }),
    CONTROL_VEHICLE_HORN: (data, operator, action) => ({
      data,
      operator,
      action
    }),
    SAVE_REARRANGEMENT_HOUR: (data) => data,
    SAVE_MOVE_BRANCH: (data) => data,
    GET_MAINTENANCE_AVAILABLE_VEHICLE_LIST: (filters) => ({
      filters
    }),
    GET_MAINTENANCE_VEHICLE_LIST: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    }),
    ADD_MAINTENANCE_VEHICLE: (data) => data,
    EDIT_MAINTENANCE_VEHICLE: (data) => data,
    DELETE_MAINTENANCE_VEHICLE: (ID) => ({ ID })
  },
  'INITIALIZE_VEHICLES_LIST_PAGE',
  'INITIALIZE_VEHICLE_BASIC_PAGE',
  'INITIALIZE_VEHICLE_DUPLICATE',
  'INITIALIZE_VEHICLE_CONTROL',
  'INITIALIZE_REARRANGEMENT_POPUP',
  'CHANGE_VEHICLE_CONTROL',
  'DELETE_FOR_VEHICLES',
  'CHANGE_VEHICLES_LIST_PAGE',
  'CHANGE_VEHICLES_BASIC_PAGE',
  'INITIALIZE_MAINTENANCE_AVAILABLE_POPUP',
  'INITIALIZE_MAINTENANCE_VEHICLE',
  'GO_VEHICLE_LIST',
  'CHANGE_PROGRESS'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
// export const entityLoadAllBranchList = createEntity({ key: loadAllBranch().type
//   , parseData: parseDataForList,
// });

// export const entityBranchList = createEntity({ key: getBranchList().type
//   , parseData: parseDataForList
//   , afterFailureFn: () => {
//       FMSCommon.toast.fail('Common.Msg.NoData');
//     },
//   });
export const entityVehiclesList = createEntity({
  key: getVehiclesList().type,
  parseData: parseDataForList,
  *afterFailureFn(...args) {
    const pager = fp.getOr({ filters: {} }, '[1]', args);
    yield put(changeVehiclesListPage({ pager }));
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityVehicleBasic = createEntity({
  key: getVehicleBasic().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityGoVehicleList = createEntity({
  key: goVehicleList().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityCheckPlateNumberDuplicate = createEntity({
  key: checkPlateNumberDuplicate().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Alert');
  },
  afterSuccessFn(payload) {
    const { isDuplicate } = payload;
    if (isDuplicate) {
      FMSCommon.toast.fail('Vehicle.Duplicate.Fail');
    } else {
      FMSCommon.toast.success('Vehicle.Duplicate.Success');
    }
  }
});

export const entityControlVehicle = createEntity({
  key: controlVehicleDoorOpen().type,
  *beforeFn() {
    yield put(changeVehicleControl({ contorlLoading: true }));
    return true;
  },
  *afterSuccessFn(payload) {
    const { result } = payload;
    const { code, message } = result || {};

    if (code) {
      if (code < 2000 || code > 3000) {
        FMSCommon.toast.fail('Vehicle.Control.Fail.Code', null, {
          code: code || '',
          message
        });
        yield put(changeVehicleControl({ contorlLoading: false }));
      } // else contorlLoading 유지.
    } else {
      yield put(changeVehicleControl({ contorlLoading: false }));
    }
  },
  *afterFailureFn() {
    FMSCommon.toast.fail('Vehicle.Control.Request.Fail');
    yield put(changeVehicleControl({ contorlLoading: false }));
  }
});

// 재정비시간 차량 목록
export const entityRearrangementVehicle = createEntity({
  key: getRearrangementVehicle().type,
  parseData: parseDataForList,
  // parseData: (action) => {
  //   const list = parseDataForList(action, 'vehicles')
  //   return list
  // },
  afterFailureFn: () => {
    // FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

// APP-23 차량정비 화면 start
export const entityMaintenanceAvailableVehicleList = createEntity({
  key: getMaintenanceAvailableVehicleList().type,
  parseData: (action) => {
    const list = parseDataForList(action, 'vehicles');
    return list;
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});
export const entityMaintenanceVehicleList = createEntity({
  key: getMaintenanceVehicleList().type,
  parseData: (action) => {
    const list = parseDataForList(action, 'list');
    return list;
  },
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});
// APP-23 차량정비 화면 end

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    },
    deleteVehiclesState: 'pending'
  },
  detail: {
    data: {},
    loading: false,
    progressLoading: false,
    mode: 'create',
    tid: null
  },
  duplicate: {
    data: false,
    loading: false
  },
  control: {
    data: {
      result: {
        code: null
      },
      tid: null
    },
    loading: false,
    contorlLoading: false
  },
  rearrangementVehicleList: {
    pager: {
      filters: {}
    },
    data: []
  },
  maintenanceAvailableVehicleList: {
    data: [],
    loading: false
  },
  maintenanceVehicleList: {
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'startTime', order: 'desc' },
      filters: {}
    },
    data: [],
    loading: false
  },
  progress: {
    progressLoading: false
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const vehiclesListPageReducer = handleActions(
  {
    [initializeVehiclesListPage().type]: () => initializeState.list,
    [changeVehiclesListPage().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);

const vehicleBasicPageReducer = handleActions(
  {
    [initializeVehicleBasicPage().type]: () => initializeState.detail,
    [changeVehiclesBasicPage().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);

const goVehicleListReducer = handleActions(
  {
    [goVehicleList().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);

const vehicleDuplicateReducer = handleActions(
  {
    [initializeVehicleDuplicate().type]: () => initializeState.duplicate
  },
  {},
  { produce }
);

const rearrangementReducer = handleActions(
  {
    [initializeRearrangementPopup().type]: () => initializeState.rearrangementVehicleList
  },
  {},
  { produce }
);

const maintenanceAvailableReducer = handleActions(
  {
    [initializeMaintenanceAvailablePopup().type]: () =>
      initializeState.maintenanceAvailableVehicleList
  },
  {},
  { produce }
);
const maintenanceReducer = handleActions(
  {
    [initializeMaintenanceVehicle().type]: () => initializeState.maintenanceVehicleList
  },
  {},
  { produce }
);

// page action reducer 선언
export const vehicleBasic = combineReducers({
  list: createReducer({
    entity: entityVehiclesList,
    reducer: vehiclesListPageReducer
  }),
  detail: createReducer({
    entity: entityVehicleBasic,
    reducer: vehicleBasicPageReducer
  }),
  duplicate: createReducer({
    entity: entityCheckPlateNumberDuplicate,
    reducer: vehicleDuplicateReducer
  }),
  control: createReducer({
    entity: entityControlVehicle,
    reducer: handleActions(
      {
        [initializeVehicleControl().type]: () => initializeState.control,
        [changeVehicleControl().type]: (state, { payload }) => ({
          ...state,
          ...payload
        })
      },
      {},
      { produce }
    )
  }),
  rearrangementVehicleList: createReducer({
    entity: entityRearrangementVehicle,
    reducer: rearrangementReducer
  }),
  maintenanceAvailableVehicleList: createReducer({
    entity: entityMaintenanceAvailableVehicleList,
    reducer: maintenanceAvailableReducer
  }),
  maintenanceVehicleList: createReducer({
    entity: entityMaintenanceVehicleList,
    reducer: maintenanceReducer
  }),
  goList: createReducer({
    entity: entityGoVehicleList,
    reducer: goVehicleListReducer
  }),
  progress: handleActions(
    {
      [initializeProgress().type]: () => initializeState.progress,
      [changeProgress().type]: (state, { payload }) => ({
        ...state,
        ...payload
      })
    },
    initializeState.progress,
    { produce }
  )
});
/** ********************************************** */
