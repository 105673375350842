import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const { initializeHomeMenu, changeHomeMenu } = createActions(
  // {
  //   CHANGE_HOME_MENU: (data) => (data),
  // },
  'INITIALIZE_HOME_MENU',
  'CHANGE_HOME_MENU'
);
/** ********************************************** */

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  homeMenu: {
    pathname: null,
    state: null,
    // route pathname의 첫번째 depth가 변경되었는지 여부
    // ex) /charge => /charge/:id 이동 = false, /charge => /rentStatus 이동 = true
    isMainPathChanged: false
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const homeMenuReducer = handleActions(
  {
    [initializeHomeMenu().type]: () => initializeState.homeMenu,
    [changeHomeMenu().type]: (state, { payload }) => {
      return { ...state, ...payload };
    }
  },
  {},
  { produce }
);

// page action reducer 선언
export const homeMenu = combineReducers({
  homeMenu: homeMenuReducer
});
/** ********************************************** */
