import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FMSColors, FMSFontSize } from 'service/common/designLib';
import Select from 'react-select';
import * as _ from 'lodash';

const Container = styled.div`
  width: 100%;
  display: inline-block;
  ${(props) => (props.includeLabel ? 'display: flex; align-items: top;' : undefined)}
`;

const Label = styled.div`
  width: ${(props) => props.width}px;
  line-height: 24px;
  font-size: 13px;
  color: ${(props) => (props.color ? props.color : '#787878')};
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FMSSelect = styled(Select)`
  width: 100%;
  border-radius: 1px;
  font-size: 14px;
  line-height: 22px;
`;

const CustomSelect = forwardRef((props, ref) => {
  const {
    intl,
    label,
    validators,
    options,
    style,
    controlStyle,
    optionStyle,
    className,
    defaultValue, // Unused
    value,
    error = [],
    validateSelect,
    onChange,
    underline,
    underlineColor,
    required,
    disabled,
    placeholder,
    isCodeValue,
    menuPlacement,
    maxMenuHeight,
    hidden = false,
    ...rest
  } = props;

  const borderStyle = {
    border: `1px solid ${error && error.length > 0 ? FMSColors.danger : '#ebebeb'}`
  };

  const colorStyle =
    error && error.length > 0 ? { color: FMSColors.danger } : { color: FMSColors.grey };

  const customStyles = {
    container: (base, state) => ({ ...base, zIndex: state.isFocused ? 999 : 0, ...style }),
    option: (provided, { isSelected }) => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '2em',
      color: '#999',
      backgroundColor: isSelected ? '#fbfbfb' : null,
      cursor: 'pointer',
      ':hover': {
        color: FMSColors.primary,
        backgroundColor: '#fbfbfb'
      },
      ':active': {
        color: FMSColors.primary,
        backgroundColor: '#fbfbfb'
      },
      transition: 'all 0.2s ease-in-out',
      zIndex: 999
    }),
    singleValue: (provided) => ({
      ...provided,
      ...colorStyle
    }),
    control: (provided, state) => ({
      ...provided,
      ...borderStyle,
      ...colorStyle,
      borderRadius: '1px',
      height: '32px',
      backgroundColor: '#FFF',
      fontSize: '14px',
      lineHeight: '22px',
      minHeight: '32px',
      backgroundImage: state.isDisabled
        ? 'none'
        : `url('${process.env.PUBLIC_URL}/assets/images/un2.png')`,
      backgroundPosition: 'right 4px center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '25px',
      cursor: 'pointer',
      boxShadow: 'none',
      '&:hover': state.isFocused
        ? {
            borderColor: '#454545',
            backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/un2-focus.png')`
          }
        : null,
      transition: 'border-color 0.2s ease-in-out',
      '& > div': { display: 'inline-flex' }
    }),
    placeholder: () => ({
      color: '#cbcbcb',
      fontSize: '13px',
      lineHeight: '32px'
    }),
    valueContainer: () => ({
      marginLeft: '3px'
    })
  };

  const customFilter = (option, searchText) => {
    if (!isCodeValue) {
      if (!option.label) return;
      if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      }
      return false;
    }
    if (
      intl
        .formatMessage({ id: option.label.props.id })
        .toLowerCase()
        .includes(searchText.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  const renderSelect = (placeholderText) => (
    <FMSSelect
      ref={ref}
      autoFocus={false}
      backspaceRemovesValue={false}
      components={{ DropdownIndicator: null, IndicatorSeparator: null }}
      filterOption={customFilter}
      isDisabled={disabled}
      maxMenuHeight={maxMenuHeight}
      menuPlacement={menuPlacement}
      options={
        options &&
        options.map((option) => {
          const newOptions = { ...option };
          if (isCodeValue && typeof newOptions.label === 'string') {
            newOptions.label = <FormattedMessage id={option.label} />;
          }
          return newOptions;
        })
      }
      placeholder={placeholderText}
      styles={customStyles}
      value={selectedOption || {}}
      onChange={onChange}
      onKeyUp={(newValue, actionMeta) => {
        validateSelect(newValue, actionMeta);
      }}
      {...rest}
    />
  );

  const renderErrorMsg = () =>
    error &&
    error.length > 0 && (
      <p className='error msg'>{error[0].msg && <FormattedMessage id={error[0].msg} />}</p>
    );

  const selectedOption =
    options &&
    options
      .filter((option) => option && option.value === value)
      .map((option) => {
        const newOptions = { ...option };
        if (isCodeValue && typeof newOptions.label === 'string') {
          newOptions.label = <FormattedMessage id={option.label} />;
        }
        return newOptions;
      });

  return hidden ? null : (
    <Container className={className} includeLabel={!!label} style={style}>
      {label && (
        <Label color={label.color} width={label.width}>
          {label.text && <FormattedMessage id={label.text} />}
          {required && '*'}
        </Label>
      )}
      <SelectContainer
        style={{
          width: `calc(100% - ${label && label.width ? label.width : 0}px)`
        }}
      >
        {placeholder ? (
          <FormattedMessage id={placeholder}>
            {(placeholderText) => renderSelect(placeholderText)}
          </FormattedMessage>
        ) : (
          renderSelect('')
        )}
        {renderErrorMsg()}
      </SelectContainer>
    </Container>
  );
});

CustomSelect.defaultProps = {
  options: [],
  style: {},
  controlStyle: {},
  optionStyle: {},
  className: '',
  defaultValue: {},
  selectedValue: {},
  menuPlacement: 'auto',
  maxMenuHeight: 300
};

// export default CustomSelect;

export default injectIntl(
  React.memo(
    CustomSelect,
    // 리렌더링 방지
    (prevProps, nextProps) => {
      return typeof nextProps.name !== 'undefined' && _.isEqual(prevProps, nextProps);
      // return customReplacer(prevProps) === customReplacer(nextProps);
    }
  ),
  { forwardRef: true }
);
