import { takeLatest } from 'redux-saga/effects';
import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';

import {
  createPush,
  entityCityList,
  entityCreatePush,
  entityDeletePush,
  entityPushDetail,
  entityPushList,
  entityCustomerList,
  getCityList,
  getPushDetail,
  getPushList,
  deletePush, getCustomerList
} from '.';
import {
  createPushAPI,
  deletePushAPI,
  getCityListAPI, getCustomerListAPI,
  getPushDetailAPI,
  getPushListAPI
} from '../../../apis/pushApi';

/** ********************************************** */
/** constant */
export const historyUrl = '/push';

/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// 리스트조회

const getPushListAsync = createSagaAction.bind(null, entityPushList, getPushListAPI);
const getPushDetailAsync = createSagaAction.bind(null, entityPushDetail, getPushDetailAPI);
const getCityListAsync = createSagaAction.bind(null, entityCityList, getCityListAPI);
const getCustomerListAsync = createSagaAction.bind(null, entityCustomerList, getCustomerListAPI);
const createPushAsync = createSagaAction.bind(null, entityCreatePush, createPushAPI);
const deletePushAsync = createSagaAction.bind(null, entityDeletePush, deletePushAPI);

export default function* pushWatcher() {
  yield takeLatest(getPushList().type, fetchSaga, getPushListAsync, entityPushList);
  yield takeLatest(getCityList().type, fetchSaga, getCityListAsync, entityCityList);
  yield takeLatest(getCustomerList().type, fetchSaga, getCustomerListAsync, entityCustomerList);
  yield takeLatest(getPushDetail().type, fetchSaga, getPushDetailAsync, entityPushDetail);
  yield takeLatest(
    createPush().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createPushAsync,
    entityCreatePush
  );
  yield takeLatest(deletePush().type, fetchSaga, null, deletePushAsync, entityDeletePush);
}
