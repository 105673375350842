import { put, call, fork, take, race } from 'redux-saga/effects';
import { parseErrorWithErrorField, action, parseData } from 'v2/redux/lib/actionLib';
import {
  openModalConfirm,
  confirmYes,
  confirmNo,
  closeModalConfirm
} from '../modules/modals/confirmModal';

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const INIT_STATE = 'INIT_STATE';
const CHANGE_STATE = 'CHANGE_STATE';

/**
 * 기본 action type에 _로 시작하는 추가 action type을 만들어 리턴함.
 *
 * @param {*} base
 */
function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE, INIT_STATE, CHANGE_STATE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

export function createEntityNew({
  key,
  apiFn,
  defaultState,
  confirmMessage,
  parseData: _parseData,
  parseError: _parseError,
  beforeFn,
  afterSuccessFn,
  afterFailureFn,
  changeProgress,
  reducer
}) {
  if (!key) {
    throw new Error('Key is required.');
  }
  if (!apiFn) {
    throw new Error('apiFn is required.');
  }
  if (!defaultState) {
    throw new Error('defaultState is required.');
  }

  // Entity Action Async 객체 생성
  // VEHICLE_CREATE_REQUEST, VEHICLE_CREATE_SUCESS, VEHICLE_CREATE_FAILURE
  const entityActionAsync = createRequestTypes(key);

  // Entity Action Async
  return {
    request: (params) => action(entityActionAsync[REQUEST], params),
    success: (params, response) => action(entityActionAsync[SUCCESS], { params, response }),
    failure: (params, error) => action(entityActionAsync[FAILURE], { params, error }),
    initState: (state) => action(entityActionAsync[INIT_STATE], { state }),
    changeState: (state) => action(entityActionAsync[CHANGE_STATE], { state }),
    parseError: _parseError || parseErrorWithErrorField,
    parseData: _parseData || parseData,
    beforeFn,
    afterSuccessFn,
    afterFailureFn,
    changeProgress,
    key,
    apiFn,
    defaultState,
    confirmMessage,
    reducer,
    types: [
      entityActionAsync[REQUEST],
      entityActionAsync[SUCCESS],
      entityActionAsync[FAILURE],
      entityActionAsync[INIT_STATE],
      entityActionAsync[CHANGE_STATE]
    ]
  };
}

export function createReducerNew(entity) {
  const [requestType, successType, failureType, initStateType, changeStateType] = entity.types;

  const updateStore = (state, action) => {
    let draft = { ...state };

    switch (action.type) {
      case requestType:
        draft.loading = true;
        draft.apiState = 'request';
        break;
      case successType: {
        const result = entity.parseData ? entity.parseData(action) : null;
        draft.loading = false;
        draft.apiState = 'success';
        const newValue = {};
        Object.assign(newValue, draft, result);
        draft = newValue;
        break;
      }
      case failureType: {
        const { error } = action; // entity.parseError ? entity.parseError(action) : null;
        draft.loading = false;
        draft.apiState = 'failure';
        draft.error = error;
        break;
      }
      case initStateType: {
        draft = { ...action.state };
        break;
      }
      case changeStateType: {
        draft = { ...state, ...action.state };
        break;
      }
      default:
        break;
    }

    return draft;
  };

  return function updateStoreByKey(state = {}, action) {
    switch (action.type) {
      case requestType:
      case successType:
      case failureType:
      case initStateType:
      case changeStateType:
        return updateStore(state, action);
      default: {
        let stateNew = state;
        if (entity.reducer) {
          stateNew = entity.reducer(state, action);
        }
        return stateNew;
      }
    }
  };
}

function* callApiSaga(entity, params) {
  if (!entity) return;

  try {
    if (entity.changeProgress) {
      yield fork(entity.changeProgress, true);
    }

    yield put(entity.request(params));

    let response = null;
    let error = null;

    const paramsArray = Array.isArray(params) ? params : [params];
    for (let index = 0; index < paramsArray.length; index += 1) {
      const paramsCur = paramsArray[index];

      try {
        response = yield call(entity.apiFn, paramsCur);
        if (response) {
          if (entity.parseError) {
            error = entity.parseError(response);
          }
        } else {
          error = {};
        }
      } catch (e) {
        error = e;
      }

      if (error) {
        break;
      }
    }

    if (entity.changeProgress) {
      yield fork(entity.changeProgress, false);
    }

    if (error) {
      yield put(entity.failure(params, error));
    } else {
      yield put(entity.success(params, response));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function getNewState(payload, defaultState) {
  if (!payload) {
    return null;
  }

  if (payload.newState) {
    const { newState } = payload;
    if (typeof newState === 'string') {
      if (newState === 'defaultState') {
        return defaultState;
      }
    }
    if (typeof newState === 'object') {
      return newState;
    }
  }

  if (typeof payload === 'object') {
    const keys = Object.keys(payload);
    if (keys.length) {
      const firstObj = payload[keys[0]];
      return getNewState(firstObj, defaultState);
    }
  }

  return null;
}

export function* fetchSagaNew(entity, action) {
  const newState = getNewState(action.payload, entity.defaultState);
  if (newState) {
    if (newState === entity.defaultState) {
      yield put(entity.initState(newState));
    } else {
      yield put(entity.changeState(newState));
    }

    return { success: true };
  }

  let isContinue = true;

  try {
    if (entity.confirmMessage) {
      yield put(openModalConfirm({ messageLabel: entity.confirmMessage }));
      const { no } = yield race({
        yes: take(confirmYes().type),
        no: take(confirmNo().type)
      });
      yield put(closeModalConfirm());

      if (no) isContinue = false;
    }

    if (isContinue) {
      yield fork(callApiSaga, entity, action.payload);

      // entity async request action type(success, failure) 감지.
      const [, listPageSuccessType, listPageFailureType] = entity.types;
      const { success, failure } = yield race({
        success: take(listPageSuccessType),
        failure: take(listPageFailureType)
      });

      if (failure) {
        if (entity.afterFailureFn) {
          yield fork(entity.afterFailureFn, failure.error, failure.params);
        }

        return { failure };
      }

      if (success) {
        if (entity.afterSuccessFn) {
          yield fork(entity.afterSuccessFn, success.response.data, {
            ...action.payload
          });
        }

        return { success };
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return { failure: 'error' };
}

export function changeState(state) {
  return { newState: state };
}

export function initState() {
  return { newState: 'defaultState' };
}
