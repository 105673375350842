import React, { memo, useCallback, useEffect, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import FMSLoader from 'components/ui/common/FMSLoader';
import { getProfile } from 'v2/redux/modules/authentication';
import { loadAllCodeNew } from 'v2/redux/modules/code';

import HomeIndex from 'v2/containers/main/components/HomeIndex';

const stateSelector = createSelector(
  (store) => store.authentication,
  (store) => store.newCode,
  (authentication, newCode) => ({
    profile: fp.getOr(null, 'profile.data', authentication),
    loading: fp.getOr(false, 'profile.loading', authentication),
    profileStatus: fp.getOr('', 'profile.data.activationStatus', authentication),
    codeLoading: fp.getOr(false, 'loadAll.loading', newCode),
    data: fp.getOr(null, 'loadAll.data', newCode),
    filters: fp.getOr({}, 'loadAll.pager.filters', newCode)
  })
);

const App = ({ history, ...props }) => {
  const { profile, profileStatus, loading, codeLoading, data, filters } = useSelector(
    stateSelector,
    fp.equals
  );

  const dispatch = useDispatch();
  const handleGetProfile = useCallback(() => dispatch(getProfile()), [dispatch]);
  const handleLoadAllCode = useCallback(() => dispatch(loadAllCodeNew(filters)), [dispatch]);

  useEffect(() => {
    if (!profile) {
      handleGetProfile();
    }
  }, [dispatch, profile]);

  useEffect(() => {
    if (profile && !data) handleLoadAllCode();
  }, [dispatch, profile, data]);

  return (
    <Suspense fallback={<FMSLoader loading />}>
      <Switch>
        <Route>
          <HomeIndex {...{ history, profileStatus, loading: loading || codeLoading, ...props }} />
        </Route>
      </Switch>
    </Suspense>
  );
};
export default memo(App);
