import { put, select, takeLatest } from 'redux-saga/effects';

import { createBusinessLicenseAPI, getBusinessLicenseAPI } from 'v2/apis/businessLicenseApi';

import { createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import { createBusinessLicense, getBusinessLicenseList, entityLicenseBusinessList } from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/license';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateBusinessLicense = createEntity({
  key: createBusinessLicense().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn: () => {
    // FMSCommon.toast.success('Common.Created.Success');
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createAsync = createSagaAction.bind(
  null,
  entityCreateBusinessLicense,
  createBusinessLicenseAPI
);
const listAsync = createSagaAction.bind(null, entityLicenseBusinessList, getBusinessLicenseAPI);

/**
 * Watcher
 */
export default function* licenseWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createBusinessLicense().type,
    fetchSaga,
    createAsync,
    entityCreateBusinessLicense
  );
  yield takeLatest(getBusinessLicenseList().type, fetchSaga, listAsync, entityLicenseBusinessList);
}
