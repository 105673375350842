import { put, select, takeLatest } from 'redux-saga/effects';

import { getRentalSearchListAPI } from 'v2/apis/rentalSearchApi';

import { getVehicleBasicDetailAPI } from 'v2/apis/vehicleApi';

import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';

import history from 'service/common/history';
import {
  getRentalSearchList,
  entityRentalSearchList,
  getRentalSearchDetail,
  entityRentalSearchDetail
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/license';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorLicenseListPage = (state) => state.license.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page

/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getRentalSearchListAsync = createSagaAction.bind(
  null,
  entityRentalSearchList,
  getRentalSearchListAPI
);
const getRentalSearchDetailAsync = createSagaAction.bind(
  null,
  entityRentalSearchDetail,
  getVehicleBasicDetailAPI
);
// License ACL

/**
 * Watcher
 */
export default function* rentalSearchWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    getRentalSearchList().type,
    fetchSaga,
    getRentalSearchListAsync,
    entityRentalSearchList
  );
  yield takeLatest(
    getRentalSearchDetail().type,
    fetchSaga,
    getRentalSearchDetailAsync,
    entityRentalSearchDetail
  );
}
