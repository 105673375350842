import { call, put, takeLatest, take, race, fork } from 'redux-saga/effects';
import { getVehicleSapListAPI, getVehicleListAPI } from 'v2/apis/vehicleApi';
import { createSagaAction } from 'v2/redux/sagas';

import { getVehicleSapList, entityVehiclesSapList, setVehicleSapList } from '.';

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getVehiclesSapListAsync = createSagaAction.bind(
  null,
  entityVehiclesSapList,
  getVehicleSapListAPI
);

export function* fetchSapSaga(entityAsyncSaga, entity, action, ...args) {
  try {
    const params = {
      filters: {
        countryID: action.payload.filters.countryID,
        businessID: action.payload.filters.businessID
      },
      pageInfo: {
        limit: 1000000,
        page: 1
      }
    };

    yield put(setVehicleSapList({ loading: true }));
    const res = yield call(getVehicleListAPI, params);
    yield put(setVehicleSapList({ regList: fp.getOr([], 'data.items', res) }));

    // businessSaga SubRoutin 실행
    yield fork(entityAsyncSaga, {
      ...action.payload,
      ...args,
      regList: fp.getOr([], 'data.items', res)
    });

    // entity async request action type(success, failure) 감지.
    const [, listPageSuccessType, listPageFailureType] = entity.types;
    const { success, failure } = yield race({
      success: take(listPageSuccessType),
      failure: take(listPageFailureType)
    });

    // onFailure -> afterFailureFn 실행
    if (failure && entity.afterFailureFn) {
      yield fork(entity.afterFailureFn, failure.error, failure.params);
    }
    // onSuccess -> afterSuccessFn 실행
    if (success && entity.afterSuccessFn) {
      yield fork(entity.afterSuccessFn, success.response.data.list, {
        ...action.payload,
        ...args
      });
    }
    return { success, failure };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return { failure: 'error' };
}

/**
 * Watcher
 */
export default function* vehicleSapWatcher() {
  yield takeLatest(
    getVehicleSapList().type,
    fetchSapSaga,
    getVehiclesSapListAsync,
    entityVehiclesSapList
  );
}
