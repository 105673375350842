import { takeLatest } from 'redux-saga/effects';
import { confirmFetchSaga, createSagaAction, fetchSaga } from 'v2/redux/sagas';
import { getTaxRateListAPI, updateTaxRateAPI } from 'v2/apis/taxRateAPI';

import { entityTaxRateList, entityUpdateTaxRate, getTaxRateList, updateTaxRate } from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/taxRate';

/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// 리스트조회
const getTaxRateListAsync = createSagaAction.bind(null, entityTaxRateList, getTaxRateListAPI);
const updateTaxRateAsync = createSagaAction.bind(null, entityUpdateTaxRate, updateTaxRateAPI);

export default function* taxRateWatcher() {
  yield takeLatest(getTaxRateList().type, fetchSaga, getTaxRateListAsync, entityTaxRateList);
  yield takeLatest(
    updateTaxRate().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateTaxRateAsync,
    entityUpdateTaxRate
  );
}
