import request from 'apis/request';

const prefix = '/vehicle/cost';

/**
 * 고정 비용 리스트 조회
 * ex) /vehicle/cost/fixed
 */
export const getVehicleFixedCostListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  // const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;

  data.pageNum = page;
  data.rowsPerPage = limit;
  // data.sortOrder = (order === 'desc') ? 2 : 1;
  // data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}/fixed`,
    data
  });
};

/**
 * rental history 조회
 * ex) /monitoring/vehicle/vehicleID
 */
export const getVehicleHistoryListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 30 } = pageInfo;
  const data = {};
  data.page = page;
  data.rowPerPage = 30;
  console.log('pageInfo:', pageInfo);
  console.log('data:', data);
  return request({
    url: `/monitoring/vehicle/${filters.ID}`,
    data
  });
};

/**
 * 고정비용 리스트 등록
 * ex) /vehicle/cost/fixed
 */
export const createFixedCostListAPI = ({ data }) => {
  return request({
    url: `${prefix}/fixed`,
    method: 'post',
    data
  });
};

/**
 * 고정비용 수정
 * ex) /vehicle/cost/fixed/:vehicleId/code/:costCode
 */
export const updateFixedCostAPI = ({ data }) => {
  return request({
    url: `${prefix}/fixed/${data.vehicleId}/code/${data.costCode}`,
    method: 'patch',
    data
  });
  // request({
  //   url: `${prefix}/dealer-group/businessId/${data.businessId}/code/${data.costCode}`,
  //   method: 'patch',
  //   data,
  // })
};

/**
 * 고정비용 삭제
 * ex) /vehicle/cost/fixed/:vehicleId/code/:costCode/type/:vehicleType
 */
export const deleteFixedCostListAPI = (data) => {
  return request({
    url: `${prefix}/fixed/${data.vehicleId}/code/${data.costCode}/type/${data.vehicleType}`,
    method: 'delete',
    data: {
      startDate: data.startDate,
      endDate: data.endDate
    }
  });
};

/**
 * 변동 비용 리스트 조회
 * ex) /vehicle/cost/variable
 */
export const getVariableCostListAPI = ({ filters = {} }) => {
  // const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;

  // data.sortOrder = (order === 'desc') ? 2 : 1;
  // data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}/variable`,
    data
  });
};

/**
 * 변동 비용 저장
 * ex) /vehicle/cost/variable
 */
export const saveVariableCostAPI = ({ data }) => {
  return request({
    url: `${prefix}/variable`,
    method: 'post',
    data
  });
};
