import request from 'apis/request';
import { PAGE_LIMIT } from 'v2/constants/index';

/**
 * 시승차 목록 조회
 */
export const getAllReservationListAPI = ({ pageInfo, sortInfo, filters }) => {
  /* filters */
  const { status, keywordType, keyword, vehicleType, startDate, endDate, ...data } = filters;
  data.startDate = startDate;
  data.endDate = endDate;

  if (keywordType) {
    data[keywordType] = keyword;
  }
  if (status) {
    data.status = status;
  } else {
    data.status = '1,2,3,4,7,8';
  }
  // if (vehicleType) {
  //   data.vehicleType = vehicleType;
  // } else {
  //   data.vehicleType = '2';
  // }
  data.reservationTag = 'TESTCAR';
  /* pageInfo */
  data.page = pageInfo && pageInfo.page ? pageInfo.page : 1;
  data.rowPerPage = pageInfo && pageInfo.limit ? pageInfo.limit : PAGE_LIMIT;
  /* sortInfo */
  const { field, order } = sortInfo;
  data.sortField = field === 'createdAt' ? 3 : 1;
  data.sortOrder = order === 'desc' ? 2 : 1;
  // /* businessID */
  // data.businessID = ID;
  // data.branchID = ID2;
  return request({
    url: '/reservation',
    data
  });
};

/**
 * 승인대기 일괄 승인 PATCH
 */
export const confirmReservationsAPI = ({ data }) => {
  return request({
    url: '/reservation/bulkconfirm',
    method: 'patch',
    data: {
      reservationIDs: data
    }
  });
};

/**
 * 승인대기 일괄 거부 PATCH
 */
export const denyReservationsAPI = ({ data, reason }) => {
  return request({
    url: '/reservation/bulkdeny',
    method: 'patch',
    data: {
      reservationIDs: data,
      reason
    }
  });
};

/**
 * 예약 내역 요약
 */
export const summaryReservationsAPI = ({ filters }) => {
  return request({
    url: '/reservation/summary',
    method: 'get',
    data: {
      startTime: filters.startDate,
      endTime: filters.endDate,
      businessID: filters.businessID,
      branchID: filters.branchID
    }
  });
};

/**
 * CSV 다운로드
 */
export const downloadReservationsAPI = ({ filters, pageInfo, sortInfo }) => {
  /* filters */
  const { status, keywordType, keyword, vehicleType, startDate, endDate, ...data } = filters;
  data.startDate = startDate;
  data.endDate = endDate;

  if (keywordType) {
    data[keywordType] = keyword;
  }
  if (_.isEmpty(status)) {
    data.status = '1,2,3,4,7,8';
  } else {
    data.status = status;
  }
  // if (vehicleType) {
  //   data.vehicleType = vehicleType;
  // } else {
  //   data.vehicleType = '1,2';
  // }

  data.reservationTag = 'TESTCAR';

  /* pageInfo */
  data.page = pageInfo && pageInfo.page ? pageInfo.page : 1;
  data.rowPerPage = pageInfo && pageInfo.limit ? pageInfo.limit : PAGE_LIMIT;
  /* sortInfo */
  const { field, order } = sortInfo;
  data.sortField = field === 'createdAt' ? 1 : 2;
  data.sortOrder = order === 'desc' ? 2 : 1;
  // /* businessID */
  // data.businessID = ID;
  // data.branchID = ID2;
  return request({
    url: '/reservation/disapproval/download',
    method: 'file',
    data
  });
};
