import { combineReducers } from 'redux';

import {
  confirmModalReducer,
  initializeState as initializeStateConfirmModal
} from './confirmModal';
import {
  postcodeModalReducer,
  initializeState as initializeStatePostcodeModal
} from './postcodeModal';

// page action reducer 선언
const modals = combineReducers({
  confirmModal: confirmModalReducer,
  postcodeModal: postcodeModalReducer
});

export const initializeState = {
  ...initializeStateConfirmModal,
  ...initializeStatePostcodeModal
};

export default modals;
