import { put, select, takeLatest } from 'redux-saga/effects';

import { getAccidentsAPI, deleteAccidentInfoAPI } from 'v2/apis/operation/accidentApi';

import { createSagaAction, confirmFetchSaga, fetchSaga, fetchSagas } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import history from 'service/common/history';
import { getAccidents, changeAccidentsPage, entityAccidents, deleteForAccidents } from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/accident';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorAccidentsPage = (state) => state.accident.list;
/** ********************************************** */

/** ********************************************** */
// mulitple delete for list page.
export const entityDeleteForAccidents = createEntity({
  key: deleteForAccidents().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (!isContinue) {
      FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    } else {
      yield put(changeAccidentsPage({ deleteAccidentsState: 'pending' }));
    }
    return isContinue;
  },
  *afterSuccessFn(payload, params) {
    FMSCommon.toast.success('Common.Deleted.Success');
    yield put(changeAccidentsPage({ deleteAccidentsState: 'deleted' }));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Deletes.Fail');
    yield put(changeAccidentsPage({ deleteAccidentsState: 'deleteFailed' }));
  }
});

/**
 * SubRoutin
 */
const entityAccidentsAsync = createSagaAction.bind(null, entityAccidents, getAccidentsAPI);
const deleteForAccidentsAsync = createSagaAction.bind(
  null,
  entityDeleteForAccidents,
  deleteAccidentInfoAPI
);

/**
 * Watcher
 */
export default function* accidentsWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(getAccidents().type, fetchSaga, entityAccidentsAsync, entityAccidents);
  yield takeLatest(
    deleteForAccidents().type,
    fetchSagas,
    null,
    deleteForAccidentsAsync,
    entityDeleteForAccidents
  );
}
