import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getChargeListAPI,
  getChargeDetailAPI,
  getChargeDetailVehicleListAPI,
  getVehicleListAPI,
  deleteChargeAPI,
  createChargeAPI,
  updateChargeAPI,
  getVehicleChargeListAPI,
  deleteChargeVehicleAPI,
  checkChargeNameDuplicateAPI
} from 'v2/apis/chargeApi';

import { createSagaAction, fetchSaga, confirmFetchSaga, confirmFetchSagas } from 'v2/redux/sagas';
import { createEntity, parseErrorWithErrorField } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import history from 'service/common/history';
import { getErrorMessage } from 'constants/errors';

import {
  changeChargePage,
  getCharges,
  entityCharges,
  getCharge,
  entityCharge,
  getVehicleCharge,
  entityVehicleCharge,
  getVehicle,
  entityVehicle,
  createCharge,
  deleteCharge,
  updateCharge,
  deleteForCharges,
  getChargeForVehicle,
  entityChargeForVehicle,
  deleteChargeVehicle,
  changeChargeList,
  entityCheckChargeNameDuplicate,
  checkChargeNameDuplicate
} from '.';

export const historyUrl = '/charge';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorChargePage = (state) => state.charge.list;
export const selectorMyBusiness = (state) => state.authentication.myBusiness.data;
export const selectorChargeVehicleListPage = (state) => state.charge.chargeForVehicleList;

export const entityCreateCharge = createEntity({
  key: createCharge().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload) {
    const { pager, status } = yield select((store) => ({
      pager: fp.getOr({}, 'charge.list.pager', store),
      status: fp.getOr('', 'charge.list.status', store)
    }));

    const chargeID = fp.getOr('', 'ID', payload);
    FMSCommon.toast.success('Common.Created.Success');
    history.replace({ pathname: `/charge/${chargeID}`, state: { pager, path: status } });
  }
});

export const entityDeleteCharge = createEntity({
  key: deleteCharge().type,
  parseError: parseErrorWithErrorField, // (action) => parseErrorWithErrorField(action, 'errorCode'),
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Charge.Toast.Delete.Fail'); // error.errorCode === 910470
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorChargePage);
    const { pager, status } = selectorListPage;
    if (status === '/chargeVehicle') {
      history.push({ pathname: `${status}`, state: { pager, path: status } });
    } else {
      history.push({ pathname: historyUrl, state: { pager, path: status } });
    }
  }
});

export const entityUpdateCharge = createEntity({
  key: updateCharge().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    const state = yield select(({ authentication }) => ({
      businessID: fp.get('myBusiness.data.ID', authentication),
      branchID: fp.get('myBranch.data.ID', authentication)
    }));

    const { ID } = requestParam;
    yield put(getVehicleCharge(ID));
    yield put(getVehicle({ ID: state.businessID, branchID: state.branchID }));
  }
});

export const entityDeleteForCharges = createEntity({
  key: deleteForCharges().type,
  parseError: parseErrorWithErrorField, // (action) => parseErrorWithErrorField(action, 'errorCode'),
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeChargePage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');

    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorChargePage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCharges(pageInfo, sortInfo, filters));
  },
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Charge.Toast.Delete.Fail');
    const selectorListPage = yield select(selectorChargePage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCharges(pageInfo, sortInfo, filters));
  }
});

export const entityDeleteChargeVehicle = createEntity({
  key: deleteChargeVehicle().type,
  parseError: parseErrorWithErrorField, // (action) => parseErrorWithErrorField(action, 'errorCode'),
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeChargeList({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
    return isContinue;
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Charge.Toast.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorChargeVehicleListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    const { ID } = yield select(selectorMyBusiness);
    yield put(getChargeForVehicle(pageInfo, sortInfo, filters));
  }
});

function* changeChargePageSaga() {
  yield put(changeChargePage({ mode: 'view' }));
}

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getChargesAsync = createSagaAction.bind(null, entityCharges, getChargeListAPI);
const getChargeAsync = createSagaAction.bind(null, entityCharge, getChargeDetailAPI);
const getVehicleChargeAsync = createSagaAction.bind(
  null,
  entityVehicleCharge,
  getChargeDetailVehicleListAPI
);
const getVehicleAsync = createSagaAction.bind(null, entityVehicle, getVehicleListAPI);
const createChargeAsync = createSagaAction.bind(null, entityCreateCharge, createChargeAPI);
const deleteChargeAsync = createSagaAction.bind(null, entityDeleteCharge, deleteChargeAPI);
const updateChargeAsync = createSagaAction.bind(null, entityUpdateCharge, updateChargeAPI);
const deleteForChargesAsync = createSagaAction.bind(null, entityDeleteForCharges, deleteChargeAPI);
const getChargeForVehicleAsync = createSagaAction.bind(
  null,
  entityChargeForVehicle,
  getVehicleChargeListAPI
);
const deleteChargeVehicleAsync = createSagaAction.bind(
  null,
  entityDeleteChargeVehicle,
  deleteChargeVehicleAPI
);
const checkChargeNameDuplicateAsync = createSagaAction.bind(
  null,
  entityCheckChargeNameDuplicate,
  checkChargeNameDuplicateAPI
);

export default function* chargeWatcher() {
  yield takeLatest(getCharges().type, fetchSaga, getChargesAsync, entityCharges);
  yield takeLatest(getCharge().type, fetchSaga, getChargeAsync, entityCharge);
  yield takeLatest(
    createCharge().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createChargeAsync,
    entityCreateCharge
  );
  yield takeLatest(
    deleteCharge().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteChargeAsync,
    entityDeleteCharge
  );
  yield takeLatest(
    updateCharge().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateChargeAsync,
    entityUpdateCharge
  );
  yield takeLatest(getVehicle().type, fetchSaga, getVehicleAsync, entityVehicle);
  yield takeLatest(getVehicleCharge().type, fetchSaga, getVehicleChargeAsync, entityVehicleCharge);
  yield takeLatest(
    deleteForCharges().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteForChargesAsync,
    entityDeleteForCharges
  );

  yield takeLatest(
    getChargeForVehicle().type,
    fetchSaga,
    getChargeForVehicleAsync,
    entityChargeForVehicle
  );
  yield takeLatest(
    deleteChargeVehicle().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteChargeVehicleAsync,
    entityDeleteChargeVehicle
  );

  yield takeLatest(entityCreateCharge.types[1], changeChargePageSaga);
  yield takeLatest(entityUpdateCharge.types[1], changeChargePageSaga);
  yield takeLatest(
    checkChargeNameDuplicate().type,
    fetchSaga,
    checkChargeNameDuplicateAsync,
    entityCheckChargeNameDuplicate
  );
}
