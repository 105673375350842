import request from 'apis/request';

const prefix = '/branch';

function getComapnySortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/*
  필요한 api
  1. pageList 데이터 조회
  2. 등록하는 부분
  3. 검색해서 pageList 데이터 조회
  4. 등록페이지에서 저장
  5. 취소
*/

export const getAllBranchListAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 영업점 전체 리스트 조회
 */
export const getBranchListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getComapnySortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 영업점 상세 조회
 */
export const getBranchDetailAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}`
  });

/**
 * 영업점 등록 기능
 */
export const createBranchAPI = (inputData) => {
  // const data = convertFormToData(inputData.data);
  return request({
    url: `${prefix}`,
    method: 'post',
    data: inputData.data
  });
};

/**
 * 영업점 수정 기능
 */
export const updateBranchAPI = (inputData) => {
  // const data = convertFormToData(inputData.data);
  return request({
    url: `${prefix}/${inputData.ID}`,
    method: 'patch',
    data: inputData.data
  });
};

/**
 * 영업점 삭제 기능
 */
export const deleteBranchAPI = ({ ID }) =>
  request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });

/**
 * 영업점 중지 기능
 */
export const suspendBranchAPI = ({ ID }) =>
  request({
    url: `${prefix}/suspend/${ID}`,
    method: 'patch'
  });

/**
 * 영업점 복원 기능
 */
export const restoreBranchAPI = ({ ID }) =>
  request({
    url: `${prefix}/restore/${ID}`,
    method: 'patch'
  });

/**
 * 영업점 복수 삭제 기능
 */
export const deleteBranchsAPI = (IDArr) =>
  Promise.all(
    IDArr.map((ID) =>
      request({
        method: 'delete',
        url: `${prefix}/${ID}`
      })
    )
  );
