import React, { Suspense, useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'v2/redux/modules/authentication';

import FMSLoader from 'components/ui/common/FMSLoader';
import unapprovedRoutes from 'v2/constants/unapprovedRoutes';

import LanguageSelectContainer from 'v2/pages/authentication/LanguageSelectContainer';
import { isRu } from 'v2/lib/utils';
import HomeNavLeftMenu from './components/HomeNavLeftMenu';
import HomeNavTopMenu from './components/HomeNavTopMenu';
import { mypageMenu } from './lib/homeLib';

import { Body, Container, Header, HeaderLogo, HeaderGnb, LNB, Content } from './styles/HomeStyles';

const renderType = () => {
  const renderResult = (
    <Switch>
      {unapprovedRoutes.map((menu) => (
        <Route
          key={menu.path}
          aclAuth={menu.aclAuth}
          component={menu.component}
          exact={menu.exact}
          path={menu.path}
        />
      ))}
    </Switch>
  );
  return <Suspense fallback={<FMSLoader loading />}>{renderResult}</Suspense>;
};

const HomeUnapprovePage = ({
  history: {
    location: { pathname: url }
  }
}) => {
  const { profile } = useSelector(({ authentication }) => {
    const {
      profile: { data, loading }
    } = authentication;
    return {
      profile: data,
      profileLoading: loading || !data
    };
  });

  const dispatch = useDispatch();
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const [menuUrl] = useState(url);
  const [selectedMenuIDs, setSelectedMenuIDs] = useState();

  useEffect(() => {
    const myMenu = mypageMenu.filter((item) => item.menuUrl === menuUrl).map((item) => item.menuID);
    if (myMenu && myMenu.length > 0) {
      setSelectedMenuIDs(myMenu);
    } else {
      setSelectedMenuIDs([mypageMenu[0].menuID]);
    }
  }, [menuUrl]);

  // react route onClick시 menu change
  const handleSelectMenu = useCallback((menu, url) => {
    if (!menu) {
      if (url) {
        const selecteMenuIDs = mypageMenu
          .filter((item) => item.menuUrl === url)
          .map((item) => item.menuID);
        setSelectedMenuIDs(selecteMenuIDs);
      }
    } else {
      setSelectedMenuIDs([menu.menuID]);
    }
  }, []);

  return (
    <Body alt='Body'>
      <Container alt='Container'>
        <Header alt='Header'>
          <HeaderLogo to='/' onClick={() => handleSelectMenu(null, null, true)}>
            <img
              alt='KIA Flex'
              r-if={$utils.isRu}
              src='/assets/images/img_header_flex_logo.png'
              style={{ height: 'auto' }}
            />
            <img
              r-else
              alt='FMS logo'
              src='/assets/images/img_header_logo.png'
              style={{ height: 'auto' }}
            />
          </HeaderLogo>
          <HeaderGnb>
            <ul>
              <li>
                <Link to='/mypage' onClick={() => handleSelectMenu(null, '/mypage')}>
                  <FormattedMessage id='Common.Logout.Hello' values={{ user: profile.name }} />
                </Link>
                <button
                  aria-label={<FormattedMessage id='Common.Logout' />}
                  className='logout'
                  type='button'
                  onClick={handleLogout}
                >
                  <label>
                    <FormattedMessage id='Common.Logout' />
                  </label>
                </button>
              </li>
              <li className='split'> </li>
              {process.env.FMS_LOGIN_TYPE === 'CCSP' && (
                <li>
                  <Link to='/password' onClick={() => handleSelectMenu(null, '/password')}>
                    <FormattedMessage id='Menu.Password' />
                  </Link>
                </li>
              )}
              <li
                className='split'
                {...(!profile || !profile.roleGrade || profile.roleGrade !== '1'
                  ? { style: { display: 'none' } }
                  : null)}
              >
                {' '}
              </li>
              <li>
                <LanguageSelectContainer />
              </li>
            </ul>
          </HeaderGnb>
        </Header>
        <HomeNavTopMenu />
        <>
          <div style={{ display: 'flex', flexDirection: 'row', minHeight: 'calc(100% - 136px)' }}>
            <LNB>
              <HomeNavLeftMenu
                menuList={!isRu ? mypageMenu.slice(0, 1) : mypageMenu.slice(0, 2)}
                {...{ selectedMenuIDs, handleSelectMenu }}
              />
            </LNB>
            <Content alt='Content'>{renderType()}</Content>
          </div>
        </>
      </Container>
    </Body>
  );
};
export default React.memo(HomeUnapprovePage);
