import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';

import history from 'service/common/history';

export const {
  initializeVehicleRegistrationCardFormPage /** 차량등록증 이미지 등록 이후 차량정보 등록 화면 */,
  changeVehicleRegistrationCardFormPage /** 차량등록증 이미지 등록 이후 차량정보 등록 화면 */,
  uploadVehicleRegistrationCard /** 차량등록증 이미지 업로드, 응답결과는 OCR 응답 결과 */,
  // deleteVehicleRegistrationCard, /** API 미지원 업로드된 임시 차량등록증 이미지 삭제 */
  createVehicleRegistrationCard /** 차량등록증 이미지를 통한 차량 등록 */
} = createActions(
  {
    // DELETE_VEHICLE_REGISTRATION_CARD: (imageID, modelID) => ({ imageID, modelID }),
    CREATE_VEHICLE_REGISTRATION_CARD: (data) => ({ data }),
    UPLOAD_VEHICLE_REGISTRATION_CARD: (image) => ({ image })
  },
  'INITIALIZE_VEHICLE_REGISTRATION_CARD_FORM_PAGE',
  'CHANGE_VEHICLE_REGISTRATION_CARD_FORM_PAGE'
);
/** ********************************************** */
/** constant */
export const historyUrl = '/vehicle/registrationCard';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorListPage = (state) => state.vehicle.list;
/** ********************************************** */

export const initializeState = {
  form: {
    data: null,
    loading: false,
    progressLoading: false,
    error: null,
    mode: 'create'
  }
};

/** 업로드 + 조회 */
export const entityVehicleRegistrationCard = createEntity({
  key: uploadVehicleRegistrationCard().type,
  afterFailureFn() {
    FMSCommon.toast.fail('Vehicle.Registration.Card.Upload.Fail');
  },
  afterSuccessFn(payload) {
    const { pager } = selectorListPage;
    const { registeredVehicleCardID } = payload;
    if (registeredVehicleCardID)
      history.replace({ pathname: `${historyUrl}/${registeredVehicleCardID}`, state: pager });
  }
});

const vehicleRegistrationCardReducer = handleActions(
  {
    [initializeVehicleRegistrationCardFormPage().type]: () => initializeState.form,
    [changeVehicleRegistrationCardFormPage().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  initializeState.form,
  { produce }
);

export const vehicleRegistrationCard = combineReducers({
  form: createReducer({
    entity: entityVehicleRegistrationCard,
    reducer: vehicleRegistrationCardReducer
  })
});
