import { takeLatest } from 'redux-saga/effects';
import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getNotificationListAPI } from 'v2/apis/notificationAPI';

import { entityNotificationList, getNotificationList } from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/notification';

/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// 리스트조회
const getNotificationListAsync = createSagaAction.bind(
  null,
  entityNotificationList,
  getNotificationListAPI
);

export default function* notificationWatcher() {
  yield takeLatest(
    getNotificationList().type,
    fetchSaga,
    getNotificationListAsync,
    entityNotificationList
  );
}
