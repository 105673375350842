import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeChargeNameDuplicate,
  initializeChargesPage,
  getCharges,
  deleteForCharges,
  initializeChargePage,
  getCharge,
  changeChargePage,
  initializeVehicleChargePage,
  getVehicleCharge,
  changeVehicleChargePage,
  initializeVehiclePage,
  getVehicle,
  createCharge,
  updateCharge,
  deleteCharge,
  initializeChargeForVehiclePage,
  getChargeForVehicle,
  changeChargeList,
  deleteChargeVehicle,
  changeChargeForVehiclePage,
  checkChargeNameDuplicate
} = createActions(
  {
    GET_CHARGES: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_CHARGE: (ID) => ({ ID }),
    GET_VEHICLE_CHARGE: (ID) => ({ ID }),
    CREATE_CHARGE: (data) => ({ data }),
    UPDATE_CHARGE: (ID, data) => ({ ID, data }),
    DELETE_CHARGE: (ID) => ({ ID }),
    GET_CHARGE_FOR_VEHICLE: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    DELETE_CHARGE_VEHICLE: fp.map(
      fp.pipe(fp.get('vehicle_id'), (id) => fp.set('vehicleId', id, {}))
    ),
    CHECK_CHARGE_NAME_DUPLICATE: (businessID, name) => ({
      businessID,
      name
    })
  },
  'INITIALIZE_CHARGE_NAME_DUPLICATE',
  'GET_VEHICLE',
  'INITIALIZE_CHARGES_PAGE',
  'DELETE_FOR_CHARGES',
  'INITIALIZE_CHARGE_PAGE',
  'CHANGE_CHARGE_PAGE',
  'INITIALIZE_VEHICLE_CHARGE_PAGE',
  'INITIALIZE_VEHICLE_PAGE',
  'INITIALIZE_CHARGE_FOR_VEHICLE_PAGE',
  'CHANGE_VEHICLE_CHARGE_PAGE',
  'CHANGE_CHARGE_LIST',
  'CHANGE_CHARGE_FOR_VEHICLE_PAGE'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */

export const entityCheckChargeNameDuplicate = createEntity({
  key: checkChargeNameDuplicate().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Alert');
  },
  afterSuccessFn(payload) {
    const { isDuplicate } = payload;
    if (isDuplicate) {
      FMSCommon.toast.fail('ChargeName.Duplicate.Fail');
    } else {
      FMSCommon.toast.success('ChargeName.Duplicate.Success');
    }
  }
});

export const entityCharges = createEntity({
  key: getCharges().type,
  parseData: (action) => parseDataForList(action, 'chargeItems'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityCharge = createEntity({
  key: getCharge().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityVehicleCharge = createEntity({
  key: getVehicleCharge().type,
  parseData: (action) => parseDataForList(action, 'vehicleItem'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityVehicle = createEntity({
  key: getVehicle().type,
  parseData: (action) => parseDataForList(action, 'vehicles'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityChargeForVehicle = createEntity({
  key: getChargeForVehicle().type,
  parseData: (action) => parseDataForList(action, 'charge_vehicle_items'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    },
    status: '/charge'
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create'
  },
  vehicleChargeList: {
    data: []
  },
  vehicleList: {
    data: []
  },
  chargeForVehicleList: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  duplicate: {
    data: false,
    loading: false
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const chargesPageReducer = handleActions(
  {
    [initializeChargesPage().type]: () => initializeState.list,
    [changeChargeList().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const chargePageReducer = handleActions(
  {
    [initializeChargePage().type]: () => initializeState.detail,
    [changeChargePage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const vehicleChargePageReducer = handleActions(
  {
    [initializeVehicleChargePage().type]: () => initializeState.vehicleChargeList,
    [changeVehicleChargePage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const vehiclePageReducer = handleActions(
  {
    [initializeVehiclePage().type]: () => initializeState.vehicleList
  },
  {},
  { produce }
);
const chargeForVehiclePageReducer = handleActions(
  {
    [initializeChargeForVehiclePage().type]: () => initializeState.chargeForVehicleList,
    [changeChargeForVehiclePage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const chargeNameDuplicateReducer = handleActions(
  {
    [initializeChargeNameDuplicate().type]: () => initializeState.duplicate
  },
  {},
  { produce }
);

// page action reducer 선언
export const charge = combineReducers({
  list: createReducer({
    entity: entityCharges,
    reducer: chargesPageReducer
  }),
  detail: createReducer({
    entity: entityCharge,
    reducer: chargePageReducer
  }),
  vehicleChargeList: createReducer({
    entity: entityVehicleCharge,
    reducer: vehicleChargePageReducer
  }),
  vehicleList: createReducer({
    entity: entityVehicle,
    reducer: vehiclePageReducer
  }),
  chargeForVehicleList: createReducer({
    entity: entityChargeForVehicle,
    reducer: chargeForVehiclePageReducer
  }),
  duplicate: createReducer({
    entity: entityCheckChargeNameDuplicate,
    reducer: chargeNameDuplicateReducer
  })
});
/** ********************************************** */
