import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeLoadAllCompanyUsers,
  loadAllCompanyUsers,
  initializeLoadAllCompanyUser,
  loadAllCompanyUser,
  initializeCompanyUsersPage,
  initializeCompanyUserPage,
  changeCompanyUsersPage,
  getCompanyUsers,
  deleteForCompanyUsers,

  changeCompanyUserPage,
  getCompanyUser,
  createCompanyUser,
  updateCompanyUser,
  deleteCompanyUser
} = createActions(
  {
    LOAD_ALL_COMPANY_USERS: (filters) => ({ filters }),
    LOAD_ALL_COMPANY_USER: (ID) => ({ ID }),
    GET_COMPANY_USERS: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_COMPANY_USER: (companyID, ID) => ({ companyID, ID }),
    CREATE_COMPANY_USER: (data) => ({ data }),
    UPDATE_COMPANY_USER: (ID, data) => ({ ID, data }),
    DELETE_COMPANY_USER: (data) => ({ ...data })
  },
  'INITIALIZE_LOAD_ALL_COMPANY_USERS',
  'INITIALIZE_LOAD_ALL_COMPANY_USER',
  'INITIALIZE_COMPANY_USERS_PAGE',
  'CHANGE_COMPANY_USERS_PAGE',
  'DELETE_FOR_COMPANY_USERS',
  'INITIALIZE_COMPANY_USER_PAGE',
  'CHANGE_COMPANY_USER_PAGE'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllCompanyUsers = createEntity({
  key: loadAllCompanyUsers().type,
  parseData: parseDataForList
});

export const entityCompanyUsers = createEntity({
  key: getCompanyUsers().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityLoadAllCompanyUser = createEntity({
  key: loadAllCompanyUser().type,
  parseData: parseDataForList
});

export const entityCompanyUser = createEntity({
  key: getCompanyUser().type
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create'
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  },
  loadUser: {
    data: [],
    loading: false
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const companyUsersPageReducer = handleActions(
  {
    [initializeLoadAllCompanyUsers().type]: () => initializeState.list,
    [initializeCompanyUsersPage().type]: () => initializeState.list,
    [changeCompanyUsersPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const companyUsersLoadAllReducer = handleActions(
  {
    [initializeLoadAllCompanyUsers().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const companyUserPageReducer = handleActions(
  {
    [initializeCompanyUserPage().type]: () => initializeState.detail,
    [changeCompanyUserPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const companyUserLoadAllReducer = handleActions(
  {
    [initializeLoadAllCompanyUser().type]: () => initializeState.loadUser
  },
  {},
  { produce }
);

// page action reducer 선언
export const companyUser = combineReducers({
  list: createReducer({
    entity: entityCompanyUsers,
    reducer: companyUsersPageReducer
  }),
  detail: createReducer({
    entity: entityCompanyUser,
    reducer: companyUserPageReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllCompanyUsers,
    reducer: companyUsersLoadAllReducer
  }),
  loadUser: createReducer({
    entity: entityLoadAllCompanyUser,
    reducer: companyUserLoadAllReducer
  })
});
/** ********************************************** */
