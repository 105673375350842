import styled, { keyframes, css } from 'styled-components';
import { FMSColors } from 'service/common/designLib';
import withAuth from 'service/auth/withAuth';
import FMSButtonBase from './FMSButtonBase';

export const FMSBtnForm = withAuth(styled((props) => FMSButtonBase({
    ...{
      filled: false,
      round: 1 / 6
    },
    ...props
  }))`
  min-width: 140px;
  height: ${(props) => props.height || '46px'};
  .btnLabel {
    margin: 14px;
    font-size: 14px;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnFormPrimary = withAuth(styled((props) => FMSButtonBase({
    ...{
      filled: true,
      hasBoxShadow: true,
      color: FMSColors.white,
      backgroundColor: FMSColors.primary,
      round: 1 / 6
    },
    ...props
  }))`
  min-width: 150px;
  height: ${(props) => props.height || '46px'};
  .btnLabel {
    font-size: 14px;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnFormGrey = withAuth(styled((props) => FMSButtonBase({
    ...{
      filled: true,
      hasBoxShadow: true,
      boxShadowColor: 'rgba(0, 0, 0, 0.1)',
      color: FMSColors.grey,
      backgroundColor: FMSColors.whiteSmoke,
      borderColor: '#dddddd',
      round: 1 / 6
    },
    ...props
  }))`
  min-width: 150px;
  height: ${(props) => props.height || '46px'};
  .btnLabel {
    font-size: 14px;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnFormInner = withAuth(styled((props) => FMSButtonBase({
    ...{
      hasBoxShadow: true,
      filled: true,
      borderColor: FMSColors.primary,
      backgroundColor: FMSColors.secondary,
      color: FMSColors.primary
    },
    ...props
  }))`
  min-width: 140px;
  max-width: 140px;
  height: 40px;
  ${(props) => props.active
      ? `background-color: ${FMSColors.primaryHover};
         color: #fff;
         transition: color 0.2s;
         &:hover {
           background-color: ${FMSColors.primaryHover};
         }`
      : ''};
  .btnLabel {
    display: block;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnList = withAuth(styled((props) => FMSButtonBase({
    ...{
      filled: true,
      isVariation: true,
      borderColor: FMSColors.primary,
      color: FMSColors.primary,
      backgroundColor: FMSColors.white
    },
    ...props
  }))`
  min-width: 80px;
  height: 40px;
  .btnLabel {
    display: block;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnListIcon = withAuth(styled(FMSButtonBase)`
  padding: 1px 6px;
  .btnLabel {
    width: 32px;
    height: 32px;
    line-height: 32px;
    display: block;
    overflow: hidden;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnListDate = withAuth(styled((props) => FMSButtonBase({
    ...{
      isVariation: true
    },
    ...props
  }))`
  padding: 1px 6px;
  width: auto;
  white-space: nowrap;
  word-break: keep-all;
  min-width: 60px;
  height: 40px;
  :first-of-type {
    :hover {
      border-right-width: 0.5px !important;
    }
  }
  :last-child {
    :hover {
      border-left-width: 0.5px !important;
    }
  }
  :not(:first-of-type):not(:last-child) {
    :hover {
      border-width: 1.5px !important;
    }
  }
  .btnLabel {
    display: block;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnFormUpdate = withAuth(styled((props) => FMSButtonBase({
    ...{
      filled: props.filled,
      isVariation: true,
      borderColor: FMSColors.primary,
      color: FMSColors.white,
      backgroundColor: FMSColors.primary
    },
    ...props
  }))`
  min-width: 80px;
  height: ${(props) => props.height || '40px'};
  .btnLabel {
    font-family: 'kiam', Tahoma, sans-serif;
    display: block;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnTable = withAuth(styled((props) => FMSButtonBase({
    ...{
      filled: true,
      color: '#ffffff',
      backgroundColor: '#56cccc',
      round: 1 / 6
    },
    ...props
  }))`
  min-width: 66px;
  height: 28px;
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnLogout = withAuth(styled((props) => FMSButtonBase({
    ...{
      filled: true,
      color: '#ffffff',
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      round: 5
    },
    ...props
  }))`
  min-width: 80px;
  height: 24px;
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnRound = withAuth(styled((props) => FMSButtonBase({
    ...{
      filled: true,
      color: FMSColors.grey,
      backgroundColor: FMSColors.white,
      borderColor: FMSColors.secondaryHover,
      round: 18
    },
    ...props
  }))`
  min-width: 70px;
  height: 36px;

  font-size: 14px;
  font-weight: 400;

  ${({ active, activeCss }) => active
      ? `
    ${activeCss}
  `
      : ''};

  &:disabled {
    opacity: 0.5;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnRefresh = withAuth(styled((props) => FMSButtonBase({
    ...{ filled: true, backgroundColor: FMSColors.primary },
    ...props
  }))`
  position: relative;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 3px;
  vertical-align: bottom;
  overflow: hidden;
  outline: none;
  & span:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: url('${process.env.PUBLIC_URL}/assets/images/icons/refresh.svg') no-repeat center;
    background-size: 24px;
    ${(props) => (props.isLoading ? rotateAnimation : '')}
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnPopup = withAuth(styled((props) => FMSButtonBase({
    ...{
      color: props.filled ? '#ffffff' : '#dddddd',
      // ?ackgroundColor: props.filled ? '#ac2b31' : '#999999',
      backgroundColor: props.filled ? '#05141F' : '#999999',
      borderColor: props.filled ? undefined : '#dddddd'
    },
    ...props
  }))`
  min-width: 100px;
  height: 36px;
  .btnLabel {
    margin: 7px;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FMSBtnModal = withAuth(styled((props) => FMSButtonBase({
    ...{
      color: props.filled ? FMSColors.white : FMSColors.grey,
      backgroundColor: props.filled ? FMSColors.primary : '#fbfbfb',
      borderColor: props.filled ? undefined : FMSColors.line,
      flat: true
    },
    ...props
  }))`
  min-width: 100px;
  height: 36px;
  .btnLabel {
    margin: 7px;
  }
`)({
  aclAuth: 'ALL',
  aclType: 'R'
});

export const FormButtonContainer = styled.div`
  width: 100%;
  text-align: ${(props) => (props.align ? props.align : 'right')};
  & button:not(:first-of-type) {
    margin-left: 10px;
  }
`;

export const CenterButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  & button:not(:first-of-type) {
    margin-left: 10px;
  }
`;

// animations
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const rotateAnimation = css`
  animation: ${rotate} 1s ease-in-out infinite forwards;
`;
