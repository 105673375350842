import React from 'react';
import { Provider } from 'react-redux';
import store from 'v2/redux/store';
import IntlApp from './IntlApp';

const Root = () => (
  <Provider store={store}>
    <IntlApp />
  </Provider>
);

export default React.memo(Root);
