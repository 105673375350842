import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeLoadAllSurvey,
  loadAllSurvey,
  initializeSurveyListPage,
  changeSurveyListPage,
  getSurveyList
} = createActions(
  {
    LOAD_ALL_SURVEY: (filters) => ({ filters }),
    GET_SURVEY_LIST: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    })
  },
  'INITIALIZE_LOAD_ALL_SURVEY',
  'INITIALIZE_SURVEY_LIST_PAGE',
  'CHANGE_SURVEY_LIST_PAGE'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllSurvey = createEntity({
  key: loadAllSurvey().type,
  parseData: parseDataForList
});

export const entitySurveyList = createEntity({
  key: getSurveyList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  // detail: {
  //   data: null,
  //   loading: false,
  //   mode: 'create',
  // },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const surveyListPageReducer = handleActions(
  {
    [initializeSurveyListPage().type]: () => initializeState.list,
    [changeSurveyListPage().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
);
const surveyListLoadAllReducer = handleActions(
  {
    [initializeLoadAllSurvey().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);

// page action reducer 선언
export const survey = combineReducers({
  list: createReducer({
    entity: entitySurveyList,
    reducer: surveyListPageReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllSurvey,
    reducer: surveyListLoadAllReducer
  })
});
/** ********************************************** */
