import request from 'apis/request';

const prefix = '/survey';

function getSurveySortFieldNum(field) {
  console.log('설문조사리스트 조회');
  switch (field) {
    default:
    case 'createdAt':
      return 1;
  }
}

/*
  필요한 api
  1. pageList 데이터 조회
  2. 등록하는 부분
  3. 검색해서 pageList 데이터 조회
  4. 등록페이지에서 저장
  5. 취소
*/

export const getAllSurveyAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 설문조사 리스트 조회
 */
export const getSurveyListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  console.log('API출력');
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSurveySortFieldNum(field);

  console.log('data======>', data);
  return request({
    url: `${prefix}`,
    data
  });
};
