import request from 'apis/request';

const prefix = '/monitoring';

/**
 * 은퇴차량조회
 */
export const getVehicleRetiredListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;
  console.log('getVehicleRetiredListAPI data:', data);
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;

  return request({
    url: `${prefix}/retire`,
    data
  });
};
export const recoverVehiclesAPI = (data) => {
  console.log('data:', data);
  return request({
    url: `vehicle/${data.ID}/recover`,
    method: 'patch'
  });
};
