import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FMSFontSize, FMSColors } from 'service/common/designLib';
import { getValueAlternative } from 'service/utils/FMSValueUtil';
import { useFormContext } from 'react-hook-form';

const CheckboxWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
  }
  div {
    display: flex;
    align-items: center;
    font-size: ${({ fontSize }) => fontSize || FMSFontSize.checkButton};
    color: ${({ fontColor }) => fontColor || FMSColors.dark};
    img {
      background-size: contain;
    }
  }
`;

const FMSCheckbox = ({
  checked,
  value,
  defaultChecked,
  disabled,
  readOnly,
  name,
  size = 24,
  label,
  labelPosition = 'right',
  labelIsCode = true,
  onCheckChange,

  styleWrapper,
  fontSize,
  fontColor,
  style,
  triggerValidationOff = false,
  mode
}) => {
  const formContext = useFormContext();
  const { register, triggerValidation } = formContext || {};
  const [localChecked, setLocalChecked] = useState(
    getValueAlternative(checked, defaultChecked, false)
  );

  useEffect(() => setLocalChecked(getValueAlternative(checked, defaultChecked, false)), [
    defaultChecked
  ]);

  const onClick = () => {
    if (!triggerValidationOff && triggerValidation) triggerValidation();
    if (onCheckChange) onCheckChange(!localChecked, name);
    setLocalChecked((prevLocalChecked) => !prevLocalChecked);
  };

  const setLable = (isCode, label) => (
    <span>{isCode ? <FormattedMessage id={label} /> : label}</span>
  );

  const CheckboxComponent = styleWrapper || CheckboxWrapper;
  return mode === 'view' ? (
    (checked !== null && checked !== undefined ? (
      checked
    ) : (
      localChecked
    )) ? (
      label && setLable(labelIsCode, label)
    ) : null
  ) : (
    <CheckboxComponent {...{ style, fontSize, fontColor }}>
      <input
        ref={register}
        checked={checked !== null && checked !== undefined ? checked : localChecked}
        disabled={disabled}
        name={name}
        readOnly={readOnly}
        type='checkbox'
        value={value}
        onChange={() => null}
      />
      <div onClick={!disabled ? onClick : () => null}>
        {label && labelPosition === 'left' && setLable(labelIsCode, label)}
        <img
          alt='checkbox'
          height={`${size}px`}
          src={`${process.env.PUBLIC_URL}/assets/images/checkbox${
            disabled || readOnly ? '-disabled' : ''
          }${localChecked ? '-on' : ''}.svg`}
          style={{ marginLeft: '5px', marginRight: '5px' }}
          width={`${size}px`}
        />
        {label && labelPosition === 'right' && setLable(labelIsCode, label)}
      </div>
    </CheckboxComponent>
  );
};
export default React.memo(FMSCheckbox);
