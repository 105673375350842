import React from 'react';

class Marker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false
    };
  }

  componentDidMount() {
    const { lat, lng, draggable, map, behavior, ui, onDrag, onClick, image, content } = this.props;
    if (map) {
      if (image) {
        // 마커이미지 존재
        const markerIcon = new window.H.map.Icon(image, {
          size: { w: 48, h: 62 },
          anchor: { x: 24, y: 55 }
        });

        this.marker = new window.H.map.Marker(
          new window.H.geo.Point(lat, lng), // 마커의 좌표
          { icon: markerIcon, volatility: true }
        );
      } else {
        // 기본이미지
        this.marker = new window.H.map.Marker(
          new window.H.geo.Point(lat, lng), // 마커의 좌표
          { volatility: true }
        );
      }
      if (content) {
        this.marker.setData(content);
      }

      if (draggable) {
        this.marker.draggable = draggable;
      }

      onClick
        && this.marker.addEventListener(
          'tap',
          (e) => {
            const { target } = e;
            const infoBubble = new window.H.ui.InfoBubble(target.getGeometry(), {
              // read custom data
              content: target.getData()
            });
            // remove infobubble
            ui.getBubbles().forEach((bubble) => ui.removeBubble(bubble));
            // show info bubble
            ui.addBubble(infoBubble);
          },
          false
        );
      // marker drag는 3개의 이벤트의 조합으로 이루어진다.
      onDrag
        && this.marker.addEventListener(
          'dragstart',
          (e) => {
            const { target } = e;
            const pointer = e.currentPointer;
            const targetPosition = map.geoToScreen(target.getGeometry());
            target.offset = new window.H.math.Point(
              pointer.viewportX - targetPosition.x,
              pointer.viewportY - targetPosition.y
            );
            behavior.disable();
          },
          false
        );

      onDrag
        && this.marker.addEventListener(
          'drag',
          (e) => {
            const { target } = e;
            const pointer = e.currentPointer;
            target.setGeometry(
              map.screenToGeo(
                pointer.viewportX - target.offset.x,
                pointer.viewportY - target.offset.y
              )
            );
          },
          false
        );

      onDrag
        && this.marker.addEventListener(
          'dragend',
          (e) => {
            behavior.enable();
            onDrag(this.marker);
          },
          false
        );

      map.addObject(this.marker);
    }
    this.setState({ init: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { map, lat, lng, image, title, language } = this.props;
    if (prevProps.lat !== lat || prevProps.lng !== lng || prevProps.language !== language) {
      this.marker.setGeometry(new window.H.geo.Point(lat, lng));
    }
    if (prevProps.image !== image) {
      this.marker.setIcon(
        new window.H.map.Icon(image, { size: { w: 48, h: 62 }, anchor: { x: 24, y: 55 } })
      );
    }
  }

  componentWillUnmount() {
    const { markerClusterer, map } = this.props;
    if (markerClusterer) {
      markerClusterer.removeMarker(this.marker);
    }
    map.removeObject(this.marker);
  }

  render() {
    const { init } = this.state;
    const { children, map } = this.props;

    return (
      <>
        {init
          && React.Children.map(children, (child) => React.cloneElement(child, { map, marker: this.marker }))}
      </>
    );
  }
}

export default Marker;
