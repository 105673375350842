import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { put } from 'redux-saga/effects';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { getErrorMessage } from '../../../../constants/errors';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeTaxRateListPage,
  getTaxRateList,
  updateTaxRate,
  changeTaxRateDetailPage
} = createActions(
  {
    GET_TAX_RATE_LIST: (countryID) => ({ countryID })
  },
  'UPDATE_TAX_RATE',
  'CHANGE_TAX_RATE_DETAIL_PAGE',
  'INITIALIZE_TAX_RATE_LIST_PAGE'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityTaxRateList = createEntity({
  key: getTaxRateList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityUpdateTaxRate = createEntity({
  key: updateTaxRate().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { countryID } = requestParams || null;
    if (countryID) yield put(getTaxRateList(countryID));
  }
});

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: null,
    mode: 'view',
    loading: false,
    error: null
  },
  detail: {
    data: null,
    loading: false,
    mode: 'view',
    error: null
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.

const taxRateListPageReducer = handleActions(
  {
    [initializeTaxRateListPage().type]: () => initializeState.list,
    [changeTaxRateDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const taxRate = combineReducers({
  list: createReducer({
    entity: entityTaxRateList,
    reducer: taxRateListPageReducer
  })
});
/** ********************************************** */
