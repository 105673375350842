import { combineReducers } from 'redux';
import { createActions } from 'redux-actions';
import { createEntityNew, createReducerNew, fetchSagaNew } from 'v2/redux/lib/reducerLibNew';
import { takeLatest } from 'redux-saga/effects';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import {
  getAccessoryModelListAPI,
  getAccessoryModelListForItemAPI,
  getAccessoryModelAPI,
  createAccessoryModelAPI,
  updateAccessoryModelAPI,
  deleteAccessoryModelAPI,
  updateAccessoryModelVehiclesAPI,
  getAccessoryVehicleListAPI,
  getAccessoryDealerModelListAPI
} from 'v2/apis/accessoryModelApi';
import { getErrorMessage } from 'constants/errors';

export const {
  getAccessoryModelList,
  getAccessoryModelListForItem,
  getAccessoryModelDetail,
  getAccessoryVehicleList,
  createAccessoryModel,
  updateAccessoryModel,
  updateAccessoryModelVehicles,
  deleteAccessoryModel,
  getAccessoryDealerModelList
} = createActions({
  GET_ACCESSORY_MODEL_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
  GET_ACCESSORY_MODEL_LIST_FOR_ITEM: (pageInfo, sortInfo, filters) => ({
    pageInfo,
    sortInfo,
    filters
  }),
  GET_ACCESSORY_MODEL_DETAIL: (ID) => ({ ID }),
  GET_ACCESSORY_VEHICLE_LIST: (businessId) => ({ businessId }),
  CREATE_ACCESSORY_MODEL: (data, image) => ({ data, image }),
  UPDATE_ACCESSORY_MODEL: (ID, data, image) => ({ ID, data, image }),
  UPDATE_ACCESSORY_MODEL_VEHICLES: (ID, data, selectedVehicleList) => ({
    ID,
    data,
    selectedVehicleList
  }),
  DELETE_ACCESSORY_MODEL: (data) => {
    return data;
  },
  GET_ACCESSORY_DEALER_MODEL_LIST: (
    categoryCd,
    businessId,
    branchId,
    vehicleId,
    startTimeMill,
    endTimeMill
  ) => ({
    categoryCd,
    businessId,
    branchId,
    vehicleId,
    startTimeMill,
    endTimeMill
  })
});

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  vehicleList: {
    data: []
  },
  detail: {
    data: {
      availableVehicleModels: null // []
    },
    mode: 'view' // view, edit, create
  },
  create: { apiState: '' },
  update: { apiState: '' },
  updateVehicles: { apiState: '' },
  delete: { apiState: '' },
  dealerList: {
    data: []
  }
};

const entityModelList = createEntityNew({
  key: getAccessoryModelList().type,
  apiFn: getAccessoryModelListAPI,
  defaultState: initializeState.list,
  parseData: (action) => parseDataForList(action, 'stockItems'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const entityModelListForItem = createEntityNew({
  key: getAccessoryModelList().type,
  apiFn: getAccessoryModelListForItemAPI,
  defaultState: initializeState.list,
  parseData: (action) => parseDataForList(action, 'stockItems'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const entityVehicleList = createEntityNew({
  key: getAccessoryVehicleList().type,
  apiFn: getAccessoryVehicleListAPI,
  defaultState: initializeState.vehicleList,
  parseData: (action) => parseDataForList(action, 'modelList'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const entityModelDetail = createEntityNew({
  key: getAccessoryModelDetail().type,
  apiFn: getAccessoryModelAPI,
  defaultState: initializeState.detail,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

const entityModelCreate = createEntityNew({
  key: createAccessoryModel().type,
  apiFn: createAccessoryModelAPI,
  confirmMessage: 'Common.Confirm.Create',
  defaultState: initializeState.create,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
  }
});

const entityModelUpdate = createEntityNew({
  key: updateAccessoryModel().type,
  apiFn: updateAccessoryModelAPI,
  confirmMessage: 'Common.Confirm.Update',
  defaultState: initializeState.update,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Updated.Success');
  }
});

const entityModelVehiclesUpdate = createEntityNew({
  key: updateAccessoryModelVehicles().type,
  apiFn: updateAccessoryModelVehiclesAPI,
  defaultState: initializeState.updateVehicles,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  }
});

const entityModelDelete = createEntityNew({
  key: deleteAccessoryModel().type,
  apiFn: deleteAccessoryModelAPI,
  defaultState: initializeState.delete,
  confirmMessage: 'Common.Confirm.Delete',
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
  }
});

const entityDealerModelList = createEntityNew({
  key: getAccessoryDealerModelList().type,
  apiFn: getAccessoryDealerModelListAPI,
  defaultState: initializeState.list,
  parseData: (action) => parseDataForList(action, 'accessorySvcItems'),
  afterFailureFn: () => {
    FMSCommon.toast.fail('ERROR_CODE_INTERNAL_SERVER_ERROR');
  }
});

export const accessoryModel = combineReducers({
  list: createReducerNew(entityModelList),
  listItem: createReducerNew(entityModelList),
  vehicleList: createReducerNew(entityVehicleList),
  detail: createReducerNew(entityModelDetail),
  create: createReducerNew(entityModelCreate),
  update: createReducerNew(entityModelUpdate),
  updateVehicles: createReducerNew(entityModelVehiclesUpdate),
  delete: createReducerNew(entityModelDelete),
  dealerList: createReducerNew(entityDealerModelList)
});

export default function* watcher() {
  yield takeLatest(getAccessoryModelList().type, fetchSagaNew, entityModelList);
  yield takeLatest(getAccessoryModelListForItem().type, fetchSagaNew, entityModelListForItem);
  yield takeLatest(getAccessoryVehicleList().type, fetchSagaNew, entityVehicleList);
  yield takeLatest(getAccessoryModelDetail().type, fetchSagaNew, entityModelDetail);
  yield takeLatest(createAccessoryModel().type, fetchSagaNew, entityModelCreate);
  yield takeLatest(updateAccessoryModel().type, fetchSagaNew, entityModelUpdate);
  yield takeLatest(updateAccessoryModelVehicles().type, fetchSagaNew, entityModelVehiclesUpdate);
  yield takeLatest(deleteAccessoryModel().type, fetchSagaNew, entityModelDelete);
  yield takeLatest(getAccessoryDealerModelList().type, fetchSagaNew, entityDealerModelList);
}
