import request from 'apis/request';

const prefix = '/appVersion';

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'appVersion':
      return 1;
    case 'buildVersion':
      return 2;
    case 'os':
      return 3;
    case 'updateType':
      return 4;
  }
}

/**
 * 앱버전관리 리스트 조회
 */
export const getAppVersionListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page, limit } = pageInfo;
  const { field, order } = sortInfo;
  const { ...data } = filters;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 앱버전관리 삭제 기능
 */
export const deleteAppVersionAPI = ({ ID }) => {
  return request({
    method: 'delete',
    url: `${prefix}/${ID}`
  });
};

/**
 * 앱버전관리 등록 기능
 */
export const createAppVersionAPI = (inputData) => {
  return request({
    url: `${prefix}`,
    method: 'post',
    data: inputData.data
  });
};
