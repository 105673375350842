import { put, select, takeLatest } from 'redux-saga/effects';
import {
  getVehicleInsuranceMonthAPI,
  deleteVehicleInsuranceMonthAPI,
  getDetailVehicleInsuranceAPI,
  updateVehicleInsuranceAPI,
  getInsurancePaymentAPI,
  createVehicleInsuranceMonthAPI,
  updateInsurancePaymentAPI,
  getVehicleInsurancePaymentDetailAPI,
  updateInsurancePaymentForVehicleAPI
} from 'v2/apis/vehicleInsuranceApi';
import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';
import {
  getVehicleInsuranceMonthList,
  entityVehicleInsuranceMonth,
  deleteVehicleInsuranceMonth,
  changeVehicleInsuranceDetail,
  getVehicleInsuranceDetail,
  entityVehicleInsuranceDetail,
  updateVehicleInsurance,
  createVehicleInsuranceMonth,
  entityInsurancePaymentList,
  getInsurancePaymentList,
  updateInsurancePayment,
  updateInsurancePaymentForVehicle,
  entityVehicleInsurancePaymentDetail,
  getVehicleInsurancePaymentDetail,
  entityVehicleInsuranceDetailChange,
  getVehicleInsuranceDetailChange
} from '.';

export const historyUrl = '/vehicleInsurance';
export const selectorCompanyListPage = (state) => state.vehicleInsurance.list;

export const entityDeleteVehicleInsuranceMonth = createEntity({
  key: deleteVehicleInsuranceMonth().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Deleted.Success');
    const { vehicleID, insureYear } = requestParam;
    yield put(getInsurancePaymentList(vehicleID, insureYear));
  }
});

export const entityUpdateVehicleInsurance = createEntity({
  key: updateVehicleInsurance().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { vehicleID } = payload;
    yield put(getVehicleInsuranceDetail(vehicleID));
  }
});

export const entityCreateVehicleInsurance = createEntity({
  key: createVehicleInsuranceMonth().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Created.Success');
    const { ID } = payload;
    const { insureYear } = requestParam;
    yield put(getVehicleInsuranceDetail(ID));
    yield put(getInsurancePaymentList(ID, insureYear));
  }
});

export const entityUpdateInsurancePayment = createEntity({
  key: updateInsurancePayment().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { ID } = requestParam;
    const selectorListPage = yield select(selectorCompanyListPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getVehicleInsuranceMonthList(pageInfo, sortInfo, filters));
    yield put(getVehicleInsuranceDetail(ID));
  }
});

export const entityUpdateInsurancePaymentForVehicle = createEntity({
  key: updateInsurancePaymentForVehicle().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload, requestParam) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { vehicleID } = requestParam;
    const { insureYear } = payload;
    yield put(getInsurancePaymentList(vehicleID, insureYear));
  }
});

const getVehicleInsuranceMonthAsync = createSagaAction.bind(
  null,
  entityVehicleInsuranceMonth,
  getVehicleInsuranceMonthAPI
);
const deleteVehicleInsuranceMonthAsync = createSagaAction.bind(
  null,
  entityDeleteVehicleInsuranceMonth,
  deleteVehicleInsuranceMonthAPI
);
const getVehicleInsuranceDetailAsync = createSagaAction.bind(
  null,
  entityVehicleInsuranceDetail,
  getDetailVehicleInsuranceAPI
);
const updateVehicleInusranceAsync = createSagaAction.bind(
  null,
  entityUpdateVehicleInsurance,
  updateVehicleInsuranceAPI
);
const getInsurancePaymentAsync = createSagaAction.bind(
  null,
  entityInsurancePaymentList,
  getInsurancePaymentAPI
);
const createVehicleInsuranceAsync = createSagaAction.bind(
  null,
  entityCreateVehicleInsurance,
  createVehicleInsuranceMonthAPI
);
const updateInsurancePaymentAsync = createSagaAction.bind(
  null,
  entityUpdateInsurancePayment,
  updateInsurancePaymentAPI
);
const getVehicleInsurancePaymentAsync = createSagaAction.bind(
  null,
  entityVehicleInsurancePaymentDetail,
  getVehicleInsurancePaymentDetailAPI
);
const updateInsurancePaymentForVehicleAsync = createSagaAction.bind(
  null,
  entityUpdateInsurancePaymentForVehicle,
  updateInsurancePaymentForVehicleAPI
);
const getVehicleInsuranceDetailChangeAsync = createSagaAction.bind(
  null,
  entityVehicleInsuranceDetailChange,
  getDetailVehicleInsuranceAPI
);

function* changeVehicleInsuranceDetailPageSaga() {
  yield put(changeVehicleInsuranceDetail({ mode: 'view' }));
}

export default function* vehicleInsurance() {
  yield takeLatest(
    getVehicleInsuranceMonthList().type,
    fetchSaga,
    getVehicleInsuranceMonthAsync,
    entityVehicleInsuranceMonth
  );
  yield takeLatest(
    deleteVehicleInsuranceMonth().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteVehicleInsuranceMonthAsync,
    entityDeleteVehicleInsuranceMonth
  );
  yield takeLatest(
    getVehicleInsuranceDetail().type,
    fetchSaga,
    getVehicleInsuranceDetailAsync,
    entityVehicleInsuranceDetail
  );
  yield takeLatest(
    updateVehicleInsurance().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateVehicleInusranceAsync,
    entityUpdateVehicleInsurance
  );
  yield takeLatest(entityUpdateVehicleInsurance.types[1], changeVehicleInsuranceDetailPageSaga);
  yield takeLatest(
    getInsurancePaymentList().type,
    fetchSaga,
    getInsurancePaymentAsync,
    entityInsurancePaymentList
  );
  yield takeLatest(
    createVehicleInsuranceMonth().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createVehicleInsuranceAsync,
    entityCreateVehicleInsurance
  );
  yield takeLatest(
    updateInsurancePayment().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateInsurancePaymentAsync,
    entityUpdateInsurancePayment
  );
  yield takeLatest(
    getVehicleInsurancePaymentDetail().type,
    fetchSaga,
    getVehicleInsurancePaymentAsync,
    entityVehicleInsurancePaymentDetail
  );
  yield takeLatest(
    updateInsurancePaymentForVehicle().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateInsurancePaymentForVehicleAsync,
    entityUpdateInsurancePaymentForVehicle
  );
  yield takeLatest(
    getVehicleInsuranceDetailChange().type,
    fetchSaga,
    getVehicleInsuranceDetailChangeAsync,
    entityVehicleInsuranceDetailChange
  );
}
