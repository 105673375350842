import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';
import { put } from 'redux-saga/effects';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { PAGE_LIMIT } from '../../../../constants';
import { getErrorMessage } from '../../../../../constants/errors';
import { initializeVehiclesListPage } from '../basic';
import { parseDataForList } from '../../lib/actionLib';

export const {
  getVehicleRetiredList /** 전체조회 */,
  recoverVehicles,
  changeVehiclesListPage,
  initializeVehicleRetiredListPage
} = createActions(
  {
    GET_VEHICLE_RETIRED_LIST: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    })
  },
  'RECOVER_VEHICLES',
  'CHANGE_VEHICLES_LIST_PAGE',
  'INITIALIZE_VEHICLE_RETIRED_LIST_PAGE'
);

export const entityVehicleRetiredList = createEntity({
  key: getVehicleRetiredList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityRecoverVehicles = createEntity({
  key: recoverVehicles().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    yield put(changeVehiclesListPage({ vehiclesState: 'pending' }));
    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Recover.Success');
    yield put(changeVehiclesListPage({ vehiclesState: 'recovered' }));
  },
  *afterFailureFn(payload) {
    if (payload && _.isArray(payload) && _.size(payload) > 0) {
      if (payload[0].error) {
        FMSCommon.toast.fail(getErrorMessage(payload[0].error.errorCode));
      }
    } else FMSCommon.toast.fail('VehicleRetired.Recover.Fail');

    yield put(changeVehiclesListPage({ vehiclesState: 'recovered' }));
  }
});

export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    },
    vehiclesState: 'pending'
  }
};

const vehicleRetiredPageReducer = handleActions(
  {
    [initializeVehicleRetiredListPage().type]: () => initializeState.list,
    [changeVehiclesListPage().type]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  {},
  { produce }
); /** 전체용 */

export const vehicleRetired = combineReducers({
  list: createReducer({
    /** 전체용 */
    entity: entityVehicleRetiredList,
    reducer: vehicleRetiredPageReducer
  })
});
/** ********************************************** */
