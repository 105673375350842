import React, { memo } from 'react';
import ConfirmModalPage from './ConfirmModalPage';
import PostcodeModalPage from './PostcodeModalPage';

const ModalRootPage = () => {
  return (
    <>
      <ConfirmModalPage />
      <PostcodeModalPage />
    </>
  );
};

export default memo(ModalRootPage);
