import request from 'apis/request';

const prefix = '/vehicle';

export const uploadVehicleRegistrationCardImageAPI = ({ image }) => {
  const data = new FormData();
  data.append('vcregistrationImage', image);

  return request({
    config: {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    },
    url: `${prefix}/uploadvcregistrationcard`,
    method: 'post',
    data
  });
};
