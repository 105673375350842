import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FMSIconRound, FMSImage } from 'components/ui/tags/FMSTags';
import { FMSCommon } from 'service/common/commonLib';
import { FMSColors } from 'service/common/designLib';

const Container = styled.div`
  ${(props) => props.mode === 'standard' && 'cursor: pointer'}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 340px;
  border-radius: 3px;
  background-repeat: none;
  background-color: ${FMSColors.whiteSmoke};
`;

const DashedContainer = styled.div.attrs({ className: 'amb-dashboard-container' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 260px;
  font-size: 16px;
  color: #999;
  border-radius: 3px;
  border: dashed 2px #dddddd;
`;

const maxSize = 500 * 1024; // 500kb
const FMSInputImage = ({ standard, imageURL, mode }) => {
  const input = React.useRef();
  const [image, setImage] = useState(imageURL);
  useEffect(() => {
    if (input.current) input.current.value = '';
    setImage(imageURL);
  }, [imageURL]);
  const onChangeImage = (e) => {
    if (e.target.files) {
      if (!e.target.files[0]) return;
      if (e.target.files[0].size > maxSize) {
        FMSCommon.toast.fail('Alert.Image.SizeLimitExceeded');
        e.target.value = '';
        setImage('');
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <>
      {mode === 'view' || mode === 'edit' ? (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            margin: '10px',
            marginBottom: '50px'
          }}
        >
          <div style={{ width: '44.9%' }}>
            {standard === 'true' && (
              <FMSIconRound>
                <FormattedMessage id='VehicleModel.StandardTag' />
              </FMSIconRound>
            )}
            <FMSImage
              imageURL={image}
              style={{
                width: '100%',
                height: '120px',
                ...(mode === 'edit' && standard === 'true' && { cursor: 'pointer' })
              }}
              {...(mode === 'edit'
                && standard === 'true' && {
                  onClick: () => input.current.click()
                })}
            />
          </div>
        </div>
      ) : (
        <Container
          {...(mode === 'standard' && {
            mode,
            onClick: () => input.current.click()
          })}
        >
          <DashedContainer>
            {image ? (
              <div
                style={{
                  width: '80%',
                  height: '80%',
                  background: `url(${image}) no-repeat center center/contain`
                }}
              />
            ) : (
              <>
                {mode === 'standard' && (
                  <img alt='+' src={`${process.env.PUBLIC_URL}/assets/images/plus.svg`} />
                )}
                <span style={{ margin: '10px' }}>Car Image</span>
              </>
            )}
          </DashedContainer>
        </Container>
      )}
      <input
        ref={input}
        hidden
        accept='image/*'
        name='image'
        type='file'
        onChange={onChangeImage}
      />
    </>
  );
};

export default FMSInputImage;
