import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { FMSColors } from 'service/common/designLib';

const Container = styled.div`
  width: 400px;
  height: 40px;
`;

const FMSSelect = styled(Select)`
  border-radius: 1px;
  font-size: 14px;
  line-height: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ::-ms-expand {
    display: none;
  }
`;

const CustomMultiSelect = ({ ...props }) => {
  const {
    options,
    placeholder,
    style,
    controlStyle,
    optionStyle,
    multiValueStyle,
    multiValueLabelStyle,
    multiValueRemoveStyle,
    className,
    defaultValue, // Unused
    value,
    onChange,
    ...rest
  } = props;

  const customStyles = {
    control: (provided) => {
      return {
        ...provided,
        borderRadius: '0px',
        border: '1px solid #dddddd',
        backgroundColor: '#ffffff',
        fontSize: '14px',
        lineHeight: '22px',
        backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/un.png')`,
        backgroundPosition: 'right 4px center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '25px',
        height: '40px',
        cursor: 'pointer',
        boxShadow: 'none',
        '&:hover': {
          borderColor: 'none'
        },
        ...controlStyle
      };
    },
    option: (provided, { isSelected }) => {
      return {
        ...provided,
        cursor: 'pointer',
        backgroundColor: isSelected ? '#093687' : null,
        ':active': {
          backgroundColor: 'red'
        },
        ':hover': {
          backgroundColor: 'gray'
        },
        ...optionStyle
      };
    },
    multiValue: (provided) => {
      return {
        ...provided,
        backgroundColor: '#093687',
        ...multiValueStyle
      };
    },
    multiValueLabel: (provided) => {
      return {
        ...provided,
        color: 'white',
        ...multiValueLabelStyle
      };
    },
    multiValueRemove: (provided) => {
      return {
        ...provided,
        color: 'white',
        ':hover': {
          backgroundColor: FMSColors.primary,
          color: 'white'
        },
        ...multiValueRemoveStyle
      };
    }
  };

  const getSelectedOptions = (options, value) => {
    const selectedOptions = [];
    value
      && value.forEach((item) => {
        options
          && options.forEach((option) => {
            if (item === option.value) {
              selectedOptions.push(option);
            }
          });
      });
    return selectedOptions;
  };

  const selectedOptions = getSelectedOptions(options, value);
  return (
    <Container className={className} style={style}>
      <FMSSelect
        {...rest}
        isMulti
        components={{ DropdownIndicator: null, IndicatorSeparator: null }}
        isClearable={false}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        value={selectedOptions}
        onChange={onChange}
      />
    </Container>
  );
};

CustomMultiSelect.defaultProps = {
  options: [],
  style: {},
  controlStyle: {},
  optionStyle: {},
  multiValueStyle: {},
  multiValueLabelStyle: {},
  multiValueRemoveStyle: {},
  className: '',
  placeholder: ''
};

export default CustomMultiSelect;
