import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getCompanyUsersAPI,
  getAllCompanyUsersAPI,
  getCompanyUserAPI,
  createCompanyUserAPI,
  updateCompanyUserAPI,
  deleteCompanyUserAPI,
  getCompanyUserListAPI
} from 'v2/apis/companyUserApi';

import { createSagaAction, confirmFetchSaga, confirmFetchSagas, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';

import history from 'service/common/history';
import {
  createCompanyUser,
  updateCompanyUser,
  deleteCompanyUser,
  getCompanyUser,
  changeCompanyUserPage,
  entityCompanyUser,
  deleteForCompanyUsers,
  getCompanyUsers,
  entityCompanyUsers,
  loadAllCompanyUsers,
  entityLoadAllCompanyUsers,
  loadAllCompanyUser,
  entityLoadAllCompanyUser
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/companyUser';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorCompanyUsersPage = (state) => state.companyUser.list;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateCompanyUser = createEntity({
  key: createCompanyUser().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Create.Fail');
  },
  *afterSuccessFn(payload, requestParams) {
    FMSCommon.toast.success('Common.Created.Success');
    const selectorListPage = yield select(selectorCompanyUsersPage);
    const { pager } = selectorListPage;
    const { ID } = payload;
    const {
      data: { companyID }
    } = requestParams || { data: null };
    history.replace({ pathname: `${historyUrl}/${companyID}/${ID}`, state: pager });
    yield put(getCompanyUser(companyID, ID));
  }
});

// update for update page
export const entityUpdateCompanyUser = createEntity({
  key: updateCompanyUser().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Update.Fail');
  },
  *afterSuccessFn(payload) {
    FMSCommon.toast.success('Common.Updated.Success');
    const { ID, companyID } = payload;
    yield put(getCompanyUser(companyID, ID));
  }
});
// delete for detail page
export const entityDeleteCompanyUser = createEntity({
  key: deleteCompanyUser().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCompanyUsersPage);
    const { pager } = selectorListPage;
    history.push({ pathname: historyUrl, state: pager });
  }
});

// mulitple delete for list page.
export const entityDeleteForCompanyUsers = createEntity({
  key: deleteForCompanyUsers().type,
  *beforeFn(payload) {
    const isContinue = payload && payload.length;
    if (isContinue) yield put(changeCompanyUserPage({ loading: true }));
    else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');

    return isContinue;
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    const selectorListPage = yield select(selectorCompanyUsersPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCompanyUsers(pageInfo, sortInfo, filters));
  },
  *afterFailureFn() {
    FMSCommon.toast.fail('Common.Deletes.Fail');
    const selectorListPage = yield select(selectorCompanyUsersPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getCompanyUsers(pageInfo, sortInfo, filters));
  }
});
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createCompanyUserAsync = createSagaAction.bind(
  null,
  entityCreateCompanyUser,
  createCompanyUserAPI
);
const updateCompanyUserAsync = createSagaAction.bind(
  null,
  entityUpdateCompanyUser,
  updateCompanyUserAPI
);
const deleteCompanyUserAsync = createSagaAction.bind(
  null,
  entityDeleteCompanyUser,
  deleteCompanyUserAPI
);
const deleteForCompanyUsersAsync = createSagaAction.bind(
  null,
  entityDeleteForCompanyUsers,
  deleteCompanyUserAPI
);

// const createFailModal = showModal.bind(null, entityCreateBusiness, {});
// const updateFailModal = showModal.bind(null, entityUpdateBusiness, {});
// const deleteFailModal = showModal.bind(null, entityDeleteBusiness, {});

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getCompanyUsersAsync = createSagaAction.bind(null, entityCompanyUsers, getCompanyUsersAPI);
const getCompanyUserAsync = createSagaAction.bind(null, entityCompanyUser, getCompanyUserAPI);
const loadAllCompanyUsersAsync = createSagaAction.bind(
  null,
  entityLoadAllCompanyUsers,
  getAllCompanyUsersAPI
);
const loadAllCompanyUserAsync = createSagaAction.bind(
  null,
  entityLoadAllCompanyUser,
  getCompanyUserListAPI
);

function* changeCompanyUserPageSaga() {
  yield put(changeCompanyUserPage({ mode: 'view' }));
}

/**
 * Watcher
 */
export default function* companyUserWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createCompanyUser().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createCompanyUserAsync,
    entityCreateCompanyUser
  );
  yield takeLatest(
    updateCompanyUser().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateCompanyUserAsync,
    entityUpdateCompanyUser
  );
  yield takeLatest(
    deleteCompanyUser().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteCompanyUserAsync,
    entityDeleteCompanyUser
  );
  yield takeLatest(getCompanyUsers().type, fetchSaga, getCompanyUsersAsync, entityCompanyUsers);
  yield takeLatest(getCompanyUser().type, fetchSaga, getCompanyUserAsync, entityCompanyUser);

  // deletes in list page
  yield takeLatest(
    deleteForCompanyUsers().type,
    confirmFetchSagas,
    'Common.Confirm.Delete',
    null,
    deleteForCompanyUsersAsync,
    entityDeleteForCompanyUsers
  );

  // select business
  yield takeLatest(
    loadAllCompanyUsers().type,
    fetchSaga,
    loadAllCompanyUsersAsync,
    entityLoadAllCompanyUsers
  );
  yield takeLatest(
    loadAllCompanyUser().type,
    fetchSaga,
    loadAllCompanyUserAsync,
    entityLoadAllCompanyUser
  );

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityCreateCompanyUser.types[1], changeCompanyUserPageSaga);
  yield takeLatest(entityUpdateCompanyUser.types[1], changeCompanyUserPageSaga);
}
