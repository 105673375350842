import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  getReservationVehiclesUsage,
  initializeUseStatePage,
  changeUseStatePage,
  changeReservationVehiclesUsage,
  initializeReservationVehiclesUsage,
  getReservationStateVehiclesCount,
  initializeReservationStateVehiclesCount,
  getReservationStateCount,
  initializeReservationStateCount
} = createActions(
  {
    GET_RESERVATION_VEHICLES_USAGE: (pageInfo, sortInfo, filters) => ({
      pageInfo,
      sortInfo,
      filters
    }),
    GET_RESERVATION_STATE_VEHICLES_COUNT: (filters) => ({ filters }),
    GET_RESERVATION_STATE_COUNT: (filters) => ({ filters })
  },
  'INITIALIZE_USE_STATE_PAGE',
  'CHANGE_USE_STATE_PAGE',
  'INITIALIZE_RESERVATION_VEHICLES_USAGE',
  'CHANGE_RESERVATION_VEHICLES_USAGE',
  'INITIALIZE_RESERVATION_STATE_VEHICLES_COUNT',
  'INITIALIZE_RESERVATION_STATE_COUNT'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
// 대여스케쥴(Day)
export const entityReservationVehiclesUsage = createEntity({
  key: getReservationVehiclesUsage().type,
  parseData: parseDataForList
});
// 대여현황(상태별 Count)
export const entityReservationVehiclesCount = createEntity({
  key: getReservationStateVehiclesCount().type
  // , parseData: parseDataForList,
});
// 대여현황(대여종류별 Count)
export const entityReservationCount = createEntity({
  key: getReservationStateCount().type
  // , parseData: parseDataForList,
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
const now = new Date();
export const initializeState = {
  useState: {
    reservationClass: 'MONTH'
  },
  reservationVehiclesUsage: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: { keywordType: 'licensePlateNumber', keyword: '', baseTime: now.getTime() }
    }
  },
  reservationVehiclesCount: {
    data: { total: 0, wait: 0, inUse: 0, incident: 0, lowVoltage: 0, notReturned: 0 },
    loading: false,
    pager: {
      filters: {}
    }
  },
  reservationCount: {
    data: { short: 0, insurance: 0, month: 0, long: 0 },
    loading: false,
    pager: {
      filters: { reservationClass: 'MONTH' }
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const pageReducer = handleActions(
  {
    [initializeUseStatePage().type]: () => initializeState.useState,
    [changeUseStatePage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const reservationVehiclesUsageReducer = handleActions(
  {
    [initializeReservationVehiclesUsage().type]: () => initializeState.reservationVehiclesUsage,
    [changeReservationVehiclesUsage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const reservationVehiclesCountReducer = handleActions(
  {
    [initializeReservationStateVehiclesCount().type]: () => initializeState.reservationVehiclesCount
  },
  {},
  { produce }
);
const reservationCountReducer = handleActions(
  {
    [initializeReservationStateCount().type]: () => initializeState.reservationCount
  },
  {},
  { produce }
);

// page action reducer 선언
export const reservationState = combineReducers({
  useState: pageReducer,
  reservationVehiclesUsage: createReducer({
    entity: entityReservationVehiclesUsage,
    reducer: reservationVehiclesUsageReducer
  }),
  reservationVehiclesCount: createReducer({
    entity: entityReservationVehiclesCount,
    reducer: reservationVehiclesCountReducer
  }),
  reservationCount: createReducer({
    entity: entityReservationCount,
    reducer: reservationCountReducer
  })
});
/** ********************************************** */
