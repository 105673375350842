import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

export const {
  initializeLoadAllLicense,
  loadAllLicense,
  initializeLicenseListState,
  getLicenseList,
  initializeLicenseState,
  changeLicenseState,
  getLicense,
  createLicense,
  updateLicense,
  deleteLicense,
  changeLicenseAclPage,
  updateLicenseAcl
} = createActions(
  {
    LOAD_ALL_LICENSE: (filters) => ({ filters }),
    GET_LICENSE_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_LICENSE: (ID) => ({ ID }),
    CREATE_LICENSE: (data) => ({ data }),
    UPDATE_LICENSE: (ID, data) => ({ ID, data }),
    UPDATE_LICENSE_ACL: (ID, data) => ({ ID, data }),
    DELETE_LICENSE: (ID) => ({ ID })
  },
  'INITIALIZE_LOAD_ALL_LICENSE',
  'INITIALIZE_LICENSE_LIST_STATE',
  'INITIALIZE_LICENSE_STATE',
  'CHANGE_LICENSE_STATE',
  'CHANGE_LICENSE_ACL_PAGE'
);

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLicenseList = createEntity({
  key: getLicenseList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityLicense = createEntity({
  key: getLicense().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityLoadAllLicense = createEntity({
  key: initializeLoadAllLicense().type,
  parseData: parseDataForList
});
/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  detail: {
    data: {
      licenseInfo: null,
      subMenus: []
    },
    loading: false,
    mode: 'create',
    aclMode: 'view'
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: { useyn: true }
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const licenseListStateReducer = handleActions(
  {
    [initializeLicenseListState().type]: () => initializeState.list
  },
  {},
  { produce }
);
const licenseLoadAllReducer = handleActions(
  {
    [initializeLoadAllLicense().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const licenseStateReducer = handleActions(
  {
    [initializeLicenseState().type]: () => initializeState.detail,
    [changeLicenseState().type]: (state, { payload }) => ({ ...state, ...payload }),
    [changeLicenseAclPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const license = combineReducers({
  list: createReducer({
    entity: entityLicenseList,
    reducer: licenseListStateReducer
  }),
  detail: createReducer({
    entity: entityLicense,
    reducer: licenseStateReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllLicense,
    reducer: licenseLoadAllReducer
  })
});
/** ********************************************** */
