import React, { Suspense, useCallback, useEffect, useState, memo } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import routerHistory from 'service/common/history';
import { updateFcmTokenAPI } from 'v2/apis/authenticationApi';

import {
  logout,
  changeMyCountry,
  changeMyBusiness,
  changeMyBranch
} from 'v2/redux/modules/authentication';

import FMSLoader from 'components/ui/common/FMSLoader';
import mainRoutes from 'v2/constants/mainRoutes';

import { initializeHomeMenu, changeHomeMenu } from 'v2/redux/modules/homeMenu';
// 20210707 약관 모달 추가
import FMSModal from 'components/ui/common/FMSModal';
import { FMSIconBtnClose } from 'components/ui/button/FMSIconBtn';
import { getTermsAPI } from 'v2/apis/termsApi';
// 20210706 GA 추가
import { messaging } from 'components/firebase/fcm';
import { notification } from 'antd';
import GoogleAnalyticsHOC from '../../../service/GoogleAnalyticsHOC';
import {
  mypageMenu,
  findMyPageMenus,
  searchMenuTree,
  compactTreeChildren,
  getMenuUrlFromLocation,
  getParentMenu
} from './lib/homeLib';
import HomeMain from './components/HomeMain';
import { setMenuActive, setMenuLeftActive, setMenuTabActive } from '../../redux/modules/menu';
import { loadAllCountryNumbers } from '../../redux/modules/country';

const serviceCountryCode = process.env.FMS_SERVICE_COUNTRY_CODE;
const stateSelector = createSelector(
  (store) => store.authentication,
  (store) => store.homeMenu,
  (authentication, homeMenu) => ({
    profile: fp.get('profile.data', authentication),
    loading: fp.get('profile.loading', authentication) || !fp.get('profile.data', authentication),
    myMenu: fp.get('profile.data.aclMenu', authentication),
    myCountry: fp.getOr({}, 'myCountry.data', authentication),
    myBusiness: fp.getOr({}, 'myBusiness.data', authentication),
    myBranch: fp.getOr({}, 'myBranch.data', authentication),
    pathname: fp.get('homeMenu.pathname', homeMenu),
    state: fp.get('homeMenu.state', homeMenu)
  })
);

const RouteComponent = memo(() => {
  const { profile } = useSelector(stateSelector, fp.equals);
  const location = useLocation();
  const [pathName, setPathname] = useState('');
  const dispatch = useDispatch();
  const aclMenu = profile?.aclMenu;
  const [backMenuActive, setBackMenuActive] = useState(false);
  const [leftMenuList, setLeftMenuList] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getFirstRouteDep = fp.pipe(fp.split('/'), fp.getOr('', '[1]'), fp.toLower);
    const isMainPathChanged = (prevPath, nextPath) =>
      fp.notEquals(getFirstRouteDep(prevPath), getFirstRouteDep(nextPath));

    fp.pipe(fp.getOr('', 'pathname'), setPathname)(location);

    const isChanged = isMainPathChanged(pathName, fp.getOr('', 'pathname', location));
    // 뒤로가기 감지 POP
    const unlisten = history.listen((location) => {
      // console.log('action:', history.action, ', path:', location.pathname);
      if (history.action === 'POP') {
        // 뒤로가기 url기준으로 해당메뉴불러옴
        const splitUrl = `/${location.pathname.split('/', 2)[1]}`;
        console.log('splitUrl:', splitUrl);
        const menuCode = fp.pipe(fp.filter((menu) => menu.menuUrl === location?.pathname))(aclMenu);
        console.log('menuCode:', menuCode);
        if (menuCode?.length > 0) {
          // 클릭시 메뉴 [menuID, parentID]형태
          const clickMenu = [];
          clickMenu.push(menuCode[0].menuID);
          clickMenu.push(menuCode[0].parentID);

          setBackMenuActive(clickMenu);
        }
      }
    });
    dispatch(
      changeHomeMenu({
        isMainPathChanged: isChanged
      })
    );
    return unlisten;
  }, [history]);

  // 뒤로가기 메뉴 탭,좌측 메뉴 css 활성화
  useEffect(() => {
    // console.log('menuActive:', backMenuActive);
    if (backMenuActive) {
      const leftMenu = fp.pipe(
        fp.filter((menu) => menu.parentID === backMenuActive[1]), // 부모ID기준으로 좌측메뉴 불러옴
        fp.sortBy('orderNum')
      )(aclMenu);
      // console.log('leftMenu:', leftMenu);
      setLeftMenuList(leftMenu);
      dispatch(setMenuActive({ data: backMenuActive })); // 좌측 active
      dispatch(setMenuTabActive({ data: backMenuActive })); // 상단TAB active
    }
  }, [backMenuActive]);

  // 뒤로가기 좌측 메뉴 불러오기
  useEffect(() => {
    // console.log('leftMenuList:', leftMenuList);
    dispatch(setMenuLeftActive({ data: leftMenuList }));
  }, [leftMenuList]);

  return (
    <Suspense fallback={<FMSLoader loading />}>
      <Switch>
        {mainRoutes.map((route) => {
          const subMenuRoutes =
            route.subRoutes &&
            route.subRoutes.length > 0 &&
            route.subRoutes.map((subRoute) => (
              <Route
                key={subRoute.path}
                exact={subRoute.exact}
                path={subRoute.path}
                render={(routeProps) => <subRoute.component {...routeProps} />}
              />
            ));
          return [
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              render={(routeProps) =>
                route.component ? <route.component {...routeProps} /> : null
              }
            />,
            subMenuRoutes
          ];
        })}
      </Switch>
    </Suspense>
  );
});

const HomeMainPage = () => {
  const dispatch = useDispatch();
  const {
    profile,
    loading,
    myMenu,
    pathname,
    state,
    myCountry,
    myBusiness,
    myBranch
  } = useSelector(stateSelector, fp.equals);

  // menu tree
  const [menuRoot, setMenuRoot] = useState(null);
  const [selectedMenuIDs, setSelectedMenuIDs] = useState();
  const [leftMenuList, setLeftMenuList] = useState([]);

  // 20210707 약관 modal
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const handleChangeMyCountry = useCallback(
    (next) => {
      dispatch(changeMyCountry({ data: next }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(loadAllCountryNumbers());
  }, []);

  const handleChangeMyBusiness = useCallback(
    (next) => {
      // data: null로 설정하면 ID 값을 읽을 때 에러 나므로 ID 속성을 지정함.
      dispatch(changeMyBusiness({ data: next }));
    },
    [dispatch]
  );

  const handleChangeMyBranch = useCallback(
    (next) => {
      const isValidBranch = (branch) => {
        const composer = fp.pipe(fp.get('ID'), fp.toNumber, fp.gt, fp.ap(0));
        const result = composer(branch);

        return result;
      };
      dispatch(changeMyBranch({ data: isValidBranch(next) ? next : { ID: 0 } }));
    },
    [dispatch]
  );

  const handleAgreeMember = useCallback(() => {
    setModalOpen(true);
    const term = getTermsAPI({ ID: 'TERMS_USE', data: { countryID: myCountry.ID } });
    term.then((data) => {
      setModalContent(data.data.items.Contents);
    });
  }, [myCountry]);

  const handlePersonalInfo = useCallback(() => {
    setModalOpen(true);
    const term = getTermsAPI({ ID: 'TERMS_SEPARATE_PRIVACY', data: { countryID: myCountry.ID } });
    term.then((data) => {
      setModalContent(data.data.items.Contents);
    });
  }, []);

  useEffect(() => {
    if (!myBusiness?.ID) return;

    const rootNode = { children: [] };

    const myMenuList = myMenu
      .filter((item) => !!item.aclyn || item.inquiryYN)
      .map((item) => _.cloneDeep(item));
    myMenuList.forEach((item) => {
      if (item.parentID) {
        const parentNode = myMenuList.find((parent) => parent.menuID === item.parentID);

        if (parentNode) {
          if (!parentNode.children) parentNode.children = [];
          parentNode.children.push(item);
        }
      } else {
        rootNode.children.push(item);
      }
    });

    // children 정렬
    compactTreeChildren(rootNode);
    setMenuRoot(rootNode);

    // 주소입력줄에 입력하거나 링크를 타고 처음 들어왔을 때,
    const urlEntered = getMenuUrlFromLocation(mainRoutes, window.location.pathname);
    const menusEntered = searchMenuTree(rootNode, urlEntered);
    // 기존 주소인 경우: 해당 주소로 이동
    if (menusEntered?.length) {
      const selectedMenuIDsNew = [];
      let selectedUrl = '';
      for (const menu of menusEntered) {
        selectedMenuIDsNew.push(menu.menuID);
        if (menu.children) {
          setLeftMenuList(menu.children);
        } else {
          selectedUrl = menu.menuUrl;
        }
      }
      setSelectedMenuIDs(selectedMenuIDsNew);
      routerHistory.push(selectedUrl); // 목록페이지로 이동
    } else {
      // 기존 주소가 아닌 경우, 또는 Top 메뉴가 없는 mypageMenu 주소인 경우: 가장 첫번째 주소로 이동
      const selectedMenuIDsNew = [];
      const selectedTopmenu = rootNode.children[0];
      selectedMenuIDsNew.push(selectedTopmenu.menuID);
      setLeftMenuList(selectedTopmenu.children);
      let selectedUrl = '';
      if (selectedTopmenu.menuUrl) selectedUrl = selectedTopmenu.menuUrl;
      else if (selectedTopmenu?.children?.length) {
        selectedUrl = selectedTopmenu.children[0].menuUrl;
        selectedMenuIDsNew.push(selectedTopmenu.children[0].menuID);
      }
      if (!selectedUrl) selectedUrl = '/';

      setSelectedMenuIDs(selectedMenuIDsNew);
      routerHistory.push(selectedUrl);
    }
  }, [myMenu?.length, myCountry?.ID, myBusiness?.ID, myBranch?.ID]);

  // changeHomeMenu으로 homeMenu.pathname state가 변경됨.
  // 차량유지관리에서만 changeHomeMenu 호출함.
  // 그러므로 차량유지관리에서만 호출됨.
  // 차량유지관리의 소스를 changeHomeMenu 호출하지 않게 수정하면 이 useEffect를 제거해도 됨.
  useEffect(() => {
    if (pathname) {
      const selectedMenuUrl = getMenuUrlFromLocation(mainRoutes, pathname);
      const list = searchMenuTree(menuRoot, selectedMenuUrl);
      const selecteMenuIDs = [];
      if (list?.length) {
        list.forEach((item) => {
          if (item && item.menuID) selecteMenuIDs.push(item.menuID);
        });
        setLeftMenuList(list[list.length - 1].children);
      }
      setSelectedMenuIDs(selecteMenuIDs);

      const urlEntered = getMenuUrlFromLocation(mainRoutes, window.location.pathname);
      const myMenu = mypageMenu
        .filter((item) => item.menuUrl === urlEntered)
        .map((item) => item.menuID);
      if (myMenu?.length) {
        setSelectedMenuIDs(myMenu.menuID);
        setLeftMenuList(findMyPageMenus(profile?.roleGrade, mypageMenu));
      }

      routerHistory.push({ pathname, state });
      initializeHomeMenu();
    }
  }, [pathname]);

  // react route onClick시 menu change
  const handleSelectMenu = useCallback(
    (menu, url, goFirstMenu) => {
      // 메뉴 클릭 시 redux를 통해 active된 메뉴 초기화
      dispatch(setMenuActive({ data: false })); // 좌측 active
      dispatch(setMenuTabActive({ data: false })); // 상단 active
      // console.log('menu1 handleSelectMenu:', menu);
      // console.log('url handleSelectMenu:', url);
      // console.log('goFirstMenu handleSelectMenu:', goFirstMenu);
      if (menu) {
        if (!menu.parentID) {
          // 메뉴탭 클릭시 좌측 리스트 재조회
          setLeftMenuList(menu.children);
          dispatch(setMenuLeftActive({ data: false })); // 좌측 메뉴 리스트
        }
        const selectedMenuIDsNew = [];

        if (!menu.menuUrl && menu?.children?.length) {
          selectedMenuIDsNew.push(menu.children[0].menuID);
        }

        let selectedMenu = menu;
        while (selectedMenu?.menuID) {
          selectedMenuIDsNew.push(selectedMenu.menuID);
          selectedMenu = getParentMenu(myMenu, selectedMenu);
        }
        setSelectedMenuIDs(selectedMenuIDsNew);
      } else if (url) {
        const selecteMenuIDs = mypageMenu
          .filter((item) => item.menuUrl === url)
          .map((item) => item.menuID);
        setSelectedMenuIDs(selecteMenuIDs);
        setLeftMenuList(findMyPageMenus(profile?.roleGrade || profile?.roleDetail, mypageMenu));
      } else if (goFirstMenu) {
        const topMenu = menuRoot?.children?.length ? menuRoot.children[0] : null;
        const leftMenu =
          topMenu?.menuUrl === '' && topMenu?.children?.length ? topMenu.children[0] : null;

        const selectedMenuIDsNew = [];
        if (topMenu?.menuID) {
          selectedMenuIDsNew.push(topMenu.menuID);
          setLeftMenuList(topMenu.children);
        }
        if (leftMenu?.menuID) {
          selectedMenuIDsNew.push(leftMenu.menuID);
        }

        if (leftMenu?.menuID) {
          routerHistory.push(leftMenu.menuUrl);
        } else if (topMenu?.menuID) {
          routerHistory.push(topMenu.menuUrl);
        }
        setSelectedMenuIDs(selectedMenuIDsNew);
      }
    },
    [menuRoot && menuRoot.children, myMenu]
  );
  // TODO add webPushYn to profile
  // profile.useWebPushYn = 'Y';

  /* set fcm messaging */

  const [fcmToken, setFcmToken] = useState();
  const updateFcmToken = async (params) => updateFcmTokenAPI(params);
  const updateFcmTokenAndSet = useCallback(
    (params) => {
      updateFcmToken({ data: params })
        .then((/* resp */) => {
          // console.log(resp)
          setFcmToken(params.fcmToken);
        })
        .catch((/* resp */) => {
          // console.log(resp)
          setFcmToken('');
        });
    },
    [updateFcmTokenAPI, messaging]
  );

  useEffect(() => {
    if (profile.useWebPushYn === 'Y' && messaging && Notification) {
      Notification.requestPermission().then(() => {
        messaging
          .getToken()
          .then((currentToken) => {
            if (currentToken) {
              const params = { fcmToken: currentToken };
              updateFcmTokenAndSet(params);
            } else {
              // eslint-disable-next-line no-console
              console.error('No registration token available. Request permission to generate one.');
            }
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('An error occurred while retrieving token. ', err);
          });
      });
    }
  }, [messaging]);

  useEffect(() => {
    if (fcmToken) {
      messaging.onMessage((payload) => {
        notification.open({
          message: payload.notification.title,
          description: payload.notification.body,
          duration: 0
        });
      });
    }
  }, [fcmToken]);

  return (
    <>
      {serviceCountryCode === 'RU' && (
        <GoogleAnalyticsHOC r-if={selectedMenuIDs} selectedMenuIDs={selectedMenuIDs} />
      )}
      <HomeMain
        {...{
          loading,
          profile,
          handleLogout,
          menuRoot,
          selectedMenuIDs,
          handleSelectMenu,
          leftMenuList,
          myCountry,
          handleChangeMyCountry,
          myBusiness,
          handleChangeMyBusiness,
          myBranch,
          handleChangeMyBranch,
          RouteComponent: <RouteComponent />,
          handleAgreeMember,
          handlePersonalInfo
        }}
      />
      <FMSModal
        customStyles={{
          content: { width: 1500, paddingTop: 60, maxHeight: '80%' }
        }}
        handleCloseModal={() => {
          setModalOpen(false);
          setModalContent('');
        }}
        isOpen={isModalOpen}
        titleStyles={{
          marginTop: -35,
          fontSize: 18,
          paddingBottom: 10,
          position: 'relative'
        }}
      >
        <FMSIconBtnClose type='button' onClick={() => setModalOpen(false)} />
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: modalContent }}
          style={{ padding: 12, height: '100%', overflow: 'auto' }}
        />
      </FMSModal>
    </>
  );
};
export default memo(HomeMainPage);
