import axios from 'axios';
import { SUPPORTED_COUNTRY } from '../constants';

/**
 * 주소 -> 위경도 변환
 */
export const getGeocodeAPI = ({ query }) =>
  axios.get('https://geocode.search.hereapi.com/v1/geocode', {
    params: {
      apiKey: `${process.env.FMS_HERE_REST_APP_KEY}`,
      q: query
    }
  });

/**
 * 위경도 -> 주소 변환
 */
export const getAddressAPI = ({ latitude, longitude }) =>
  axios.get('https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json', {
    params: {
      apiKey: `${process.env.FMS_HERE_REST_APP_KEY}`,
      mode: 'retrieveAddresses',
      prox: `${latitude},${longitude}`
    }
  });

/**
 * 키워드검색
 * 20210204 설정된 언어에 따라 결과 주소 값을 해당 언어로 받는 parameter 추가_ 김하림
 * 조회 후 autoCompleteParseData에서 countryName,postalCode 없으면 필터링
 */
export const getSearchResultAPI = ({ query }) => {
  //kyw todo
  console.log('query:', query);
  let postCountry = '';
  if (query?.postCountry !== 'ALL') {
    postCountry = `countryCode:${query?.postCountry}`;
  } else {
    postCountry = `countryCode:${SUPPORTED_COUNTRY}`;
  }
  return axios.get('https://autocomplete.search.hereapi.com/v1/autocomplete', {
    params: {
      q: query?.searchValue,
      limit: 20,
      in: postCountry,
      language: localStorage.getItem('lang'),
      apiKey: `${process.env.FMS_HERE_REST_APP_KEY}`
    }
  });
};
