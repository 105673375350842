import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'formdata-polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/ko';
import '@formatjs/intl-pluralrules/dist/locale-data/it';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'v2/containers/Root';
import { AppContainer } from 'react-hot-loader';
import 'v2/lib';
// import * as serviceWorker from 'serviceWorker';
import 'styles/index.scss';

const targetPage = 'v2/containers/Root';

const rootElement = document.getElementById('root');
const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Root />
    </AppContainer>,
    rootElement
  );
};
render();

if (module.hot) {
  module.hot.accept(targetPage, () => {
    render();
  });
}
// serviceWorker.register();
