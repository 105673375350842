import {
  format,
  differenceInHours,
  differenceInMinutes,
  addMilliseconds,
  addSeconds,
  addMinutes,
  addHours,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  subMilliseconds,
  subSeconds,
  subMinutes,
  subHours,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  differenceInDays,
  isValid
} from 'date-fns';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import isDate from 'date-fns/is_date';

import moment from 'moment';
import { FMSCommon } from '../common/commonLib';

export const getDateWithFormat = (milliseconds, dateFormat = 'YYYY-MM-DD HH:mm:ss') => {
  if (!Number(milliseconds)) {
    return milliseconds;
  }
  const test = format(new Date(Number(milliseconds)), dateFormat);
  return format(new Date(Number(milliseconds)), dateFormat);
};

export const getDateDistanceIndays = (start, end) => differenceInDays(end, start);

export const getDateDistance = (startMilliseconds, endMilliseconds /* dateFormat */ = '') => {
  const startDate = new Date(Number(startMilliseconds));
  const endDate = new Date(Number(endMilliseconds));

  const differenceInMin = differenceInMinutes(endDate, startDate);
  const minuteForAdd = differenceInMin % 60 >= 0.5 ? 1 : 0;
  const differenceInHour = differenceInHours(endDate, startDate);
  let dayForDisplay = Math.floor(differenceInHour / 24);
  let hourForDisplay = differenceInHour - 24 * dayForDisplay + minuteForAdd;
  if (hourForDisplay === 24) {
    dayForDisplay += 1;
    hourForDisplay = 0;
  }

  return {
    days: dayForDisplay,
    hours: hourForDisplay
  };
  // 다국어 처리
  // return dayForDisplay > 0 ? hourForDisplay > 0 ? `${dayForDisplay}일 ${hourForDisplay}시간` : `${dayForDisplay}일` : `${hourForDisplay}시간`;
};

export const getAddedDate = (date = new Date(), unitValue = 7, unit = 'days') => {
  const addedDate = {
    milliseconds: addMilliseconds(date, unitValue),
    seconds: addSeconds(date, unitValue),
    minutes: addMinutes(date, unitValue),
    hours: addHours(date, unitValue),
    days: addDays(date, unitValue),
    weeks: addWeeks(date, unitValue),
    months: addMonths(date, unitValue),
    years: addYears(date, unitValue)
  };

  return addedDate[unit];
};

export const getSubtractedDate = (date = new Date(), unitValue = 7, unit = 'days') => {
  const subtractedDate = {
    milliseconds: subMilliseconds(date, unitValue),
    seconds: subSeconds(date, unitValue),
    minutes: subMinutes(date, unitValue),
    hours: subHours(date, unitValue),
    days: subDays(date, unitValue),
    weeks: subWeeks(date, unitValue),
    months: subMonths(date, unitValue),
    years: subYears(date, unitValue)
  };
  return subtractedDate[unit];
};

export const parseDate = (str, format, locale) => {
  const parsed = dateFnsParse(str, format, { locale });
  if (isValid(parsed) && isDate(parsed)) {
    return parsed;
  }
  return null;
};

export const parseDateReplace = (str, format) => {
  if (!str) return null;
  // const newStr = replaceFn ? replaceFn(str) : str;
  const nesStr = str.replace(/\./g, '-');
  return parseDate(nesStr, format);
};

export const getYMDfromDate = (date) => {
  date = String(date)
    .replace('-', '')
    .replace('-', '');

  const birthdayy = date.substr(0, 4);
  const birthdaym = date.substr(4, 2);
  const birthdayd = date.substr(6, 2);

  return { birthdayy, birthdaym, birthdayd };
};

export const getDatefromYMD = (ymd, suffix = '-') => {
  if (!ymd) return '';
  const year = ymd.substr(0, 4);
  const month = ymd.substr(4, 2);
  const day = ymd.substr(6, 2);

  return `${year}${suffix}${month}${suffix}${day}`;
};

export const getBirthWithFormat = (birth, format = 'YYYY.MM.DD') => {
  const birthObj = getYMDfromDate(birth);

  const birthToDate = new Date(`${birthObj.birthdayy}-${birthObj.birthdaym}-${birthObj.birthdayd}`);
  return getDateWithFormat(birthToDate.getTime(), format);
};

export const getAmericanAge = (birth) => {
  const nowDateObj = getYMDfromDate(getDateWithFormat(new Date().getTime(), 'YYYYMMDD'));
  const birthObj = getYMDfromDate(birth);

  birth = String(birth)
    .replace('-', '')
    .replace('-', '');

  const year = nowDateObj.birthdayy;
  const monthDay = `${nowDateObj.birthdaym}${nowDateObj.birthdayd}`;
  const { birthdayy } = birthObj;
  const birthdaymd = `${birthObj.birthdaym}${birthObj.birthdayd}`;

  const age = monthDay < birthdaymd ? year - birthdayy - 1 : year - birthdayy;

  return age;
};

export const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const date = currentDate.getDate();
  return { year, month, date };
};

// Date instance -> int date
export const getIntDateFromDate = (date) => {
  const _year = date.getFullYear();
  const _month = date.getMonth() + 1;
  const _date = date.getDate();
  return { year: _year, month: _month, date: _date };
};

// lastDate
export const getLastDate = (date) => {
  const _date = String(date).replace('-', '');
  const _year = _date.substr(0, 4);
  const _month = _date.substr(4, _date.length);
  return new Date(_year, _month, 0).getDate();
};

// 날짜 포맷 수정
export const convertDateFormat = (date, originFormat = 'YYYYMMDD', changeFormat = 'DDMMYYYY') => {
  if (moment(date, changeFormat).isValid()) {
    // 변경하려는 포맷의 날짜포맷 이면 그래로 return
    return date;
  }

  const changeDate = moment(date, originFormat).format(changeFormat);
  return changeDate === 'Invalid date' ? '' : changeDate;
};

export const formatDate = (date, format) => dateFnsFormat(date, format);

// START YYYYMM ~ END YYYYMM 의 전체 일수 반환
export const getMonthToMonthTotalDays = (stYM, endYM) => {
  // 시작 DATE
  const sYM = String(stYM).replace('-', '');
  const sYear = sYM.substr(0, 4);
  const sMonth = Number(sYM.substr(4, sYM.length));
  const startDate = new Date(sYear, sMonth - 1, 1);

  // 종료 DATE
  const eYM = String(endYM).replace('-', '');
  const eYear = eYM.substr(0, 4);
  const eMonth = Number(eYM.substr(4, eYear.length));
  const eDay = new Date(eYear, eMonth, 0).getDate();
  const endDate = new Date(eYear, eMonth - 1, eDay);

  const dateDiff = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
  const totalDays = dateDiff + 1;

  return totalDays;
};

/**
 * 달력에서 선택한 날짜를 UCT 타입으로 변경(dd.mm.yyyy 기준)
 */
export const convertDateToUtc = (date, type) => {
  if (_.isEmpty(date)) {
    return date;
  }
  const dateArr = date.split('.');
  const dateNew = getDateWithFormat(new Date(dateArr[2], dateArr[1] - 1, dateArr[0]), 'YYYY.MM.DD');

  const timeNumber = FMSCommon.stringToDate(FMSCommon.uncomma(dateNew));
  const newDate = new Date(timeNumber);
  // 시작날짜는 0시 기준, 종료날짜는 23.59.59 기준으로 변경
  if (type === 'startDate') {
    newDate.setHours(0, 0, 0, 0);
    return newDate.getTime();
  } else if (type === 'endDate') {
    newDate.setHours(23, 59, 59, 999);
    return newDate.getTime();
  }
  // 년 기준 1.1,12.31 값 return
  else if (type === 'startYear') {
    return moment(dateNew)
      .startOf('year')
      .toDate()
      .getTime();
  } else if (type === 'endYear') {
    return moment(dateNew)
      .endOf('year')
      .toDate()
      .getTime();
  } else {
    newDate.setHours(0, 0, 0, 0);
    return newDate.getTime();
  }
};
