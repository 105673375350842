import { select, takeLatest, put } from 'redux-saga/effects';
import {
  getAllReservationListAPI,
  confirmReservationsAPI,
  denyReservationsAPI,
  summaryReservationsAPI,
  downloadReservationsAPI
} from 'v2/apis/testCarReservationAPI';
import { createSagaAction, fetchSaga, confirmFetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
// import history from 'service/common/history';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';
import FileSaver from 'file-saver';
import dateFnsFormat from 'date-fns/format';
import {
  getAllTestCarReservationList,
  entityAllTestCarReservationList,
  approveTestCarReservations,
  denyTestCarReservations,
  summaryTestCarReservations,
  entitySummaryTestCarReservations,
  downloadTestCarReservations
} from '.';

export const historyUrl = '/reservation';
export const historyUrlStatus = '/rentStatus';
export const selectorTestCarReservationSearchPage = (state) => state.testCarReservation.list;
export const selectorTestCarReservationSearchDetailPage = (state) => state.testCarReservation.detail;
export const selectorMyBusiness = (state) => state.authentication.myBusiness.data;

export const entityDownloadTestCarReservations = createEntity({
  key: downloadTestCarReservations().type,
  afterSuccessFn: (payload) => {
    if (payload) {
      FileSaver.saveAs(
        payload,
        `testcar-reservation-${dateFnsFormat(new Date(), 'YYYYMMDDHHmmss')}.csv`
      );
    }
  }
});
export const entityApproveTestCarReservations = createEntity({
  key: approveTestCarReservations().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Customer.Approve.Fail');
  },
  *afterSuccessFn({ success, fail }) {
    FMSCommon.toast.success('Reservation.Approve.Success', () => {}, {
      total: success.length + fail.length,
      success: success.length
    });
    const selectorListPage = yield select(selectorTestCarReservationSearchPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getAllTestCarReservationList(filters, pageInfo, sortInfo));
  }
});
export const entityDenyTestCarReservations = createEntity({
  key: denyTestCarReservations().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Customer.Deny.Fail');
  },
  *afterSuccessFn({ success, fail }) {
    FMSCommon.toast.success('Reservation.Deny.Success', () => {}, {
      total: success.length + fail.length,
      success: success.length
    });
    const selectorListPage = yield select(selectorTestCarReservationSearchPage);
    const {
      pager: { pageInfo, sortInfo, filters }
    } = selectorListPage;
    yield put(getAllTestCarReservationList(filters, pageInfo, sortInfo));
  }
});

const getAllReservationListAsync = createSagaAction.bind(
  null,
  entityAllTestCarReservationList,
  getAllReservationListAPI
);
const approveTestCarReservationsAsync = createSagaAction.bind(
  null,
  entityApproveTestCarReservations,
  confirmReservationsAPI
);
const denyTestCarReservationsAsync = createSagaAction.bind(
  null,
  entityDenyTestCarReservations,
  denyReservationsAPI
);
const summaryTestCarReservationsAsync = createSagaAction.bind(
  null,
  entitySummaryTestCarReservations,
  summaryReservationsAPI
);
const downloadTestCarReservationsAsync = createSagaAction.bind(
  null,
  entityDownloadTestCarReservations,
  downloadReservationsAPI
);

export default function* testCarReservationWatcher() {
  yield takeLatest(
    getAllTestCarReservationList().type,
    fetchSaga,
    getAllReservationListAsync,
    entityAllTestCarReservationList
  );
  yield takeLatest(
    approveTestCarReservations().type,
    confirmFetchSaga,
    'Common.Confirm.Approve',
    approveTestCarReservationsAsync,
    entityApproveTestCarReservations
  );
  yield takeLatest(
    denyTestCarReservations().type,
    confirmFetchSaga,
    null,
    denyTestCarReservationsAsync,
    entityDenyTestCarReservations
  );
  yield takeLatest(
    summaryTestCarReservations().type,
    fetchSaga,
    summaryTestCarReservationsAsync,
    entitySummaryTestCarReservations
  );
  yield takeLatest(
    downloadTestCarReservations().type,
    fetchSaga,
    downloadTestCarReservationsAsync,
    entityDownloadTestCarReservations
  );
}
