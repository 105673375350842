/* eslint-disable no-param-reassign */
import produce from 'immer';

export default function createReducer({ entity, reducer }) {
  if (entity && (!Array.isArray(entity.types) || entity.types.length < 1)) {
    throw new Error('Expected entities to be an array.');
  }

  const [requestType, successType, failureType] = entity.types;

  const updateStore = (state, action) => {
    let draft = { ...state };

    switch (action.type) {
      case requestType:
        draft.loading = true;
        draft.apiState = 'request';
        break;
      case successType: {
        const result = entity.parseData ? entity.parseData(action) : null;
        draft.loading = false;
        draft.apiState = 'success';
        const newValue = {};
        Object.assign(newValue, draft, result);
        draft = newValue;
        break;
      }
      case failureType: {
        const { error } = action; // entity.parseError ? entity.parseError(action) : null;
        draft.loading = false;
        draft.apiState = 'failure';
        draft.error = error;
        break;
      }
      default:
        break;
    }
    return draft;
  };

  return function updateStoreByKey(state = {}, action) {
    switch (action.type) {
      case requestType:
      case successType:
      case failureType:
        return updateStore(state, action);
      default: {
        let stateNew = state;
        if (reducer) {
          stateNew = reducer(state, action);
        }
        return stateNew;
        // return reducer ? reducer(state, action) : state;
      }
    }
  };
}
