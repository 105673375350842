import request from 'apis/request';

const prefix = '/program';

function getComapnySortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

export const getProgramListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  // data.page = page;
  // data.rowPerPage = limit;
  // data.sortOrder = (order === 'desc') ? 2 : 1;
  // data.sortField = getComapnySortFieldNum(field);

  return request({
    url: `${prefix}`
    // data,
  });
};

export const getAllProgramListAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  return request({
    url: `${prefix}`,
    data
  });
};

export const createProgramAPI = ({ data }) => {
  return request({
    url: `${prefix}`,
    method: 'post',
    data
  });
};

export const deleteProgramAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
};
