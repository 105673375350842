import React from 'react';
import { connect } from 'react-redux';
import authConfig from 'service/auth/authConfig';

const withAuth = (WrappedComponent) => (hocProps) => {
  class AuthComponent extends React.Component {
    state = {
      ...hocProps,
      ...this.props
    };

    componentDidMount() {
      this.getAuth();
    }

    getAuth = async () => {
      const { aclType, aclUrl } = this.state;
      const authResult = authConfig.getAclAuth(aclUrl);
      const aclAuth = this.getType({ authResult, aclType });
      this.setState({ aclAuth });
    };

    getType = ({ authResult, aclType }) => {
      return (authResult && authResult[aclType]) || 'ALL';
    };

    render() {
      const { aclAuth, aclRole } = this.state;

      if (aclAuth === 'ALL' || (aclAuth && aclRole && aclAuth.indexOf(aclRole) > -1)) {
        return <WrappedComponent {...this.state} {...this.props} />;
      }
      return null;
    }
  }

  return connect((state) => ({
    aclRole: state.authentication.profile.data.role
  }))(AuthComponent);
};

export default withAuth;
