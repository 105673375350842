import * as pages from 'containers/pages';

const anonymousRoutes = [
  {
    path: '/redirect',
    exact: true,
    component: pages.RedirectPage // 로그인 성공 후 세션 생성.
  },
  {
    path: '/',
    component: pages.LoginPage // 로그인
  }
];

export default anonymousRoutes;
