import request from 'apis/request';

const prefix = '/user';

/** 로그인을 위한 state 값 발급
 * data.clientID, data.redirectURI
 */
export const getAuthStateAPI = (data) => {
  return request({
    url: `${prefix}/login/state`,
    data: {
      clientID: data.clientID,
      redirectURI: process.env.FMS_CCSP_REDIRECT_URL
    }
  });
};

/**
 * after ccSP 로그인, FMS 가입여부 확인.
 */
export const sendAuthCodeAPI = (data) => {
  return request({
    url: data.type ? `${prefix}/auth2` : `${prefix}/auth`,
    method: 'post',
    data
  });
};
/**
 * 210804 임시 로그인.
 */
export const sendAuthCodeAPI2 = (data) => {
  return request({
    url: `${prefix}/auth2`,
    method: 'post',
    data
  });
};

/**
 * 로그아웃
 */
export const deleteAuthAPI = () => {
  return request({
    url: `${prefix}/auth`,
    method: 'delete'
  });
};

/**
 * FMS 회원가입
 */
export const joinAuthAPI = () => {
  return request({
    url: `${prefix}/join`,
    method: 'post',
    data: {}
  });
};

/**
 * FMS 회원 나의 정보 조회, 로그인 상태 확인.
 */
export const getAuthProfileAPI = () => {
  return request({
    url: `${prefix}/profile`
  });
};

/**
 * 프로필 수정
 */
export const updateProfileAPI = ({ data }) => {
  return request({
    url: `${prefix}/profile`,
    method: 'put',
    data
  });
};
/**
 * 비밀번호 변경
 */
export const changePasswordAPI = ({ passwordCurrent, passwordChange }) => {
  return request({
    url: `${prefix}/password`,
    method: 'post',
    data: {
      passwordCurrent,
      passwordChange
    }
  });
};
/**
 * 사용자 탈퇴
 */
export const deleteJoinAPI = () => {
  return request({
    url: `${prefix}/join`,
    method: 'delete'
  });
};

/**
 * FCM토큰 업데이트
 */
export const updateFcmTokenAPI = ({ data }) => {
  if (!data || !data.fcmToken) return;
  return request({
    url: `${prefix}/fcmtoken`,
    method: 'put',
    data
  });
};

/**
 * 마이페이지 로그인 사용자 정보 조회
 */
export const getUserInfoAPI = () => {
  return request({
    url: `${prefix}/profile`
  });
};
