import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

export const {
  initializeBusinessLicenseList,
  getBusinessLicenseList,
  createBusinessLicense
} = createActions(
  {
    CREATE_BUSINESS_LICENSE: (data) => ({ data }),
    GET_BUSINESS_LICENSE_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters })
  },
  'INITIALIZE_BUSINESS_LICENSE_LIST'
);

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
export const entityLicenseBusinessList = createEntity({
  key: getBusinessLicenseList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const licenseBusinessListReducer = handleActions(
  {
    [initializeBusinessLicenseList().type]: () => initializeState.list
  },
  {},
  { produce }
);

// page action reducer 선언
export const businessLicense = combineReducers({
  list: createReducer({
    entity: entityLicenseBusinessList,
    reducer: licenseBusinessListReducer
  })
});
/** ********************************************** */
