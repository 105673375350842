import React from 'react';
import styled from 'styled-components';

import { FMSColors } from 'service/common/designLib';

const GlobalBase = styled.button`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: ${(props) => props.round};
  border: solid 1px
    ${(props) => props.borderColor ? props.borderColor : props.backgroundColor || FMSColors.primary};
  background-color: ${(props) => props.filled ? props.backgroundColor || FMSColors.primary : props.color || FMSColors.white};
  color: ${(props) => (props.filled ? props.color || FMSColors.secondary : props.backgroundColor)};
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => props.padding || 0};
  font-weight: 700;
  vertical-align: bottom;
  outline: none;
  transition: background-color 0.2s ease-in-out;

  .btnLabel {
    margin: 4px;
  }

  &:hover {
    ${(props) => props.isVariation
        ? 'border-width: 1.5px'
        : `background-color: ${
            props.filled
              ? !props.backgroundColor || props.backgroundColor === FMSColors.primary
                ? FMSColors.primaryHover
                : props.backgroundColor === FMSColors.secondary
                ? FMSColors.secondaryHover
                : props.backgroundColor === FMSColors.whiteSmoke
                ? '#f0f1f6'
                : props.backgroundColor
              : FMSColors.secondary
          };`}
  }

  &:disabled {
    opacity: 0.4;
    &:hover {
      pointer-events: none;
    }
  }
`;

export const FlatButtonBase = styled(GlobalBase)`
  border: solid 1px ${(props) => (props.borderColor ? props.borderColor : props.backgroundColor)};
  background-color: ${(props) => props.backgroundColor || FMSColors.white};
  color: ${(props) => props.color || FMSColors.grey};
  &:hover {
    background-color: ${(props) => props.filled
        ? props.backgroundColor === FMSColors.primary
          ? FMSColors.primaryHover
          : props.backgroundColor === FMSColors.white
          ? FMSColors.secondary
          : props.backgroundColor
        : '#f0f1f6'};
  }
`;

const StyledButtonBase = styled(GlobalBase)``;

export default function FMSButtonBase({
  filled = false,
  color,
  backgroundColor,
  round = 1 / 6,
  children,
  flat = false,
  ...restProps
}) {
  const ButtonComponent = flat ? FlatButtonBase : StyledButtonBase;
  return (
    <ButtonComponent
      backgroundColor={backgroundColor}
      color={color}
      filled={filled}
      round={` ${round}vw `}
      {...restProps}
    >
      <span className='btnLabel'>{children}</span>
    </ButtonComponent>
  );
}
