import request from 'apis/request';

const prefix = '/license';

export const createBusinessLicenseAPI = ({ data }) => {
  return request({
    url: `${prefix}/${data.licenseID}/business`,
    method: 'post',
    data
  });
};

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}
export const getBusinessLicenseAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}/${data.licenseID}/business`,
    data
  });
};
