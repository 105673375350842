import React, { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import proptype from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const SuggestionUl = ({ suggestions, onKeyDown, handleCompleteAddress }) => {
  const getAddress = useCallback((suggestion) => {
    return fp.get('properties.GeocoderMetaData', suggestion)
      ? fp.getOr('', 'properties.GeocoderMetaData.text', suggestion)
      : fp.getOr('', 'properties.CompanyMetaData.address', suggestion);
  }, []);

  return (
    <>
      <ul className='suggestions' r-if={fp.isNotEmpty(suggestions)}>
        <li
          key={`ru-suggestion-${i}`}
          r-for={(v, i) in suggestions}
          role='presentation'
          onClick={() => handleCompleteAddress({
              fullAddress: getAddress(v),
              geometry: fp.getOr({}, 'geometry', v)
            })
          }
        >
          {getAddress(v)}
        </li>
      </ul>
      <div r-else className='no-suggestions'>
        <em>
          <FormattedMessage id='Common.Msg.NoSearchData' />
        </em>
      </div>
    </>
  );
};

SuggestionUl.proptype = {
  suggestions: proptype.array,
  handleCompleteAddress: proptype.func.isRequired
};

SuggestionUl.defaultProps = {
  suggestions: []
};

/**
 * yandex search logcaion api의 검색결과 표기
 */
const AutoComplete = ({ handleCompleteAddress, onKeyDown, suggestions }) => {
  const [userInput, setUserInput] = useState('');
  const intl = useIntl();

  const onChange = useCallback((e) => {
    setUserInput(e.currentTarget.value);
  }, []);

  const onKeyInput = useCallback((e) => {
    if (fp.equals(13, e.keyCode)) {
      onKeyDown(e.currentTarget.value);
    }
  }, []);
  return (
    <>
      <input
        className='search'
        placeholder={intl.formatMessage({ id: 'Reservation.Address.Placeholder' })}
        style={{
          width: 'calc(100% - 32px)',
          border: '1px solid #dbdee1'
        }}
        type='text'
        value={userInput}
        onChange={onChange}
        onKeyDown={onKeyInput}
      />
      <Button icon={<SearchOutlined />}  onClick={()=>onKeyDown(userInput)} style={{verticalAlign:'-4px'}}/>

      <SuggestionUl handleCompleteAddress={handleCompleteAddress} suggestions={suggestions} />
    </>
  );
};

export default AutoComplete;
