import request from 'apis/request';

const prefix = '/vehicle/repair';

function getAccidentSortFieldNum(field) {
  switch (field) {
    default:
    case 'incidentDate':
      return 1;
  }
}

/**
 * 사고/수리 목록 조회
 */
export const getAccidentsAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getAccidentSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 사고 삭제
 */
export const deleteAccidentInfoAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
