import React, { useEffect, useState, forwardRef } from 'react';
import { useForm, FormContext } from 'react-hook-form';
// import { DevTool } from 'react-hook-form-devtools';

const FMSForm = forwardRef(
  (
    { children, onSubmit, watchErrors, watchValue, isValid, isSubmitted, style, onDevTool = false },
    refsOnSubmit
  ) => {
    const methods = useForm({ mode: 'onChange' }); // onChange
    const [prevErrors, setPrevErrors] = useState({});
    useEffect(() => {
      if (isValid) {
        isValid(methods.formState.isValid);
      }
      if (isSubmitted) {
        isSubmitted(methods.formState.isSubmitted);
      }
    }, [methods.formState]);

    useEffect(() => {
      if (JSON.stringify(prevErrors) !== JSON.stringify(methods.errors)) {
        if (watchErrors) {
          watchErrors(methods.errors);
        }
        setPrevErrors(JSON.parse(JSON.stringify(methods.errors)));
      }
    }, [methods, methods.errors]);

    const watchAllFields = methods.watch();

    useEffect(() => {
      if (watchValue) watchValue(watchAllFields);
    }, [watchAllFields]);

    useEffect(() => {
      if (refsOnSubmit && methods) refsOnSubmit.current = methods;
    }, [refsOnSubmit, methods]);

    return (
      <FormContext {...methods}>
        <form style={{ width: '100%', ...style }} onSubmit={methods.handleSubmit(onSubmit)}>
          {Array.isArray(children)
            ? children.map((child) => {
                return child.props && child.props.name
                  ? React.createElement(child.type, {
                      ...{
                        ...child.props,
                        // errors: methods.errors,
                        key: child.props.name
                      }
                    })
                  : child;
              })
            : children}
        </form>
        {/* {onDevTool && <DevTool control={methods.control} />} */}
        {/* set up the dev tool */}
      </FormContext>
    );
  }
);
export default React.memo(FMSForm);
