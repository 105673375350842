import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';
import { getErrorMessage } from 'constants/errors';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  initializeLoadAllStripe,
  loadAllStripe,
  initializeStripeListPage,
  changeStripeListPage,
  getStripeList,
  deleteForStripeList,
  initializeStripeDetailPage,
  changeStripeDetailPage,
  getStripeDetail,
  createStripe,
  updateStripe,
  deleteStripe
} = createActions(
  {
    LOAD_ALL_STRIPE: (filters) => ({ filters }),
    GET_STRIPE_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_STRIPE_DETAIL: (ID) => ({ ID }),
    CREATE_STRIPE: (data) => ({ data }),
    UPDATE_STRIPE: (ID, data) => ({ ID, data }),
    DELETE_STRIPE: (ID) => ({ ID })
  },
  'INITIALIZE_LOAD_ALL_STRIPE',
  'INITIALIZE_STRIPE_LIST_PAGE',
  'CHANGE_STRIPE_LIST_PAGE',
  'DELETE_FOR_STRIPE_LIST',
  'INITIALIZE_STRIPE_DETAIL_PAGE',
  'CHANGE_STRIPE_DETAIL_PAGE'
);
/** ********************************************** */

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
export const entityLoadAllStripeList = createEntity({
  key: loadAllStripe().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityStripeList = createEntity({
  key: getStripeList().type,
  parseData: (action, dataField) => {
    const data = action && action.response && action.response.data && action.response.data;
    const fieldName = dataField || 'items';

    let resData = null;
    if (dataField === 'none') {
      resData = data;
    } else if (data && fieldName in data) {
      resData = data[fieldName];
    }
    const reqPager = action && action.params ? action.params : null;
    const resPageInfo = data ? data.pageInfo : null;
    const items = data ? data.items : null;
    const pageInfo = { ...(reqPager ? reqPager.pageInfo : null), ...(resPageInfo || null) };
    if (resData === null) {
      resData = [];
    }

    // 삭제 예정, label, value는 사용하지 마세요.
    const updatedList =
      resData
      && resData.list
      && resData.list.map
      && resData.list.map((item) => {
        item.label = item.name || item.managerName;
        item.value = item.ID;
        item.realContractType = item.contractType;
        return item;
      });
    const updatedExcelList =
      resData
      && resData.listExcel
      && resData.listExcel.map
      && resData.listExcel.map((item) => {
        item.label = item.name || item.managerName;
        item.value = item.ID;
        item.realContractType = item.contractType;
        return item;
      });

    return {
      data: { list: updatedList, excelList: updatedExcelList },
      pager: { ...reqPager, pageInfo }
    };
  },
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityStripeDetail = createEntity({
  key: getStripeDetail().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    data: {
      list: [],
      excelList: []
    },
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'created', order: 'desc' },
      filters: {
        startTime: new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
          0,
          0,
          0,
          0
        ),
        endTime: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(
          23,
          59,
          59,
          999
        )
      }
    }
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create'
  },
  loadAll: {
    data: null,
    loading: false,
    pager: {
      filters: {}
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const stripeListPageReducer = handleActions(
  {
    [initializeStripeListPage().type]: () => initializeState.list,
    [changeStripeListPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const stripeListLoadAllReducer = handleActions(
  {
    [initializeLoadAllStripe().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);
const stripeDetailPageReducer = handleActions(
  {
    [initializeStripeDetailPage().type]: () => initializeState.detail,
    [changeStripeDetailPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);

// page action reducer 선언
export const stripe = combineReducers({
  list: createReducer({
    entity: entityStripeList,
    reducer: stripeListPageReducer
  }),
  detail: createReducer({
    entity: entityStripeDetail,
    reducer: stripeDetailPageReducer
  }),
  loadAll: createReducer({
    entity: entityLoadAllStripeList,
    reducer: stripeListLoadAllReducer
  })
});
/** ********************************************** */
