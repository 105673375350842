import request from 'apis/request';

const prefix = '/currency';

export const getCurrencyListAPI = (countryID) => {
  console.log('getCurrencyListAPI call');
  return request({
    url: '/currencyList',
    data: countryID
  });
};

export const updateCurrencyAPI = (data) => {
  console.log('update:', data);
  return request({
    url: `${prefix}/${data.currencyId}`,
    method: 'patch',
    data: data
  });
};

export const createCurrencyAPI = (data) => {
  console.log('createCurrencyAPI:');
  return request({
    url: `${prefix}`,
    method: 'post',
    data
  });
};

export const deleteCurrencyAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
};
