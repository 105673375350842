import { combineReducers } from 'redux';
import { createActions, handleActions } from 'redux-actions';

import { produce } from 'immer';

import { createEntity, createReducer } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { parseDataForList } from 'v2/redux/modules/lib/actionLib';
import { PAGE_LIMIT } from 'v2/constants/index';

/** ********************************************** */
/** action 정의: async(request, success, failure는 제외) */
// createActions(type, ?payloadCreator, ?metaCreator)
// payloadCreator: 미정의시 argument 1개가 payload value가 된다. 예 action(1) => { type: actionType, payload: 1}
// metaCreator:  () => ({ admin: true }) 정의시 다음과 같이 처리된다. 예 action(1) => { type: actionType, payload: 1, meta: { admin: true }}
export const {
  getVehicleModelsList /** 전체조회 */,
  getVehicleModelList,
  getVehicleModel,
  createVehicleModel,
  updateVehicleModel,
  updateVehicleModelImage,
  deleteVehicleModel,
  deleteForVehicleModelList,
  changeVehicleModelPage,
  initializeVehicleModelsPage,
  initializeVehicleModelListPage,
  initializeVehicleModelDetailPage,
  loadAllVehicleModel,
  initializeVehicleModelLoadAllPage
} = createActions(
  {
    LOAD_ALL_VEHICLE_MODEL: (filters) => ({ filters }),
    GET_VEHICLE_MODELS_LIST: (filters) => ({ filters }),
    GET_VEHICLE_MODEL_LIST: (pageInfo, sortInfo, filters) => ({ pageInfo, sortInfo, filters }),
    GET_VEHICLE_MODEL: (ID) => ({ ID }),
    CREATE_VEHICLE_MODEL: (data, image) => ({ data, image }),
    UPDATE_VEHICLE_MODEL: (ID, data, image) => ({ ID, data, image }),
    UPDATE_VEHICLE_MODEL_IMAGE: (ID, image, mode) => ({ ID, image, mode }),
    DELETE_VEHICLE_MODEL: (ID) => ({ ID })
  },
  'INITIALIZE_VEHICLE_MODEL_LOAD_ALL_PAGE',
  'INITIALIZE_VEHICLE_MODELS_PAGE',
  'INITIALIZE_VEHICLE_MODEL_LIST_PAGE',
  'INITIALIZE_VEHICLE_MODEL_DETAIL_PAGE',
  'CHANGE_VEHICLE_MODEL_PAGE',
  'DELETE_FOR_VEHICLE_MODEL_LIST'
);

/** ********************************************** */
function parseDataForLabelList(action) {
  const actionData =
    action && action.response && action.response.data && action.response.data.items
      ? action.response.data.items
      : [];
  const updatedList = actionData.map((item) => {
    item.label = item.name;
    item.value = item.ID;

    return item;
  });

  return { data: updatedList };
}

/** ********************************************** */
/** sagas action 정의: async(request, success, failure) */
// export const entityLoadAllBranchList = createEntity({ key: loadAllBranch().type
//   , parseData: parseDataForList,
// });
// all load
export const entityVehicleModelsList = createEntity({
  key: getVehicleModelsList().type,
  parseData: parseDataForLabelList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

// page load
export const entityVehicleModelList = createEntity({
  key: getVehicleModelList().type,
  parseData: parseDataForList,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityVehicleModel = createEntity({
  key: getVehicleModel().type,
  afterFailureFn: () => {
    FMSCommon.toast.fail('Common.Msg.NoData');
  }
});

export const entityLoadAllVehicleModel = createEntity({
  key: loadAllVehicleModel().type,
  parseData: parseDataForList
});

/** ********************************************** */

/** ********************************************** */
/** Reducer 영역 */
/** reducer는 store에 넣어야 한다.  */
// 초기 state, store.createStore 시 사용. 없으면 Maximum call stack size exceeded re-render 발생.
export const initializeState = {
  list: {
    /** 전체용 */
    status: null,
    data: [],
    filters: {},
    error: null
  },
  detail: {
    data: null,
    loading: false,
    mode: 'create'
  },
  listPage: {
    data: [],
    loading: false,
    pager: {
      pageInfo: { limit: PAGE_LIMIT, page: 1 },
      sortInfo: { field: 'createdAt', order: 'desc' },
      filters: {}
    }
  },
  loadAll: {
    data: [],
    loading: false,
    pager: {
      filters: {}
    }
  }
};

// state 처리를 위한 상수 reducer 정의.
// sync action reducer를 정의하지 않으면 state값에 저장되지 않고 payload만 처리된다.
// payload만 가지고 sagas 에서 연결해서 처리할 수도 있지만 거의 사용하지 않음.
const vehicleModelsPageReducer = handleActions(
  {
    [initializeVehicleModelListPage().type]: () => initializeState.list
  },
  {},
  { produce }
); /** 전체용 */

const vehicleModelListPageReducer = handleActions(
  {
    [initializeVehicleModelListPage().type]: () => initializeState.listPage
  },
  {},
  { produce }
);
const vehicleModelDetailPageReducer = handleActions(
  {
    [initializeVehicleModelDetailPage().type]: () => initializeState.detail,
    [changeVehicleModelPage().type]: (state, { payload }) => ({ ...state, ...payload })
  },
  {},
  { produce }
);
const vehicleModelLoadAllReducer = handleActions(
  {
    [initializeVehicleModelLoadAllPage().type]: () => initializeState.loadAll
  },
  {},
  { produce }
);

// page action reducer 선언
export const vehicleModels = combineReducers({
  list: createReducer({
    /** 전체용 */
    entity: entityVehicleModelsList,
    reducer: vehicleModelsPageReducer
  }),
  listPage: createReducer({
    /** 모델관리 목록 페이지 */
    entity: entityVehicleModelList,
    reducer: vehicleModelListPageReducer
  }),
  loadAll: createReducer({
    /** 모델관리 Select */
    entity: entityLoadAllVehicleModel,
    reducer: vehicleModelLoadAllReducer
  }),
  detail: createReducer({
    /** 모델관리 상세/등록/수정 페이지 */
    entity: entityVehicleModel,
    reducer: vehicleModelDetailPageReducer
  })
});
/** ********************************************** */
