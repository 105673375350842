import React from 'react';
import styled from 'styled-components';

const FMSLoaderDFMSign = styled.div`
  ${(props) => (props.type === 'default' ? 'position: absolute;' : '')}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 20;
`;

const FMSLoader = ({ id = 'loading', type = 'default', loading }) => {
  if (loading) {
    return (
      <FMSLoaderDFMSign type={type}>
        <div className='loader' />
      </FMSLoaderDFMSign>
    );
  }
  return null;
};

export default FMSLoader;
