import { put, select, takeLatest } from 'redux-saga/effects';

import {
  getCodeListAPI,
  getCodeAPI,
  createCodeAPI,
  updateCodeAPI,
  deleteCodeAPI
} from 'v2/apis/codeApi';

import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';

import {
  initializeState,
  getCodeListNew,
  changeCodePageNew,
  createCodeNew,
  updateCodeNew,
  deleteCodeNew,
  entityCodeList,
  getCodeNew,
  entityCodeDetail,
  loadAllCodeNew,
  entityLoadAllCode
} from '.';

/** ********************************************** */
/** constant */
export const historyUrl = '/newCode';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
export const selectorCodeListPage = (state) => state.newCode.list;
export const selectorCodeLoadAll = (state) => state.newCode.loadAll;
/** ********************************************** */

/** ********************************************** */
/** sagas 정의: async(request, success, failure) - 별도 reducer는 정의하지 않아도 된다. */
// create for create page
export const entityCreateCode = createEntity({
  key: createCodeNew().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Create.Fail');
  },
  afterSuccessFn() {
    FMSCommon.toast.success('Common.Created.Success');
  }
});

// update for update page
export const entityUpdateCode = createEntity({
  key: updateCodeNew().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Update.Fail');
  },
  afterSuccessFn: () => {
    FMSCommon.toast.success('Common.Updated.Success');
  }
});
// delete for detail page
export const entityDeleteCode = createEntity({
  key: deleteCodeNew().type,
  afterFailureFn: (payload) => {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Delete.Fail');
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Deleted.Success');
    yield put(changeCodePageNew({ selectedNode: null, mode: 'none' }));
  }
});

// mulitple delete for list page.
// export const entityDeleteForBranchList = createEntity({ key: deleteForBranchList().type
//   , *beforeFn(payload) {
//     const isContinue = payload && payload.length;
//     if(isContinue) yield put(changeBranchDetailPage({ loading: true }));
// else FMSCommon.toast.warn('Common.Alert.Delete.No.Checked');
//     return isContinue;
//   }
//   , *afterSuccessFn() {
//     FMSCommon.toast.success('Common.Deleted.Success');
//     const selectorListPage = yield select(selectorBranchListPage);
//     const { pager: { pageInfo, sortInfo, filters } } = selectorListPage;
//     yield put(getBranchList(pageInfo, sortInfo, filters));
//   }
//   , *afterFailureFn() {
//     FMSCommon.toast.fail('Common.Deletes.Fail');
//     const selectorListPage = yield select(selectorBranchListPage);
//     const { pager: { pageInfo, sortInfo, filters } } = selectorListPage;
//     yield put(getBranchList(pageInfo, sortInfo, filters));
//   } });
/** ********************************************** */

/**
 * SubRoutin
 */
// watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const createCodeAsync = createSagaAction.bind(null, entityCreateCode, createCodeAPI);
const updateCodeAsync = createSagaAction.bind(null, entityUpdateCode, updateCodeAPI);
const deleteCodeAsync = createSagaAction.bind(null, entityDeleteCode, deleteCodeAPI);

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getCodeListAsync = createSagaAction.bind(null, entityCodeList, getCodeListAPI);
const getLoadAllCodeAsync = createSagaAction.bind(null, entityLoadAllCode, getCodeListAPI);
const getCodeAsync = createSagaAction.bind(null, entityCodeDetail, getCodeAPI);

function* changeCodeSaga() {
  yield put(getCodeListNew(initializeState.list.pager.filters));
}

export function* getCodeLoadAll() {
  const { data } = yield select(selectorCodeLoadAll);
  return data;
}

/**
 * Watcher
 */
export default function* newCodeWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    createCodeNew().type,
    confirmFetchSaga,
    'Common.Confirm.Create',
    createCodeAsync,
    entityCreateCode
  );
  yield takeLatest(
    updateCodeNew().type,
    confirmFetchSaga,
    'Common.Confirm.Update',
    updateCodeAsync,
    entityUpdateCode
  );
  yield takeLatest(
    deleteCodeNew().type,
    confirmFetchSaga,
    'Common.Confirm.Delete',
    deleteCodeAsync,
    entityDeleteCode
  );
  yield takeLatest(getCodeListNew().type, fetchSaga, getCodeListAsync, entityCodeList);
  yield takeLatest(getCodeNew().type, fetchSaga, getCodeAsync, entityCodeDetail);
  yield takeLatest(loadAllCodeNew().type, fetchSaga, getLoadAllCodeAsync, entityLoadAllCode);

  // yield takeLatest(getCodeListWithGroupKey().type, fetchSaga, getBranchDetailAsync, entityCodeMultiList);

  // deletes in list page
  // yield takeLatest(deleteForBranchList().type, fetchSagas, null, deleteForBranchListAsync, entityDeleteForBranchList);

  // select business
  // yield takeLatest(loadAllCode().type, fetchSaga, loadAllCodeListAsync, entityLoadAllCode);

  // type, worker
  // success update for detail page -> change mode for detail page
  yield takeLatest(entityCreateCode.types[1], changeCodeSaga);
  yield takeLatest(entityUpdateCode.types[1], changeCodeSaga);
  yield takeLatest(entityDeleteCode.types[1], changeCodeSaga);
}
