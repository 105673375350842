const authConfig = {
  getAclAuth: (acl) => {
    switch (acl) {
      case '/location':
        return {
          R: 'ALL',
          W: 'SYSTEM_ADMIN|SYSTEM_OPERATOR',
          D: 'SYSTEM_ADMIN|SYSTEM_OPERATOR',
          DN: 'ALL'
        };
      case '/vehicleModel':
        return {
          R: 'ALL',
          W: 'SYSTEM_ADMIN|SYSTEM_OPERATOR',
          D: 'SYSTEM_ADMIN|SYSTEM_OPERATOR',
          DN: 'ALL'
        };
      default:
        return {
          R: 'ALL'
        };
    }
  }
};

export default authConfig;
