import request from 'apis/request';
import { PAGE_LIMIT } from 'v2/constants/index';

const prefix = '/available/vehicle';

/**
 * 영업점 전체 리스트 조회
 */
export const getRentalSearchListAPI = ({ pageInfo = {}, /* sortInfo = {}, */ filters = {} }) => {
  const { page = 1, limit = PAGE_LIMIT } = pageInfo;
  // const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { startTime, endTime, keywordType, keyword, ...data } = filters;

  data.page = page === 0 ? 1 : page;
  data.rowPerPage = limit === 0 ? PAGE_LIMIT : limit;

  data.startTime = new Date(startTime).getTime();
  data.endTime = new Date(endTime).getTime();

  if (keyword) {
    data[keywordType] = keyword;
  }

  return request({
    url: `${prefix}`,
    data
  });
};
