import request from 'apis/request';

const prefix = '/member';

function getSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/**
 * 관리자 리스트 조회
 * ex) /member
 */
export const getMembersAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, startDate, endDate,
    keywordType,
    keyword,
    ...data
  } = filters;

  if (keywordType && keywordType !== '' && keyword) data[keywordType] = keyword;
  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getSortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 관리자 상세조회
 * ex) /member/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const getMemberAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`
  });

/**
 * 관리자 승인
 * ex) /member/ce4b7689-baa5-4cb6-ba34-dbc8468f943d/confirm
 */
export const confirmMemberAPI = ({ ID, data }) => {
  if (!data || !data.roleDetail) return;
  data.roleDetail = `${data.roleDetail.value}`;
  data.role = 'OPERATOR';

  return request({
    url: `${prefix}/${ID}/confirm`,
    method: 'put',
    data
  });
};

/**
 * 관리자 반려
 * ex) /member/ce4b7689-baa5-4cb6-ba34-dbc8468f943d/confirm
 */
export const denyMemberAPI = ({ ID, data }) => request({
    url: `${prefix}/${ID}/deny`,
    method: 'put',
    data
  });

/**
 * 관리자 수정
 * ex) /member/ce4b7689-baa5-4cb6-ba34-dbc8468f943d
 */
export const updateMemberAPI = ({ ID, data }) => {
  if (!data || !data.roleDetail) return;
  data.roleDetail = `${data.roleDetail.value}`;
  data.role = 'OPERATOR';
  return request({
    url: `${prefix}/${ID}`,
    method: 'put',
    data
  });
};

/**
 * 관리자 삭제
 * ex) /member/b93ddfcc-56fc-4efe-9dbf-94e419b1001b
 */
export const deleteMemberAPI = ({ ID }) => request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
