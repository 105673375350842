export const ErrorCode = [
  // { code : 0, label:  'ERROR_CODE_SUCCESS'  },
  // { code : 2, label:  'ERROR_CODE_NO_CONTENT'  },
  // { code : 3, label:  'ERROR_CODE_CREATED'  },
  { code: 90001, label: 'ERROR_CODE_NOT_CREATED' },
  { code: 90010, label: 'ERROR_CODE_ALREADY_EXIST' },
  { code: 90011, label: 'ERROR_CODE_NO_INSURANCE' },
  { code: 90002, label: 'ERROR_CODE_NOT_UPDATED' },
  { code: 90003, label: 'ERROR_CODE_NOT_DELETED' },
  { code: 910001, label: 'ERROR_CODE_BAD_REQUEST' },
  { code: 910002, label: 'ERROR_CODE_FORBIDDEN_ROLE' },
  { code: 910003, label: 'ERROR_CODE_FORBIDDEN_ACL' },
  { code: 910004, label: 'ERROR_CODE_INTERNAL_SERVER_ERROR' },
  { code: 910005, label: 'ERROR_CODE_NEED_LOGON' },
  { code: 910101, label: 'ERROR_CODE_VEHICLE_NOT_DELETE_RESERVATION' },
  { code: 910102, label: 'ERROR_CODE_VEHICLE_NOT_DELETE_ACCIDENT' },
  { code: 910103, label: 'ERROR_CODE_VEHICLE_VIN_DUPLICATION' },
  { code: 910104, label: 'ERROR_CODE_VEHICLE_CONTROL_ID_DUPLICATION' },
  { code: 9104, label: 'ERROR_CODE_USER_NOT_AUTHORIZATION' },
  { code: 910540, label: 'ERROR_CODE_BUSINESS_NOT_DELETE_CAR' },
  { code: 910460, label: 'ERROR_CODE_PRIVATE_UNAUTH_BUSINESS' },
  { code: 910470, label: 'ERROR_CODE_CHARGE_NOT_DELETE_RESERVATION' },
  { code: 910501, label: 'ERROR_CODE_VEHICLEMODEL_NOT_DELETE' },
  { code: 910701, label: 'ERROR_CODE_RESERVATION_ALEADY_VEHICLE' },
  { code: 910702, label: 'ERROR_CODE_RESERVATION_CONFLICT' },
  { code: 910703, label: 'ERROR_CODE_RESERVATION_UNAUTH_USER' },
  { code: 910704, label: 'ERROR_CODE_RESERVATION_FIRST_USER_BUSINESS' },
  { code: 910705, label: 'ERROR_CODE_RESERVATION_NOT_INSUE' },
  { code: 910706, label: 'ERROR_CODE_RESERVATION_RETURN_NOT_LOCATION' },
  { code: 910707, label: 'ERROR_CODE_RESERVATION_BAD_REQUEST' },
  { code: 910708, label: 'ERROR_CODE_RESERVATION_VEHICLE_ERROR' },
  { code: 910709, label: 'ERROR_CODE_RESERVATION_TIME_ERROR' },
  { code: 910710, label: 'ERROR_CODE_RESERVATION_RENTAL_COMPANY' },
  { code: 910711, label: 'ERROR_CODE_RESERVATION_DRIVER_LICENSE' },
  { code: 910712, label: 'ERROR_CODE_RESERVATION_ADDITIONAL_DRIVER_LICENSE' },
  { code: 910713, label: 'ERROR_CODE_RESERVATION_NO_CONTENT' },
  { code: 910715, label: 'ERROR_CODE_RESERVATION_GRPC_ERROR' },
  { code: 910716, label: 'ERROR_CODE_RESERVATION_STATUS_ERROR' },
  { code: 910717, label: 'ERROR_CODE_RESERVATION_KOROAD_ERROR' },
  { code: 910718, label: 'ERROR_CODE_RESERVATION_INUSE' },
  { code: 910726, label: 'ERROR_CODE_RESERVATION_OVERLAP' },
  { code: 910727, label: 'ERROR_CODE_RETIRE_RENTAL_FAIL' },
  { code: 910728, label: 'ERROR_CODE_RETIRE_RESERVATION_FAIL' },
  { code: 910739, label: 'ERROR_CODE_RESERVATION_EMAIL' },
  { code: 910740, label: 'ERROR_CODE_RESERVATION_NOUSER' },
  { code: 910800, label: 'ERROR_CODE_CHARGE' },
  { code: 910760, label: 'ERROR_CODE_TESTCAR_CONFIG_NOT_EXIST' },
  { code: 910761, label: 'ERROR_CODE_TESTCAR_RESERVATION_PERIOD_EXCESS' },
  { code: 910762, label: 'ERROR_CODE_TESTCAR_STARTTIME_WEEKEND' },
  { code: 910763, label: 'ERROR_CODE_TESTCAR_ENDTIME_WEEKEND' },
  { code: 910764, label: 'ERROR_CODE_TESTCAR_STARTTIME_HOLIDAY' },
  { code: 910765, label: 'ERROR_CODE_TESTCAR_ENDTIME_HOLIDAY' },
  { code: 910767, label: 'ERROR_CODE_TESTCAR_COUNT_EXCESS' },
  { code: 910779, label: 'ERROR_CODE_INUSE_CANNOT_DELETE' },
  { code: 910783, label: 'ERROR_CODE_INUSE_RESERVATION_CONFLICT' },
  { code: 911001, label: 'ERROR_CODE_MONITORING_NOT_FIND_VIN' },
  { code: 911101, label: 'ERROR_CODE_CONTROL_FAIL' },
  { code: 911102, label: 'ERROR_CODE_CONTROL_VIN_DUPLICATION' },
  { code: 911103, label: 'ERROR_CODE_CONTROL_NADID_DUPLICATION' },
  { code: 911201, label: 'ERROR_CODE_RESOURCE_NOT_FOUND' },
  { code: 911202, label: 'ERROR_CODE_RESOURCE_KEY_DUPLICATION' },
  { code: 911203, label: 'ERROR_CODE_RESOURCE_KEY_EMPTY' },
  { code: 911301, label: 'ERROR_CODE_COMPANY_BUSINESS_REG_NUM_EXIST' },
  { code: 920101, label: 'ERROR_CODE_EXIST_BRANCH_USER' },
  { code: 910722, label: 'ERROR_CODE_ALREADY_RESERVED' },
  { code: 910773, label: 'ERROR_CODE_ACCESSORY_AND_SERVICE_EXIST' },
  { code: 910774, label: 'ERROR_CODE_STOCK_EXIST' },
  { code: 911501, label: 'ERROR_CODE_MENU_CHILDREN_EXIST' },
  { code: 911601, label: 'ERROR_CODE_LICENSE_USE_BUSINESS_EXIST' },
  { code: 911401, label: 'ERROR_CODE_ROLE_CHILDREN_EXIST' },
  // { code: 910730, label: 'ERROR_CODE_FORCERETURN_CAN_BE_MADE_AFTER_THE_START_TIME' },
  { code: 910730, label: 'ERROR_CODE_VEHICLE_ALREADY_RENTED' },
  { code: 910719, label: 'ERROR_CODE_NOT_FOUND_RESERVATION_DATA' },
  { code: 910731, label: 'ERROR_CODE_TODAY_IS_NOT_SAME_DATE' },
  { code: 910725, label: 'ERROR_CODE_NOT_MATCH_PASSWORD' },
  { code: 910736, label: 'Reservation.Error.AvailableDate' },
  { code: 910771, label: 'ERROR_CODE_FIXEDCOST_DUPLICATION' },
  { code: 910732, label: 'ERROR_CODE_CANNOT_UPDATE_RESERVATION' },
  { code: 910733, label: 'ERROR_CODE_CANNOT_EXTENDED_RESERVATION' },
  { code: 910782, label: 'ERROR_CODE_ACCESSORY_ITEM_RESERVED' },
  // 딜러승인 오류 메시지 (KIAM-1435)
  { code: 910801, label: 'ERROR_CODE_DEALER_PERMIT_PROCESS' },
  { code: 910802, label: 'ERROR_CODE_DEALER_PERMIT_SBERPAY' },
  { code: 912005, label: 'ERROR_MESSAGE_INVOICE_RESEND_YN' },
  // 차량 거점 이동
  { code: 913001, label: 'ERROR_MESSAGE_INTERNAL_DB' },
  { code: 913002, label: 'ERROR_MESSAGE_BAD_REQUEST_BRANCH' },
  { code: 913003, label: 'ERROR_MESSAGE_BAD_REQUEST_LOCATION' },
  { code: 913004, label: 'ERROR_MESSAGE_BAD_REQUEST_BUSINESS' },
  { code: 913005, label: 'ERROR_MESSAGE_BAD_REQUEST_DELETED_LOCATION' },
  { code: 913006, label: 'ERROR_MESSAGE_INTERNAL_SERVER_ERRPR_GET_VEHICLE' },
  { code: 913007, label: 'ERROR_MESSAGE_INTERNAL_SERVER_ERRPR_UPDATE_ORIGIN_VEHICLE' },
  { code: 913008, label: 'ERROR_MESSAGE_INTERNAL_SERVER_ERRPR_INSERT_VEHICLE' },
  { code: 913009, label: 'ERROR_MESSAGE_INTERNAL_SERVER_ERRPR_UPDATE_NEW_VEHICLE' },
  { code: 913010, label: 'ERROR_MESSAGE_INTERNAL_SERVER_ERRPR_ALREADY_EXIST' },
  { code: 913011, label: 'ERROR_MESSAGE_INTERNAL_SERVER_ERROR_VIN_DUPLICATION' },
  { code: 913012, label: 'ERROR_MESSAGE_BAD_REQUEST_TRANSFERED_VEHICLE' },
  { code: 910790, label: 'ERROR_CODE_APPVERSION_ALREADY_EXIST' },
  { code: 910791, label: 'ERROR_CODE_INVAILD_APPVERSION_FORMAT' },
  { code: 910792, label: 'ERROR_CODE_INVAILD_BUILDVERSION_FORMAT' },
  // 사업자관리 거점삭제
  { code: 920103, label: 'ERROR_MESSAGE_BUSINESS_LOCATION_EXIST' },
  { code: 910108, label: 'ERROR_MESSAGE_RESERVATION_USED' }, //차량 이용시 차량이 대여중지인 경우 불가
  { code: 913013, label: 'ERROR_MESSAGE_VEHICLE_USED' }, //차량 대여중지시 이용중일 경우 불가.
  { code: 910738, label: 'ERROR_MESSAGE_EXTEND_MILEAGE_CHECK' }, //대여연장시 마일리지 구간 변경 시 불가
  { code: 910110, label: 'ERROR_ALREADY_RECOVERED_VEHICLE' }, //복구 시도
  { code: 90005, label: 'NOT_FOUND_VEHICLE' }, //복구 실패
  { code: 910107, label: 'VehicleBasic.VIN.Error' }, //복구 실패
  { code: 910111, label: 'ERROR_MESSAGE_MODEL_DUPLICATE' }, //차량모델 저장시 이름 중복 오류
  { code: 910112, label: 'ERROR_MESSAGE_MODEL_DATA_DUPLICATE' }, //차량모델 이름 중복이 2건 이상
  { code: 910113, label: 'ERROR_MESSAGE_RETIRED_VEHICLE_DUPLICATE' } //차량복구시 차량번호를 기존에 사용
];

export const getErrorMessage = (code) => {
  const findItem = ErrorCode.find((item) => code === item.code);
  return (findItem && findItem.label) || 'Common.Error.message';
};
