import request from 'apis/request';

const prefix = '/code';

export const getCodeListAPI = ({ filters }) => {
  return request({
    url: `${prefix}`,
    data: filters
  });
};

export const getCodeListWithGroupKeyAPI = (groupKeyList) => {
  return Promise.all(
    groupKeyList.map((key) => {
      return request({
        url: `${prefix}/usage`,
        data: {
          group: key
        }
      });
    })
  );
};

export const createCodeAPI = ({ data }) => {
  return request({
    url: `${prefix}`,
    method: 'post',
    data
  });
};

export const updateCodeAPI = ({ ID, data }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'patch',
    data
  });
};

export const deleteCodeAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete',
    data: { cascade: false }
  });
};

export const getCodeAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};
