import { put, select, takeLatest } from 'redux-saga/effects';

import { getVariableCostListAPI, saveVariableCostAPI } from 'v2/apis/vehicleCostApi';

import { createSagaAction, confirmFetchSaga, fetchSaga } from 'v2/redux/sagas';
import { createEntity } from 'v2/redux/lib';
import { FMSCommon } from 'service/common/commonLib';
import { getErrorMessage } from 'constants/errors';
import history from 'service/common/history';
import {
  getVehicleVariableCostList,
  entityVehicleVariableCostList,
  saveVariableCost,
  changeOpenModal
} from '.';

/** ********************************************** */
/** constant */
// export const historyUrl = '/costitembydealer';
/** ********************************************** */

/** ********************************************** */
/** saga selector */
// export const selectorVehicleCostPage = state => state.variableCost.list;
/** ********************************************** */

// 저장
export const entitySaveVariableCost = createEntity({
  key: saveVariableCost().type,
  *afterFailureFn(payload) {
    if (payload && payload.errorCode) FMSCommon.toast.fail(getErrorMessage(payload.errorCode));
    else FMSCommon.toast.fail('Common.Saved.Fail');
    yield put(changeOpenModal({ isCloseModal: true }));
  },
  *afterSuccessFn() {
    FMSCommon.toast.success('Common.Saved.Success');
    yield put(changeOpenModal({ isCloseModal: true }));
  }
});

// entityAsyncSaga, watcher 에서 실행하는 actionHandler. buseinssAction 을 호출하면 watch를 통해 API 호출한다.
const getVariableCostListAsync = createSagaAction.bind(
  null,
  entityVehicleVariableCostList,
  getVariableCostListAPI
);
const saveVariableCostAsync = createSagaAction.bind(
  null,
  entitySaveVariableCost,
  saveVariableCostAPI
);
/**
 * Watcher
 */
export default function* variableCostWatcher() {
  // type, workder, // worker arguments. { actionHandler, entity }
  yield takeLatest(
    getVehicleVariableCostList().type,
    fetchSaga,
    getVariableCostListAsync,
    entityVehicleVariableCostList
  );
  yield takeLatest(
    saveVariableCost().type,
    confirmFetchSaga,
    'Common.Confirm.Save',
    saveVariableCostAsync,
    entitySaveVariableCost
  );
}
