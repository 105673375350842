import request from 'apis/request';

const prefix = '/stripeinvoice';
const reservation_prefix = '/reservation';

function getComapnySortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

/*
  필요한 api
  1. pageList 데이터 조회
  2. 등록하는 부분
  3. 검색해서 pageList 데이터 조회
  4. 등록페이지에서 저장
  5. 취소
*/

export const getAllStripeListAPI = ({ filters = {} }) => {
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 송장관리 전체 리스트 조회
 */
export const getStripeListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { ...data } = filters;
  data.page = page;
  data.rowPerPage = limit;
  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 송장관리 엑셀다운로드 리스트 조회
 */
export const getExcelDownloadAPI = ({ filters = {} }) => {
  const { ...data } = filters;
  return request({
    url: `${prefix}`,
    data
  });
};

/**
 * 송장관리 상세 조회
 */
export const getStripeDetailAPI = ({ ID }) => request({
    url: `${reservation_prefix}/${ID}`
  });

/**
 * 송장관리 등록 기능
 */
export const createStripeAPI = (inputData) => {
  // const data = convertFormToData(inputData.data);
  return request({
    url: `${prefix}`,
    method: 'post',
    data: inputData.data
  });
};

/**
 * 송장관리 수정 기능
 */
export const updateStripeAPI = (inputData) => {
  return request({
    url: `${prefix}/${inputData.ID}`,
    method: 'patch',
    data: inputData.data
  });
};

/**
 * 송장관리 삭제 기능
 */
export const deleteStripeAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
};

/**
 * 송장관리 복수 삭제 기능
 */
export const deleteStripesAPI = (IDArr) => Promise.all(
    IDArr.map((ID) => request({
        method: 'delete',
        url: `${prefix}/${ID}`
      }))
  );
