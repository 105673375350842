import request from 'apis/request';

const prefix = '/location';

function getComapnySortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

function getVehicleSortFieldNum(field) {
  switch (field) {
    default:
    case 'createdAt':
      return 1;
    case 'name':
      return 2;
  }
}

export const getLocationListAPI = ({ filters }) => {
  return request({
    url: prefix,
    data: filters
  });
};

export const getUnusedLocationListAPI = ({ filters }) => {
  return request({
    url: prefix,
    data: { ...filters, branchUsed: 'Y' }
  });
};

export const getAllLocationListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const {
    // status, defaultStatus, keywordType, keyword, startDate, endDate,
    ...data
  } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getComapnySortFieldNum(field);

  return request({
    url: `${prefix}`,
    data
  });
};

export const getLocationDetailAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`
  });
};

export const getLocationVehicleListAPI = ({ filters = {}, pageInfo = {}, sortInfo = {} }) => {
  const { page = 1, limit = 20 } = pageInfo;
  const { field = 'createdAt', order = 'desc' } = sortInfo;
  const { ...data } = filters;

  data.page = page;
  data.rowPerPage = limit;
  data.sortOrder = order === 'desc' ? 2 : 1;
  data.sortField = getVehicleSortFieldNum(field);

  return request({
    url: '/monitoring/vehicle',
    data
  });
};

export const getLocationVehicleAPI = ({ ID }) => {
  return request({
    url: `/monitoring/station/${ID}`
  });
};

export const createLocationAPI = ({ data }) => {
  if (data && data.radius) data.radius = parseInt(data.radius, 10);
  return request({
    url: prefix,
    method: 'post',
    data
  });
};

export const updateLocationAPI = ({ ID, data }) => {
  const { ...updateData } = data;
  if (updateData && updateData.radius) updateData.radius = parseInt(updateData.radius, 10);
  return request({
    url: `${prefix}/${ID}`,
    method: 'put',
    data: updateData
  });
};

export const deleteLocationAPI = ({ ID }) => {
  return request({
    url: `${prefix}/${ID}`,
    method: 'delete'
  });
};

export const deleteLocationsAPI = (IDArr) => {
  return Promise.all(
    IDArr.map((ID) => {
      return request({
        method: 'delete',
        url: `${prefix}/${ID}`
      });
    })
  );
};
